import { Enum_Language_Preference_Enum } from 'common/schema/commonSchemaRemoteTypes';
import { getUserLanguageFromBrowser } from 'common/utils/languageUtils';
import { isSomeEnum } from 'utils/utilFunctions';
import useAppSelector from './useAppSelector';

export const useUserPreferredLanguage = (params?: {
  shouldFallbackToNavigator?: boolean;
}): Enum_Language_Preference_Enum => {
  const { preferredLanguage } = useAppSelector((store) => store.account);
  if (isSomeEnum(Enum_Language_Preference_Enum)(preferredLanguage)) {
    return preferredLanguage;
  }
  const navigatorLanguage = getUserLanguageFromBrowser(navigator.language);
  return params?.shouldFallbackToNavigator ? navigatorLanguage : Enum_Language_Preference_Enum.English;
};
