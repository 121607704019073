import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { getDefaultPriceRangeFilter } from './priceRange.defaults';
import { PriceRangeFilterState } from './priceRange.types';

type TogglePriceRangeAction = PayloadAction<{
  priceRangeId: number;
}>;
export const createTogglePriceRangeCaseReducer =
  <State extends PriceRangeFilterState>(): CaseReducer<State, TogglePriceRangeAction> =>
  (state, action) => {
    const { priceRangeId } = action.payload ?? {};
    const prevOptions = state.priceRange;

    // cannot actively turn "All" off when it is on
    if (priceRangeId === 0 && prevOptions[0]) return;

    // can turn "All" on when it is off and turn everything else off
    if (priceRangeId === 0) {
      state.priceRange = getDefaultPriceRangeFilter().priceRange;
      return;
    }

    const nextOptions = { ...prevOptions };
    // toggle priceRange
    nextOptions[priceRangeId] = !nextOptions[priceRangeId];

    // if no priceRanges are on, set "All" on
    nextOptions[0] = !Object.values(nextOptions).includes(true);

    state.priceRange = nextOptions;
  };
