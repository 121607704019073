import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { TypeUtils } from 'common/utils/generalTypeUtils';
import { getDefaultAvailabilityFilter } from './availability.defaults';
import { AvailabilityEnum, AvailabilityFilterState } from './availability.types';

type ToggleAvailabilityAction = PayloadAction<{
  availability: AvailabilityEnum;
}>;
export const createToggleAvailabilityCaseReducer =
  <State extends AvailabilityFilterState>(): CaseReducer<State, ToggleAvailabilityAction> =>
  (state, action) => {
    const { availability } = action.payload ?? {};
    if (availability === AvailabilityEnum.ALL) {
      state.availability = getDefaultAvailabilityFilter().availability;
      return;
    }

    TypeUtils.Object.keys(state.availability).forEach((key) => {
      if (state.availability[key] && key === availability) {
        state.availability[AvailabilityEnum.ALL] = true;
      } else {
        state.availability[key] = false;
      }
    });

    state.availability[availability] = !state.availability[availability];
  };
