import React from 'react';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { RECOGNIZE_BUTTON_GRADIENT, RECOGNIZE_BUTTON_BORDER_RADIUS } from '../config/constants';

const useStyles = makeStyles(() => ({
  '@keyframes isLoadingSlideInRight': {
    '0%': {
      backgroundPositionX: '-400px',
    },
    '40%': {
      backgroundPositionX: '0',
    },
    '70%': {
      backgroundPositionX: '0',
    },
    '100%': {
      backgroundPositionX: '-400px',
    },
  },
  '@keyframes isLoadingSlideInLeft': {
    '0%': {
      backgroundPositionX: '400px',
    },
    '20%': {
      backgroundPositionX: '0',
    },
    '90%': {
      backgroundPositionX: '0',
    },
    '100%': {
      backgroundPositionX: '400px',
    },
  },
  baseContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignContent: 'center',
    justifyItems: 'center',
    alignItems: 'center',
    transition: 'all 0.3s ease-in-out',
    overflow: 'hidden',
    gap: '10px',
    userSelect: 'none',
    backgroundImage: RECOGNIZE_BUTTON_GRADIENT,
    backgroundPositionX: '-400px',
    backgroundRepeat: 'no-repeat',
    cursor: 'pointer',
    '&.light': {
      backgroundColor: '#FFFFFF',
      color: '#000000',
    },
    '&.dark': {
      backgroundColor: '#000000',
      color: '#FFFFFF',
    },
    '&.follow_to_following': {
      animation: '$isLoadingSlideInRight 1000ms linear',
    },
    '&.following_to_follow': {
      animation: '$isLoadingSlideInLeft 1000ms linear',
    },
  },
  hoverEffect: {
    '&:hover': {
      '&.light': {
        backgroundColor: '#0000000A',
      },
      '&.dark': {
        backgroundColor: '#FFFFFF1A',
      },
    },
  },
  shadowHover: {
    '&:hover': {
      boxShadow: '0px 4px 12px 0px #00000029',
    },
  },
  border: {
    borderRadius: RECOGNIZE_BUTTON_BORDER_RADIUS,
    border: '1px solid #00000029',
    '&.light': {
      borderColor: '#00000029',
    },
    '&.dark': {
      borderColor: '#FFFFFF29',
    },
  },
}));

export type FollowContainerProps = {
  height?: React.CSSProperties['height'];
  width?: React.CSSProperties['width'];
  padding?: React.CSSProperties['padding'];
  withoutBorder?: boolean;
  withoutHoverShadow?: boolean;
  withoutHover?: boolean;
  className?: string;
  maxWidth?: React.CSSProperties['maxWidth'];
  minWidth?: React.CSSProperties['minWidth'];
  margin?: React.CSSProperties['margin'];
  onClick?: (e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  backgroundColor?: React.CSSProperties['backgroundColor'];
  color?: React.CSSProperties['color'];
  borderRadius?: React.CSSProperties['borderRadius'];
  customStyles?: string;
};

const FollowContainer: React.FC<FollowContainerProps> = ({
  children,
  height,
  width,
  padding,
  withoutBorder,
  withoutHoverShadow,
  withoutHover,
  className,
  maxWidth,
  minWidth,
  margin,
  backgroundColor,
  color,
  borderRadius,
  onClick,
  customStyles,
}) => {
  const { baseContainer, hoverEffect, shadowHover, border } = useStyles();
  return (
    <div
      className={clsx(
        className,
        baseContainer,
        withoutBorder ? '' : border,
        withoutHoverShadow ? '' : shadowHover,
        withoutHover ? '' : hoverEffect,
        customStyles
      )}
      style={{
        height,
        width,
        padding,
        maxWidth,
        minWidth,
        margin,
        backgroundColor,
        color,
        borderRadius,
      }}
      onClick={onClick}
      tabIndex={0}
      role="button"
    >
      {children}
    </div>
  );
};
export default FollowContainer;
