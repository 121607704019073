import { ArtworksListType } from 'store/slices/artworkListModifiers';
import { getAppliedArtistFilter } from './artist';
import { availabilityButtonsListTypeConfig, getAppliedShowroomAvailabilityFilter } from './availability';
import { getAppliedGalleriesFilter } from './galleries';
import { getAppliedShowroomOrientationFilter, orientationButtonsListTypeConfig } from './orientation';
import { getAppliedPriceFilter } from './price';
import { getAppliedPrizesFilter } from './prizes';
import { getAppliedSeriesFilter } from './series';
import { getAppliedSort, sortButtonsListTypeConfig } from './sort';
import { AppliedListingModifier, filterTypes, ListingModifierButtonsConfig } from './types';
import { getAppliedYearsFilter } from './years';

export const listModifierDefaultFunctions = {
  AVAILABILITY: getAppliedShowroomAvailabilityFilter,
  ORIENTATION: getAppliedShowroomOrientationFilter,
  YEARS: getAppliedYearsFilter,
  ARTIST: getAppliedArtistFilter,
  SERIES: getAppliedSeriesFilter,
  PRICE: getAppliedPriceFilter,
  PRIZES: getAppliedPrizesFilter,
  GALLERIES: getAppliedGalleriesFilter,
  SORT: getAppliedSort,
};

export const groupTitlesKeys: { [key in filterTypes]: string } = {
  AVAILABILITY: 'filterStrings.avalability.title',
  ORIENTATION: 'filterStrings.orientation.title',
  YEARS: 'filterStrings.years.title',
  ARTIST: 'filterStrings.artist.title',
  SERIES: 'filterStrings.series.title',
  PRICE: 'filterStrings.price.title',
  PRIZES: 'filterStrings.prize.title',
  GALLERIES: 'filterStrings.gallery.title',
  SORT: 'filterStrings.sort.title',
};

export type ListModifierDefaultsParams = {
  variant?: ArtworksListType;
};

type DefaultModifiers = { [key in filterTypes]: AppliedListingModifier };

export const getListModifierDefaults = (params: ListModifierDefaultsParams): DefaultModifiers => ({
  AVAILABILITY: listModifierDefaultFunctions.AVAILABILITY(params.variant),
  ORIENTATION: listModifierDefaultFunctions.ORIENTATION(),
  YEARS: listModifierDefaultFunctions.YEARS([]),
  ARTIST: listModifierDefaultFunctions.ARTIST([]),
  SERIES: listModifierDefaultFunctions.SERIES([]),
  PRICE: listModifierDefaultFunctions.PRICE({ min: 0, max: 0 }),
  PRIZES: listModifierDefaultFunctions.PRIZES([]),
  GALLERIES: listModifierDefaultFunctions.GALLERIES([]),
  SORT: listModifierDefaultFunctions.SORT(0, params.variant),
});

export const filtersButtonsConfig: {
  [filterTypeKey in filterTypes]: {
    [listTypeKey in ArtworksListType]: ListingModifierButtonsConfig;
  };
} = {
  AVAILABILITY: availabilityButtonsListTypeConfig,
  ORIENTATION: orientationButtonsListTypeConfig,
  YEARS: {
    ARTIST_PROFILE: {},
    COLLECTOR_PROFILE: {},
    SHOWROOM: {},
    FAVORITES: {},
  },
  ARTIST: {
    ARTIST_PROFILE: {},
    COLLECTOR_PROFILE: {},
    SHOWROOM: {},
    FAVORITES: {},
  },
  SERIES: {
    ARTIST_PROFILE: {},
    COLLECTOR_PROFILE: {},
    SHOWROOM: {},
    FAVORITES: {},
  },
  PRICE: {
    ARTIST_PROFILE: {},
    COLLECTOR_PROFILE: {},
    SHOWROOM: {},
    FAVORITES: {},
  },
  PRIZES: {
    ARTIST_PROFILE: {},
    COLLECTOR_PROFILE: {},
    SHOWROOM: {},
    FAVORITES: {},
  },
  GALLERIES: {
    ARTIST_PROFILE: {},
    COLLECTOR_PROFILE: {},
    SHOWROOM: {},
    FAVORITES: {},
  },
  SORT: sortButtonsListTypeConfig,
};

export const visibleFilters: { [listTypeKey in ArtworksListType]: filterTypes[] } = {
  SHOWROOM: ['AVAILABILITY', 'ORIENTATION', 'SORT', 'SERIES', 'YEARS'],
  ARTIST_PROFILE: ['AVAILABILITY', 'ORIENTATION', 'SORT', 'SERIES', 'PRIZES', 'YEARS'],
  COLLECTOR_PROFILE: ['ARTIST', 'SORT', 'YEARS'],
  FAVORITES: ['SORT', 'ARTIST', 'AVAILABILITY'],
};
