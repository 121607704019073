import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  countryFilterDeserialize,
  countryFilterSerialize,
} from 'common/features/Filters/predefined/Country/country.utils';
import { getDefaultCountryFilter } from 'common/features/Filters/predefined/Country/store/country.defaults';
import { createSelectCountryCaseReducer } from 'common/features/Filters/predefined/Country/store/country.reducers';
import { CountryFilterState } from 'common/features/Filters/predefined/Country/store/country.types';
import {
  disciplinesFilterDeserialize,
  disciplinesFilterSerialize,
} from 'common/features/Filters/predefined/Disciplines/Disciplines.utils';
import { getDefaultDisciplineFilter } from 'common/features/Filters/predefined/Disciplines/store/disciplines.defaults';
import { createToggleDisciplineCaseReducer } from 'common/features/Filters/predefined/Disciplines/store/disciplines.reducers';
import { DisciplinesFilterState } from 'common/features/Filters/predefined/Disciplines/store/disciplines.types';
import {
  interestsFilterDeserialize,
  interestsFilterSerialize,
} from 'common/features/Filters/predefined/Interests/interests.utils';
import { getDefaultInterestFilter } from 'common/features/Filters/predefined/Interests/store/interests.defaults';
import { createToggleInterestCaseReducer } from 'common/features/Filters/predefined/Interests/store/interests.reducers';
import { InterestsFilterState } from 'common/features/Filters/predefined/Interests/store/interests.types';
import {
  minMaxPriceFilterDeserialize,
  minMaxPriceFilterSerialize,
} from 'common/features/Filters/predefined/MinMaxPrice/minMaxPrice.utils';
import { getDefaultMinMaxPriceFilter } from 'common/features/Filters/predefined/MinMaxPrice/store/minMaxPrice.defaults';
import { createToggleMinMaxPriceCaseReducer } from 'common/features/Filters/predefined/MinMaxPrice/store/minMaxPrice.reducers';
import { MinMaxPriceFilterState } from 'common/features/Filters/predefined/MinMaxPrice/store/minMaxPrice.types';
import {
  orientationFilterDeserialize,
  orientationFilterSerialize,
} from 'common/features/Filters/predefined/Orientation/orientation.utils';
import { createToggleOrientationCaseReducer } from 'common/features/Filters/predefined/Orientation/store/orientation';
import { getDefaultOrientationFilter } from 'common/features/Filters/predefined/Orientation/store/orientation.defaults';
import { OrientationFilterState } from 'common/features/Filters/predefined/Orientation/store/orientation.types';
import { getDefaultRefreshFilter } from 'common/features/Filters/predefined/Refresh/refresh.defaults';
import { createRefreshFilterCaseReducer } from 'common/features/Filters/predefined/Refresh/refresh.reducers';
import { RefreshFilterState } from 'common/features/Filters/predefined/Refresh/refresh.types';
import { sizeFilterDeserialize, sizeFilterSerialize } from 'common/features/Filters/predefined/Size/size.utils';
import { createToggleSizeCaseReducer } from 'common/features/Filters/predefined/Size/store/size';
import { getDefaultSizeFilter } from 'common/features/Filters/predefined/Size/store/size.defaults';
import { SizeFilterState } from 'common/features/Filters/predefined/Size/store/size.types';
import { SortState } from 'common/features/Filters/predefined/SortGroup/store/sort.types';
import {
  filterHashResetMarker,
  filtersMarker,
  ListingIdentifier,
} from 'common/features/VirtualList/components/listReduxFragment';
import { tagsFilterDeserialize, tagsFilterSerialize } from 'common/features/Filters/predefined/Tags/tags.utils';
import { TagsFilterState } from 'common/features/Filters/predefined/Tags/tags.types';
import { getDefaultTagFilter } from 'common/features/Filters/predefined/Tags/tags.default';
import { createSelectTagCaseReducer } from 'common/features/Filters/predefined/Tags/tags.reducers';
import { getDefaultShopArtworksVirtualList, ShopArtworksSortOptions } from './listShopArtworks.slice';

export type ShopArtworksFiltersState = SortState<ShopArtworksSortOptions> & {
  filterHash: ListingIdentifier<ShopArtworksSortOptions>;
} & RefreshFilterState &
  MinMaxPriceFilterState &
  OrientationFilterState &
  SizeFilterState &
  DisciplinesFilterState &
  InterestsFilterState &
  CountryFilterState &
  TagsFilterState;

export const serializeFilters = (filters: Omit<ShopArtworksFiltersState, 'filterHash'>): string => {
  const serializedFilters = [
    minMaxPriceFilterSerialize,
    orientationFilterSerialize,
    sizeFilterSerialize,
    interestsFilterSerialize,
    countryFilterSerialize,
    disciplinesFilterSerialize,
    tagsFilterSerialize,
  ]
    .map((serializer) => serializer(filters))
    .join('&');

  return serializedFilters;
};

export const getHashForShopArtworksFilters = (
  filters: Omit<ShopArtworksFiltersState, 'filterHash'>,
  customSuffix?: string
): ListingIdentifier<ShopArtworksSortOptions> => {
  const serializedFilters = serializeFilters(filters);
  const hiddenFilters = [filterHashResetMarker, customSuffix ?? 'nosuffix', filters.refresh].join('_');
  const filtersIdentifier = `${filtersMarker}_${serializedFilters}_${hiddenFilters}`;
  return `${filters.currentSort}_${filtersIdentifier}` as const;
};

export const getSerializedFiltersFromHash = (hash: ListingIdentifier<ShopArtworksSortOptions>): string => {
  const [_, allFilters] = hash.split(`_${filtersMarker}_`);
  const [filters] = allFilters.split(`_${filterHashResetMarker}_`);
  return filters;
};

export const getDefaultShopArtworksFilters = (customSuffix?: string): ShopArtworksFiltersState => {
  const noHashFilterState: Omit<ShopArtworksFiltersState, 'filterHash'> = {
    ...getDefaultRefreshFilter(),
    ...getDefaultMinMaxPriceFilter(),
    ...getDefaultOrientationFilter(),
    ...getDefaultSizeFilter(),
    ...getDefaultInterestFilter(),
    ...getDefaultCountryFilter(),
    ...getDefaultDisciplineFilter(),
    ...getDefaultTagFilter(),
    currentSort: getDefaultShopArtworksVirtualList().currentSort,
  };
  const filtersState: ShopArtworksFiltersState = {
    ...noHashFilterState,
    filterHash: getHashForShopArtworksFilters(noHashFilterState, customSuffix),
  };
  return filtersState;
};

export const filterInitialState: ShopArtworksFiltersState = getDefaultShopArtworksFilters();
export const defaultFiltersHashPrefix = filterInitialState.filterHash.split(filterHashResetMarker)[0];

export const filterShopArtworksSlice = createSlice({
  name: 'filter',
  initialState: filterInitialState,
  reducers: {
    RESET_ShopArtworks: (_, action: PayloadAction<{ customSuffix?: string }>) =>
      getDefaultShopArtworksFilters(action.payload.customSuffix),
    REFRESH_ShopArtworks: createRefreshFilterCaseReducer<ShopArtworksFiltersState>(),
    APPLY_ShopArtworks: (state) => {
      state.filterHash = getHashForShopArtworksFilters(state);
    },
    SORT_ShopArtworks: (state, action: PayloadAction<ShopArtworksSortOptions>) => ({
      ...state,
      currentSort: action.payload,
    }),
    TOGGLE_MIN_MAX_PRICE_ShopArtworks: createToggleMinMaxPriceCaseReducer<ShopArtworksFiltersState>(),
    TOGGLE_ORIENTATION_ShopArtworks: createToggleOrientationCaseReducer<ShopArtworksFiltersState>(),
    TOGGLE_SIZE_ShopArtworks: createToggleSizeCaseReducer<ShopArtworksFiltersState>(),
    TOGGLE_INTERESTS_ShopArtworks: createToggleInterestCaseReducer<ShopArtworksFiltersState>(),
    TOGGLE_DISCIPLINES_ShopArtworks: createToggleDisciplineCaseReducer<ShopArtworksFiltersState>(),
    SELECT_COUNTRY_ShopArtworks: createSelectCountryCaseReducer<ShopArtworksFiltersState>(),
    SELECT_TAGS_ShopArtworks: createSelectTagCaseReducer<ShopArtworksFiltersState>(),
    CLEAR_DISCIPLINES_ShopArtworks: (state) => {
      const newState = { ...state };
      return { ...newState, disciplines: getDefaultDisciplineFilter().disciplines };
    },
    DESERIALIZE_FILTERS_ShopArtworks: (state, action: PayloadAction<string>) => {
      let newState = { ...state };
      [
        minMaxPriceFilterDeserialize,
        orientationFilterDeserialize,
        sizeFilterDeserialize,
        interestsFilterDeserialize,
        countryFilterDeserialize,
        disciplinesFilterDeserialize,
        tagsFilterDeserialize,
      ]
        .map((deserializer) => deserializer(action.payload))
        .forEach((deserializedFilter) => {
          newState = {
            ...newState,
            ...deserializedFilter,
          };
        });
      newState.filterHash = getHashForShopArtworksFilters(newState);
      return newState;
    },
  },
});
