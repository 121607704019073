import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  rectangleContainer1: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    minWidth: '150px',
    height: '30px',
    borderRadius: '100px',
    boxSizing: 'border-box',
    fontSize: theme.typography.pxToRem(18),
    [theme.breakpoints.down('xs')]: {
      fontSize: theme.typography.pxToRem(16),
      minWidth: '130px',
    },
  },
  rectangleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: '44px',
    borderRadius: '100px',
    boxSizing: 'border-box',
  },
  innerContainer1: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    paddingRight: '15px',
  },
  innerContainer: {
    paddingLeft: '15px',
    paddingRight: '5px',
    fontSize: theme.typography.pxToRem(18),
  },
  circleContainer: {
    height: '35px',
    width: '35px',
    borderRadius: '100%',
    textAlign: 'center',
  },
  centerNumberContainer: {
    height: '35px',
    width: '32px',
    fontSize: theme.typography.pxToRem(18),
  },
  roundCircleColor: {
    backgroundColor: theme.palette.common.black === 'black' ? '#F4F4F4' : '#FFFFFF',
  },
  rectangleColor: {
    backgroundColor: theme.palette.common.black === 'black' ? '#FFFFFF' : '#969696',
  },
  whiteRectangleColor: {
    backgroundColor: '#F7F7F7',
  },
  innerContainerText: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  addFontFamilly: {},

  addBorderOnhover: {
    border: '1.5px solid transparent',
    cursor: 'pointer',
    '&:hover': {
      display: 'inline-block',
      background: '-webkit-linear-gradient(96deg, #68CADB 23%, #7723E5 39%, #EE49BB 52.15%, #FFCF53 100%)',
      MozBackgroundOrigin: 'border',
      backgroundOrigin: 'border-box',
      borderRadius: '100px',
    },
  },
  addBorder: {
    display: 'inline-block',
    background: '-webkit-linear-gradient(96deg, #68CADB 23%, #7723E5 39%, #EE49BB 52.15%, #FFCF53 100%)',
    MozBackgroundOrigin: 'border',
    backgroundOrigin: 'border-box',
    border: '1.5px solid transparent',
    borderRadius: '100px',
    cursor: 'pointer',
  },
  button: {
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.25)',
    borderRadius: '100px',
    width: theme.spacing(23),
    border: '1.5px solid transparent',
    [theme.breakpoints.down('xs')]: {
      fontSize: theme.typography.pxToRem(14),
      width: 'auto',
    },
  },
}));
