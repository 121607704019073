import useAppSelector from 'common/hooks/useAppSelector';
import React from 'react';
import followButtonPredefines, { FollowButtonPredefines } from '../config/followButtonPredefines';
import useRecognizeButtonLogic, { UseRecognizeButtonLogicPropsType } from '../hooks/useRecognizeButtonLogic';
import useShouldShowRecognizeButton from '../hooks/useShouldShowRecognizeButton';
import FollowButtonBase, { FollowButtonBaseProps } from './FollowButtonBase';

export type FollowButtonProps = UseRecognizeButtonLogicPropsType &
  Pick<FollowButtonBaseProps, 'predefine' | 'isDarkMode' | 'withoutTransition'> & {
    invertWhenRecognizing?: boolean;
    hideRecognitionButton?: boolean;
    customStyles?: string;
  };

const FollowButton: React.FC<FollowButtonProps> = ({
  predefine,
  isDarkMode,
  withoutTransition,
  invertWhenRecognizing,
  hideRecognitionButton,
  customStyles,
  ...logicProps
}) => {
  const { isRecognizing, clickHandler, isTrigger } = useRecognizeButtonLogic(logicProps);
  const predefined = followButtonPredefines[predefine];
  const variant = isRecognizing ? predefined.FOLLOWING : predefined.FOLLOW;
  const shouldShowRecognizeButton = useShouldShowRecognizeButton(logicProps.targetContextId);
  const appDarkMode = useAppSelector((state) => state.darkMode.isDarkMode);
  let finalDarkMode = isDarkMode ?? appDarkMode;
  if (invertWhenRecognizing !== undefined && isRecognizing === invertWhenRecognizing) {
    finalDarkMode = !finalDarkMode;
  }

  if (!shouldShowRecognizeButton || hideRecognitionButton) {
    return null;
  }

  return (
    <FollowButtonBase
      variant={variant}
      predefine={predefine}
      onClick={clickHandler}
      isDarkMode={finalDarkMode}
      withoutTransition={withoutTransition ?? !isTrigger}
      customStyles={customStyles}
    />
  );
};

// only runs logic to check if the button should be shown or not
export const FollowButtonPreview: React.FC<{
  isRecognizing: boolean;
  predefine: FollowButtonPredefines;
  targetContextId?: number;
  isDarkMode?: boolean;
}> = ({ predefine, isRecognizing, targetContextId, isDarkMode }) => {
  const predefined = followButtonPredefines[predefine];
  const variant = isRecognizing ? predefined.FOLLOWING : predefined.FOLLOW;
  const shouldShowRecognizeButton = useShouldShowRecognizeButton(targetContextId);
  const appDarkMode = useAppSelector((state) => state.darkMode.isDarkMode);
  const finalDarkMode = isDarkMode ?? appDarkMode;

  if (!shouldShowRecognizeButton) {
    return null;
  }
  return <FollowButtonBase variant={variant} predefine={predefine} isDarkMode={finalDarkMode} withoutTransition />;
};

export default FollowButton;
