import useAnalytics from 'common/features/Analytics/Analytics';
import { Enum_Pseudo_User_Action_Enum } from 'common/schema/commonSchemaRemoteTypes';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { userTrapActions, UserTrapView } from 'store/slices/userTrapSlice';

type RecognizeUserTrapCallback = (targetContextId: number, targetExploreImages?: string[]) => void;

const useRecognizeUserTrapCallback = (): RecognizeUserTrapCallback => {
  const dispatch = useDispatch();
  const analytics = useAnalytics();
  const { pathname } = useLocation();

  return useCallback<RecognizeUserTrapCallback>(
    (targetContextId, targetExploreImages) => {
      dispatch(
        userTrapActions.SET_USER_TRAP_DATA({
          data: {
            artistContextId: targetContextId,
            artworkExploreImages: targetExploreImages,
          },
        })
      );
      dispatch(userTrapActions.OPEN(Enum_Pseudo_User_Action_Enum.Recognize));
      dispatch(userTrapActions.SET_CURRENT_VIEW(UserTrapView.SIMPLE_CONTENT));

      analytics.postData('STARTED', 'RECOGNIZE', 'CREATE_ACCOUNT_CLICK_CTA', {
        recognize_target_context_id: targetContextId,
      });
      analytics.postData('STARTED', 'GUEST_MODALS', 'OPEN_FOLLOW', {
        tryToFollowContextId: targetContextId,
        currentPage: pathname,
        isGuestUser: true,
      });
    },
    [dispatch]
  );
};

export default useRecognizeUserTrapCallback;
