import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GetExploreArtworkInfoQuery } from 'common/schema/commonSchemaRemoteOperationTypes';
import { Maybe, Scalars } from 'common/schema/commonSchemaRemoteTypes';

export type ArtworkCardDataType = {
  artist: GetExploreArtworkInfoQuery['artists'][0];
  artwork: GetExploreArtworkInfoQuery['artworks'][0] & { originalArtworkId?: Maybe<number> | undefined };
};

export const artworkCardDataTypeGuard = {
  isArtist: (data: unknown): data is ArtworkCardDataType['artist'] => {
    if (!data) {
      return false;
    }
    const artist = data as ArtworkCardDataType['artist'];
    return !!artist.contextId && !!artist.profileId;
  },
  isArtwork: (data: unknown): data is ArtworkCardDataType['artwork'] => {
    if (!data) {
      return false;
    }
    const artwork = data as ArtworkCardDataType['artwork'];
    return !!artwork.artworkId && !!artwork.artistContextId;
  },
};

type ArtworkCardEntities = Record<Scalars['bigint'], ArtworkCardDataType>;

const initialState: ArtworkCardEntities = {};

export const artworkCardsSlice = createSlice({
  name: 'artworkCardsCache',
  initialState,
  reducers: {
    UPDATE_ARTWORK_CARDS: (state, action: PayloadAction<ArtworkCardDataType[]>) => {
      action.payload.forEach((card) => {
        if (!card.artwork.artworkId) {
          return;
        }
        state[card.artwork.artworkId] = card;
      });
    },
  },
});
