import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  Enum_Billing_Subscription_Period_Enum,
  Enum_Billing_Subscription_Product_Type_Enum,
  Enum_Billing_Subscription_Status_Enum,
} from 'common/schema/commonSchemaRemoteTypes';

// https://docs.stripe.com/error-codes
const HandledPaymentFailedErrorCodes = ['card_declined', 'expired_card'] as const;
export function isHandledPaymentErrorCode(
  errorCode: string
): errorCode is (typeof HandledPaymentFailedErrorCodes)[number] {
  return HandledPaymentFailedErrorCodes.includes(errorCode as any);
}
export type PaymentFailedErrorCode = (typeof HandledPaymentFailedErrorCodes)[number] | 'other';

export const HandledCardDeclineCodes = ['card_expired'] as const;
export function isHandledDeclineCode(declineCode: string): declineCode is (typeof HandledCardDeclineCodes)[number] {
  return HandledCardDeclineCodes.includes(declineCode as any);
}
export type CardDeclineCode = (typeof HandledCardDeclineCodes)[number] | 'other';

export type SubscriptionPrice = {
  amount: number;
  currency: string;
};
export type SubscriptionPeriod = {
  nextInvoiceDate: Date;
  recurringInterval: Enum_Billing_Subscription_Period_Enum;
};
export interface SubscriptionStateInfo {
  subscriptionId: number;
  status: Enum_Billing_Subscription_Status_Enum;
  lastFailedPayment?: {
    date: Date;
    errorCode: PaymentFailedErrorCode;
    declineCode: CardDeclineCode;
  };
  nextBillingDate?: Date;
  period: SubscriptionPeriod;
  subscriptionProduct: Enum_Billing_Subscription_Product_Type_Enum;
  price: SubscriptionPrice;
}

export interface DomainStateInfo {
  // TODO: can switch to using an enum here, if we add a cron that also checks that a domain is correctly reachable
  status: 'pending' | 'connected' | 'inactive';
  name: string;
  isRegisteredThroughKaleido: boolean;
}
export interface DomainSetupState {
  connectedDomain?: DomainStateInfo;
  hasDomainSubscription?: boolean;
  domainRenewalSubscription?: SubscriptionStateInfo;
  hostingSubscription?: SubscriptionStateInfo;
  isLoading: boolean;
}

const initialState: DomainSetupState = {
  isLoading: false, // should be true only if it is loading something, not by default;
};

const domainSetupSlice = createSlice({
  initialState,
  name: 'domainSetup',
  reducers: {
    SET_DOMAIN_CONNECTIVITY: (
      oldState,
      action: PayloadAction<DomainSetupState['connectedDomain']>
    ): DomainSetupState => ({
      ...oldState,
      connectedDomain: action.payload,
    }),
    SET_LOADING: (oldState, action: PayloadAction<boolean>): DomainSetupState => ({
      ...oldState,
      isLoading: action.payload,
    }),
    SET_SUBSCRIPTION_STATUSES: (
      oldState,
      action: PayloadAction<Pick<DomainSetupState, 'domainRenewalSubscription' | 'hostingSubscription'>>
    ): DomainSetupState => ({
      ...oldState,
      ...action.payload,
    }),
    SET_DOMAIN_NAME: (oldState, action: PayloadAction<string>): DomainSetupState => {
      if (!oldState.connectedDomain) {
        return oldState;
      }
      return {
        ...oldState,
        connectedDomain: {
          ...oldState.connectedDomain,
          name: action.payload,
        },
      };
    },
    SET_HAS_DOMAIN_SUBSCRIPTION: (oldState, action: PayloadAction<boolean>): DomainSetupState => ({
      ...oldState,
      hasDomainSubscription: action.payload,
    }),
  },
});

export const domainSetupActions = domainSetupSlice.actions;
export const domainSetupReducer = domainSetupSlice.reducer;
