import { createSlice } from '@reduxjs/toolkit';

export type Invitation = {
  invitationId: number;
  avatar: string;
  name: string;
  email: string;
  preferredLanguage: string;
};

export interface Invitations {
  pendingInvitations: Invitation[];
  acceptedInvitations: Invitation[];
}

const initialState: Invitations = {
  pendingInvitations: [],
  acceptedInvitations: [],
};

const invitationsSlice = createSlice({
  name: 'invitations',
  initialState,
  reducers: {
    SET_PENDING_INVITATIONS: (
      state,
      action: {
        payload: {
          pendingInvitations: Invitation[];
        };
      }
    ) => {
      state.pendingInvitations = action.payload.pendingInvitations;
    },
    ADD_NEW_PENDING_INVITATION: (
      state,
      action: {
        payload: {
          invitation: Invitation;
        };
      }
    ) => {
      state.pendingInvitations = [action.payload.invitation, ...state.pendingInvitations];
    },
    REMOVE_PENDING_INVITATION: (
      state,
      action: {
        payload: {
          invitationId: number;
        };
      }
    ) => {
      state.pendingInvitations = state.pendingInvitations.filter(
        (invitation) => invitation.invitationId !== action.payload.invitationId
      );
    },
    SET_ACCEPTED_INVITATIONS: (
      state,
      action: {
        payload: {
          acceptedInvitations: Invitation[];
        };
      }
    ) => {
      state.acceptedInvitations = action.payload.acceptedInvitations;
    },
  },
});

export const invitationsActions = invitationsSlice.actions;
export default invitationsSlice;
