import useAppSelector from 'common/hooks/useAppSelector';
import { useEffect, useMemo } from 'react';
import useDebouncedState from 'common/hooks/utils/useDebouncedState';
import { RecognitionsFiltersHash } from '../store/list.types';
import { RecognitionsFiltersState } from '../store/filter.types';

// exported for use in the initial state
export const filtersHash = (state: RecognitionsFiltersState, suffix: string | number = ''): RecognitionsFiltersHash =>
  // using template string to match typing
  `${state.currentSort}_${[suffix].join('_')}` as const;

export const useRecognitionsFiltersIdentifier = (contextId?: string | number): RecognitionsFiltersHash => {
  const filters = useAppSelector((store) => store.myRecognitions.filter);
  const currentIdentifier = useMemo(() => filtersHash(filters, contextId), [filters, contextId]);
  const [appliedIdentifier, setAppliedIdentifier] = useDebouncedState(currentIdentifier, 500);
  useEffect(() => {
    setAppliedIdentifier(currentIdentifier);
  }, [currentIdentifier]);
  return appliedIdentifier;
};
