import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type DarkModeState = {
  themedPagesMounted: number;
  isDarkMode: boolean;
};

const initialState: DarkModeState = {
  themedPagesMounted: 0,
  isDarkMode: false,
};

const darkModeSlice = createSlice({
  initialState,
  name: 'darkMode',
  reducers: {
    MOUNT_THEMED_PAGE: (state) => {
      state.themedPagesMounted += 1;
    },
    UNMOUNT_THEMED_PAGE: (state) => {
      state.themedPagesMounted -= 1;
      if (state.themedPagesMounted <= 0) {
        state.isDarkMode = false;
      }
    },
    SET_DARK_MODE: (state, { payload }: PayloadAction<boolean>) => {
      state.isDarkMode = payload;
    },
  },
});

export const darkModeActions = darkModeSlice.actions;
export default darkModeSlice;
