import { Theme, makeStyles } from '@material-ui/core';

export const useRatingScoreStyles = makeStyles<
  Theme,
  {
    isDarkMode?: boolean;
  }
>(() => ({
  container: {
    display: 'flex',
    gap: '12px',
    width: '100%',
  },
  pendingIcon: ({ isDarkMode }) => ({
    '& > img': {
      filter: isDarkMode ? 'invert(1)' : 'none',
    },
  }),
}));
