import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GetExploreArtistInfoQuery } from 'common/schema/commonSchemaRemoteOperationTypes';
import { Scalars } from 'common/schema/commonSchemaRemoteTypes';

export type ArtistCardDataType = {
  artist: GetExploreArtistInfoQuery['artists'][0];
  artworks: GetExploreArtistInfoQuery['artworks'];
};

type ArtistCardEntities = Record<Scalars['bigint'], ArtistCardDataType>;

const initialState: ArtistCardEntities = {};

export const artistCardsSlice = createSlice({
  name: 'artistCardsCache',
  initialState,
  reducers: {
    UPDATE_ARTIST_CARDS: (state, action: PayloadAction<ArtistCardDataType[]>) => {
      action.payload.forEach((card) => {
        if (!card.artist.contextId) {
          return;
        }
        state[card.artist.contextId] = card;
      });
    },
  },
});
