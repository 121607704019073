import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { ListingDirection, listingDirections } from '../components/listingDirections';
import { listingStatusLoadingIdentifier } from '../components/listingStatus';
import { canLoadMore } from '../components/listLoader';
import { ListReduxFragment } from '../components/listReduxFragment';
import { SortOptionsEnum } from '../VirtualList.config';

export type AppendListedReducer<State extends ListReduxFragment<SortOptionsEnum, unknown>> = CaseReducer<
  State,
  PayloadAction<{
    list: string;
    append: [direction: ListingDirection, identifier: State['identifier'], values: State['data']['cursor'][0][]];
  }>
>;

export const createAppendListedCaseReducer =
  <State extends ListReduxFragment<SortOptionsEnum, unknown>>(
    virtualListIdentifier: string
  ): AppendListedReducer<State> =>
  (state, action) => {
    if (!state.data.listed.status.startsWith(listingStatusLoadingIdentifier)) {
      return;
    }

    const {
      list,
      append: [direction, identifier, values],
    } = action.payload;
    if (list !== virtualListIdentifier) {
      return;
    }
    if (identifier !== state.identifier) {
      console.warn('listed append identifier mismatch, should be cold restarting', identifier, state.identifier);
      return;
    }

    state.data.listed.hasMore = [...state.data.listed.hasMore];

    const directionIdx = listingDirections.indexOf(direction);
    state.data.listed.hasMore[directionIdx] = state.data.listed.batchSize === values.length;

    state.data.cursor[directionIdx] = values[values.length - 1];
    state.data.detailed.hasMore[directionIdx] = state.data.detailed.hasMore[directionIdx] || values.length > 0;

    const appendValues = [
      // backward
      () => undefined,
      // forward
      () => {
        state.data.listed.values = [...state.data.listed.values, ...values];
      },
    ][directionIdx];

    appendValues();

    if (canLoadMore(state.data.listed, 'FORWARD') || canLoadMore(state.data.listed, 'BACKWARD')) {
      state.data.listed.status = 'IDLE';
    } else {
      state.data.listed.status = 'FINISHED';
    }

    if (!canLoadMore(state.data.detailed, 'FORWARD') && !canLoadMore(state.data.detailed, 'BACKWARD')) {
      state.data.detailed.status = 'FINISHED';
    }
  };
