import Snackbar, { SnackbarOrigin } from '@material-ui/core/Snackbar';
import { CheckCircleOutline } from '@material-ui/icons';
import MuiAlert, { Color } from '@material-ui/lab/Alert';
import zIndex from 'common/utils/zIndex';
import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useToastProviderReducer } from './ToastProvider.reducer';

type ExtendedSnackbarProps = {
  newMessage: string;
  newDuration?: number;
  newPosition?: SnackbarOrigin;
  newImage?: React.ReactNode;
  newSeverity?: Color;
  newClassName?: string;
};

export type SnackbarContextType = {
  showInfoSnackbar: (message: string, duration?: number) => void;
  showErrorSnackbar: (message?: string, duration?: number) => void;
  showExtendedOptionsSnackbar: (props: ExtendedSnackbarProps) => void;
  closeAll: () => void;
};

const SnackbarContext = React.createContext<SnackbarContextType>({
  showInfoSnackbar: () => null,
  showErrorSnackbar: () => null,
  showExtendedOptionsSnackbar: () => null,
  closeAll: () => null,
});

export function useSnackbar() {
  return useContext(SnackbarContext);
}

type Props = {
  children: React.ReactNode;
};
const ToastProvider = ({ children }: Props) => {
  const [state, dispatch] = useToastProviderReducer();
  const { t } = useTranslation();

  const showInfoSnackbar = (newMessage: string, newDuration?: number) => {
    dispatch({
      type: 'OPEN',
      payload: {
        position: { horizontal: 'center', vertical: 'bottom' },
        severity: 'success',
        message: newMessage,
        duration: newDuration,
        open: true,
      },
    });
  };

  const showErrorSnackbar: SnackbarContextType['showErrorSnackbar'] = (newMessage, newDuration) => {
    dispatch({
      type: 'OPEN',
      payload: {
        position: { horizontal: 'center', vertical: 'bottom' },
        severity: 'error',
        message: newMessage ?? t('addArtworkErrors.commercial.somethingWentWrong'),
        duration: newDuration || 10000,
        open: true,
      },
    });
  };

  const showExtendedOptionsSnackbar = (props: ExtendedSnackbarProps) => {
    const { newMessage, newDuration, newImage, newPosition, newSeverity, newClassName } = props;
    dispatch({
      type: 'OPEN',
      payload: {
        position: newPosition,
        severity: newSeverity || 'info',
        message: newMessage,
        duration: newDuration || 1500,
        infoToastImage: newImage,
        open: true,
        className: newClassName,
      },
    });
  };

  const handleClose = (_?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    dispatch({ type: 'CLOSE' });
  };

  const closeAll = () => dispatch({ type: 'CLOSE' });

  const snackbarContextValue = useMemo(
    () => ({
      showInfoSnackbar,
      showErrorSnackbar,
      showExtendedOptionsSnackbar,
      closeAll,
    }),
    []
  );

  return (
    <SnackbarContext.Provider value={snackbarContextValue}>
      {children}
      <Snackbar
        open={state.open}
        autoHideDuration={state.duration}
        onClose={handleClose}
        anchorOrigin={state.position}
        className={state.className}
        style={{ zIndex: zIndex.values.SNACKBAR }}
      >
        <MuiAlert
          iconMapping={{
            success: <CheckCircleOutline style={{ fill: '#fff' }} />,
            info: state.infoToastImage,
          }}
          severity={state.severity}
          action={null}
          style={{
            height: 33,
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            zIndex: zIndex.values.SNACKBAR,
          }}
          onClick={handleClose}
        >
          {state.message}
        </MuiAlert>
      </Snackbar>
    </SnackbarContext.Provider>
  );
};

export default ToastProvider;
