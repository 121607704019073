import { combineReducers } from 'redux';
import { recognitionsListSlice } from './list.slice';
import { recognitionsFiltersSlice } from './filter.slice';
import { recognitionsDisplaySlice } from './display.slice';

export const actions = {
  ...recognitionsListSlice.actions,
  ...recognitionsFiltersSlice.actions,
  ...recognitionsDisplaySlice.actions,
};

export const recognitionsV2Slice = {
  recognitionsV2: combineReducers({
    [recognitionsListSlice.name]: recognitionsListSlice.reducer,
    [recognitionsFiltersSlice.name]: recognitionsFiltersSlice.reducer,
    [recognitionsDisplaySlice.name]: recognitionsDisplaySlice.reducer,
  }),
};
