import { CountryFilterState } from 'common/features/Filters/predefined/Country/store/country.types';
import { InterestsFilterState } from 'common/features/Filters/predefined/Interests/store/interests.types';
import { PriceRangeFilterState } from 'common/features/Filters/predefined/PriceRange/store/priceRange.types';

export const sorts = ['byDate'] as const;
export type RecognitionsSort = (typeof sorts)[0];

export const isRecognitionsSort = (v: string): v is RecognitionsSort => sorts.includes(v as RecognitionsSort);

// currently auto-applies on every change
// maybe todo: add flag to apply on demand
export type RecognitionsFiltersState = InterestsFilterState &
  PriceRangeFilterState &
  CountryFilterState & {
    sort: RecognitionsSort;
  };
