import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GetFavoriteModalDetailsQuery } from 'common/schema/commonSchemaRemoteOperationTypes';
import { Enum_Context_Type_Enum, Scalars } from 'common/schema/commonSchemaRemoteTypes';

export type FavoriteModalCardDataType = {
  user: {
    type: Enum_Context_Type_Enum;
    contextId?: number | null;
    profileId?: number | null;
    title?: string | null;
    handle?: string | null;
    avatar?: number | null;
    firstName?: string | null;
    lastName?: string | null;
    isPublic?: string | null;
  };
  artworks?: GetFavoriteModalDetailsQuery['Artworks'];
};

type FavoriteModalCardEntities = Record<Scalars['bigint'], FavoriteModalCardDataType>;

const initialState: FavoriteModalCardEntities = {};

export const favoriteModalCardsSlice = createSlice({
  name: 'favoriteModalCardsCache',
  initialState,
  reducers: {
    UPDATE_FAVORITE_MODAL_CARDS: (state, action: PayloadAction<FavoriteModalCardDataType[]>) => {
      action.payload.forEach((card) => {
        if (!card.user.contextId || state[card.user.contextId]) {
          return;
        }
        state[card.user.contextId] = card;
      });
    },
  },
});
