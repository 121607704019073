import { LazyQueryResult, QueryLazyOptions } from '@apollo/client';
import { Artwork_Bool_Exp, Exact, Maybe } from 'common/schema/commonSchemaRemoteTypes';
import {
  GetDocumentsAllArtistUniqueArtworksQuery,
  GetManageArtAllArtistArtworksQuery,
} from 'common/schema/commonSchemaRemoteOperationTypes';
import { MyDocumentsListElementProps } from '../components/DocumentsListing/MyDocumentsListElement';

export enum Enum_Document_Type_Enum {
  Certificate = 'CERTIFICATE',
  Other = 'OTHER',
}

export enum Enum_Generate_New_Certificate_Modal_Step_Type_Enum {
  SELECT_ARTWORKS = 'SELECT_ARTWORKS',
  CONFIRM_CERTIFICATES = 'CONFIRM_CERTIFICATES',
  GENERATING = 'GENERATING',
  NO_ARTWORKS = 'NO_ARTWORKS',
}

export type ArtworkToTableType = MyDocumentsListElementProps;

export type DocumentsReadyQueueType = {
  pieceId: number;
  url: string;
};

export type fetchAllArtworksFunctionType = (
  options?:
    | QueryLazyOptions<Exact<{ extra_where?: Maybe<Artwork_Bool_Exp> | undefined; contextId: number }>>
    | undefined
) => Promise<
  LazyQueryResult<
    GetManageArtAllArtistArtworksQuery,
    Exact<{ extra_where?: Maybe<Artwork_Bool_Exp> | undefined; contextId: number }>
  >
>;

export type fetchUniqueArtworksOnlyFunctionType = (
  options?:
    | QueryLazyOptions<Exact<{ extra_where?: Maybe<Artwork_Bool_Exp> | undefined; contextId: number }>>
    | undefined
) => Promise<
  LazyQueryResult<
    GetDocumentsAllArtistUniqueArtworksQuery,
    Exact<{ extra_where?: Maybe<Artwork_Bool_Exp> | undefined; contextId: number }>
  >
>;
