import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { GetUserSubscriptionsQuery } from 'common/schema/commonSchemaRemoteOperationTypes';
import {
  Enum_Billing_Subscription_Period_Enum,
  Enum_Billing_Subscription_Status_Enum,
} from 'common/schema/commonSchemaRemoteTypes';

export interface subscriptionsState {
  subscriptions: GetUserSubscriptionsQuery['Billing_subscription'] | [];
  isPremium: boolean;
  subscriptionStatus: Enum_Billing_Subscription_Status_Enum | undefined;
  subscriptionPeriod: Enum_Billing_Subscription_Period_Enum | undefined;
  // TODO: ADD OTHER SUBSCRIPTION RELATED DATA
}

const initialState: subscriptionsState = {
  subscriptions: [],
  isPremium: false,
  subscriptionStatus: undefined,
  subscriptionPeriod: undefined,
};

const subscriptionsSlice = createSlice({
  name: 'subscriptions',
  initialState,
  reducers: {
    SET_SUBSCRIPTIONS: (state, action: PayloadAction<subscriptionsState>) => {
      state.subscriptions = action.payload.subscriptions;
      state.isPremium = action.payload.isPremium;
      state.subscriptionStatus = action.payload.subscriptionStatus;
      state.subscriptionPeriod = action.payload.subscriptionPeriod;
    },
    RESET_SUBSCRIPTIONS: () => initialState,
  },
});

export const subscriptionsActions = subscriptionsSlice.actions;
export default subscriptionsSlice;
