import React, { useMemo } from 'react';
import { Theme, useMediaQuery } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { CDNUrl } from 'utils/constants';
import { useStyles } from './StartImageCard.styles';
import { StartCardCollectorInfo } from './StartCardCollectorInfo/StartCardCollectorInfo';
import { StartCardMiddleSection } from './StartCardMiddleSection/StartCardMiddleSection';
import { StartCardStatsSection } from './StartCardStatsSection/StartCardStatsSection';

type Props = {
  name: string;
  artworks: number;
  artists: number;
  location: string;
  abreviation: string;
  height: number;
  width: number;
};

const path = `${CDNUrl}/images/bgprofileCollector.png`;

export const StartImageCardV2: React.FC<Props> = ({
  abreviation,
  location,
  name,
  artworks,
  artists,
  height,
  width,
}) => {
  const matches = useMediaQuery('(max-width:760px)');
  const { t } = useTranslation();
  const isPhone = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));
  const middleTextLength = t('collectorProfileStrings.profileViewPage.startCard.artCollectionOf').length;
  const classes = useStyles({ matches, middleTextLength });
  const maxNameLength = 13;
  const trimmedName = useMemo(
    () => (name.length <= maxNameLength ? name : `${name.slice(0, maxNameLength - name.length)}...`),
    [name]
  );

  const calcWidth = useMemo<number>(() => (!isPhone ? width : width + 32), [isPhone, width]);

  const calcHeight = useMemo<number>(() => (!isPhone ? height : height + 32), [isPhone, height]);

  return (
    <div
      className={classes.startImageCardContainer}
      style={{
        height: calcHeight,
        width: calcWidth,
        backgroundImage: `url(${path})`,
      }}
    >
      <StartCardCollectorInfo abreviation={abreviation} location={location} />
      <StartCardMiddleSection trimmedName={trimmedName} width={calcWidth} />
      <StartCardStatsSection artworksCount={artworks} artistsCount={artists} />
    </div>
  );
};
