import { filtersHash } from '../hooks/useProfileGalleryFiltersIdentifier';
import { getDefaultFilters } from './filter.defaults';
import {
  ProfileGalleryListState,
  ProfileGalleryListByTitle,
  ListDirection,
  listingDirections,
  ListWindowLoaderStateType,
  ProfileGalleryListById,
  ProfileGalleryListByDate,
} from './list.types';
import { ProfileGalleryCardEventsEnum } from '../components/GalleryArtistsList/ProfileGalleryArtistsListElement';

export const STATEMENT_PLACEHOLDER_ID = -1;

export const defaults = {
  byTitle: (): {
    type: 'byTitle';
    data: ProfileGalleryListByTitle;
  } => ({
    type: 'byTitle',
    data: {
      __typename: 'byTitle',
      lastScrollPosition: 0,
      detailedToListedStartOffset: 0,
      cursor: [
        { title: 'zzzzzzzzz', contextId: 0, eventType: '' },
        { title: '', contextId: 0, eventType: '' },
      ],
      listed: {
        batchSize: 64,
        status: 'COLD',
        values: [
          {
            contextId: STATEMENT_PLACEHOLDER_ID,
            title: ProfileGalleryCardEventsEnum.STATEMENT,
            eventType: ProfileGalleryCardEventsEnum.STATEMENT,
          },
        ],
        hasMore: [false, false],
      },
      detailed: {
        batchSize: 16,
        status: 'COLD',
        values: [STATEMENT_PLACEHOLDER_ID],
        hasMore: [false, false],
      },
    },
  }),
  byId: (): {
    type: 'byId';
    data: ProfileGalleryListById;
  } => ({
    type: 'byId',
    data: {
      __typename: 'byId',
      lastScrollPosition: 0,
      detailedToListedStartOffset: 0,
      cursor: [
        { id: 0, contextId: 0, eventType: '' },
        { id: 0, contextId: 0, eventType: '' },
      ],
      listed: {
        batchSize: 64,
        status: 'COLD',
        values: [
          {
            contextId: STATEMENT_PLACEHOLDER_ID,
            id: STATEMENT_PLACEHOLDER_ID,
            eventType: ProfileGalleryCardEventsEnum.STATEMENT,
          },
        ],
        hasMore: [false, false],
      },
      detailed: {
        batchSize: 16,
        status: 'COLD',
        values: [STATEMENT_PLACEHOLDER_ID],
        hasMore: [false, false],
      },
    },
  }),
  byDate: (): {
    type: 'byDate';
    data: ProfileGalleryListByDate;
  } => ({
    type: 'byDate',
    data: {
      __typename: 'byDate',
      lastScrollPosition: 0,
      detailedToListedStartOffset: 0,
      cursor: [
        { createdAt: '1000-01-01T00:00:00.000000+00:00', contextId: 0, eventType: '' },
        { createdAt: '1000-01-01T00:00:00.000000+00:00', contextId: 0, eventType: '' },
      ],
      listed: {
        batchSize: 64,
        status: 'COLD',
        values: [
          {
            contextId: STATEMENT_PLACEHOLDER_ID,
            createdAt: undefined,
            eventType: ProfileGalleryCardEventsEnum.STATEMENT,
          },
        ],
        hasMore: [false, false],
      },
      detailed: {
        batchSize: 16,
        status: 'COLD',
        values: [STATEMENT_PLACEHOLDER_ID],
        hasMore: [false, false],
      },
    },
  }),
};

const defaultFilters = getDefaultFilters();
export const listInitialState: ProfileGalleryListState = {
  identifier: filtersHash(defaultFilters),
  ...defaults[defaultFilters.sort](),
};

export const hasMore = (loader: ListWindowLoaderStateType<unknown, unknown>, direction: ListDirection): boolean =>
  loader.hasMore[listingDirections.indexOf(direction)];
