import { OrientationEnum, OrientationFilterState } from './orientation.types';

export const getDefaultOrientationFilter = (): OrientationFilterState => ({
  orientation: {
    [OrientationEnum.ALL]: true,
    [OrientationEnum.SQUARE]: false,
    [OrientationEnum.LANDSCAPE]: false,
    [OrientationEnum.PORTRAIT]: false,
  },
});
export const defaultOrientationFilter = getDefaultOrientationFilter();
