import { useMemo, useState } from 'react';
import { isVuforiaScoreGenerated } from 'stacks/ShowcaseNewV2/context/ShowcaseProvider/utils/helpers';
import { Maybe } from 'common/schema/commonSchemaRemoteTypes';
import RatingScore from '../RatingScore/RatingScore';
import ArRatingInfoModal from './ArRatingInfoModal';

type ArtworkArRatingProps = {
  vuforiaInfo: {
    vuforiaRating?: Maybe<number>;
    targetId?: Maybe<string>;
    pendingVuforiaImagePath?: Maybe<string>;
  };
  overrideArText?: string;
  arIconContainerOverrideClassName?: string;
  ratingScoreOverrideClassName?: string;
  showInfoIcon?: boolean;
};

const ArtworkArRating: React.FC<ArtworkArRatingProps> = ({
  vuforiaInfo,
  overrideArText,
  showInfoIcon,
  arIconContainerOverrideClassName,
  ratingScoreOverrideClassName,
}) => {
  const { vuforiaRating, targetId, pendingVuforiaImagePath } = vuforiaInfo;
  const [open, setOpen] = useState(false);

  const { score, isScoreGenerated } = useMemo(
    () => ({
      score: vuforiaRating,
      isScoreGenerated: isVuforiaScoreGenerated(vuforiaRating, targetId, pendingVuforiaImagePath),
    }),
    [vuforiaRating, targetId, pendingVuforiaImagePath]
  );

  const rate = useMemo(
    () => (isScoreGenerated ? Math.min(5, Math.max(0, score ?? 0)) : null),
    [isScoreGenerated, score]
  );

  return (
    <>
      <RatingScore
        score={rate}
        maxScore={5}
        ratingText={overrideArText}
        showInfoIcon={showInfoIcon}
        onClickInfoIcon={() => setOpen(true)}
        arIconContainerOverrideClassName={arIconContainerOverrideClassName}
        ratingScoreOverrideClassName={ratingScoreOverrideClassName}
      />
      <ArRatingInfoModal open={open} onClose={() => setOpen(false)} score={rate} />
    </>
  );
};

export default ArtworkArRating;
