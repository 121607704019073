import { useContext, useEffect, useMemo } from 'react';
import { useGetPrintSizesByArtworkIdLazyQuery } from 'common/schema/commonSchemaRemoteGraphqlQueries';
import { useTranslation } from 'react-i18next';
import { GetExploreArtworkInfoQuery } from 'common/schema/commonSchemaRemoteOperationTypes';
import useApolloErrorHandler from 'common/features/ErrorHandling/hooks/useApolloErrorHandler';
import { UnitConversionContext } from 'common/contexts/UnitConversionProvider';
import { getArtworkCardDetailsString, getPrintCardDetailsString } from './cardStringGetters';

export type GetArtworkDetailsProps = Pick<
  GetExploreArtworkInfoQuery['artworks'][0],
  'editionType' | 'discipline' | 'height' | 'width' | 'artworkId' | 'measuringUnit'
>;

// TODO : move the query to the parent components
const DISABLE_PRINT_DETAILS = false;

const useGetArtworkDetails = (artwork?: GetExploreArtworkInfoQuery['artworks'][0] | GetArtworkDetailsProps): string => {
  const [getSizes, { data, error }] = useGetPrintSizesByArtworkIdLazyQuery();
  const { t } = useTranslation();
  useApolloErrorHandler(error);

  useEffect(() => {
    if (artwork?.editionType !== 'STANDALONE_EDITION' || DISABLE_PRINT_DETAILS) {
      return;
    }
    getSizes({
      variables: {
        artworkId: artwork?.artworkId ?? 0,
      },
    });
  }, [artwork]);

  const convert = useContext(UnitConversionContext).transformUnits;
  const details = useMemo(() => {
    if (artwork?.editionType !== 'STANDALONE_EDITION') {
      return getArtworkCardDetailsString(
        t,
        artwork?.discipline,
        artwork?.width,
        artwork?.height,
        artwork?.measuringUnit
      );
    }
    return getPrintCardDetailsString(t, data?.editions[0]?.edition[0]?.sizes);
  }, [artwork, t, data, convert]);

  return details;
};

export default useGetArtworkDetails;
