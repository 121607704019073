import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type InitialStateType = {
  isLearnMoreDialogOpen: boolean;
  isFTUDialogOpen: boolean;
  isDotsDialogOpen: boolean;
};

const initialState: InitialStateType = {
  isLearnMoreDialogOpen: false,
  isFTUDialogOpen: false,
  isDotsDialogOpen: false,
};

export const consumerProductFTuSlice = createSlice({
  name: 'consumerProductFTU',
  initialState,
  reducers: {
    OPEN_LEARN_MORE_DIALOG: (
      state,
      action: PayloadAction<{
        isLearnMoreDialogOpen: boolean;
      }>
    ) => {
      state.isLearnMoreDialogOpen = action.payload.isLearnMoreDialogOpen;
    },
    OPEN_DOTS_DIALOG: (
      state,
      action: PayloadAction<{
        isDotsDialogOpen: boolean;
      }>
    ) => {
      state.isDotsDialogOpen = action.payload.isDotsDialogOpen;
    },
    OPEN_FTU_DIALOG: (
      state,
      action: PayloadAction<{
        isFTUDialogOpen: boolean;
      }>
    ) => {
      state.isFTUDialogOpen = action.payload.isFTUDialogOpen;
    },
  },
});

export const { actions } = consumerProductFTuSlice;
