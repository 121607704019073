import { Enum_Transaction_Status_Enum } from 'common/schema/commonSchemaRemoteTypes';
import { ArtworksListType } from 'store/slices/artworkListModifiers';
import {
  AppliedListingModifier,
  ARTWORK_QUERY_CONDITION,
  FILTERS_DEFAULT_VALUE,
  isOptionType,
  ListingModifier,
  ListingModifierButtonsConfig,
  ListingModifierOption,
} from './types';

// can map to translation keys
enum KnownOptions {
  AVAILABILITY_ALL = 'filterStrings.avalability.all',
  AVAILABILITY_AVAILABLE = 'filterStrings.avalability.available',
  AVAILABILITY_UNAVAILABLE = 'artwork.availability.UNAVAILABLE',
  AVAILABILITY_RESERVED = 'filterStrings.avalability.reserved',
  AVAILABILITY_COLLECTED = 'landingPageStrings.footer.availabilityStrings.collected',
}

type AvailabilityVariant = ArtworksListType;
type AvailabilityVariantOptions = {
  [key in AvailabilityVariant]: ListingModifierOption[];
};

const variants: AvailabilityVariantOptions = {
  SHOWROOM: [
    {
      value: 2,
      name: KnownOptions.AVAILABILITY_RESERVED,
      key: 'ARTWORK_QUERY_CONDITION',
      payload: { is_available_sale: { _eq: false } },
    },
  ],
  ARTIST_PROFILE: [
    {
      value: 3,
      name: KnownOptions.AVAILABILITY_UNAVAILABLE,
      key: 'ARTWORK_QUERY_CONDITION',
      payload: {
        _and: [
          { is_available_sale: { _eq: false } },
          {
            _not: {
              transaction_histories: {
                _and: [{ status: { _eq: Enum_Transaction_Status_Enum.Accepted } }, { trashed_at: { _is_null: true } }],
              },
            },
          },
        ],
      },
    },
    {
      value: 2,
      name: KnownOptions.AVAILABILITY_COLLECTED,
      key: 'ARTWORK_QUERY_CONDITION',
      payload: {
        transaction_histories: {
          status: { _eq: Enum_Transaction_Status_Enum.Accepted },
          trashed_at: { _is_null: true },
        },
      },
    },
  ],
  FAVORITES: [
    {
      value: 2,
      name: KnownOptions.AVAILABILITY_COLLECTED,
      key: 'ARTWORK_QUERY_CONDITION',
      payload: {
        transaction_histories: {
          status: { _eq: Enum_Transaction_Status_Enum.Accepted },
          trashed_at: { _is_null: true },
        },
      },
    },
  ],
  COLLECTOR_PROFILE: [],
};

export const getAvailabilityFilter = (variant?: AvailabilityVariant): ListingModifier => ({
  type: 'AVAILABILITY',
  allOptions: [
    {
      value: FILTERS_DEFAULT_VALUE,
      name: KnownOptions.AVAILABILITY_ALL,
      key: 'ARTWORK_QUERY_CONDITION',
      payload: {},
    },
    {
      value: 1,
      name: KnownOptions.AVAILABILITY_AVAILABLE,
      key: 'ARTWORK_QUERY_CONDITION',
      payload: { is_available_sale: { _eq: true } },
    },
    ...(variant ? variants[variant] : []),
  ],
});

export const availabilityButtonsDefaultConfig: ListingModifierButtonsConfig = {
  [KnownOptions.AVAILABILITY_ALL]: {},
  [KnownOptions.AVAILABILITY_AVAILABLE]: { dot: 'AVAILABLE' },
  [KnownOptions.AVAILABILITY_UNAVAILABLE]: { dot: 'UNAVAILABLE' },
  [KnownOptions.AVAILABILITY_COLLECTED]: { dot: 'COLLECTED' },
  [KnownOptions.AVAILABILITY_RESERVED]: {},
};
export const availabilityButtonsListTypeConfig: {
  [key in ArtworksListType]: ListingModifierButtonsConfig;
} = {
  SHOWROOM: { ...availabilityButtonsDefaultConfig },
  ARTIST_PROFILE: { ...availabilityButtonsDefaultConfig },
  COLLECTOR_PROFILE: { ...availabilityButtonsDefaultConfig },
  FAVORITES: { ...availabilityButtonsDefaultConfig },
};

export const getAppliedShowroomAvailabilityFilter = (
  variant?: AvailabilityVariant
): AppliedListingModifier<ARTWORK_QUERY_CONDITION> => ({
  ...getAvailabilityFilter(variant),
  selectionType: 'SINGLE',
  selectedOptions: [FILTERS_DEFAULT_VALUE],
  flattenSelectedOptions: (options, selectedOptions) => {
    const flattenedOption = options.find((option) => selectedOptions.includes(option.value));
    if (!flattenedOption || !isOptionType<ARTWORK_QUERY_CONDITION>(flattenedOption, 'ARTWORK_QUERY_CONDITION')) {
      throw new Error('Wrong option type');
    }
    return flattenedOption;
  },
});
