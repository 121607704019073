import { createSlice } from '@reduxjs/toolkit';
import { Enum_Art_Type_Enum } from 'common/schema/commonSchemaRemoteTypes';

export const artworkTypeSlice = createSlice({
  name: 'artworkType',
  initialState: {
    artworkType: Enum_Art_Type_Enum.Digital,
  },
  reducers: {
    SET_ARTWORK_TYPE: (state, action) => {
      state.artworkType = action.payload;
    },
  },
});
export const artworkTypeSliceActions = artworkTypeSlice.actions;
export default artworkTypeSlice;
