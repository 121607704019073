import firebase from 'firebase/app';
import { AUTH_PUBLISHER, FirebaseEmailCreds, FirebaseTokenLoginCreds } from '../../commons/types';
import { getProxyHandler } from '../../commons/utils';
import { AuthCallbacks, updateAuthState } from '../AuthHandler';
import Firebase from './FirebaseSetup';
import { FirebaseStateProps, ThirdPartyProviderId } from './types';
import { updateFirebaseJWTandClaims } from './utils';

const FirebaseState: FirebaseStateProps = new Proxy(
  {
    account: null,
    jwt: null,
    claims: null,
    jwtExpirationDate: null,
    firebaseUserId: null,
    firebaseUserEmail: null,
    /**
     * Used for Third Party Sign In. If true, we skip updating the JWT and Claims in onAuthStateChanged and instead, do it in the signInWithCredential after the FinalizeThirdPartySignIn mutation
     *
     * For Third party sign in, the mutation FinalizeThirdPartySignIn sets the claims on BE.
     * If we try to call updateFirebaseJWTandClaims before this mutation is done, the app won't find the userId and will crash
     * In case of signInWithRedirect, we can call finalizeThirdPartySignIn in onAuthStateChanged before the updateFirebaseJWTandClaims,
     * but for signInWithCredential, we need to skip the updateFirebaseJWTandClaims in onAuthStateChanged and do it after the mutation is done
     */
    skipUpdateFirebaseJWTAndClaims: false,
  },
  getProxyHandler(AUTH_PUBLISHER.FIREBASE_HANDLER)
);

export const FirebaseCallbacks = {
  signInWithEmailAndPassword: async ({ email, password }: FirebaseEmailCreds): Promise<void> => {
    // This should never happen, but just in case, if the user is already logged in, we just update the JWT and Claims
    // Maybe add this to other calls as well?
    if (FirebaseState.account) {
      // TODO: should probably await here
      updateFirebaseJWTandClaims();
      return;
    }
    const firebaseUserCredential = await Firebase.signInWithEmailAndPassword(email, password);
    FirebaseState.account = firebaseUserCredential.user;
    // to await the jwt during register
    await updateFirebaseJWTandClaims();
  },
  signInWithCustomToken: async ({ firebaseToken }: FirebaseTokenLoginCreds): Promise<void> => {
    const firebaseUserCredential = await Firebase.signInWithCustomToken(firebaseToken);
    FirebaseState.account = firebaseUserCredential.user;
  },
  signInWithRedirect: async (providerId: ThirdPartyProviderId): Promise<void> => {
    const provider =
      providerId === ThirdPartyProviderId.GOOGLE
        ? new firebase.auth.GoogleAuthProvider()
        : new firebase.auth.OAuthProvider(providerId);
    await Firebase.signInWithRedirect(provider);
  },
  signInWithCredential: async (token: string): Promise<void> => {
    const authCredential = firebase.auth.GoogleAuthProvider.credential('', token);

    // Skip updateFirebaseJWTandClaims on AuthStateChanged since finalizeThirdPartySignIn is not done
    FirebaseState.skipUpdateFirebaseJWTAndClaims = true;
    const userCredential = await Firebase.signInWithCredential(authCredential);
    FirebaseState.skipUpdateFirebaseJWTAndClaims = false;

    await AuthCallbacks.finalizeThirdPartySignIn(userCredential);
    await updateFirebaseJWTandClaims();
  },
  signOut: async (): Promise<void> => {
    FirebaseState.account = null;
    FirebaseState.jwt = null;
    FirebaseState.claims = null;
    await Firebase.signOut();
  },
  checkPassword: async (password: string): Promise<boolean> => {
    if (!FirebaseState.account || !FirebaseState.account.email) {
      return false;
    }
    const credential = firebase.auth.EmailAuthProvider.credential(
      FirebaseState.account.email.trim().toLowerCase(),
      password
    );
    await FirebaseState.account.reauthenticateWithCredential(credential);
    return true;
  },
  resetPassword: async (email: string): Promise<void> =>
    await Firebase.sendPasswordResetEmail(email, {
      url: 'https://localhost:3000/',
    }),
  updateEmail: async (newEmail: string): Promise<void> => {
    if (!FirebaseState.account) {
      return;
    }
    await FirebaseState.account.updateEmail(newEmail);
  },
  updatePassword: async (newPassword: string): Promise<void> => {
    if (!FirebaseState.account) {
      return;
    }
    await FirebaseState.account.updatePassword(newPassword);
  },
  isJwtExpired: (): boolean => {
    if (!FirebaseState.jwtExpirationDate) {
      return true;
    }
    const now = Date.now();
    const isExpired = now > FirebaseState.jwtExpirationDate;
    return isExpired;
  },
};

// The watcher for Firebase auth state changes
Firebase.onAuthStateChanged(async (user) => {
  // Get possible redirect result if there is one (used for third party sign in)
  const result = await firebase.auth().getRedirectResult();
  if (result.user) {
    await AuthCallbacks.finalizeThirdPartySignIn(result);
  }

  if (user) {
    FirebaseState.account = user;
    if (!FirebaseState.skipUpdateFirebaseJWTAndClaims) {
      await updateFirebaseJWTandClaims();
    }
  }

  updateAuthState();
});

export default FirebaseState;
