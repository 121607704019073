import { GetFeaturedCollectionsLazyQueryHookResult } from 'common/schema/commonSchemaRemoteGraphqlQueries';
import {
  GetFeaturedCollectionsQuery,
  GetFeaturedCollectionsQueryVariables,
} from 'common/schema/commonSchemaRemoteOperationTypes';
import { CollectionsListType } from 'store/slices/collectionListModifiers';
import { Artist_Collection_Bool_Exp, Artwork } from '../../../schema/commonSchemaRemoteTypes';

export interface FilteredCollectionsQueryHandlers {
  [CollectionsListType.COLLECTIONS]: {
    queryHandler: GetFeaturedCollectionsLazyQueryHookResult;
    queryArtworkType: GetFeaturedCollectionsQuery['Artist_Collection'][0];
    queryVariables: Omit<GetFeaturedCollectionsQueryVariables, 'where'>;
  };
}

export const FILTERS_DEFAULT_VALUE = 0;

type BaseOptionType = {
  name: string;
  value: number;
  key: string;
  payload: unknown;
};

export type BaseOptionValueType = BaseOptionType['value'];

export type COLLECTION_QUERY_CONDITION = BaseOptionType & {
  key: 'COLLECTION_QUERY_CONDITION';
  payload: Artist_Collection_Bool_Exp;
};

export type COLLECTION_QUERY_CONDITION_IN = BaseOptionType & {
  key: 'COLLECTION_QUERY_CONDITION_IN';
  payload: Artist_Collection_Bool_Exp;
};

export type COLLECTION_LOCAL_CONDITION_PAYLOAD = (
  value: GetFeaturedCollectionsQuery['Artist_Collection'][0]
) => boolean;

export type COLLECTION_LOCAL_CONDITION = BaseOptionType & {
  key: 'COLLECTION_LOCAL_CONDITION';
  payload: COLLECTION_LOCAL_CONDITION_PAYLOAD;
};

export type COLLECTION_LOCAL_SORT_PAYLOAD = (
  a: GetFeaturedCollectionsQuery['Artist_Collection'][0],
  b: GetFeaturedCollectionsQuery['Artist_Collection'][0]
) => number;

export type COLLECTION_LOCAL_SORT = BaseOptionType & {
  key: 'COLLECTION_LOCAL_SORT';
  payload: COLLECTION_LOCAL_SORT_PAYLOAD;
};

export const isOptionType = <T extends BaseOptionType>(option: BaseOptionType, type: T['key']): option is T =>
  option.key === type;

export type filterTypes = 'SORT' | 'CATEGORIES';

export interface FiltersArtworkType extends Partial<Artwork> {
  order?: number;
  price?: number;
}

export type ListingModifier = {
  type: filterTypes;
  allOptions: ListingModifierOption[];
};

export type ListingModifierOption =
  | COLLECTION_QUERY_CONDITION
  | COLLECTION_QUERY_CONDITION_IN
  | COLLECTION_LOCAL_CONDITION
  | COLLECTION_LOCAL_SORT;

export type ListingModifierButton = {
  specialWidth?: 'FULL' | 'HALF';
  image?: string;
};

export type ListingModifierButtonsConfig = { [optionName: string]: ListingModifierButton };

export type AppliedListingModifier<T extends ListingModifierOption = ListingModifierOption> = {
  selectedOptions: ListingModifierOption['value'][];
  selectionType: 'SINGLE' | 'MULTIPLE';
  flattenSelectedOptions: (options: ListingModifierOption[], selectedOptions: ListingModifierOption['value'][]) => T;
} & ListingModifier;
