import { createSlice } from '@reduxjs/toolkit';

export type ShopArtworksDisplayState = {
  isFiltersDialogOpen: boolean;
  isArtworkSizesDialogOpen: boolean;
};

export const getDefaultDisplay = (): ShopArtworksDisplayState => ({
  isFiltersDialogOpen: false,
  isArtworkSizesDialogOpen: false,
});

export const displayInitialState: ShopArtworksDisplayState = getDefaultDisplay();

export const displayShopArtworksSlice = createSlice({
  name: 'display',
  initialState: displayInitialState,
  reducers: {
    OPEN_FILTERS_ShopArtworks: (state) => {
      state.isFiltersDialogOpen = true;
    },
    CLOSE_FILTERS_ShopArtworks: (state) => {
      state.isFiltersDialogOpen = false;
    },
    OPEN_ArtworkSizesDialog: (state) => {
      state.isArtworkSizesDialogOpen = true;
    },
    CLOSE_ArtworkSizesDialog: (state) => {
      state.isArtworkSizesDialogOpen = false;
    },
  },
});
