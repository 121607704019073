import { Scalars } from 'common/schema/commonSchemaRemoteTypes';
import { NearbyGalleriesSort } from './filter.types';

export type NearbyGalleriesFiltersHash = `${NearbyGalleriesSort}_${string}`;

export const listingDirections = ['BACKWARD', 'FORWARD'] as const;
export type ListDirection = (typeof listingDirections)[0] | (typeof listingDirections)[1];

export const loadingIdentifier = 'LOADING' as const;
export type ListLoadingId = typeof loadingIdentifier;

export type ListLoaderStatus = 'COLD' | 'FINISHED' | 'IDLE' | `${ListLoadingId}_${ListDirection}`;

export type ListWindowLoaderStateType<ValuesType, StatusType> = {
  batchSize: number;
  values: Array<ValuesType>;
  status: StatusType;
  hasMore: [boolean, boolean]; // [backward, forward]
};

export type ListLoaderStateType<Typename extends NearbyGalleriesSort, CursorType, IdType = Scalars['bigint']> = {
  __typename: Typename;
  listed: ListWindowLoaderStateType<CursorType, ListLoaderStatus>;
  detailed: ListWindowLoaderStateType<IdType, ListLoaderStatus>;
  cursor: [CursorType, CursorType]; // [backward, forward]
  detailedToListedStartOffset: number;
  lastScrollPosition: number;
};

export type NearbyGalleryListByTitleCursor = {
  contextId: Scalars['bigint'];
  title: Scalars['String'];
};
export type NearbyGalleryListByTitle = ListLoaderStateType<'byTitle', NearbyGalleryListByTitleCursor>;

export type ListActionArgs =
  | [ListDirection, NearbyGalleryListByTitle['__typename'], NearbyGalleryListByTitle['listed']['values']];

export type DetailActionArgs =
  | [ListDirection, NearbyGalleryListByTitle['__typename'], NearbyGalleryListByTitle['detailed']['values']];

export type NearbyGalleriesListState = {
  identifier: NearbyGalleriesFiltersHash;
  type: NearbyGalleriesSort;
} & {
  type: 'byTitle';
  data: NearbyGalleryListByTitle;
};

export type NearbyGalleriesListingCursor = NearbyGalleryListByTitleCursor;

export const isNearbyGalleryListByDateCursor = (
  cursor: NearbyGalleriesListingCursor
): cursor is NearbyGalleryListByTitleCursor => (cursor as NearbyGalleryListByTitleCursor)?.title !== undefined;

export const LOADING_STATES: ListLoaderStatus[] = ['LOADING_BACKWARD', 'LOADING_FORWARD'];
