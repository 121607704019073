import { combineReducers } from 'redux';
import { recognitionsModalListSlice } from './list.slice';
import { recognitionsModalFiltersSlice } from './filter.slice';

export const actions = {
  ...recognitionsModalListSlice.actions,
  ...recognitionsModalFiltersSlice.actions,
};

export const recognitionsModalSlice = {
  myRecognitionsModal: combineReducers({
    [recognitionsModalListSlice.name]: recognitionsModalListSlice.reducer,
    [recognitionsModalFiltersSlice.name]: recognitionsModalFiltersSlice.reducer,
  }),
};
