import { useShouldShowFeatureFlag } from 'common/features/FeatureFlag/hooks/useFeatureFlag';
import { useCustomDomain } from 'common/hooks/useCustomDomain';
import { useSelectedProfile } from 'common/hooks/useSelectedProfile';
import { useIsMyProfile } from 'stacks/ProfileArtist/hooks/useIsMyProfile';

const useShouldShowRecognizeButton = (targetContextId?: number): boolean => {
  const [recognitionsFeatureFlag] = useShouldShowFeatureFlag('recognitionsTabArtistProfile');
  const isCustomDomain = !!useCustomDomain();
  const { contextId, type } = useSelectedProfile() ?? {};
  const isMyProfile = useIsMyProfile(contextId ?? 0, targetContextId ?? 0);
  return recognitionsFeatureFlag && !isCustomDomain && !isMyProfile && ['ARTIST', 'COLLECTOR', ''].includes(type || '');
};

export default useShouldShowRecognizeButton;
