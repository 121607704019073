import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { ListingDirection, listingDirections } from '../components/listingDirections';
import { listingStatusLoadingIdentifier } from '../components/listingStatus';
import { ListReduxFragment } from '../components/listReduxFragment';
import { KnownVirtualListEnum, SortOptionsEnum } from '../VirtualList.config';

export type ColdStartReducer<State extends ListReduxFragment<SortOptionsEnum, unknown>> = CaseReducer<
  State,
  PayloadAction<{
    list: KnownVirtualListEnum;
    identifier: State['identifier'];
    startList: [direction: ListingDirection, sort: State['currentSort'], cursor: State['data']['cursor']];
  }>
>;

export const createColdStartVirtualListReducer =
  <State extends ListReduxFragment<SortOptionsEnum, unknown>>(
    virtualListIdentifier: KnownVirtualListEnum,
    getDefaultListState: (type: State['currentSort']) => State['data']
  ): ColdStartReducer<State> =>
  (state, action) => {
    const {
      list,
      identifier,
      startList: [direction, sort, [, forward]],
    } = action.payload;

    if (list !== virtualListIdentifier) {
      return;
    }

    if (state.identifier !== identifier) {
      state.currentSort = sort;
      state.data = getDefaultListState(state.currentSort);
    }
    if (state.data.listed.status !== 'COLD') {
      return;
    }

    state.data.listed.hasMore = [...state.data.listed.hasMore];

    const directionIdx = listingDirections.indexOf(direction);
    state.data.listed.hasMore[directionIdx] = true;
    state.data.cursor[directionIdx] = forward;

    state.identifier = identifier;
    state.data.listed.status = `${listingStatusLoadingIdentifier}_${direction}` as const;
  };
