export enum OrientationEnum {
  ALL = 'all',
  SQUARE = 'square',
  LANDSCAPE = 'landscape',
  PORTRAIT = 'portrait',
}

export type OrientationFilterState = {
  orientation: Record<
    OrientationEnum,
    boolean // is toggled
  >;
};
