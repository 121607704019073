import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createToggleYearCaseReducer } from 'common/features/Filters/predefined/Year/store/year.reducers';
import { createToggleArtistsCaseReducer } from 'common/features/Filters/predefined/Artists/store/artists.reducers';
import { createSortMostRecentCaseReducer } from 'common/features/Filters/predefined/SortMostRecentV2/store/mostRecent.reducer';
import { createSortLeastRecentCaseReducer } from 'common/features/Filters/predefined/SortLeastRecentV2/store/leastRecent.reducer';
import { createSortCollectorSelectionCaseReducer } from 'common/features/Filters/predefined/SortCollectorSelection/store/collectorSelection.reducer';
import { filterInitialState, getDefaultFilters } from './filter.defaults';
import { ProfileCollectorFiltersState } from './filter.types';

export const profileCollectorFiltersSlice = createSlice({
  name: 'filter',
  initialState: filterInitialState,
  reducers: {
    RESET_ProfileCollector: getDefaultFilters,
    SORT_ProfileCollector: (state, action: PayloadAction<ProfileCollectorFiltersState['currentSort']>) => ({
      ...state,
      sort: action.payload,
    }),
    CHANGE_YEAR_ProfileCollector: createToggleYearCaseReducer<ProfileCollectorFiltersState>(),
    CHANGE_ARTIST_ProfileCollector: createToggleArtistsCaseReducer<ProfileCollectorFiltersState>(),
    SORT_BY_MOST_RECENT_ProfileCollector: createSortMostRecentCaseReducer<ProfileCollectorFiltersState>(),
    SORT_BY_LEAST_RECENT_ProfileCollector: createSortLeastRecentCaseReducer<ProfileCollectorFiltersState>(),
    SORT_BY_COLLECTOR_SELECTION_ProfileCollector:
      createSortCollectorSelectionCaseReducer<ProfileCollectorFiltersState>(),
  },
});
