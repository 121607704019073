import clsx from 'clsx';
import { useMemo } from 'react';
import { t } from 'i18next';
import { CDNUrl } from 'utils/constants';
import { useIsMobileScreen } from 'common/hooks/utils/useIsMobileScreen';
import { useTheme } from '@material-ui/core';
import { useRatingScoreStyles } from './RatingScore.styles';
import RatingProgressCircle from './components/RatingProgressCircle/RatingProgressCircle';
import RatingStarsText from './components/RatingStarsText/RatingStarsText';
import { RatingStarsTextType } from './utils/types';

const imagePaths = {
  pending: `${CDNUrl}/images/congratsPage/arPending.svg`,
  noScore: `${CDNUrl}/images/artFeed/noArIcon.svg`,
  arIcon: `${CDNUrl}/images/artFeed/arIcon.svg`,
};

const RatingScore: React.FC<RatingStarsTextType> = (ratingScore) => {
  const {
    ratingScoreOverrideClassName,
    score,
    ratingText,
    iconImage,
    showInfoIcon,
    arIconContainerOverrideClassName,
    onClickInfoIcon,
    isDarkMode,
  } = ratingScore;
  const currentTheme = useTheme();
  const isCurrentDarkMode = isDarkMode !== undefined ? isDarkMode : currentTheme.palette.common.white === 'black';
  const classes = useRatingScoreStyles({ isDarkMode: isCurrentDarkMode });
  const isMobile = useIsMobileScreen();

  const isPending = useMemo(() => score === null, [score]);

  const { arText, arIconSrc } = useMemo(() => {
    if (score === null) {
      return {
        arText: ratingText || t('showcase.ar.score.title.pendingScore'),
        arIconSrc: iconImage || imagePaths.pending,
      };
    }

    if (score === 0) {
      return {
        arText: ratingText || t('showcase.ar.score.title.noScore'),
        arIconSrc: iconImage || imagePaths.noScore,
      };
    }

    return {
      arText: ratingText || (isMobile ? t('showcase.ar.score.title.mobile') : t('showcase.ar.score.title')),
      arIconSrc: iconImage || imagePaths.arIcon,
    };
  }, [score, ratingText, isMobile, iconImage]);

  return (
    <div className={clsx(classes.container, ratingScoreOverrideClassName)}>
      <RatingProgressCircle
        iconImage={arIconSrc}
        score={score}
        arIconContainerOverrideClassName={clsx(
          isPending ? classes.pendingIcon : undefined,
          arIconContainerOverrideClassName
        )}
        isDarkMode={isCurrentDarkMode}
      />
      <RatingStarsText
        ratingText={arText}
        iconImage={arIconSrc}
        score={score}
        showInfoIcon={isPending ? false : showInfoIcon}
        onClickInfoIcon={onClickInfoIcon}
        isDarkMode={isCurrentDarkMode}
      />
    </div>
  );
};

export default RatingScore;
