import { combineReducers } from 'redux';
import { recognitionsListSlice } from './list.slice';
import { recognitionsFiltersSlice } from './filter.slice';

export const actions = {
  ...recognitionsListSlice.actions,
  ...recognitionsFiltersSlice.actions,
};

export const recognitionsSlice = {
  myRecognitions: combineReducers({
    [recognitionsListSlice.name]: recognitionsListSlice.reducer,
    [recognitionsFiltersSlice.name]: recognitionsFiltersSlice.reducer,
  }),
};
