import { Button } from '@material-ui/core';
import AvailabilityDot from 'common/components/AvailabilityDot/AvailabilityDot';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSwipeContainerComponentsStyles } from './useSwipeContainerComponents.styles';

const SwipeContainerCollectedButton: React.FC = () => {
  const classes = useSwipeContainerComponentsStyles();
  const { t } = useTranslation();

  return (
    <Button className={classes.collectedBtn} variant="outlined" color="primary" disableRipple>
      <AvailabilityDot availability="COLLECTED" />
      {t('showcase.buttons.collected')}
    </Button>
  );
};

export default SwipeContainerCollectedButton;
