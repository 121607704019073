import { Theme, makeStyles } from '@material-ui/core';

export const useRatingProgressCircleStyles = makeStyles<
  Theme,
  {
    isDarkMode?: boolean;
  }
>(() => ({
  arIconContainer: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '33px',
    background: 'var(--black-gray-8-b, rgba(0, 0, 0, 0.08))',
    '& > div': {
      position: 'absolute',
      '&.score': {
        height: 'calc(100% - 4px) !important',
        width: 'calc(100% - 4px) !important',
        borderRadius: '33px',
      },
    },
  },
  arIcon: {
    width: '16px',
    height: '16px',
    padding: '13px',
  },
  noArIcon: {
    width: '20px',
    height: '20px',
    padding: '11px',
  },
  circleProgress: ({ isDarkMode }) => ({
    strokeLinecap: 'round',
    stroke: 'url(#linearColors)',
    filter: isDarkMode ? 'invert(1)' : 'none',
  }),
  circleBackground: ({ isDarkMode }) => ({
    width: '42px',
    height: '42px',
    filter: isDarkMode ? 'invert(1)' : 'none',
  }),
}));
