import { makeStyles } from '@material-ui/core';
import './common/keyframes.css';
import zIndex from 'common/utils/zIndex';

export const useProvenancePanelStyles = makeStyles((theme) => ({
  panel: {
    position: 'absolute',
    boxSizing: 'border-box',
    width: '100%',
    height: '100%',
    zIndex: zIndex.values.PROVENANCE_PANEL,
    ...theme.mixins.flexColumn,
    background: 'rgba(255, 255, 255, 0.8)',
    backdropFilter: 'blur(24px)',
    borderRadius: 'inherit',
    padding: '28px 24px 2px 24px',
    animation: 'fade-in 0.3s',
  },
  panelFadeOut: {
    animation: 'fade-out 0.3s',
  },
  closeButton: {
    position: 'absolute',
    right: 20,
    zIndex: zIndex.values.PROVENANCE_CLOSE_BUTTON,
    borderRadius: '6px',
  },
}));
