// import { ExploreArtistCommonFilterFieldsFragment } from 'common/schema/commonSchemaRemoteOperationTypes';
import { Order_By } from 'common/schema/commonSchemaRemoteTypes';
import { ProfileListType } from 'store/slices/profileListModifiers';
import { CDNBaseUrl, CDNUrl } from 'utils/constants';
import {
  AppliedListingModifier,
  FILTERS_DEFAULT_VALUE,
  isOptionType,
  ListingModifier,
  ListingModifierButtonsConfig,
  ListingModifierOption,
  PROFILE_QUERY_SORT,
} from './types';

enum KnownOptions {
  SORT_MOST_KALEIDO_COINS = 'filterStrings.sort.mostKaleidoCoins',
  SORT_MOST_ARTWORKS = 'filterStrings.sort.mostArtworks',
  SORT_RECENTLY_JOINED = 'filterStrings.sort.recentlyJoined',
  SORT_EARLY_ADOPTERS = 'filterStrings.sort.earlyAdopters',
  SORT_ALPHABETICALLY_A = 'filterStrings.sort.alphabeticallyA',
  SORT_ALPHABETICALLY_Z = 'filterStrings.sort.alphabeticallyZ',
}

type SortVariant = ProfileListType;
type SortVariantOptions = {
  [key in SortVariant]: (collectionId: number) => ListingModifierOption[];
};

const variants: SortVariantOptions = {
  EXPLORE: () => [
    {
      value: FILTERS_DEFAULT_VALUE,
      name: KnownOptions.SORT_RECENTLY_JOINED,
      key: 'PROFILE_QUERY_SORT',
      payload: { context: { id: Order_By.Desc } },
    },
    {
      value: 1,
      name: KnownOptions.SORT_EARLY_ADOPTERS,
      key: 'PROFILE_QUERY_SORT',
      payload: { context: { id: Order_By.Asc } },
    },
    {
      value: 2,
      name: KnownOptions.SORT_MOST_KALEIDO_COINS,
      key: 'PROFILE_QUERY_SORT',
      payload: { total_points: Order_By.Desc }, // TODO: THIS SHOULD BE DE DEFAULT AND SET TO {}
    },
    {
      value: 3,
      name: KnownOptions.SORT_MOST_ARTWORKS,
      key: 'PROFILE_QUERY_SORT',
      payload: { artworks_count: Order_By.Desc },
    },
    {
      value: 4,
      name: KnownOptions.SORT_ALPHABETICALLY_A,
      key: 'PROFILE_QUERY_SORT',
      payload: { artist_title: Order_By.Asc },
    },
    {
      value: 5,
      name: KnownOptions.SORT_ALPHABETICALLY_Z,
      key: 'PROFILE_QUERY_SORT',
      payload: { artist_title: Order_By.Desc },
    },
  ],
  COLLECTION: () => [
    {
      value: FILTERS_DEFAULT_VALUE,
      name: KnownOptions.SORT_MOST_KALEIDO_COINS,
      key: 'PROFILE_QUERY_SORT',
      payload: { total_points: Order_By.Desc }, // TODO: THIS SHOULD BE DE DEFAULT AND SET TO {}
    },
    {
      value: 1,
      name: KnownOptions.SORT_MOST_ARTWORKS,
      key: 'PROFILE_QUERY_SORT',
      payload: { artworks_count: Order_By.Desc },
    },
    {
      value: 2,
      name: KnownOptions.SORT_RECENTLY_JOINED,
      key: 'PROFILE_QUERY_SORT',
      payload: { context: { id: Order_By.Desc } },
    },
    {
      value: 3,
      name: KnownOptions.SORT_EARLY_ADOPTERS,
      key: 'PROFILE_QUERY_SORT',
      payload: { context: { id: Order_By.Asc } },
    },
    {
      value: 4,
      name: KnownOptions.SORT_ALPHABETICALLY_A,
      key: 'PROFILE_QUERY_SORT',
      payload: { artist_title: Order_By.Asc },
    },
    {
      value: 5,
      name: KnownOptions.SORT_ALPHABETICALLY_Z,
      key: 'PROFILE_QUERY_SORT',
      payload: { artist_title: Order_By.Desc },
    },
  ],
};

export const getSorter = (collectionId = 0, variant?: SortVariant): ListingModifier => {
  const computedSorters: ListingModifier = {
    type: 'SORT',
    allOptions: [...(variant ? variants[variant](collectionId) : [])],
  };
  computedSorters.allOptions.sort((a, b) => a.value - b.value);
  return computedSorters;
};

export const sortButtonsDefaultConfig: ListingModifierButtonsConfig = {
  [KnownOptions.SORT_MOST_KALEIDO_COINS]: { specialWidth: 'HALF' },
  [KnownOptions.SORT_MOST_ARTWORKS]: { specialWidth: 'HALF' },
  [KnownOptions.SORT_RECENTLY_JOINED]: {
    specialWidth: 'HALF',
    image: `${CDNBaseUrl}/static/images/showroom/most-recent.svg`,
  },
  [KnownOptions.SORT_EARLY_ADOPTERS]: {
    specialWidth: 'HALF',
    image: `${CDNBaseUrl}/static/images/showroom/least-recent.svg`,
  },
  [KnownOptions.SORT_ALPHABETICALLY_A]: {
    specialWidth: 'HALF',
    image: `${CDNUrl}/images/artistProfileFooterIcons/filters/azSort.svg`,
  },
  [KnownOptions.SORT_ALPHABETICALLY_Z]: {
    specialWidth: 'HALF',
    image: `${CDNUrl}/images/artistProfileFooterIcons/filters/zaSort.svg`,
  },
};
export const sortButtonsListTypeConfig: {
  [key in ProfileListType]: ListingModifierButtonsConfig;
} = {
  EXPLORE: { ...sortButtonsDefaultConfig },
  COLLECTION: { ...sortButtonsDefaultConfig },
};

export const getAppliedSort = (
  collectionId?: number,
  variant?: SortVariant
): AppliedListingModifier<PROFILE_QUERY_SORT> => ({
  ...getSorter(collectionId, variant),
  selectionType: 'SINGLE',
  selectedOptions: [FILTERS_DEFAULT_VALUE],
  flattenSelectedOptions: (options, selectedOptions) => {
    const flattenedOption = options.find((option) => selectedOptions.includes(option.value));
    if (!flattenedOption || !isOptionType<PROFILE_QUERY_SORT>(flattenedOption, 'PROFILE_QUERY_SORT')) {
      throw new Error('Wrong option type');
    }
    return flattenedOption;
  },
});
