import { useCallback } from 'react';
import { useGetUserSubscriptionsLazyQuery } from 'common/schema/commonSchemaRemoteGraphqlQueries';
import { subscriptionsActions } from 'store/slices/subscriptionsSlice';
import { Enum_Billing_Subscription_Product_Type_Enum } from 'common/schema/commonSchemaRemoteTypes';
import { useDispatch } from 'react-redux';
import { useSelectedProfile } from './useSelectedProfile';

const useFetchUserSubscriptions = () => {
  const dispatch = useDispatch();
  const { contextId } = useSelectedProfile() ?? {};
  const [getUserSubscriptions] = useGetUserSubscriptionsLazyQuery({
    fetchPolicy: 'network-only',
  });

  const fetchUserSubscriptions = useCallback(async () => {
    if (!contextId) {
      return;
    }

    const userSubscriptions = (await getUserSubscriptions({ variables: { contextId } }))?.data;
    if (userSubscriptions?.Billing_subscription[0]) {
      const subscriptions = userSubscriptions?.Billing_subscription.filter((sub) =>
        sub.subscription_products.some(
          (prod) =>
            prod.type === Enum_Billing_Subscription_Product_Type_Enum.MystudioPremium ||
            prod.type === Enum_Billing_Subscription_Product_Type_Enum.MystudioPro ||
            prod.type === Enum_Billing_Subscription_Product_Type_Enum.SuperSite
        )
      );
      if (subscriptions.length) {
        const subscriptionStatus = subscriptions[0].billing_subscription_status;
        const subscriptionPeriod = subscriptions[0].billing_subscription_period;
        const isPremium = subscriptions.length > 0;
        dispatch(
          subscriptionsActions.SET_SUBSCRIPTIONS({ subscriptions, isPremium, subscriptionStatus, subscriptionPeriod })
        );
      }
    } else {
      dispatch(
        subscriptionsActions.SET_SUBSCRIPTIONS({
          subscriptions: [],
          isPremium: false,
          subscriptionStatus: undefined,
          subscriptionPeriod: undefined,
        })
      );
    }
  }, [contextId, dispatch, getUserSubscriptions]);

  return { fetchUserSubscriptions };
};

export default useFetchUserSubscriptions;
