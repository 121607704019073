import { Enum_Context_Type_Enum } from 'common/schema/commonSchemaRemoteTypes';
import { SortOptionsEnum } from 'common/features/VirtualList/VirtualList.config';
import { getDefaultFilters } from './filter.defaults';
import {
  RecognitionsModalListMostRecent,
  RecognitionsModalListState,
  ListDirection,
  listingDirections,
  ListWindowLoaderStateType,
} from './list.types';
import { RecognitionsModalSort, isRecognitionsModalort } from './filter.types';
import { filtersHash } from '../hooks/useRecognitionsModalFiltersIdentifier';

export const defaults = {
  most_recent: (): {
    type: SortOptionsEnum.MOST_RECENT;
    data: RecognitionsModalListMostRecent;
  } => ({
    type: SortOptionsEnum.MOST_RECENT,
    data: {
      __typename: SortOptionsEnum.MOST_RECENT,
      lastScrollPosition: 0,
      detailedToListedStartOffset: 0,
      cursor: [
        {
          recognizedAt: '1000-01-01T00:00:00.000000+00:00',
          recognizedBy: 0,
          recognizedByType: Enum_Context_Type_Enum.Artist,
        },
        {
          recognizedAt: '4000-01-01T00:00:00.000000+00:00',
          recognizedBy: 0,
          recognizedByType: Enum_Context_Type_Enum.Artist,
        },
      ],
      listed: {
        batchSize: 64,
        status: 'COLD',
        values: [],
        hasMore: [false, false],
      },
      detailed: {
        batchSize: 16,
        status: 'COLD',
        values: [],
        hasMore: [false, false],
      },
    },
  }),
};
const defaultFilters = getDefaultFilters();

export const getDefaultListState: () => RecognitionsModalListState = () => {
  const currentSort: RecognitionsModalSort = isRecognitionsModalort(defaultFilters.currentSort)
    ? defaultFilters.currentSort
    : SortOptionsEnum.MOST_RECENT;

  return {
    identifier: filtersHash(defaultFilters),
    ...defaults[currentSort](),
  };
};

export const listInitialState = getDefaultListState();

export const hasMore = (loader: ListWindowLoaderStateType<unknown, unknown>, direction: ListDirection): boolean =>
  loader.hasMore[listingDirections.indexOf(direction)];
