import { logger } from '@old/services/logger';
import { useShouldShowFeatureFlag } from 'common/features/FeatureFlag/hooks/useFeatureFlag';
import useAsyncState from 'common/hooks/utils/useAsyncState';
import { useGetEditionBySizeCheapestPrintLazyQuery } from 'common/schema/commonSchemaRemoteGraphqlQueries';
import { useCallback, useEffect } from 'react';

const useEditionSizesCheapestPrints = (editionBySizeIds: number[]) => {
  const [showPrintsFeature] = useShouldShowFeatureFlag('prints');

  const [cheapestPrints, setCheapestPrints] = useAsyncState<{ [editionBySizeId: number]: number }>({});

  const [getCheapestPrint] = useGetEditionBySizeCheapestPrintLazyQuery();

  const getEditionBySizesCheapestPrints = useCallback(async (ids: number[]) => {
    try {
      const results = await Promise.all(
        ids.map(async (editionBySizeId) => {
          const { data } = await getCheapestPrint({ variables: { editionBySizeId } });
          return data?.get_min_price_available_print.length ? data.get_min_price_available_print[0] : undefined;
        })
      );
      setCheapestPrints(() => {
        const next: typeof cheapestPrints = {};
        results.forEach((r) => {
          if (r?.price) {
            next[r.edition_by_size_id] = r.price;
          }
        });
        return next;
      });
    } catch (error) {
      logger.error(error);
      setCheapestPrints({});
    }
  }, []);
  useEffect(() => {
    if (!showPrintsFeature) {
      return;
    }
    if (!editionBySizeIds.length) {
      setCheapestPrints({});
    }
    getEditionBySizesCheapestPrints(editionBySizeIds);
  }, [editionBySizeIds, getEditionBySizesCheapestPrints, showPrintsFeature]);

  return cheapestPrints;
};

export default useEditionSizesCheapestPrints;
