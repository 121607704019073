import { Grid, makeStyles } from '@material-ui/core';
import { TABLET_BREAKPOINT } from 'common/utils/constants';
import React from 'react';

export const HOVER_TRANSITION = 'all 0.35s ease-in-out';
export const HOVER_TRANSFORM = 'translateY(34px)';

export const defaultAnimationState: Record<string, React.CSSProperties> = {
  details: {
    minWidth: 0,
    transition: HOVER_TRANSITION, // animates translateY
    transform: HOVER_TRANSFORM,
  },
  toAnimate: {
    paddingTop: 6,
    paddingBottom: 12,
    opacity: 0,
    transition: HOVER_TRANSITION, // animates opacity
  },
};

const useArtworkCardOnHoverStyles = makeStyles((theme) => ({
  onHoverContainer: {
    '& .image-info-layout-animated-gradient': {
      '&::before': {
        content: "''",
        position: 'absolute',
        width: '100%',
        height: theme.spacing(6.25),
        bottom: theme.spacing(0),
        backgroundImage: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.48) 100%) !important',
        opacity: 0,
        transition: HOVER_TRANSITION,
      },

      '& .toAnimate': {
        paddingBottom: '4px !important',
      },
    },

    '&:hover .image-info-layout-animated-gradient': {
      '&::before': {
        opacity: 1,
      },
    },
    '&:hover .details': {
      transform: 'translateY(0px) !important',
      '& .toAnimate': {
        opacity: '1 !important',
      },
    },

    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      '&:hover .details': {
        transform: `${HOVER_TRANSFORM} !important`,
        '& .toAnimate': {
          opacity: '0 !important',
        },
      },
    },
  },
}));

const ArtworkCardOnHover: React.FC = ({ children }) => {
  const classes = useArtworkCardOnHoverStyles();
  return (
    <Grid container className={classes.onHoverContainer}>
      {children}
    </Grid>
  );
};

export default ArtworkCardOnHover;
