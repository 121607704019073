type Props = {
  userContextId: number;
  targetContextId: number;
};

// This function returns the channel name for a direct message between two users.
export const buildChannelName = ({ userContextId, targetContextId }: Props): string => {
  const sortedContextIds = [userContextId, targetContextId].sort((a, b) => a - b);
  return `dm_${sortedContextIds.join('_')}`;
};

// This function returns the channel name for a direct message between two users.
export const buildDMChannelGroupName = (userContextId: number): string => `cg_dm_${userContextId}`;

// This function returns the channel name for the in-app notification center of a user.
export const buildNotificationChannelName = (userContextId: number): string => `notif.${userContextId}`;
