import { ICity } from '@old/shared/LocationInput/hooks/useCities';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface CitiesState {
  list: ICity[];
}

const initialState: CitiesState = {
  list: [],
};

const citiesSlice = createSlice({
  name: 'cities',
  initialState,
  reducers: {
    SET_CITIES: (state, action: PayloadAction<ICity[]>) => {
      state.list = action.payload;
    },
  },
});

export const citiesActions = citiesSlice.actions;
export default citiesSlice;
