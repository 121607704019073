import React from 'react';
import { useTranslation } from 'react-i18next';
import { RoundRectangleWithoutCircle } from '../../../../subcomponents/roundrectangle/RoundRectangle';
import { startCardStatsSectionStyles } from './StartCardStatsSection.styles';

type Props = {
  artworksCount: number;
  artistsCount: number;
};

export const StartCardStatsSection: React.FC<Props> = ({ artworksCount, artistsCount }) => {
  const { t } = useTranslation();
  const classes = startCardStatsSectionStyles();

  return (
    <div className={classes.startCardStatsSectionContainer}>
      <RoundRectangleWithoutCircle
        number={artworksCount}
        title={
          artworksCount === 1
            ? t('collectorProfileStrings.profileViewPage.startCard.artwork.singular')
            : t('collectorProfileStrings.profileViewPage.startCard.artwork.plural')
        }
      />
      <RoundRectangleWithoutCircle
        number={artistsCount}
        title={
          artistsCount === 1
            ? t('collectorProfileStrings.profileViewPage.startCard.artist.singular')
            : t('collectorProfileStrings.profileViewPage.startCard.artist.plural')
        }
      />
    </div>
  );
};
