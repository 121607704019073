import { combineReducers } from 'redux';
import { favoritesListSlice } from './list.slice';
import { favoritesFiltersSlice } from './filter.slice';
import { favoritesDisplaySlice } from './display.slice';

export const actions = {
  ...favoritesListSlice.actions,
  ...favoritesFiltersSlice.actions,
  ...favoritesDisplaySlice.actions,
};

export const favoritesSlice = {
  myFavorites: combineReducers({
    [favoritesListSlice.name]: favoritesListSlice.reducer,
    [favoritesFiltersSlice.name]: favoritesFiltersSlice.reducer,
    [favoritesDisplaySlice.name]: favoritesDisplaySlice.reducer,
  }),
};
