import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@material-ui/core';
import zIndex from 'common/utils/zIndex';
import React, { useContext, useRef, useState } from 'react';
import { CDNUrl } from 'utils/constants';
import { useConfirmationModalStyles } from './ConfirmationModal.styles';

type Props = {
  title: string;
  message: string | JSX.Element | (string | JSX.Element)[];
  okText: string;
  cancelText: string;
};

type ConfirmationMadalProviderType = {
  showConfirmationModal: (props: Props) => Promise<boolean>;
};

const ConfirmationModalContext = React.createContext<ConfirmationMadalProviderType>({
  showConfirmationModal: () => Promise.resolve(false),
});

export function useConfirmationModal() {
  return useContext(ConfirmationModalContext);
}

const ConfirmationModalProvider: React.FC = ({ children }) => {
  const classes = useConfirmationModalStyles();
  const [open, setOpen] = useState(false);
  const [dialogProps, setDialogProps] = useState<Props>();
  const futureResolution = useRef<(value: boolean) => void>();

  const showConfirmationModal = (props: Props): Promise<boolean> => {
    setDialogProps(props);
    setOpen(true);
    const newPromise = new Promise<boolean>((resolve) => {
      futureResolution.current = resolve;
    });
    return newPromise;
  };

  const handleButton = (value: boolean) => {
    setOpen(false);
    if (futureResolution.current) {
      futureResolution.current(value);
    }
  };

  return (
    <ConfirmationModalContext.Provider value={{ showConfirmationModal }}>
      {children}
      <Dialog
        open={open}
        classes={{
          paper: classes.paper,
        }}
        BackdropProps={{
          className: classes.backdrop,
        }}
        onClose={() => handleButton(false)}
        style={{
          zIndex: zIndex.values.CONFIRMATION_MODAL,
        }}
      >
        <div className={classes.title}>
          <DialogTitle>{dialogProps?.title}</DialogTitle>
          <IconButton onClick={() => handleButton(false)}>
            <img src={`${CDNUrl}/images/dashboardIcons/close.svg`} alt="close" />
          </IconButton>
        </div>
        <DialogContent className={classes.content}>{dialogProps?.message}</DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={() => handleButton(true)}>
            {dialogProps?.okText}
          </Button>
          <Button variant="outlined" color="primary" onClick={() => handleButton(false)}>
            {dialogProps?.cancelText}
          </Button>
        </DialogActions>
      </Dialog>
    </ConfirmationModalContext.Provider>
  );
};

export default ConfirmationModalProvider;
