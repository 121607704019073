import { makeStyles } from '@material-ui/core';

export const adminWidgetStyles = makeStyles({
  buttonAdminTool: {
    width: '100%',
    background: '#FFFFFF',
    border: '1.2px solid #CCCCCC',
    borderRadius: '7px',
    zIndex: 10,
  },
  labelPlacementStart: {
    margin: 0,
    gap: 24,
  },
  label: {
    fontWeight: 700,
    color: '#121112',
  },
  gridContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '16px',
    padding: '10',
  },
});
