import { createSlice } from '@reduxjs/toolkit';
import { FavoritesModalType } from '../FavoritesModal/views/FavoritesModal';

const favoritesEngagementInitialState: FavoritesModalType = {
  artworkId: 0,
  artworkImageUrl: '',
  artworkTitle: '',
  favoritesCount: 0,
  isDisplayed: false,
};

export const favoritesEngagementSlice = createSlice({
  name: 'favoritesEngagement',
  initialState: favoritesEngagementInitialState,
  reducers: {
    SET_FAVORITES_ENGAGEMENT: (_, action) => ({
      ...action.payload,
      isDisplayed: true,
    }),
    CLOSE_MODAL: (state) => ({
      ...state,
      isDisplayed: false,
    }),
    OPEN_MODAL: (state) => ({
      ...state,
      isDisplayed: true,
    }),
  },
});
