import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@material-ui/core';
import React, { useMemo } from 'react';
import { t } from 'i18next';
import { CDNUrl } from 'utils/constants';
import { Alert } from '@material-ui/lab';
import { Trans } from 'react-i18next';
import { useIsMobileScreen } from 'common/hooks/utils/useIsMobileScreen';
import zIndex from 'common/utils/zIndex';
import { alignmentStyles } from 'utils/alignmentStyles';
import useGetReservedScreenspace from 'common/features/Screenspace/hooks/useGetReservedScreenspace';
import { KnownSpacesOrder } from 'common/features/Screenspace/store/screenspaceSlice';
import { useArRatingInfoModalStyles } from './ArRatingInfoModal.styles';
import RatingScore from '../RatingScore/RatingScore';

type Props = {
  open: boolean;
  onClose: () => void;
  score: number | null;
};

const ArRatingInfoModal: React.FC<Props> = ({ open, onClose, score }) => {
  const classes = useArRatingInfoModalStyles();
  const isMobile = useIsMobileScreen();
  const alignmentClasses = alignmentStyles();
  const showIcon = useMemo(() => (isMobile ? { icon: false } : {}), [isMobile]);

  const {
    withSafeArea: { top: paddingTop },
  } = useGetReservedScreenspace({ untilOrder: KnownSpacesOrder.First }).toCSSProps();

  const scroreText = useMemo(() => {
    switch (score) {
      case null:
        return undefined;
        break;
      case 0:
        return t('arScoreInfoDialog.score0');
        break;
      case 1:
      case 2:
        return t('arScoreInfoDialog.score1', { score });
        break;
      case 3:
      case 4:
      case 5:
        return t('arScoreInfoDialog.score3', { score });
      default:
        return t('arScoreInfoDialog.score0');
    }
  }, [score]);

  const ratingText = useMemo(() => (score ? t('arScoreInfoDialog.score') : undefined), [score]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullScreen={isMobile}
      style={{ zIndex: zIndex.values.ANNOUNCEMENT_MODAL }}
      PaperProps={{
        className: classes.paper,
      }}
    >
      <DialogTitle classes={{ root: classes.noPadding }} style={{ paddingTop }}>
        <div className={classes.dialogHeader}>
          <Typography className={classes.dialogTitle}>{t('arScoreInfoDialog.title')}</Typography>
          <IconButton onClick={() => onClose()} className={classes.closeIcon}>
            <img src={`${CDNUrl}/images/artDetailsIcons/newProfileArtShowcase/close.svg`} alt="img" />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {!isMobile ? (
          <div className={classes.infoContainer}>
            <div className={classes.textInfoContainer}>
              <Typography>
                <Trans i18nKey="arScoreInfoDialog.info1" />
              </Typography>
              <Typography>{t('arScoreInfoDialog.info2')}</Typography>
              <div className={classes.scoreContainer}>
                <RatingScore score={score} maxScore={5} ratingText={ratingText} isDarkMode={false} />
                <Typography>{scroreText}</Typography>
              </div>
            </div>
            <div>
              <img src={`${CDNUrl}/images/showcase-3/arInfoModalImage.png`} alt="info" />
            </div>
          </div>
        ) : (
          <div className={classes.textInfoContainer}>
            <Typography>
              <Trans i18nKey="arScoreInfoDialog.info1" />
            </Typography>
            <div className={alignmentClasses.flexCenter}>
              <img src={`${CDNUrl}/images/showcase-3/arInfoModalImage.png`} alt="info" />
            </div>
            <Typography>{t('arScoreInfoDialog.info2')}</Typography>
            <div className={classes.scoreContainer}>
              <RatingScore score={score} maxScore={5} ratingText={ratingText} isDarkMode={false} />
              <Typography>{scroreText}</Typography>
            </div>
          </div>
        )}

        <Alert
          {...showIcon}
          severity="info"
          className={classes.alert}
          classes={{
            icon: classes.alertIcon,
            message: classes.alertMessage,
          }}
        >
          {t('arScoreInfoDialog.alert')}
        </Alert>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button onClick={onClose} color="primary" variant="contained" className={classes.closeButton}>
          {t('common.understood')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ArRatingInfoModal;
