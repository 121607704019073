import { getCountryFullName } from 'common/hooks/useCountries';
import { GetProvenanceAndHandlesByArtworkIdQuery } from 'common/schema/commonSchemaRemoteOperationTypes';
import { Artwork_Tag, Enum_Artwork_Discipline_Enum } from 'common/schema/commonSchemaRemoteTypes';
import { t } from 'i18next';
import { ArtworkSlugType } from 'stacks/ProfileArtShowcase/hooks/useGetArtworkBySlug';
import { getCDNImageUrl } from 'utils/CDNImages';
import { CDNUrl } from 'utils/constants';
import {
  ArtistData,
  CommercialData,
  DetailsData,
  DetailsDigital,
  DetailsPhysical,
  PriceData,
  ProvenanceData,
} from './helpers';

export const getArtworkName = (artShowcase?: ArtworkSlugType) => {
  if (artShowcase?.artwork_details && artShowcase?.artwork_details?.title) {
    return artShowcase?.artwork_details.title;
  }
  return '';
};

export const getSeriesName = (artShowcase?: ArtworkSlugType) => {
  if (artShowcase?.artwork_to_collections && artShowcase?.artwork_to_collections.length > 0) {
    return artShowcase?.artwork_to_collections[0].Collection.name || '';
  }
  return '';
};

export const getArtistInfo = (artShowcase?: ArtworkSlugType) => {
  let artistInfo = {
    image: '',
    title: '',
    handle: '',
    location: {
      city: '',
      country: '',
    },
  };

  const artistContext = artShowcase?.artist_context;
  const artistProfile = artistContext?.profile;
  const artistLocations = artistContext?.locations;

  const city = artistLocations?.[0]?.city ?? '';
  const country = getCountryFullName(artistLocations?.[0]?.country || '');

  if (artistContext && artistProfile) {
    artistInfo = {
      image: getCDNImageUrl({
        target: 'Profile',
        purpose: 'Avatar',
        targetId: artistProfile?.id,
        fileId: artistProfile?.avatar ?? 0,
        meta: {
          size: 'large',
        },
      }),
      title: artistProfile.title || '',
      handle: artistProfile.handle || '',
      location: {
        city,
        country,
      },
    };
  }

  return artistInfo;
};

export const getGalleryInfo = (artShowcase?: ArtworkSlugType) => {
  let galleryInfo = {
    image: '',
    title: '',
    handle: '',
  };
  const galleryContext = artShowcase?.gallery_context;
  const galleryProfile = galleryContext?.profile;

  if (galleryProfile) {
    galleryInfo = {
      image: getCDNImageUrl({
        target: 'Profile',
        purpose: 'Avatar',
        targetId: galleryProfile?.id,
        fileId: galleryProfile?.avatar ?? 0,
        meta: {
          size: 'large',
        },
      }),
      title: galleryProfile.title || '',
      handle: galleryProfile.handle || '',
    };
  }

  return galleryInfo;
};

type StoryTellingInfo = {
  secondaryTags: Artwork_Tag[] | undefined;
  artworkStatement: string;
  seriesStatement: string;
  historicalStatement: Artwork_Tag | undefined;
};

export const getStorytellingTabInfo = (artShowcase?: ArtworkSlugType): StoryTellingInfo => {
  const storytellingInfo: StoryTellingInfo = {
    secondaryTags: [] as Artwork_Tag[],
    artworkStatement: '',
    seriesStatement: '',
    historicalStatement: undefined,
  };

  if (artShowcase?.secondary_tags) {
    storytellingInfo.secondaryTags = artShowcase?.secondary_tags.map((att) => att.tag);
  }

  const artworkCollection = artShowcase?.artwork_to_collections;
  if (artworkCollection && artworkCollection.length) {
    storytellingInfo.seriesStatement = artworkCollection[0].Collection.description || '';
  }

  const artworkStory = artShowcase?.artwork_story;
  storytellingInfo.artworkStatement = artworkStory?.statement || '';

  storytellingInfo.historicalStatement = artShowcase?.primary_tag?.[0]?.tag;

  return storytellingInfo;
};

export const getProvenanceTabInfo = <
  T extends {
    date?: string | null;
    photoURL?: string | null;
    text?: string | null;
  }
>(
  provenance?: (T | null)[] | null
): ProvenanceData[] =>
  provenance?.length
    ? provenance.map<ProvenanceData>((item, index) => ({
        date: item?.date ?? '',
        image: item?.photoURL ?? '',
        text: item?.text ?? '',
        id: index,
      }))
    : [];

export const getProvenancePanelInfo = (provenance?: GetProvenanceAndHandlesByArtworkIdQuery): ProvenanceData[] =>
  provenance?.getArtworkEventByArtworkId?.length
    ? provenance?.getArtworkEventByArtworkId?.map<ProvenanceData>((item, index) => {
        let to;
        const buyerHandle = item?.artwork_transaction?.buyer_context?.profile?.handle;
        const sellerHandle = provenance?.Artwork?.[0]?.transaction_histories?.[0]?.seller_context?.profile?.handle;

        if (item?.event_type === 'TRANSFER_EVENT' && buyerHandle) {
          to = `/collector/${buyerHandle}`;
        }
        if (item?.event_type === 'SOLD_EVENT' && sellerHandle) {
          to = `/${sellerHandle}`;
        }
        return {
          date: item?.date ?? '',
          image: item?.photoURL ?? '',
          text: item?.text ?? '',
          id: index,
          to,
        };
      })
    : [];
// const provenanceData: ProvenanceData[] = [];

// if (provenance?.length) {
//   for (let i = 0; i < provenance?.length; i++) {
//     const provenanceField: ProvenanceData = {
//       date: provenance[i].date,
//       image: provenance[i].photoURL,
//       text: provenance[i].text,
//       id: i,
//     };
//     provenanceData.push(provenanceField);
//   }
// }
export const getPhysicalDetails = (artworkDetails: any) => {
  const physicalDetails: DetailsPhysical = {
    fileDimensions: {
      height: 0,
      width: 0,
      measuringUnit: '',
      depth: 0,
    },
    creationDate: {
      year: undefined,
      month: undefined,
      day: undefined,
    },
    materialsAndMedium: '',
    discipline_other: '',
    discipline: Enum_Artwork_Discipline_Enum.Other,
    disciplineText: '',
  };

  physicalDetails.fileDimensions.depth = artworkDetails.depth?.toFixed(2);
  physicalDetails.fileDimensions.height = artworkDetails.height?.toFixed(2);
  physicalDetails.fileDimensions.measuringUnit = artworkDetails.measuring_unit || '';
  physicalDetails.fileDimensions.width = artworkDetails.width?.toFixed(2);

  physicalDetails.creationDate.year = artworkDetails.creation_date_year;
  physicalDetails.creationDate.month = artworkDetails.creation_date_month;
  physicalDetails.creationDate.day = artworkDetails.creation_date_day;

  physicalDetails.materialsAndMedium = artworkDetails.materials_and_medium || '';

  physicalDetails.discipline_other = artworkDetails.discipline_other || '';
  physicalDetails.discipline = artworkDetails.discipline || null;
  physicalDetails.disciplineText =
    t(`addArtworkStrings.artDetails.select.discipline.${artworkDetails.ENUM_artwork_discipline?.description}`) || '';

  return physicalDetails;
};

export const getDigitalDetails = (artShowcase?: ArtworkSlugType) => {
  const artworkDetails = artShowcase?.artwork_details;
  const primaryImageMetadata = artShowcase?.primary_image_metadata;
  const digital: DetailsDigital = {
    fileFormat: '',
    fileDimensions: {
      height: 0,
      width: 0,
      measuringUnit: '',
      duration: 0,
    },
    creationDate: {
      year: undefined,
      month: undefined,
      day: undefined,
    },
    tools: '',
  };

  digital.fileFormat = primaryImageMetadata?.file_extension || undefined;

  digital.fileDimensions.duration = artworkDetails?.duration || undefined;
  digital.fileDimensions.height = Math.round((artworkDetails?.height ?? 0) * 100) / 100;
  digital.fileDimensions.measuringUnit = artworkDetails?.measuring_unit || '';
  digital.fileDimensions.width = Math.round((artworkDetails?.width ?? 0) * 100) / 100;

  digital.creationDate.year = artworkDetails?.creation_date_year || undefined;
  digital.creationDate.month = artworkDetails?.creation_date_month || undefined;
  digital.creationDate.day = artworkDetails?.creation_date_day || undefined;

  digital.tools = artworkDetails?.tools || '';

  return digital;
};

export const getDetailsTabInfo = (artShowcase?: ArtworkSlugType) => {
  const detailsInfo: DetailsData | null = {
    artType: '',
    details: null,
  };

  const artworkDetails = artShowcase?.artwork_details;
  if (artworkDetails) {
    detailsInfo.artType = artworkDetails.art_type?.toLocaleLowerCase() || '';

    if (detailsInfo?.artType === 'physical') {
      detailsInfo.details = getPhysicalDetails(artworkDetails);
    } else {
      detailsInfo.details = getDigitalDetails(artShowcase);
    }
  }

  return detailsInfo;
};

export const getArtistTabInfo = (artShowcase?: ArtworkSlugType) => {
  const artist: ArtistData = {
    artistStatement: '',
  };

  const artistContext = artShowcase?.artist_context;
  const artistProfile = artistContext?.profile;

  if (artistProfile) {
    artist.artistStatement = artistProfile.statement || '';
  }

  return artist;
};

export const getCommercialInfo = (artShowcase?: ArtworkSlugType) => {
  const commercial: CommercialData = {
    isAvailableForSale: false,
    price: {
      isPricePublic: false,
      value: null,
      currency: '',
    },
  };
  commercial.isAvailableForSale = artShowcase?.is_available_sale || false;

  const prices = artShowcase?.Artwork_prices;

  if (!prices || !prices?.length) {
    commercial.price = null;
    return commercial;
  }

  const lastPriceIndex = prices.length - 1;

  (commercial.price as PriceData).currency = prices[lastPriceIndex]?.currency?.toLocaleUpperCase() || '';
  (commercial.price as PriceData).isPricePublic = prices[lastPriceIndex]?.is_public || false;

  const price = prices[lastPriceIndex]?.price;

  (commercial.price as PriceData).value = price ? price.toLocaleString('en-US', { maximumFractionDigits: 5 }) : '';

  return commercial;
};

export const getCollectorNameImage = (collectorContext: CollectorProfileSubType) => {
  const collector = {
    image: '',
    title: '',
    handle: '',
    isPublic: false,
  };
  const collectorProfile = collectorContext?.profile;
  const profilePrivacy = collectorProfile?.is_public;

  collector.handle = profilePrivacy === 'PRIVATE' ? '' : collectorProfile?.handle || '';
  collector.isPublic = profilePrivacy !== 'PRIVATE';

  if (profilePrivacy === 'PRIVATE') {
    collector.image = `${CDNUrl}/images/artDetailsIcons/newProfileArtShowcase/privateCollector.png`;
    collector.title = 'Private Collector';
  }

  if (profilePrivacy === 'PUBLIC_USERNAME') {
    collector.image = getCDNImageUrl({
      target: 'Profile',
      purpose: 'Avatar',
      targetId: collectorProfile?.id ?? 0,
      fileId: collectorProfile?.avatar ?? 0,
      meta: {
        size: 'large',
      },
    });
    collector.title = collectorProfile?.handle || '';
  }

  if (profilePrivacy === 'PUBLIC_LEGAL_NAME') {
    collector.image = getCDNImageUrl({
      target: 'Profile',
      purpose: 'Avatar',
      targetId: collectorProfile?.id ?? 0,
      fileId: collectorProfile?.avatar ?? 0,
      meta: {
        size: 'large',
      },
    });
    collector.title = `${collectorProfile?.first_name} ${collectorProfile?.last_name}` || '';
  }

  return collector;
};

type CollectorProfileSubType = ArtworkSlugType['transaction_histories'][number]['buyer_context'];

export const getCollectorInfo = (artShowcase?: ArtworkSlugType) => {
  const transactions = artShowcase?.transaction_histories;
  if (!transactions?.length) {
    return null;
  }

  const collectorProfile = transactions[0].buyer_context;
  return getCollectorNameImage(collectorProfile);
};
