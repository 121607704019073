import { SortState } from 'common/features/Filters/predefined/SortGroup/store/sort.types';
import { SortOptionsEnum } from 'common/features/VirtualList/VirtualList.config';
import { RecognitionsFiltersEnum } from './filter.defaults';

export const sorts = [SortOptionsEnum.MOST_RECENT] as const;
export type RecognitionsModalSort = (typeof sorts)[0];
export const isRecognitionsModalort = (v: string): v is RecognitionsModalSort =>
  sorts.includes(v as RecognitionsModalSort);
export type FilterState = {
  chosenFilter: keyof typeof RecognitionsFiltersEnum;
};

// currently auto-applies on every change
// maybe todo: add flag to apply on demand
export type RecognitionsModalFiltersState = SortState & FilterState;
