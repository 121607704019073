import { getCDNImageUrl } from 'utils/CDNImages';

export const SHOWCASE_TRANSITION_TIME = 200;

export type ShowcasePreviewRouteState = {
  showcasePreviewSrc?: string;
  showTransition?: boolean;
  showingTransition?: boolean;
};

export const getTransitionToShowcaseState = (
  artworkId?: number,
  fileId?: number
): ShowcasePreviewRouteState & { artworkId?: number; fileId?: number } => {
  const previewSrc = getCDNImageUrl({
    target: 'Artwork',
    purpose: 'Primary',
    targetId: artworkId ?? 0,
    fileId: fileId ?? 0,
    meta: {
      size: 'thumbnail-small',
    },
  });
  const hasPreview = !!artworkId;
  return {
    showcasePreviewSrc: hasPreview ? previewSrc : undefined,
    showTransition: hasPreview,
    showingTransition: false,
    artworkId,
    fileId,
  };
};
