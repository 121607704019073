import { TypeUtils } from 'common/utils/generalTypeUtils';
import { AvailabilityEnum, AvailabilityFilterState } from './store/availability.types';

export const defaultAvailabilityOptions = [
  AvailabilityEnum.ALL,
  AvailabilityEnum.AVAILABLE,
  AvailabilityEnum.UNAVAILABLE,
  AvailabilityEnum.COLLECTED,
];

export const availabilityFilterHash = (options: AvailabilityFilterState['availability']): string =>
  TypeUtils.Object.keys(options ?? {})
    .filter((availability) => !!options[availability])
    .sort()
    .join('_');
