import { Enum_Social_Graph_Followers_State_Enum } from 'common/schema/commonSchemaRemoteTypes';
import { handleFollowActionResponseType } from '../hooks/useArtistAdmirationsActions';

export const setFollowRelation = async (
  contextId: number,
  targetContextId: number,
  isRecognizing: boolean,
  newStateValue: Enum_Social_Graph_Followers_State_Enum,
  handleFollowAction: (
    contextId: number,
    targetContextId: number,
    newStateValue: Enum_Social_Graph_Followers_State_Enum,
    isRecognizing: boolean
  ) => Promise<handleFollowActionResponseType>,
  triggerRecognizeButtonPressed?: any, // Not passed when is recognize back button from dashboard or notifications panel
  triggerAnalytics = false // set to false when is recognize back button from dashboard or notifications panel
): Promise<handleFollowActionResponseType> => {
  const { followId, resultData } = await handleFollowAction(contextId, targetContextId, newStateValue, isRecognizing);

  if (triggerAnalytics && triggerRecognizeButtonPressed) {
    triggerRecognizeButtonPressed(null);
  }

  return {
    followId,
    resultData,
  };
};
