import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import useAppSelector from 'common/hooks/useAppSelector';

export type artistByInterestDataType = {
  id: string;
  name: string;
  avatar: string;
  location: string;
  order: number;
  handle: string;
  artistCollectionId: number;
  artistCollectionToArtistContextId: number;
};

type artistsByInterestSliceType = {
  artistsByInterest: artistByInterestDataType[];
  totalArtists: number;
};

const initialState: artistsByInterestSliceType = {
  artistsByInterest: [],
  totalArtists: 0,
};

const artistsByInterestSlice = createSlice({
  name: 'artistsByInterest',
  initialState,
  reducers: {
    SET_TOTAL_ARTISTS: (state, action: PayloadAction<number>) => ({
      ...state,
      totalArtists: action.payload,
    }),
    LOAD_ARTISTS: (state, action: PayloadAction<artistByInterestDataType[]>) => ({
      ...state,
      artistsByInterest: [...state.artistsByInterest, ...action.payload],
    }),
    UPDATE_ARTISTS: (state, action: PayloadAction<artistByInterestDataType[]>) => ({
      ...state,
      artistsByInterest: action.payload,
    }),
    RESET_ARTISTS: (state) => {
      state.artistsByInterest = [];
    },
  },
});

export const useArtistsByInterestSlice = () => useAppSelector((state) => state.artistsByInterest);

export const artistsByInterestActions = artistsByInterestSlice.actions;

export default artistsByInterestSlice;
