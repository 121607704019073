import React, { RefObject } from 'react';
import { loadingLogoAnimationStyles } from './LoadingLogoAnimation.styles';

type Props = {
  svgRef: RefObject<SVGSVGElement>;
};

const LoadingLogoAnimation: React.FC<Props> = ({ svgRef }): JSX.Element => {
  const classes = loadingLogoAnimationStyles();
  const ANIMATION_DURATION = '2s';

  return (
    <div id="componentLogoContainer" className={classes.container}>
      <div className={classes.svgContainer}>
        <svg
          id="SVGLogo"
          ref={svgRef}
          viewBox="0 0 88 88"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="xMinYMin meet"
          className={classes.svgContent}
        >
          <title id="SVGLogo">Loading</title>
          <path id="triangleC4" d="M 0 0 H 44 L 22 22 L 0 0 Z" fill="url(#paint1_linear_901_1255)" />
          <path id="triangleC3" d="M 0 0 L 22 22 L 0 44 V 0 Z" fill="url(#paint1_linear_901_1255)" />
          <path id="triangleC8" d="M 0 0 H 88 L 66 22 L 44 0 Z" fill="url(#paint1_linear_901_1255)" />
          <path id="triangleC7" d="M 44 0 L 66 22 L 44 44 V 0 Z" fill="url(#paint1_linear_901_1255)" />
          <path id="triangleC2" d="M 0 44 H 44 L 22 66 L 0 44 Z" fill="url(#paint1_linear_901_1255)" />
          <path id="triangleC1" d="M 0 44 L 22 66 L 0 88 V 0 Z" fill="url(#paint1_linear_901_1255)" />
          <path id="triangleC5" d="M 44 44 V 44 L 66 66 L 44 88 Z" fill="url(#paint1_linear_901_1255)" />
          <path id="triangleC6" d="M 44 88 H 88 L 66 66 Z" fill="url(#paint1_linear_901_1255)" />

          <defs>
            <linearGradient
              xmlns="http://www.w3.org/2000/svg"
              id="paint1_linear_901_1255"
              x1="87.9998"
              y1="75.4287"
              x2="-43.8041"
              y2="-128.163"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0.0172383" stopColor="#68CAF3" />
              <stop offset="0.13974" stopColor="#7723E5" />
              <stop offset="0.270833" stopColor="#EE49B1" stopOpacity="0.841452" />
              <stop offset="0.465262" stopColor="#FFCF53" />
              <stop offset="1" stopColor="#DE4299" />
            </linearGradient>

            <animate
              id="triangleA4"
              href="triangleC4"
              xlinkHref="#triangleC4"
              attributeName="opacity"
              dur={ANIMATION_DURATION}
              repeatCount="indefinite"
              values="1;  1; 1;  0; 0;  0; 0;    0; 0;  1; 1;  1; 1;  1; 1; 1;    0;  0; 0;  0; 0;  0; 0;    0; 0;  1; 1;  1; 1;  1; 1;  1;"
              keyTimes="0; 0.06; 0.09; 0.12; 0.15; 0.18; 0.21; 0.24; 0.27; 0.31; 0.34; 0.37; 0.40; 0.43; 0.46; 0.49; 0.52; 0.55; 0.58; 0.6; 0.65; 0.68; 0.71; 0.74; 0.77; 0.80; 0.83; 0.86; 0.89; 0.93; 0.96; 1"
            />
            <animate
              xlinkHref="#triangleC3"
              attributeName="opacity"
              dur={ANIMATION_DURATION}
              repeatCount="indefinite"
              values="0;  0; 0;  0; 0;  0; 0;    0; 0;  0; 0;  1; 1;  1; 1; 1;    1;  1; 1;  0; 0;  0; 0;    0; 1;  1; 1;  1; 1;  1; 1;  1;"
              keyTimes="0; 0.06; 0.09; 0.12; 0.15; 0.18; 0.21; 0.24; 0.27; 0.31; 0.34; 0.37; 0.40; 0.43; 0.46; 0.49; 0.52; 0.55; 0.58; 0.6; 0.65; 0.68; 0.71; 0.74; 0.77; 0.80; 0.83; 0.86; 0.89; 0.93; 0.96; 1"
            />
            <animate
              xlinkHref="#triangleC8"
              attributeName="opacity"
              dur={ANIMATION_DURATION}
              repeatCount="indefinite"
              values="1;  1; 1;  1; 1;  1; 1;    1; 1;  1; 1;  1; 1;  1; 1; 1;    0;  0; 0;  0; 0;  0; 0;    0; 0;  0; 0;  1; 1;  1; 1;  1;"
              keyTimes="0; 0.06; 0.09; 0.12; 0.15; 0.18; 0.21; 0.24; 0.27; 0.31; 0.34; 0.37; 0.40; 0.43; 0.46; 0.49; 0.52; 0.55; 0.58; 0.6; 0.65; 0.68; 0.71; 0.74; 0.77; 0.80; 0.83; 0.86; 0.89; 0.93; 0.96; 1"
            />
            <animate
              xlinkHref="#triangleC7"
              attributeName="opacity"
              dur={ANIMATION_DURATION}
              repeatCount="indefinite"
              values="1;  1; 1;  1; 0;  0; 0;    1; 1;  1; 1;  1; 1;  1; 1; 1;    1;  0; 0;  0; 0;  0; 0;    0; 0;  1; 1;  1; 1;  1; 1;  1;"
              keyTimes="0; 0.06; 0.09; 0.12; 0.15; 0.18; 0.21; 0.24; 0.27; 0.31; 0.34; 0.37; 0.40; 0.43; 0.46; 0.49; 0.52; 0.55; 0.58; 0.6; 0.65; 0.68; 0.71; 0.74; 0.77; 0.80; 0.83; 0.86; 0.89; 0.93; 0.96; 1"
            />
            <animate
              xlinkHref="#triangleC2"
              attributeName="opacity"
              dur={ANIMATION_DURATION}
              repeatCount="indefinite"
              values="1;  1; 0;  0; 0;  0; 0;     0; 0;  1; 1;  1; 1;  1; 1; 1;     1;  1; 1;  1; 1;  0; 0;    0; 1;  1; 1;  1; 1;  1; 1;  1;"
              keyTimes="0; 0.06; 0.09; 0.12; 0.15; 0.18; 0.21; 0.24; 0.27; 0.31; 0.34; 0.37; 0.40; 0.43; 0.46; 0.49; 0.52; 0.55; 0.58; 0.6; 0.65; 0.68; 0.71; 0.74; 0.77; 0.80; 0.83; 0.86; 0.89; 0.93; 0.96; 1"
            />
            <animate
              xlinkHref="#triangleC1"
              attributeName="opacity"
              dur={ANIMATION_DURATION}
              repeatCount="indefinite"
              values="0;  0; 0;  0; 0;  0; 0;    0; 0;  0; 0;  1; 1;  1; 1; 1;    1;  1; 1;  1; 1;  1; 1;    1; 1;  1; 1;  1; 1;  1; 1;  1;"
              keyTimes="0; 0.06; 0.09; 0.12; 0.15; 0.18; 0.21; 0.24; 0.27; 0.31; 0.34; 0.37; 0.40; 0.43; 0.46; 0.49; 0.52; 0.55; 0.58; 0.6; 0.65; 0.68; 0.71; 0.74; 0.77; 0.80; 0.83; 0.86; 0.89; 0.93; 0.96; 1"
            />
            <animate
              xlinkHref="#triangleC5"
              attributeName="opacity"
              dur={ANIMATION_DURATION}
              repeatCount="indefinite"
              values="1;  1; 1;  0; 0;  0; 0;    0; 0;  1; 1;  1; 1;  1; 1; 1;    1;  1; 1;  0; 0;  0; 0;    0; 0;  1; 1;  1; 1;  1; 1;  1;"
              keyTimes="0; 0.06; 0.09; 0.12; 0.15; 0.18; 0.21; 0.24; 0.27; 0.31; 0.34; 0.37; 0.40; 0.43; 0.46; 0.49; 0.52; 0.55; 0.58; 0.6; 0.65; 0.68; 0.71; 0.74; 0.77; 0.80; 0.83; 0.86; 0.89; 0.93; 0.96; 1"
            />
            <animate
              xlinkHref="#triangleC6"
              attributeName="opacity"
              dur={ANIMATION_DURATION}
              repeatCount="indefinite"
              values="0;  0; 0;  0; 0;  0; 0;    0;  0; 0;  1; 1;  1; 1; 1; 1;    0;  0; 0;  0; 0;  0; 0;    0; 0;  0; 0;  1; 1;  1; 1;  1;"
              keyTimes="0; 0.06; 0.09; 0.12; 0.15; 0.18; 0.21; 0.24; 0.27; 0.31; 0.34; 0.37; 0.40; 0.43; 0.46; 0.49; 0.52; 0.55; 0.58; 0.6; 0.65; 0.68; 0.71; 0.74; 0.77; 0.80; 0.83; 0.86; 0.89; 0.93; 0.96; 1"
            />
          </defs>
        </svg>
      </div>
    </div>
  );
};

export default React.memo(LoadingLogoAnimation);
