import React, { useEffect } from 'react';

// const getDynamicLink = (redirectTo: string) =>
//   `https://kaleido.page.link/?apn=art.kaleido&ibi=software.amplified.kaleidoAR&isi=1453006620&ius=software.amplified.kaleidoAR&efr=1&link=https://kaleido.art?redirectURL=${redirectTo}`;

const DeepLinkingController: React.FC = ({ children }) => {
  // const isMobile = window.innerWidth <= 768;
  // const isUnityWebview = window.location.hash.includes('unityWebView:active');

  const queryParams = new URLSearchParams(window.location.search);
  const deepLinkTo = queryParams.get('deepLinkTo');

  useEffect(() => {
    if (!deepLinkTo) {
      return;
    }

    window.location.href = deepLinkTo;
    /*
    if (!isMobile || isUnityWebview) {
      window.location.href = deepLinkTo;
      return;
    }

    window.location.href = getDynamicLink(encodeURIComponent(deepLinkTo));
    */
  }, [deepLinkTo]);

  if (deepLinkTo) {
    return null;
  }

  return <>{children}</>;
};

export default DeepLinkingController;
