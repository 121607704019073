import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useRef, useState } from 'react';
import { ProvenanceData } from 'stacks/ProfileArtShowcase/components/ProfileArtShowcaseLeftSide/hooks/helpers';
import clsx from 'clsx';
import ProvenanceItem from 'stacks/ProfileArtShowcase/components/ProfileArtShowcaseLeftSide/components/ProfileArtShowcaseSidebar/tabs/helpers/ProvenanceItem';
import { provenanceContainerStyles } from './ProvenanceContainer.styles';

const ANIMATION_DURATION_SECONDS = 1.5;

type ProvenanceContainerProps = {
  provenance: ProvenanceData[];
  hasAnimationStarted: boolean;
};

const ProvenanceContainer: React.FC<ProvenanceContainerProps> = ({ provenance, hasAnimationStarted }) => {
  const { t } = useTranslation();
  const customClasses = provenanceContainerStyles();
  const interval = useRef(0);
  const [startAnimation, setStartAnimation] = useState(0);

  useEffect(() => {
    if (provenance && hasAnimationStarted) {
      const timeout = window.setTimeout(() => setStartAnimation((prev) => prev + 1), 200);
      window.clearInterval(interval.current);
      interval.current = window.setInterval(
        () => setStartAnimation((prev) => prev + 1),
        ANIMATION_DURATION_SECONDS * 1000
      );
      return () => {
        window.clearTimeout(timeout);
      };
    }
    return undefined;
  }, [provenance, hasAnimationStarted]);

  useEffect(() => {
    if (startAnimation >= provenance.length) {
      clearInterval(interval.current);
    }
  }, [startAnimation, provenance, interval]);

  return (
    <div className={customClasses.container}>
      <Typography variant="h5" className={customClasses.title}>
        {t('coaStrings.provenance')}
      </Typography>
      <div
        className={clsx(customClasses.content, customClasses.customProvenanceStyle)}
        style={{ marginTop: `${-68 * (provenance.length - Math.min(startAnimation, provenance.length))}px` }}
      >
        {provenance.map((item, index) => (
          <div
            className="fade-in"
            key={`item_${item.id}`}
            style={{
              animationPlayState: startAnimation > 0 && startAnimation <= provenance.length ? 'running' : 'paused',
              animationDelay: `${ANIMATION_DURATION_SECONDS * (provenance.length - index - 1)}s`,
            }}
          >
            <ProvenanceItem
              key={`provenance_item_${item.text}_${item.date}`}
              image={item.image}
              bottomText={item.text}
              topText={item.date}
              to={item?.to}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProvenanceContainer;
