import { createTheme } from '@material-ui/core/styles';
import createMixins from '@material-ui/core/styles/createMixins';
import { CreateCSSProperties } from '@material-ui/core/styles/withStyles';
import zIndex from '@material-ui/core/styles/zIndex';
import { CSSProperties } from 'react';

export type CreatableCSSProperties = CSSProperties | CreateCSSProperties<any>;
declare module '@material-ui/core/styles/createMixins' {
  interface Mixins {
    nonSelectableText: CreatableCSSProperties;
    textEllipsis: CSSProperties;
    flexRow: CSSProperties;
    flexColumn: CSSProperties;
    flexColumnCentered: CSSProperties;
    flexCentered: CSSProperties;
    flexSpaceBetween: CSSProperties;
    overflowNoScrollbar: CreatableCSSProperties;
    sectionTitle: CSSProperties;
    unselectable: CSSProperties;
    hidden: CSSProperties;
    absoluteFill: CSSProperties;
    fixedFullscreen: CSSProperties;
    rowItemsSpaced: (space?: number) => CreatableCSSProperties;
    textMaxRows: (maxRows: number) => CreatableCSSProperties;
    noScrollBar: CreatableCSSProperties;
    thinScrollBar: CreatableCSSProperties;
  }
}

const { breakpoints, spacing } = createTheme();

export const mixins = createMixins(breakpoints, spacing, {
  nonSelectableText: {
    userSelect: 'none',
    '-webkit-touch-callout': 'none' /* iOS Safari */,
    '-webkit-user-select': 'none' /* Chrome/Safari/Opera */,
    '-moz-user-select': 'none' /* Firefox */,
    '-ms-user-select': 'none' /* Internet Explorer/Edge */,
  },
  textEllipsis: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  sectionTitle: {
    fontSize: spacing(2.25),
    lineHeight: `${spacing(3.25)}px`,
    fontWeight: 600,
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  flexColumnCentered: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  flexCentered: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  flexSpaceBetween: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  overflowNoScrollbar: {
    overflow: 'auto',
    /* Hide scrollbar for Chrome, Safari and Opera */
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    msOverflowStyle: 'none' /* IE and Edge */,
    scrollbarWidth: 'none' /* Firefox */,
  },
  unselectable: {
    userSelect: 'none',
    msUserSelect: 'none',
    MozUserSelect: 'none',
    WebkitUserSelect: 'none',
    cursor: 'default',
  },
  hidden: {
    visibility: 'hidden',
    pointerEvents: 'none',
  },
  absoluteFill: {
    position: 'absolute',
    inset: 0,
  },
  fixedFullscreen: {
    inset: 0,
    zIndex: zIndex.modal,
    position: 'fixed',
    overflow: 'auto',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  rowItemsSpaced: (space?: number) => ({
    '& > :not(:last-child)': {
      marginRight: spacing(space || 1),
    },
  }),
  textMaxRows: (maxRows: number) => ({
    overflow: 'hidden',
    display: '-webkit-inline-box',
    lineClamp: maxRows || 1,
    '-webkit-line-clamp': maxRows || 1,
    '-webkit-box-orient': 'vertical',
  }),
  noScrollBar: {
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  thinScrollBar: {
    '&::-webkit-scrollbar': {
      width: 4,
      background: 'transparent',
      paddingRight: 10,
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#F2F2F2',
      borderRadius: '2px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#000000',
      height: '52px',
      borderRadius: '2px',
      borderRight: 'none',
      borderLeft: 'none',
    },
  },
});

export const customTheme = createTheme({
  mixins,
  palette: {
    background: {
      default: '255, 255, 255',
    },
    common: {
      black: 'black',
      white: 'white',
    },
  },
  overrides: {
    MuiSwitch: {
      root: {
        width: 32,
        height: 18,
        padding: 0,
        display: 'flex',
        overflow: 'visible',
        marginRight: '6px',
      },
      switchBase: {
        padding: 2,
        color: 'white',
        borderRadius: '57px',

        '&$checked': {
          transform: 'translateX(12px)',
          color: 'white !important',
          '& + $track': {
            opacity: 1,
            backgroundColor: 'black !important',
            borderColor: 'black',
          },
        },
        '&:hover': {
          backgroundColor: 'rgba(0,0,0,0.04)',
        },
      },
      input: {
        width: '32px',
        left: '-6px',
      },
      thumb: {
        width: 16,
        height: 16,
        boxShadow: 'none',
        color: 'white',
      },
      track: {
        border: '1px solid #D1D1D1',
        borderRadius: '57px',
        opacity: 1,
        backgroundColor: '#D1D1D1',
      },
    },
    MuiDrawer: {
      paper: {
        backgroundColor: 'rgba(255, 255, 255, 0.7)',
      },
    },
    MuiTabs: {
      root: {
        minHeight: '0',
        minWidth: '0',
        fontFamily: 'Poppins',
      },
      flexContainer: {
        [breakpoints.down(560)]: {
          justifyContent: 'space-between',
        },
      },
    },
    MuiTab: {
      root: {
        minHeight: '0',
        minWidth: '0 !important',
        padding: '0',
        fontFamily: 'Poppins',
        color: 'black',
      },
      textColorInherit: {
        color: 'black',
      },
    },
    MuiSelect: {
      root: {
        paddingLeft: '16px',
        border: '1px solid #C8C8C8',
        background: 'white',
      },
    },
    MuiButton: {
      root: {
        textTransform: 'none',
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        lineHeight: '21px',
        height: 40,
        fontSize: 16,
        color: '#353945',
        borderRadius: '6px',
        [breakpoints.down('xs')]: {
          fontSize: 12,
          height: 36,
        },
        whiteSpace: 'nowrap',
      },
      contained: {
        borderRadius: '6px',
        '&:hover': {
          [breakpoints.down(560)]: {
            backgroundColor: 'white !important',
          },
        },
      },
      containedSecondary: {
        borderRadius: '6px',
        background: '#40BB84 !important',
      },
      containedPrimary: {
        borderRadius: '6px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        fontFamily: 'Poppins',
        textTransform: 'none',
        minWidth: '0',

        backgroundColor: '#000000',
        color: '#FFFFFF ',

        '&:hover': {
          backgroundColor: 'black !important',
        },

        '&:focus': {
          outline: 'none',
          boxSizing: 'border-box',
          backgroundColor: '#000000 !important',
        },

        '&:disabled': {
          backgroundColor: 'rgba(0, 0, 0, 0.2)',
          color: 'rgba(255, 255, 255, 0.4) ',
        },
      },
      outlined: {
        borderRadius: '6px',

        color: '#121112',
        borderColor: 'rgba(0, 0, 0, 0.23)',
        backgroundColor: 'transparent',

        '& img': {
          filter: 'invert(0)',
        },

        '&:hover': {
          color: '#121112',
          borderColor: 'rgba(0, 0, 0, 0.23)',
          backgroundColor: 'transparent',
        },
        '&:focus': {
          outline: 'none',
          boxSizing: 'border-box',

          color: '#121112',
          borderColor: 'rgba(0, 0, 0, 0.23)',
          backgroundColor: 'transparent',
        },

        '&:disabled': {
          backgroundColor: 'rgba(255, 255, 255, 0.5)',
          color: 'rgba(0, 0, 0, 0.33)',
        },
      },
      outlinedPrimary: {
        borderRadius: '6px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        fontFamily: 'Poppins',
        textTransform: 'none',
        minWidth: '0',

        color: '#121112',
        borderColor: 'rgba(0, 0, 0, 0.23)',
        backgroundColor: 'white',
        '& img': {
          filter: 'invert(0)',
        },

        '&:hover': {
          backgroundColor: '#F9F9F9',
          border: '1px solid rgba(0, 0, 0, 0.23)',

          [breakpoints.down(560)]: {
            color: '#000000',
          },
        },
        '&:focus': {
          outline: 'none',
          backgroundColor: '#F9F9F9',
          border: '1px solid #000000',

          boxSizing: 'border-box',
        },

        '&:disabled': {
          backgroundColor: 'rgba(255, 255, 255, 0.5)',
          color: 'rgba(0, 0, 0, 0.33)',
        },
      },
      textPrimary: {
        color: '#121112',
        '&:disabled': {
          backgroundColor: 'transparent',
          color: ' rgba(0, 0, 0, 0.3);',
        },
      },
      sizeSmall: {
        fontSize: '14px',
        height: '36px',
      },
      sizeLarge: {
        fontSize: '16px',
      },
    },
    MuiBackdrop: {
      root: {
        background: 'rgb(255 255 255 / 80%) !important',
      },
    },
    MuiLink: {
      button: {
        fontSize: '14px',
      },
    },
    MuiIcon: {
      colorPrimary: {
        width: 20,
        height: 20,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& img': {
          filter: 'invert(0)',
        },
      },
    },
    MuiIconButton: {
      root: {
        borderRadius: '2px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'linear-gradient(0deg, #000000, #000000)',
        minWidth: '0',
        padding: '5px',
        '&:hover': {
          backgroundColor: '#f9f9f96e',
        },
        '&:focus': {
          outline: 'none',
          boxSizing: 'border-box',
        },
        '&:disabled': {
          opacity: 0.4,
          color: 'linear-gradient(0deg, rgba(0, 0, 0, 0.33), rgba(0, 0, 0, 0.33))',
        },
      },
      colorPrimary: {
        borderRadius: '2px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'linear-gradient(0deg, #000000, #000000)',
        boxSizing: 'border-box',
        minWidth: '0',
        padding: '4px',
        '&:hover': {
          backgroundColor: '#F1F1F16e',
        },
        '&:focus': {
          outline: 'none',
          border: '1px solid #838383',
          boxSizing: 'border-box',
        },
        '&:disabled': {
          border: '1px solid rgba(0, 0, 0, 0.2)',
          color: 'linear-gradient(0deg, rgba(0, 0, 0, 0.33), rgba(0, 0, 0, 0.33))',
        },
      },
    },
    MuiTypography: {
      root: {},
      h1: {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '72px',
        lineHeight: '108px',
        color: '#000000',
      },
      h2: {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '28px',
        lineHeight: '42px',
        [breakpoints.down('sm')]: {
          lineHeight: '35px',
        },
        [breakpoints.down('xs')]: {
          fontSize: '24px',
          lineHeight: '30px',
        },
      },
      h3: {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '24px',
        lineHeight: '33px',
      },
      h4: {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '20px',
        lineHeight: '30px',
      },
      h5: {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '20px',
      },
      h6: {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '18px',
        wordBreak: 'break-word',
        [breakpoints.down('xs')]: {
          lineHeight: '18px',
        },
      },
    },
    MuiTextField: {
      root: {
        fontSize: '16px',
        fontFamily: 'Poppins',
        "& .MuiInputLabel-outlined[data-shrink='false']": {
          transform: 'translate(14px, 16px) scale(1)',
        },
        '& input': {
          fontFamily: 'Poppins',
          fontStyle: 'normal',
          fontWeight: 'normal',
          fontSize: '16px',
          lineHeight: '18px',
          padding: 14,
        },
        '& textarea': {
          paddingRight: 12,
          [breakpoints.down('xs')]: {
            fontSize: 14,
          },
        },
        '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
          borderColor: '#D04418',
        },
        '& .PrivateNotchedOutline-legendLabelled-45': {
          color: '#D04418 ',
        },
        '& .MuiFormHelperText-root.Mui-error': {
          color: '#D04418 ',
          fontSize: 14,
          marginTop: 4,
        },
        '& .Mui-error': {
          marginLeft: 0,
          color: '#D04418 ',
        },
        '& p.MuiFormHelperText-root.MuiFormHelperText-contained': {
          position: 'absolute',
          top: '44px',
          marginLeft: 0,
        },
        '& .MuiFormHelperText-root': {
          marginTop: 0,
          fontSize: '12px',
          lineHeight: '16px',
        },
        '& .MuiFormHelperText-root.MuiFormHelperText-contained.Mui-error': {
          position: 'absolute',
          top: 44,
        },
        '& .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl.MuiInputBase-multiline.MuiOutlinedInput-multiline':
          {
            paddingRight: '0px',
            '&::-webkit-scrollbar': {
              width: '20px',
            },
          },
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: '6px',
        backgroundColor: 'white',
        '&$error $notchedOutline': {
          borderColor: '#D04418 ',
        },
      },
    },
    MuiDialog: {
      paper: {
        padding: '40px',
        boxSizing: 'border-box',
        borderRadius: '2px',
      },
    },
    MuiDialogTitle: {
      root: {
        padding: 0,
        margin: 0,
      },
    },
    MuiDialogContent: {
      root: {
        padding: 0,
        marginBottom: 40,
        marginTop: 40,
      },
    },
    MuiDialogActions: {
      root: {
        display: 'flex',
        justifyContent: 'flex-end',
        border: 'none',
        padding: 0,
      },
    },
    MuiSlider: {
      root: {
        display: 'flex',
        alignItems: 'center',
        '& span.MuiSlider-rail': {
          background: '#e2e2e2',
          opacity: 1,
          borderRadius: '10px',
          height: '8px',
        },
        '& span.MuiSlider-track': {
          color: '#e2e2e2',
        },
        '& span.MuiSlider-thumb.MuiSlider-thumbColorPrimary': {
          width: '24px',
          height: '24px',
          color: 'black',
          margin: '0px',
        },
      },
    },
    MuiLinearProgress: {
      root: {
        backgroundColor: 'linear-gradient(85.98deg, #FF9C65 3.7%, #D848EF 97.14%)',
        borderRadius: '2px',
        '& .MuiLinearProgress-bar.MuiLinearProgress-barColorPrimary.MuiLinearProgress-bar1Determinate': {
          background: 'linear-gradient(85.98deg, #FF9C65 3.7%, #D848EF 97.14%)',
        },
      },
    },
    MuiInputBase: {
      inputMultiline: {
        paddingRight: '0px',
      },
    },
    MuiCheckbox: {
      colorPrimary: {
        '&$checked': {
          color: '#121112',
        },
      },
    },
    MuiBadge: {
      badge: {
        minWidth: '18px',
        height: '18px',
        borderRadius: '100%',
        lineHeight: 0,
      },
    },
  },
});

export const defaultLightTheme = createTheme(customTheme);
export const defaultDarkTheme = createTheme(customTheme, {
  palette: {
    background: {
      default: '0, 0, 0',
    },
    common: {
      black: 'white',
      white: 'black',
    },
  },
  typography: {
    h5: {
      color: 'white',
    },
  },
  overrides: {
    MuiDrawer: {
      paper: {
        backgroundColor: 'rgba(35, 35, 35, 0.7)',
      },
    },
    MuiContainer: {
      root: {
        background: '#0D0D0D',
      },
    },
    MuiTab: {
      root: {
        color: 'white',
      },
      textColorInherit: {
        color: 'white',
      },
    },
    MuiSelect: {
      root: {
        borderColor: '#C8C8C8',
        background: 'rgba(13, 13, 13, 0.6)',
      },
    },
    MuiBackdrop: {
      root: {
        background: 'rgb(0 0 0 / 80%) !important',
      },
    },
    MuiIcon: {
      colorPrimary: {
        '& img': {
          filter: 'invert(1)',
        },
      },
    },
    MuiButton: {
      root: {
        color: 'black',
      },
      containedPrimary: {
        backgroundColor: 'white',
        color: '#0D0D0D',

        '&:hover': {
          backgroundColor: '#c2c2c2 !important',
        },

        '&:focus': {
          backgroundColor: '#c2c2c2 !important',
        },

        '&:disabled': {
          backgroundColor: 'rgba(255, 255, 255, 0.2)',
          color: 'rgba(255, 255, 255, 0.4)',
        },
      },
      outlined: {
        color: '#FFFFFF',
        borderColor: '#FFFFFF',
        backgroundColor: 'transparent',

        '& img': {
          filter: 'invert(1)',
        },

        '&:hover': {
          color: '#FFFFFF',
          borderColor: '#FFFFFF',
          backgroundColor: 'transparent',
        },

        '&:focus': {
          color: '#FFFFFF',
          borderColor: '#FFFFFF',
          backgroundColor: 'transparent',
        },

        '&:disabled': {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          color: 'rgba(255, 255, 255, 0.33)',
        },
      },
      outlinedPrimary: {
        color: '#FFFFFF',
        borderColor: '#FFFFFF',
        backgroundColor: 'black',
        '& img': {
          filter: 'invert(1)',
        },

        '&:hover': {
          backgroundColor: '#1e1e1e',
          border: '1px solid #FFFFFF',

          [breakpoints.down(560)]: {
            color: '#FFFFFF',
          },
        },
        '&:focus': {
          outline: 'none',
          backgroundColor: '#1e1e1e',
          border: '1px solid #FFFFFF',
        },

        '&:disabled': {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          color: 'rgba(255, 255, 255, 0.33)',
        },
      },
    },
  },
});
