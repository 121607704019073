const SvgGradientColor: React.FC = () => (
  <svg width="300" height="300" style={{ position: 'absolute', width: '0', height: '0' }}>
    <defs>
      <linearGradient id="linearColors" x1="100%" y1="100%" x2="0%" y2="0%">
        <stop offset="10.47%" style={{ stopColor: '#68CAF3' }} />
        <stop offset="37.25%" style={{ stopColor: '#7723E5' }} />
        <stop offset="66.25%" style={{ stopColor: '#EE49B1' }} />
        <stop offset="87.7%" style={{ stopColor: '#FFCF53' }} />
      </linearGradient>
    </defs>
  </svg>
);

export default SvgGradientColor;
