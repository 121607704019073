import { useShouldShowFeatureFlag } from 'common/features/FeatureFlag/hooks/useFeatureFlag';
import { TypeUtils } from 'common/utils/generalTypeUtils';
import { useEffect, useMemo } from 'react';
import { ShowcaseDetailsType } from '../utils/types';
import useEditionSizesCheapestPrints from './useEditionSizesCheapestPrints';

const useShowcaseDetailsCheapestPrints = (showcaseDetails: ShowcaseDetailsType) => {
  const [showPrintsFeature] = useShouldShowFeatureFlag('prints');

  const editionSizes = useMemo(() => {
    if (!showPrintsFeature) {
      return [];
    }
    return TypeUtils.Object.keys(showcaseDetails.editionsTab.editionSizesDetails);
  }, [showPrintsFeature, showcaseDetails.editionsTab.editionSizesDetails]);

  const editionBySizeIds = useMemo(
    () =>
      editionSizes
        .map((size) => showcaseDetails.editionsTab.editionSizesDetails[size]?.id)
        .filter<number>((id): id is number => !!id),
    [editionSizes]
  );

  const cheapestPrintsByEditionBySizeIds = useEditionSizesCheapestPrints(editionBySizeIds);

  useEffect(() => {
    editionSizes.forEach((size) => {
      const sizeDetails = showcaseDetails.editionsTab.editionSizesDetails[size];
      if (sizeDetails) {
        sizeDetails.startingPrice = cheapestPrintsByEditionBySizeIds[sizeDetails.id];
      }
    });
  }, [cheapestPrintsByEditionBySizeIds]);
};

export default useShowcaseDetailsCheapestPrints;
