import { interestsFilterHash } from 'common/features/Filters/predefined/Interests/interests.utils';
import useAppSelector from 'common/hooks/useAppSelector';
import { useEffect, useMemo } from 'react';
import useDebouncedState from 'common/hooks/utils/useDebouncedState';
import { radiusFilterHash } from 'stacks/Nearby/RadiusFilter/radius.utils';
import { boostSkewFilterHash } from 'common/features/Filters/predefined/BoostSkew/boostSkew.utils';
import { NearbyArtistsFiltersHash, LOADING_STATES } from '../store/list.types';
import { NearbyArtistsFiltersState } from '../store/filter.types';

// exported for use in the initial state
export const filtersHash = (state: NearbyArtistsFiltersState, suffix: string | number = ''): NearbyArtistsFiltersHash =>
  // using template string to match typing
  `${state.sort}_${[
    interestsFilterHash(state.interests),
    radiusFilterHash(state.currentRadius),
    boostSkewFilterHash(state.isBoostSkewed),
    /**
     * This was removed since it was breaking the logic behind isDefaultFilter function,
     * if you want to add it back, you need to update the isDefaultFilter function to ignore it
     * or update defaultFiltersForEveryRadius to use it from state
     */
    // state.refresh,
    suffix,
  ].join('_')}` as const;

export const useNearbyArtistsFiltersIdentifier = (contextId?: string | number): NearbyArtistsFiltersHash => {
  const filters = useAppSelector((store) => store.nearbyArtists.filter);
  const currentIdentifier = useMemo(() => filtersHash(filters, contextId), [filters, contextId]);
  const [appliedIdentifier, setAppliedIdentifier] = useDebouncedState(currentIdentifier, 750);
  const listStatus = useAppSelector((store) => store.nearbyArtists.list.data.listed.status);

  useEffect(() => {
    if (LOADING_STATES.includes(listStatus)) {
      return;
    }
    setAppliedIdentifier(currentIdentifier);
  }, [currentIdentifier, listStatus]);
  return appliedIdentifier;
};
