import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Maybe } from 'common/schema/commonSchemaRemoteTypes';

const routingSlice = createSlice({
  name: 'routing',
  initialState: {
    lastFailedConditionalRoute: '' as string,
    lastRoute: null as Maybe<string>,
    previousLastRoute: null as Maybe<string>,
  },
  reducers: {
    CONDITIONAL_ROUTE_FAILED: (state, action) => ({
      ...state,
      lastFailedConditionalRoute: action.payload,
    }),
    SET_LAST_ROUTE: (state, action: PayloadAction<Maybe<string>>) => ({
      ...state,
      lastRoute: action.payload,
      previousLastRoute: state.lastRoute,
    }),
  },
});

export default routingSlice;
