import useAppSelector from 'common/hooks/useAppSelector';
import { useEffect, useMemo } from 'react';
import useDebouncedState from 'common/hooks/utils/useDebouncedState';
import { RecognitionsModalFiltersHash } from '../store/list.types';
import { RecognitionsModalFiltersState } from '../store/filter.types';

// exported for use in the initial state
export const filtersHash = (
  state: RecognitionsModalFiltersState,
  suffix: string | number = ''
): RecognitionsModalFiltersHash =>
  // using template string to match typing
  `${state.currentSort}_${[suffix].join('_')}` as const;

export const useRecognitionsModalFiltersIdentifier = (contextId?: string | number): RecognitionsModalFiltersHash => {
  const filters = useAppSelector((store) => store.myRecognitionsModal.filter);
  const currentIdentifier = useMemo(() => filtersHash(filters, contextId), [filters, contextId]);
  const [appliedIdentifier, setAppliedIdentifier] = useDebouncedState(currentIdentifier, 500);
  useEffect(() => {
    setAppliedIdentifier(currentIdentifier);
  }, [currentIdentifier]);
  return appliedIdentifier;
};
