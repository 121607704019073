import { LinkProps } from 'react-router-dom';
import { kaleidoHistoryTracker } from 'common/features/KaleidoHistoryTracker/config/useKaleidoHistoryTracker';
import { ROUTES } from 'routes/_definition';
import UnstyledAnchor from './UnstyledAnchor';
import { useGoBackSkipConsecutiveShowcases } from './hooks/useGoBackSkipConsecutiveShowcases';

const GoBack: React.FC<Partial<LinkProps>> = (props) => {
  const goBackSkipConsecutiveShowcases = useGoBackSkipConsecutiveShowcases();
  const { to } = props;

  return kaleidoHistoryTracker.hasNavigatedOnKaleido() ? (
    <UnstyledAnchor
      {...props}
      to={{ ...(typeof to === 'object' ? to : {}), pathname: '.' }}
      onClick={(e) => {
        e.preventDefault();
        goBackSkipConsecutiveShowcases();
      }}
      onKeyPress={(e) => {
        e.preventDefault();
        goBackSkipConsecutiveShowcases();
      }}
    />
  ) : (
    <UnstyledAnchor {...props} to={ROUTES.LIVE} />
  );
};

export default GoBack;
