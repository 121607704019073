import { MinMaxPriceFilterState } from './minMaxPrice.types';

export const minMaxPriceValues = [50, 200, 500, 1000, 2500, 5000, 10000];

export const getDefaultMinMaxPriceFilter = (): MinMaxPriceFilterState => ({
  min: 0,
  max: 0,
});

export const defaultMinMaxPriceFilter = getDefaultMinMaxPriceFilter();
