/* eslint-disable max-len */

import useAppSelector from 'common/hooks/useAppSelector';
import { useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { eventTriggerActions, EventTriggerState } from '../store/eventTriggerSlice';

type Key = keyof EventTriggerState;
type Value<T extends Key> = EventTriggerState[T];
type Trig<T extends Key, V = Value<T> | null> = (v: V) => void;

declare function eventTrigger<T1 extends Key>(t1: T1): [Trig<T1>];
declare function eventTrigger<T1 extends Key, T2 extends Key>(t1: T1, t2: T2): [Trig<T1>, Trig<T2>];

export const useEventTriggerCaller: typeof eventTrigger = (...triggers: Key[]): any => {
  const dispatch = useDispatch();
  return useMemo(
    () => triggers.map((trigger) => (value: unknown) => dispatch(eventTriggerActions.set({ trigger, value }))),
    []
  );
};

declare function eventWatcher<T1 extends Key, V1 = Value<T1>>(t1: T1): [V1];
declare function eventWatcher<T1 extends Key, T2 extends Key, V1 = Value<T1>, V2 = Value<T2>>(t1: T1, t2: T2): [V1, V2];

export const useEventTriggerWatcher: typeof eventWatcher = (...triggers: Key[]): any =>
  useAppSelector(
    (state) => triggers.map((trigger) => state.eventTriggers[trigger]),
    (left, right) => left.length === right.length && left.findIndex((_, idx) => left[idx] !== right[idx]) === -1
  );

export const useEventTriggerWatchIncrement = (triggerValue: number): number => {
  const initialValue = useRef(triggerValue);
  return useMemo(() => triggerValue - initialValue.current, [triggerValue]);
};
