import { createSlice } from '@reduxjs/toolkit';
import { createSortMostRecentCaseReducer } from 'common/features/Filters/predefined/SortMostRecent/store/recent';
import { filterInitialState, getDefaultFilters } from './filter.defaults';
import { RecognitionsModalFiltersState } from './filter.types';

export const recognitionsModalFiltersSlice = createSlice({
  name: 'filter',
  initialState: filterInitialState,
  reducers: {
    RESET_RecognitionsModal: getDefaultFilters,
    SORT_BY_MOST_RECENT_RecognitionsModal: createSortMostRecentCaseReducer<RecognitionsModalFiltersState>(),
    SET_FILTER_RecognitionsModal: (state, action) => ({
      ...state,
      chosenFilter: action.payload,
    }),
  },
});
