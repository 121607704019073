import { Grid } from '@material-ui/core';
import { SkeletonText } from 'common/components/TypographyText/SkeletonText';
import React from 'react';
import { mixins } from 'utils/customTheme';
import { defaultAnimationState } from './ArtworkCardOnHover';
import { ArtworkInformationRow } from './ArtworkInformationRow';

const defaultDetailsTextStyles: React.CSSProperties = {
  color: 'white',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '20px',
  ...defaultAnimationState.toAnimate,

  maxWidth: '100%',
  ...mixins.textEllipsis,
};

export type ArtworkInformationOverlayProps = {
  showTopText?: boolean;
  title?: {
    left?: string;
    right?: string;
  };
  details?: string;
};
export type ArtworkInformationOverlayType = React.FC<ArtworkInformationOverlayProps>;
export const ArtworkInformationOverlay: ArtworkInformationOverlayType = ({ showTopText, title, details }) => (
  <Grid
    container
    spacing={1}
    alignItems="center"
    style={{
      padding: 16,
      paddingBottom: 8,
      position: 'relative',
      flexWrap: 'nowrap',
      maxWidth: 'calc(100% + 8px)',
    }}
  >
    <Grid container direction="column" className="details" style={defaultAnimationState.details}>
      {showTopText && <ArtworkInformationRow left={title?.left} right={title?.right} />}
      <SkeletonText text={details} style={defaultDetailsTextStyles} className="toAnimate" />
    </Grid>
  </Grid>
);
