import React, { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';
import { startCardMiddleSectionStyles } from './StartCardMiddleSection.styles';

type Props = {
  trimmedName: string;
  width: number;
};

export const StartCardMiddleSection: React.FC<Props> = ({ trimmedName, width }) => {
  const { t } = useTranslation();
  const middleTextLength = useMemo(
    () => t('collectorProfileStrings.profileViewPage.startCard.artCollectionOf').length,
    [t]
  );
  const classes = startCardMiddleSectionStyles({ middleTextLength, width });
  return (
    <div className={classes.startCardMiddleSectionContainer}>
      <Typography className={classes.middleText}>
        <Trans
          i18nKey="collectorProfileStrings.profileViewPage.startCard.artCollectionOf"
          t={t}
          values={{ name: trimmedName.toUpperCase() }}
        >
          <b>{t('collectorProfileStrings.profileViewPage.startCard.collection')}</b>
        </Trans>
      </Typography>
    </div>
  );
};
