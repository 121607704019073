import React, { useMemo } from 'react';
import { Grid, Theme } from '@material-ui/core';
import { RenderComponentProps } from 'masonic';
import useAppSelector from 'common/hooks/useAppSelector';
import { Enum_Social_Graph_Followers_State_Enum } from 'common/schema/commonSchemaRemoteTypes';
import { useSelectedProfile } from 'common/hooks/useSelectedProfile';
import { useDispatch } from 'react-redux';
import { GenericArtworkCardProps } from 'common/components/ArtworkCard/GenericArtworkCard/GenericArtworkCard';
import { profileToShowcaseAnimationActions } from 'store/slices/profileToShowcaseAnimationSlice';
import { getCDNImageUrl } from 'utils/CDNImages';
import { useTranslation } from 'react-i18next';
import { toStartCase } from 'stacks/Home/Artworks/components/HomeArtworksListElement';
import { formatDimensions } from 'stacks/Dashboard/common/ArtworkPublish/components/PublishArtwork/utils/publishArtworkUtils';
import AvailabilityDot from 'common/components/AvailabilityDot/AvailabilityDot';
import FollowButton from 'common/components/RecognizeButtonV2/components/FollowButton';
import { getCountryFullName } from 'common/hooks/useCountries';
import FavoritesButton from 'common/features/FavoritesButton/FavoritesButton';
import { useTheme } from '@material-ui/styles';
import useGetSwipeContainerProps from 'common/components/ArtworkCard/common/SwipeInfoContainer/hooks/useGetSwipeContainerProps';
import { ProfileCollectorCardProps } from './ProfileCollectorArtworkListElement';
import { CollectorArtPrizeCard } from './CollectorArtPrizeCard';

export const ProfileCollectorArtworkCard: React.ComponentType<
  RenderComponentProps<ProfileCollectorCardProps> & { isSquare?: boolean; isVisible: boolean }
> = ({ data: { artworkId, onCardClick, columnCount }, isVisible, width, index }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selectedProfile = useSelectedProfile();
  const defaultTheme: Theme = useTheme();
  const hasSmallCTA = width <= 600;

  const { artist, artwork } = useAppSelector((store) => store.entities.artworkCardsCache[artworkId]) ?? {};
  const swipeContainerProps = useGetSwipeContainerProps(index, artwork?.artworkId ?? 0);

  const isFavorite = useAppSelector(
    (store) =>
      !!store.favorites.favorites.find(
        (v) => v.artworkId === artwork?.artworkId && v.contextId === (selectedProfile?.contextId ?? 0)
      )
  );

  const recognitionState = useAppSelector(
    (store) => store.entities.recognitionsCache.outgoing[selectedProfile?.contextId ?? 0]?.state
  );

  const isRecognizing =
    recognitionState === undefined ? undefined : recognitionState === Enum_Social_Graph_Followers_State_Enum.Active;

  const availabilityDot = useMemo(() => {
    if (artwork?.lastTransactionId) {
      return 'COLLECTED';
    }
    return artwork?.isAvailableSale ? 'AVAILABLE' : 'UNAVAILABLE';
  }, [artwork?.isAvailableSale, artwork?.lastTransactionId]);

  const cardProps = useMemo<GenericArtworkCardProps>(() => {
    const cardTargetLinksProps: GenericArtworkCardProps['cardTargetLinksProps'] = {
      top:
        artist?.handle && artwork?.slug
          ? {
              to: `/${artist?.handle}/showcase/${artwork?.slug}`,
              onClick: () => {
                onCardClick?.();
                dispatch(profileToShowcaseAnimationActions.SET_ANIMATION({ animation: 'SHOWCASE_SHOW_ANIMATION' }));
              },
            }
          : { to: '#' },
      bottom: { to: artist?.handle ? `/${artist?.handle}` : '#', onClick: onCardClick },
    };

    const artistChipProps: GenericArtworkCardProps['artistChipProps'] = {
      image: !artist?.profileId
        ? undefined
        : getCDNImageUrl({
            target: 'Profile',
            purpose: 'Avatar',
            targetId: artist?.profileId ?? 0,
            fileId: artist?.avatar ?? 0,
            meta: {
              size: 'small',
            },
          }),
      avatarSize: hasSmallCTA ? '40px' : '40px',
      textItems: [
        { text: artist?.title ?? undefined },
        { text: [artist?.city, getCountryFullName(artist?.country || '')].join(', ') },
      ],
    };

    const imageSrc: GenericArtworkCardProps['imageProps'] = {
      src: !artwork?.artworkId
        ? undefined
        : getCDNImageUrl({
            target: 'Artwork',
            purpose: 'Primary',
            targetId: artwork?.originalArtworkId ?? artwork?.artworkId ?? 0,
            fileId: artwork?.thumbnailMetadataId ?? 0,
            meta: {
              size: 'large',
            },
          }),
    };
    const artworkInfoProps: GenericArtworkCardProps['artworkInfoProps'] = {
      title: artwork?.title ?? undefined,
      ctaStyle: {
        width: '38px',
        alignSelf: 'flex-end',
        marginBottom: '4px',
      },
      details: [
        artwork?.discipline
          ? t(`addArtworkStrings.artDetails.select.discipline.${toStartCase(artwork.discipline)}`)
          : t('addArtworkStrings.artDetails.select.discipline.undefined'),
        artwork?.width && artwork?.height
          ? formatDimensions({
              values: [artwork?.width ?? 0, artwork?.height ?? 0, artwork.depth ?? 0],
              unit: artwork?.measuringUnit ?? '',
            })
          : t('addArtworkStrings.artDetails.select.discipline.undefined'),
      ].join('  |  '),
      prefix: {
        title: <AvailabilityDot availability={availabilityDot} />,
      },
    };

    return {
      cardTargetLinksProps,
      artistChipProps,
      imageProps: imageSrc,
      artworkInfoProps,
    };
  }, [artwork, artist]);

  const cardCTA = !artist?.contextId ? undefined : (
    <FollowButton
      targetContextId={artist.contextId}
      predefine={hasSmallCTA ? 'SHORT_MEDIUM_LISTING_CARD_CTA' : 'TALL_WIDE_LISTING_CARD_CTA'}
      isInitiallyRecognizing={isRecognizing}
      isDarkMode={defaultTheme.palette.common.black === 'white'}
    />
  );

  const infoCTA = useMemo(
    () =>
      artwork?.artworkId ? (
        <Grid
          item
          container
          justifyContent="center"
          alignItems="center"
          style={{ width: '38px', height: '38px' }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <Grid
            item
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <FavoritesButton artworkId={artwork.artworkId} isFavorite={isFavorite} skipFavoritesListingReset />
          </Grid>
        </Grid>
      ) : undefined,
    [artwork?.artworkId, isFavorite]
  );

  return (
    <CollectorArtPrizeCard
      {...cardProps}
      cardCTA={cardCTA}
      swipeContainerProps={columnCount === 1 ? swipeContainerProps : undefined}
      artworkInfoProps={{
        ...(cardProps.artworkInfoProps ?? {}),
        infoCTA,
      }}
      showArtPrizes
      artworkId={artworkId}
      isVisible={isVisible}
    />
  );
};
