import { useCallback, useContext } from 'react';
import {
  convertibleCurrencies,
  currencyPossibilitiesToTypeMap,
  CurrencyTypeEnum,
} from 'common/components/CurrencySelector/CurrencySelector.formik';
import { UnitConversionContext } from 'common/contexts/UnitConversionProvider';
import { useShouldShowFeatureFlag } from 'common/features/FeatureFlag/hooks/useFeatureFlag';
import { isSomeEnum } from 'utils/utilFunctions';
import { useSelectedProfile } from './useSelectedProfile';
import { countryList } from './utils/countries';
import useAppSelector from './useAppSelector';
import { useCustomDomain } from './useCustomDomain';

type ConvertToPreferredCurrencyFnType = ({
  price,
  currency,
}: {
  price: number;
  currency: CurrencyTypeEnum | string | null | undefined;
}) => { price: number; currency: CurrencyTypeEnum };

type useShowPreferredCurrencyReturnType = {
  convertToPreferredCurrency: ConvertToPreferredCurrencyFnType;
};

export const usePreferredCurrency = (): CurrencyTypeEnum => {
  const profile = useSelectedProfile();
  const storeCurrency = useAppSelector((store) => store.account.preferredCurrency);
  const preferredCurrency = profile?.settings.currency;

  if (isSomeEnum(CurrencyTypeEnum)(preferredCurrency)) {
    return preferredCurrency;
  }
  if (isSomeEnum(CurrencyTypeEnum)(storeCurrency)) {
    return storeCurrency;
  }
  const possibleCountry = countryList.find((country) => country.iso2 === profile?.locations?.[0]?.country);
  if (possibleCountry?.currency) {
    return possibleCountry.currency;
  }

  return CurrencyTypeEnum.$;
};

const useShowPreferredCurrency = (): useShowPreferredCurrencyReturnType => {
  const outputCurrency = usePreferredCurrency();
  const { currencyConversionValues } = useContext(UnitConversionContext);
  const [shouldShowPreferredCurrency] = useShouldShowFeatureFlag('preferredCurrency');
  const isCustomDomain = !!useCustomDomain();

  const convertToPreferredCurrency: ConvertToPreferredCurrencyFnType = useCallback(
    ({ price, currency }) => {
      const inputCurrency: CurrencyTypeEnum = currencyPossibilitiesToTypeMap[currency ?? ''] ?? CurrencyTypeEnum.$;
      const noCurrencyConversion = { price, currency: inputCurrency };

      if (!shouldShowPreferredCurrency || !convertibleCurrencies.includes(inputCurrency) || isCustomDomain) {
        return noCurrencyConversion;
      }

      const startingCurrencyRate = currencyConversionValues[inputCurrency];
      const targetCurrencyRate = currencyConversionValues[outputCurrency];
      const priceInPreferredCurr = (price * startingCurrencyRate) / targetCurrencyRate;

      return { price: Math.ceil(priceInPreferredCurr), currency: outputCurrency };
    },
    [currencyConversionValues, outputCurrency, shouldShowPreferredCurrency]
  );

  return { convertToPreferredCurrency };
};

export default useShowPreferredCurrency;
