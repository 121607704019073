import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GetArtistProfileArtworkInfoQuery } from 'common/schema/commonSchemaRemoteOperationTypes';
import { Scalars } from 'common/schema/commonSchemaRemoteTypes';
import { NotNullish } from 'common/utils/types';

export type ProfileArtworkFavoritesCountDataType = NotNullish<
  GetArtistProfileArtworkInfoQuery['artworkFavoriteCounts']
>[0];

type ArtworkFavCountEntities = Record<Scalars['bigint'], ProfileArtworkFavoritesCountDataType>;

const initialState: ArtworkFavCountEntities = {};

export const artistProfileArtworkFavoritesCountSlice = createSlice({
  name: 'artistProfileArtworkFavoritesCount',
  initialState,
  reducers: {
    UPDATE_PROFILE_ARTWORK_FAVORITE_COUNT: (state, action: PayloadAction<ProfileArtworkFavoritesCountDataType[]>) => {
      action.payload.forEach((fav_count) => {
        if (!fav_count.artworkId) {
          return;
        }
        state[fav_count.artworkId] = fav_count;
      });
    },
  },
});
