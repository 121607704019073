import React, { useMemo } from 'react';
import { Theme, Typography, useMediaQuery } from '@material-ui/core';
import { startCardCollectorInfoStyles } from './StartCardCollectorInfo.styles';

type Props = {
  abreviation: string;
  location: string;
};

export const StartCardCollectorInfo: React.FC<Props> = ({ abreviation, location }) => {
  const classes = startCardCollectorInfoStyles();
  const isXs = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));
  const mobileLocation = useMemo(() => {
    const locationArray = location.replace(',', ',\n');
    if (isXs) return locationArray;
    return location;
  }, [isXs, location]);

  return (
    <div className={classes.collectorInfoContainer}>
      <Typography className={classes.abreviation}>{abreviation}</Typography>
      <Typography className={classes.location}>{mobileLocation}</Typography>
    </div>
  );
};
