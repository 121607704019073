import { Grid } from '@material-ui/core';
import React from 'react';
import { BasicImageType } from '../../Image/types';
import ProvenancePanel from '../ArtCollectedCard/components/ProvenancePanel';
import { ArtworkInformationType } from './ArtworkInformation';

export const ArtCollectedImageInfoLayout: React.FC<{
  children: [
    React.ReactElement<ArtworkInformationType>,
    React.ReactElement<BasicImageType>,
    React.ReactElement<typeof ProvenancePanel>
  ];
}> = ({ children }) => (
  <Grid container style={{ position: 'relative' }}>
    <Grid
      container
      style={{
        position: 'absolute',
        width: '100%',
        zIndex: 1,
        height: '100%',
        overflow: 'hidden',
      }}
      direction="column"
      justifyContent="flex-end"
      alignItems="flex-end"
    >
      <Grid
        item
        style={{
          width: '100%',
          backgroundImage: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.48) 100%)',
          borderRadius: 4,
        }}
      >
        {children[0]}
      </Grid>
    </Grid>
    <Grid item xs={12} style={{ width: '100%' }}>
      {children[2]}
      {children[1]}
    </Grid>
  </Grid>
);
