import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ArtworkInformation,
  hasAvailablePrints,
  isArtworkAvailable,
  KaliAutomatizationState,
  KaliAutomatizationSteps,
  PendingConfirmation,
} from './utils';

const getArtworkInformationDefault = () => ({
  country: '',
  city: '',
  region: undefined,
  city_id: '',
  admin_code: '',
  admin_name: '',
  numberOfCities: 0,
  zipCode: '',
  shippingOption: '',
  availability: undefined,
  pricing: [],
});

export const kaliAutomatizationState: KaliAutomatizationState = {
  pendingConfirmations: [],
  allConfirmationsAvailability: {},
  step: undefined,
  currentArtworkId: undefined,
  artworkInformation: getArtworkInformationDefault(),
};
interface ILocation {
  country: string;
  city: string;
  city_id: string;
  admin_code: string;
}

export const kaliAutomatizationSlice = createSlice({
  name: 'kaliAutomatization',
  initialState: kaliAutomatizationState,
  reducers: {
    SET_LOCATION: (state, action: PayloadAction<ILocation>) => {
      const { country, city, city_id, admin_code } = action.payload;
      state.artworkInformation.country = country;
      state.artworkInformation.city = city;
      state.artworkInformation.city_id = city_id;
      state.artworkInformation.admin_code = admin_code;
    },
    UPDATE_PENDING_CONFIRMATIONS: (
      state,
      action: {
        payload: {
          pendingConfirmations: PendingConfirmation[];
        };
      }
    ) => ({
      ...state,
      pendingConfirmations: action.payload.pendingConfirmations,
    }),
    OPEN_MODAL: (
      state,
      action: {
        payload: {
          currentArtworkId: number;
        };
      }
    ) => ({
      ...state,
      currentArtworkId: action.payload.currentArtworkId,
      step: KaliAutomatizationSteps.CONFIRM_ARTWORK_AVAILABILITY,
    }),
    CLOSE_MODAL: (state) => {
      const { pendingConfirmations, currentArtworkId: currentArtwork } = state;
      const filteredPendingConfirmations = pendingConfirmations.filter(
        (pendingConfirmation) => pendingConfirmation.artworkId !== currentArtwork
      );

      return {
        ...state,
        pendingConfirmations: filteredPendingConfirmations,
        allConfirmationsAvailability:
          filteredPendingConfirmations.length === 0 ? {} : state.allConfirmationsAvailability,
        step: undefined,
        currentArtworkId: undefined,
        artworkInformation: getArtworkInformationDefault(),
      };
    },
    GO_NEXT_STEP: (state) => {
      const {
        step: currentStep,
        artworkInformation: { availability, hasHiddenPrice, pricing, allPrintsHavePublicPrice },
      } = state;
      let nextStep = currentStep;

      switch (currentStep) {
        case KaliAutomatizationSteps.CONFIRM_ARTWORK_AVAILABILITY:
          if (isArtworkAvailable(availability) || hasAvailablePrints(pricing)) {
            if (hasHiddenPrice || (hasAvailablePrints(pricing) && !allPrintsHavePublicPrice)) {
              nextStep = KaliAutomatizationSteps.CONFIRM_PRICING;
              break;
            }
            nextStep = KaliAutomatizationSteps.CONFIRM_SHIPPING;
          } else {
            nextStep = KaliAutomatizationSteps.ORDER_UPDATED;
          }
          break;
        case KaliAutomatizationSteps.CONFIRM_PRICING:
          nextStep = KaliAutomatizationSteps.CONFIRM_SHIPPING;
          break;
        case KaliAutomatizationSteps.CONFIRM_SHIPPING:
          nextStep = KaliAutomatizationSteps.ORDER_UPDATED;
          break;
        case KaliAutomatizationSteps.ORDER_UPDATED:
        default:
          nextStep = undefined;
      }
      return {
        ...state,
        step: nextStep,
      };
    },
    GO_PREVIOUS_STEP: (state) => {
      const {
        step: currentStep,
        artworkInformation: { hasHiddenPrice, pricing, allPrintsHavePublicPrice },
      } = state;
      let previousStep = currentStep;

      switch (currentStep) {
        case KaliAutomatizationSteps.CONFIRM_PRICING:
          previousStep = KaliAutomatizationSteps.CONFIRM_ARTWORK_AVAILABILITY;
          break;
        case KaliAutomatizationSteps.CONFIRM_SHIPPING:
          if (hasHiddenPrice || (hasAvailablePrints(pricing) && !allPrintsHavePublicPrice)) {
            previousStep = KaliAutomatizationSteps.CONFIRM_PRICING;
            break;
          }
          previousStep = KaliAutomatizationSteps.CONFIRM_ARTWORK_AVAILABILITY;
          break;
        case KaliAutomatizationSteps.ORDER_UPDATED:
          previousStep = KaliAutomatizationSteps.CONFIRM_SHIPPING;
          break;
        case KaliAutomatizationSteps.CONFIRM_ARTWORK_AVAILABILITY:
        default:
          previousStep = undefined;
      }

      return {
        ...state,
        step: previousStep,
      };
    },
    UPDATE_PRICING: (
      state,
      action: {
        payload: {
          pricing: ArtworkInformation['pricing'];
        };
      }
    ) => {
      const { currentArtworkId } = state;
      if (!currentArtworkId) {
        return state;
      }

      return {
        ...state,
        artworkInformation: {
          ...state.artworkInformation,
          pricing: action.payload.pricing,
        },
      };
    },
    SET_HAS_HIDDEN_PRICE: (
      state,
      action: {
        payload: {
          hasHiddenPrice: boolean;
        };
      }
    ) => {
      const { currentArtworkId } = state;
      if (!currentArtworkId) {
        return state;
      }
      return {
        ...state,
        artworkInformation: {
          ...state.artworkInformation,
          hasHiddenPrice: action.payload.hasHiddenPrice,
        },
      };
    },
    UPDATE_ARTWORK_AVAILABILITY: (
      state,
      action: {
        payload: {
          artworkInformation?: Partial<ArtworkInformation>;
        };
      }
    ) => {
      const { currentArtworkId } = state;
      if (!currentArtworkId) {
        return state;
      }

      const [payloadPrint] = action.payload.artworkInformation?.pricing ?? [];
      const isAlreadyConfirmed =
        state.artworkInformation.pricing?.findIndex((print) => print?.printSize === payloadPrint?.printSize) !== -1;

      const availablePrintSizes = isAlreadyConfirmed
        ? state.artworkInformation.pricing?.map((print) => {
            if (print?.printSize === payloadPrint?.printSize) {
              return {
                ...print,
                ...payloadPrint,
              };
            }
            return print;
          })
        : [...(state.artworkInformation.pricing ?? []), payloadPrint ?? {}];

      return {
        ...state,
        artworkInformation: {
          ...state.artworkInformation,
          ...action.payload.artworkInformation,
          pricing: availablePrintSizes,
        },
        allConfirmationsAvailability:
          action.payload.artworkInformation?.availability || availablePrintSizes.length
            ? {
                ...state.allConfirmationsAvailability,
                [currentArtworkId]:
                  isArtworkAvailable(action.payload.artworkInformation?.availability) ||
                  hasAvailablePrints(availablePrintSizes),
              }
            : state.allConfirmationsAvailability,
      };
    },
    UPDATE_ARTWORK_INFORMATION: (
      state,
      action: {
        payload: {
          artworkInformation?: Partial<ArtworkInformation>;
        };
      }
    ) => {
      const { currentArtworkId } = state;
      if (!currentArtworkId) {
        return state;
      }

      const [payloadPrint] = action.payload.artworkInformation?.pricing ?? [];
      const isAlreadyConfirmed =
        state.artworkInformation.pricing?.findIndex((print) => print?.printSize === payloadPrint?.printSize) !== -1;

      const availablePrintSizes = isAlreadyConfirmed
        ? state.artworkInformation.pricing?.map((print) => {
            if (print?.printSize === payloadPrint?.printSize) {
              return {
                ...print,
                ...payloadPrint,
              };
            }
            return print;
          })
        : [...(state.artworkInformation.pricing ?? []), payloadPrint ?? {}];

      return {
        ...state,
        artworkInformation: {
          ...state.artworkInformation,
          ...action.payload.artworkInformation,
          pricing: availablePrintSizes,
        },
      };
    },
  },
});
export const kaliAutomatizationActions = kaliAutomatizationSlice.actions;
