import { SizeEnum, SizeFilterState } from './size.types';

export const getDefaultSizeFilter = (): SizeFilterState => ({
  size: {
    [SizeEnum.ALL]: true,
    [SizeEnum.SMALL]: false,
    [SizeEnum.MEDIUM]: false,
    [SizeEnum.LARGE]: false,
  },
});
export const defaultSizeFilter = getDefaultSizeFilter();
