import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { myDocumentsActions } from 'store/slices/myDocumentsSlice';
import { useGetGeneratedCertificatesCountLazyQuery } from 'common/schema/commonSchemaRemoteGraphqlQueries';
import useApolloErrorHandler from 'common/features/ErrorHandling/hooks/useApolloErrorHandler';
import { useSelectedProfile } from './useSelectedProfile';

export const useFetchUserDocumentsCount = () => {
  const dispatch = useDispatch();
  const contextId = useSelectedProfile()?.contextId ?? 0;

  const [getUserCertificatesCount, { error: certificatesCountError }] = useGetGeneratedCertificatesCountLazyQuery({
    fetchPolicy: 'cache-and-network',
  });
  useApolloErrorHandler(certificatesCountError);

  useEffect(() => {
    if (!contextId) {
      return;
    }
    (async () => {
      const { data: certificatesCount } = await getUserCertificatesCount({ variables: { contextId } });
      dispatch(myDocumentsActions.SET_HAS_DOCUMENTS((certificatesCount?.documents ?? 0) > 0));
    })();
  }, [dispatch, contextId, getUserCertificatesCount]);
};
