import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Location } from 'history';

export const PROFILE_ANIMATION_OUT_DURATION = 3000;
export type ShowcaseAnimationType =
  | 'PROFILE_DISASSEMBLE'
  | 'SHOWCASE_ASSEMBLE'
  | 'SHOWCASE_FADE'
  | 'SHOWCASE_QUERY_LOADING_DONE'
  | 'SHOWCASE_SHOW_BUTTONS'
  | 'SHOWCASE_SHOW_LOADING'
  | 'NO_ANIMATION'
  | 'SHOWCASE_SHOW_ANIMATION';

type ProfileToShowcaseAnimationType = {
  currentLocation?: Location;
  nextLocation?: Location;
  animation?: ShowcaseAnimationType;
  isShowcaseQueryLoading: boolean;
  showcaseIsDarkMode?: boolean;
  profileIsDarkMode?: boolean;
  mobileZoomedImageActive?: boolean;
};
const initialState: ProfileToShowcaseAnimationType = {
  currentLocation: undefined,
  nextLocation: undefined,
  animation: 'SHOWCASE_SHOW_LOADING', // Default Loading animation
  isShowcaseQueryLoading: true,
  showcaseIsDarkMode: undefined,
  profileIsDarkMode: undefined,
  mobileZoomedImageActive: false,
};

const profileToShowcaseAnimationSlice = createSlice({
  initialState,
  name: 'profileToShowcaseAnimation',
  reducers: {
    SET_CURRENT_LOCATION: (
      state,
      action: PayloadAction<{
        currentLocation?: Location;
      }>
    ) => {
      state.currentLocation = action.payload.currentLocation;
    },
    SET_NEXT_LOCATION: (
      state,
      action: PayloadAction<{
        nextLocation?: Location;
      }>
    ) => {
      state.nextLocation = action.payload.nextLocation;
    },
    SET_ANIMATION: (
      state,
      action: PayloadAction<{
        animation?: ShowcaseAnimationType;
      }>
    ) => {
      state.animation = action.payload.animation;
    },
    SET_IS_SHOWCASE_QUERY_LOADING: (
      state,
      action: PayloadAction<{
        isShowcaseQueryLoading: boolean;
      }>
    ) => {
      state.isShowcaseQueryLoading = action.payload.isShowcaseQueryLoading;
    },
    SET_PROFILE_IS_DARK_MODE: (
      state,
      action: PayloadAction<{
        profileIsDarkMode: boolean;
      }>
    ) => {
      state.profileIsDarkMode = action.payload.profileIsDarkMode;
    },
    SET_SHOWCASE_IS_DARK_MODE: (
      state,
      action: PayloadAction<{
        showcaseIsDarkMode?: boolean;
      }>
    ) => {
      state.showcaseIsDarkMode = action.payload.showcaseIsDarkMode;
    },
    SET_MOBILE_ZOOMED_IMAGE_ACTIVE: (
      state,
      action: PayloadAction<{
        mobileZoomedImageActive?: boolean;
      }>
    ) => {
      state.mobileZoomedImageActive = action.payload.mobileZoomedImageActive;
    },
  },
});

export const profileToShowcaseAnimationActions = profileToShowcaseAnimationSlice.actions;
export const profileToShowcaseAnimationReducer = profileToShowcaseAnimationSlice.reducer;

export default profileToShowcaseAnimationSlice;
