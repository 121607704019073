import { createSlice } from '@reduxjs/toolkit';

export interface arrangeArtworksModalState {
  isOpen: boolean;
}

const initialState: arrangeArtworksModalState = {
  isOpen: false,
};

const arrangeArtworksModalSlice = createSlice({
  name: 'arrangeArtworksModal',
  initialState,
  reducers: {
    OPEN_ARRANGE_ARTWORKS_MODAL: (state) => ({ ...state, isOpen: true }),
    CLOSE_ARRANGE_ARTWORKS_MODAL: (state) => ({ ...state, isOpen: false }),
  },
});

export const arrangeArtworksModalActions = arrangeArtworksModalSlice.actions;
export default arrangeArtworksModalSlice;
