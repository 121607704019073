import { getDefaultCountryFilter } from 'common/features/Filters/predefined/Country/store/country.defaults';
import { getDefaultTitleFilter } from 'common/features/Filters/predefined/Title/store/title.defaults';
import { ProfileGalleryFiltersState } from './filter.types';

export const getDefaultFilters = (): ProfileGalleryFiltersState => ({
  sort: 'byDate',
  ...getDefaultCountryFilter(),
  ...getDefaultTitleFilter(),
});

export const filterInitialState: ProfileGalleryFiltersState = getDefaultFilters();
