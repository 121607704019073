import { combineReducers } from '@reduxjs/toolkit';
import { listProfileMuseumArtworksSlice } from './listProfileMuseumArtworks.slice';
import { filterProfileMuseumArtworksSlice } from './filterProfileMuseumArtworks.slice';
import { displayProfileMuseumArtworksSlice } from './displayProfileMuseumArtworks.slice';

export const actions = {
  ...listProfileMuseumArtworksSlice.actions,
  ...filterProfileMuseumArtworksSlice.actions,
  ...displayProfileMuseumArtworksSlice.actions,
};

export const profileMuseumArtworksSlice = {
  profileMuseumArtworks: combineReducers({
    [listProfileMuseumArtworksSlice.name]: listProfileMuseumArtworksSlice.reducer,
    [filterProfileMuseumArtworksSlice.name]: filterProfileMuseumArtworksSlice.reducer,
    [displayProfileMuseumArtworksSlice.name]: displayProfileMuseumArtworksSlice.reducer,
  }),
};
