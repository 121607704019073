import VirtualList from 'common/features/VirtualList/VirtualList';
import { KnownVirtualListEnum, SortOptionsEnum } from 'common/features/VirtualList/VirtualList.config';
import { GetArtistManageArtworksListingByArtistOrderQuery } from 'common/schema/commonSchemaRemoteOperationTypes';

type RequiredArtworkId = { artworkId: number };
const {
  listingCursor: { getDefaultListingCursor, nextPage, prevPage },
  sortOptions: { generateSortOptionsAndDefault },
} = VirtualList;

export const [, optionsByArtistSelection] = generateSortOptionsAndDefault(SortOptionsEnum.ARTIST_SELECTION);
export type ByArtistSelectionSortOptions = (typeof optionsByArtistSelection)[number];
export type ByArtistSelectionCursor = RequiredArtworkId &
  GetArtistManageArtworksListingByArtistOrderQuery['artworks'][0];

export const cursorsByArtistSelection: Record<
  ByArtistSelectionSortOptions,
  [ByArtistSelectionCursor, ByArtistSelectionCursor]
> = {
  [SortOptionsEnum.ARTIST_SELECTION]: getDefaultListingCursor<ByArtistSelectionCursor>(
    { artworkId: prevPage.bigint.bigger },
    { artworkId: nextPage.bigint.smaller }
  ),
};

export const optionsForArtistManageArtworks = [...optionsByArtistSelection];
export const [defaultSortForArtistManageArtworks] = generateSortOptionsAndDefault(...optionsForArtistManageArtworks);
export type ArtistManageArtworksSortOptions = ByArtistSelectionSortOptions;
export type ArtistManageArtworksCursor = ByArtistSelectionCursor;

export const cursorsForArtistManageArtworks: Record<
  ArtistManageArtworksSortOptions,
  [ArtistManageArtworksCursor, ArtistManageArtworksCursor]
> = {
  ...cursorsByArtistSelection,
};

export const {
  listSlice: listArtistManageArtworksSlice,
  getSpecificCursor: getSpecificArtistManageArtworksCursor,
  getDefaultVirtualList: getDefaultArtistManageArtworksVirtualList,
} = VirtualList.slices.createListSlice<ArtistManageArtworksSortOptions, ArtistManageArtworksCursor>({
  defaultBatchSizes: {
    listedBatchSize: 128,
    detailedBatchSize: 32,
  },
  allSpecificCursors: cursorsForArtistManageArtworks,
  virtualListIdentifier: KnownVirtualListEnum.ARTIST_MANAGE_ARTWORKS,
  defaultFilterHash: '',
  defaultSortOption: SortOptionsEnum.ARTIST_SELECTION,
});
