import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SortOptionsEnum } from 'common/features/VirtualList/VirtualList.config';
import useAppSelector from 'common/hooks/useAppSelector';

const sorts = [SortOptionsEnum.MOST_RECENT, SortOptionsEnum.MOST_PARTICIPANTS, SortOptionsEnum.ALPHABETICAL] as const;

export type FeaturedPageSort = (typeof sorts)[number];

export const isFeaturedPageSort = (v: string): v is FeaturedPageSort => sorts.includes(v as FeaturedPageSort);

export interface featuredPageState {
  CURRENT_SORT: FeaturedPageSort;
}

const initialState: featuredPageState = {
  CURRENT_SORT: SortOptionsEnum.MOST_RECENT,
};

const featuredPageSlice = createSlice({
  name: 'featuredPage',
  initialState,
  reducers: {
    CHANGE_CURRENT_SORT: (state, action: PayloadAction<FeaturedPageSort>) => {
      state.CURRENT_SORT = action.payload;
    },
  },
});

export const useFeaturedPage = () => useAppSelector((state) => state.featuredPage);

export const featuredPageActions = featuredPageSlice.actions;

export default featuredPageSlice;
