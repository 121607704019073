import { createSlice } from '@reduxjs/toolkit';

export type ArtPrizeWinnersType = {
  artist_context_id: number;
};

export type ArtPrizesFilters = {
  FILTER: 'none' | 'PARTICIPANTS' | 'RECENT' | 'ALPHABETICAL';
  SORTING_TYPE: 0 | 1 | -1;
  CHOSEN_FILTER: 'most_recent';
};

export interface ArtPrizeData {
  title: string;
  subtitle: string;
  description: string;
  short_description: string;
  background_metadata_id: string;
  thumbnail_metadata_id: string;
  amount_participants: number;
  prize_show_slug: string;
  published_at: string;
  Art_prize_winners: ArtPrizeWinnersType;
}

export interface ArtPrizeDataState {
  artPrizes: ArtPrizeData[];
  sixMoreRecentArtPrizes: ArtPrizeData[];
  scrollYPosition: number; // It's no longer used.
  artPrizesFilters: ArtPrizesFilters;
  backButtonPath: string;
  queryChanged: number;
  queryChangedMoreRecent: number;
}

const initialState: ArtPrizeDataState = {
  artPrizes: [],
  sixMoreRecentArtPrizes: [],
  scrollYPosition: 0,
  backButtonPath: 'none',
  artPrizesFilters: { FILTER: 'RECENT', SORTING_TYPE: 1, CHOSEN_FILTER: 'most_recent' },
  queryChanged: 0,
  queryChangedMoreRecent: 0,
};

const artPrizesSlice = createSlice({
  name: 'featuredArtPrizes',
  initialState,
  reducers: {
    ADD_ART_PRIZES: (state, action) => {
      state.artPrizes = action.payload;
    },
    ADD_SIX_MORE_RECENT_ART_PRIZES: (state, action) => {
      state.sixMoreRecentArtPrizes = action.payload;
    },
    UPDATE_SCROLL_POSITION: (state, action) => {
      state.scrollYPosition = action.payload;
    },
    UPDATE_FILTERS: (state, action) => {
      state.artPrizesFilters = action.payload;
    },
    REFRESH: (state) => {
      state.queryChanged += 1;
    },
    REFRESH_MORE_RECENT: (state) => {
      state.queryChangedMoreRecent += 1;
    },
    UPDATE_BACK_BUTTON_PATH: (state, action) => {
      state.backButtonPath = action.payload;
    },
  },
});

export default artPrizesSlice;
