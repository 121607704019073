import { Typography, TypographyProps } from '@material-ui/core';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { formatNumberToLocale, getAcronym } from 'utils/utilFunctions';
import { getEditionTypeName } from '../../utils/publishPrintsUtils';

export type PrintNameProps = {
  edition?: string;
  index: number;
} & TypographyProps;
export const PrintName: React.FC<PrintNameProps> = ({ edition, index, style, ...props }) => {
  const { t } = useTranslation();
  return useMemo(() => {
    let printName = `${t('publish.prints.printLabel')} ${formatNumberToLocale(index)}`;
    if (edition?.length) {
      const editionTitle = getEditionTypeName(edition, t);
      const acronym = getAcronym(editionTitle);
      printName = `${acronym} ${printName}`;
    }
    return (
      <Typography style={{ ...style, whiteSpace: 'nowrap' }} {...props}>
        {printName}
      </Typography>
    );
  }, [edition, index, t]);
};

export default PrintName;
