import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ArtworkToTableType,
  DocumentsReadyQueueType,
  Enum_Generate_New_Certificate_Modal_Step_Type_Enum,
} from 'stacks/MyDocuments/utils/myDocumentsTypes';
import { Print } from './certificatesSlice';

export interface generateNewCertificateModalState {
  isOpen: boolean;
  currentStep: Enum_Generate_New_Certificate_Modal_Step_Type_Enum;
  selectedArtworks: number[];
  selectedPrints: number[];
  selectedPrintsInfo: Print[];
  certificatesReadyQueue: DocumentsReadyQueueType[];
  certificatesQueue: number[];
  staticSelectedArtworks: number[]; // Used for the count in the modal
  artworkToTable: ArtworkToTableType[]; // used to send the data to the myDocuments table
  currentPage: number;
  totalArtworks: number;
  hasArtworks: boolean;
}

const initialState: generateNewCertificateModalState = {
  isOpen: false,
  currentStep: Enum_Generate_New_Certificate_Modal_Step_Type_Enum.SELECT_ARTWORKS,
  selectedArtworks: [],
  selectedPrints: [],
  selectedPrintsInfo: [],
  certificatesQueue: [],
  certificatesReadyQueue: [],
  staticSelectedArtworks: [],
  artworkToTable: [],
  currentPage: 1,
  totalArtworks: 0,
  hasArtworks: false,
};

const generateNewCertificateModalSlice = createSlice({
  name: 'generateNewCertificateModal',
  initialState,
  reducers: {
    OPEN_GENERATE_NEW_CERTIFICATES_MODAL: (state) => ({ ...state, isOpen: true }),
    CLOSE_GENERATE_NEW_CERTIFICATES_MODAL: (state) => ({
      ...state,
      isOpen: false,
      currentStep: Enum_Generate_New_Certificate_Modal_Step_Type_Enum.SELECT_ARTWORKS,
      selectedArtworks: [],
      selectedPrints: [],
      selectedPrintsInfo: [],
    }),
    SET_CURRENT_STEP: (state, action: PayloadAction<Enum_Generate_New_Certificate_Modal_Step_Type_Enum>) => ({
      ...state,
      currentStep: action.payload,
    }),
    RESET_GENERATE_NEW_CERTIFICATE_MODAL_STATE: () => initialState,
    SET_SELECTED_ARTWORKS: (state, action: PayloadAction<number[]>) => ({ ...state, selectedArtworks: action.payload }),
    SET_STATIC_SELECTED_ARTWORKS: (state, action: PayloadAction<number[]>) => ({
      ...state,
      staticSelectedArtworks: action.payload,
    }),
    SET_ARTWORK_TO_TABLE: (state, action: PayloadAction<ArtworkToTableType[]>) => ({
      ...state,
      artworkToTable: action.payload,
    }),
    SET_SELECTED_PRINTS: (state, action: PayloadAction<number[]>) => ({ ...state, selectedPrints: action.payload }),
    SET_SELECTED_PRINTS_INFO: (state, action: PayloadAction<Print[]>) => ({
      ...state,
      selectedPrintsInfo: action.payload,
    }),
    SET_ARTWORKS_QUEUE: (state, action: PayloadAction<number[]>) => ({
      ...state,
      certificatesQueue: [...state.certificatesQueue, ...action.payload],
    }),
    DROP_FROM_ARTWORKS_QUEUE: (state, action: PayloadAction<number>) => ({
      ...state,
      certificatesQueue: state.certificatesQueue.filter((id) => id !== action.payload),
    }),
    SET_ARTWORKS_READY_QUEUE: (state, action: PayloadAction<DocumentsReadyQueueType>) => ({
      ...state,
      certificatesReadyQueue: [...state.certificatesReadyQueue, action.payload],
    }),
    RESET_ARTWORKS_READY_QUEUE: (state) => ({ ...state, certificatesReadyQueue: [] }),
    SET_CURRENT_PAGE: (state, action: PayloadAction<number>) => ({ ...state, currentPage: action.payload }),
    SET_TOTAL_ARTWORKS: (state, action: PayloadAction<number>) => ({ ...state, totalArtworks: action.payload }),
    SET_HAS_ARTWORKS: (state, action: PayloadAction<boolean>) => ({ ...state, hasArtworks: action.payload }),
  },
});

export const generateNewCertificateModalActions = generateNewCertificateModalSlice.actions;
export default generateNewCertificateModalSlice;
