import { Scalars } from 'common/schema/commonSchemaRemoteTypes';
import { HomeArtworksSort } from './filter.types';

export type HomeArtworksFiltersHash = `${HomeArtworksSort}_${string}`;

export const listingDirections = ['BACKWARD', 'FORWARD'] as const;
export type ListDirection = (typeof listingDirections)[0] | (typeof listingDirections)[1];

export const loadingIdentifier = 'LOADING' as const;
export type ListLoadingId = typeof loadingIdentifier;

export type ListLoaderStatus = 'COLD' | 'FINISHED' | 'IDLE' | `${ListLoadingId}_${ListDirection}`;

export type ListWindowLoaderStateType<ValuesType, StatusType> = {
  batchSize: number;
  values: Array<ValuesType>;
  status: StatusType;
  hasMore: [boolean, boolean]; // [backward, forward]
};

export type ListLoaderStateType<Typename extends HomeArtworksSort, CursorType, IdType = Scalars['bigint']> = {
  __typename: Typename;
  listed: ListWindowLoaderStateType<CursorType, ListLoaderStatus>;
  detailed: ListWindowLoaderStateType<IdType, ListLoaderStatus>;
  cursor: [CursorType, CursorType]; // [backward, forward]
  detailedToListedStartOffset: number;
  lastScrollPosition: number;
};

export type HomeArtworksListByDateCursor = {
  artistContextId: Scalars['bigint'];
  galleryContextId: Scalars['bigint'];
  artPrizeShowId: Scalars['bigint'];
  artworkId: Scalars['bigint'];
  publishedDate: Scalars['date'];
  eventType: string;
  collectedByCtxId: Scalars['bigint'];
  aspectRatio: number;
};
export type HomeArtworksListByDate = ListLoaderStateType<
  'byDate',
  HomeArtworksListByDateCursor,
  HomeArtworksListByDateCursor | null
>;

export type ListActionArgs = [
  ListDirection,
  HomeArtworksListByDate['__typename'],
  HomeArtworksListByDate['listed']['values']
];

export type DetailActionArgs = [
  ListDirection,
  HomeArtworksListByDate['__typename'],
  HomeArtworksListByDate['detailed']['values']
];

export type HomeArtworksListState = {
  identifier: HomeArtworksFiltersHash;
  type: HomeArtworksSort;
} & {
  type: 'byDate';
  data: HomeArtworksListByDate;
};

export type HomeArtworksListingCursor = HomeArtworksListByDateCursor;

export const isHomeArtworksListByDateCursor = (
  cursor: HomeArtworksListingCursor
): cursor is HomeArtworksListByDateCursor => (cursor as HomeArtworksListByDateCursor)?.publishedDate !== undefined;

export const LOADING_STATES: ListLoaderStatus[] = ['LOADING_BACKWARD', 'LOADING_FORWARD'];
