import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GetArtistProfileArtworkInfoQuery } from 'common/schema/commonSchemaRemoteOperationTypes';
import { Scalars } from 'common/schema/commonSchemaRemoteTypes';
import { NotNullish } from 'common/utils/types';

export type ProfileArtworkCardDataType = {
  artist: NotNullish<GetArtistProfileArtworkInfoQuery['artist']>[0];
  artwork: NotNullish<GetArtistProfileArtworkInfoQuery['artworks']>[0];
};

export const profileArtworkCardDataTypeGuard = {
  isArtist: (data: unknown): data is ProfileArtworkCardDataType['artist'] => {
    if (!data) {
      return false;
    }
    const artist = data as ProfileArtworkCardDataType['artist'];
    return !!artist.contextId && !!artist.profileId;
  },
  isArtwork: (data: unknown): data is ProfileArtworkCardDataType['artwork'] => {
    if (!data) {
      return false;
    }
    const artwork = data as ProfileArtworkCardDataType['artwork'];
    return !!artwork.artworkId && !!artwork.artistContextId;
  },
};

type ArtworkCardEntities = Record<Scalars['bigint'], ProfileArtworkCardDataType>;

const initialState: ArtworkCardEntities = {};

export const artistProfileArtworkCardsSlice = createSlice({
  name: 'artistProfileArtworkCards',
  initialState,
  reducers: {
    UPDATE_PROFILE_ARTWORK_CARDS: (state, action: PayloadAction<ProfileArtworkCardDataType[]>) => {
      action.payload.forEach((card) => {
        if (!card.artwork.artworkId) {
          return;
        }
        state[card.artwork.artworkId] = card;
      });
    },
  },
});
