import { CustomDomainRouteParamsType, RouteParamsType } from 'routes/_definition';

const createPath = (...args: RouteParamsType | CustomDomainRouteParamsType): string => {
  const [path, params] = args;

  if (typeof params === 'undefined') {
    return path;
  }

  return Object.entries(params).reduce(
    (previousValue: string, [param, value]) => previousValue.replace(`:${param}`, value),
    path
  );
};

export default createPath;
