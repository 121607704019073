import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { actions as recognitionsV2Actions } from 'stacks/RecognitionsV2/store';

type ResetRecognitionListingCallback = () => void;

const useResetMyRecognitionsListing = (props?: { skipReset?: boolean }): ResetRecognitionListingCallback => {
  const dispatch = useDispatch();
  const { skipReset } = props ?? {};
  const nothingToDo = useCallback(() => undefined, []);
  const resetListing = useCallback(() => dispatch(recognitionsV2Actions.RESET_LIST_Recognitions()), [dispatch]);

  if (skipReset) {
    return nothingToDo;
  }

  return resetListing;
};

export default useResetMyRecognitionsListing;
