import { combineReducers } from 'redux';
import { homeArtistsListSlice } from './list.slice';
import { homeArtistsFiltersSlice } from './filter.slice';
import { homeArtistsDisplaySlice } from './display.slice';

export const homeArtistsActions = {
  ...homeArtistsListSlice.actions,
  ...homeArtistsFiltersSlice.actions,
  ...homeArtistsDisplaySlice.actions,
};

export const homeArtistsSlice = {
  homeArtists: combineReducers({
    [homeArtistsListSlice.name]: homeArtistsListSlice.reducer,
    [homeArtistsFiltersSlice.name]: homeArtistsFiltersSlice.reducer,
    [homeArtistsDisplaySlice.name]: homeArtistsDisplaySlice.reducer,
  }),
};
