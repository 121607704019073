import { CaseReducerActions, createSlice, Slice } from '@reduxjs/toolkit';
import { getDefaultListReduxFragment, ListReduxFragment } from '../components/listReduxFragment';
import { getDefaultListState } from '../components/listState';
import { KnownVirtualListEnum, SortOptionsEnum } from '../VirtualList.config';
import { ColdResetReducer, createColdResetVirtualListReducer } from '../reducers/cold.reset';
import { ColdStartReducer, createColdStartVirtualListReducer } from '../reducers/cold.start';
import { AppendDetailedReducer, createAppendDetailedCaseReducer } from '../reducers/detailed.append';
import { createRequestDetailedCaseReducer, RequestDetailedReducer } from '../reducers/detailed.request';
import { AppendListedReducer, createAppendListedCaseReducer } from '../reducers/listed.append';
import { createRequestListedCaseReducer, RequestListedReducer } from '../reducers/listed.request';

export type CreateVirtualListSliceProps<SortSubset extends SortOptionsEnum, CursorType> = {
  defaultBatchSizes: {
    listedBatchSize: number;
    detailedBatchSize: number;
  };
  allSpecificCursors: Record<SortSubset, [CursorType, CursorType]>;
  defaultFilterHash: string;
  virtualListIdentifier: KnownVirtualListEnum;
  defaultSortOption: SortSubset;
};

export type ReduxListSliceType<SortSubset extends SortOptionsEnum, CursorType> = Slice<
  ListReduxFragment<SortSubset, CursorType>,
  {
    COLD_RESET: ColdResetReducer<ListReduxFragment<SortSubset, CursorType>>;
    COLD_START: ColdStartReducer<ListReduxFragment<SortSubset, CursorType>>;
    REQUEST_LISTED: RequestListedReducer<ListReduxFragment<SortSubset, CursorType>>;
    APPEND_LISTED: AppendListedReducer<ListReduxFragment<SortSubset, CursorType>>;
    REQUEST_DETAILED: RequestDetailedReducer<ListReduxFragment<SortSubset, CursorType>>;
    APPEND_DETAILED: AppendDetailedReducer<ListReduxFragment<SortSubset, CursorType>>;
  },
  'list'
>;

export type ReduxListSliceActions<SortSubset extends SortOptionsEnum, CursorType> = CaseReducerActions<{
  COLD_RESET: ColdResetReducer<ListReduxFragment<SortSubset, CursorType>>;
  COLD_START: ColdStartReducer<ListReduxFragment<SortSubset, CursorType>>;
  REQUEST_LISTED: RequestListedReducer<ListReduxFragment<SortSubset, CursorType>>;
  APPEND_LISTED: AppendListedReducer<ListReduxFragment<SortSubset, CursorType>>;
  REQUEST_DETAILED: RequestDetailedReducer<ListReduxFragment<SortSubset, CursorType>>;
  APPEND_DETAILED: AppendDetailedReducer<ListReduxFragment<SortSubset, CursorType>>;
}>;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const createListSlice = <SortSubset extends SortOptionsEnum, CursorType>({
  defaultBatchSizes,
  allSpecificCursors,
  defaultFilterHash,
  virtualListIdentifier,
  defaultSortOption,
}: CreateVirtualListSliceProps<SortSubset, CursorType>) => {
  const getSpecificCursor = (sort: SortSubset): [CursorType, CursorType] => [
    { ...allSpecificCursors[sort][0] },
    { ...allSpecificCursors[sort][1] },
  ];

  const getDefaultVirtualList = () => {
    const [backwardCursor, forwardCursor] = getSpecificCursor(defaultSortOption);
    return getDefaultListReduxFragment<SortSubset, CursorType>({
      currentSort: defaultSortOption,
      filtersHash: defaultFilterHash,
      ...defaultBatchSizes,
      backwardCursor,
      forwardCursor,
    });
  };

  type State = ListReduxFragment<SortSubset, CursorType>;

  const listSlice = createSlice({
    name: 'list',
    initialState: getDefaultVirtualList(),
    reducers: {
      COLD_RESET: createColdResetVirtualListReducer(virtualListIdentifier, getDefaultVirtualList),
      COLD_START: createColdStartVirtualListReducer<State>(virtualListIdentifier, (sort) => {
        const [backwardCursor, forwardCursor] = getSpecificCursor(sort);
        return getDefaultListState<CursorType>({
          ...defaultBatchSizes,
          backwardCursor,
          forwardCursor,
        });
      }),
      REQUEST_LISTED: createRequestListedCaseReducer<State>(virtualListIdentifier),
      APPEND_LISTED: createAppendListedCaseReducer<State>(virtualListIdentifier),
      REQUEST_DETAILED: createRequestDetailedCaseReducer<State>(virtualListIdentifier),
      APPEND_DETAILED: createAppendDetailedCaseReducer<State>(virtualListIdentifier),
    },
  });

  return {
    listSlice,
    getDefaultVirtualList,
    getSpecificCursor,
  };
};
