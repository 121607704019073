import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SortableSection } from 'stacks/Dashboard/Artist/ArtistManageCard/components/ReorderSections';
import { Enum_Social_Link_Type_Enum } from 'common/schema/commonSchemaRemoteTypes';

const initialState: SortableSection[] = [
  { id: Enum_Social_Link_Type_Enum.Personal, section: Enum_Social_Link_Type_Enum.Personal, order: 0 },
  { id: Enum_Social_Link_Type_Enum.Press, section: Enum_Social_Link_Type_Enum.Press, order: 1 },
  { id: Enum_Social_Link_Type_Enum.Marketplace, section: Enum_Social_Link_Type_Enum.Marketplace, order: 2 },
  { id: Enum_Social_Link_Type_Enum.Media, section: Enum_Social_Link_Type_Enum.Media, order: 3 },
];

const biolinkSectionsSlice = createSlice({
  name: 'biolinkSections',
  initialState,
  reducers: {
    SET_ORDER: (_state, action: PayloadAction<SortableSection[]>) => [...action.payload],
    RESET_ORDER: () => initialState,
  },
});

export const biolinkSectionsActions = biolinkSectionsSlice.actions;
export default biolinkSectionsSlice;
