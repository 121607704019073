import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ONBOARD_PREFS } from 'stacks/OnboardingV2/ProfileTypeSelect/hooks/useGetOptions';

export type RegisterParamsState = {
  intent: {
    name: string;
    id: number;
  } | null;
  onboard: {
    type: string;
  } | null;
  register: {
    language: string;
    firstName: string;
    token: number;
    email: string;
    invitationTokenId?: number | string;
    profileType?: ONBOARD_PREFS;
  };
  displayData: {
    name: string;
    image: string;
  } | null;
};

const initialState: RegisterParamsState = {
  intent: null,
  onboard: null,
  register: {
    language: '',
    firstName: '',
    token: 0,
    email: '',
    invitationTokenId: 0,
    profileType: undefined,
  },
  displayData: null,
};

const registerParamsSlice = createSlice({
  name: 'registerParams',
  initialState,
  reducers: {
    SET_INTENT: (
      state,
      action: PayloadAction<{
        name: string;
        id: number;
      }>
    ) => {
      state.intent = action.payload;
    },
    SET_ONBOARD_TYPE: (state, action: PayloadAction<{ type: string }>) => {
      state.onboard = action.payload;
    },
    SET_LANGUAGE: (state, action: PayloadAction<string>) => {
      state.register = { ...state.register, language: action.payload };
    },
    SET_PROFILE_TYPE: (state, action: PayloadAction<ONBOARD_PREFS>) => {
      state.register = { ...state.register, profileType: action.payload };
    },
    SET_REGISTER_PREFERENCES: (
      state,
      action: PayloadAction<{
        language: string;
        firstName: string;
        token: number;
        email: string;
        invitationTokenId?: number | string;
      }>
    ) => {
      state.register = {
        ...action.payload,
        language: action.payload.language ? action.payload.language : state.register.language,
      };
    },
    SET_DISPLAY_DATA: (
      state,
      action: PayloadAction<{
        name: string;
        image: string;
      }>
    ) => {
      state.displayData = action.payload;
    },
  },
});

export const registerParamsActions = registerParamsSlice.actions;
export default registerParamsSlice;
