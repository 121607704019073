import { Explore_Artists_Bool_Exp, Explore_Artists_Order_By } from 'common/schema/commonSchemaRemoteTypes';
import {
  AppliedListingModifier,
  BaseOptionValueType,
  FILTERS_DEFAULT_VALUE,
  isOptionType,
  PROFILE_LOCAL_CONDITION,
  PROFILE_LOCAL_CONDITION_PAYLOAD,
  PROFILE_LOCAL_SORT,
  PROFILE_LOCAL_SORT_PAYLOAD,
  PROFILE_QUERY_CONDITION,
  PROFILE_QUERY_CONDITION_IN,
  PROFILE_QUERY_SORT,
} from '../config/types';

type AppliedFilters = {
  profileQueryCondition: Explore_Artists_Bool_Exp;
  profileSortCondition: Explore_Artists_Order_By;
  localSort: PROFILE_LOCAL_SORT_PAYLOAD;
  localFilter: PROFILE_LOCAL_CONDITION_PAYLOAD;
};
export const concatAppliedListModifiers = (
  appliedListingModifiers: AppliedListingModifier[],
  extraFilters?: Explore_Artists_Bool_Exp[]
): AppliedFilters => ({
  profileQueryCondition: appliedListingModifiers.reduce<Explore_Artists_Bool_Exp>(
    (appliedCondition, currentModifier) => {
      const flattenedOptions = currentModifier.flattenSelectedOptions(
        currentModifier.allOptions,
        currentModifier.selectedOptions
      );
      if (
        !isOptionType<PROFILE_QUERY_CONDITION>(flattenedOptions, 'PROFILE_QUERY_CONDITION') &&
        !isOptionType<PROFILE_QUERY_CONDITION_IN>(flattenedOptions, 'PROFILE_QUERY_CONDITION_IN')
      ) {
        return appliedCondition;
      }
      return {
        _and: [...(appliedCondition._and ?? []), flattenedOptions.payload],
      };
    },
    extraFilters?.length ? { _and: [...extraFilters] } : {}
  ),
  profileSortCondition: appliedListingModifiers.reduce<Explore_Artists_Order_By>(
    (appliedQuerySort, currentModifier) => {
      const flattenedOptions = currentModifier.flattenSelectedOptions(
        currentModifier.allOptions,
        currentModifier.selectedOptions
      );
      if (!isOptionType<PROFILE_QUERY_SORT>(flattenedOptions, 'PROFILE_QUERY_SORT')) {
        return appliedQuerySort;
      }
      return flattenedOptions.payload;
    },
    {}
  ),
  localFilter: appliedListingModifiers.reduce<PROFILE_LOCAL_CONDITION_PAYLOAD>(
    (appliedLocalFilter, currentModifier) => {
      const flattenedOptions = currentModifier.flattenSelectedOptions(
        currentModifier.allOptions,
        currentModifier.selectedOptions
      );
      if (!isOptionType<PROFILE_LOCAL_CONDITION>(flattenedOptions, 'PROFILE_LOCAL_CONDITION')) {
        return appliedLocalFilter;
      }
      return (artwork) => appliedLocalFilter(artwork) && flattenedOptions.payload(artwork);
    },
    () => true
  ),
  localSort: appliedListingModifiers.reduce<PROFILE_LOCAL_SORT_PAYLOAD>(
    (appliedLocalSort, currentModifier) => {
      const flattenedOptions = currentModifier.flattenSelectedOptions(
        currentModifier.allOptions,
        currentModifier.selectedOptions
      );
      if (!isOptionType<PROFILE_LOCAL_SORT>(flattenedOptions, 'PROFILE_LOCAL_SORT')) {
        return appliedLocalSort;
      }
      return (a, b) => appliedLocalSort(a, b) || flattenedOptions.payload(a, b);
    },
    () => 0
  ),
});

export const selectOptionFromAppliedModifier = (
  appliedListingModifier: AppliedListingModifier,
  optionValue: BaseOptionValueType
): BaseOptionValueType[] => {
  if (optionValue === FILTERS_DEFAULT_VALUE) return [FILTERS_DEFAULT_VALUE];
  const type = appliedListingModifier.selectionType;
  const { selectedOptions } = appliedListingModifier;
  const isAtIndex = selectedOptions.indexOf(optionValue);
  const usedOptions = [...selectedOptions];

  if (isAtIndex > -1) {
    usedOptions.splice(isAtIndex, 1);
    if (!usedOptions.length) return [FILTERS_DEFAULT_VALUE];
    return usedOptions;
  }

  if (type === 'SINGLE') {
    return [optionValue];
  }

  return [...usedOptions.filter((x) => x !== FILTERS_DEFAULT_VALUE), optionValue];
};
