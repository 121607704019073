import { useCallback, useEffect, useMemo } from 'react';
import useAsyncState from './utils/useAsyncState';

export type LazyPaginatorHookProps<T> = {
  initialItems?: T[];
  totalItems: number;
  itemsPerPage?: number;
  fetchPageItems: (page: number) => Promise<T[]>;
  cachePageItems?: boolean;
};

type LazyPaginatorHookReturn<T> = [number, T[], number, (value: number) => void, boolean];

export default function useLazyPaginator<T>({
  initialItems,
  totalItems,
  itemsPerPage = 10,
  fetchPageItems,
  cachePageItems = false,
}: LazyPaginatorHookProps<T>): LazyPaginatorHookReturn<T> {
  const [page, setPage] = useAsyncState(1);
  const [pageItems, setPageItems] = useAsyncState<T[]>(initialItems ?? []);
  const [pagedItems, setPagedItems] = useAsyncState<{ [page: number]: T[] | undefined }>({});
  const pageCount = useMemo(() => Math.ceil(totalItems / itemsPerPage), [itemsPerPage, totalItems]);

  const [loading, setLoading] = useAsyncState(false);

  const handlePageChange = useCallback(
    async (value: number) => {
      setLoading(true);
      const cachedItems = pagedItems[value];
      let items = cachedItems;
      if (!cachePageItems || !cachedItems?.length) {
        items = await fetchPageItems(value);
      }
      if (items?.length) {
        setPageItems(items);
      }
      if (cachePageItems && !pagedItems[value]?.length) {
        setPagedItems((prev) => {
          prev[value] = items;
          return prev;
        });
      }
      setLoading(false);
      setPage(value);
    },
    [cachePageItems, pagedItems, fetchPageItems, setPagedItems]
  );

  useEffect(() => {
    if (!initialItems) {
      handlePageChange(1);
    }
  }, []);

  useEffect(() => {
    if (page > pageCount) {
      setPage(pageCount);
    }
  }, [page, pageCount]);

  return [page, pageItems, pageCount, handlePageChange, loading];
}
