import useAppSelector from 'common/hooks/useAppSelector';
import { useEffect, useMemo } from 'react';
import useDebouncedState from 'common/hooks/utils/useDebouncedState';
import { countryFilterHash } from 'common/features/Filters/predefined/Country/country.utils';
import { titleFilterHash } from 'common/features/Filters/predefined/Title/title.utils';
import { ProfileGalleryFiltersHash, LOADING_STATES } from '../store/list.types';
import { ProfileGalleryFiltersState } from '../store/filter.types';

// exported for use in the initial state
export const filtersHash = (
  state: ProfileGalleryFiltersState,
  suffix: string | number = ''
): ProfileGalleryFiltersHash =>
  // using template string to match typing
  `${state.sort}_${[countryFilterHash(state.country), titleFilterHash(state.title), suffix].join('_')}` as const;

export const useProfileGalleryFiltersIdentifier = (contextId?: string | number): ProfileGalleryFiltersHash => {
  const filters = useAppSelector((store) => store.profileGallery.filter);
  const currentIdentifier = useMemo(() => filtersHash(filters, contextId), [filters, contextId]);
  const [appliedIdentifier, setAppliedIdentifier] = useDebouncedState(currentIdentifier, 750);
  const listStatus = useAppSelector((store) => store.profileGallery.list.data.listed.status);

  useEffect(() => {
    if (LOADING_STATES.includes(listStatus)) return;
    setAppliedIdentifier(currentIdentifier);
  }, [currentIdentifier, listStatus]);
  return appliedIdentifier;
};
