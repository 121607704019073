import { createSlice } from '@reduxjs/toolkit';

const visitingAccountSlice = createSlice({
  name: 'visitingAccount',
  initialState: {
    profileId: 0 as number,
    profileContextId: 0 as number,
  },
  reducers: {
    setValues: (state, action) => ({
      ...state,
      profileId: action.payload.profileId,
      profileContextId: action.payload.profileContextId,
    }),
  },
});

export default visitingAccountSlice;
