import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { ArtworksFilterState } from './artworks.types';

export type SelectArtworkIdsAction = PayloadAction<ArtworksFilterState>;

export const createSelectArtworkIdsCaseReducer =
  <State extends ArtworksFilterState>(): CaseReducer<State, SelectArtworkIdsAction> =>
  (state, action) => {
    state.ids = action.payload.ids;
  };
