import { createSlice } from '@reduxjs/toolkit';

export interface ArtShowcaseState {
  currentImage: string;
  primaryImage: string;
  secondaryImages: string[];
  processImages: string[];
  currentShowType: 'PRIMARY' | 'SECONDARY' | 'STORY';
  cachedUrls: string[];
  isMouseMoving: boolean;
  isDarkMode: boolean;
  userLastInquiryDate: string | null;
}

const initialState: ArtShowcaseState = {
  currentImage: '',
  primaryImage: '',
  secondaryImages: [],
  processImages: [],
  currentShowType: 'PRIMARY',
  cachedUrls: [],
  isMouseMoving: true,
  isDarkMode: false,
  userLastInquiryDate: null,
};

// TODO: FIX
const handleCacheImage = (url: string, state: any) => {
  if (state.cachedUrls.indexOf(url) === -1) {
    const imageSource = new Image();
    imageSource.src = url;
    state.cachedUrls = [...state.cachedUrls, url];
  }
};

const profileArtShowcaseSlice = createSlice({
  name: 'artShowcase',
  initialState,
  reducers: {
    RESET: (state) => {
      state.currentImage = '';
      state.primaryImage = '';
      state.processImages = [];
      state.secondaryImages = [];
      state.cachedUrls = [];
    },
    SET_CURRENT_IMAGE: (state, action) => {
      state.currentImage = action.payload;

      const index = state.processImages.indexOf(action.payload);
      if (index !== -1) {
        const processImageIndex = Math.min(state.processImages.length - 1, index + 1);
        handleCacheImage(state.processImages[processImageIndex], state);
      }

      if (state.cachedUrls.indexOf(action.payload) === -1) {
        state.cachedUrls = [...state.cachedUrls, action.payload];
      }
    },
    SET_PRIMARY_IMAGE: (state, action) => {
      state.primaryImage = action.payload;

      handleCacheImage(action.payload, state);
      state.currentImage = action.payload;
      state.currentShowType = 'PRIMARY';
    },
    SET_SECONDARY_IMAGES: (state, action) => {
      state.secondaryImages = action.payload;

      state.secondaryImages.forEach((image: string) => handleCacheImage(image, state));
    },
    SET_PROCESS_IMAGES: (state, action) => {
      state.processImages = action.payload;

      state.processImages.forEach((image: string) => handleCacheImage(image, state));
    },
    SET_CURRENT_SHOW_TYPE: (state, action) => {
      state.currentShowType = action.payload;
    },
    SET_IS_MOUSE_MOVING: (state, action) => {
      state.isMouseMoving = action.payload;
    },
    SET_IS_DARK_MODE: (state, action) => {
      state.isDarkMode = action.payload;
    },
    SET_NEXT_PROCESS_IMAGE: (state) => {
      if (!state.processImages.length) {
        return;
      }

      if (state.currentShowType !== 'STORY') {
        state.currentShowType = 'STORY';
        state.currentImage = state.processImages[0];
      } else {
        const index = state.processImages.indexOf(state.currentImage);
        state.currentImage = state.processImages[index + 1 >= state.processImages.length ? 0 : index + 1];
      }
    },
    SET_PREVIOUS_PROCESS_IMAGE: (state) => {
      if (!state.processImages.length) {
        return;
      }

      if (state.currentShowType !== 'STORY') {
        state.currentShowType = 'STORY';
        state.currentImage = state.processImages[0];
      } else {
        const index = state.processImages.indexOf(state.currentImage);
        state.currentImage = state.processImages[index - 1 === -1 ? state.processImages.length - 1 : index - 1];
      }
    },
    SET_LAST_INQUIRY_DATE: (state, action) => {
      state.userLastInquiryDate = action.payload;
    },
  },
});

export const profileArtShowcaseActions = profileArtShowcaseSlice.actions;
export default profileArtShowcaseSlice;
