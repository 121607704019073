import { useGetLastInquiryDateLazyQuery } from 'common/schema/commonSchemaRemoteGraphqlQueries';
import { GetLastInquiryDateQuery } from 'common/schema/commonSchemaRemoteOperationTypes';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { parseDate } from 'utils/utilFunctions';
import { profileArtShowcaseActions } from '../store/profileArtShowcaseSlice';

export const useGetLastInquiryDate = (contextId?: number, artworkId?: number): GetLastInquiryDateQuery | undefined => {
  const [getLastInquiryDate, { data: lastInquiryDate }] = useGetLastInquiryDateLazyQuery({
    fetchPolicy: 'network-only',
  });

  const dispatch = useDispatch();

  useEffect(() => {
    if (!contextId && artworkId) {
      dispatch(profileArtShowcaseActions.SET_LAST_INQUIRY_DATE(null));
    }
  }, [artworkId]);

  useEffect(() => {
    if (!contextId || !artworkId) {
      return;
    }

    getLastInquiryDate({
      variables: {
        context_sender_id: contextId,
        artwork_id: artworkId,
      },
    });
  }, [contextId, artworkId]);

  useEffect(() => {
    if (!lastInquiryDate) {
      return;
    }
    const purchases = lastInquiryDate.Purchase_inquires || [];

    if (purchases.length && purchases[0].expires_at) {
      const expireDate = new Date(purchases[0].expires_at?.toString()).getTime();
      const now = new Date().getTime();

      // if (!expireDate || expireDate - now > 0) { // keep message on
      if (expireDate - now > 0) {
        dispatch(profileArtShowcaseActions.SET_LAST_INQUIRY_DATE(parseDate(purchases[0].created_at.toString()) || ''));
      }
    } else {
      dispatch(profileArtShowcaseActions.SET_LAST_INQUIRY_DATE(null));
    }
  }, [lastInquiryDate]);

  return lastInquiryDate;
};
