import { Enum_Size_Enum } from 'common/schema/commonSchemaRemoteTypes';
import { ConfirmShippingFormType } from '../hooks/useConfirmShippingForm';

export type PendingConfirmation = {
  artworkId?: number;
  historyId?: number;
  journeyId?: number;
};

type ConfirmAvailability = {
  [artworkId: number]: boolean;
};

export enum ArtworkAvailability {
  AVAILABLE = 'AVAILABLE',
  UNAVAILABLE = 'UNAVAILABLE',
  SOLD = 'SOLD',
}

export type ConfirmedPrintSizesType = {
  price?: number;
  currency?: string;
  printSize?: Enum_Size_Enum;
  availability: ArtworkAvailability;
}[];

export const isArtworkAvailable = (availability?: ArtworkAvailability): boolean =>
  availability === ArtworkAvailability.AVAILABLE;

export const isPrintAvailable = (
  confirmedPrintSizes: ConfirmedPrintSizesType,
  currentPrintSize?: Enum_Size_Enum
): boolean =>
  confirmedPrintSizes.find((print) => print?.printSize === currentPrintSize)?.availability ===
  ArtworkAvailability.AVAILABLE;

export const isPrintUnavailable = (
  confirmedPrintSizes: ConfirmedPrintSizesType,
  currentPrintSize?: Enum_Size_Enum
): boolean =>
  confirmedPrintSizes.find((print) => print?.printSize === currentPrintSize)?.availability ===
  ArtworkAvailability.UNAVAILABLE;

export const isPrintSold = (confirmedPrintSizes: ConfirmedPrintSizesType, currentPrintSize?: Enum_Size_Enum): boolean =>
  confirmedPrintSizes.find((print) => print?.printSize === currentPrintSize)?.availability === ArtworkAvailability.SOLD;

export const hasAvailablePrints = (confirmedPrintSizes: ConfirmedPrintSizesType): boolean =>
  confirmedPrintSizes.some((print) => print?.availability === ArtworkAvailability.AVAILABLE);

export const isArtworkUnavailable = (availability?: ArtworkAvailability): boolean =>
  availability === ArtworkAvailability.UNAVAILABLE;

export const isArtworkSold = (availability?: ArtworkAvailability): boolean => availability === ArtworkAvailability.SOLD;

export type ArtworkInformation = {
  availability?: ArtworkAvailability;
  hasHiddenPrice?: boolean;
  allPrintsHavePublicPrice?: boolean;
  pricing: ConfirmedPrintSizesType;
} & ConfirmShippingFormType;

export type KaliAutomatizationState = {
  pendingConfirmations: PendingConfirmation[];
  allConfirmationsAvailability: ConfirmAvailability;
  step?: KaliAutomatizationSteps;
  currentArtworkId?: number;
  artworkInformation: ArtworkInformation;
};

export enum KaliAutomatizationSteps {
  CONFIRM_ARTWORK_AVAILABILITY = 'CONFIRM_ARTWORK_AVAILABILITY',
  CONFIRM_PRICING = 'CONFIRM_PRICING',
  CONFIRM_SHIPPING = 'CONFIRM_SHIPPING',
  ORDER_UPDATED = 'ORDER_UPDATED',
}

export const isKaliAutomatizationModalOpen = (currentArtworkId?: number): boolean =>
  currentArtworkId !== undefined && currentArtworkId !== 0;
