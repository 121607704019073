import { ROUTES } from 'routes/_definition';
import { useCallback, useMemo } from 'react';
import { useLocation } from 'react-router';
import useAppSelector from 'common/hooks/useAppSelector';
import { EventTriggers } from 'common/features/EventTrigger/store/eventTriggerSlice';
import zIndex from 'common/utils/zIndex';

export const LIGHT_MODE = 'rgba(255, 255, 255, 0.70)';
export const DARK_MODE = 'rgba(0, 0, 0, 0.70)';
export const SAFE_AREA_BOTTOM = 'max(env(safe-area-inset-bottom), 0px)';
export const SAFE_AREA_BOTTOM_MIN10 = 'calc(max(env(safe-area-inset-bottom), 10px) - 10px)';
export const SAFE_AREA_BOTTOM_DEPRECATED = '0px';
export const SAFE_AREA_TOP_DEPRECATED = '0px';
export const SAFE_AREA_TOP = 'calc(max(env(safe-area-inset-top), 5px) - 5px)';
export const SAFE_AREA_TOP_CLEAN = 'max(env(safe-area-inset-top), 0px)';
export const SAFE_AREA_TOP_NAVBAR = 'calc(max(env(safe-area-inset-top) - 14px, 0px))';
export const SAFE_AREA_BOTTOM_NAVBAR = 'calc(max(env(safe-area-inset-bottom) - 14px, 0px))';

type GlassmorphicNotchProps = {
  position: 'TOP' | 'BOTTOM';
  background?: string;
  customHeight?: string;
};

const ROUTES_WITH_TOP_BAR_SCROLL = [
  ROUTES.LIVE,
  ROUTES.EXPLORE,
  ROUTES.COLLECTIONS,
  ROUTES.PRIZES,
  ROUTES.FEATURED,
  ROUTES.NEARBY,
  ROUTES.SHOP,
  ROUTES.HOME,
];

const GlassmorphicNotch: React.FC<GlassmorphicNotchProps> = ({ background = LIGHT_MODE, position, customHeight }) => {
  const { pathname } = useLocation();
  const isProfile = useAppSelector((store) => store.eventTriggers[EventTriggers.IsProfile]);
  const height = useMemo(() => {
    switch (position) {
      case 'TOP': {
        return SAFE_AREA_TOP;
      }
      case 'BOTTOM': {
        return SAFE_AREA_BOTTOM;
      }
      default: {
        return '0px';
      }
    }
  }, [position]);

  // Only top bar is clickable
  const onClick = useCallback(() => {
    if (position !== 'TOP') {
      return;
    }
    if (!ROUTES_WITH_TOP_BAR_SCROLL.includes(pathname as ROUTES) && !isProfile) {
      return;
    }
    document.querySelector('.document-scroll')?.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, [pathname, position, isProfile]);

  return (
    <div
      onClick={onClick}
      style={{
        position: 'fixed',
        top: position === 'TOP' ? '0px' : undefined,
        bottom: position === 'BOTTOM' ? '0px' : undefined,
        left: '0px',
        right: '0px',
        width: '100%',
        height: customHeight ?? height,
        background,
        zIndex: zIndex.values.GLASSMORPHIC_NOTCH,
        backdropFilter: 'blur(20px)',
        WebkitBackdropFilter: 'blur(20px)',
      }}
    />
  );
};

export default GlassmorphicNotch;
