import { getDefaultArtistContextIdFilter } from 'common/features/Filters/predefined/ArtistContextId/store/artistContextId';
import { getDefaultDateFilter } from 'common/features/Filters/predefined/Date/store/date.defaults';
import { getDefaultRefreshFilter } from 'common/features/Filters/predefined/Refresh/refresh.defaults';
import { getDefaultArtworkIdsFilter } from 'common/features/Filters/predefined/Artworks/store/artworks.defaults';
import { HomeArtworksFiltersState } from './filter.types';

export const getDefaultFilters = (): HomeArtworksFiltersState => ({
  sort: 'byDate',
  ...getDefaultRefreshFilter(),
  ...getDefaultArtistContextIdFilter(),
  since: {
    ...getDefaultDateFilter(),
  },
  until: {
    ...getDefaultDateFilter(),
  },
  artworks: {
    ...getDefaultArtworkIdsFilter(),
  },
});

export const filterInitialState: HomeArtworksFiltersState = getDefaultFilters();
