export enum AuthStatusEnum {
  'PENDING' = 'PENDING', // Default initial state -> Firebase state is not concluded yet

  'LOGGED_OUT' = 'LOGGED_OUT', // User is logged out
  'AUTHENTICATED' = 'AUTHENTICATED', // User is Authenticated with Firebase -> Profile Provider is not concluded yet

  'REGISTERED' = 'REGISTERED', // User is logged in and has an Incomplete Profile
  'LOGGED_IN' = 'LOGGED_IN', // User is logged in and has a Complete Profile
}
export const AUTH_STATE_CHANGE_EVENT = 'AUTH_STATE_CHANGE_EVENT';
export const DEFAULT_AUTH_STATUS = AuthStatusEnum.PENDING;

export type AuthStateProps = {
  status: AuthStatusEnum;
};

export type AccountDetails = {
  firstName: string;
  lastName: string;
  language: string;
  invitationToken: string;
};

export type GoogleProfileInfo = {
  email?: string;
  family_name?: string;
  given_name?: string;
  picture?: string;
  locale?: string;
};

export type ParsedUserInfo = Pick<AccountDetails, 'firstName' | 'lastName' | 'language'> & {
  email: string;
  profilePicture?: string;
};
