import useAppSelector from 'common/hooks/useAppSelector';
import { LoadMoreItemsCallback } from 'masonic';
import { useCallback, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { HasMoreInListingDirections, listingDirections } from '../components/listingDirections';
import { LIST_LOADING_STATES } from '../components/listingStatus';
import { ListReduxFragment } from '../components/listReduxFragment';
import { ReduxListSliceActions } from '../slices/createListSlice';
import { KnownVirtualListEnum, SortOptionsEnum } from '../VirtualList.config';

type Selector<SortSubset extends SortOptionsEnum, CursorType> = (
  reduxStore: any
) => ListReduxFragment<SortSubset, CursorType>;

const useDetailsTrailSize = <SortSubset extends SortOptionsEnum, CursorType>(
  selector: Selector<SortSubset, CursorType>
): [number, number] => {
  const backwardTrailingSize = 0;
  const forwardTrailingSize = useAppSelector(
    (store) => selector(store).data.listed.values.length - selector(store).data.detailed.values.length
  );
  return useMemo(() => [backwardTrailingSize, forwardTrailingSize], [backwardTrailingSize, forwardTrailingSize]);
};

const useHasMoreDetails = <SortSubset extends SortOptionsEnum, CursorType>(
  selector: Selector<SortSubset, CursorType>
): HasMoreInListingDirections => {
  const hasMoreBackward = useAppSelector((store) => selector(store).data.detailed.hasMore[0]);
  const hasMoreForward = useAppSelector((store) => selector(store).data.detailed.hasMore[1]);
  return useMemo(() => [hasMoreBackward, hasMoreForward], [hasMoreBackward, hasMoreForward]);
};

export const generateUseLoadMoreItemsCallback =
  <SortSubset extends SortOptionsEnum, CursorType>(
    list: KnownVirtualListEnum,
    actions: ReduxListSliceActions<SortSubset, CursorType>,
    selector: Selector<SortSubset, CursorType>
  ) =>
  (): {
    loadMoreItemsCallback: LoadMoreItemsCallback<unknown>;
    batchSize: number;
    hasMore: HasMoreInListingDirections;
    detailsTrailSize: [number, number];
  } => {
    const dispatch = useDispatch();

    const isDetailsLoading = useAppSelector((store) =>
      LIST_LOADING_STATES.includes(selector(store).data.detailed.status)
    );
    const isListingLoading = useAppSelector((store) =>
      LIST_LOADING_STATES.includes(selector(store).data.listed.status)
    );
    const identifier = useAppSelector((store) => selector(store).identifier);
    const batchSize = useAppSelector((store) => selector(store).data.detailed.batchSize);
    const hasMore = useHasMoreDetails(selector);
    const detailsTrailSize = useDetailsTrailSize(selector);

    const refInfo = useRef({
      isDetailsLoading,
      isListingLoading,
      hasMore,
      detailsTrailSize,
    });

    refInfo.current.isDetailsLoading = isDetailsLoading;
    refInfo.current.isListingLoading = isListingLoading;
    refInfo.current.hasMore = hasMore;
    refInfo.current.detailsTrailSize = detailsTrailSize;

    const loadMore = useCallback<LoadMoreItemsCallback<unknown>>(() => {
      const { current } = refInfo;
      if (current.isDetailsLoading) {
        return;
      }
      const direction = 'FORWARD'; // todo : figure out backward loading
      const directionIdx = listingDirections.indexOf(direction);
      if (!current.hasMore[directionIdx]) {
        return;
      }
      dispatch(actions.REQUEST_DETAILED({ list, direction }));
      if (current.detailsTrailSize[directionIdx] / batchSize <= 2 && !current.isListingLoading) {
        dispatch(actions.REQUEST_LISTED({ list, identifier, direction }));
      }
    }, [batchSize, dispatch, identifier]);

    return {
      loadMoreItemsCallback: loadMore,
      hasMore,
      detailsTrailSize,
      batchSize,
    };
  };
