import { makeStyles } from '@material-ui/core';

export const useSwipeContainerComponentsStyles = makeStyles((theme) => ({
  extraMarginBottom: {
    marginBottom: theme.spacing(2),
  },
  // SwipeContainerTitle
  titleWrapper: {
    fontWeight: 400,
    fontStyle: 'italic',
    fontSize: theme.typography.pxToRem(18),
    color: theme.palette.common.black === 'white' ? 'rgba(255, 255, 255, 0.64)' : '#585A62',
    marginBottom: theme.spacing(2),
  },
  title: {
    color: theme.palette.common.black === 'white' ? '#ffffff' : '#121112',
  },
  year: {
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.common.black === 'white' ? '#ffffff' : 'inherit',
  },
  series: {
    fontWeight: 600,
  },

  // SwipeContainerCollectedButton
  collectedBtn: {
    ...theme.mixins.flexRow,
    width: 'fit-content',
    height: 'fit-content',
    padding: '12px 20px',
    border:
      theme.palette.common.black === 'white' ? '1px solid rgba(255, 255, 255, 0.4)' : '1px solid rgba(0, 0, 0, 0.16)',
    borderColor: theme.palette.common.black === 'white' ? 'rgba(255, 255, 255, 0.4)' : 'rgba(0, 0, 0, 0.16)',
    fontSize: theme.typography.pxToRem(16),
    borderRadius: '6px',
    marginBottom: theme.spacing(2),
  },

  // SwipeContainerPrice
  priceWrapper: {
    ...theme.mixins.flexCentered,
    width: 'fit-content',
    borderRadius: '6px',
    padding: '4px',
    marginBottom: theme.spacing(2),
    border:
      theme.palette.common.black === 'white' ? '1px solid rgba(255, 255, 255, 0.4)' : '1px solid rgba(0, 0, 0, 0.12)',
  },
  price: {
    paddingBottom: '4px',
    fontWeight: 600,
    width: 'max-content',
    fontSize: theme.typography.pxToRem(17),
    margin: '0 8px',
    ...theme.mixins.flexCentered,
  },

  // SwipeContainerInquiryButton
  inquiryButton: {
    ...theme.mixins.flexCentered,
    padding: '12px 16px',
    background: theme.palette.common.black === 'white' ? '#FFFFFF' : '#121112',
    borderRadius: '6px',
    width: 'fit-content',
    height: 'fit-content',
    '& h5': {
      color: theme.palette.common.black === 'white' ? '#000000 !important' : '#FFFFFF',
      fontSize: theme.typography.pxToRem(14),
    },
  },

  // SwipeContainerUnavailableButton
  unavailableBtn: {
    ...theme.mixins.flexRow,
    color: theme.palette.common.black === 'white' ? '#FFFFFF !important' : '#000000',
    width: 'fit-content',
    height: 'fit-content',
    padding: '12px 20px',
    border:
      theme.palette.common.black === 'white' ? '1px solid rgba(255, 255, 255, 0.4)' : '1px solid rgba(0, 0, 0, 0.16)',
    borderColor: theme.palette.common.black === 'white' ? 'rgba(255, 255, 255, 0.4) !important' : 'rgba(0, 0, 0, 0.16)',
    fontSize: theme.typography.pxToRem(16),
    borderRadius: '6px',
    marginBottom: theme.spacing(2),
  },

  // SwipeContainerDetails
  detailsWrapper: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    boxSizing: 'border-box',

    '& > div:not(:first-child)': {
      marginTop: theme.spacing(1.625),
    },
  },
  detailElement: {
    ...theme.mixins.flexRow,
    alignItems: 'center',
    gap: '8px',
    '& img': {
      width: '36px',
      height: '36px',
      filter: theme.palette.common.black === 'white' ? 'invert(1)' : 'none',
    },
  },
  arText: {
    color: '#000000 !important',
  },
  arIcon: {
    width: '36px',
    height: '36px',
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
    borderRadius: '50%',

    '& > div': {
      height: `${theme.spacing(4.5)}px !important`,
      width: `${theme.spacing(4.5)}px !important`,
      ...theme.mixins.flexCentered,

      '& > svg': {
        height: `${theme.spacing(3)}px !important`,
        width: `${theme.spacing(3)}px !important`,
      },
    },

    '& g > g > g > path': {
      fill: 'rgba(0, 0, 0, 0.64)',
    },
  },
  // Skeletons
  skeleton: {
    filter: theme.palette.common.black === 'white' ? 'invert(1)' : 'none',
    borderRadius: '6px',
  },
  arIconContainerOverride: {
    width: '36px !important',
    height: '36px !important',
  },
  ratingScoreOverride: {
    gap: '8px !important',
  },
}));
