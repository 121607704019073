import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GetAppearanceSettingsByHandleQuery } from 'common/schema/commonSchemaRemoteOperationTypes';

export type AppearanceSettings = {
  profile: GetAppearanceSettingsByHandleQuery['profile'][0];
  gridAppearanceSettings: GetAppearanceSettingsByHandleQuery['gridAppearanceSettings'][0];
};

export type AppearanceSettingsState = {
  byHandle: Record<string, AppearanceSettings>;
  byContextId: Record<number, AppearanceSettings>;
};

const defaultAppearanceSettingsState: AppearanceSettingsState = {
  byHandle: {},
  byContextId: {},
};

export const appearanceSettingsSlice = createSlice({
  name: 'appearanceSettings',
  initialState: defaultAppearanceSettingsState,
  reducers: {
    SET_APPEARANCE_SETTINGS: (state, action: PayloadAction<AppearanceSettings>) => {
      const { payload } = action;
      const { profile } = payload;
      if (!profile.handle || !profile.context?.id) {
        return;
      }
      state.byHandle[profile.handle.toLowerCase()] = payload;
      state.byContextId[profile.context.id] = payload;
    },
  },
});
