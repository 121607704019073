import { getDefaultCountryFilter } from 'common/features/Filters/predefined/Country/store/country.defaults';
import { getDefaultInterestFilter } from 'common/features/Filters/predefined/Interests/store/interests.defaults';
import { getDefaultPriceRangeFilter } from 'common/features/Filters/predefined/PriceRange/store/priceRange.defaults';
import { RecognitionsFiltersState } from './filter.types';

export const getDefaultFilters = (): RecognitionsFiltersState => ({
  sort: 'byDate',
  ...getDefaultInterestFilter(),
  ...getDefaultPriceRangeFilter(),
  ...getDefaultCountryFilter(),
});

export const filterInitialState: RecognitionsFiltersState = getDefaultFilters();
