import { combineReducers } from 'redux';
import { followersListSlice } from './list.slice';
import { followersFiltersSlice } from './filter.slice';
import { followersDisplaySlice } from './display.slice';

export const actions = {
  ...followersListSlice.actions,
  ...followersFiltersSlice.actions,
  ...followersDisplaySlice.actions,
};

export const followersDashboardSlice = {
  followersDashboard: combineReducers({
    [followersListSlice.name]: followersListSlice.reducer,
    [followersFiltersSlice.name]: followersFiltersSlice.reducer,
    [followersDisplaySlice.name]: followersDisplaySlice.reducer,
  }),
};
