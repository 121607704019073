import { createSlice } from '@reduxjs/toolkit';

export type ArtistManageArtworksDisplayState = {
  isFiltersDialogOpen: boolean;
};

export const getDefaultDisplay = (): ArtistManageArtworksDisplayState => ({
  isFiltersDialogOpen: false,
});

export const displayInitialState: ArtistManageArtworksDisplayState = getDefaultDisplay();

export const displayArtistManageArtworksSlice = createSlice({
  name: 'display',
  initialState: displayInitialState,
  reducers: {
    OPEN_FILTERS_ArtistManageArtworks: (state) => {
      state.isFiltersDialogOpen = true;
    },
    CLOSE_FILTERS_ArtistManageArtworks: (state) => {
      state.isFiltersDialogOpen = false;
    },
  },
});
