import { makeStyles } from '@material-ui/core';

export const startCardCollectorInfoStyles = makeStyles((theme) => ({
  collectorInfoContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  abreviation: {
    color: 'black',
    fontSize: theme.typography.pxToRem(48),
    fontWeight: 700,
    [theme.breakpoints.down('xs')]: {
      fontSize: theme.typography.pxToRem(36),
    },
  },
  location: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: 400,
    [theme.breakpoints.down('xs')]: {
      fontSize: theme.typography.pxToRem(16),
      whiteSpace: 'pre-line',
    },
  },
}));
