import { BoostSkewFilterState } from 'common/features/Filters/predefined/BoostSkew/store/boostSkew.types';
import { CountryFilterState } from 'common/features/Filters/predefined/Country/store/country.types';
import { InterestsFilterState } from 'common/features/Filters/predefined/Interests/store/interests.types';
import { PriceRangeFilterState } from 'common/features/Filters/predefined/PriceRange/store/priceRange.types';
import { RefreshFilterState } from 'common/features/Filters/predefined/Refresh/refresh.types';
import { TitleFilterState } from 'common/features/Filters/predefined/Title/store/title.types';

export const sorts = ['byDate', 'byTitle'] as const;
export type HomeArtistsSort = (typeof sorts)[0] | (typeof sorts)[1];

export const isHomeArtistSort = (v: string): v is HomeArtistsSort => sorts.includes(v as HomeArtistsSort);

// currently auto-applies on every change
// maybe todo: add flag to apply on demand
export type HomeArtistsFiltersState = InterestsFilterState &
  PriceRangeFilterState &
  CountryFilterState &
  TitleFilterState &
  BoostSkewFilterState &
  RefreshFilterState & {
    sort: HomeArtistsSort;
  };
