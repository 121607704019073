import { makeStyles } from '@material-ui/core';

export const startCardStatsSectionStyles = makeStyles(() => ({
  startCardStatsSectionContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));
