import { makeStyles } from '@material-ui/core';

const useAppThemeColors = makeStyles((theme) => ({
  invertWhenDark: { filter: theme.palette.common.black === 'white' ? 'invert(1)' : 'invert(0)' },
  invertWhenLight: { filter: theme.palette.common.black === 'white' ? 'invert(1)' : 'invert(0)' },
  lightBackgroundDarkText: { backgroundColor: theme.palette.common.white, color: theme.palette.common.black },
  darkBackgroundLightText: { backgroundColor: theme.palette.common.black, color: theme.palette.common.white },
}));

export default useAppThemeColors;
