import { useShouldShowFeatureFlag } from 'common/features/FeatureFlag/hooks/useFeatureFlag';
import { useGetArtworkExternalBuyLinkLazyQuery } from 'common/schema/commonSchemaRemoteGraphqlQueries';
import { useCallback } from 'react';

export const openUnityExternalLink = (externalLink: string) => {
  const link = document.createElement('a');
  link.href = `uniwebview://externalLink?url=${encodeURIComponent(externalLink)}`;
  link.dispatchEvent(new MouseEvent('click'));
  link.remove();
};

/**
 * Hook to get the external buy link for an artwork and open it in a new tab
 * If on unityWebView, the link shouldn't have the protocol (https://) since it will be added by the unity app
 * @param artworkId to get the external buy link for
 * @returns openArtworkBuyLink function to open the external buy link in a new tab
 */
export const useArtworkExternalBuyLink = (artworkId: number | null): { openArtworkBuyLink: () => Promise<boolean> } => {
  const [isUnityWebview] = useShouldShowFeatureFlag('unityWebView');
  const [getExternalLink, { data }] = useGetArtworkExternalBuyLinkLazyQuery({
    fetchPolicy: 'cache-first',
  });

  const openArtworkBuyLink = useCallback(async () => {
    if (!artworkId) {
      return false;
    }
    const existingData = data?.artwork?.externalLink;
    const getRemoteData = async () =>
      (
        await getExternalLink({
          variables: {
            artworkId,
          },
        })
      )?.data?.artwork?.externalLink;
    const artworkExternalBuyLink = data ? existingData : await getRemoteData();

    if (!artworkExternalBuyLink) {
      return false;
    }

    if (isUnityWebview) {
      openUnityExternalLink(artworkExternalBuyLink);
    } else {
      window.open(artworkExternalBuyLink, '_blank', 'noopener');
    }
    return true;
  }, [artworkId, data, getExternalLink, isUnityWebview]);

  return { openArtworkBuyLink };
};
