import VirtualList from 'common/features/VirtualList/VirtualList';
import { KnownVirtualListEnum, SortOptionsEnum } from 'common/features/VirtualList/VirtualList.config';
import { Maybe } from 'common/schema/commonSchemaRemoteTypes';

type RequiredIds = {
  artworkId: number;
  artistContextId: number;
  aspect?: Maybe<{ ratio: number }>;
};
const {
  listingCursor: { getDefaultListingCursor, nextPage, prevPage },
  sortOptions: { generateSortOptionsAndDefault },
} = VirtualList;

export const [, optionsByCurationScore] = generateSortOptionsAndDefault(SortOptionsEnum.CURATION_SCORE);
export type ByCurationScoreSortOptions = (typeof optionsByCurationScore)[number];
export type ByCurationScoreCursor = RequiredIds & { score?: number | null };
export const cursorsByCurationScore: Record<
  ByCurationScoreSortOptions,
  [ByCurationScoreCursor, ByCurationScoreCursor]
> = {
  [SortOptionsEnum.CURATION_SCORE]: getDefaultListingCursor<ByCurationScoreCursor>(
    { artworkId: prevPage.bigint.bigger, score: prevPage.int.bigger, artistContextId: 0 },
    { artworkId: nextPage.bigint.smaller, score: nextPage.int.smaller, artistContextId: 0 }
  ),
};

export const optionsForShopArtworks = [...optionsByCurationScore];
export const [defaultSortForShopArtworks] = generateSortOptionsAndDefault(...optionsForShopArtworks);
export type ShopArtworksSortOptions = ByCurationScoreSortOptions;
export type ShopArtworksCursor = ByCurationScoreCursor;
export const cursorsForShopArtworks: Record<ShopArtworksSortOptions, [ShopArtworksCursor, ShopArtworksCursor]> = {
  ...cursorsByCurationScore,
};

export const {
  listSlice: listShopArtworksSlice,
  getSpecificCursor: getSpecificShopArtworksCursor,
  getDefaultVirtualList: getDefaultShopArtworksVirtualList,
} = VirtualList.slices.createListSlice<ShopArtworksSortOptions, ShopArtworksCursor>({
  defaultBatchSizes: {
    listedBatchSize: 128,
    detailedBatchSize: 32,
  },
  allSpecificCursors: cursorsForShopArtworks,
  virtualListIdentifier: KnownVirtualListEnum.SHOP,
  defaultFilterHash: '',
  defaultSortOption: SortOptionsEnum.CURATION_SCORE,
});
