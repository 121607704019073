import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface artistProfileDetailsState {
  title: string;
  handle: string;
  avatar?: string;
  banner?: string;
  statement?: string;
  quote?: string;
  googleProfilePhoto?: string;
}

const initialState: artistProfileDetailsState = {
  title: '',
  handle: '',
  statement: '',
  quote: '',
};

const artistProfileDetailsSlice = createSlice({
  name: 'artistProfileDetails',
  initialState,
  reducers: {
    SET_TITLE_AND_HANDLE: (
      state,
      action: PayloadAction<{
        title: string;
        handle: string;
      }>
    ) => {
      state.handle = action.payload.handle;
      state.title = action.payload.title;
    },
    SET_STATEMENT_AND_QUOTE: (
      state,
      action: PayloadAction<{
        statement: string;
        quote: string;
      }>
    ) => {
      state.statement = action.payload.statement;
      state.quote = action.payload.quote;
    },
    SET_AVATAR_AND_BANNER: (
      state,
      action: PayloadAction<{
        avatar?: string;
        banner?: string;
      }>
    ) => {
      state.avatar = action.payload.avatar ?? '';
      state.banner = action.payload.banner ?? '';
    },
    SET_GOOGLE_PROFILE_PHOTO: (state, action: PayloadAction<string>) => {
      state.googleProfilePhoto = action.payload;
    },
    RESET_ARTIST_PROFILE_DETAILS: () => initialState,
  },
});

export const artistProfileDetailsActions = artistProfileDetailsSlice.actions;
export default artistProfileDetailsSlice;
