import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { artistContextIdFilterHash } from 'common/features/Filters/predefined/ArtistContextId/artistContextId.utils';
import { getDefaultArtistContextIdFilter } from 'common/features/Filters/predefined/ArtistContextId/store/artistContextId';
import { createToggleArtistContextIdCaseReducer } from 'common/features/Filters/predefined/ArtistContextId/store/artistContextId.reducers';
import { ArtistContextIdFilterState } from 'common/features/Filters/predefined/ArtistContextId/store/artistContextId.types';
import { availabilityFilterHash } from 'common/features/Filters/predefined/Availability/availability.utils';
import { getDefaultAvailabilityFilter } from 'common/features/Filters/predefined/Availability/store/availability.defaults';
import { createToggleAvailabilityCaseReducer } from 'common/features/Filters/predefined/Availability/store/availability.reducers';
import { AvailabilityFilterState } from 'common/features/Filters/predefined/Availability/store/availability.types';
import { dateFilterHash } from 'common/features/Filters/predefined/Date/date.utils';
import { getDefaultDateFilter } from 'common/features/Filters/predefined/Date/store/date.defaults';
import {
  createSelectDateCaseReducer,
  SelectDateAction,
} from 'common/features/Filters/predefined/Date/store/date.reducers';
import { DateFilterState } from 'common/features/Filters/predefined/Date/store/date.types';
import { orientationFilterHash } from 'common/features/Filters/predefined/Orientation/orientation.utils';
import { createToggleOrientationCaseReducer } from 'common/features/Filters/predefined/Orientation/store/orientation';
import { getDefaultOrientationFilter } from 'common/features/Filters/predefined/Orientation/store/orientation.defaults';
import { OrientationFilterState } from 'common/features/Filters/predefined/Orientation/store/orientation.types';
import { prizesFilterHash } from 'common/features/Filters/predefined/Prizes/Prizes.utils';
import { getDefaultPrizeFilter } from 'common/features/Filters/predefined/Prizes/store/prizes.defaults';
import { createTogglePrizeCaseReducer } from 'common/features/Filters/predefined/Prizes/store/prizes.reducers';
import { PrizesFilterState } from 'common/features/Filters/predefined/Prizes/store/prizes.types';
import { getDefaultRefreshFilter } from 'common/features/Filters/predefined/Refresh/refresh.defaults';
import { createRefreshFilterCaseReducer } from 'common/features/Filters/predefined/Refresh/refresh.reducers';
import { RefreshFilterState } from 'common/features/Filters/predefined/Refresh/refresh.types';
import { seriesFilterHash } from 'common/features/Filters/predefined/Series/Series.utils';
import { getDefaultSeriesFilter } from 'common/features/Filters/predefined/Series/store/series.defaults';
import { createToggleSeriesCaseReducer } from 'common/features/Filters/predefined/Series/store/series.reducers';
import { SeriesFilterState, SeriesOption } from 'common/features/Filters/predefined/Series/store/series.types';
import { SortState } from 'common/features/Filters/predefined/SortGroup/store/sort.types';
import { getDefaultYearsFilter } from 'common/features/Filters/predefined/Year/store/year.defaults';
import { createToggleYearCaseReducer } from 'common/features/Filters/predefined/Year/store/year.reducers';
import { YearFilterState } from 'common/features/Filters/predefined/Year/store/year.types';
import { yearsFilterHash } from 'common/features/Filters/predefined/Year/year.utils';
import { filterHashResetMarker, ListingIdentifier } from 'common/features/VirtualList/components/listReduxFragment';
import { SortOptionsEnum } from 'common/features/VirtualList/VirtualList.config';
import { ArtistProfileArtworksSortOptions } from './listArtistProfileArtworks.slice';

type ArtistContextSeries = { artistContextSeries: SeriesOption[] };

export type ArtistProfileArtworksFiltersState = SortState<ArtistProfileArtworksSortOptions> & {
  filterHash: ListingIdentifier<ArtistProfileArtworksSortOptions>;
} & RefreshFilterState &
  AvailabilityFilterState &
  OrientationFilterState &
  YearFilterState &
  PrizesFilterState &
  SeriesFilterState &
  ArtistContextSeries &
  ArtistContextIdFilterState & { since: DateFilterState } & { until: DateFilterState };

export const getHashForArtistProfileArtworksFilters = (
  filters: Omit<ArtistProfileArtworksFiltersState, 'filterHash'>,
  customSuffix?: string
): ListingIdentifier<ArtistProfileArtworksSortOptions> =>
  `${filters.currentSort}_${[
    dateFilterHash(filters.since.selectedDate),
    dateFilterHash(filters.until.selectedDate),
    availabilityFilterHash(filters.availability),
    orientationFilterHash(filters.orientation),
    yearsFilterHash(filters.selectedYears),
    prizesFilterHash(filters.prizes),
    seriesFilterHash(filters.series),
    filterHashResetMarker, // user-applied filters above this point
    artistContextIdFilterHash(filters.selectedArtistContextId),
    customSuffix ?? 'nosuffix',
    filters.refresh,
  ].join('_')}` as const;

export const getDefaultArtistProfileArtworksFilters = (customSuffix?: string): ArtistProfileArtworksFiltersState => {
  const noHashFilterState: Omit<ArtistProfileArtworksFiltersState, 'filterHash'> = {
    ...getDefaultRefreshFilter(),
    ...getDefaultArtistContextIdFilter(),
    ...getDefaultAvailabilityFilter(),
    ...getDefaultOrientationFilter(),
    ...getDefaultYearsFilter(),
    ...getDefaultPrizeFilter(),
    ...getDefaultSeriesFilter(),
    artistContextSeries: [],
    since: {
      ...getDefaultDateFilter(),
    },
    until: {
      ...getDefaultDateFilter(),
    },
    currentSort: SortOptionsEnum.ARTIST_SELECTION,
  };
  const filtersState: ArtistProfileArtworksFiltersState = {
    ...noHashFilterState,
    filterHash: getHashForArtistProfileArtworksFilters(noHashFilterState, customSuffix),
  };
  return filtersState;
};

export const filterInitialState: ArtistProfileArtworksFiltersState = getDefaultArtistProfileArtworksFilters();

const sinceDateCaseReducer = createSelectDateCaseReducer<ArtistProfileArtworksFiltersState['since']>();
const untilDateCaseReducer = createSelectDateCaseReducer<ArtistProfileArtworksFiltersState['until']>();

export const filterArtistProfileArtworksSlice = createSlice({
  name: 'filter',
  initialState: filterInitialState,
  reducers: {
    RESET_ArtistProfileArtworks: (_, action: PayloadAction<{ customSuffix?: string }>) =>
      getDefaultArtistProfileArtworksFilters(action.payload.customSuffix),
    REFRESH_ArtistProfileArtworks: createRefreshFilterCaseReducer<ArtistProfileArtworksFiltersState>(),
    ARTIST_CONTEXT_ID_ArtistProfileArtworks:
      createToggleArtistContextIdCaseReducer<ArtistProfileArtworksFiltersState>(),
    SELECT_SINCE_DATE_ArtistProfileArtworks: (state, action: SelectDateAction) => {
      sinceDateCaseReducer(state.since, action);
    },
    SELECT_UNTIL_DATE_ArtistProfileArtworks: (state, action: SelectDateAction) => {
      untilDateCaseReducer(state.until, action);
    },
    SORT_ArtistProfileArtworks: (state, action: PayloadAction<ArtistProfileArtworksSortOptions>) => ({
      ...state,
      currentSort: action.payload,
    }),
    TOGGLE_AVAILABILITY_ArtistProfileArtworks: createToggleAvailabilityCaseReducer<ArtistProfileArtworksFiltersState>(),
    TOGGLE_ORIENTATION_ArtistProfileArtworks: createToggleOrientationCaseReducer<ArtistProfileArtworksFiltersState>(),
    TOGGLE_YEAR_ArtistProfileArtworks: createToggleYearCaseReducer<ArtistProfileArtworksFiltersState>(),
    TOGGLE_PRIZE_ArtistProfileArtworks: createTogglePrizeCaseReducer<ArtistProfileArtworksFiltersState>(),
    TOGGLE_SERIES_ArtistProfileArtworks: createToggleSeriesCaseReducer<ArtistProfileArtworksFiltersState>(),
    SET_CONTEXT_SERIES_ArtistProfileArtworks: (state, action: PayloadAction<SeriesOption[]>) => ({
      ...state,
      artistContextSeries: action.payload,
    }),
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      (action) => !!action?.type && action.type.startsWith('filter/') && action.type.endsWith('_ArtistProfileArtworks'),
      (state) => {
        state.filterHash = getHashForArtistProfileArtworksFilters(state);
      }
    );
  },
});
