import { makeStyles } from '@material-ui/core';

type Props = {
  isDarkMode: boolean;
};

export const useFavoritesCounterButtonStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: 'transparent',
    justifyContent: 'flex-start',
    height: 'fit-content',
    borderRadius: '6px',
    borderColor: 'rgba(0, 0, 0, 0.16)',
    marginLeft: 'auto',
  },
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  favButton: {
    height: theme.spacing(3),
    width: theme.spacing(3),
  },
  artworkFavoritesCount: (props: Props) => ({
    margin: '1px 8px 1px 4px',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: theme.spacing(2),
    color: props.isDarkMode ? 'white' : 'black',
  }),
}));
