import { interestsFilterHash } from 'common/features/Filters/predefined/Interests/interests.utils';
import { priceRangeFilterHash } from 'common/features/Filters/predefined/PriceRange/priceRange.utils';
import useAppSelector from 'common/hooks/useAppSelector';
import { useEffect, useMemo } from 'react';
import useDebouncedState from 'common/hooks/utils/useDebouncedState';
import { countryFilterHash } from 'common/features/Filters/predefined/Country/country.utils';
import useAnalytics from 'common/features/Analytics/Analytics';
import { RecognitionsFiltersHash, LOADING_STATES } from '../store/list.types';
import { RecognitionsFiltersState } from '../store/filter.types';

export const extractIdFromIdentifier = (identifier: RecognitionsFiltersHash) =>
  identifier.substring(identifier.lastIndexOf('_') + 1);

// exported for use in the initial state
export const filtersHash = (state: RecognitionsFiltersState, suffix: string | number = ''): RecognitionsFiltersHash =>
  // using template string to match typing
  `${state.sort}_${[
    interestsFilterHash(state.interests),
    priceRangeFilterHash(state.priceRange),
    countryFilterHash(state.country),
    suffix,
  ].join('_')}` as const;

export const useRecognitionsFiltersIdentifier = (contextId?: string | number): RecognitionsFiltersHash => {
  const filters = useAppSelector((store) => store.recognitionsV2.filter);
  const currentIdentifier = useMemo(() => filtersHash(filters, contextId), [filters, contextId]);
  const analytics = useAnalytics();
  const [appliedIdentifier, setAppliedIdentifier] = useDebouncedState(currentIdentifier, 750);

  useEffect(() => {
    analytics.postData('SUCCESS', 'CONSUMER_PRODUCT_FOOTER', 'CLICK_FILTER_APPLY', {
      filterSort: filters,
    });
  }, [appliedIdentifier]);

  const listStatus = useAppSelector((store) => store.recognitionsV2.list.data.listed.status);
  useEffect(() => {
    if (LOADING_STATES.includes(listStatus)) return;

    setAppliedIdentifier(currentIdentifier);
  }, [currentIdentifier, listStatus]);
  return appliedIdentifier;
};
