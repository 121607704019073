import { createSlice } from '@reduxjs/toolkit';

export type CertificateGeneration = null | 'GENERATING' | 'GENERATED';
export type Print = {
  printNumber?: number;
  editionId?: number;
  printPieceId?: number;
  edition_by_type_id?: number;
};
export interface CertificatesState {
  certificatePreview: 'PHYSICAL' | 'DIGITAL';
  certificateLoading: boolean;
  certificateGeneration: CertificateGeneration;
  generationDate: number | undefined;
  selectedPrint: Print;
  artworkId?: number;
}

const initialState: CertificatesState = {
  certificatePreview: 'PHYSICAL',
  certificateLoading: true,
  certificateGeneration: null,
  generationDate: undefined,
  selectedPrint: {
    printNumber: undefined,
    editionId: undefined,
    printPieceId: undefined,
  },
  artworkId: undefined,
};

const certificatesSlice = createSlice({
  name: 'certificates',
  initialState,
  reducers: {
    SET_CERTIFICATE_PREVIEW: (
      state,
      action: {
        payload: {
          certificatePreview: 'PHYSICAL' | 'DIGITAL';
        };
      }
    ) => {
      state.certificatePreview = action.payload.certificatePreview;
    },
    SET_CERTIFICATE_LOADING: (
      state,
      action: {
        payload: {
          certificateLoading: boolean;
        };
      }
    ) => {
      state.certificateLoading = action.payload.certificateLoading;
    },
    SET_CERTIFICATE_GENERATION: (
      state,
      action: {
        payload: {
          certificateGeneration: null | 'GENERATING' | 'GENERATED';
        };
      }
    ) => {
      state.certificateGeneration = action.payload.certificateGeneration;
    },
    SET_CERTIFICATE_DATE: (
      state,
      action: {
        payload: {
          generationDate: number | undefined;
        };
      }
    ) => {
      state.generationDate = action.payload.generationDate;
    },
    SET_SELECTED_PRINT: (
      state,
      action: {
        payload: {
          selectedPrint: Print;
        };
      }
    ) => {
      state.selectedPrint = {
        ...state.selectedPrint,
        ...action.payload.selectedPrint,
      };
    },
    SET_ARTWORK_ID: (
      state,
      action: {
        payload: {
          artworkId: number | undefined;
        };
      }
    ) => {
      state.artworkId = action.payload.artworkId;
    },
  },
});

export const certificatesActions = certificatesSlice.actions;
export default certificatesSlice;
