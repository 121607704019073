import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createSelectCountryCaseReducer } from 'common/features/Filters/predefined/Country/store/country.reducers';
import { createFilterTitleCaseReducer } from 'common/features/Filters/predefined/Title/store/title.reducers';
import { filterInitialState, getDefaultFilters } from './filter.defaults';
import { ProfileGalleryFiltersState } from './filter.types';

export const profileGalleryFiltersSlice = createSlice({
  name: 'filter',
  initialState: filterInitialState,
  reducers: {
    RESET_ProfileGallery: getDefaultFilters,
    SORT_ProfileGallery: (state, action: PayloadAction<ProfileGalleryFiltersState['sort']>) => ({
      ...state,
      sort: action.payload,
    }),
    CHANGE_COUNTRY_ProfileGallery: createSelectCountryCaseReducer<ProfileGalleryFiltersState>(),
    SEARCH_TITLE_ProfileGallery: createFilterTitleCaseReducer<ProfileGalleryFiltersState>(),
  },
});
