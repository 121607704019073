import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GetFavoritesArtworksByContextIdQuery } from 'common/schema/commonSchemaRemoteOperationTypes';
import useAppSelector from '../../common/hooks/useAppSelector';

export type FavoritesObjectType = {
  contextId: number;
  artworkId: number;
};

type FavoritesDetailsType = {
  contextId: number;
  favoritesDetails: GetFavoritesArtworksByContextIdQuery['Collection'][0]['artwork_to_collections'][0]['Artwork'][];
  updatedList: boolean;
};

type FavoritesModifierType = {
  favorites: FavoritesObjectType[];
  favoritesDetails: FavoritesDetailsType;
};

const initialState: FavoritesModifierType = {
  favorites: [],
  favoritesDetails: {
    contextId: 0,
    favoritesDetails: [],
    updatedList: false,
  },
};

const favoritesModifierSlice = createSlice({
  name: 'favorites',
  initialState,
  reducers: {
    LOAD_FAVORITES: (state, action: PayloadAction<FavoritesObjectType[]>) => ({
      ...state,
      favorites: action.payload,
    }),
    PATCH_FAVORITES: (state, action: PayloadAction<FavoritesObjectType[]>) => ({
      ...state,
      favorites: [
        ...action.payload,
        ...state.favorites.filter(
          (f) => action.payload.findIndex((v) => v.artworkId === f.artworkId && v.contextId === f.contextId) === -1
        ),
      ],
    }),
    ADD_FAVORITE: (state, action: PayloadAction<FavoritesObjectType>) => ({
      favorites: [...state.favorites, action.payload],
      favoritesDetails: {
        ...state.favoritesDetails,
        updatedList: false,
      },
    }),
    REMOVE_FAVORITE: (state, action: PayloadAction<FavoritesObjectType>) => ({
      favorites: state.favorites.filter(
        (fav) => fav.artworkId !== action.payload.artworkId && fav.contextId === action.payload.contextId
      ),
      favoritesDetails: {
        ...state.favoritesDetails,
        updatedList: false,
      },
    }),
    LOAD_FAVORITES_DETAILS: (state, action: PayloadAction<FavoritesDetailsType>) => ({
      ...state,
      favoritesDetails: action.payload,
    }),
  },
});

export const favoritesModifiersActions = favoritesModifierSlice.actions;

export const useFavorites = () => useAppSelector((state) => state.favorites);

export default favoritesModifierSlice;
