import LoadingLogo from 'common/components/LoadingLogo/LoadingLogo';
import React from 'react';
import Auth from '..';

type FirebaseLoaderProps = {
  children?: React.ReactNode;
};
/**
 * This component ensures that the children are not mounted until the Firebase auth state is resolved.
 * It will show the loading logo while the auth state is pending and then mount the children when the auth state is resolved.
 */
const FirebaseLoader: React.FC<FirebaseLoaderProps> = ({ children }) => {
  const isPending = Auth.useIsPending();

  return (
    <LoadingLogo open={isPending} childrenMountingCondition={!isPending} isFullscreen isContained={false}>
      {children}
    </LoadingLogo>
  );
};

export default FirebaseLoader;
