import { useMemo } from 'react';
import { useGalleriesProfile } from 'store/slices/galleriesProfileSlice';
import { getCDNImageUrl } from 'utils/CDNImages';
import { getSocialLinks } from '../utils/galleriesProfileFunctions';

const useGalleryInfo = () => {
  const { galleryInfo } = useGalleriesProfile();

  return useMemo(() => {
    const socialMedias = getSocialLinks(galleryInfo?.Context?.[0]?.profile_social_links || []);
    const profileId = galleryInfo?.Context[0]?.profile?.id ?? 0;
    const contextId = galleryInfo?.Context[0]?.id ?? 0;
    const orderedLocations = galleryInfo?.Context[0]?.locations?.slice().sort((a, b) => a.id - b.id);
    const locationId = orderedLocations?.length ? orderedLocations[0].id : 0;
    const locationMapId = orderedLocations?.length ? orderedLocations[0].location_map_id : 0;

    return {
      contextId,
      profileId,
      locationId,
      profileTitle: galleryInfo?.Context[0]?.profile?.title ?? '',
      profileDescription: galleryInfo?.Context[0]?.profile?.description ?? '',
      profileAvatar: !profileId
        ? undefined
        : getCDNImageUrl({
            target: 'Profile',
            purpose: 'Avatar',
            targetId: profileId,
            fileId: galleryInfo?.Context[0]?.profile?.avatar ?? 0,
            meta: {
              size: 'large',
            },
          }),
      profileBanner: !profileId
        ? undefined
        : getCDNImageUrl({
            target: 'Profile',
            purpose: 'KardBanner',
            targetId: profileId,
            fileId: galleryInfo?.Context[0]?.profile?.kard_banner ?? 0,
            meta: {
              size: 'large',
            },
          }),
      profileLogo: !profileId
        ? undefined
        : getCDNImageUrl({
            target: 'Profile',
            purpose: 'Featured_art',
            targetId: profileId,
            fileId: galleryInfo?.Context[0]?.profile?.featured_art ?? 0,
            meta: {
              size: 'large',
            },
          }),
      profileStatement: galleryInfo?.Context[0]?.profile?.statement,
      profileEmail: galleryInfo?.Context[0]?.profile?.email ?? '',
      locationCity: orderedLocations?.[0]?.city || '',
      locationCountry: orderedLocations?.[0]?.country || '',
      locationZipCode: orderedLocations?.[0]?.zip_code || '',
      locationAddress: orderedLocations?.[0]?.address || '',
      locationMapId,
      locationMap: !profileId
        ? undefined
        : getCDNImageUrl({
            target: 'Profile',
            purpose: 'Location',
            targetId: profileId,
            fileId: locationId,
            meta: { size: 'map', imageId: locationId },
            format: 'png',
          }),
      coordinates: galleryInfo?.Context[0]?.locations[0]?.location?.coordinates,
      profileSocialMedias: socialMedias,
      profilePhoneNumber: galleryInfo?.Context?.[0]?.profile_phones[0]?.phone || '',
    };
  }, [galleryInfo]);
};

export default useGalleryInfo;
