import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  AudioPlaylist,
  buildPlaylistName,
  getPlaylistNameOverlap,
  SingleAudioSourceType,
} from 'common/components/GeneralAudioPlayer/utils/types';

export enum PlayerStates {
  PLAY = 'PLAY',
  PAUSED = 'PAUSED',
  STOPPED = 'STOPPED',
}

type AudioStateType = {
  audios: Record<string, SingleAudioSourceType>;
  playlists: Record<string, AudioPlaylist>;
  current: {
    playlist: string;
    audio: string;
    time: {
      seek: number;
      manual: number;
      updated: boolean;
    };
  };
  player: {
    status: PlayerStates;
    isPlaylistVisible: boolean;
    isExpanded: boolean;
  };
};

const getInitialState: () => AudioStateType = () => ({
  audios: {},
  playlists: {},
  current: {
    audio: '',
    playlist: '',
    time: {
      seek: 0,
      manual: 0,
      updated: false,
    },
  },
  player: {
    status: PlayerStates.STOPPED,
    isPlaylistVisible: false,
    isExpanded: false,
  },
});

const initialState: AudioStateType = getInitialState();

const audioPlayerSlice = createSlice({
  initialState,
  name: 'audioPlayer',
  reducers: {
    LOAD_PLAYLIST: (
      state,
      action: PayloadAction<{
        playlistId: string;
        audios?: SingleAudioSourceType[];
      }>
    ) => {
      // extract the props
      const { playlistId, audios } = action.payload;

      if (audios) {
        // cache the audios
        audios.forEach((audio) => {
          state.audios[audio.hash] = {
            ...audio,
            duration: audio.duration || state.audios[audio.hash]?.duration || 0, // try to keep the duration in case the same audio gets loaded multiple times
          };
        });

        // update the audios reference
        state.audios = {
          ...state.audios,
        };

        // create the playlist
        state.playlists[playlistId] = {
          id: playlistId,
          audios: audios.map((audio) => audio.hash),
        };
      }

      if (!state.playlists[playlistId]) {
        console.warn("the specified playlist doesn't exist", playlistId);
        return;
      }

      const oldPlaylist = state.current.playlist;
      const newPlaylist = playlistId;
      const oldAudio = state.current.audio;
      const oldTime = state.current.time;
      const oldPlayerStatus = state.player.status;

      // load the new playlist
      state.current.playlist = newPlaylist;
      state.current.audio = '';
      state.current.time = {
        seek: 0,
        manual: 0,
        updated: false,
      };
      state.player.status = PlayerStates.STOPPED;

      // if there was no old playlist or audio or not playing, continue with the reset of the playlist
      if (!oldPlaylist || oldPlayerStatus !== PlayerStates.PLAY || !oldAudio) {
        return;
      }

      // check if the old playlist and new playlist overlap
      const overlap = getPlaylistNameOverlap(oldPlaylist, newPlaylist);

      // not enough overlap, continue with the reset of the playlist
      if (overlap <= 1) {
        return;
      }

      // they overlap enough, we need to keep the old audio
      state.current.audio = oldAudio;
      state.current.time = oldTime;
      state.player.status = oldPlayerStatus;

      // have a check to see if the old audio is in the new playlist
      const isCurrentAudioInNewPlaylist = state.playlists[newPlaylist].audios.includes(oldAudio);

      // we need to create a temporary playlist with the old audio at the end if it's not in the new playlist
      // .e.g. : going from the showcase to artist profile should keep the artwork audio playing
      if (!isCurrentAudioInNewPlaylist) {
        const composedPlaylistId = buildPlaylistName(playlistId, 'temporary');
        state.playlists[composedPlaylistId] = {
          id: composedPlaylistId,
          audios: [...state.playlists[playlistId].audios, state.current.audio],
        };
        state.current.playlist = composedPlaylistId;
      }
    },
    ADD_AUDIO_DURATION: (state, action: PayloadAction<{ audioHash: string; duration: number }>) => {
      // extract the props
      const { audioHash, duration } = action.payload;

      // set the audio duration
      if (!state.audios[audioHash]) {
        console.warn("the specified audio doesn't exist", audioHash);
        return;
      }

      state.audios[audioHash] = {
        ...state.audios[audioHash],
        duration,
      };
    },
    PLAY: (
      state,
      action: PayloadAction<{
        audioHash?: string;
        isExpandedPlayer?: boolean;
      }>
    ) => {
      // extract the props
      const { audioHash, isExpandedPlayer } = action.payload;

      if (!audioHash) {
        // no audio hash give, nothing to do
        return;
      }

      // the the expanded state
      state.player.isExpanded = isExpandedPlayer ?? state.player.isExpanded;
      state.player.status = PlayerStates.PLAY;

      if (audioHash === state.current.audio) {
        // play the current audio and that's it
        return;
      }

      // check if the given audio is in the playlist
      const { playlist } = state.current;
      const { audios } = state.playlists[playlist];
      const audioIndex = audios.indexOf(audioHash);
      if (audioIndex === -1) {
        state.player.status = PlayerStates.STOPPED;
        console.warn("the specified audio isn't in the current playlist", audioHash);
        return;
      }

      state.current.audio = audioHash;
      // reset the seek time
      state.current.time = {
        seek: 0,
        manual: 0,
        updated: false,
      };
    },
    PAUSE: (state) => {
      // set the status to paused
      state.player.status = PlayerStates.PAUSED;
    },
    STOP: (state) => {
      // reset the seek time
      state.current.time = {
        seek: 0,
        manual: 0,
        updated: false,
      };
      // set the status to stopped
      state.player.status = PlayerStates.STOPPED;
      state.current.audio = '';
    },
    UPDATE_SEEK_TIME: (state, action: PayloadAction<{ audioHash: string; time: number }>) => {
      // extract the props
      const { audioHash, time } = action.payload;
      const { audio } = state.current;

      // if the given audio is different from the current one, nothing to do
      if (audio !== audioHash) {
        return;
      }

      // update the seek time
      state.current.time.seek = time;
      state.current.time.updated = false;
    },
    MANUAL_SEEK: (state, action: PayloadAction<{ audioHash: string; time: number }>) => {
      // extract the props
      const { audioHash, time } = action.payload;
      const { audio } = state.current;

      // if the given audio is different from the current one, nothing to do
      if (audio !== audioHash) {
        return;
      }

      // update the seek time
      state.current.time.manual = time;
      state.current.time.seek = time;
      state.current.time.updated = true;
    },
    TOGGLE_PLAYER_EXPANDED: (state, action: PayloadAction<boolean | undefined>) => {
      // when giving undefined, toggle the expanded state
      state.player.isExpanded = action.payload ?? !state.player.isExpanded;
    },
    TOGGLE_PLAYLIST_VISIBILITY: (state, action: PayloadAction<boolean | undefined>) => {
      // when giving undefined, toggle the playlist visibility
      state.player.isPlaylistVisible = action.payload ?? !state.player.isPlaylistVisible;
    },
  },
});

export const audioPlayerActions = audioPlayerSlice.actions;
export const audioPlayerReducer = audioPlayerSlice.reducer;

export default audioPlayerSlice;
