import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Enum_Context_Type_Enum, Enum_Social_Graph_Followers_State_Enum } from 'common/schema/commonSchemaRemoteTypes';

import useAppSelector from 'common/hooks/useAppSelector';

export type AdmirationsModifiersObjectType = {
  id: number | undefined;
  context_id: any | null;
  target_context_id: any | null;
  state: Enum_Social_Graph_Followers_State_Enum | null;
};

export type RecognitionsArtistType = {
  followId?: number;
  context_id: number;
  profile_id: number;
  profile_avatar: number;
  profile_title: string;
  profile_handle: string;
  location_city: string;
  location_country: string;
  order: number;
  profile_firstName?: string;
  profile_lastName?: string;
  profile_type?: Enum_Context_Type_Enum;
};

export type RecognitionsArtworkType = {
  id: number;
  artist_context_id: number;
  thumbnail_metadata_id: number;
};

export type RecognitionsInfoWithArtworkType = {
  Artists: RecognitionsArtistType[];
  Artworks: RecognitionsArtworkType[];
};

type AdmirationsModifiersType = {
  newRecognitions: AdmirationsModifiersObjectType[];
  mutualRecognitions: AdmirationsModifiersObjectType[];
  simpleRecognitions: AdmirationsModifiersObjectType[];
  ignoredRecognitions: AdmirationsModifiersObjectType[];
  collectorRecognitions: AdmirationsModifiersObjectType[];
  otherArtistRecognitions: RecognitionsInfoWithArtworkType; // Recognitions of Artist B on its profile
  previousHandle: string;
  sliceCurrentPage: number;
  enterConnectionPage: boolean;
};

export const recognitionsByQueryPage = 50;

const initialState: AdmirationsModifiersType = {
  newRecognitions: [
    {
      id: undefined,
      context_id: null,
      target_context_id: null,
      state: null,
    },
  ],
  mutualRecognitions: [
    {
      id: undefined,
      context_id: null,
      target_context_id: null,
      state: null,
    },
  ],
  simpleRecognitions: [
    {
      id: undefined,
      context_id: null,
      target_context_id: null,
      state: null,
    },
  ],
  ignoredRecognitions: [
    {
      id: undefined,
      context_id: null,
      target_context_id: null,
      state: null,
    },
  ],
  collectorRecognitions: [
    {
      id: undefined,
      context_id: null,
      target_context_id: null,
      state: null,
    },
  ],
  otherArtistRecognitions: { Artists: [], Artworks: [] },
  previousHandle: '',
  sliceCurrentPage: 0,
  enterConnectionPage: false,
};

const admirationsModifiersSlice = createSlice({
  name: 'recognitions',
  initialState,
  reducers: {
    LOAD_RECOGNITIONS_AND_ADMIRATIONS: (state, action: PayloadAction<any>) => ({
      ...state,
      newRecognitions: action.payload.newRecognitions,
      mutualRecognitions: action.payload.mutualRecognitions,
      collectorRecognitions: action.payload.collectorRecognitions,
      simpleRecognitions: action.payload.simpleRecognitions,
      ignoredRecognitions: action.payload.ignoredRecognitions,
    }),
    LOAD_OTHER_ARTIST_RECOGNITIONS: (state, action: PayloadAction<RecognitionsInfoWithArtworkType>) => ({
      ...state,
      otherArtistRecognitions: {
        Artists: [...state.otherArtistRecognitions.Artists, ...action.payload.Artists],
        Artworks: [...state.otherArtistRecognitions.Artworks, ...action.payload.Artworks],
      },
    }),
    REPLACE_OTHER_ARTIST_RECOGNITIONS: (state, action: PayloadAction<RecognitionsInfoWithArtworkType>) => ({
      ...state,
      otherArtistRecognitions: {
        Artworks: [],
        Artists: [...action.payload.Artists],
      },
    }),
    ADD_SIMPLE_RECOGNITION: (state, action: PayloadAction<AdmirationsModifiersObjectType>) => ({
      ...state,
      simpleRecognitions: [...state.simpleRecognitions, action.payload],
    }),
    ADD_IGNORED_RECOGNITION: (state, action: PayloadAction<AdmirationsModifiersObjectType>) => ({
      ...state,
      newRecognitions: state.newRecognitions.filter((recognition) => recognition.id !== action.payload.id),
      ignoredRecognitions: [...state.ignoredRecognitions, action.payload],
    }),
    REMOVE_IGNORED_RECOGNITION: (state, action: PayloadAction<any>) => ({
      ...state,
      ignoredRecognitions: state.ignoredRecognitions.filter((recognition) => recognition.id !== action.payload.id),
    }),
    RECOGNIZE_BACK: (state, action: PayloadAction<AdmirationsModifiersObjectType>) => ({
      ...state,
      newRecognitions: state.newRecognitions.filter((recognition) => recognition.id !== action.payload.id),
      simpleRecognitions: [...state.simpleRecognitions, action.payload],
      mutualRecognitions: [...state.mutualRecognitions, action.payload],
    }),
    REMOVE_NEW_RECOGNITION: (state, action: PayloadAction<AdmirationsModifiersObjectType>) => ({
      ...state,
      newRecognitions: state.newRecognitions.filter((newRecognition) => newRecognition.id !== action.payload.id),
    }),
    REMOVE_ADMIRATION: (state, action: PayloadAction<AdmirationsModifiersObjectType>) => ({
      ...state,
      newRecognitions: [...state.newRecognitions, action.payload],
      simpleRecognitions: state.simpleRecognitions.filter(
        (simpleRecognition) => simpleRecognition.id !== action.payload.id
      ),
      mutualRecognitions: state.mutualRecognitions.filter(
        (mutualRecognition) => mutualRecognition.id !== action.payload.id
      ),
    }),
    DECLINE_NEW_RECOGNITION: (state, action: PayloadAction<AdmirationsModifiersObjectType>) => ({
      ...state,
      newRecognitions: state.newRecognitions.filter((newRecognition) => newRecognition.id !== action.payload.id),
    }),
    CLEAR_OTHER_ARTIST_RECOGNITIONS: (state) => ({
      ...state,
      otherArtistRecognitions: { Artists: [], Artworks: [] },
      sliceCurrentPage: 0,
    }),
    ENTER_CONNECTION_PAGE: (state, action: PayloadAction<boolean>) => ({
      ...state,
      enterConnectionPage: action.payload,
    }),
    SET_PREVIOUS_HANDLE: (state, action: PayloadAction<string>) => ({
      ...state,
      previousHandle: action.payload,
    }),
    SET_SLICE_CURRENT_PAGE: (state, action: PayloadAction<number>) => ({
      ...state,
      sliceCurrentPage: action.payload,
    }),
  },
});

export const useRecognitions = () => useAppSelector((state) => state?.recognitions);

export const admirationsModifiersActions = admirationsModifiersSlice.actions;

export default admirationsModifiersSlice;
