import {
  AppliedListingModifier,
  ARTWORK_LOCAL_CONDITION,
  FILTERS_DEFAULT_VALUE,
  isOptionType,
  ListingModifier,
  ListingModifierOption,
} from './types';

export type PriceOptionType = {
  min: number;
  max: number;
};

export const generatPriceMinMaxFilterOption = (min: number, max: number): ListingModifierOption => ({
  name: 'Price Range',
  value: FILTERS_DEFAULT_VALUE,
  key: 'ARTWORK_LOCAL_CONDITION',
  // if max === 0 then show all artworks
  payload: (art) =>
    max === 0 ||
    min === max ||
    (!!art.showcasePrice?.[0]?.price && art.showcasePrice?.[0]?.price >= min && art.showcasePrice?.[0]?.price <= max),
});

export const getPriceFilter = (): ListingModifier => ({
  type: 'PRICE',
  allOptions: [
    {
      value: FILTERS_DEFAULT_VALUE,
      name: 'filterStrings.price.all',
      key: 'ARTWORK_LOCAL_CONDITION',
      payload: () => true,
    },
  ],
});

export const getAppliedPriceFilter = (
  priceOptions: PriceOptionType
): AppliedListingModifier<ARTWORK_LOCAL_CONDITION> => {
  const priceFilter = getPriceFilter();
  const newPriceFilterOptions = generatPriceMinMaxFilterOption(priceOptions.min, priceOptions.max);
  priceFilter.allOptions = [newPriceFilterOptions];
  return {
    ...priceFilter,
    selectionType: 'SINGLE',
    selectedOptions: [priceOptions.min, priceOptions.max],
    flattenSelectedOptions: (options, selectedOptions) => {
      const flattenedOption = selectedOptions.length && options[0];
      if (!flattenedOption || !isOptionType<ARTWORK_LOCAL_CONDITION>(flattenedOption, 'ARTWORK_LOCAL_CONDITION')) {
        throw new Error('Wrong option type');
      }
      return flattenedOption;
    },
  };
};
