import { kaleidoHistoryTracker } from 'common/features/KaleidoHistoryTracker/config/useKaleidoHistoryTracker';
import { useHistory } from 'react-router';

export const useGoBackSkipConsecutiveShowcases = (): (() => void) => {
  const history = useHistory();

  const goBackSkipConsecutiveShowcases = () => {
    const showcasesToIgnore = kaleidoHistoryTracker.getPreviousConsecutiveShowcaseNumber();
    // Skip all consecutive showcases accessed before current page
    history.go(-showcasesToIgnore - 1);
  };

  return goBackSkipConsecutiveShowcases;
};
