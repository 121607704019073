import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import useAppSelector from './useAppSelector';

export const useCustomDomain = (): string | null => {
  const overrideCustomDomain = useAppSelector((store) => store.myWebsiteSettings.overrideCustomDomain);
  const customDomainData = useAppSelector((store) => store.domain);
  return overrideCustomDomain || customDomainData.domain?.profileHandle || '';
};

export const useCustomDomainData = () => useSelector((store: RootState) => store.domain);
