import { AuthStateProps } from '../handlers/AuthHandler/types';
import { FirebaseStateProps } from '../handlers/FirebaseHandler/types';
import { ProfileStateProps } from '../handlers/ProfileHandler/types';

export enum AUTH_PUBLISHER {
  AUTH_HANDLER = 'AUTH_HANDLER',
  PROFILE_HANDLER = 'PROFILE_HANDLER',
  FIREBASE_HANDLER = 'FIREBASE_HANDLER',
}

export type ProxyHandlerTarget = {
  [AUTH_PUBLISHER.AUTH_HANDLER]: AuthStateProps;
  [AUTH_PUBLISHER.PROFILE_HANDLER]: ProfileStateProps;
  [AUTH_PUBLISHER.FIREBASE_HANDLER]: FirebaseStateProps;
};

export type FirebaseEmailCreds = {
  email: string;
  password: string;
};

export type FirebaseTokenRegisterCreds = {
  email: string;
  firstName?: string;
  languagePreference?: string;
  action?: string;
  actionId?: number;
  invitationToken: string;
};

export type FirebaseTokenLoginCreds = {
  firebaseToken: string;
};

export type SignUpResult = { result: boolean; message?: string };
