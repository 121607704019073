import { authActions } from 'store/slices/authSlice';
import { store } from 'store/store';
import { AuthStatusEnum } from '../handlers/AuthHandler/types';
import { FirebaseStateProps } from '../handlers/FirebaseHandler/types';
import { AUTH_PUBLISHER, ProxyHandlerTarget } from './types';

/**
 * This provides a proxy handler that will update the redux store whenever a value is set
 * in any handler (Auth/Firebase/Profile...)
 */

const authHandlerStoreDispatcher = (value: AuthStatusEnum): void => {
  store.dispatch(authActions.SET_AUTH_STATUS(value));
};

const profileHandlerStoreDispatcher = (value: string): void => {
  store.dispatch(authActions.SET_PROFILE_EMAIL(value));
};

const firebaseHandlerStoreDispatcher = (
  value: string,
  property: keyof Pick<FirebaseStateProps, 'firebaseUserEmail' | 'firebaseUserId'>
): void => {
  switch (property) {
    case 'firebaseUserEmail':
      store.dispatch(authActions.SET_FIREBASE_USER_EMAIL(value));
      break;
    case 'firebaseUserId':
      store.dispatch(authActions.SET_FIREBASE_USER_ID(Number(value)));
      break;
    default:
      break;
  }
};

const reduxStoreDispatchers: Record<AUTH_PUBLISHER, (value: any, property?: any) => void> = {
  [AUTH_PUBLISHER.AUTH_HANDLER]: authHandlerStoreDispatcher,
  [AUTH_PUBLISHER.PROFILE_HANDLER]: profileHandlerStoreDispatcher,
  [AUTH_PUBLISHER.FIREBASE_HANDLER]: firebaseHandlerStoreDispatcher,
};

export const getProxyHandler = <T extends AUTH_PUBLISHER>(publisher: T) => ({
  set: (
    target: ProxyHandlerTarget[T],
    property: keyof ProxyHandlerTarget[T],
    value: ProxyHandlerTarget[T][keyof ProxyHandlerTarget[T]]
  ): boolean => {
    target[property] = value;
    reduxStoreDispatchers[publisher](value, property);
    return true;
  },
});
