/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useShouldShowFeatureFlag } from 'common/features/FeatureFlag/hooks/useFeatureFlag';
import useAppSelector from 'common/hooks/useAppSelector';
import {
  useAuthHandlerCreateAppAccountMutation,
  useAuthHandlerRequestFirebaseCustomAuthMutation,
} from 'common/schema/commonSchemaRemoteGraphqlQueries';
import { useDispatch } from 'react-redux';
import { accountActions } from 'store/slices/account';
import { FirebaseEmailCreds, FirebaseTokenRegisterCreds } from './commons/types';
import { AuthCallbacks } from './handlers/AuthHandler';
import { AccountDetails, AuthStatusEnum } from './handlers/AuthHandler/types';
import { FINAL_AUTH_STATUS, ONBOARDING_AUTH_STATUS } from './handlers/FirebaseHandler/utils';
import { usePostLoginActions } from './hooks/usePostLoginActions';
import { usePreLogoutActions } from './hooks/usePrelogoutActions';

/**
 * This is the main Auth service that will be used by the app
 * This should be the Gate between Authentication and the rest of the app
 */
export default {
  useLogin: () => ({
    emailAndPasswordLogin: AuthCallbacks.emailAndPasswordLogin,
    customTokenLogin: AuthCallbacks.customTokenLogin,
  }),
  useSignUp: () => {
    const [isUnityWebView] = useShouldShowFeatureFlag('unityWebView');
    const handlePostLoginActions = usePostLoginActions();
    const [createAppAccount] = useAuthHandlerCreateAppAccountMutation({
      fetchPolicy: 'network-only',
    });
    const [requestFirebaseCustomAuth] = useAuthHandlerRequestFirebaseCustomAuthMutation({
      fetchPolicy: 'network-only',
    });

    return {
      emailAndPasswordSignUp: async (creds: FirebaseEmailCreds, accountDetails: AccountDetails) => {
        const result = await AuthCallbacks.emailAndPasswordSignUp(creds, accountDetails, createAppAccount);
        await handlePostLoginActions();
        return result;
      },
      customTokenSignUp: async (creds: FirebaseTokenRegisterCreds) => {
        const result = await AuthCallbacks.customTokenSignUp(creds, requestFirebaseCustomAuth);
        return result;
      },
      appleSignUp: AuthCallbacks.appleSignUp,
      googleSignUp: async (requestId?: string, encodedIntent?: string) => {
        await AuthCallbacks.googleSignUp(isUnityWebView, requestId, encodedIntent);
      },
    };
  },
  checkPassword: AuthCallbacks.checkPassword,
  resetPassword: AuthCallbacks.resetPassword,
  updateEmail: AuthCallbacks.updateEmail,
  updatePassword: AuthCallbacks.updatePassword,
  useLogout: () => {
    const handlePreLogoutActions = usePreLogoutActions();
    const dispatch = useDispatch();

    return {
      logout: async () => {
        await handlePreLogoutActions();
        dispatch(accountActions.LOG_OUT());
        await AuthCallbacks.logout();
      },
    };
  },
  useIsAuthComplete: (): boolean => {
    const authStatus = useAppSelector((state) => state.auth.status);
    return FINAL_AUTH_STATUS.includes(authStatus);
  },
  useIsAuthenticated: (): boolean => {
    // ! Do not use this hook. This should be used only by the Profile Provider
    const authStatus = useAppSelector((state) => state.auth.status);
    return authStatus === AuthStatusEnum.AUTHENTICATED;
  },
  useIsLoggedIn: (): boolean => {
    const authStatus = useAppSelector((state) => state.auth.status);
    return authStatus === AuthStatusEnum.LOGGED_IN;
  },
  useIsOnboardingStatus: (): boolean => {
    const authStatus = useAppSelector((state) => state.auth.status);
    return ONBOARDING_AUTH_STATUS.includes(authStatus);
  },
  useIsValidAccount: (): boolean => {
    // This is used by AuthGuardRoute to determine if the user is in a valid auth state to access the route
    const authStatus = useAppSelector((state) => state.auth.status);
    return [...ONBOARDING_AUTH_STATUS, AuthStatusEnum.LOGGED_IN].includes(authStatus);
  },
  useIsLoggedOut: (): boolean => {
    const authStatus = useAppSelector((state) => state.auth.status);
    return authStatus === AuthStatusEnum.LOGGED_OUT;
  },
  useIsPending: (): boolean => {
    // ! Do not use this hook. This should be used only by FirebaseLoader
    const authStatus = useAppSelector((state) => state.auth.status);
    return authStatus === AuthStatusEnum.PENDING;
  },
  useFirebaseUserId: (): number | null => {
    // This is used to access the Firebase user id, DO NOT use this to verify if the user is logged in
    const firebaseUserId = useAppSelector((state) => state.auth.firebaseUserId);
    return firebaseUserId;
  },
  useFirebaseUserEmail: (): string | null => {
    // This is used to access the Firebase user email, DO NOT use this to verify if the user is logged in
    const firebaseUserEmail = useAppSelector((state) => state.auth.firebaseUserEmail);
    return firebaseUserEmail;
  },
};
