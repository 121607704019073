import { CountryFilterState } from 'common/features/Filters/predefined/Country/store/country.types';
import { InterestsFilterState } from 'common/features/Filters/predefined/Interests/store/interests.types';

export const sorts = ['byDate'] as const;
export type CollectorsSort = (typeof sorts)[0];

export const isCollectorsSort = (v: string): v is CollectorsSort => sorts.includes(v as CollectorsSort);

// currently auto-applies on every change
// maybe todo: add flag to apply on demand
export type CollectorsFiltersState = InterestsFilterState &
  CountryFilterState & {
    sort: CollectorsSort;
  };
