import VirtualList from 'common/features/VirtualList/VirtualList';
import { KnownVirtualListEnum, SortOptionsEnum } from 'common/features/VirtualList/VirtualList.config';
import {
  GetArtistProfileArtworksListingByArtistOrderQuery,
  GetArtistProfileArtworksListingByDateQuery,
  GetArtistProfileArtworksListingByPriceQuery,
  GetArtistProfileArtworksListingBySizeQuery,
} from 'common/schema/commonSchemaRemoteOperationTypes';

type RequiredArtworkId = { artworkId: number };
const {
  listingCursor: { getDefaultListingCursor, nextPage, prevPage },
  sortOptions: { generateSortOptionsAndDefault },
} = VirtualList;

export const [, optionsByArtistSelection] = generateSortOptionsAndDefault(SortOptionsEnum.ARTIST_SELECTION);
export type ByArtistSelectionSortOptions = (typeof optionsByArtistSelection)[number];
export type ByArtistSelectionCursor = RequiredArtworkId &
  GetArtistProfileArtworksListingByArtistOrderQuery['artworks'][0];
export const cursorsByArtistSelection: Record<
  ByArtistSelectionSortOptions,
  [ByArtistSelectionCursor, ByArtistSelectionCursor]
> = {
  [SortOptionsEnum.ARTIST_SELECTION]: getDefaultListingCursor<ByArtistSelectionCursor>(
    { artworkId: prevPage.bigint.bigger, artistOrder: prevPage.int.smaller },
    { artworkId: nextPage.bigint.smaller, artistOrder: nextPage.int.bigger }
  ),
};

export const [, optionsByDate] = generateSortOptionsAndDefault(
  SortOptionsEnum.MOST_RECENT,
  SortOptionsEnum.LEAST_RECENT
);
export type ByDateSortOptions = (typeof optionsByDate)[number];
export type ByDateCursor = RequiredArtworkId & GetArtistProfileArtworksListingByDateQuery['artworks'][0];
export const cursorsByDate: Record<ByDateSortOptions, [ByDateCursor, ByDateCursor]> = {
  [SortOptionsEnum.MOST_RECENT]: getDefaultListingCursor<ByDateCursor>(
    { artworkId: prevPage.bigint.bigger, publishedDate: prevPage.date.future },
    { artworkId: nextPage.bigint.smaller, publishedDate: nextPage.date.past }
  ),
  [SortOptionsEnum.LEAST_RECENT]: getDefaultListingCursor<ByDateCursor>(
    { artworkId: prevPage.bigint.bigger, publishedDate: prevPage.date.past },
    { artworkId: nextPage.bigint.smaller, publishedDate: nextPage.date.future }
  ),
};

export const [, optionsByPrice] = generateSortOptionsAndDefault(
  SortOptionsEnum.MOST_EXPENSIVE,
  SortOptionsEnum.LEAST_EXPENSIVE
);
export type ByPriceSortOptions = (typeof optionsByPrice)[number];
export type ByPriceCursor = RequiredArtworkId & GetArtistProfileArtworksListingByPriceQuery['artworks'][0];
export const cursorsByPrice: Record<ByPriceSortOptions, [ByPriceCursor, ByPriceCursor]> = {
  [SortOptionsEnum.MOST_EXPENSIVE]: getDefaultListingCursor<ByPriceCursor>(
    { artworkId: prevPage.bigint.bigger, price: prevPage.bigint.bigger },
    { artworkId: nextPage.bigint.smaller, price: nextPage.bigint.smaller }
  ),
  [SortOptionsEnum.LEAST_EXPENSIVE]: getDefaultListingCursor<ByPriceCursor>(
    { artworkId: prevPage.bigint.bigger, price: prevPage.bigint.smaller },
    { artworkId: nextPage.bigint.smaller, price: nextPage.bigint.bigger }
  ),
};

export const [, optionsBySize] = generateSortOptionsAndDefault(
  SortOptionsEnum.BIGGEST_SIZE,
  SortOptionsEnum.SMALLEST_SIZE
);
export type BySizeSortOptions = (typeof optionsBySize)[number];
export type BySizeCursor = RequiredArtworkId & GetArtistProfileArtworksListingBySizeQuery['artworks'][0];
export const cursorsBySize: Record<BySizeSortOptions, [BySizeCursor, BySizeCursor]> = {
  [SortOptionsEnum.BIGGEST_SIZE]: getDefaultListingCursor<BySizeCursor>(
    { artworkId: prevPage.bigint.bigger, area: prevPage.int.bigger },
    { artworkId: nextPage.bigint.smaller, area: nextPage.int.smaller }
  ),
  [SortOptionsEnum.SMALLEST_SIZE]: getDefaultListingCursor<BySizeCursor>(
    { artworkId: prevPage.bigint.bigger, area: prevPage.int.smaller },
    { artworkId: nextPage.bigint.smaller, area: nextPage.int.bigger }
  ),
};

export const optionsForArtistProfileArtworks = [
  ...optionsByArtistSelection,
  ...optionsByDate,
  ...optionsByPrice,
  ...optionsBySize,
];
export const [defaultSortForArtistProfileArtworks] = generateSortOptionsAndDefault(...optionsForArtistProfileArtworks);
export type ArtistProfileArtworksSortOptions =
  | ByArtistSelectionSortOptions
  | ByDateSortOptions
  | ByPriceSortOptions
  | BySizeSortOptions;
export type ArtistProfileArtworksCursor = ByArtistSelectionCursor | ByDateCursor | ByPriceCursor | BySizeCursor;
export const cursorsForArtistProfileArtworks: Record<
  ArtistProfileArtworksSortOptions,
  [ArtistProfileArtworksCursor, ArtistProfileArtworksCursor]
> = {
  ...cursorsByArtistSelection,
  ...cursorsByDate,
  ...cursorsByPrice,
  ...cursorsBySize,
};

export const {
  listSlice: listArtistProfileArtworksSlice,
  getSpecificCursor: getSpecificArtistProfileArtworksCursor,
  getDefaultVirtualList: getDefaultArtistProfileArtworksVirtualList,
} = VirtualList.slices.createListSlice<ArtistProfileArtworksSortOptions, ArtistProfileArtworksCursor>({
  defaultBatchSizes: {
    listedBatchSize: 128,
    detailedBatchSize: 32,
  },
  allSpecificCursors: cursorsForArtistProfileArtworks,
  virtualListIdentifier: KnownVirtualListEnum.ARTIST_PROFILE_ARTWORKS,
  defaultFilterHash: '',
  defaultSortOption: SortOptionsEnum.ARTIST_SELECTION,
});
