import { makeStyles } from '@material-ui/core';

export const provenanceContainerStyles = makeStyles(() => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
  },
  container: {
    height: '100%',
    overflowY: 'scroll',
    width: '100%',

    '&::-webkit-scrollbar': {
      display: 'none',
    },

    scrollbarWidth: 'none',
  },
  title: {
    zIndex: 1000,
    position: 'relative',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '22px',
    lineHeight: '14px',
    color: '#000000',

    marginTop: 5,
    marginBottom: 16,
  },
  customProvenanceStyle: {
    zIndex: 100,
    transition: '0.5s all ease-in',
    '& > div > div > div:first-child': {
      marginRight: '7px !important',
    },

    '& > div > div > div:first-child > div > div:nth-child(2)': {
      borderLeft: '1px solid #434448',
    },

    '& > div > div > div:nth-child(2) > h4': {
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '21px',
      color: '#757575',
      marginBottom: 0,
    },

    '& > div > div > div:nth-child(2) > h5': {
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '21px',
      color: 'black',
    },
  },
}));
