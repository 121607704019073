import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import notificationThreadCount from 'common/features/DirectMessaging/utils/notificationThreadCount';
import useAppSelector from 'common/hooks/useAppSelector';

export enum NOTIFICATION_CENTER_VIEWS {
  NOTIFICATIONS = 'NOTIFICATIONS',
  MILESTONES = 'MILESTONES',
  RECOGNITIONS = 'RECOGNITIONS',
}

export type NotificationsState = {
  count: number;
  hasMilestoneCount: boolean;
  hasSetInitialCount: boolean;
  initialOffset: number;
  isPanelOpen: boolean;
  isDismissingAllNotifications: boolean;
  notificationsLoaded: boolean;
  currentView: NOTIFICATION_CENTER_VIEWS;
  shouldRefreshList?: number;
  newNotificationCount: number;
};

const initialState: NotificationsState = {
  count: 0,
  hasMilestoneCount: false,
  hasSetInitialCount: false,
  initialOffset: 0,
  isPanelOpen: false,
  isDismissingAllNotifications: false,
  notificationsLoaded: false,
  currentView: NOTIFICATION_CENTER_VIEWS.NOTIFICATIONS,
  newNotificationCount: 0,
};

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    /* MARK_NOTIFICATION_AS_READ: (state, action: PayloadAction<NotificationsType>) => ({
      ...state,
      appNotifications: state.appNotifications.map((notification) => ({
        ...notification,
        read_at: new Date().toDateString(),
      })),
    }), */
    SET_COUNT: (state, action: PayloadAction<number>) => ({
      ...state,
      count: action.payload,
    }),
    SET_INITIAL_COUNT: (state, action: PayloadAction<number>) => ({
      ...state,
      count: action.payload,
      hasSetInitialCount: true,
    }),
    INCREASE_COUNT: (state) => {
      if (state.hasSetInitialCount) {
        return {
          ...state,
          hasSetInitialCount: false,
        };
      }
      return {
        ...state,
        count: state.count + 1,
      };
    },
    INCREASE_MILESTONE_NOTIFICATIONS_COUNT: (state) => {
      if (!state.hasMilestoneCount) {
        return {
          ...state,
          count: state.count + 1,
          hasMilestoneCount: true,
        };
      }
      return { ...state };
    },
    DECREASE_MILESTONE_NOTIFICATIONS_COUNT: (state) => {
      if (state.hasMilestoneCount) {
        return {
          ...state,
          count: state.count - 1,
          hasMilestoneCount: false,
        };
      }
      return { ...state };
    },
    SET_INITIAL_OFFSET: (state, action: PayloadAction<number>) => ({
      ...state,
      initialOffset: action.payload,
    }),
    SET_OPEN: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isPanelOpen: action.payload,
      isMilestonesOpen: false,
    }),
    SET_CURRENT_VIEW: (state, action: PayloadAction<NOTIFICATION_CENTER_VIEWS>) => ({
      ...state,
      currentView: action.payload,
    }),
    SET_IS_DISMISSING_ALL_NOTIFICATIONS: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isDismissingAllNotifications: action.payload,
    }),
    SET_NOTIFICATIONS_LOADED: (state, action: PayloadAction<boolean>) => ({
      ...state,
      notificationsLoaded: action.payload,
    }),
    REFRESH_LIST: (state, action: PayloadAction<number>) => ({
      ...state,
      shouldRefreshList: action.payload,
    }),
    INCREMENT_NEW_NOTIFICATION_COUNT: (state) => ({
      ...state,
      newNotificationCount: !state.isPanelOpen ? state.newNotificationCount + 1 : state.newNotificationCount,
    }),
    UPDATE_NEW_NOTIFICATION_COUNT: (state, action: PayloadAction<number>) => {
      if (action.payload === 0) {
        notificationThreadCount.reset();
      }
      return {
        ...state,
        newNotificationCount: action.payload,
      };
    },
  },
});

export const useNotifications = () => useAppSelector((state) => state.notifications);

export const notificationsActions = notificationsSlice.actions;

export default notificationsSlice;
