import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type MenuButtonAnimationState = 'OPENED' | 'CLOSED' | 'OPENING' | 'CLOSING';

type InitialStateType = {
  animationState: MenuButtonAnimationState;
  tutorialDone: boolean;
  disableClick: boolean;
  visible: boolean;
};

const initialState: InitialStateType = {
  animationState: 'CLOSED',
  tutorialDone: false,
  disableClick: false,
  visible: true,
};

export const consumerProductMenuButtonSlice = createSlice({
  name: 'consumerProductMenuButton',
  initialState,
  reducers: {
    /**
     * Tutorial for menu button.
     * While the tutorial is opened, the user can not click on the menu button.
     */
    TUTORIAL: (
      state,
      action: PayloadAction<{
        start: boolean;
      }>
    ) => {
      if (!state.tutorialDone) {
        state.disableClick = true;
        state.animationState = action.payload.start ? 'OPENING' : 'CLOSING';

        if (!action.payload.start) {
          state.tutorialDone = true;
        }
      }
    },
    /**
     * It starts the closing/opening menu animation.
     * It updates the animation status to 'OPENING'/ 'CLOSING'.
     * While the animation is in progress the user can not click again on the button.
     * USAGE: Use it whenever you want to close/ open the menu.
     */
    ON_START_ANIMATION: (
      state,
      action: PayloadAction<{
        animationAction: 'OPENING' | 'CLOSING';
      }>
    ) => {
      if (!state.tutorialDone) {
        return;
      }

      if (state.animationState === action.payload.animationAction) {
        return;
      }

      if (state.disableClick) {
        return;
      }

      state.animationState = action.payload.animationAction;
      state.disableClick = true;
    },
    /**
     * After the animation is finished, the animation states is updated to 'CLOSED'/ 'OPENED'.
     * And also it re-enables user's ability to click on the button.
     * It is used only onAnimationEnd.
     */
    ON_END_ANIMATION: (
      state,
      action: PayloadAction<{
        animationAction: 'OPENING' | 'CLOSING';
      }>
    ) => {
      if (!state.tutorialDone) {
        return;
      }

      if (state.disableClick) {
        if (action.payload.animationAction === 'OPENING') {
          state.animationState = 'OPENED';
        }
        if (action.payload.animationAction === 'CLOSING') {
          state.animationState = 'CLOSED';
        }
        state.disableClick = false;
      }
    },
    SET_VISIBILITY: (state, action: PayloadAction<{ visible: boolean }>) => {
      state.visible = action.payload.visible;
    },
  },
});

export const { actions } = consumerProductMenuButtonSlice;
