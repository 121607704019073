import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createSelectCountryCaseReducer } from 'common/features/Filters/predefined/Country/store/country.reducers';
import { createToggleInterestCaseReducer } from 'common/features/Filters/predefined/Interests/store/interests.reducers';
import { filterInitialState, getDefaultFilters } from './filter.defaults';
import { CollectorsFiltersState } from './filter.types';

export const collectorsFiltersSlice = createSlice({
  name: 'filter',
  initialState: filterInitialState,
  reducers: {
    RESET_Collectors: getDefaultFilters,
    SORT_Collectors: (state, action: PayloadAction<CollectorsFiltersState['sort']>) => ({
      ...state,
      sort: action.payload,
    }),
    TOGGLE_INTEREST_Collectors: createToggleInterestCaseReducer<CollectorsFiltersState>(),
    CHANGE_COUNTRY_Collectors: createSelectCountryCaseReducer<CollectorsFiltersState>(),
  },
});
