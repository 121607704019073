import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type Invite = {
  token?: string | null;
  preset?: string | null;
};

const initialState: Invite = {
  token: null,
  preset: null,
};

const inviteSlice = createSlice({
  initialState,
  name: 'invite',
  reducers: {
    SET_INVITE_DATA: (state, action: PayloadAction<Invite>) => ({
      ...state,
      ...action.payload,
    }),
  },
});

export const inviteActions = inviteSlice.actions;
export const inviteReducer = inviteSlice.reducer;
