import React, { useCallback, useRef } from 'react';
import useAsyncState from './useAsyncState';

const useCooldownState = <T>(initialValue: T, cooldown = 500): [T, React.Dispatch<React.SetStateAction<T>>] => {
  const [state, setState] = useAsyncState(initialValue);
  const setAt = useRef(0);

  const setCooldownState: React.Dispatch<React.SetStateAction<T>> = useCallback((value: React.SetStateAction<T>) => {
    const timeLeft = Date.now() - setAt.current;
    if (timeLeft < cooldown) {
      const timer = window.setTimeout(() => {
        setState(value);
      }, timeLeft);
      return () => window.clearTimeout(timer);
    }

    setState(value);
    return () => undefined;
  }, []);

  return [state, setCooldownState];
};

export default useCooldownState;
