import { CurrencyTypeEnum } from 'common/components/CurrencySelector/CurrencySelector.formik';
import { Enum_Profile_Region_Enum } from 'common/schema/commonSchemaRemoteTypes';

export type ICountry = {
  translationKey: string;
  displayName: string;
  searchName: string;
  iso2: string;
  iso3: string;
  prio?: number;
  region: Enum_Profile_Region_Enum;
  currency?: CurrencyTypeEnum;
};

// TODO: [citiesByCountryAndState] double check against cities table and update
export const countriesWithNoCities = [
  'AS',
  'AI',
  'BM',
  'CC',
  'KY',
  'FK',
  'VG',
  'FO',
  'CX',
  'GP',
  'PF',
  'YT',
  'PR',
  'PS',
  'PN',
  'GG',
  'IO',
  'HM',
  'BV',
  'HK',
  'SX',
  'GS',
  'AW',
  'CK',
  'GF',
  'GL',
  'VA',
  'GU',
  'MH',
  'IM',
  'NC',
  'MQ',
  'MC',
  'GI',
  'AN',
  'RE',
  'NF',
  'XK',
  'WF',
  'BL',
  'SJ',
  'MS',
  'CW',
  'TF',
  'TC',
  'MP',
  'AQ',
  'TK',
  'JE',
  'EH',
  'SH',
  'BQ',
  'VI',
  'MO',
  'MF',
  'AX',
  'PM',
  'NU',
  'UM',
];

// TODO: [citiesByCountryAndState] add regions to Countries tables and compute the rest
export const countryList: ICountry[] = [
  {
    translationKey: 'strings.geolocation.countries.isoCodes.AF',
    displayName: 'Afghanistan',
    searchName: 'afghanistan',
    iso2: 'AF',
    iso3: 'AFG',
    region: Enum_Profile_Region_Enum.Asia,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.AL',
    displayName: 'Albania',
    searchName: 'albania',
    iso2: 'AL',
    iso3: 'ALB',
    region: Enum_Profile_Region_Enum.Europe,
    currency: CurrencyTypeEnum['€'],
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.DZ',
    displayName: 'Algeria',
    searchName: 'algeria',
    iso2: 'DZ',
    iso3: 'DZA',
    region: Enum_Profile_Region_Enum.Africa,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.AS',
    displayName: 'American Samoa',
    searchName: 'americansamoa',
    iso2: 'AS',
    iso3: 'ASM',
    region: Enum_Profile_Region_Enum.Oceania,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.AD',
    displayName: 'Andorra',
    searchName: 'andorra',
    iso2: 'AD',
    iso3: 'AND',
    region: Enum_Profile_Region_Enum.Europe,
    currency: CurrencyTypeEnum['€'],
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.AO',
    displayName: 'Angola',
    searchName: 'angola',
    iso2: 'AO',
    iso3: 'AGO',
    region: Enum_Profile_Region_Enum.Africa,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.AI',
    displayName: 'Anguilla',
    searchName: 'anguilla',
    iso2: 'AI',
    iso3: 'AIA',
    region: Enum_Profile_Region_Enum.NorthAmerica,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.AG',
    displayName: 'Antigua and Barbuda',
    searchName: 'antiguaandbarbuda',
    iso2: 'AG',
    iso3: 'ATG',
    region: Enum_Profile_Region_Enum.NorthAmerica,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.AR',
    displayName: 'Argentina',
    searchName: 'argentina',
    iso2: 'AR',
    iso3: 'ARG',
    region: Enum_Profile_Region_Enum.SouthAmerica,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.AM',
    displayName: 'Armenia',
    searchName: 'armenia',
    iso2: 'AM',
    iso3: 'ARM',
    region: Enum_Profile_Region_Enum.Asia,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.AW',
    displayName: 'Aruba',
    searchName: 'aruba',
    iso2: 'AW',
    iso3: 'ABW',
    region: Enum_Profile_Region_Enum.NorthAmerica,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.AU',
    displayName: 'Australia',
    searchName: 'australia',
    iso2: 'AU',
    iso3: 'AUS',
    region: Enum_Profile_Region_Enum.Oceania,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.AT',
    displayName: 'Austria',
    searchName: 'austria',
    iso2: 'AT',
    iso3: 'AUT',
    region: Enum_Profile_Region_Enum.Europe,
    currency: CurrencyTypeEnum['€'],
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.AZ',
    displayName: 'Azerbaijan',
    searchName: 'azerbaijan',
    iso2: 'AZ',
    iso3: 'AZE',
    region: Enum_Profile_Region_Enum.Asia,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.BS',
    displayName: 'The Bahamas',
    searchName: 'thebahamas',
    iso2: 'BS',
    iso3: 'BHS',
    region: Enum_Profile_Region_Enum.NorthAmerica,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.BH',
    displayName: 'Bahrain',
    searchName: 'bahrain',
    iso2: 'BH',
    iso3: 'BHR',
    region: Enum_Profile_Region_Enum.Asia,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.BD',
    displayName: 'Bangladesh',
    searchName: 'bangladesh',
    iso2: 'BD',
    iso3: 'BGD',
    region: Enum_Profile_Region_Enum.Asia,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.BB',
    displayName: 'Barbados',
    searchName: 'barbados',
    iso2: 'BB',
    iso3: 'BRB',
    region: Enum_Profile_Region_Enum.NorthAmerica,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.BY',
    displayName: 'Belarus',
    searchName: 'belarus',
    iso2: 'BY',
    iso3: 'BLR',
    region: Enum_Profile_Region_Enum.Europe,
    currency: CurrencyTypeEnum['€'],
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.BE',
    displayName: 'Belgium',
    searchName: 'belgium',
    iso2: 'BE',
    iso3: 'BEL',
    region: Enum_Profile_Region_Enum.Europe,
    currency: CurrencyTypeEnum['€'],
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.BZ',
    displayName: 'Belize',
    searchName: 'belize',
    iso2: 'BZ',
    iso3: 'BLZ',
    region: Enum_Profile_Region_Enum.NorthAmerica,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.BJ',
    displayName: 'Benin',
    searchName: 'benin',
    iso2: 'BJ',
    iso3: 'BEN',
    region: Enum_Profile_Region_Enum.Africa,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.BM',
    displayName: 'Bermuda',
    searchName: 'bermuda',
    iso2: 'BM',
    iso3: 'BMU',
    region: Enum_Profile_Region_Enum.NorthAmerica,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.BT',
    displayName: 'Bhutan',
    searchName: 'bhutan',
    iso2: 'BT',
    iso3: 'BTN',
    region: Enum_Profile_Region_Enum.Asia,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.BO',
    displayName: 'Bolivia',
    searchName: 'bolivia',
    iso2: 'BO',
    iso3: 'BOL',
    region: Enum_Profile_Region_Enum.SouthAmerica,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.BA',
    displayName: 'Bosnia and Herzegovina',
    searchName: 'bosniaandherzegovina',
    iso2: 'BA',
    iso3: 'BIH',
    region: Enum_Profile_Region_Enum.Europe,
    currency: CurrencyTypeEnum['€'],
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.BW',
    displayName: 'Botswana',
    searchName: 'botswana',
    iso2: 'BW',
    iso3: 'BWA',
    region: Enum_Profile_Region_Enum.Africa,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.BR',
    displayName: 'Brazil',
    searchName: 'brazil',
    iso2: 'BR',
    iso3: 'BRA',
    region: Enum_Profile_Region_Enum.SouthAmerica,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.VG',
    displayName: 'British Virgin Islands',
    searchName: 'britishvirginislands',
    iso2: 'VG',
    iso3: 'VGB',
    region: Enum_Profile_Region_Enum.NorthAmerica,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.BN',
    displayName: 'Brunei',
    searchName: 'brunei',
    iso2: 'BN',
    iso3: 'BRN',
    region: Enum_Profile_Region_Enum.Asia,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.BG',
    displayName: 'Bulgaria',
    searchName: 'bulgaria',
    iso2: 'BG',
    iso3: 'BGR',
    region: Enum_Profile_Region_Enum.Europe,
    currency: CurrencyTypeEnum['€'],
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.BF',
    displayName: 'Burkina Faso',
    searchName: 'burkinafaso',
    iso2: 'BF',
    iso3: 'BFA',
    region: Enum_Profile_Region_Enum.Africa,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.BI',
    displayName: 'Burundi',
    searchName: 'burundi',
    iso2: 'BI',
    iso3: 'BDI',
    region: Enum_Profile_Region_Enum.Africa,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.KH',
    displayName: 'Cambodia',
    searchName: 'cambodia',
    iso2: 'KH',
    iso3: 'KHM',
    region: Enum_Profile_Region_Enum.Asia,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.CM',
    displayName: 'Cameroon',
    searchName: 'cameroon',
    iso2: 'CM',
    iso3: 'CMR',
    region: Enum_Profile_Region_Enum.Africa,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.CA',
    displayName: 'Canada',
    searchName: 'canada',
    iso2: 'CA',
    iso3: 'CAN',
    region: Enum_Profile_Region_Enum.NorthAmerica,
    currency: CurrencyTypeEnum.C$,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.CV',
    displayName: 'Cape Verde',
    searchName: 'capeverde',
    iso2: 'CV',
    iso3: 'CPV',
    region: Enum_Profile_Region_Enum.Africa,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.KY',
    displayName: 'Cayman Islands',
    searchName: 'caymanislands',
    iso2: 'KY',
    iso3: 'CYM',
    region: Enum_Profile_Region_Enum.NorthAmerica,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.CF',
    displayName: 'Central African Republic',
    searchName: 'centralafricanrepublic',
    iso2: 'CF',
    iso3: 'CAF',
    region: Enum_Profile_Region_Enum.Africa,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.TD',
    displayName: 'Chad',
    searchName: 'chad',
    iso2: 'TD',
    iso3: 'TCD',
    region: Enum_Profile_Region_Enum.Africa,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.CL',
    displayName: 'Chile',
    searchName: 'chile',
    iso2: 'CL',
    iso3: 'CHL',
    region: Enum_Profile_Region_Enum.SouthAmerica,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.CN',
    displayName: 'China',
    searchName: 'china',
    iso2: 'CN',
    iso3: 'CHN',
    region: Enum_Profile_Region_Enum.Asia,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.CX',
    displayName: 'Christmas Island',
    searchName: 'christmasisland',
    iso2: 'CX',
    iso3: 'CXR',
    region: Enum_Profile_Region_Enum.Asia,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.CC',
    displayName: 'Cocos (Keeling) Islands',
    searchName: 'cocos(keeling)islands',
    iso2: 'CC',
    iso3: 'CCK',
    region: Enum_Profile_Region_Enum.Asia,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.CO',
    displayName: 'Colombia',
    searchName: 'colombia',
    iso2: 'CO',
    iso3: 'COL',
    region: Enum_Profile_Region_Enum.SouthAmerica,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.KM',
    displayName: 'Comoros',
    searchName: 'comoros',
    iso2: 'KM',
    iso3: 'COM',
    region: Enum_Profile_Region_Enum.Africa,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.CG',
    displayName: 'Republic of the Congo',
    searchName: 'republicofthecongo',
    iso2: 'CG',
    iso3: 'COG',
    region: Enum_Profile_Region_Enum.Africa,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.CK',
    displayName: 'Cook Islands',
    searchName: 'cookislands',
    iso2: 'CK',
    iso3: 'COK',
    region: Enum_Profile_Region_Enum.Oceania,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.CR',
    displayName: 'Costa Rica',
    searchName: 'costarica',
    iso2: 'CR',
    iso3: 'CRI',
    region: Enum_Profile_Region_Enum.NorthAmerica,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.CI',
    displayName: "Cote d'Ivoire",
    searchName: "coted'ivoire",
    iso2: 'CI',
    iso3: 'CIV',
    region: Enum_Profile_Region_Enum.Africa,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.HR',
    displayName: 'Croatia',
    searchName: 'croatia',
    iso2: 'HR',
    iso3: 'HRV',
    region: Enum_Profile_Region_Enum.Europe,
    currency: CurrencyTypeEnum['€'],
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.CU',
    displayName: 'Cuba',
    searchName: 'cuba',
    iso2: 'CU',
    iso3: 'CUB',
    region: Enum_Profile_Region_Enum.NorthAmerica,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.CY',
    displayName: 'Cyprus',
    searchName: 'cyprus',
    iso2: 'CY',
    iso3: 'CYP',
    region: Enum_Profile_Region_Enum.Asia,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.CZ',
    displayName: 'Czech Republic',
    searchName: 'czechrepublic',
    iso2: 'CZ',
    iso3: 'CZE',
    region: Enum_Profile_Region_Enum.Europe,
    currency: CurrencyTypeEnum['€'],
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.DK',
    displayName: 'Denmark',
    searchName: 'denmark',
    iso2: 'DK',
    iso3: 'DNK',
    region: Enum_Profile_Region_Enum.Europe,
    currency: CurrencyTypeEnum['€'],
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.DJ',
    displayName: 'Djibouti',
    searchName: 'djibouti',
    iso2: 'DJ',
    iso3: 'DJI',
    region: Enum_Profile_Region_Enum.Africa,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.DM',
    displayName: 'Dominica',
    searchName: 'dominica',
    iso2: 'DM',
    iso3: 'DMA',
    region: Enum_Profile_Region_Enum.NorthAmerica,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.DO',
    displayName: 'Dominican Republic',
    searchName: 'dominicanrepublic',
    iso2: 'DO',
    iso3: 'DOM',
    region: Enum_Profile_Region_Enum.NorthAmerica,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.EC',
    displayName: 'Ecuador',
    searchName: 'ecuador',
    iso2: 'EC',
    iso3: 'ECU',
    region: Enum_Profile_Region_Enum.SouthAmerica,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.EG',
    displayName: 'Egypt',
    searchName: 'egypt',
    iso2: 'EG',
    iso3: 'EGY',
    region: Enum_Profile_Region_Enum.Africa,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.SV',
    displayName: 'El Salvador',
    searchName: 'elsalvador',
    iso2: 'SV',
    iso3: 'SLV',
    region: Enum_Profile_Region_Enum.NorthAmerica,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.GQ',
    displayName: 'Equatorial Guinea',
    searchName: 'equatorialguinea',
    iso2: 'GQ',
    iso3: 'GNQ',
    region: Enum_Profile_Region_Enum.Africa,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.ER',
    displayName: 'Eritrea',
    searchName: 'eritrea',
    iso2: 'ER',
    iso3: 'ERI',
    region: Enum_Profile_Region_Enum.Africa,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.EE',
    displayName: 'Estonia',
    searchName: 'estonia',
    iso2: 'EE',
    iso3: 'EST',
    region: Enum_Profile_Region_Enum.Europe,
    currency: CurrencyTypeEnum['€'],
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.ET',
    displayName: 'Ethiopia',
    searchName: 'ethiopia',
    iso2: 'ET',
    iso3: 'ETH',
    region: Enum_Profile_Region_Enum.Africa,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.FK',
    displayName: 'Falkland Islands (Islas Malvinas)',
    searchName: 'falklandislands(islasmalvinas)',
    iso2: 'FK',
    iso3: 'FLK',
    region: Enum_Profile_Region_Enum.SouthAmerica,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.FO',
    displayName: 'Faroe Islands',
    searchName: 'faroeislands',
    iso2: 'FO',
    iso3: 'FRO',
    region: Enum_Profile_Region_Enum.Europe,
    currency: CurrencyTypeEnum['€'],
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.FJ',
    displayName: 'Fiji',
    searchName: 'fiji',
    iso2: 'FJ',
    iso3: 'FJI',
    region: Enum_Profile_Region_Enum.Oceania,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.FI',
    displayName: 'Finland',
    searchName: 'finland',
    iso2: 'FI',
    iso3: 'FIN',
    region: Enum_Profile_Region_Enum.Europe,
    currency: CurrencyTypeEnum['€'],
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.FR',
    displayName: 'France',
    searchName: 'france',
    iso2: 'FR',
    iso3: 'FRA',
    region: Enum_Profile_Region_Enum.Europe,
    currency: CurrencyTypeEnum['€'],
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.GF',
    displayName: 'French Guiana',
    searchName: 'frenchguiana',
    iso2: 'GF',
    iso3: 'GUF',
    region: Enum_Profile_Region_Enum.SouthAmerica,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.PF',
    displayName: 'French Polynesia',
    searchName: 'frenchpolynesia',
    iso2: 'PF',
    iso3: 'PYF',
    region: Enum_Profile_Region_Enum.Oceania,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.GA',
    displayName: 'Gabon',
    searchName: 'gabon',
    iso2: 'GA',
    iso3: 'GAB',
    region: Enum_Profile_Region_Enum.Africa,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.GM',
    displayName: 'The Gambia',
    searchName: 'thegambia',
    iso2: 'GM',
    iso3: 'GMB',
    region: Enum_Profile_Region_Enum.Africa,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.GE',
    displayName: 'Georgia',
    searchName: 'georgia',
    iso2: 'GE',
    iso3: 'GEO',
    region: Enum_Profile_Region_Enum.Asia,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.DE',
    displayName: 'Germany',
    searchName: 'germany',
    iso2: 'DE',
    iso3: 'DEU',
    region: Enum_Profile_Region_Enum.Europe,
    currency: CurrencyTypeEnum['€'],
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.GH',
    displayName: 'Ghana',
    searchName: 'ghana',
    iso2: 'GH',
    iso3: 'GHA',
    region: Enum_Profile_Region_Enum.Africa,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.GI',
    displayName: 'Gibraltar',
    searchName: 'gibraltar',
    iso2: 'GI',
    iso3: 'GIB',
    region: Enum_Profile_Region_Enum.Europe,
    currency: CurrencyTypeEnum['€'],
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.GR',
    displayName: 'Greece',
    searchName: 'greece',
    iso2: 'GR',
    iso3: 'GRC',
    region: Enum_Profile_Region_Enum.Europe,
    currency: CurrencyTypeEnum['€'],
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.GL',
    displayName: 'Greenland',
    searchName: 'greenland',
    iso2: 'GL',
    iso3: 'GRL',
    region: Enum_Profile_Region_Enum.NorthAmerica,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.GD',
    displayName: 'Grenada',
    searchName: 'grenada',
    iso2: 'GD',
    iso3: 'GRD',
    region: Enum_Profile_Region_Enum.NorthAmerica,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.GP',
    displayName: 'Guadeloupe',
    searchName: 'guadeloupe',
    iso2: 'GP',
    iso3: 'GLP',
    region: Enum_Profile_Region_Enum.NorthAmerica,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.GU',
    displayName: 'Guam',
    searchName: 'guam',
    iso2: 'GU',
    iso3: 'GUM',
    region: Enum_Profile_Region_Enum.Oceania,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.GT',
    displayName: 'Guatemala',
    searchName: 'guatemala',
    iso2: 'GT',
    iso3: 'GTM',
    region: Enum_Profile_Region_Enum.NorthAmerica,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.GN',
    displayName: 'Guinea',
    searchName: 'guinea',
    iso2: 'GN',
    iso3: 'GIN',
    region: Enum_Profile_Region_Enum.Africa,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.GW',
    displayName: 'Guinea-Bissau',
    searchName: 'guinea-bissau',
    iso2: 'GW',
    iso3: 'GNB',
    region: Enum_Profile_Region_Enum.Africa,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.GY',
    displayName: 'Guyana',
    searchName: 'guyana',
    iso2: 'GY',
    iso3: 'GUY',
    region: Enum_Profile_Region_Enum.SouthAmerica,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.HT',
    displayName: 'Haiti',
    searchName: 'haiti',
    iso2: 'HT',
    iso3: 'HTI',
    region: Enum_Profile_Region_Enum.NorthAmerica,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.VA',
    displayName: 'Holy See (Vatican City)',
    searchName: 'holysee(vaticancity)',
    iso2: 'VA',
    iso3: 'VAT',
    region: Enum_Profile_Region_Enum.Europe,
    currency: CurrencyTypeEnum['€'],
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.HN',
    displayName: 'Honduras',
    searchName: 'honduras',
    iso2: 'HN',
    iso3: 'HND',
    region: Enum_Profile_Region_Enum.NorthAmerica,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.HU',
    displayName: 'Hungary',
    searchName: 'hungary',
    iso2: 'HU',
    iso3: 'HUN',
    region: Enum_Profile_Region_Enum.Europe,
    currency: CurrencyTypeEnum['€'],
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.IS',
    displayName: 'Iceland',
    searchName: 'iceland',
    iso2: 'IS',
    iso3: 'ISL',
    region: Enum_Profile_Region_Enum.Europe,
    currency: CurrencyTypeEnum['€'],
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.IN',
    displayName: 'India',
    searchName: 'india',
    iso2: 'IN',
    iso3: 'IND',
    region: Enum_Profile_Region_Enum.Asia,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.ID',
    displayName: 'Indonesia',
    searchName: 'indonesia',
    iso2: 'ID',
    iso3: 'IDN',
    region: Enum_Profile_Region_Enum.Asia,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.IR',
    displayName: 'Iran',
    searchName: 'iran',
    iso2: 'IR',
    iso3: 'IRN',
    region: Enum_Profile_Region_Enum.Asia,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.IQ',
    displayName: 'Iraq',
    searchName: 'iraq',
    iso2: 'IQ',
    iso3: 'IRQ',
    region: Enum_Profile_Region_Enum.Asia,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.IE',
    displayName: 'Ireland',
    searchName: 'ireland',
    iso2: 'IE',
    iso3: 'IRL',
    region: Enum_Profile_Region_Enum.Europe,
    currency: CurrencyTypeEnum['€'],
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.IL',
    displayName: 'Israel',
    searchName: 'israel',
    iso2: 'IL',
    iso3: 'ISR',
    region: Enum_Profile_Region_Enum.Asia,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.IT',
    displayName: 'Italy',
    searchName: 'italy',
    iso2: 'IT',
    iso3: 'ITA',
    region: Enum_Profile_Region_Enum.Europe,
    currency: CurrencyTypeEnum['€'],
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.JM',
    displayName: 'Jamaica',
    searchName: 'jamaica',
    iso2: 'JM',
    iso3: 'JAM',
    region: Enum_Profile_Region_Enum.NorthAmerica,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.JP',
    displayName: 'Japan',
    searchName: 'japan',
    iso2: 'JP',
    iso3: 'JPN',
    region: Enum_Profile_Region_Enum.Asia,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.JO',
    displayName: 'Jordan',
    searchName: 'jordan',
    iso2: 'JO',
    iso3: 'JOR',
    region: Enum_Profile_Region_Enum.Asia,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.KZ',
    displayName: 'Kazakhstan',
    searchName: 'kazakhstan',
    iso2: 'KZ',
    iso3: 'KAZ',
    region: Enum_Profile_Region_Enum.Asia,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.KE',
    displayName: 'Kenya',
    searchName: 'kenya',
    iso2: 'KE',
    iso3: 'KEN',
    region: Enum_Profile_Region_Enum.Africa,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.KI',
    displayName: 'Kiribati',
    searchName: 'kiribati',
    iso2: 'KI',
    iso3: 'KIR',
    region: Enum_Profile_Region_Enum.Oceania,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.KP',
    displayName: 'North Korea',
    searchName: 'northkorea',
    iso2: 'KP',
    iso3: 'PRK',
    region: Enum_Profile_Region_Enum.Asia,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.KR',
    displayName: 'South Korea',
    searchName: 'southkorea',
    iso2: 'KR',
    iso3: 'KOR',
    region: Enum_Profile_Region_Enum.Asia,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.KW',
    displayName: 'Kuwait',
    searchName: 'kuwait',
    iso2: 'KW',
    iso3: 'KWT',
    region: Enum_Profile_Region_Enum.Asia,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.KG',
    displayName: 'Kyrgyzstan',
    searchName: 'kyrgyzstan',
    iso2: 'KG',
    iso3: 'KGZ',
    region: Enum_Profile_Region_Enum.Asia,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.LA',
    displayName: 'Laos',
    searchName: 'laos',
    iso2: 'LA',
    iso3: 'LAO',
    region: Enum_Profile_Region_Enum.Asia,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.LV',
    displayName: 'Latvia',
    searchName: 'latvia',
    iso2: 'LV',
    iso3: 'LVA',
    region: Enum_Profile_Region_Enum.Europe,
    currency: CurrencyTypeEnum['€'],
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.LB',
    displayName: 'Lebanon',
    searchName: 'lebanon',
    iso2: 'LB',
    iso3: 'LBN',
    region: Enum_Profile_Region_Enum.Asia,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.LS',
    displayName: 'Lesotho',
    searchName: 'lesotho',
    iso2: 'LS',
    iso3: 'LSO',
    region: Enum_Profile_Region_Enum.Africa,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.LR',
    displayName: 'Liberia',
    searchName: 'liberia',
    iso2: 'LR',
    iso3: 'LBR',
    region: Enum_Profile_Region_Enum.Africa,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.LY',
    displayName: 'Libya',
    searchName: 'libya',
    iso2: 'LY',
    iso3: 'LBY',
    region: Enum_Profile_Region_Enum.Africa,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.LI',
    displayName: 'Liechtenstein',
    searchName: 'liechtenstein',
    iso2: 'LI',
    iso3: 'LIE',
    region: Enum_Profile_Region_Enum.Europe,
    currency: CurrencyTypeEnum['€'],
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.LT',
    displayName: 'Lithuania',
    searchName: 'lithuania',
    iso2: 'LT',
    iso3: 'LTU',
    region: Enum_Profile_Region_Enum.Europe,
    currency: CurrencyTypeEnum['€'],
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.LU',
    displayName: 'Luxembourg',
    searchName: 'luxembourg',
    iso2: 'LU',
    iso3: 'LUX',
    region: Enum_Profile_Region_Enum.Europe,
    currency: CurrencyTypeEnum['€'],
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.MK',
    displayName: 'North Macedonia',
    searchName: 'northmacedonia',
    iso2: 'MK',
    iso3: 'MKD',
    region: Enum_Profile_Region_Enum.Europe,
    currency: CurrencyTypeEnum['€'],
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.MG',
    displayName: 'Madagascar',
    searchName: 'madagascar',
    iso2: 'MG',
    iso3: 'MDG',
    region: Enum_Profile_Region_Enum.Africa,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.MW',
    displayName: 'Malawi',
    searchName: 'malawi',
    iso2: 'MW',
    iso3: 'MWI',
    region: Enum_Profile_Region_Enum.Africa,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.MY',
    displayName: 'Malaysia',
    searchName: 'malaysia',
    iso2: 'MY',
    iso3: 'MYS',
    region: Enum_Profile_Region_Enum.Asia,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.MV',
    displayName: 'Maldives',
    searchName: 'maldives',
    iso2: 'MV',
    iso3: 'MDV',
    region: Enum_Profile_Region_Enum.Asia,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.ML',
    displayName: 'Mali',
    searchName: 'mali',
    iso2: 'ML',
    iso3: 'MLI',
    region: Enum_Profile_Region_Enum.Africa,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.MT',
    displayName: 'Malta',
    searchName: 'malta',
    iso2: 'MT',
    iso3: 'MLT',
    region: Enum_Profile_Region_Enum.Europe,
    currency: CurrencyTypeEnum['€'],
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.IM',
    displayName: 'Isle of Man',
    searchName: 'isleofman',
    iso2: 'IM',
    iso3: 'IMN',
    region: Enum_Profile_Region_Enum.Europe,
    currency: CurrencyTypeEnum['€'],
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.MH',
    displayName: 'Marshall Islands',
    searchName: 'marshallislands',
    iso2: 'MH',
    iso3: 'MHL',
    region: Enum_Profile_Region_Enum.Oceania,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.MQ',
    displayName: 'Martinique',
    searchName: 'martinique',
    iso2: 'MQ',
    iso3: 'MTQ',
    region: Enum_Profile_Region_Enum.NorthAmerica,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.MR',
    displayName: 'Mauritania',
    searchName: 'mauritania',
    iso2: 'MR',
    iso3: 'MRT',
    region: Enum_Profile_Region_Enum.Africa,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.MU',
    displayName: 'Mauritius',
    searchName: 'mauritius',
    iso2: 'MU',
    iso3: 'MUS',
    region: Enum_Profile_Region_Enum.Africa,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.YT',
    displayName: 'Mayotte',
    searchName: 'mayotte',
    iso2: 'YT',
    iso3: 'MYT',
    region: Enum_Profile_Region_Enum.Africa,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.MX',
    displayName: 'Mexico',
    searchName: 'mexico',
    iso2: 'MX',
    iso3: 'MEX',
    region: Enum_Profile_Region_Enum.NorthAmerica,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.FM',
    displayName: 'Federated States of Micronesia',
    searchName: 'federatedstatesofmicronesia',
    iso2: 'FM',
    iso3: 'FSM',
    region: Enum_Profile_Region_Enum.Oceania,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.MD',
    displayName: 'Moldova',
    searchName: 'moldova',
    iso2: 'MD',
    iso3: 'MDA',
    region: Enum_Profile_Region_Enum.Europe,
    currency: CurrencyTypeEnum['€'],
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.MC',
    displayName: 'Monaco',
    searchName: 'monaco',
    iso2: 'MC',
    iso3: 'MCO',
    region: Enum_Profile_Region_Enum.Europe,
    currency: CurrencyTypeEnum['€'],
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.MN',
    displayName: 'Mongolia',
    searchName: 'mongolia',
    iso2: 'MN',
    iso3: 'MNG',
    region: Enum_Profile_Region_Enum.Asia,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.MS',
    displayName: 'Montserrat',
    searchName: 'montserrat',
    iso2: 'MS',
    iso3: 'MSR',
    region: Enum_Profile_Region_Enum.NorthAmerica,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.MA',
    displayName: 'Morocco',
    searchName: 'morocco',
    iso2: 'MA',
    iso3: 'MAR',
    region: Enum_Profile_Region_Enum.Africa,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.MZ',
    displayName: 'Mozambique',
    searchName: 'mozambique',
    iso2: 'MZ',
    iso3: 'MOZ',
    region: Enum_Profile_Region_Enum.Africa,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.MM',
    displayName: 'Myanmar (Burma)',
    searchName: 'myanmar(burma)',
    iso2: 'MM',
    iso3: 'MMR',
    region: Enum_Profile_Region_Enum.Asia,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.NA',
    displayName: 'Namibia',
    searchName: 'namibia',
    iso2: 'NA',
    iso3: 'NAM',
    region: Enum_Profile_Region_Enum.Africa,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.NR',
    displayName: 'Nauru',
    searchName: 'nauru',
    iso2: 'NR',
    iso3: 'NRU',
    region: Enum_Profile_Region_Enum.Oceania,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.NP',
    displayName: 'Nepal',
    searchName: 'nepal',
    iso2: 'NP',
    iso3: 'NPL',
    region: Enum_Profile_Region_Enum.Asia,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.NL',
    displayName: 'Netherlands',
    searchName: 'netherlands',
    iso2: 'NL',
    iso3: 'NLD',
    region: Enum_Profile_Region_Enum.Europe,
    currency: CurrencyTypeEnum['€'],
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.AN',
    displayName: 'Netherlands Antilles',
    searchName: 'netherlandsantilles',
    iso2: 'AN',
    iso3: 'ANT',
    region: Enum_Profile_Region_Enum.NorthAmerica,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.NC',
    displayName: 'New Caledonia',
    searchName: 'newcaledonia',
    iso2: 'NC',
    iso3: 'NCL',
    region: Enum_Profile_Region_Enum.Oceania,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.NZ',
    displayName: 'New Zealand',
    searchName: 'newzealand',
    iso2: 'NZ',
    iso3: 'NZL',
    region: Enum_Profile_Region_Enum.Oceania,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.NI',
    displayName: 'Nicaragua',
    searchName: 'nicaragua',
    iso2: 'NI',
    iso3: 'NIC',
    region: Enum_Profile_Region_Enum.NorthAmerica,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.NE',
    displayName: 'Niger',
    searchName: 'niger',
    iso2: 'NE',
    iso3: 'NER',
    region: Enum_Profile_Region_Enum.Africa,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.NG',
    displayName: 'Nigeria',
    searchName: 'nigeria',
    iso2: 'NG',
    iso3: 'NGA',
    region: Enum_Profile_Region_Enum.Africa,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.NU',
    displayName: 'Niue',
    searchName: 'niue',
    iso2: 'NU',
    iso3: 'NIU',
    region: Enum_Profile_Region_Enum.Oceania,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.NF',
    displayName: 'Norfolk Island',
    searchName: 'norfolkisland',
    iso2: 'NF',
    iso3: 'NFK',
    region: Enum_Profile_Region_Enum.Oceania,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.MP',
    displayName: 'Northern Mariana Islands',
    searchName: 'northernmarianaislands',
    iso2: 'MP',
    iso3: 'MNP',
    region: Enum_Profile_Region_Enum.Oceania,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.NO',
    displayName: 'Norway',
    searchName: 'norway',
    iso2: 'NO',
    iso3: 'NOR',
    region: Enum_Profile_Region_Enum.Europe,
    currency: CurrencyTypeEnum['€'],
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.OM',
    displayName: 'Oman',
    searchName: 'oman',
    iso2: 'OM',
    iso3: 'OMN',
    region: Enum_Profile_Region_Enum.Asia,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.PK',
    displayName: 'Pakistan',
    searchName: 'pakistan',
    iso2: 'PK',
    iso3: 'PAK',
    region: Enum_Profile_Region_Enum.Asia,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.PW',
    displayName: 'Palau',
    searchName: 'palau',
    iso2: 'PW',
    iso3: 'PLW',
    region: Enum_Profile_Region_Enum.Oceania,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.PS',
    displayName: 'Palestinian Territory',
    searchName: 'palestinianterritory',
    iso2: 'PS',
    iso3: 'PSE',
    region: Enum_Profile_Region_Enum.Asia,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.PA',
    displayName: 'Panama',
    searchName: 'panama',
    iso2: 'PA',
    iso3: 'PAN',
    region: Enum_Profile_Region_Enum.NorthAmerica,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.PG',
    displayName: 'Papua New Guinea',
    searchName: 'papuanewguinea',
    iso2: 'PG',
    iso3: 'PNG',
    region: Enum_Profile_Region_Enum.Oceania,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.PY',
    displayName: 'Paraguay',
    searchName: 'paraguay',
    iso2: 'PY',
    iso3: 'PRY',
    region: Enum_Profile_Region_Enum.SouthAmerica,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.PE',
    displayName: 'Peru',
    searchName: 'peru',
    iso2: 'PE',
    iso3: 'PER',
    region: Enum_Profile_Region_Enum.SouthAmerica,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.PH',
    displayName: 'Philippines',
    searchName: 'philippines',
    iso2: 'PH',
    iso3: 'PHL',
    region: Enum_Profile_Region_Enum.Asia,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.PN',
    displayName: 'Pitcairn Islands',
    searchName: 'pitcairnislands',
    iso2: 'PN',
    iso3: 'PCN',
    region: Enum_Profile_Region_Enum.Oceania,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.PL',
    displayName: 'Poland',
    searchName: 'poland',
    iso2: 'PL',
    iso3: 'POL',
    region: Enum_Profile_Region_Enum.Europe,
    currency: CurrencyTypeEnum['€'],
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.PT',
    displayName: 'Portugal',
    searchName: 'portugal',
    iso2: 'PT',
    iso3: 'PRT',
    region: Enum_Profile_Region_Enum.Europe,
    currency: CurrencyTypeEnum['€'],
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.PR',
    displayName: 'Puerto Rico',
    searchName: 'puertorico',
    iso2: 'PR',
    iso3: 'PRI',
    region: Enum_Profile_Region_Enum.NorthAmerica,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.QA',
    displayName: 'Qatar',
    searchName: 'qatar',
    iso2: 'QA',
    iso3: 'QAT',
    region: Enum_Profile_Region_Enum.Asia,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.RE',
    displayName: 'Reunion',
    searchName: 'reunion',
    iso2: 'RE',
    iso3: 'REU',
    region: Enum_Profile_Region_Enum.Africa,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.RO',
    displayName: 'Romania',
    searchName: 'romania',
    iso2: 'RO',
    iso3: 'ROU',
    prio: 3,
    region: Enum_Profile_Region_Enum.Europe,
    currency: CurrencyTypeEnum['€'],
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.RU',
    displayName: 'Russia',
    searchName: 'russia',
    iso2: 'RU',
    iso3: 'RUS',
    region: Enum_Profile_Region_Enum.Europe,
    currency: CurrencyTypeEnum['€'],
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.RW',
    displayName: 'Rwanda',
    searchName: 'rwanda',
    iso2: 'RW',
    iso3: 'RWA',
    region: Enum_Profile_Region_Enum.Africa,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.KN',
    displayName: 'Saint Kitts and Nevis',
    searchName: 'saintkittsandnevis',
    iso2: 'KN',
    iso3: 'KNA',
    region: Enum_Profile_Region_Enum.NorthAmerica,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.LC',
    displayName: 'Saint Lucia',
    searchName: 'saintlucia',
    iso2: 'LC',
    iso3: 'LCA',
    region: Enum_Profile_Region_Enum.NorthAmerica,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.PM',
    displayName: 'Saint Pierre and Miquelon',
    searchName: 'saintpierreandmiquelon',
    iso2: 'PM',
    iso3: 'SPM',
    region: Enum_Profile_Region_Enum.NorthAmerica,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.VC',
    displayName: 'Saint Vincent and the Grenadines',
    searchName: 'saintvincentandthegrenadines',
    iso2: 'VC',
    iso3: 'VCT',
    region: Enum_Profile_Region_Enum.NorthAmerica,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.SM',
    displayName: 'San Marino',
    searchName: 'sanmarino',
    iso2: 'SM',
    iso3: 'SMR',
    region: Enum_Profile_Region_Enum.Europe,
    currency: CurrencyTypeEnum['€'],
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.ST',
    displayName: 'Sao Tome and Principe',
    searchName: 'saotomeandprincipe',
    iso2: 'ST',
    iso3: 'STP',
    region: Enum_Profile_Region_Enum.Africa,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.SA',
    displayName: 'Saudi Arabia',
    searchName: 'saudiarabia',
    iso2: 'SA',
    iso3: 'SAU',
    region: Enum_Profile_Region_Enum.Asia,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.SN',
    displayName: 'Senegal',
    searchName: 'senegal',
    iso2: 'SN',
    iso3: 'SEN',
    region: Enum_Profile_Region_Enum.Africa,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.SC',
    displayName: 'Seychelles',
    searchName: 'seychelles',
    iso2: 'SC',
    iso3: 'SYC',
    region: Enum_Profile_Region_Enum.Africa,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.SL',
    displayName: 'Sierra Leone',
    searchName: 'sierraleone',
    iso2: 'SL',
    iso3: 'SLE',
    region: Enum_Profile_Region_Enum.Africa,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.SG',
    displayName: 'Singapore',
    searchName: 'singapore',
    iso2: 'SG',
    iso3: 'SGP',
    region: Enum_Profile_Region_Enum.Asia,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.SK',
    displayName: 'Slovakia',
    searchName: 'slovakia',
    iso2: 'SK',
    iso3: 'SVK',
    region: Enum_Profile_Region_Enum.Europe,
    currency: CurrencyTypeEnum['€'],
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.SI',
    displayName: 'Slovenia',
    searchName: 'slovenia',
    iso2: 'SI',
    iso3: 'SVN',
    region: Enum_Profile_Region_Enum.Europe,
    currency: CurrencyTypeEnum['€'],
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.SB',
    displayName: 'Solomon Islands',
    searchName: 'solomonislands',
    iso2: 'SB',
    iso3: 'SLB',
    region: Enum_Profile_Region_Enum.Oceania,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.SO',
    displayName: 'Somalia',
    searchName: 'somalia',
    iso2: 'SO',
    iso3: 'SOM',
    region: Enum_Profile_Region_Enum.Africa,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.ZA',
    displayName: 'South Africa',
    searchName: 'southafrica',
    iso2: 'ZA',
    iso3: 'ZAF',
    region: Enum_Profile_Region_Enum.Africa,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.ES',
    displayName: 'Spain',
    searchName: 'spain',
    iso2: 'ES',
    iso3: 'ESP',
    region: Enum_Profile_Region_Enum.Europe,
    currency: CurrencyTypeEnum['€'],
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.LK',
    displayName: 'Sri Lanka',
    searchName: 'srilanka',
    iso2: 'LK',
    iso3: 'LKA',
    region: Enum_Profile_Region_Enum.Asia,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.SD',
    displayName: 'Sudan',
    searchName: 'sudan',
    iso2: 'SD',
    iso3: 'SDN',
    region: Enum_Profile_Region_Enum.Africa,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.SR',
    displayName: 'Suriname',
    searchName: 'suriname',
    iso2: 'SR',
    iso3: 'SUR',
    region: Enum_Profile_Region_Enum.SouthAmerica,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.SJ',
    displayName: 'Svalbard',
    searchName: 'svalbard',
    iso2: 'SJ',
    iso3: 'SJM',
    region: Enum_Profile_Region_Enum.Europe,
    currency: CurrencyTypeEnum['€'],
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.SZ',
    displayName: 'Eswatini',
    searchName: 'eswatini',
    iso2: 'SZ',
    iso3: 'SWZ',
    region: Enum_Profile_Region_Enum.Africa,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.SE',
    displayName: 'Sweden',
    searchName: 'sweden',
    iso2: 'SE',
    iso3: 'SWE',
    region: Enum_Profile_Region_Enum.Europe,
    currency: CurrencyTypeEnum['€'],
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.CH',
    displayName: 'Switzerland',
    searchName: 'switzerland',
    iso2: 'CH',
    iso3: 'CHE',
    region: Enum_Profile_Region_Enum.Europe,
    currency: CurrencyTypeEnum['€'],
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.SY',
    displayName: 'Syria',
    searchName: 'syria',
    iso2: 'SY',
    iso3: 'SYR',
    region: Enum_Profile_Region_Enum.Asia,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.TW',
    displayName: 'Taiwan',
    searchName: 'taiwan',
    iso2: 'TW',
    iso3: 'TWN',
    region: Enum_Profile_Region_Enum.Asia,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.TJ',
    displayName: 'Tajikistan',
    searchName: 'tajikistan',
    iso2: 'TJ',
    iso3: 'TJK',
    region: Enum_Profile_Region_Enum.Asia,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.TZ',
    displayName: 'Tanzania',
    searchName: 'tanzania',
    iso2: 'TZ',
    iso3: 'TZA',
    region: Enum_Profile_Region_Enum.Africa,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.TH',
    displayName: 'Thailand',
    searchName: 'thailand',
    iso2: 'TH',
    iso3: 'THA',
    region: Enum_Profile_Region_Enum.Asia,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.TG',
    displayName: 'Togo',
    searchName: 'togo',
    iso2: 'TG',
    iso3: 'TGO',
    region: Enum_Profile_Region_Enum.Africa,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.TK',
    displayName: 'Tokelau',
    searchName: 'tokelau',
    iso2: 'TK',
    iso3: 'TKL',
    region: Enum_Profile_Region_Enum.Oceania,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.TO',
    displayName: 'Tonga',
    searchName: 'tonga',
    iso2: 'TO',
    iso3: 'TON',
    region: Enum_Profile_Region_Enum.Oceania,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.TT',
    displayName: 'Trinidad and Tobago',
    searchName: 'trinidadandtobago',
    iso2: 'TT',
    iso3: 'TTO',
    region: Enum_Profile_Region_Enum.NorthAmerica,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.TN',
    displayName: 'Tunisia',
    searchName: 'tunisia',
    iso2: 'TN',
    iso3: 'TUN',
    region: Enum_Profile_Region_Enum.Africa,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.TR',
    displayName: 'Turkey',
    searchName: 'turkey',
    iso2: 'TR',
    iso3: 'TUR',
    region: Enum_Profile_Region_Enum.Asia,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.TM',
    displayName: 'Turkmenistan',
    searchName: 'turkmenistan',
    iso2: 'TM',
    iso3: 'TKM',
    region: Enum_Profile_Region_Enum.Asia,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.TC',
    displayName: 'Turks and Caicos Islands',
    searchName: 'turksandcaicosislands',
    iso2: 'TC',
    iso3: 'TCA',
    region: Enum_Profile_Region_Enum.NorthAmerica,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.TV',
    displayName: 'Tuvalu',
    searchName: 'tuvalu',
    iso2: 'TV',
    iso3: 'TUV',
    region: Enum_Profile_Region_Enum.Oceania,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.UG',
    displayName: 'Uganda',
    searchName: 'uganda',
    iso2: 'UG',
    iso3: 'UGA',
    region: Enum_Profile_Region_Enum.Africa,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.UA',
    displayName: 'Ukraine',
    searchName: 'ukraine',
    iso2: 'UA',
    iso3: 'UKR',
    region: Enum_Profile_Region_Enum.Europe,
    currency: CurrencyTypeEnum['€'],
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.AE',
    displayName: 'United Arab Emirates',
    searchName: 'unitedarabemirates',
    iso2: 'AE',
    iso3: 'ARE',
    prio: 3,
    region: Enum_Profile_Region_Enum.Asia,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.GB',
    displayName: 'United Kingdom',
    searchName: 'unitedkingdom',
    iso2: 'GB',
    iso3: 'GBR',
    prio: 4,
    region: Enum_Profile_Region_Enum.Europe,
    currency: CurrencyTypeEnum['£'],
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.US',
    displayName: 'United States',
    searchName: 'unitedstatesofamerica',
    iso2: 'US',
    iso3: 'USA',
    prio: 5,
    region: Enum_Profile_Region_Enum.NorthAmerica,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.UM',
    displayName: 'United States Minor Outlying Islands',
    searchName: 'unitedstatesminoroutlyingislands',
    iso2: 'UM',
    iso3: 'UMI',
    prio: 2.5,
    region: Enum_Profile_Region_Enum.NorthAmerica,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.UY',
    displayName: 'Uruguay',
    searchName: 'uruguay',
    iso2: 'UY',
    iso3: 'URY',
    region: Enum_Profile_Region_Enum.SouthAmerica,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.UZ',
    displayName: 'Uzbekistan',
    searchName: 'uzbekistan',
    iso2: 'UZ',
    iso3: 'UZB',
    region: Enum_Profile_Region_Enum.Asia,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.VU',
    displayName: 'Vanuatu',
    searchName: 'vanuatu',
    iso2: 'VU',
    iso3: 'VUT',
    region: Enum_Profile_Region_Enum.Oceania,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.VE',
    displayName: 'Venezuela',
    searchName: 'venezuela',
    iso2: 'VE',
    iso3: 'VEN',
    region: Enum_Profile_Region_Enum.SouthAmerica,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.VN',
    displayName: 'Vietnam',
    searchName: 'vietnam',
    iso2: 'VN',
    iso3: 'VNM',
    region: Enum_Profile_Region_Enum.Asia,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.VI',
    displayName: 'Virgin Islands',
    searchName: 'virginislands',
    iso2: 'VI',
    iso3: 'VIR',
    region: Enum_Profile_Region_Enum.NorthAmerica,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.WF',
    displayName: 'Wallis and Futuna',
    searchName: 'wallisandfutuna',
    iso2: 'WF',
    iso3: 'WLF',
    region: Enum_Profile_Region_Enum.Oceania,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.EH',
    displayName: 'Western Sahara',
    searchName: 'westernsahara',
    iso2: 'EH',
    iso3: 'ESH',
    region: Enum_Profile_Region_Enum.Africa,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.WS',
    displayName: 'Western Samoa',
    searchName: 'westernsamoa',
    iso2: 'WS',
    iso3: 'WSM',
    region: Enum_Profile_Region_Enum.Oceania,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.YE',
    displayName: 'Yemen',
    searchName: 'yemen',
    iso2: 'YE',
    iso3: 'YEM',
    region: Enum_Profile_Region_Enum.Asia,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.CD',
    displayName: 'Democratic Republic of the Congo',
    searchName: 'democraticrepublicofthecongo',
    iso2: 'CD',
    iso3: 'COD',
    region: Enum_Profile_Region_Enum.Africa,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.ZM',
    displayName: 'Zambia',
    searchName: 'zambia',
    iso2: 'ZM',
    iso3: 'ZMB',
    region: Enum_Profile_Region_Enum.Africa,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.ZW',
    displayName: 'Zimbabwe',
    searchName: 'zimbabwe',
    iso2: 'ZW',
    iso3: 'ZWE',
    region: Enum_Profile_Region_Enum.Africa,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.HK',
    displayName: 'Hong Kong',
    searchName: 'hongkong',
    iso2: 'HK',
    iso3: 'HKG',
    region: Enum_Profile_Region_Enum.Asia,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.MO',
    displayName: 'Macau',
    searchName: 'macau',
    iso2: 'MO',
    iso3: 'MAC',
    region: Enum_Profile_Region_Enum.Asia,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.AQ',
    displayName: 'Antarctica',
    searchName: 'antarctica',
    iso2: 'AQ',
    iso3: 'ATA',
    region: Enum_Profile_Region_Enum.Oceania,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.BV',
    displayName: 'Bouvet Island',
    searchName: 'bouvetisland',
    iso2: 'BV',
    iso3: 'BVT',
    region: Enum_Profile_Region_Enum.Oceania,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.IO',
    displayName: 'British Indian Ocean Territory',
    searchName: 'britishindianoceanterritory',
    iso2: 'IO',
    iso3: 'IOT',
    region: Enum_Profile_Region_Enum.Asia,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.TF',
    displayName: 'French Southern and Antarctic Lands',
    searchName: 'frenchsouthernandantarcticlands',
    iso2: 'TF',
    iso3: 'ATF',
    region: Enum_Profile_Region_Enum.Oceania,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.HM',
    displayName: 'Heard Island and McDonald Islands',
    searchName: 'heardislandandmcdonaldislands',
    iso2: 'HM',
    iso3: 'HMD',
    region: Enum_Profile_Region_Enum.Oceania,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.SH',
    displayName: 'Saint Helena',
    searchName: 'sainthelena',
    iso2: 'SH',
    iso3: 'SHN',
    region: Enum_Profile_Region_Enum.Africa,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.GS',
    displayName: 'South Georgia and the South Sandwich Islands',
    searchName: 'southgeorgiaandthesouthsandwichislands',
    iso2: 'GS',
    iso3: 'SGS',
    region: Enum_Profile_Region_Enum.SouthAmerica,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.GG',
    displayName: 'Guernsey',
    searchName: 'guernsey',
    iso2: 'GG',
    iso3: 'GGY',
    region: Enum_Profile_Region_Enum.Europe,
    currency: CurrencyTypeEnum['€'],
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.RS',
    displayName: 'Serbia',
    searchName: 'serbia',
    iso2: 'RS',
    iso3: 'SRB',
    region: Enum_Profile_Region_Enum.Europe,
    currency: CurrencyTypeEnum['€'],
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.BL',
    displayName: 'Saint Barthélemy',
    searchName: 'saintbarthelemy',
    iso2: 'BL',
    iso3: 'BLM',
    region: Enum_Profile_Region_Enum.NorthAmerica,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.ME',
    displayName: 'Montenegro',
    searchName: 'montenegro',
    iso2: 'ME',
    iso3: 'MNE',
    region: Enum_Profile_Region_Enum.Europe,
    currency: CurrencyTypeEnum['€'],
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.JE',
    displayName: 'Jersey',
    searchName: 'jersey',
    iso2: 'JE',
    iso3: 'JEY',
    region: Enum_Profile_Region_Enum.Europe,
    currency: CurrencyTypeEnum['€'],
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.CW',
    displayName: 'Curaçao',
    searchName: 'curacao',
    iso2: 'CW',
    iso3: 'CUW',
    region: Enum_Profile_Region_Enum.NorthAmerica,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.MF',
    displayName: 'Saint Martin',
    searchName: 'saintmartin',
    iso2: 'MF',
    iso3: 'MAF',
    region: Enum_Profile_Region_Enum.NorthAmerica,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.SX',
    displayName: 'Sint Maarten',
    searchName: 'sintmaarten',
    iso2: 'SX',
    iso3: 'SXM',
    region: Enum_Profile_Region_Enum.NorthAmerica,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.TL',
    displayName: 'Timor-Leste',
    searchName: 'timor-leste',
    iso2: 'TL',
    iso3: 'TLS',
    region: Enum_Profile_Region_Enum.Oceania,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.SS',
    displayName: 'South Sudan',
    searchName: 'southsudan',
    iso2: 'SS',
    iso3: 'SSD',
    region: Enum_Profile_Region_Enum.Africa,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.AX',
    displayName: 'Åland Islands',
    searchName: 'alandislands',
    iso2: 'AX',
    iso3: 'ALA',
    region: Enum_Profile_Region_Enum.Europe,
    currency: CurrencyTypeEnum['€'],
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.BQ',
    displayName: 'Bonaire',
    searchName: 'bonaire',
    iso2: 'BQ',
    iso3: 'BES',
    region: Enum_Profile_Region_Enum.NorthAmerica,
  },
  {
    translationKey: 'strings.geolocation.countries.isoCodes.XK',
    displayName: 'Republic of Kosovo',
    searchName: 'republicofkosovo',
    iso2: 'XK',
    iso3: 'UNK',
    region: Enum_Profile_Region_Enum.Europe,
    currency: CurrencyTypeEnum['€'],
  },
];

export const commonAdministrativeTypeKeysByState: Record<string, string> = {
  CL: 'Region',
  PE: 'Region',
  FR: 'Region',
  IT: 'Region',
  DO: 'Region',
  GR: 'Region',
  GH: 'Region',
  ET: 'Region',
  CM: 'Region',
  FI: 'Region',
  BE: 'Region',
  SN: 'Region',
  DK: 'Region',
  BF: 'Region',

  BR: 'State',
  MX: 'State',
  US: 'State',
  DE: 'State',
  AT: 'State',

  EC: 'Province',
  CR: 'Province',
  CA: 'Province',
  ZA: 'Province',
  NL: 'Province',
  AO: 'Province',
  MZ: 'Province',
  ZM: 'Province',
  RW: 'Province',
  IR: 'Province',
  TR: 'Province',
  ZW: 'Province',

  GT: 'Department',
  UY: 'Department',
  BO: 'Department',
  HN: 'Department',
  SV: 'Department',
  CD: 'Department',
  BJ: 'Department',

  CO: 'DepartmentOrCapitalDistrict',
  PY: 'DepartmentOrCapitalDistrict',

  UG: 'District',
  IL: 'District',

  KE: 'County',
  SE: 'County',
  IE: 'County',
  NO: 'County',
  AL: 'County',

  RO: 'CountyOrMunicipality',
  HU: 'CountyOrMunicipality',
};
