import { Enum_Profile_Region_Enum, Explore_Artists_Bool_Exp } from 'common/schema/commonSchemaRemoteTypes';
import {
  AppliedListingModifier,
  FILTERS_DEFAULT_VALUE,
  isOptionType,
  ListingModifier,
  PROFILE_QUERY_CONDITION_IN,
} from './types';

enum KnownOptions {
  REGION_ALL = 'filterStrings.avalability.all',
  REGION_NORTH_AMERICA = 'filterStrings.region.northAmerica',
  REGION_SOUTH_AMERICA = 'filterStrings.region.southAmerica',
  REGION_EUROPE = 'filterStrings.region.europe',
  REGION_ASIA = 'filterStrings.region.asia',
  REGION_AFRICA = 'filterStrings.region.africa',
  REGION_OCEANIA = 'filterStrings.region.oceania',
}

export const getRegionFilter = (): ListingModifier => ({
  type: 'REGION',
  allOptions: [
    {
      value: FILTERS_DEFAULT_VALUE,
      name: KnownOptions.REGION_ALL,
      key: 'PROFILE_QUERY_CONDITION_IN',
      payload: {},
    },
    {
      value: 1,
      name: KnownOptions.REGION_NORTH_AMERICA,
      key: 'PROFILE_QUERY_CONDITION_IN',
      payload: { region: { _in: [Enum_Profile_Region_Enum.NorthAmerica] } },
    },
    {
      value: 2,
      name: KnownOptions.REGION_SOUTH_AMERICA,
      key: 'PROFILE_QUERY_CONDITION_IN',
      payload: { region: { _in: [Enum_Profile_Region_Enum.SouthAmerica] } },
    },
    {
      value: 3,
      name: KnownOptions.REGION_EUROPE,
      key: 'PROFILE_QUERY_CONDITION_IN',
      payload: { region: { _in: [Enum_Profile_Region_Enum.Europe] } },
    },
    {
      value: 4,
      name: KnownOptions.REGION_ASIA,
      key: 'PROFILE_QUERY_CONDITION_IN',
      payload: { region: { _in: [Enum_Profile_Region_Enum.Asia] } },
    },
    {
      value: 5,
      name: KnownOptions.REGION_AFRICA,
      key: 'PROFILE_QUERY_CONDITION_IN',
      payload: { region: { _in: [Enum_Profile_Region_Enum.Africa] } },
    },
    {
      value: 6,
      name: KnownOptions.REGION_OCEANIA,
      key: 'PROFILE_QUERY_CONDITION_IN',
      payload: { region: { _in: [Enum_Profile_Region_Enum.Oceania] } },
    },
  ],
});

export const getAppliedRegionFilter = (): AppliedListingModifier<PROFILE_QUERY_CONDITION_IN> => ({
  ...getRegionFilter(),
  selectionType: 'MULTIPLE',
  selectedOptions: [FILTERS_DEFAULT_VALUE],
  flattenSelectedOptions: (options, selectedOptions) => {
    const selectedModifiers = options.filter((option) => selectedOptions.includes(option.value));
    const flattenedModifiers = selectedModifiers.reduce<Explore_Artists_Bool_Exp>((flattenedOptions, currentOption) => {
      if (
        isOptionType<PROFILE_QUERY_CONDITION_IN>(currentOption, 'PROFILE_QUERY_CONDITION_IN') &&
        currentOption.payload.region?._in
      ) {
        return {
          region: {
            _in: [...(flattenedOptions.region?._in ?? []), ...currentOption.payload.region._in],
          },
        };
      }
      return flattenedOptions;
    }, {});

    return {
      key: 'PROFILE_QUERY_CONDITION_IN',
      name: 'APPLIED_REGION_FILTER',
      value: -1,
      payload: flattenedModifiers,
    };
  },
});
