import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import { CDNUrl, RELEASE } from 'utils/constants';

const hasReleaseVersionKey = (): boolean => !(!RELEASE || RELEASE.length === 0);

const getReleaseCommitKey = (): string | null => {
  if (RELEASE?.length) {
    const releaseVersionParts = RELEASE.split('-');
    return releaseVersionParts[releaseVersionParts.length - 1].substring(0, 7);
  }
  return null;
};

const i18nOptions = {
  // lng: 'en',
  fallbackLng: 'en',
  lowerCaseLng: true,
  debug: false,
  supportedLngs: ['en', 'es', 'ro', 'pt', 'fr'],
  ns: ['translations'], // , 'common'
  defaultNS: 'translations',
  detection: {
    order: ['navigator', 'cookie'],
  },
  backend: {
    loadPath: `${CDNUrl}/locale/{{lng}}/{{ns}}.json${
      hasReleaseVersionKey() ? `?updated=${getReleaseCommitKey()}` : ''
    }`,
    crossDomain: true,
  },
  wait: process && !process.release,
};

if (process && !process.release) {
  i18n.use(HttpApi).use(LanguageDetector).use(initReactI18next);
}

if (!i18n.isInitialized) {
  i18n.init(i18nOptions, (err, t) => {
    if (!err && t) {
      return;
    }
    console.log(err, t);
  });
}

export default i18n;
