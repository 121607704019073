import { createSlice } from '@reduxjs/toolkit';
import { GetUserRecognitionsQuery } from 'common/schema/commonSchemaRemoteOperationTypes';

export type RecognizedArtistData = GetUserRecognitionsQuery['Social_graph_followers'][0];

export type AllRecognizedArtists = RecognizedArtistData[];

export interface RecognitionsPageState {
  currentPage: number;
  displayedRecognitions: AllRecognizedArtists;
}

const initialState: RecognitionsPageState = {
  currentPage: 1,
  displayedRecognitions: [],
};

const recognitionsPageSlice = createSlice({
  name: 'recognitionsPage',
  initialState,
  reducers: {
    SET_CURRENT_PAGE: (
      state,
      action: {
        payload: {
          currentPage: number;
        };
      }
    ) => ({
      ...state,
      currentPage: action.payload.currentPage,
    }),
    ADD_RECOGNITIONS_FOR_CURRENT_PAGE: (
      state,
      action: {
        payload: {
          data: AllRecognizedArtists;
        };
      }
    ) => ({
      ...state,
      displayedRecognitions: action.payload.data,
    }),
  },
});

export const recognitionsPageActions = recognitionsPageSlice.actions;
export default recognitionsPageSlice;
