export enum SizeEnum {
  ALL = 'all',
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

export type SizeFilterState = {
  size: Record<
    SizeEnum,
    boolean // is toggled
  >;
};
