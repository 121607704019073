export type ArtistsFilterState = {
  selectedArtists: Record<
    number,
    boolean // is toggled
  >;
};

export type ArtistFilterOptionType = {
  artistContextId: number;
  artistTitle: string;
};

export const DEFAULT_ARTIST_ID_VALUE = -1;
