import { LazyQueryResult, QueryLazyOptions } from '@apollo/client';
import {
  useGetArtworksInCurrentSeriesLazyQuery,
  useGetNumArtworksInCurrentSeriesQuery,
} from 'common/schema/commonSchemaRemoteGraphqlQueries';
import { GetArtworksInCurrentSeriesQuery } from 'common/schema/commonSchemaRemoteOperationTypes';
import { Exact, Maybe } from 'common/schema/commonSchemaRemoteTypes';
import { useEffect, useMemo, useState } from 'react';

export type FetchMoreArtworksInCurrentSeriesType = (
  options?:
    | QueryLazyOptions<
        Exact<{
          seriesTitle?: Maybe<string> | undefined;
          artistContextId: number;
          offset: number;
          limit: number;
          artworkId: number;
        }>
      >
    | undefined
) => Promise<
  LazyQueryResult<
    GetArtworksInCurrentSeriesQuery,
    Exact<{
      seriesTitle?: Maybe<string> | undefined;
      artistContextId: number;
      offset: number;
      limit: number;
      artworkId: number;
    }>
  >
>;

export type ArtworksInCurrentSeries = GetArtworksInCurrentSeriesQuery['Artwork'];

type GetArtworksInCurrentSeriesReturnType = {
  numArtworksInCurrentSeries: number;
  loading: boolean;
  artworksInCurrentSeries: ArtworksInCurrentSeries;
  fetchArtworksInCurrentSeries: FetchMoreArtworksInCurrentSeriesType;
};

const useGetArtworksInCurrentSeries = (
  artistContextId: number,
  artworkId: number,
  seriesTitle?: string
): GetArtworksInCurrentSeriesReturnType => {
  const [displayedArtworks, setDisplayedArtworks] = useState<ArtworksInCurrentSeries>([]);

  useEffect(() => {
    setDisplayedArtworks([]);
  }, [artistContextId]);

  const { data: numArtworksInCurrentSeriesData, loading: numArtworksInCurrentSeriesLoading } =
    useGetNumArtworksInCurrentSeriesQuery({
      variables: {
        artworkId,
        seriesTitle: seriesTitle ?? '',
        artistContextId: artistContextId ?? 0,
      },
      fetchPolicy: 'network-only',
      skip: !artistContextId,
    });

  const numArtworksInCurrentSeries = useMemo(() => {
    if (!numArtworksInCurrentSeriesData) {
      return 0;
    }

    return numArtworksInCurrentSeriesData?.Artwork?.length;
  }, [numArtworksInCurrentSeriesData]);

  const [fetchArtworks, { data: artworks, loading: artworksLoading }] = useGetArtworksInCurrentSeriesLazyQuery({
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    fetchArtworks({
      variables: {
        artworkId,
        seriesTitle: seriesTitle ?? '',
        artistContextId,
        offset: 0,
        limit: 8,
      },
    });
  }, [fetchArtworks, artistContextId, seriesTitle, artworkId]);

  useEffect(() => {
    if (!artworks?.Artwork) {
      return;
    }

    setDisplayedArtworks((prevArtworks) => prevArtworks.concat(artworks?.Artwork));
  }, [artworks?.Artwork]);

  return {
    numArtworksInCurrentSeries,
    loading: artworksLoading && numArtworksInCurrentSeriesLoading,
    artworksInCurrentSeries: displayedArtworks,
    fetchArtworksInCurrentSeries: fetchArtworks,
  };
};

export default useGetArtworksInCurrentSeries;
