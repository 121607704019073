import { DeepPartial } from '@reduxjs/toolkit';
import { FollowButtonState, FollowButtonVariants } from './variantProps';

type FollowButtonPredefine = {
  FOLLOW: FollowButtonVariants;
  FOLLOWING: FollowButtonVariants;
  override: DeepPartial<FollowButtonState>;
  // we currently do not have any hover state which would show the unfollow button
  UNFOLLOW?: FollowButtonVariants;
  withHover?: boolean;
};

const defaultCardCTAContainerProps: Partial<FollowButtonState['container']> = {
  maxWidth: '125px',
};

const TALL_WIDE_LISTING_CARD_CTA: FollowButtonPredefine = {
  FOLLOW: 'FOLLOW_FULL', // isRecognizing: false
  FOLLOWING: 'FOLLOWING_ICON_ONLY', // isRecognizing: true
  override: {
    container: {
      ...defaultCardCTAContainerProps,
      height: '26px',
    },
  },
};

const SHORT_MEDIUM_LISTING_CARD_CTA: FollowButtonPredefine = {
  FOLLOW: 'FOLLOW_TEXT_ONLY', // isRecognizing: false
  FOLLOWING: 'FOLLOWING_ICON_ONLY', // isRecognizing: true
  override: {
    container: {
      ...defaultCardCTAContainerProps,
      height: '18px',
    },
  },
};

const DASHBOARD_FOLLOWERS_LISTING_CTA: FollowButtonPredefine = {
  FOLLOW: 'FOLLOW_BACK', // isRecognizing: false
  FOLLOWING: 'UNFOLLOW_TEXT_ONLY', // isRecognizing: true
  override: {
    container: {
      ...defaultCardCTAContainerProps,
      maxWidth: '118px',
      minWidth: '118px',
      width: '118px',
      padding: '8px',
      borderRadius: '6px !important',
      height: '22px',
      margin: '2px 0px 0px 0px !important',
    },
  },
};

const TALL_FULL_SIDEBAR_CTA: FollowButtonPredefine = {
  FOLLOW: 'FOLLOW_FULL', // isRecognizing: false
  FOLLOWING: 'FOLLOWING_FULL', // isRecognizing: true
  override: {
    container: {
      width: 'calc(100% - 34px)', // 16px * 2 padding + 2px border
      height: '26px',
      withoutHover: true,
      withoutHoverShadow: true,
    },
    icon: {
      size: '18px',
      style: {
        opacity: '0.9',
      },
    },
    text: {
      style: {
        opacity: '0.9',
      },
    },
  },
};
const SHORT_FULL_SIDEBAR_CTA: FollowButtonPredefine = {
  FOLLOW: 'FOLLOW_FULL', // isRecognizing: false
  FOLLOWING: 'FOLLOWING_FULL', // isRecognizing: true
  override: {
    container: {
      ...TALL_FULL_SIDEBAR_CTA.override.container,
      height: '20px',
    },
    icon: TALL_FULL_SIDEBAR_CTA.override.icon,
    text: TALL_FULL_SIDEBAR_CTA.override.text,
  },
};

const BOTTOM_NAVBAR_CTA: FollowButtonPredefine = {
  FOLLOW: 'FOLLOW_ICON_ONLY', // isRecognizing: false
  FOLLOWING: 'FOLLOWING_ICON_ONLY', // isRecognizing: true
  override: {
    container: {
      height: 'calc(100% - 20px)',
      withoutBorder: true,
      withoutHoverShadow: true,
      backgroundColor: 'transparent',
    },
  },
};

const followButtonPredefines = {
  TALL_WIDE_LISTING_CARD_CTA,
  SHORT_MEDIUM_LISTING_CARD_CTA,
  TALL_FULL_SIDEBAR_CTA,
  SHORT_FULL_SIDEBAR_CTA,
  BOTTOM_NAVBAR_CTA,
  DASHBOARD_FOLLOWERS_LISTING_CTA,
};

export type FollowButtonPredefines = keyof typeof followButtonPredefines;

export default followButtonPredefines;
