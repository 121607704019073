import { getDefaultInterestFilter } from './store/interests.defaults';
import { InterestsFilterState } from './store/interests.types';

export const interestsFilterHash = (options: InterestsFilterState['interests']): string =>
  Object.keys(options ?? {})
    .sort()
    .flatMap((interestGroup) =>
      Object.keys(options[interestGroup] ?? {})
        .filter((interestId) => !!options[interestGroup][Number(interestId)])
        .sort()
    )
    .join('_');

const SERIALIZATION_KEY = 'intrst'; // to avoid collision with other filters and enum values
const getKey = (group: string): string => `${SERIALIZATION_KEY}${group}`;

export const interestsFilterSerialize = (options: InterestsFilterState): string => {
  const serialized = new URLSearchParams();
  Object.keys(options.interests ?? {})
    .filter(
      (interestGroup) =>
        !!options.interests[interestGroup] &&
        Object.keys(options.interests[interestGroup]).some(
          (interestId) => !!interestId && !!options.interests[interestGroup][Number(interestId)]
        )
    )
    .sort()
    .forEach((interestGroup) => {
      serialized.set(
        getKey(interestGroup),
        JSON.stringify(
          // array of ids
          Object.keys(options.interests[interestGroup] ?? {})
            .filter((interestId) => !!interestId && !!options.interests[interestGroup][Number(interestId)])
            .sort()
        )
      );
    });
  return serialized.toString();
};

export const interestsFilterDeserialize = (serializedFilters: string): InterestsFilterState => {
  const serialized = new URLSearchParams(serializedFilters);
  const interestsFilter = getDefaultInterestFilter();
  const interestGroups = Array.from(serialized.keys()).filter((key) => key.startsWith(SERIALIZATION_KEY));
  interestGroups.forEach((interestGroup) => {
    const interestGroupKey = interestGroup.replace(SERIALIZATION_KEY, '');
    const interestOptions = serialized.get(interestGroup);
    if (!interestOptions) {
      return;
    }
    try {
      const parsedInterest = JSON.parse(interestOptions);
      if (!Array.isArray(parsedInterest)) {
        return;
      }
      interestsFilter.interests[interestGroupKey] = {};
      parsedInterest.forEach((interestId) => {
        if (!interestId) {
          return;
        }
        interestsFilter.interests[interestGroupKey][Number(interestId)] = true;
      });
    } catch (e) {
      console.warn('Failed to parse interest filter', e);
    }
  });
  return interestsFilter;
};
