import { SortState } from 'common/features/Filters/predefined/SortGroup/store/sort.types';
import { ListingIdentifier, filterHashResetMarker } from 'common/features/VirtualList/components/listReduxFragment';
import { RefreshFilterState } from 'common/features/Filters/predefined/Refresh/refresh.types';
import { getDefaultRefreshFilter } from 'common/features/Filters/predefined/Refresh/refresh.defaults';
import { SortOptionsEnum } from 'common/features/VirtualList/VirtualList.config';
import { createRefreshFilterCaseReducer } from 'common/features/Filters/predefined/Refresh/refresh.reducers';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TitleFilterState } from 'common/features/Filters/predefined/Title/store/title.types';
import { titleFilterHash } from 'common/features/Filters/predefined/Title/title.utils';
import { getDefaultTitleFilter } from 'common/features/Filters/predefined/Title/store/title.defaults';
import { createFilterTitleCaseReducer } from 'common/features/Filters/predefined/Title/store/title.reducers';
import { createToggleAvailabilityCaseReducer } from 'common/features/Filters/predefined/Availability/store/availability.reducers';
import { createToggleOrientationCaseReducer } from 'common/features/Filters/predefined/Orientation/store/orientation';
import { getDefaultAvailabilityFilter } from 'common/features/Filters/predefined/Availability/store/availability.defaults';
import { getDefaultOrientationFilter } from 'common/features/Filters/predefined/Orientation/store/orientation.defaults';
import { availabilityFilterHash } from 'common/features/Filters/predefined/Availability/availability.utils';
import { AvailabilityFilterState } from 'common/features/Filters/predefined/Availability/store/availability.types';
import { OrientationFilterState } from 'common/features/Filters/predefined/Orientation/store/orientation.types';
import { orientationFilterHash } from 'common/features/Filters/predefined/Orientation/orientation.utils';
import { createToggleDisciplineCaseReducer } from 'common/features/Filters/predefined/Disciplines/store/disciplines.reducers';
import { DisciplinesFilterState } from 'common/features/Filters/predefined/Disciplines/store/disciplines.types';
import { disciplinesFilterHash } from 'common/features/Filters/predefined/Disciplines/Disciplines.utils';
import { getDefaultDisciplineFilter } from 'common/features/Filters/predefined/Disciplines/store/disciplines.defaults';
import { ArtShowArtworksSortOptions } from './listArtShowArtworks.slice';

export type ArtShowArtworksFiltersState = SortState<ArtShowArtworksSortOptions> & {
  filterHash: ListingIdentifier<ArtShowArtworksSortOptions>;
} & RefreshFilterState &
  DisciplinesFilterState &
  AvailabilityFilterState &
  OrientationFilterState &
  TitleFilterState;

export const getHashForArtShowArtworksFilters = (
  filters: Omit<ArtShowArtworksFiltersState, 'filterHash'>,
  customSuffix?: string
): ListingIdentifier<ArtShowArtworksSortOptions> =>
  `${filters.currentSort}_${[
    titleFilterHash(filters.title),
    disciplinesFilterHash(filters.disciplines),
    availabilityFilterHash(filters.availability),
    orientationFilterHash(filters.orientation),
    filterHashResetMarker, // user-applied filters above this point
    customSuffix ?? 'nosuffix',
    filters.refresh,
  ].join('_')}` as const;

export const getDefaultArtShowArtworksFilters = (customSuffix?: string): ArtShowArtworksFiltersState => {
  const noHashFilterState: Omit<ArtShowArtworksFiltersState, 'filterHash'> = {
    ...getDefaultRefreshFilter(),
    ...getDefaultTitleFilter(),
    ...getDefaultDisciplineFilter(),
    ...getDefaultAvailabilityFilter(),
    ...getDefaultOrientationFilter(),
    currentSort: SortOptionsEnum.BIGGEST_SIZE,
  };
  const filtersState: ArtShowArtworksFiltersState = {
    ...noHashFilterState,
    filterHash: getHashForArtShowArtworksFilters(noHashFilterState, customSuffix),
  };
  return filtersState;
};

export const filterInitialState: ArtShowArtworksFiltersState = getDefaultArtShowArtworksFilters();

export const filterArtShowArtworksSlice = createSlice({
  name: 'filter',
  initialState: filterInitialState,
  reducers: {
    RESET_ArtShowArtworks: (state, action: PayloadAction<{ customSuffix?: string }>) => ({
      ...state,
      ...getDefaultArtShowArtworksFilters(action.payload.customSuffix),
    }),
    REFRESH_ArtShowArtworks: createRefreshFilterCaseReducer<ArtShowArtworksFiltersState>(),
    SORT_ArtShowArtworks: (state, action: PayloadAction<ArtShowArtworksSortOptions>) => ({
      ...state,
      currentSort: action.payload,
    }),
    SET_TITLE_ArtShowArtworks: createFilterTitleCaseReducer<ArtShowArtworksFiltersState>(),
    TOGGLE_AVAILABILITY_ArtShowArtworks: createToggleAvailabilityCaseReducer<ArtShowArtworksFiltersState>(),
    TOGGLE_ORIENTATION_ArtShowArtworks: createToggleOrientationCaseReducer<ArtShowArtworksFiltersState>(),
    TOGGLE_DISCIPLINE_ArtShowArtworks: createToggleDisciplineCaseReducer<ArtShowArtworksFiltersState>(),
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      (action) => !!action?.type && action.type.startsWith('filter/') && action.type.endsWith('_ArtShowArtworks'),
      (state) => {
        state.filterHash = getHashForArtShowArtworksFilters(state);
      }
    );
  },
});
