import {
  Edition_By_Size,
  Edition_By_Type,
  Enum_Artwork_Showroom_Status_Enum,
  Enum_Size_Enum,
} from 'common/schema/commonSchemaRemoteTypes';
import { DEFAULT_MEASURE_UNIT } from 'common/utils/constants';
import { SizeMap } from 'common/utils/types';
import { mapValues } from 'lodash';
import { useTranslation } from 'react-i18next';
import { ArtworkAvailabilityConfigValues } from 'stacks/Dashboard/common/ArtworkPublish/components/common/Commercial/components/ArtworkAvailability';
import { MAX_PRINT_COUNT } from 'stacks/Dashboard/common/ArtworkPublish/utils/artworkPublishConstants';
import { formatNumberToLocale } from 'utils/utilFunctions';
import * as Yup from 'yup';
import { FormikSectionConfig } from '../utils/formikTypeUtils';
import {
  ArtworkCreationDateConfigValues,
  ArtworkDimensionsConfigValues,
  ArtworkMeasuringUnitConfigValues,
  artworkMeasuringUnitValidationSchema,
  dateValidationSchema,
  widthAndHeightValidationSchema,
} from './Artwork';

export interface EditionSize {
  size: Enum_Size_Enum;
  id?: Edition_By_Size['id'];
  disabled?: boolean;
}
export interface PrintsSizesConfigValues {
  selectedSizes: EditionSize[];
}

export const PrintsSizesFormikConfig: FormikSectionConfig<PrintsSizesConfigValues> = (override) => {
  const { t } = useTranslation();
  return {
    initialValues: {
      selectedSizes: override?.selectedSizes || [],
    },
    validationSchema: {
      selectedSizes: Yup.array()
        .of(
          Yup.object().shape({
            disabled: Yup.bool(),
          })
        )
        .min(1, t('publish.prints.size.notSelectedError'))
        .test(
          'size-required',
          t('publish.prints.size.notSelectedError'),
          (sizes) => !!sizes?.filter((v) => !v.disabled).length
        ),
    },
  };
};

// TODO: verify the exact list of default edition types
// TODO: use enum/list from db (users can add more edition types)

export enum DefaultEditionType {
  LIMITED_EDITION = 'LIMITED_EDITION',
  ARTIST_PROOFS = 'ARTIST_PROOFS',
  BON_A_TIRER = 'BON_A_TIRER',
  HORS_COMMERCE = 'HORS_COMMERCE',
  PRINTER_PROOFS = 'PRINTER_PROOFS',
}

export type EditionTypeValues = {
  checked: boolean;
  editionTypeId: number;
  id?: number;
};

export type SizeEditionMap<T> = Partial<SizeMap<{ [editionKey: string]: T }>>;
export interface PrintSizeEditionTypeValues {
  sizeEditionTypes: SizeEditionMap<EditionTypeValues>;
}

export const PrintsSizesEditionTypeFormikConfig: FormikSectionConfig<PrintSizeEditionTypeValues> = (override) => ({
  initialValues: {
    sizeEditionTypes: { ...override?.sizeEditionTypes },
  },
  validationSchema: {},
});

export type EditionPrintsCountMap = { [editionKey: string]: number | undefined };

export interface PrintsCountConfigValues {
  editionPrintsCount: EditionPrintsCountMap;
}

export type PrintDateConfigValues = ArtworkCreationDateConfigValues;
export interface PrintTechniqueConfigValues {
  printTechnique?: string;
}
export interface PrintMaterialConfigValues {
  printMaterial?: string;
}

export type PrintDimensionsConfigValue = Omit<ArtworkDimensionsConfigValues, 'depth'>;

export type PrintSizeDetailsConfigValues = PrintsCountConfigValues &
  PrintDimensionsConfigValue &
  ArtworkMeasuringUnitConfigValues &
  PrintTechniqueConfigValues &
  PrintMaterialConfigValues &
  PrintDateConfigValues;

export const PrintSizeDetailsFormikConfig: FormikSectionConfig<PrintSizeDetailsConfigValues> = (override) => {
  const { t } = useTranslation();

  return {
    initialValues: {
      ...override,
      artworkMeasuringUnit: override?.artworkMeasuringUnit || DEFAULT_MEASURE_UNIT,
      editionPrintsCount: override?.editionPrintsCount ?? {},
    },
    validationSchema: {
      artworkMeasuringUnit: artworkMeasuringUnitValidationSchema,
      editionPrintsCount: Yup.lazy((obj) =>
        Yup.object(
          mapValues(obj, () =>
            Yup.number()
              .min(1, t('publish.prints.errors.editionPrintCount.required'))
              .max(
                MAX_PRINT_COUNT,
                t('publish.prints.errors.editionPrintCount.max', {
                  maxNumber: formatNumberToLocale(MAX_PRINT_COUNT),
                })
              )
              .positive(t('publish.prints.errors.editionPrintCount.positive'))
              .required(t('publish.prints.errors.editionPrintCount.required'))
          )
        )
      ),
      ...widthAndHeightValidationSchema,
      ...dateValidationSchema,
      printTechnique: Yup.string().required(t('publish.prints.errors.printTechinque.required')),
      // TODO: validation schema for print technique and material
    },
  };
};

export type PrintPriceStatus = {
  id?: number;
  editionByTypeId: number;
  price?: number;
  number: number;
  status?: Enum_Artwork_Showroom_Status_Enum;
  collector?: string;
};

export type PrintEditionCommercialData = {
  prices?: PrintPriceStatus[];
  name: string;
  printAmount: Edition_By_Type['print_amount'];
} & Pick<Edition_By_Type, 'id' | 'status'>;

export type PrintSizePricesConfigValues = Partial<{ [key: string]: PrintEditionCommercialData }>;

export const PrintSizePricesFormikConfig: FormikSectionConfig<PrintSizePricesConfigValues> = (override) => ({
  initialValues: { ...override },
  validationSchema: {},
});

export enum PrintPriceType {
  'FIXED' = 'Fixed',
  'CUSTOM' = 'Custom',
}

export const DEFAULT_PRINT_PRICE_TYPE = PrintPriceType.FIXED;

export type PrintSizePrices = {
  printPrices: PrintSizePricesConfigValues;
  printDimensions: PrintDimensionsConfigValue;
} & Omit<ArtworkAvailabilityConfigValues, 'artworkName'>;

export type PrintsCommercialConfigValues = {
  printPriceType?: PrintPriceType;
  printSizes?: Partial<SizeMap<PrintSizePrices>>;
};

export const PrintsCommercialFormikConfig: FormikSectionConfig<PrintsCommercialConfigValues> = (override) => ({
  initialValues: { ...override },
  validationSchema: {
    printPriceType: Yup.string().oneOf(Object.values(PrintPriceType)).optional(),
    // NOTE: validation is handled manually before submit
    // printSizes: Yup.lazy((size) => Yup.object(
    //   mapValues(size, () => Yup.object().shape({
    //     printPrices: Yup.lazy((edition) => Yup.object(
    //       mapValues(edition, () => Yup.object().shape({
    //         prices: Yup.object().shape({
    //           price: Yup.number().required(t('addArtworkStrings.errors.priceRequired')),
    //         }),
    //       })),
    //     )),
    //   })),
    // )),
  },
});
