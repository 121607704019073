import { createSlice } from '@reduxjs/toolkit';
import {
  getListModifierDefaults,
  listModifierDefaultFunctions,
  ListModifierDefaultsParams,
} from 'common/features/FilteredArtworksList/config/defaults';
import { ArtistOptionType } from 'common/features/FilteredArtworksList/config/artist';
import { SeriesOptionType } from 'common/features/FilteredArtworksList/config/series';
import {
  AppliedListingModifier,
  BaseOptionValueType,
  filterTypes,
  isOptionType,
  ARTWORK_QUERY_CONDITION,
  ARTWORK_QUERY_CONDITION_IN,
  FilteredArtworkQueryHandlers,
  FILTERS_DEFAULT_VALUE,
} from 'common/features/FilteredArtworksList/config/types';
import { selectOptionFromAppliedModifier } from 'common/features/FilteredArtworksList/hooks/useFilterUtils';
import { LocalCommonModifiersRequiredFieldsFragment } from 'common/schema/commonSchemaRemoteOperationTypes';
import { PriceOptionType } from 'common/features/FilteredArtworksList/config/price';
import { PrizesOptionType } from 'common/features/FilteredArtworksList/config/prizes';
import { GalleriesOptionType } from 'common/features/FilteredArtworksList/config/galleries';

type ListStateType = 'READY' | 'FETCH' | 'INITIALIZE' | 'LOADING';

export enum ArtworksListType {
  ARTIST_PROFILE = 'ARTIST_PROFILE',
  SHOWROOM = 'SHOWROOM',
  COLLECTOR_PROFILE = 'COLLECTOR_PROFILE',
  FAVORITES = 'FAVORITES',
}

type ListModifierType<T extends LocalCommonModifiersRequiredFieldsFragment> = {
  identifier: string;
  artworks: T[];
  listState: ListStateType;
  filters: { [filterKey in filterTypes]: AppliedListingModifier };
  priceRange: PriceOptionType;
  queryChanged: number;
  filterChanged: number;
  hasModifiersApplied: boolean;
  allArtworks: T[];
};

type listModifiersSliceType = {
  [listTypeKey in ArtworksListType]: ListModifierType<FilteredArtworkQueryHandlers[listTypeKey]['queryArtworkType']>;
} & {
  showCasing: ArtworksListType | null;
};

const listTypeDefaultState: <T extends ArtworksListType>(
  params: ListModifierDefaultsParams
) => ListModifierType<FilteredArtworkQueryHandlers[T]['queryArtworkType']> = (params) => ({
  identifier: '',
  artworks: [],
  listState: 'READY',
  filters: getListModifierDefaults(params),
  priceRange: { min: 0, max: 0 },
  hasModifiersApplied: false,
  filterChanged: 0,
  queryChanged: 0,
  allArtworks: [],
});

const getInitialState: () => listModifiersSliceType = () => ({
  [ArtworksListType.ARTIST_PROFILE]: listTypeDefaultState<ArtworksListType.ARTIST_PROFILE>({
    variant: ArtworksListType.ARTIST_PROFILE,
  }),
  [ArtworksListType.COLLECTOR_PROFILE]: listTypeDefaultState<ArtworksListType.COLLECTOR_PROFILE>({
    variant: ArtworksListType.COLLECTOR_PROFILE,
  }),
  [ArtworksListType.SHOWROOM]: listTypeDefaultState<ArtworksListType.SHOWROOM>({
    variant: ArtworksListType.SHOWROOM,
  }),
  [ArtworksListType.FAVORITES]: listTypeDefaultState<ArtworksListType.FAVORITES>({
    variant: ArtworksListType.FAVORITES,
  }),
  showCasing: ArtworksListType.ARTIST_PROFILE,
});

type SetArtworkListType =
  | {
      type: ArtworksListType.ARTIST_PROFILE;
      artworks: listModifiersSliceType[ArtworksListType.ARTIST_PROFILE]['artworks'];
    }
  | {
      type: ArtworksListType.COLLECTOR_PROFILE;
      artworks: listModifiersSliceType[ArtworksListType.COLLECTOR_PROFILE]['artworks'];
    }
  | { type: ArtworksListType.SHOWROOM; artworks: listModifiersSliceType[ArtworksListType.SHOWROOM]['artworks'] }
  | { type: ArtworksListType.FAVORITES; artworks: listModifiersSliceType[ArtworksListType.FAVORITES]['artworks'] };
type SetAllArtworksListType =
  | {
      type: ArtworksListType.ARTIST_PROFILE;
      allArtworks: listModifiersSliceType[ArtworksListType.ARTIST_PROFILE]['allArtworks'];
    }
  | {
      type: ArtworksListType.COLLECTOR_PROFILE;
      allArtworks: listModifiersSliceType[ArtworksListType.COLLECTOR_PROFILE]['allArtworks'];
    }
  | { type: ArtworksListType.SHOWROOM; allArtworks: listModifiersSliceType[ArtworksListType.SHOWROOM]['allArtworks'] }
  | {
      type: ArtworksListType.FAVORITES;
      allArtworks: listModifiersSliceType[ArtworksListType.FAVORITES]['allArtworks'];
    };

const artworkListModifiersSlice = createSlice({
  name: 'artworksListModifiers',
  initialState: getInitialState(),
  reducers: {
    INITIALIZE_TYPE: (
      state,
      action: {
        payload: {
          type: ArtworksListType;
          identifier: string;
        };
      }
    ) => {
      const { type, identifier } = action.payload;
      if (!identifier || identifier === state[type].identifier) {
        return state;
      }

      return {
        ...state,
        [type]: {
          ...listTypeDefaultState({
            variant: type,
          }),
          identifier,
          listState: 'INITIALIZE',
        },
        showCasing: type,
      };
    },
    ADD_CUSTOM_OPTIONS: (
      state,
      action: {
        payload: {
          type: ArtworksListType;
          yearOptions?: number[];
          seriesOptions?: SeriesOptionType[];
          artistOptions?: ArtistOptionType[];
          priceOptions?: PriceOptionType;
          prizeOptions?: PrizesOptionType[];
          galleryOption?: GalleriesOptionType[];
          sortCollectionId?: number;
        };
      }
    ) => {
      const {
        seriesOptions,
        yearOptions,
        type,
        sortCollectionId,
        artistOptions,
        priceOptions,
        prizeOptions,
        galleryOption,
      } = action.payload;
      const workingState = state[type];

      if (seriesOptions) {
        workingState.filters = {
          ...workingState.filters,
          SERIES: listModifierDefaultFunctions.SERIES(seriesOptions),
        };
      }

      if (prizeOptions) {
        workingState.filters = {
          ...workingState.filters,
          PRIZES: listModifierDefaultFunctions.PRIZES(prizeOptions),
        };
      }

      if (galleryOption) {
        workingState.filters = {
          ...workingState.filters,
          GALLERIES: listModifierDefaultFunctions.GALLERIES(galleryOption),
        };
      }

      if (yearOptions) {
        workingState.filters = {
          ...workingState.filters,
          YEARS: listModifierDefaultFunctions.YEARS(yearOptions),
        };
      }
      if (artistOptions) {
        workingState.filters = {
          ...workingState.filters,
          ARTIST: listModifierDefaultFunctions.ARTIST(artistOptions),
        };
      }
      if (priceOptions) {
        workingState.filters = {
          ...workingState.filters,
          PRICE: listModifierDefaultFunctions.PRICE({ min: 0, max: 0 }),
        };
        workingState.priceRange = priceOptions;
      }
      if (sortCollectionId) {
        workingState.filters = {
          ...workingState.filters,
          SORT: listModifierDefaultFunctions.SORT(sortCollectionId, type),
        };
      }
      return state;
    },
    UPDATE_SELECTED_OPTIONS: (
      state,
      action: {
        payload: {
          type: ArtworksListType;
          options: {
            key: filterTypes;
            value: BaseOptionValueType;
            rangeValue?: PriceOptionType;
          }[];
        };
      }
    ) => {
      const { type, options } = action.payload;
      if (state[type].listState !== 'READY') {
        return state;
      }
      const workingState = state[type];
      let queryChanged = false;
      let filterChanged = false;

      let priceRangeChanged = false;
      options.forEach((selectedOption) => {
        const { key, value, rangeValue } = selectedOption;
        const affectedModifier = workingState.filters[key];
        const newSelectedOptions = selectOptionFromAppliedModifier(affectedModifier, value);

        const affectedOption = affectedModifier.allOptions.find((option) => option.value === value);
        if (
          affectedOption &&
          (isOptionType<ARTWORK_QUERY_CONDITION>(affectedOption, 'ARTWORK_QUERY_CONDITION') ||
            isOptionType<ARTWORK_QUERY_CONDITION_IN>(affectedOption, 'ARTWORK_QUERY_CONDITION_IN'))
        ) {
          queryChanged = true;
        }
        filterChanged = true;

        const newAppliedListingModifierState: AppliedListingModifier = {
          ...affectedModifier,
          selectedOptions: newSelectedOptions,
        };

        workingState.filters = {
          ...workingState.filters,
          [key]: newAppliedListingModifierState,
        };

        // modifi the price filter
        if (key === 'PRICE') {
          workingState.filters = {
            ...workingState.filters,
            PRICE: listModifierDefaultFunctions.PRICE(rangeValue ?? { min: 0, max: 0 }),
          };
          filterChanged = true;

          if (
            state[type].priceRange.min !== selectedOption.rangeValue?.min ||
            (state[type].priceRange.max !== selectedOption.rangeValue?.max && selectedOption.rangeValue?.max !== 0)
          ) {
            priceRangeChanged = true;
          }
        }
      });

      workingState.hasModifiersApplied =
        !!Object.values(workingState.filters).find(
          (modifier) => !modifier.selectedOptions.includes(FILTERS_DEFAULT_VALUE)
        ) || priceRangeChanged;

      if (queryChanged) {
        workingState.queryChanged += 1;
      }
      if (queryChanged || filterChanged) {
        workingState.filterChanged += 1;
      }
      state[type] = { ...workingState } as any;
      return state;
      // { ...state, [type]: { ...workingState } };
    },
    REFRESH: (
      state,
      {
        payload: { type },
      }: {
        payload: {
          type: ArtworksListType;
        };
      }
    ) => {
      state[type].queryChanged += 1;
      return state;
    },
    UPDATE_LIST_STATE: (
      state,
      action: {
        payload: {
          type: ArtworksListType;
          newState: ListStateType;
        };
      }
    ) => ({
      ...state,
      [action.payload.type]: {
        ...state[action.payload.type],
        listState: action.payload.newState,
      },
    }),
    SET_ARTWORKS: (
      state,
      action: {
        payload: SetArtworkListType;
      }
    ) => ({
      ...state,
      [action.payload.type]: {
        ...state[action.payload.type],
        artworks: action.payload.artworks,
      },
    }),
    SET_ALL_ARTWORKS: (
      state,
      action: {
        payload: SetAllArtworksListType;
      }
    ) => ({
      ...state,
      [action.payload.type]: {
        ...state[action.payload.type],
        allArtworks: action.payload.allArtworks,
      },
    }),
  },
});

export const artworkListActions = artworkListModifiersSlice.actions;

export default artworkListModifiersSlice;
