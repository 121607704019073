import { createSlice } from '@reduxjs/toolkit';
import { createSortMostRecentCaseReducer } from 'common/features/Filters/predefined/SortMostRecent/store/recent';
import { filterInitialState, getDefaultFilters } from './filter.defaults';
import { RecognitionsFiltersState } from './filter.types';

export const recognitionsFiltersSlice = createSlice({
  name: 'filter',
  initialState: filterInitialState,
  reducers: {
    RESET_Recognitions: getDefaultFilters,
    SORT_BY_MOST_RECENT_Recognitions: createSortMostRecentCaseReducer<RecognitionsFiltersState>(),
    SET_FILTER_Recognitions: (state, action) => ({
      ...state,
      chosenFilter: action.payload,
    }),
  },
});
