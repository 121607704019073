import { Grid, makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles({
  customContainer: {
    flexWrap: 'nowrap',
    gap: '4px',
  },
});

export const FooterLayout: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const classes = useStyles();
  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      wrap="nowrap"
      className={classes.customContainer}
    >
      {children}
    </Grid>
  );
};
