import { getUserLanguageFromBrowser } from 'common/utils/languageUtils';
import gql from 'graphql-tag';
import i18n from 'i18n';
import FirebaseState from '../FirebaseHandler';
import { FirebaseUserCredential, ThirdPartyProviderId } from '../FirebaseHandler/types';
import { GoogleProfileInfo, ParsedUserInfo } from './types';

// Used by Google and Apple login to add any existing intents to URL before redirect
export const addEncodedIntentToURL = (encodedIntent: string): void => {
  const url = new URL(window.location.href);
  url.searchParams.set('intent', encodedIntent);
  window.history.pushState({}, '', url.href);
};

// Used by Google and Apple login to parse user info when returning from third party provider
export const parseUserInfoForThirdPartyLogin = (
  userCredential: FirebaseUserCredential,
  providerId?: string
): ParsedUserInfo | null => {
  const locale = i18n.language ?? 'en';
  if (!userCredential.user || !userCredential.user.email || !providerId) {
    return null;
  }
  // Apple only shares user information such as the display name with apps the first time a user signs in.
  let displayName = userCredential.user.displayName ?? FirebaseState.account?.displayName;
  if (!displayName) {
    const nameFromEmail = userCredential.user.email.slice(0, userCredential.user.email.trim()?.indexOf('@'));
    displayName = nameFromEmail.replace('.', ' ').replace(/[^a-zA-Z\s]/g, '');
  }

  const aditionalUserInfo =
    providerId === ThirdPartyProviderId.GOOGLE && userCredential.additionalUserInfo?.profile
      ? (userCredential.additionalUserInfo.profile as GoogleProfileInfo)
      : null;

  const fName = displayName.includes(' ') ? displayName.slice(0, displayName.trim()?.lastIndexOf(' ')) : displayName;
  const lName = displayName.includes(' ') ? displayName.slice(displayName.trim()?.lastIndexOf(' ')) : displayName;

  return {
    firstName: aditionalUserInfo?.given_name ?? fName ?? 'First Name',
    lastName: aditionalUserInfo?.family_name ?? lName ?? 'Last Name',
    email: userCredential.user.email,
    // get the locale language if not available in additional user info
    language: getUserLanguageFromBrowser(aditionalUserInfo?.locale ?? locale).toString(),
    profilePicture: aditionalUserInfo?.picture,
  };
};

export const FINALIZE_THIRD_PARTY_SIGN_IN = gql`
  mutation FinalizeThirdPartySignIn(
    $firstName: String!
    $lastName: String!
    $email: String!
    $preferredLanguage: String!
    $providerId: String!
  ) {
    thirdPartySignIn(
      first_name: $firstName
      last_name: $lastName
      preferred_language: $preferredLanguage
      email: $email
      provider_id: $providerId
    ) {
      valid
      message
    }
  }
`;

export const ACTIVATE_FCM_TOKEN = gql`
  mutation activateMyFCMToken($platform: String!) {
    activateMyFCMToken(platform: $platform)
  }
`;
