import { RouteProps } from 'react-router';

type DefaultRoutePropsTypes = 'subrouter' | 'terminalRoute' | 'passthroughRoute';

const defaultRouteProps: {
  [k in DefaultRoutePropsTypes]: Pick<RouteProps, 'strict' | 'exact' | 'sensitive'>;
} = {
  subrouter: {
    exact: false,
    sensitive: false,
    strict: false,
  },
  terminalRoute: {
    exact: true,
    sensitive: false,
    strict: false,
  },
  passthroughRoute: {
    exact: false,
    sensitive: false,
    strict: false,
  },
};

export default defaultRouteProps;
