import { useSelectedProfile } from 'common/hooks/useSelectedProfile';
import { useMemo } from 'react';
import { CollectionIds } from 'stacks/ProfileArtShowcase/hooks/useGetArtworkCollectionIds';

const canArtistTransfer = (creatorId: number | null, ownerId: number | null, profileContextId?: number) =>
  creatorId === ownerId && ownerId === profileContextId;

const canGalleryTransfer = (
  creatorId: number | null,
  ownerId: number | null,
  managedById: number | null,
  profileContextId?: number
) => (managedById === ownerId || creatorId === ownerId) && managedById === profileContextId;

export const canUserEditArtwork = (
  profileType: string,
  profileContextId: number,
  collectionIds: CollectionIds
): boolean => {
  // CAN'T EDIT TRANSFERRED ARTWORKS - might need to change this implementation later when you will need to be able to edit the transferred ones

  if (
    profileType === 'ARTIST' &&
    !canArtistTransfer(
      collectionIds.creatorId, // TRANSFERRED
      collectionIds.ownerId,
      profileContextId
    )
  ) {
    return false;
  }

  if (
    profileType === 'GALLERY' &&
    !canGalleryTransfer(
      collectionIds.creatorId, // TRANSFERRED
      collectionIds.ownerId,
      collectionIds.managedById,
      profileContextId
    )
  ) {
    return false;
  }

  if (profileType === 'ARTIST') {
    return collectionIds.creatorId === profileContextId;
  }

  if (profileType === 'GALLERY') {
    return collectionIds.managedById === profileContextId;
  }

  return false;
};

const useShowEditButton = (isMobile: boolean, collectionIds: CollectionIds) => {
  const selectedProfile = useSelectedProfile();

  return useMemo(() => {
    if (!selectedProfile?.type || !selectedProfile?.contextId) {
      return false;
    }

    const canLoggedUserEdit = canUserEditArtwork(selectedProfile.type, selectedProfile?.contextId, collectionIds);

    if (canLoggedUserEdit && !isMobile) {
      return true;
    }

    return false;
  }, [isMobile, collectionIds, selectedProfile]);
};

export default useShowEditButton;
