import { createSlice } from '@reduxjs/toolkit';

// TO DO: delete this file, it's no longer used

export interface CollectionsData {
  id: string;
  title: string;
  description: string;
  short_description: string;
  banner_metadata_id: string;
  thumbnail_metadata_id: string;
  amount_participants: number;
  internal_url: string;
}
export interface CollectionsDataState {
  collections: CollectionsData[];
  scrollYPosition: number; // It's no longer used.
}
const initialState: CollectionsDataState = {
  collections: [],
  scrollYPosition: 0,
};
const collectionsPageSlice = createSlice({
  name: 'featuredCollections',
  initialState,
  reducers: {
    ADD_COLLECTIONS: (state, action) => {
      state.collections = action.payload;
    },
    UPDATE_SCROLL_POSITION: (state, action) => {
      state.scrollYPosition = action.payload;
    },
  },
});
export default collectionsPageSlice;
