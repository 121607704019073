import React from 'react';
import { Box } from '@material-ui/core';
import zIndex from 'common/utils/zIndex';

const BadgePreview: React.FC<{ top?: number }> = ({ top }) => (
  <Box
    style={
      {
        '--cardWidth': '200px',
        background: 'rgba(255, 255, 255, 0.8)',
        boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.12)',
        backdropFilter: 'blur(24px)',
        borderRadius: '8px',
        position: 'absolute',
        zIndex: zIndex.values.CARD_BADGE,
        top: top ?? 10,
        left: 8,
        height: '44px',
        minWidth: '44px',
        maxWidth: '44px',
        overflow: 'hidden',
        transition: 'max-width 0.6s ease-in-out',
      } as React.CSSProperties
    }
  >
    <Box
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0px 9px',
        width: 'max-content',
        height: '100%',
      }}
    />
  </Box>
);

export default BadgePreview;
