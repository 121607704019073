import { makeStyles } from '@material-ui/core';

const STATEMENT_ROWS = 5;

export const galleryInfoSectionStyles = makeStyles(() => ({
  container: {
    padding: '0px 48px',
    background: '#FBFBFB',
    border: '1px solid #CDCDCD',
    borderRadius: '4px',
    height: '100% !important',
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    flexDirection: 'column',
  },
  statement: {
    textAlign: 'justify',
    fontSize: '18px',
    color: 'black',
    lineHeight: '24px',
    fontWeight: 400,
    whiteSpace: 'pre-wrap',
    maxWidth: '100%',
    display: '-webkit-box',
    '-webkit-line-clamp': STATEMENT_ROWS,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
  },
  statementFirefox: {
    /* for Firefox */
    display: '-moz-box',
    '-moz-line-clamp': STATEMENT_ROWS,
    '-moz-box-orient': 'vertical',
  },
  statementIE: {
    display: '-ms-box',
    '-ms-line-clamp': STATEMENT_ROWS,
    '-ms-box-orient': 'vertical',
  },
  logoContainer: {
    display: 'block',
    position: 'relative',
    width: '100%',
    maxWidth: '320px',
    overflow: 'hidden',
  },
  logo: {
    width: '100%',
  },
  viewMoreButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  viewMoreButton: {
    width: '150px',
    height: '48px',
    borderRadius: '6px',
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
    },
  },
}));
