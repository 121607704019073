import React, { useCallback } from 'react';
import { Box, Typography } from '@material-ui/core';
import { Trans } from 'react-i18next';
import ARIconAnimation from 'stacks/ShowcaseNew/components/buttons/ARButton/ARIconAnimation';
import { useSwipeContainerComponentsStyles } from './useSwipeContainerComponents.styles';

type SwipeContainerArElementProps = {
  hasAr: boolean;
};

const SwipeContainerArElement: React.FC<SwipeContainerArElementProps> = ({ hasAr }) => {
  const classes = useSwipeContainerComponentsStyles();

  const onClick = useCallback(() => window.open('https://bit.ly/3WOqZjG', '_blank'), []);

  if (!hasAr) {
    return <></>;
  }

  return (
    <Box className={classes.detailElement}>
      <ARIconAnimation className={classes.arIcon} />
      <Typography className={classes.arText} variant="h5">
        <Trans i18nKey="showcase.details.activatesInAR" onClick={onClick}>
          <span style={{ fontWeight: 600, whiteSpace: 'pre' }} />
        </Trans>
      </Typography>
    </Box>
  );
};

export default SwipeContainerArElement;
