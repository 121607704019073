import React from 'react';
import { Button, Theme, Typography, useMediaQuery } from '@material-ui/core';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { CDNUrl } from 'utils/constants';
import { useEventTriggerCaller } from 'common/features/EventTrigger/hooks/useEventTrigger';
import { EventTriggers } from 'common/features/EventTrigger/store/eventTriggerSlice';
import { galleryInfoSectionStyles } from './GalleryInfoSection.styles';

type GalleryInfoSectionProps = {
  galleryImg: string;
  galleryStatement: string;
};

export const GalleryInfoSection: React.FC<GalleryInfoSectionProps> = ({ galleryImg, galleryStatement }) => {
  const classes = galleryInfoSectionStyles();
  const isPhone = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));
  const { t } = useTranslation();

  const [triggerProfileGalleryStatementViewMoreButtonPressed] = useEventTriggerCaller(
    EventTriggers.profileGalleryStatementViewMoreButtonPressed
  );

  if (isPhone) return null;

  return (
    <div className={classes.container}>
      <div className={classes.logoContainer}>
        <img className={classes.logo} alt="" src={galleryImg} />
      </div>
      <Typography className={clsx(classes.statement, classes.statementFirefox, classes.statementIE)}>
        {galleryStatement}
      </Typography>
      <div className={classes.viewMoreButtonContainer}>
        <Button
          variant="contained"
          className={classes.viewMoreButton}
          onClick={() => triggerProfileGalleryStatementViewMoreButtonPressed(null)}
        >
          {t('common.action.viewMore')}
          <img alt="" style={{ marginLeft: '8px' }} src={`${CDNUrl}/images/arrowDown.svg`} />
        </Button>
      </div>
    </div>
  );
};
