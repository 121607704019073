import { createSlice } from '@reduxjs/toolkit';
import { createSortMostRecentCaseReducer } from 'common/features/Filters/predefined/SortMostRecent/store/recent';
import { filterInitialState, getDefaultFilters } from './filter.defaults';
import { FavoritesModalFiltersState } from './filter.types';

export const favoritesModalFiltersSlice = createSlice({
  name: 'filter',
  initialState: filterInitialState,
  reducers: {
    RESET_FavoritesModal: getDefaultFilters,
    SORT_BY_MOST_RECENT_FavoritesModal: createSortMostRecentCaseReducer<FavoritesModalFiltersState>(),
  },
});
