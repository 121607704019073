import { milestoneFeatures, milestoneStatuses } from './milestonesConstants';
import { MilestoneFeature, MilestoneStatus } from './milestonesTypes';

export const isMilestoneFeatureType = (value: unknown): value is MilestoneFeature => {
  if (typeof value !== 'string' || value.length <= 0) {
    return false;
  }
  return milestoneFeatures.includes(value as MilestoneFeature);
};

export const isMilestoneStatus = (value: unknown): value is MilestoneStatus => {
  if (typeof value !== 'string' || value.length <= 0) {
    return false;
  }
  return milestoneStatuses.includes(value as MilestoneStatus);
};
