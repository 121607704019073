import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { ArtistContextIdFilterState } from './artistContextId.types';

type ToggleArtistAction = PayloadAction<{
  artistContextId: number;
}>;

export const createToggleArtistContextIdCaseReducer =
  <State extends ArtistContextIdFilterState>(): CaseReducer<State, ToggleArtistAction> =>
  (state, action) => {
    state.selectedArtistContextId = action.payload.artistContextId;
  };
