import { useCallback, useEffect } from 'react';

const listeners: { name: string; cb: (evt?: Event) => void }[] = [];

export const useScrollEvent = (name: string, cb: (evt?: Event) => void) => ({
  subscribe: () => listeners.push({ name, cb }),
  unsubscribe: () =>
    listeners.splice(
      listeners.findIndex((x) => x.name === name),
      1
    ),
});

export const OnScrollEventHandler = () => {
  const scrollEventCalled = useCallback(
    (evt) => {
      listeners.filter((x) => typeof x.cb === 'function').forEach((x) => x.cb(evt));
    },
    [listeners]
  );
  useEffect(() => {
    window.addEventListener('scroll', scrollEventCalled);
    return () => {
      window.removeEventListener('scroll', scrollEventCalled);
    };
  }, [scrollEventCalled]);

  return null;
};
