import { combineReducers } from 'redux';
import { homeArtworksListSlice } from './list.slice';
import { homeArtworksFiltersSlice } from './filter.slice';
import { homeArtworksDisplaySlice } from './display.slice';

export const actions = {
  ...homeArtworksListSlice.actions,
  ...homeArtworksFiltersSlice.actions,
  ...homeArtworksDisplaySlice.actions,
};

export const homeArtworksSlice = {
  homeArtworks: combineReducers({
    [homeArtworksListSlice.name]: homeArtworksListSlice.reducer,
    [homeArtworksFiltersSlice.name]: homeArtworksFiltersSlice.reducer,
    [homeArtworksDisplaySlice.name]: homeArtworksDisplaySlice.reducer,
  }),
};
