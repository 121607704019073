import { ProfileListType } from 'store/slices/profileListModifiers';
import { getAppliedArtistFilter } from './artist';
import { getAppliedDisciplineFilter } from './discipline';
import { getAppliedRegionFilter } from './region';
import { getAppliedSort, sortButtonsListTypeConfig } from './sort';
import { AppliedListingModifier, filterTypes, ListingModifierButtonsConfig } from './types';

export const listModifierDefaultFunctions = {
  ARTIST: getAppliedArtistFilter,
  SORT: getAppliedSort,
  DISCIPLINE: getAppliedDisciplineFilter,
  REGION: getAppliedRegionFilter,
};

export const groupTitlesKeys: { [key in filterTypes]: string } = {
  ARTIST: 'filterStrings.artist.title',
  SORT: 'filterStrings.sort.title',
  DISCIPLINE: 'filterStrings.discipline.title',
  REGION: 'filterStrings.region.title',
};

export type ListModifierDefaultsParams = {
  variant?: ProfileListType;
};

type DefaultModifiers = { [key in filterTypes]: AppliedListingModifier };

export const getListModifierDefaults = (params: ListModifierDefaultsParams): DefaultModifiers => ({
  ARTIST: listModifierDefaultFunctions.ARTIST([]),
  SORT: listModifierDefaultFunctions.SORT(0, params.variant),
  DISCIPLINE: listModifierDefaultFunctions.DISCIPLINE(),
  REGION: listModifierDefaultFunctions.REGION(),
});

export const filtersButtonsConfig: {
  [filterTypeKey in filterTypes]: {
    [listTypeKey in ProfileListType]: ListingModifierButtonsConfig;
  };
} = {
  ARTIST: {
    EXPLORE: {},
    COLLECTION: {},
  },
  SORT: sortButtonsListTypeConfig,
  DISCIPLINE: {
    EXPLORE: {},
    COLLECTION: {},
  },
  REGION: {
    EXPLORE: {},
    COLLECTION: {},
  },
};

export const visibleFilters: { [listTypeKey in ProfileListType]: filterTypes[] } = {
  EXPLORE: ['SORT', 'DISCIPLINE', 'REGION'],
  COLLECTION: ['SORT', 'REGION'],
};
