import { Artwork_Bool_Exp } from '../../../schema/commonSchemaRemoteTypes';
import {
  AppliedListingModifier,
  ARTWORK_QUERY_CONDITION_IN,
  FILTERS_DEFAULT_VALUE,
  isOptionType,
  ListingModifier,
  ListingModifierOption,
} from './types';

export const generateYearsFilterOption = (year: number): ListingModifierOption => ({
  name: year.toString(),
  value: year,
  key: 'ARTWORK_QUERY_CONDITION_IN',
  payload: { artwork_details: { creation_date_year: { _in: [year] } } },
});

export const getYearsFilter = (): ListingModifier => ({
  type: 'YEARS',
  allOptions: [
    {
      value: FILTERS_DEFAULT_VALUE,
      name: 'filterStrings.years.all',
      key: 'ARTWORK_QUERY_CONDITION_IN',
      payload: {},
    },
  ],
});

export const getAppliedYearsFilter = (yearOptions: number[]): AppliedListingModifier<ARTWORK_QUERY_CONDITION_IN> => {
  const yearsFilter = getYearsFilter();
  const newYearFilterOptions = yearOptions.map(generateYearsFilterOption);
  yearsFilter.allOptions.push(...newYearFilterOptions);
  return {
    ...yearsFilter,
    selectionType: 'MULTIPLE',
    selectedOptions: [FILTERS_DEFAULT_VALUE],
    flattenSelectedOptions: (options, selectedOptions) => {
      const selectedModifiers = options.filter((option) => selectedOptions.includes(option.value));
      const flattenedModifiers = selectedModifiers.reduce<Artwork_Bool_Exp>((flattenedOptions, currentOption) => {
        if (
          isOptionType<ARTWORK_QUERY_CONDITION_IN>(currentOption, 'ARTWORK_QUERY_CONDITION_IN') &&
          currentOption.payload.artwork_details?.creation_date_year?._in?.length
        ) {
          return {
            artwork_details: {
              creation_date_year: {
                _in: [
                  ...(flattenedOptions.artwork_details?.creation_date_year?._in ?? []),
                  ...currentOption.payload.artwork_details.creation_date_year._in,
                ],
              },
            },
          };
        }
        return flattenedOptions;
      }, {});

      return {
        key: 'ARTWORK_QUERY_CONDITION_IN',
        name: 'APPLIED_YEARS_FILTER',
        value: -1,
        payload: flattenedModifiers,
      };
    },
  };
};
