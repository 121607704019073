import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { ArtistsFilterState, DEFAULT_ARTIST_ID_VALUE } from './artists.types';
import { getDefaultArtistsFilter } from './artists.defaults';

type ToggleArtistAction = PayloadAction<{
  artistContextId: number;
}>;

export const createToggleArtistsCaseReducer =
  <State extends ArtistsFilterState>(): CaseReducer<State, ToggleArtistAction> =>
  (state, action) => {
    const { artistContextId } = action.payload ?? {};
    const prevArtists = state.selectedArtists;

    // cannot actively turn "All" off when it is on
    if (artistContextId === DEFAULT_ARTIST_ID_VALUE && prevArtists[DEFAULT_ARTIST_ID_VALUE]) return;

    // can turn "All" on when it is off and turn everything else off
    if (artistContextId === DEFAULT_ARTIST_ID_VALUE) {
      state.selectedArtists = getDefaultArtistsFilter().selectedArtists;
      return;
    }

    const nextArtists = { ...prevArtists };
    nextArtists[artistContextId] = !nextArtists[artistContextId];

    // if no priceRanges are on, set "All" on
    nextArtists[DEFAULT_ARTIST_ID_VALUE] = !Object.values(nextArtists).includes(true);

    state.selectedArtists = nextArtists;
  };
