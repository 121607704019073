import { useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router';

type HistoryItem = [
  string, // key
  boolean, // isShowcase
  number? // starting history length
];

interface KaleidoHistoryTracker {
  readonly currentIndex: number;
  readonly kaleidoHistoryArray: HistoryItem[];
}

const initKaleidoHistoryTracker = () => {
  const ref: {
    current: KaleidoHistoryTracker;
  } = {
    current: sessionStorage.getItem('kaleidoHistoryTracker')
      ? JSON.parse(sessionStorage.getItem('kaleidoHistoryTracker') || '')
      : {
          currentIndex: -1,
          kaleidoHistoryArray: [],
        },
  };

  const update = (session: KaleidoHistoryTracker) => {
    ref.current = session;
    sessionStorage.setItem('kaleidoHistoryTracker', JSON.stringify(ref.current));
  };

  const get = () => ref.current;

  const init = (pathname: string, key: string) => {
    // Previously not on Kaleido
    if (ref.current.currentIndex <= -1) {
      update({
        currentIndex: 0,
        kaleidoHistoryArray: [[key, pathname.includes('/showcase/'), window.history.length]],
      });
      return;
    }

    const existingIndex = ref.current.kaleidoHistoryArray.findIndex((v) => v[0] === key);
    if (existingIndex > -1) {
      update({
        ...ref.current,
        currentIndex: existingIndex,
      });
      return;
    }

    const forwardKaleidoIndex = ref.current.kaleidoHistoryArray.findIndex((v) => (v[2] ?? -1) >= window.history.length);

    if (forwardKaleidoIndex === -1) {
      update({
        kaleidoHistoryArray: [
          ...ref.current.kaleidoHistoryArray,
          [key, pathname.includes('/showcase'), window.history.length],
        ],
        currentIndex: ref.current.kaleidoHistoryArray.length,
      });
      return;
    }

    const newKaleidoHistoryArray = ref.current.kaleidoHistoryArray.slice(0, forwardKaleidoIndex);
    update({
      kaleidoHistoryArray: [...newKaleidoHistoryArray, [key, pathname.includes('/showcase'), window.history.length]],
      currentIndex: newKaleidoHistoryArray.length,
    });
  };

  const getCurrentIndex = () => ref.current?.currentIndex;

  const onPop = (currentLocation: { pathname: string; key: string }) => {
    let index = getCurrentIndex();
    if (index === -1) {
      return;
    }

    const array = [...ref.current.kaleidoHistoryArray];

    if (index > 0 && currentLocation.key === array[index - 1]?.[0]) {
      index -= 1;
    } else if (currentLocation.key === array[index + 1]?.[0]) {
      index += 1;
    }

    update({
      currentIndex: index,
      kaleidoHistoryArray: array,
    });
  };

  const onPush = (currentLocation: { pathname: string; key: string }) => {
    let index = getCurrentIndex();
    if (index === -1) {
      return;
    }

    const array = ref.current.kaleidoHistoryArray.slice(0, index + 1);

    array.push([currentLocation.key, currentLocation.pathname.includes('/showcase/')]);
    index += 1;

    update({
      currentIndex: index,
      kaleidoHistoryArray: array,
    });
  };

  const onReplace = (currentLocation: { pathname: string; key: string }) => {
    const index = getCurrentIndex();
    if (index === -1) {
      return;
    }

    const array = ref.current.kaleidoHistoryArray.slice(0, index + 1);

    array[index] = [currentLocation.key, currentLocation.pathname.includes('/showcase/')];

    update({
      currentIndex: index,
      kaleidoHistoryArray: array,
    });
  };

  const hasNavigatedOnKaleido = () => ref.current.kaleidoHistoryArray[getCurrentIndex()]?.[2] === undefined;
  const wholeHistoryIsShowcase = () => ref.current.kaleidoHistoryArray.every((v) => v[1]);

  const getKaleidoTrackerKey = (currentLocation: { pathname: string; key?: string; hash: string }) =>
    (currentLocation.key || currentLocation.hash || currentLocation.pathname) ?? 'unknown';

  // To be used on Close button within Art Showcase
  const closeShowcase = () => {
    let index = ref.current.currentIndex;
    let counter = 0;

    while (ref.current.kaleidoHistoryArray[index]?.[1]) {
      index -= 1;
      counter += 1;
    }

    update({
      kaleidoHistoryArray: ref.current.kaleidoHistoryArray,
      currentIndex: index,
    });

    return counter;
  };

  const getPreviousConsecutiveShowcaseNumber = () => {
    // Ignore if first page accessed if a showcase
    if (ref.current.currentIndex <= 1) {
      return 0;
    }

    let prevIndex = ref.current.currentIndex - 1;
    let counter = 0;

    while (ref.current.kaleidoHistoryArray[prevIndex][1]) {
      prevIndex -= 1;
      counter += 1;
    }

    // We don't want to update the history there is no previous showcase page
    // to skip when clicking the back button
    if (counter !== 0) {
      update({
        kaleidoHistoryArray: ref.current.kaleidoHistoryArray,
        currentIndex: prevIndex,
      });
    }

    return counter;
  };

  // To be used on Close button within Art Showcase
  // only when there is no previous kaleido page in history
  const onCloseNoBackPush = (currentLocation: { pathname: string; key: string }) => {
    const index = getCurrentIndex();
    if (index === -1) {
      return;
    }

    const array: HistoryItem[] = [
      [currentLocation.key, currentLocation.pathname.includes('/showcase/'), window.history.length],
    ];

    update({
      currentIndex: 0,
      kaleidoHistoryArray: array,
    });
  };

  return {
    update,
    get,
    getCurrentIndex,
    init,
    onPop,
    onPush,
    onReplace,
    hasNavigatedOnKaleido,
    getKaleidoTrackerKey,
    closeShowcase,
    wholeHistoryIsShowcase,
    getPreviousConsecutiveShowcaseNumber,
    onCloseNoBackPush,
  };
};

export const kaleidoHistoryTracker = initKaleidoHistoryTracker();

const useKaleidoHistoryTracker = (): void => {
  const history = useHistory();
  const { hash, pathname, key } = useLocation();
  const actualKey = kaleidoHistoryTracker.getKaleidoTrackerKey({ hash, pathname, key });

  const isInitialized = useRef(false);

  useEffect(() => {
    if (isInitialized.current || !actualKey) {
      return;
    }

    isInitialized.current = true;

    kaleidoHistoryTracker.init(pathname, actualKey);
  }, [actualKey, pathname]);

  useEffect(() => {
    const kaleidoHistoryUnlisten = history.listen((currentLocation, action) => {
      const currentKey = kaleidoHistoryTracker.getKaleidoTrackerKey({
        hash: currentLocation.hash,
        pathname: currentLocation.pathname,
        key: currentLocation.key,
      });

      const params = {
        pathname: currentLocation.pathname,
        key: currentKey,
      };
      if (action === 'POP') {
        kaleidoHistoryTracker.onPop(params);
      } else if (action === 'PUSH') {
        kaleidoHistoryTracker.onPush(params);
      } else if (action === 'REPLACE') {
        kaleidoHistoryTracker.onReplace(params);
      }
    });

    return kaleidoHistoryUnlisten;
  }, []);
};

export default useKaleidoHistoryTracker;
