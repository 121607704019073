import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface interestsState {
  interestIds: number[];
}

const initialState: interestsState = {
  interestIds: [] as number[],
};

const interestsSlice = createSlice({
  name: 'interests',
  initialState,
  reducers: {
    SET_INTERESTS: (state, action: PayloadAction<number[]>) => {
      state.interestIds = action.payload;
    },
    RESET_INTERESTS: (state) => {
      state.interestIds = [];
    },
  },
});

export const interestsActions = interestsSlice.actions;
export default interestsSlice;
