import { Maybe } from 'common/schema/commonSchemaRemoteTypes';
import { getCDNImageUrl } from 'utils/CDNImages';

export const unknownProfileImageSrc = getCDNImageUrl({
  target: 'Profile',
  purpose: 'Avatar',
  targetId: 0,
  fileId: 0,
  meta: {
    size: 'medium',
  },
});

export const getProfileAvatarSrc = (
  size: 'medium' | 'large',
  profileId?: Maybe<number>,
  avatarId?: Maybe<number>
): string | undefined => {
  if (!profileId) {
    return undefined;
  }

  return getCDNImageUrl({
    target: 'Profile',
    purpose: 'Avatar',
    targetId: profileId,
    fileId: avatarId ?? 0,
    meta: {
      size,
    },
  });
};

export const getSquareArtworkImageSrc = (
  size: 'large' | 'medium' | 'small' | undefined,
  artworkId?: Maybe<number>,
  fileId?: Maybe<number>
): string | undefined => {
  if (!artworkId || !fileId || !size) {
    return undefined;
  }
  if (size !== 'small') {
    return getCDNImageUrl({
      target: 'Artwork',
      purpose: 'Primary',
      targetId: artworkId,
      fileId,
      meta: {
        size,
      },
    });
  }

  return getCDNImageUrl({
    target: 'Artwork',
    purpose: 'Thumbnail',
    targetId: artworkId,
    fileId,
    meta: {
      size,
    },
  });
};

export const getAspectArtworkImageSrc = (
  size: 'thumbnail-small' | 'thumbnail-large' | 'medium' | 'large' | undefined,
  artworkId?: Maybe<number>,
  fileId?: Maybe<number>
): string | undefined => {
  if (!artworkId || !fileId || !size) {
    return undefined;
  }
  return getCDNImageUrl({
    target: 'Artwork',
    purpose: 'Primary',
    targetId: artworkId,
    fileId,
    meta: {
      size,
    },
  });
};

const imagesHooks = {
  getProfileAvatarSrc,
  getSquareArtworkImageSrc,
  getAspectArtworkImageSrc,
};
export default imagesHooks;
