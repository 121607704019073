// eslint-disable-next-line import/no-extraneous-dependencies
import { combineReducers } from 'redux';
import { homeFeaturedCollectionsSlice } from 'stacks/HomeV2/components/featured/store/featuredSlice';
import { collectedArtworksSlice } from 'stacks/HomeV2/components/collected/store/collectedSlice';
import favoritesModifierSlice from '../favoritesSlice';
import { artistCardsSlice } from './artistCards';
import { artworkCardsSlice } from './artworkCards';
import { recognitionsSlice } from './recognitions';
import { prizeBadgesSlice } from './prizeBadges';
import { collectedArtworkChipInfoSlice } from './collectedArtworkInfoChips';
import { artworkSwipeContainerSlice } from './artworkSwipeContainer';
import { favoriteModalCardsSlice } from './favoriteModalCards';
import { appearanceSettingsSlice } from './appearanceSettings';
import { showcaseCache } from './showcaseCache';
import { artistProfileArtworkCardsSlice } from './artistProfileArtworkCards';
import { artistProfileArtworkFavoritesCountSlice } from './artistProfileArtworkFavoritesCount';
import { galleryCardsSlice } from './galleryCards';
import { recognizeModalCardsSlice } from './recognizeModalCards';
import { artistManageArtworkCardsSlice } from './artistManageArtworkCards';
import { artShowsSlice } from './artShows';
import { notificationsSlice } from './notifications';
import { artworkCollectedBySlice } from './artworkCollectedBy';
import { shopArtworkCardsSlice } from './shopArtworkCards';

export const entityActions = {
  ...artistCardsSlice.actions,
  ...galleryCardsSlice.actions,
  ...recognitionsSlice.actions, // todo : update logic around the app to use this slice insteaf of recogsmodifiers slice
  ...artworkCardsSlice.actions,
  ...favoritesModifierSlice.actions, // todo : move favs to entities too like recognitiones and update logic around the app
  ...prizeBadgesSlice.actions,
  ...collectedArtworkChipInfoSlice.actions,
  ...artworkSwipeContainerSlice.actions,
  ...favoriteModalCardsSlice.actions,
  ...recognizeModalCardsSlice.actions,
  ...appearanceSettingsSlice.actions,
  ...showcaseCache.actions,
  ...artistProfileArtworkCardsSlice.actions,
  ...artistProfileArtworkFavoritesCountSlice.actions,
  ...artistManageArtworkCardsSlice.actions,
  ...notificationsSlice.actions,
  ...artworkCollectedBySlice.actions,
  ...artShowsSlice.actions,
  ...homeFeaturedCollectionsSlice.actions,
  ...shopArtworkCardsSlice.actions,
  ...collectedArtworksSlice.actions,
};

export const entitiesSlice = {
  entities: combineReducers({
    [artShowsSlice.name]: artShowsSlice.reducer,
    [artistCardsSlice.name]: artistCardsSlice.reducer,
    [galleryCardsSlice.name]: galleryCardsSlice.reducer,
    [recognitionsSlice.name]: recognitionsSlice.reducer,
    [artworkCardsSlice.name]: artworkCardsSlice.reducer,
    [prizeBadgesSlice.name]: prizeBadgesSlice.reducer,
    [collectedArtworkChipInfoSlice.name]: collectedArtworkChipInfoSlice.reducer,
    [artworkSwipeContainerSlice.name]: artworkSwipeContainerSlice.reducer,
    [favoriteModalCardsSlice.name]: favoriteModalCardsSlice.reducer,
    [recognizeModalCardsSlice.name]: recognizeModalCardsSlice.reducer,
    [appearanceSettingsSlice.name]: appearanceSettingsSlice.reducer,
    [showcaseCache.name]: showcaseCache.reducer,
    [artistProfileArtworkCardsSlice.name]: artistProfileArtworkCardsSlice.reducer,
    [artistProfileArtworkFavoritesCountSlice.name]: artistProfileArtworkFavoritesCountSlice.reducer,
    [artistManageArtworkCardsSlice.name]: artistManageArtworkCardsSlice.reducer,
    [notificationsSlice.name]: notificationsSlice.reducer,
    [artworkCollectedBySlice.name]: artworkCollectedBySlice.reducer,
    [shopArtworkCardsSlice.name]: shopArtworkCardsSlice.reducer,
    [homeFeaturedCollectionsSlice.name]: homeFeaturedCollectionsSlice.reducer,
    [collectedArtworksSlice.name]: collectedArtworksSlice.reducer,
  }),
};
