import { SnackbarOrigin } from '@material-ui/core';
import { Color } from '@material-ui/lab/Alert';
import { useReducer } from 'react';

type ToastProviderStateType = {
  message: string;
  duration: number;
  severity: Color;
  position: SnackbarOrigin;
  infoToastImage: React.ReactNode;
  open: boolean;
  className?: string;
};

const initialState: ToastProviderStateType = {
  message: '',
  duration: 1500,
  severity: 'success',
  position: { horizontal: 'center', vertical: 'bottom' },
  infoToastImage: null,
  open: false,
  className: undefined,
};

type ActionType = 'OPEN' | 'CLOSE';

const actionHandler: (
  state: ToastProviderStateType,
  action: {
    type: ActionType;
    payload?: Partial<ToastProviderStateType>;
  }
) => ToastProviderStateType = (state, action) => {
  if (action.type === 'CLOSE' || !action.payload) {
    return {
      ...state,
      open: false,
    };
  }
  return {
    ...state,
    open: action.payload.open || false,
    message: action.payload.message || '',
    severity: action.payload.severity || 'success',
    duration: action.payload.duration || 1500,
    infoToastImage: action.payload.infoToastImage || null,
    position: action.payload.position || { horizontal: 'center', vertical: 'bottom' },
    className: action.payload.className,
  };
};

const toastProviderReducer = (
  state: ToastProviderStateType,
  action: { type: ActionType; payload?: Partial<ToastProviderStateType> }
) => actionHandler(state, action);

export const useToastProviderReducer = () => useReducer(toastProviderReducer, initialState);
