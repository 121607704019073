import { getDefaultSortOption } from 'common/features/Filters/predefined/SortGroup/store/recent.defaults';
import { SortOptionsEnum } from 'common/features/VirtualList/VirtualList.config';
import { RecognitionsFiltersState } from './filter.types';

export enum RecognitionsFiltersEnum {
  ALL = 'ALL',
  ARTIST = 'ARTIST',
  COLLECTOR = 'COLLECTOR',
}

export enum RecognitionsTypeFiltersEnum {
  FOLLOWING = 'FOLLOWING',
  FOLLOWERS = 'FOLLOWERS',
}

export const getDefaultFilters = (): RecognitionsFiltersState => ({
  ...getDefaultSortOption(SortOptionsEnum.MOST_RECENT),
  chosenFilter: RecognitionsFiltersEnum.ALL,
});

export const filterInitialState: RecognitionsFiltersState = getDefaultFilters();
