import { createSlice } from '@reduxjs/toolkit';

type InitialStateType = {
  isOpen: boolean;
};

const initialState: InitialStateType = {
  isOpen: false,
};

export const artworkDetailsSidebarSlice = createSlice({
  name: 'artworkDetailsSidebar',
  initialState,
  reducers: {
    OPEN_ARTWORK_DETAILS: (state) => {
      state.isOpen = true;
    },
    CLOSE_ARTWORK_DETAILS: (state) => {
      state.isOpen = false;
    },
  },
});

export const { actions } = artworkDetailsSidebarSlice;
