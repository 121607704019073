import { useUserPreferredLanguage } from 'common/hooks/useUserPreferredLanguage';
import { useGetKaleidoTagsLibraryQuery } from 'common/schema/commonSchemaRemoteGraphqlQueries';
import { useMemo } from 'react';
import useApolloErrorHandler from 'common/features/ErrorHandling/hooks/useApolloErrorHandler';
import {
  mapArtworkAITagsWithLanguage,
  normalizeArtworkAITags,
} from '../../../PublishArtwork/utils/publishArtworkUtils';
import {
  AIArtworkTagsUI,
  CategoryTag,
  Enum_UI_AI_Category_Tags_Enum,
} from '../../../../../../../../common/components/TagsLibraryDialog/utils/types';

type TagsLibraryHook = {
  skipProcessingCategorizedTags?: boolean;
  skipProcessingTranslatedCategorizedTags?: boolean;
};
const useTagsLibrary = (
  skip: TagsLibraryHook = { skipProcessingCategorizedTags: false, skipProcessingTranslatedCategorizedTags: false }
) => {
  const { skipProcessingCategorizedTags, skipProcessingTranslatedCategorizedTags } = skip;
  const preferredLanguage = useUserPreferredLanguage({ shouldFallbackToNavigator: true });
  const { data, loading, error } = useGetKaleidoTagsLibraryQuery({
    fetchPolicy: 'network-only',
  });
  useApolloErrorHandler(error);

  const { categorizedTags, translatedCategorizedTags, rawTagsLibrary } = useMemo(() => {
    if (loading) {
      return {
        categorizedTags: {},
        translatedCategorizedTags: [],
      };
    }

    const tags = data?.Kaleido_general_settings?.[0]?.data;
    if (!tags) {
      return {
        categorizedTags: {} as AIArtworkTagsUI,
        translatedCategorizedTags: [] as CategoryTag[],
      };
    }

    return {
      categorizedTags: skipProcessingCategorizedTags ? ({} as AIArtworkTagsUI) : normalizeArtworkAITags(tags),
      translatedCategorizedTags: skipProcessingTranslatedCategorizedTags
        ? ([] as CategoryTag[])
        : mapArtworkAITagsWithLanguage(tags, preferredLanguage, true),
      rawTagsLibrary: tags,
    };
  }, [loading, data, preferredLanguage]);

  const fetchTagsFromCategory = (category: Enum_UI_AI_Category_Tags_Enum) =>
    translatedCategorizedTags.find((tag) => tag.categoryTagEnum === category)?.tagList || [];

  return {
    isDataLoading: loading,
    fetchTagsFromCategory,
    translatedCategorizedTags,
    categorizedTags,
    rawTagsLibrary,
  };
};

export default useTagsLibrary;
