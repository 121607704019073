import { getDefaultBoostSkewFilter } from 'common/features/Filters/predefined/BoostSkew/store/boostSkew.defaults';
import { getDefaultCountryFilter } from 'common/features/Filters/predefined/Country/store/country.defaults';
import { getDefaultInterestFilter } from 'common/features/Filters/predefined/Interests/store/interests.defaults';
import { getDefaultPriceRangeFilter } from 'common/features/Filters/predefined/PriceRange/store/priceRange.defaults';
import { getDefaultRefreshFilter } from 'common/features/Filters/predefined/Refresh/refresh.defaults';
import { getDefaultTitleFilter } from 'common/features/Filters/predefined/Title/store/title.defaults';
import { HomeArtistsFiltersState } from './filter.types';

export const getDefaultFilters = (): HomeArtistsFiltersState => ({
  sort: 'byDate',
  ...getDefaultInterestFilter(),
  ...getDefaultPriceRangeFilter(),
  ...getDefaultCountryFilter(),
  ...getDefaultTitleFilter(),
  ...getDefaultRefreshFilter(),
  ...getDefaultBoostSkewFilter(),
});

export const filterInitialState: HomeArtistsFiltersState = getDefaultFilters();
