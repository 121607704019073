import { Theme, makeStyles } from '@material-ui/core';
import { TABLET_BREAKPOINT } from 'common/utils/constants';

export const useRatingStarsTextStyles = makeStyles<
  Theme,
  {
    isDarkMode?: boolean;
  }
>((theme) => ({
  container: {
    width: '100%',
    display: 'flex',
    gap: '6px',
    alignItems: 'center',
  },
  ratingText: ({ isDarkMode }) => ({
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '22px',
    color: isDarkMode ? 'white' : 'black',
  }),
  infoIconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  infoIcon: {
    marginLeft: '6px',
    width: '20px',
    height: '20px',
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      width: '20px',
      height: '20px',
    },
  },
}));
