import type React from 'react';

export const SENTRY_DNS: string = process.env.REACT_APP_SENTRY_DNS || '';
export const PUBLIC_URL: string = process.env.REACT_APP_PUBLIC_URL || '';
export const SKIP_PREFLIGHT_CHECK: string = process.env.SKIP_PREFLIGHT_CHECK || '';
export const FIREBASE_API_KEY: string = process.env.REACT_APP_FIREBASE_API_KEY || '';
export const FIREBASE_AUTH_DOMAIN: string = process.env.REACT_APP_FIREBASE_AUTH_DOMAIN || '';
export const FIREBASE_PROJECT_ID: string = process.env.REACT_APP_FIREBASE_PROJECT_ID || '';
export const FIREBASE_MESSAGING_SENDER_ID: string = process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID || '';
export const FIREBASE_APP_ID: string = process.env.REACT_APP_FIREBASE_APP_ID || '';
export const HASURA_READER_HTTP_ENDPOINT: string = process.env.REACT_APP_HASURA_READONLY_HTTP_ENDPOINT || '';
export const HASURA_HTTP_ENDPOINT: string = process.env.REACT_APP_HASURA_HTTP_ENDPOINT || '';
export const HASURA_WSS_ENDPOINT: string = process.env.REACT_APP_HASURA_WSS_ENDPOINT || '';
export const STORAGE_LINK: string = process.env.REACT_APP_STORAGE_LINK || '';
export const BACKEND_NODE: string = process.env.REACT_APP_BACKEND_NODE || '';
export const ANALYTICS_LINK: string = process.env.REACT_APP_ANALYTICS_LINK || '';
export const GAMIFICATION_URL: string = process.env.REACT_APP_GAMIFICATION_URL || '';
export const CSC_KEY: string = process.env.REACT_APP_CSC_KEY || '';
export const ENV_TYPE: string = process.env.REACT_APP_ENV_TYPE || 'local';
export const GOOGLE_ANALYTICS_ID: string = process.env.REACT_APP_GOOGLE_ANALYTICS_ID || '';
export const FIREBASE_STORAGE_BUCKET: string = process.env.REACT_APP_FIREBASE_STORAGE_BUCKET || '';
export const URL_LINK: string = process.env.REACT_APP_HOST || '';
/** True if the app was built for custom-domains (specific for 1 artist, different url paths) */
export const IS_CUSTOM_DOMAINS_APP: boolean = process.env.REACT_APP_KDOMAIN_CHECK === 'true';
export const FIREBASE_DATABASE_URL: string = process.env.REACT_APP_FIREBASE_DATABASE_URL || '';
export const KALEIDO_URL: string = process.env.REACT_APP_KALEIDO_URL || '';
export const PUBNUB_PUBLISH_KEY: string = process.env.REACT_APP_PUBNUB_PUBLISH_KEY || '';
export const PUBNUB_SUBSCRIBE_KEY: string = process.env.REACT_APP_PUBNUB_SUBSCRIBE_KEY || '';
export const PUBNUB_SECRET_KEY: string = process.env.REACT_APP_PUBNUB_SECRET_KEY || '';
export const AB_TESTING_CLIENT_KEY: string = process.env.REACT_APP_STATSIG_AB_TESTING || '';
export const CDNOrigin: string = process.env.REACT_APP_CDN_ORIGIN || '';
export const CDNBaseUrl: string = `${CDNOrigin}/CDN` || '';
export const CDNUrl: string = process.env.REACT_APP_CDN_URL || '';
export const RELEASE: string | undefined = process.env.REACT_APP_RELEASE || undefined;
export const INITIAL_HISTORY_LENGTH = window?.history?.length ?? 0;
export const SUPPORT_EMAIL = 'hello@kaleido.art';
export const GOOGLE_CLIENT_ID: string = process.env.REACT_APP_GOOGLE_CLIENT_ID || '';

export enum HasuraRole {
  USER = 'user',
  ANONYMOUS = 'anonymous',
}

export const accessCookieName = 'app-access';
export enum variants {
  contained = 'contained',
  outlined = 'outlined',
}

export const termsOfUseUrl = 'https://www.termsfeed.com/live/0311bd69-8e12-432a-95f0-614556066769';
export const privacyPolicyUrl = 'https://www.termsfeed.com/live/37733623-54f9-41ba-8e56-233b888ab1ab';
export const IOSAppUrl = 'https://apps.apple.com/app/id1453006620';
export const androidAppUrl = 'https://play.google.com/store/apps/details?id=art.kaleido';
export const kaleidoAppUrl = 'https://www.kaleido.art';
export const kaleidoInstagramUrl = 'https://www.instagram.com/kaleido/';
export const MAX_IMAGE_FILE_SIZE_MB = 20;
export const MAX_ARTWORK_IMAGE_FILE_SIZE_MB = 35;
export const KALEIDO_AR_MOBILE_STORE_PAGE = 'https://bit.ly/3Az4OBM';
export const MY_STUDIO_MARKETING_WEBSITE = 'https://www.mystudio.art/';

export const MINIMUM_VUFORIA_RATING = 1;

export const DISABLE_CHAT_ABOVE_THIS_DATE = '2024-09-29';

export const noTapHighlightCSSProps: React.CSSProperties = {
  '-webkit-tap-highlight-color': 'transparent',
} as React.CSSProperties;
