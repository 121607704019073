import { getDefaultMinMaxPriceFilter } from './store/minMaxPrice.defaults';
import { MinMaxPriceFilterState } from './store/minMaxPrice.types';

export const minMaxPriceFilterHash = (options: MinMaxPriceFilterState): string => [options.min, options.max].join('.');

const SERIALIZATION_KEY = 'price';

export const minMaxPriceFilterSerialize = (options: MinMaxPriceFilterState): string => {
  const serialized = new URLSearchParams();
  serialized.set(SERIALIZATION_KEY, JSON.stringify([options.min, options.max]));
  return serialized.toString();
};

export const minMaxPriceFilterDeserialize = (serializedFilters: string): MinMaxPriceFilterState => {
  const serialized = new URLSearchParams(serializedFilters);
  const priceOptions = serialized.get(SERIALIZATION_KEY);
  const minMaxPriceFilter = getDefaultMinMaxPriceFilter();
  if (!priceOptions) {
    return minMaxPriceFilter;
  }
  try {
    const parsedPrice = JSON.parse(priceOptions);
    if (!Array.isArray(parsedPrice)) {
      return minMaxPriceFilter;
    }
    minMaxPriceFilter.min = parsedPrice[0];
    minMaxPriceFilter.max = parsedPrice[1];
  } catch (e) {
    console.warn('Failed to parse price filter', e);
  }
  return minMaxPriceFilter;
};
