import { combineReducers } from 'redux';
import { profileGalleryListSlice } from './list.slice';
import { profileGalleryFiltersSlice } from './filter.slice';
import { profileGalleryDisplaySlice } from './display.slice';

export const profileGalleryActions = {
  ...profileGalleryListSlice.actions,
  ...profileGalleryFiltersSlice.actions,
  ...profileGalleryDisplaySlice.actions,
};

export const profileGallerySlice = {
  profileGallery: combineReducers({
    [profileGalleryListSlice.name]: profileGalleryListSlice.reducer,
    [profileGalleryFiltersSlice.name]: profileGalleryFiltersSlice.reducer,
    [profileGalleryDisplaySlice.name]: profileGalleryDisplaySlice.reducer,
  }),
};
