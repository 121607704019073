import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GetGalleryInfoByHandleQuery } from 'common/schema/commonSchemaRemoteOperationTypes';
import useAppSelector from 'common/hooks/useAppSelector';
import { ArtistProfileCardType } from 'common/utils/types';

type galleriesProfileSliceType = {
  galleryInfo: GetGalleryInfoByHandleQuery;
  artists: ArtistProfileCardType[];
};

const initialState: galleriesProfileSliceType = {
  galleryInfo: {
    Context: [],
  },
  artists: [],
};

const galleriesProfileSlice = createSlice({
  name: 'galleriesProfile',
  initialState,
  reducers: {
    LOAD_GALLERY_INFO: (state, action: PayloadAction<Pick<galleriesProfileSliceType, 'galleryInfo'>>) => ({
      ...state,
      galleryInfo: { ...action.payload.galleryInfo },
    }),
    LOAD_GALLERY_ARTISTS: (state, action: PayloadAction<Pick<galleriesProfileSliceType, 'artists'>>) => ({
      ...state,
      artists: [...state.artists, ...action.payload.artists],
    }),
    RESET_GALLERY_ARTISTS: (state) => {
      state.artists = [];
    },
  },
});

export const useGalleriesProfile = () => useAppSelector((state) => state.galleriesProfile);

export const galleriesProfileActions = galleriesProfileSlice.actions;

export default galleriesProfileSlice;
