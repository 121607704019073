import { CircularProgress } from '@material-ui/core';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import SvgGradientColor from './SvgGradientColor';
import { useRatingProgressCircleStyles } from './RatingProgressCircle.styles';
import { RatingScoreType } from '../../utils/types';

const RatingProgressCircle: React.FC<RatingScoreType> = ({
  score,
  maxScore = 5,
  arIconContainerOverrideClassName,
  iconImage,
  isDarkMode,
}) => {
  const classes = useRatingProgressCircleStyles({ isDarkMode });
  const progressValue = ((score ?? 0) * 100) / maxScore;
  const hasScore = useMemo(() => (score ? score > 0 : false), [score]);
  return (
    <div>
      <div className={clsx(classes.arIconContainer, classes.circleBackground, arIconContainerOverrideClassName)}>
        <SvgGradientColor />
        <CircularProgress
          variant="determinate"
          value={progressValue}
          size="100%"
          thickness={3}
          className="score"
          classes={{ circle: classes.circleProgress }}
        />
        <img className={hasScore ? classes.arIcon : classes.noArIcon} src={iconImage} alt="icon" />
      </div>
    </div>
  );
};

export default RatingProgressCircle;
