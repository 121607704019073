import React from 'react';
import { CDNUrl } from 'utils/constants';

type FollowIconVariants =
  | {
      variant: 'NONE';
      color?: 'NONE';
    }
  | {
      variant: 'PLUS';
      color: 'BLACK' | 'WHITE';
    }
  | {
      variant: 'CHECKED';
      color: 'BLACK' | 'WHITE' | 'COLOR';
    };

const iconSrcs: Record<FollowIconVariants['variant'], Record<Required<FollowIconVariants>['color'], string>> = {
  NONE: {
    BLACK: '',
    WHITE: '',
    COLOR: '',
    NONE: '',
  },
  PLUS: {
    BLACK: `${CDNUrl}/images/recognitionsButtonIcons/addRecognizeIconBlack.svg`,
    WHITE: `${CDNUrl}/images/recognitionsButtonIcons/addRecognizeIconWhite.svg`,
    COLOR: '',
    NONE: '',
  },
  CHECKED: {
    BLACK: `${CDNUrl}/images/recognitionsButtonIcons/checkedRecognizeIconBlack.svg`,
    WHITE: `${CDNUrl}/images/recognitionsButtonIcons/checkedRecognizeIconWhite.svg`,
    COLOR: `${CDNUrl}/images/recognitionsButtonIcons/activeRecognizeIcon.svg`,
    NONE: '',
  },
};

type FollowIconSize = {
  size?: React.CSSProperties['width'];
};

export type FollowIconProps = FollowIconVariants &
  FollowIconSize &
  React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>;

const FollowIcon: React.FC<FollowIconProps> = ({ variant, color, size = '20px', ...imgProps }) => {
  if (variant === 'NONE') {
    return null;
  }
  const iconSrc = iconSrcs[variant][color ?? 'NONE'];
  const iconSize = size;

  return <img src={iconSrc} draggable="false" alt="icon" width={iconSize} height={iconSize} {...imgProps} />;
};

export default FollowIcon;
