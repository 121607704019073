import React from 'react';
import clsx from 'clsx';
import CompositeImage from '../CompositeImage/CompositeImage';

type Props = {
  src?: string;
  size?: React.CSSProperties['width'];
  containerClassName?: string;
  style?: React.CSSProperties;
  hideArtUniverseBadge?: boolean;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  // TODO : go and remove all alt props from KaleidoAvatar
  // eslint-disable-next-line react/no-unused-prop-types
  alt?: string;
  className?: string;
  ref?: React.LegacyRef<HTMLDivElement>;
};

const KaleidoAvatar: React.FC<Props> = ({
  hideArtUniverseBadge,
  containerClassName,
  className,
  style,
  src,
  size,
  onClick,
  ref,
}) => {
  // TODO : go to places that need to hide art universe badge and use directly SimpleProfilePicture
  if (hideArtUniverseBadge) {
    return (
      <div className={clsx(containerClassName, className)} style={style} onClick={onClick} ref={ref}>
        <CompositeImage.SimpleProfilePicture src={src} size={size} />
      </div>
    );
  }

  return (
    <div className={clsx(containerClassName, className)} style={style} onClick={onClick} ref={ref}>
      <CompositeImage.ProfilePicture src={src} size={size} />
    </div>
  );
};

export default KaleidoAvatar;
