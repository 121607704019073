import { ROUTES } from 'routes/_definition';
import { useShouldShowFeatureFlag } from 'common/features/FeatureFlag/hooks/useFeatureFlag';
import useShowcaseLink from 'common/features/ShowcaseTransition/useShowcaseLink';
import { Maybe } from 'common/schema/commonSchemaRemoteTypes';
import { useDispatch } from 'react-redux';
import { LinkProps, useHistory } from 'react-router-dom';
import { profileToShowcaseAnimationActions } from 'store/slices/profileToShowcaseAnimationSlice';
import { createPath, createShowcasePath } from 'routes/routeUtils';

export const unknownTargetLinkProps = { to: '#' };

export const useCustomArtworkShowcaseTargetLink: (
  showcasePathname: string,
  artworkId?: Maybe<number>,
  fileId?: Maybe<number>
) => LinkProps = (pathname, artworkId, fileId) => {
  const showcaseLink = useShowcaseLink(pathname, artworkId ?? undefined, fileId ?? undefined);

  if (!showcaseLink) {
    return unknownTargetLinkProps;
  }

  return {
    to: showcaseLink,
  };
};

export const useArtworkShowcaseTargetLink: (
  artistHandle?: Maybe<string>,
  artworkSlug?: Maybe<string>,
  artworkId?: Maybe<number>,
  fileId?: Maybe<number>,
  searchParams?: string
) => LinkProps = (artistHandle, artworkSlug, artworkId, fileId, searchParams) => {
  const [shouldShowProfileV2] = useShouldShowFeatureFlag('artistProfileV2');
  const dispatch = useDispatch();
  const history = useHistory();
  const path = createShowcasePath(history.location.pathname, artworkSlug ?? '', artistHandle ?? '');
  const showcaseLink = useShowcaseLink(path, artworkId ?? undefined, fileId ?? undefined, searchParams);
  if (!artistHandle || !artworkSlug) {
    return unknownTargetLinkProps;
  }
  return {
    to: shouldShowProfileV2 ? showcaseLink : `/${artistHandle}/showcase/${artworkSlug}`,
    onClick: () => {
      dispatch(profileToShowcaseAnimationActions.SET_ANIMATION({ animation: 'SHOWCASE_SHOW_ANIMATION' }));
    },
  };
};

export const useArtistProfileTargetLink: (artistHandle?: Maybe<string>) => LinkProps = (artistHandle) => {
  const [shouldShowProfileV2] = useShouldShowFeatureFlag('artistProfileV2');

  if (!artistHandle) {
    return unknownTargetLinkProps;
  }
  return {
    to: shouldShowProfileV2 ? createPath(ROUTES.ARTIST_HANDLE, { artistHandle }) : `/${artistHandle}`,
  };
};

const cardTargetLinksPropsHooks = {
  useArtworkShowcaseTargetLink,
  useArtistProfileTargetLink,
};

export default cardTargetLinksPropsHooks;
