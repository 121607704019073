import { t } from 'i18next';
import { sortArtworksByOrder } from '../useFilter';

// TODO : FIX THIS : { id: 0, name: t('artistProfileStrings.footer.options.allSeries') }
export const defaultSeries = { id: 0, name: 'All Series' };

// TODO : FIX THIS : t('artistProfileStrings.footer.options.allYears');
export const defaultYears = 'All Years';

export type FilterOption = {
  id: number;
  desc: string;
  icon?: string;
  query: any;
  mobileDesc?: string;
  filterFnc?: (artwork: any) => boolean;
  sortFnc?: (artworks: any[]) => any[];
};

const sortByPrice = (direction: 'asc' | 'desc') => (artworks: any[]) =>
  artworks.slice().sort((a, b) => {
    if ([null, undefined].includes(a.Artwork_prices[0]?.price)) return 1;
    if ([null, undefined].includes(b.Artwork_prices[0]?.price)) return -1;
    return (direction === 'desc' ? 1 : -1) * (a.Artwork_prices[0]?.price - b.Artwork_prices[0]?.price);
  });

const sortByOrder = () => (artworks: any[]) => artworks.slice().sort(sortArtworksByOrder);

/** ************** Filter Functions ******************* */

const filterByOrientation = {
  all: (_: any) => true,
  portrait: (artwork: any) => artwork.artwork_details.height > artwork.artwork_details.width,
  landscape: (artwork: any) => artwork.artwork_details.height < artwork.artwork_details.width,
  square: (artwork: any) => artwork.artwork_details.height === artwork.artwork_details.width,
};

export const availabilityOptions: FilterOption[] = [
  {
    id: 0,
    desc: t('artistProfileStrings.footer.options.availabilityOptions.all'),
    icon: '',
    query: { is_available_sale: {} },
  },
  {
    id: 1,
    desc: t('artistProfileStrings.footer.options.availabilityOptions.available'),
    icon: '',
    query: { is_available_sale: { _eq: true } },
  },
  {
    id: 2,
    desc: t('artistProfileStrings.footer.options.availabilityOptions.sold'),
    icon: '',
    query: { is_available_sale: { _eq: false } },
  },
];

export const defaultAllOptions: FilterOption[] = [
  {
    id: 0,
    desc: t('artistProfileStrings.footer.options.allOption'),
    icon: '',
    query: { is_available_sale: {} },
  },
];
export const orientationOptions: FilterOption[] = [
  {
    id: 0,
    desc: t('artistProfileStrings.footer.options.orientationOptions.allOrientations'),
    mobileDesc: t('artistProfileStrings.footer.options.orientationOptions.allOrientations'),
    query: {},
    filterFnc: filterByOrientation.all,
  },
  {
    id: 1,
    desc: t('artistProfileStrings.footer.options.orientationOptions.portrait'),
    icon: '/images/artistProfileFooterIcons/filters/portrait.svg',
    query: {},
    filterFnc: filterByOrientation.portrait,
  },
  {
    id: 2,
    desc: t('artistProfileStrings.footer.options.orientationOptions.landscape'),
    icon: '/images/artistProfileFooterIcons/filters/landscape.svg',
    query: {},
    filterFnc: filterByOrientation.landscape,
  },
  {
    id: 3,
    desc: t('artistProfileStrings.footer.options.orientationOptions.square'),
    icon: '/images/artistProfileFooterIcons/filters/square.svg',
    query: {},
    filterFnc: filterByOrientation.square,
  },
];

const getOrderSelectionOption = (type: 'ARTIST' | 'COLLECTOR'): FilterOption => ({
  id: 0,
  desc:
    type === 'ARTIST'
      ? t('profileFilterStrings.options.sort.artistSelection.desktop')
      : t('profileFilterStrings.options.sort.artistSelection.desktop.collectorSelection.desktop'),
  query: {},
  sortFnc: sortByOrder(),
});

const commonOptions: FilterOption[] = [
  {
    id: 1,
    desc: t('profileFilterStrings.options.sort.mostRecent.desktop'),
    mobileDesc: t('profileFilterStrings.options.sort.mostRecent.mobile'),
    icon: '/images/most_recent.svg',
    query: { artwork_details: { creation_date_year: 'desc' } },
  },
  {
    id: 2,
    desc: t('profileFilterStrings.options.sort.leastRecent.desktop'),
    mobileDesc: t('profileFilterStrings.options.sort.mostRecent.desktop.leastRecent.mobile'),
    icon: '/images/calendar.svg',
    query: { artwork_details: { creation_date_year: 'asc' } },
  },
  {
    id: 3,
    desc: t('profileFilterStrings.options.sort.largestSize.desktop'),
    mobileDesc: t('profileFilterStrings.options.sort.mostRecent.desktop.largestSize.mobile'),
    icon: '/images/largest.svg',
    query: { artwork_details: { height: 'desc' } },
  },
  {
    id: 4,
    desc: t('profileFilterStrings.options.sort.smallestSize.desktop'),
    mobileDesc: t('profileFilterStrings.options.sort.smallestSize.mobile'),
    icon: '/images/smallest.svg',
    query: { artwork_details: { height: 'asc' } },
  },
  {
    id: 5,
    desc: t('profileFilterStrings.options.sort.mostExpensive.desktop'),
    mobileDesc: t('profileFilterStrings.options.sort.mostExpensive.mobile'),
    icon: '/images/3dollar.svg',
    query: {},
    sortFnc: sortByPrice('asc'),
  },
  {
    id: 6,
    desc: t('profileFilterStrings.options.sort.leastExpensive.desktop'),
    mobileDesc: t('profileFilterStrings.options.sort.leastExpensive.mobile'),
    icon: '/images/1dollar.svg',
    query: {},
    sortFnc: sortByPrice('desc'),
  },
];

export const orderOptions: FilterOption[] = [getOrderSelectionOption('ARTIST'), ...commonOptions];

export const collectorOrderOptions: FilterOption[] = [getOrderSelectionOption('COLLECTOR'), ...commonOptions];

export const defaultOrientation = orientationOptions[0];
