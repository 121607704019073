import { createSlice } from '@reduxjs/toolkit';
import { selectOptionFromAppliedModifier } from 'common/features/FilteredCollectionsList/hooks/useFilterUtils';
import {
  getListModifierDefaults,
  listModifierDefaultFunctions,
  ListModifierDefaultsParams,
} from 'common/features/FilteredCollectionsList/config/defaults';
import {
  AppliedListingModifier,
  filterTypes,
  FilteredCollectionsQueryHandlers,
  BaseOptionValueType,
  isOptionType,
  COLLECTION_QUERY_CONDITION,
  COLLECTION_QUERY_CONDITION_IN,
  FILTERS_DEFAULT_VALUE,
} from 'common/features/FilteredCollectionsList/config/types';
import { GetFeaturedCollectionsQuery } from 'common/schema/commonSchemaRemoteOperationTypes';

type ListStateType = 'READY' | 'FETCH' | 'INITIALIZE' | 'LOADING';

export enum CollectionsListType {
  COLLECTIONS = 'COLLECTIONS',
}

type ListModifierType<T extends GetFeaturedCollectionsQuery['Artist_Collection'][0]> = {
  identifier: string;
  collections: T[];
  listState: ListStateType;
  filters: { [filterKey in filterTypes]: AppliedListingModifier };
  queryChanged: number;
  filterChanged: number;
  hasModifiersApplied: boolean;
  allCollections: T[];
};

type listModifiersSliceType = {
  [listTypeKey in CollectionsListType]: ListModifierType<
    FilteredCollectionsQueryHandlers[listTypeKey]['queryArtworkType']
  >;
} & {
  showCasing: CollectionsListType | null;
};

const listTypeDefaultState: <T extends CollectionsListType>(
  params: ListModifierDefaultsParams
) => ListModifierType<FilteredCollectionsQueryHandlers[T]['queryArtworkType']> = (params) => ({
  identifier: '',
  collections: [],
  listState: 'READY',
  filters: getListModifierDefaults(params),
  priceRange: { min: 0, max: 0 },
  hasModifiersApplied: false,
  filterChanged: 0,
  queryChanged: 0,
  allCollections: [],
});

const getInitialState: () => listModifiersSliceType = () => ({
  [CollectionsListType.COLLECTIONS]: listTypeDefaultState<CollectionsListType.COLLECTIONS>({
    variant: CollectionsListType.COLLECTIONS,
  }),
  showCasing: CollectionsListType.COLLECTIONS,
});

type SetCollectionListType = {
  type: CollectionsListType.COLLECTIONS;
  collections: listModifiersSliceType[CollectionsListType.COLLECTIONS]['collections'];
};
type SetAllCollectionsListType = {
  type: CollectionsListType.COLLECTIONS;
  allCollections: listModifiersSliceType[CollectionsListType.COLLECTIONS]['collections'];
};

const collectionsListModifiersSlice = createSlice({
  name: 'collectionsListModifiers',
  initialState: getInitialState(),
  reducers: {
    INITIALIZE_TYPE: (
      state,
      action: {
        payload: {
          type: CollectionsListType;
          identifier: string;
        };
      }
    ) => {
      const { type, identifier } = action.payload;
      if (!identifier || identifier === state[type].identifier) {
        return state;
      }

      return {
        ...state,
        [type]: {
          ...listTypeDefaultState({
            variant: type,
          }),
          identifier,
          listState: 'INITIALIZE',
        },
        showCasing: type,
      };
    },
    ADD_CUSTOM_OPTIONS: (
      state,
      action: {
        payload: {
          type: CollectionsListType;
          sortCollectionId?: number;
        };
      }
    ) => {
      const { type, sortCollectionId } = action.payload;
      const workingState = state[type];

      if (sortCollectionId) {
        workingState.filters = {
          ...workingState.filters,
          SORT: listModifierDefaultFunctions.SORT(sortCollectionId, type),
        };
      }
      return state;
    },
    UPDATE_SELECTED_OPTIONS: (
      state,
      action: {
        payload: {
          type: CollectionsListType;
          options: {
            key: filterTypes;
            value: BaseOptionValueType;
          }[];
        };
      }
    ) => {
      const { type, options } = action.payload;
      if (state[type].listState !== 'READY') {
        return state;
      }
      const workingState = state[type];
      let queryChanged = false;
      let filterChanged = false;

      options.forEach((selectedOption) => {
        const { key, value } = selectedOption;
        const affectedModifier = workingState.filters[key];
        const newSelectedOptions = selectOptionFromAppliedModifier(affectedModifier, value);

        const affectedOption = affectedModifier.allOptions.find((option) => option.value === value);
        if (
          affectedOption &&
          (isOptionType<COLLECTION_QUERY_CONDITION>(affectedOption, 'COLLECTION_QUERY_CONDITION') ||
            isOptionType<COLLECTION_QUERY_CONDITION_IN>(affectedOption, 'COLLECTION_QUERY_CONDITION_IN'))
        ) {
          queryChanged = true;
        }
        filterChanged = true;

        const newAppliedListingModifierState: AppliedListingModifier = {
          ...affectedModifier,
          selectedOptions: newSelectedOptions,
        };

        workingState.filters = {
          ...workingState.filters,
          [key]: newAppliedListingModifierState,
        };
      });

      workingState.hasModifiersApplied = !!Object.values(workingState.filters).find(
        (modifier) => !modifier.selectedOptions.includes(FILTERS_DEFAULT_VALUE)
      );

      if (queryChanged) {
        workingState.queryChanged += 1;
      }
      if (queryChanged || filterChanged) {
        workingState.filterChanged += 1;
      }
      state[type] = { ...workingState } as any;
      return state;
      // { ...state, [type]: { ...workingState } };
    },
    REFRESH: (
      state,
      {
        payload: { type },
      }: {
        payload: {
          type: CollectionsListType;
        };
      }
    ) => {
      state[type].queryChanged += 1;
      return state;
    },
    UPDATE_LIST_STATE: (
      state,
      action: {
        payload: {
          type: CollectionsListType;
          newState: ListStateType;
        };
      }
    ) => ({
      ...state,
      [action.payload.type]: {
        ...state[action.payload.type],
        listState: action.payload.newState,
      },
    }),
    SET_COLLECTIONS: (
      state,
      action: {
        payload: SetCollectionListType;
      }
    ) => ({
      ...state,
      [action.payload.type]: {
        ...state[action.payload.type],
        collections: action.payload.collections,
      },
    }),
    SET_ALL_COLLECTIONS: (
      state,
      action: {
        payload: SetAllCollectionsListType;
      }
    ) => ({
      ...state,
      [action.payload.type]: {
        ...state[action.payload.type],
        allCollections: action.payload.allCollections,
      },
    }),
  },
});

export const collectionsListActions = collectionsListModifiersSlice.actions;

export default collectionsListModifiersSlice;
