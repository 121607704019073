import { combineReducers } from '@reduxjs/toolkit';
import { listArtistManageArtworksSlice } from './listArtistManageArtworks.slice';
import { filterArtistManageArtworksSlice } from './filterArtistManageArtworks.slice';
import { displayArtistManageArtworksSlice } from './displayArtistManageArtworks.slice';

export const actions = {
  ...listArtistManageArtworksSlice.actions,
  ...filterArtistManageArtworksSlice.actions,
  ...displayArtistManageArtworksSlice.actions,
};

export const artistManageArtworksSlice = {
  artistManageArtworks: combineReducers({
    [listArtistManageArtworksSlice.name]: listArtistManageArtworksSlice.reducer,
    [filterArtistManageArtworksSlice.name]: filterArtistManageArtworksSlice.reducer,
    [displayArtistManageArtworksSlice.name]: displayArtistManageArtworksSlice.reducer,
  }),
};
