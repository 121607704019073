import { ArtistInfoChip, ArtistInfoChipProps } from 'common/components/ArtistInfoChip/ArtistInfoChip';
import { TypographyTextType } from 'common/components/TypographyText/SkeletonText';

const defaultTextProps: [Omit<TypographyTextType, 'text'>, Omit<TypographyTextType, 'text'>] = [
  {
    noWrap: true,
    variant: 'h6',
    style: {
      fontWeight: 400,
      fontSize: '18px',
      lineHeight: '24px',
      textOverflow: 'ellipsis',
      maxWidth: '100%',
      color: '#000000',
    },
  },
  {
    noWrap: true,
    variant: 'caption',
    style: {
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '22px',
      color: 'rgba(0, 0, 0, 0.5)',
      textOverflow: 'ellipsis',
      maxWidth: '100%',
    },
  },
];

export const ArtistChip: React.FC<ArtistInfoChipProps> = ({ image, textItems = [], avatarSize }) => (
  <ArtistInfoChip
    avatarSize={avatarSize}
    image={image}
    textItems={[
      { ...defaultTextProps[0], ...textItems[0] },
      { ...defaultTextProps[1], ...textItems[1] },
    ]}
  />
);
