import { createSlice } from '@reduxjs/toolkit';

export interface LaunchedMyStudioModalState {
  isOpen: boolean;
}

const initialState: LaunchedMyStudioModalState = {
  isOpen: false,
};

const launchedMyStudioModalSlice = createSlice({
  name: 'launchedMyStudioModal',
  initialState,
  reducers: {
    OPEN_MODAL: (state) => {
      state.isOpen = true;
    },
    CLOSE_MODAL: (state) => {
      state.isOpen = false;
    },
  },
});

export const launchedMyStudioModalActions = launchedMyStudioModalSlice.actions;

export default launchedMyStudioModalSlice;
