import { filtersHash } from '../hooks/useHomeArtworksFiltersIdentifier';
import { getDefaultFilters } from './filter.defaults';
import {
  HomeArtworksListState,
  HomeArtworksListByDate,
  ListDirection,
  listingDirections,
  ListWindowLoaderStateType,
} from './list.types';

export const defaults = {
  byDate: (): {
    type: 'byDate';
    data: HomeArtworksListByDate;
  } => ({
    type: 'byDate',
    data: {
      __typename: 'byDate',
      lastScrollPosition: 0,
      detailedToListedStartOffset: 0,
      cursor: [
        {
          publishedDate: '1000-01-01T00:00:00.000000+00:00',
          artistContextId: 0,
          artworkId: 0,
          collectedByCtxId: 0,
          eventType: 'PUBLISH',
          galleryContextId: 0,
          artPrizeShowId: 0,
          aspectRatio: 0,
        },
        {
          publishedDate: new Date().toISOString(),
          artistContextId: 0,
          artworkId: 0,
          collectedByCtxId: 0,
          eventType: 'PUBLISH',
          galleryContextId: 0,
          artPrizeShowId: 0,
          aspectRatio: 0,
        },
      ],
      listed: {
        batchSize: 128,
        status: 'COLD',
        values: [],
        hasMore: [false, false],
      },
      detailed: {
        batchSize: 32,
        status: 'COLD',
        values: [],
        hasMore: [false, false],
      },
    },
  }),
};

const defaultFilters = getDefaultFilters();
export const listInitialState: HomeArtworksListState = {
  identifier: filtersHash(defaultFilters),
  ...defaults[defaultFilters.sort](),
};

export const hasMore = (loader: ListWindowLoaderStateType<unknown, unknown>, direction: ListDirection): boolean =>
  loader.hasMore[listingDirections.indexOf(direction)];
