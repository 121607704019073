import React, { PropsWithChildren } from 'react';
import { FeatureFlags, FeatureStatus } from '../config/features';
import useFeatureFlag from '../hooks/useFeatureFlag';

export interface FeatureFlagProps {
  feature: FeatureFlags;
  status?: FeatureStatus;
}

const FeatureFlag: React.FC<PropsWithChildren<FeatureFlagProps>> = ({ feature, status, children }) => {
  const [flagStatus] = useFeatureFlag(feature);

  if (flagStatus !== status) {
    return null;
  }

  return <>{children}</>;
};

const FeatureFlagActive: React.FC<PropsWithChildren<FeatureFlagProps>> = ({ feature, status = 'active', children }) => (
  <FeatureFlag feature={feature} status={status}>
    {children}
  </FeatureFlag>
);

const FeatureFlagInactive: React.FC<PropsWithChildren<FeatureFlagProps>> = ({
  feature,
  status = 'inactive',
  children,
}) => (
  <FeatureFlag feature={feature} status={status}>
    {children}
  </FeatureFlag>
);

/**
 * How to use:
 *
 * <Flagged.Active feature='my-flag'/>
 *  <MyNewFeatureComponent />
 * </Flagged.Active>
 *
 * <Flagged.Inactive feature="my-flag"/>
 *  <MyOldFeatureComponent />
 * </Flagged.Inactive>
 *
 * For flagged logic use: ../hooks/useFeatureFlag
 */
const Flagged = {
  Active: FeatureFlagActive,
  Inactive: FeatureFlagInactive,
};

export default Flagged;
