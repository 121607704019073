import { socialMediaEnum } from './socialMediaEnum';

interface SocialMediaData {
  prefix: string;
  placeholder?: string;
  pin?: string;
}

export const SOCIAL_MEDIA_DATA: {
  [key in socialMediaEnum]: SocialMediaData;
} = {
  WEBSITE: {
    prefix: 'https://',
    placeholder: 'www.yourwebsite.com',
  },
  FACEBOOK: {
    prefix: 'https://facebook.com/',
    placeholder: 'username',
  },
  INSTAGRAM: {
    prefix: 'https://instagram.com/',
    placeholder: 'username',
  },
  TWITTER: {
    prefix: 'https://twitter.com/',
    placeholder: 'username',
  },
  PINTEREST: {
    prefix: 'https://www.pinterest.com/',
    placeholder: 'username',
  },
  TIKTOK: {
    prefix: 'https://tiktok.com/',
    placeholder: '@username',
  },
  YOUTUBE: {
    prefix: 'https://youtube.com/channel/',
    placeholder: 'url',
  },
  CUSTOMLINK: {
    prefix: '',
  },
};
