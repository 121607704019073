import { TypeGuard } from 'common/utils/types';

export const generateListingCursorTypeGuard =
  <CursorType>(possibleCursorCheck: (castedCursor: CursorType) => boolean): TypeGuard<CursorType> =>
  (possibleCursor): possibleCursor is CursorType =>
    !!possibleCursor && possibleCursorCheck(possibleCursor as CursorType);
export const defaultListingCursor = [null, null];
export const getDefaultListingCursor = <CursorType>(
  backward: CursorType,
  forward: CursorType
): [CursorType, CursorType] => [backward, forward];

export const nextPage = {
  bigint: {
    bigger: Number.MIN_SAFE_INTEGER,
    smaller: Number.MAX_SAFE_INTEGER,
  },
  int: {
    bigger: -2147483648,
    smaller: 2147483647,
  },
  date: {
    past: '9999-12-31T23:59:59.999Z',
    future: '0000-01-01T00:00:00.000Z',
  },
};
export const prevPage = nextPage;
