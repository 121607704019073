import { Box } from '@material-ui/core';
import React from 'react';
import { SwipeContainerComponents } from './components';
import useGetArtworkDetails from './hooks/useGetArtworkDetails';
import { SwipeContainerReturn } from './hooks/useSwipeableContainer';
import { useSwipeInfoContainerStyles } from './SwipeInfoContainer.styles';

export type SwipeInfoContainerProps = {
  rightOffset?: number;
  artworkId?: number;
  isSquare?: boolean;
  touchProps?: SwipeContainerReturn;
};

const SwipeInfoContainer: React.FC<SwipeInfoContainerProps> = ({
  rightOffset,
  artworkId,
  touchProps,
  isSquare = true,
}) => {
  const maxRightOffset = window.innerWidth;
  useGetArtworkDetails(artworkId ?? 0, rightOffset === maxRightOffset, rightOffset);
  const classes = useSwipeInfoContainerStyles({ maxRightOffset, rightOffset, isSquare });

  return (
    <Box className={classes.wrapper}>
      <Box
        className={classes.swipeContainer}
        {...touchProps}
        style={{
          left: !rightOffset ? maxRightOffset : rightOffset,
        }}
      >
        <Box className={classes.contentWrapper}>
          <Box className={classes.contentCenter}>
            <SwipeContainerComponents.Title artworkId={artworkId ?? 0} />
            <SwipeContainerComponents.AvailabilityButton artworkId={artworkId ?? 0} />
            <SwipeContainerComponents.Details artworkId={artworkId ?? 0} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SwipeInfoContainer;
