import { createHttpLink, ServerError, split } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { createUploadLink } from 'apollo-upload-client';
import { GraphQLError, OperationDefinitionNode } from 'graphql';
import { HASURA_HTTP_ENDPOINT, HASURA_READER_HTTP_ENDPOINT, HASURA_WSS_ENDPOINT, STORAGE_LINK } from 'utils/constants';
import { onError } from '@apollo/client/link/error';
import Sentry from 'common/features/Sentry/Sentry';
import FirebaseState, { FirebaseCallbacks } from 'common/services/Auth/handlers/FirebaseHandler';
import AuthState from 'common/services/Auth/handlers/AuthHandler';
import { STATUS_WITH_JWT, updateFirebaseJWTandClaims } from 'common/services/Auth/handlers/FirebaseHandler/utils';

export const serverErrorRoutes = ['/500'];

const httpLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    const { kind, operation } = definition as OperationDefinitionNode;
    return kind === 'OperationDefinition' && operation === 'mutation';
  },
  createHttpLink({
    uri: HASURA_HTTP_ENDPOINT,
  }),
  createHttpLink({
    uri: HASURA_READER_HTTP_ENDPOINT,
  })
);

const wsLink = new WebSocketLink({
  uri: HASURA_WSS_ENDPOINT || '',
  options: {
    lazy: true,
    reconnect: true,
    connectionParams: async () => {
      if (STATUS_WITH_JWT.includes(AuthState.status) && FirebaseCallbacks.isJwtExpired()) {
        await updateFirebaseJWTandClaims();
      }
      return {
        headers: {
          authorization: `Bearer ${FirebaseState.jwt}`,
        },
      };
    },
  },
});

const uploadLinkUri = createUploadLink({
  uri: STORAGE_LINK,
});

export const authLink = setContext(async (request, { headers, ...rest }) => {
  // Update JWT if expired BEFORE sending request
  if (STATUS_WITH_JWT.includes(AuthState.status) && FirebaseCallbacks.isJwtExpired()) {
    console.log('JWT expired, updating...');
    await updateFirebaseJWTandClaims();
  }
  Sentry.client.addBreadcrumb({
    category: 'graphql',
    type: 'http',
    level: Sentry.client.Severity.Info,
    data: {
      op: request.operationName,
      vars: request.variables,
    },
  });
  const jwtToken = FirebaseState.jwt;
  const newHeaders = {
    ...headers,
    'X-Hasura-Showroom-Password': (headers && headers['X-Hasura-Showroom-Password']) || '',
    'X-Hasura-Mobile-App-Key': '',
    'X-Forwarded-Origin': window.location.host,
  };

  if (jwtToken) {
    return {
      ...rest,
      headers: {
        ...newHeaders,
        authorization: `Bearer ${jwtToken}`,
      },
    };
  }
  return {
    ...rest,
    headers: newHeaders,
  };
});

export type ReportedGQLError = {
  err: GraphQLError;
  op: string;
  vars: Record<string, unknown>;
};

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  if (networkError) {
    const op = operation.operationName;
    const vars = operation.variables;
    Sentry.client.withScope((scope) => {
      scope.addBreadcrumb({
        type: 'http',
        category: 'graphql-network-error',
        data: { __op: op, __err: networkError, ...vars },
      });
      scope.setFingerprint(['network', op, String(networkError.message)]);
      Sentry.client.captureException(
        new Error(`${op} network: ${(networkError as ServerError).statusCode} ${networkError.message}`)
      );
    });

    if (serverErrorRoutes.includes(window.location.pathname)) {
      return;
    }

    if ((networkError as ServerError).statusCode === 500) {
      window.location.href = '/500';
    }
  }

  if (graphQLErrors) {
    const op = operation.operationName;
    const vars = operation.variables;

    graphQLErrors.forEach((gqlerr) => {
      Sentry.client.withScope((scope) => {
        scope.addBreadcrumb({
          type: 'http',
          category: 'graphql-error',
          data: { __op: op, __err: gqlerr, ...vars },
        });
        scope.setFingerprint(['gql', op, gqlerr.message]);
        Sentry.client.captureException(new Error(`${op}: ${gqlerr.message}`));
      });
    });
  }
});

export const uploadLink = errorLink.concat(uploadLinkUri);

export const link = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    const { kind, operation } = definition as OperationDefinitionNode;
    return kind === 'OperationDefinition' && operation === 'subscription';
  },
  wsLink,
  authLink.concat(errorLink.concat(httpLink))
);
