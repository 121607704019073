import { createSlice } from '@reduxjs/toolkit';
import { Enum_Flags_Type_Enum } from 'common/schema/commonSchemaRemoteTypes';

export enum PermissionStatus {
  Inactive = 'Inactive',
  Declined = 'Declined',
  Active = 'Active',
  Unknown = 'Unknown',
}

export enum PermissionTypes {
  Notifications = 'Notifications',
  Location = 'Location', // TODO - Make Location permission modal use the same logic as the other permissions(it was already done and i left it as is for now)
  Camera = 'Camera',
  Microphone = 'Microphone',
}

export enum PermissionRequestResponse {
  Granted = 'Granted',
  Denied = 'Denied',
  Unknown = 'Unknown',
}
export enum NotificationModalState {
  Open = 'Open',
  Closed = 'Closed',
  Dismissed = 'Dismissed',
}
export type NotificationModals = {
  [Key in Enum_Flags_Type_Enum as string]: NotificationModalState;
};

type PermissionsState = {
  [Key in PermissionTypes as string]: {
    isOpen: boolean;
    dismissedModals: NotificationModals;
    permissionStatus: PermissionStatus;
    permissionResponse: PermissionRequestResponse;
    token: string;
    deviceId: string;
    artworkId?: number;
    artistId?: number;
  };
};

const initialState: PermissionsState = {
  Notifications: {
    isOpen: false,
    dismissedModals: {
      [Enum_Flags_Type_Enum.NotificationsDefaultModal]: NotificationModalState.Closed,
      [Enum_Flags_Type_Enum.NotificationsFavoriteModal]: NotificationModalState.Closed,
      [Enum_Flags_Type_Enum.NotificationsRecognizeModal]: NotificationModalState.Closed,
      [Enum_Flags_Type_Enum.NotificationsChatModal]: NotificationModalState.Closed,
    },
    artworkId: 0,
    artistId: 0,
    permissionStatus: PermissionStatus.Unknown,
    permissionResponse: PermissionRequestResponse.Unknown,
    token: '',
    deviceId: '',
  },
  Location: {
    isOpen: true,
    dismissedModals: {},
    permissionStatus: PermissionStatus.Inactive, // todo : switch to PermissionStatus.Unknown and test
    permissionResponse: PermissionRequestResponse.Unknown,
    token: '',
    deviceId: '',
  },
  Camera: {
    isOpen: false,
    dismissedModals: {},
    permissionStatus: PermissionStatus.Inactive, // todo : switch to PermissionStatus.Unknown and test
    permissionResponse: PermissionRequestResponse.Unknown,
    token: '',
    deviceId: '',
  },
  Microphone: {
    isOpen: false,
    dismissedModals: {},
    permissionStatus: PermissionStatus.Inactive, // todo : switch to PermissionStatus.Unknown and test
    permissionResponse: PermissionRequestResponse.Unknown,
    token: '',
    deviceId: '',
  },
};
export const permissionsStateSlice = createSlice({
  name: 'permissionsState',
  initialState,
  reducers: {
    UPDATE_PERMISSIONS_STATUS: (state, action) => {
      state[action.payload.permissionType] = {
        ...state[action.payload.permissionType],
        permissionStatus: action.payload.permissionStatus,
      };
    },
    UPDATE_PERMISSIONS_RESPONSE: (state, action) => {
      state[action.payload.permissionType] = {
        ...state[action.payload.permissionType],
        permissionResponse: action.payload.permissionResponse,
      };
    },
    UPDATE_PERMISSIONS_DATA: (state, action) => {
      state.Notifications.token = action.payload.permissionToken;
      state.Notifications.deviceId = action.payload.deviceId;
    },
    UPDATE_PERMISSIONS_DEVICE_ID: (state, action) => {
      state.Notifications.deviceId = action.payload.deviceId;
    },
    UPDATE_PERMISSIONS_MODAL_OPEN: (state, action) => {
      state[action.payload.permissionType] = {
        ...state[action.payload.permissionType],
        isOpen: action.payload.isOpen,
      };
    },
    UPDATE_NOTIFICATIONS_MODAL_STATE: (
      state,
      action: {
        payload: {
          permissionType: PermissionTypes;
          modalType: Enum_Flags_Type_Enum;
          status: NotificationModalState;
          artworkId?: number;
          artistId?: number;
        };
      }
    ) => {
      const { permissionType, modalType, status } = action.payload;
      const modalStatus = state[permissionType]?.dismissedModals?.[modalType];
      if (modalStatus === NotificationModalState.Dismissed) {
        return;
      }
      if (
        modalStatus !== undefined &&
        !(
          state[permissionType].permissionResponse !== PermissionRequestResponse.Unknown &&
          status === NotificationModalState.Open
        )
      ) {
        state[permissionType] = {
          ...state[permissionType],
          dismissedModals: {
            ...state[permissionType].dismissedModals,
            [modalType]: status,
          },
        };
      }
      state[permissionType] = {
        ...state[permissionType],
        artworkId: action.payload.artworkId ?? state[permissionType].artworkId,
        artistId: action.payload.artistId ?? state[permissionType].artistId,
      };
    },
  },
});

export const permissionsStateActions = permissionsStateSlice.actions;
export default permissionsStateSlice;
