import components from './components';
import reducers from './reducers';
import slices from './slices';
import hooks from './hooks';

const VirtualList = {
  ...components,
  reducers,
  slices,
  hooks,
};

export default VirtualList;
