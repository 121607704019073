import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Enum_Flags_Type_Enum } from 'common/schema/commonSchemaRemoteTypes';

type ContextFlagSliceType = Record<number, Partial<Record<Enum_Flags_Type_Enum, string | null | undefined>>>;

const getInitialState: () => ContextFlagSliceType = () => ({});
const initialState = getInitialState();

const contextFlagSlice = createSlice({
  name: 'contextFlag',
  initialState,
  reducers: {
    SET_CONTEXT_FLAG: (
      state,
      action: PayloadAction<{
        contextId: number;
        flag: Enum_Flags_Type_Enum;
        value: string | null | undefined;
      }>
    ) => {
      const { contextId, flag } = action.payload;
      state[contextId] = state[contextId] || {};
      state[contextId][flag] = action.payload.value;
    },
  },
});

export const contextFlagActions = contextFlagSlice.actions;
export default contextFlagSlice;
