import { countryList } from 'common/hooks/utils/countries';
import { getDefaultCountryFilter } from './store/country.defaults';
import { CountryFilterState } from './store/country.types';

export const countryFilterHash = (option: CountryFilterState['country']): string => option?.iso2 ?? 'anyc';

const SERIALIZATION_KEY = 'cntry';

export const countryFilterSerialize = (state: CountryFilterState): string => {
  const serialized = new URLSearchParams();
  serialized.set(SERIALIZATION_KEY, state.country?.iso2 ?? '');
  return serialized.toString();
};

export const countryFilterDeserialize = (serializedFilters: string): CountryFilterState => {
  const serialized = new URLSearchParams(serializedFilters);
  const countryOption = serialized.get(SERIALIZATION_KEY);
  const countryFilter = getDefaultCountryFilter();
  if (!countryOption) {
    return countryFilter;
  }
  countryFilter.country = countryList.find((country) => country.iso2 === countryOption);
  return countryFilter;
};
