import { FooterLayout } from './FooterLayout';
import { TripleImageLayout } from './TripleImageLayout';
import { ImageInfoLayout } from './ImageInfoLayout';
import { ArtworkInformation } from './ArtworkInformation';
import { ArtworkInformationMobile } from './ArtworkInformationMobile';
import { SoloSquareImage } from './SoloSquareImage';
import { SoloAspectRatioImage } from './SoloAspectRatioImage';
import { ArtCollectedImageInfoLayout } from './ArtCollectedImageInfoLayout';
import { SwipeCardTopBottomLayout } from './SwipeCardTopBottomLayout';
import { ShowcaseReelImageLayout } from './ShowcaseReelImageLayout';
import { ImageInfoLayoutMobile } from './ImageInfoLayoutMobile';
import { ArtworkInformationOverlay } from './ArtworkInformationOverlay';
import { ArtworkInformationRow } from './ArtworkInformationRow';

const ArtistCardComponents = {
  FooterLayout,
  SoloSquareImage,
  SoloAspectRatioImage,
  TripleImageLayout,
  ImageInfoLayout,
  ImageInfoLayoutMobile,
  ArtworkInformation,
  ArtworkInformationMobile,
  ArtworkInformationOverlay,
  ArtworkInformationRow,
  ArtCollectedImageInfoLayout,
  SwipeCardTopBottomLayout,
  ShowcaseReelImageLayout,
};

export default ArtistCardComponents;
