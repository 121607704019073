import { makeStyles } from '@material-ui/core';

export const useConfirmationModalStyles = makeStyles((theme) => ({
  paper: {
    maxWidth: theme.spacing(60),
    width: '100%',
    height: 'unset',
    borderRadius: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      padding: '16px',
    },
  },
  backdrop: {
    background: 'rgba(18, 17, 18, 0.8) !important',
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    alignItems: 'center',
    '& h2': {
      fontStyle: 'normal !important',
      fontWeight: 600,
      fontSize: '18px !important',
      lineHeight: '27px !important',
      color: '#121112 !important',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      [theme.breakpoints.down('xs')]: {
        fontSize: '15px !important',
        paddingLeft: '16px',
      },
    },
    '& .MuiDialogTitle-root': {
      paddingRight: 0,
      width: 'calc(100% - 100px)',
      [theme.breakpoints.down('xs')]: {
        padding: '0',
        width: 'calc(100% - 40px)',
      },
    },
  },
  content: {
    fontSize: '16px !important',
    lineHeight: '22px !important',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(4),
    display: 'inline',
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px !important',
    },
  },
  closeIcon: {
    marginRight: '24px',
    [theme.breakpoints.down('xs')]: {
      border: '1px solid rgba(0, 0, 0, 0.16)',
      borderRadius: '6px',
      padding: '4px',
      marginRight: '0px',
    },
  },
}));
