import { createSlice } from '@reduxjs/toolkit';
import { displayInitialState } from './display.defaults';

export const recognitionsDisplaySlice = createSlice({
  name: 'display',
  initialState: displayInitialState,
  reducers: {
    OPEN_FILTERS_Recognitions: (state) => {
      state.isFiltersDialogOpen = true;
    },
    CLOSE_FILTERS_Recognitions: (state) => {
      state.isFiltersDialogOpen = false;
    },
  },
});
