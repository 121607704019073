import { createTheme, makeStyles, Theme, ThemeProvider } from '@material-ui/core';
import useGetReservedScreenspace, { CSSPropsResult } from 'common/features/Screenspace/hooks/useGetReservedScreenspace';
import { KnownSpacesOrder } from 'common/features/Screenspace/store/screenspaceSlice';
import useAppSelector from 'common/hooks/useAppSelector';
import { useMemo } from 'react';
import { defaultDarkTheme, defaultLightTheme } from 'utils/customTheme';

const useGlobalStyles = makeStyles((theme) => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
      transition: 'background-color 0.3s ease-in-out',
    },
  },
}));

const UseGlobalStyles: React.FC = () => {
  useGlobalStyles();
  return null;
};

const useGlobalMarginStyles = makeStyles<Theme, CSSPropsResult>(() => ({
  '@global': {
    body: (css) => ({
      padding: css.all,
      height: `calc(100vh - ${css.top} - ${css.bottom})`,
    }),
  },
}));

const AppThemeProvider: React.FC<{ applyGlobalStyles?: boolean }> = ({ children, applyGlobalStyles = false }) => {
  const isDarkMode = useAppSelector((state) => state.darkMode.isDarkMode);
  const reservedSpaceCss = useGetReservedScreenspace({ untilOrder: KnownSpacesOrder.DashboardNavbar - 1 }).toCSSProps();
  useGlobalMarginStyles(reservedSpaceCss.withSafeArea);
  return (
    <ThemeProvider
      theme={useMemo(() => (isDarkMode ? createTheme(defaultDarkTheme) : createTheme(defaultLightTheme)), [isDarkMode])}
    >
      {applyGlobalStyles && <UseGlobalStyles />}
      {children}
    </ThemeProvider>
  );
};

export default AppThemeProvider;
