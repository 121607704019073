import { Grid } from '@material-ui/core';
import ArtistCardComponents from '../../common';

export const TopBottomLayout: React.FC<{
  children: [
    React.ReactElement<any>, // todo : fix this
    React.ReactElement<typeof ArtistCardComponents.FooterLayout>
  ];
}> = ({ children }) => (
  <Grid container direction="row" justifyContent="space-between" alignItems="center">
    <Grid item xs={12}>
      {children[0]}
    </Grid>
    <Grid item xs={12} style={{ paddingTop: '12px' }}>
      {children[1]}
    </Grid>
  </Grid>
);
