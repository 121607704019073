import { getDefaultSortOption } from 'common/features/Filters/predefined/SortGroup/store/recent.defaults';
import { SortOptionsEnum } from 'common/features/VirtualList/VirtualList.config';
import { RecognitionsModalFiltersState } from './filter.types';

export enum RecognitionsFiltersEnum {
  ALL = 'ALL',
  ARTIST = 'ARTIST',
  COLLECTOR = 'COLLECTOR',
}

export const getDefaultFilters = (): RecognitionsModalFiltersState => ({
  ...getDefaultSortOption(SortOptionsEnum.MOST_RECENT),
  chosenFilter: RecognitionsFiltersEnum.ALL,
});

export const filterInitialState: RecognitionsModalFiltersState = getDefaultFilters();
