import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { DateFilterState } from './date.types';

export type SelectDateAction = PayloadAction<DateFilterState>;

export const createSelectDateCaseReducer =
  <State extends DateFilterState>(): CaseReducer<State, SelectDateAction> =>
  (state, action) => {
    state.selectedDate = action.payload.selectedDate;
  };
