// http://localhost:8080/auth/mobileGoogleLogin?access_token=google-login-token-goes-here&state=googleLoginRequestId-12345
// http://localhost:8080/auth/mobileGoogleLogin?access_token=googleLoginRequestId-12345&state=googleLoginRequestId-12345

import { openUnityExternalLink } from 'common/hooks/useArtworkExternalBuyLink';
import { BACKEND_NODE } from 'utils/constants';

const BACKEND_GOOGLE_LOGIN_URL = `${BACKEND_NODE}/auth/mobileGoogleLogin`;

// prevent multiple intervals from running at the same time
const lastInterval = {
  cleanup: (() => undefined) as () => void,
};

const mobileGoogleLogin = {
  generateRequestId: (): string => `googleLoginRequestId-${Date.now()}-${Math.ceil(Math.random() * 1000000)}`,
  getToken: async (
    code: string,
    state: string,
    requestAttempts?: number
  ): Promise<{ token: string; encodedIntent: string | undefined }> => {
    lastInterval.cleanup();

    const fetchToken = async () => {
      const response = await fetch(`${BACKEND_GOOGLE_LOGIN_URL}?code=${code}&state=${state}`);
      const { token, encodedIntent } = (await response.json()) ?? {};
      return { token, encodedIntent };
    };

    return new Promise((resolve, reject) => {
      let attempts = requestAttempts ?? 60;
      const interval = window.setInterval(async () => {
        // prep cleanup in case of multiple calls to getToken
        lastInterval.cleanup = () => {
          clearInterval(interval);
          reject(new Error('CANCELLED'));
        };

        // update attempts counter
        attempts -= 1;

        // attempt to fetch token
        const result = await fetchToken();
        if (result.token) {
          lastInterval.cleanup = () => undefined;
          clearInterval(interval);
          resolve(result);
        }

        // ran out of attempts => reject
        if (!attempts) {
          lastInterval.cleanup = () => undefined;
          clearInterval(interval);
          reject(new Error('TOKEN_TIMEOUT'));
        }
      }, 1000); // every 2s
    });
  },
  getGoogleLoginUrl: (requestId: string): string =>
    `${BACKEND_GOOGLE_LOGIN_URL}?code=choose_account&state=${requestId}`,
};

export const externalGoogleAuth = async (
  requestId?: string,
  encodedIntent?: string
): Promise<{ token: string; encodedIntent: string | undefined } | null> => {
  const finalRequestId = requestId ?? mobileGoogleLogin.generateRequestId();
  let state = finalRequestId;
  if (encodedIntent) {
    state = `${finalRequestId}-encodedIntent-${encodedIntent}`;
  }
  if (!requestId) {
    const url = mobileGoogleLogin.getGoogleLoginUrl(state);
    openUnityExternalLink(url);
  }
  try {
    return await mobileGoogleLogin.getToken(finalRequestId, state, requestId ? 5 : 60);
  } catch (error) {
    return null;
  }
};

export default mobileGoogleLogin;
