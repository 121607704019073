import { makeStyles, Theme, Typography } from '@material-ui/core';
import { Palette } from '@material-ui/core/styles/createPalette';
import clsx from 'clsx';
import { HtmlDivProps } from 'common/utils/types';
import React from 'react';

// TODO: find a better solution for this
// possibly adding the rgba values in the themes palette
// which would make this unnecessary

const darkThemeBlacks = ['#0D0D0D', 'black', '#000000', '#000'];
export const isDarkTheme = (theme: Theme | Palette | string) => {
  let blackColor: string = theme as string;

  if ((theme as Theme)?.palette?.common?.black) {
    blackColor = (theme as Theme).palette.common.black;
  } else if ((theme as Palette)?.common?.black) {
    blackColor = (theme as Palette).common.black;
  } else if (typeof theme === 'string') {
    blackColor = theme;
  }

  return !darkThemeBlacks.includes(blackColor);
};

const useStyles = makeStyles(({ spacing, mixins, palette }) => {
  const isDark = isDarkTheme(palette);
  return {
    cardContainer: {
      padding: spacing(2),
      backgroundColor: isDark ? 'rgba(0,0,0,0.5)' : 'rgba(255,255,255,0.5)',
      borderRadius: spacing(1),
      ...mixins.flexColumn,
    },
    title: {
      color: palette.common.black,
      fontWeight: 600,
      marginBottom: spacing(2),
    },
    clickable: {
      '&:hover': {
        background: isDark ? 'rgba(0,0,0,0.7)' : 'rgba(255,255,255,0.7)',
      },
      transition: 'background 200ms ease-in-out',
      cursor: 'pointer',
    },
  };
});

type CardContainerProps = HtmlDivProps & { title?: React.ReactNode };

const CardContainer: React.FC<CardContainerProps> = ({ children, onClick, title, ...props }) => {
  const classes = useStyles();
  return (
    <>
      <Typography variant="h4" className={classes.title}>
        {title}
      </Typography>
      <div className={clsx(classes.cardContainer, onClick ? classes.clickable : '')} onClick={onClick} {...props}>
        {children}
      </div>
    </>
  );
};

export default CardContainer;
