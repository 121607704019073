import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import useAppSelector from 'common/hooks/useAppSelector';
import { GetArtPrizeShowQuery, GetArtPrizeShowWinnersV2Query } from 'common/schema/commonSchemaRemoteOperationTypes';

export interface artPrizeWinnersState {
  artPrizeSlug: string;
  artPrizeInfo?: GetArtPrizeShowQuery;
  winnersList: GetArtPrizeShowWinnersV2Query['getArtPrizeShowWinners']; // whole list of winners (bestInShow, 1st, 2nd, 3rd)
  artPrizeWinnersInfo: GetArtPrizeShowWinnersV2Query['getArtPrizeShowWinners']; // List of winners without repeated data to show on UI
  honorableMentionsList: GetArtPrizeShowWinnersV2Query['getArtPrizeShowWinners']; // whole list of honorable mentions
  honorableMentionsInfo: GetArtPrizeShowWinnersV2Query['getArtPrizeShowWinners']; // List of honorable mentions without repeated data to show on UI
  popularVoteMentionList: GetArtPrizeShowWinnersV2Query['getArtPrizeShowWinners']; // whole list of honorable mentions
  popularVoteMentionInfo: GetArtPrizeShowWinnersV2Query['getArtPrizeShowWinners']; // List of honorable mentions without repeated data to show on UI
}

export const artPrizeWinnersInitialState: artPrizeWinnersState[] = [
  {
    artPrizeSlug: '',
    artPrizeInfo: undefined,
    winnersList: [],
    artPrizeWinnersInfo: [],
    honorableMentionsList: [],
    honorableMentionsInfo: [],
    popularVoteMentionList: [],
    popularVoteMentionInfo: [],
  },
];

export const artPrizeWinnersInitialStateSingle: artPrizeWinnersState = {
  artPrizeSlug: '',
  artPrizeInfo: undefined,
  winnersList: [],
  artPrizeWinnersInfo: [],
  honorableMentionsList: [],
  honorableMentionsInfo: [],
  popularVoteMentionList: [],
  popularVoteMentionInfo: [],
};

const artPrizeWinnersPageSlice = createSlice({
  name: 'artPrizeWinners',
  initialState: artPrizeWinnersInitialState,
  reducers: {
    SET_WINNERS_PAGE_INFO: (state, action: PayloadAction<artPrizeWinnersState>) => [...state, action.payload],
  },
});

export const useArtPrizeWinnersPage = () => useAppSelector((state) => state.artPrizeWinners);

export const artPrizeWinnersPageActions = artPrizeWinnersPageSlice.actions;
export default artPrizeWinnersPageSlice;
