import { t as translate } from 'i18next';
import latinize from 'latinize';
import { useMemo } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { countryList, ICountry, countriesWithNoCities } from './utils/countries';

const matchIsoPrio = 3;

const getCountryPrio = (country: ICountry, lowerCaseInput: string, upperCaseInput: string, t: TFunction): number => {
  const matchPosition = Math.max(
    country.searchName.indexOf(lowerCaseInput),
    t(country.translationKey).toLowerCase().indexOf(lowerCaseInput)
  );
  return (
    (country.prio || 1) *
    (+(country.iso2 === upperCaseInput || country.iso3 === upperCaseInput) * matchIsoPrio +
      Number(matchPosition !== -1) +
      Number(matchPosition === 0) +
      Number(matchPosition > -1 && lowerCaseInput.length === country.searchName.length))
  );
};

export const getCountryFullName = (countryInput: string, onboarding = false): string => {
  if (!countryInput) {
    return '';
  }

  // 'The United States' is a special case for onboarding
  if (onboarding && (countryInput.toUpperCase() === 'US' || countryInput.toUpperCase() === 'USA')) {
    return translate('strings.geolocation.countries.isoCodes.US2');
  }

  const countryByCountryCode = countryList.find(
    (country) =>
      country.iso2.toLowerCase() === countryInput?.toLowerCase() ||
      country.iso3.toLowerCase() === countryInput?.toLowerCase()
  );
  if (countryByCountryCode) {
    return translate(countryByCountryCode.translationKey);
  }
  return countryInput;
};

const searchCountryGenerator =
  (countries: ICountry[], t: TFunction) =>
  (input?: string, limit?: number): ICountry[] => {
    const normalizedInput = latinize(input || '').replace(/[^a-zA-Z.]/g, '');
    const lowerCaseInput = normalizedInput.toLowerCase();
    const upperCaseInput = normalizedInput.toUpperCase();
    return countries
      .filter(
        (x) =>
          x.searchName.indexOf(lowerCaseInput) !== -1 ||
          t(x.translationKey).toLowerCase().indexOf(lowerCaseInput) !== -1 ||
          x.iso2.indexOf(upperCaseInput) !== -1 ||
          x.iso3.indexOf(upperCaseInput) !== -1
      )
      .map((x) => ({ ...x, score: getCountryPrio(x, lowerCaseInput, upperCaseInput, t) }))
      .sort((a, b) => b.score - a.score)
      .slice(0, limit || countries.length);
  };

export const useCountries = () => {
  const countries = countryList.filter((country) => !countriesWithNoCities.includes(country.iso2));
  const { t } = useTranslation();
  const searchCountry = useMemo(() => searchCountryGenerator(countries, t), [t, countries]);

  return {
    countries,
    searchCountry,
  };
};
