import { createSlice } from '@reduxjs/toolkit';
import { RecognitionsModalType } from '../RecognitionsModal/views/RecognitionsModal';

const recognitionsEngagementInitialState: RecognitionsModalType = {
  artistContextId: 0,
  recognitionsCount: 0,
  isDisplayed: false,
};

export const recognitionsEngagementSlice = createSlice({
  name: 'recognitionsEngagement',
  initialState: recognitionsEngagementInitialState,
  reducers: {
    SET_RECOGNITIONS_ENGAGEMENT: (_, action) => ({
      ...action.payload,
      isDisplayed: true,
    }),
    CLOSE_MODAL: (state) => ({
      ...state,
      isDisplayed: false,
    }),
    OPEN_MODAL: (state) => ({
      ...state,
      isDisplayed: true,
    }),
  },
});

export const recognitionsEngagementActions = recognitionsEngagementSlice.actions;
