import React from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core';
import { BasicImageType } from 'common/components/Image/types';

type isDarkModeProp = {
  isDarkMode?: boolean;
  fullBorders?: boolean;
};

export const useTripleLinearImageLayoutStyles = makeStyles<Theme, isDarkModeProp>(() => ({
  image: ({ isDarkMode, fullBorders }) => ({
    flex: 1,
    borderRadius: '8px',
    border: !isDarkMode ? '4px solid #FFF' : '4px solid #000',
    marginLeft: '-25px',
    overflow: 'hidden',
    height: '100%',
    aspectRatio: '1/1',

    '&.--first': {
      zIndex: 3,
      borderLeft: !fullBorders ? 'none' : '',
      marginLeft: 0,
    },
    '&.--second': {
      zIndex: 2,
    },
    '&.--third': {
      zIndex: 1,
      borderRight: !fullBorders ? 'none' : '',
    },
  }),
  container: {
    display: 'flex',
  },
}));

const TripleLinearImageLayout: React.FC<{
  isDarkMode?: boolean;
  fullBorders?: boolean;
  children: [
    React.ReactElement<BasicImageType>,
    React.ReactElement<BasicImageType>,
    React.ReactElement<BasicImageType>
  ];
}> = ({ children, isDarkMode = false, fullBorders = false }) => {
  const classes = useTripleLinearImageLayoutStyles({ isDarkMode, fullBorders });
  return (
    <div style={{ display: 'flex' }}>
      <div className={clsx(classes.image, '--first')}>{children[0]}</div>
      <div className={clsx(classes.image, '--second')}>{children[1]}</div>
      <div className={clsx(classes.image, '--third')}>{children[2]}</div>
    </div>
  );
};

export default TripleLinearImageLayout;
