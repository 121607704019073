import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Enum_Profile_Background_Type_Enum } from 'common/schema/commonSchemaRemoteTypes';

export interface backgroundState {
  backgrounds: Enum_Profile_Background_Type_Enum[];
}

const initialState: backgroundState = {
  backgrounds: [] as Enum_Profile_Background_Type_Enum[],
};

const backgroundSlice = createSlice({
  name: 'backgrounds',
  initialState,
  reducers: {
    SET_BACKGROUNDS: (state, action: PayloadAction<Enum_Profile_Background_Type_Enum[]>) => {
      state.backgrounds = action.payload;
    },
    RESET_BACKGROUNDS: () => initialState,
  },
});

export const backgroundActions = backgroundSlice.actions;
export default backgroundSlice;
