import React, { useCallback } from 'react';
import useAppSelector from 'common/hooks/useAppSelector';
import Links from 'common/components/Links/Links';
import { Typography } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import useOpenShowcase from 'common/features/ArtworkShowcaseControl/hooks/useOpenShowcase';
import { useProfileAnimationState } from 'stacks/ProfileArtist/hooks/useProfileAnimationState';
import { useDispatch } from 'react-redux';
import { profileToShowcaseAnimationActions } from 'store/slices/profileToShowcaseAnimationSlice';
import { useHistory } from 'react-router';
import { ROUTES } from 'routes/_definition';
import { useSwipeContainerComponentsStyles } from './useSwipeContainerComponents.styles';
import { SwipeContainerComponentsProps } from '.';

const SwipeContainerTitle: React.FC<SwipeContainerComponentsProps> = ({ artworkId }) => {
  const classes = useSwipeContainerComponentsStyles();
  const { series, artworkDetails, slug, artist } =
    useAppSelector((store) => store.entities.artworkSwipeContainerCache[artworkId]) ?? {};
  const dispatch = useDispatch();
  const { location } = useHistory();
  const openShowcase = useOpenShowcase();
  const { startTransitionToShowcase } = useProfileAnimationState(true);

  const openArtShowcase = useCallback(() => {
    if (location.pathname === ROUTES.LIVE || location.pathname === ROUTES.FAVORITES) {
      dispatch(profileToShowcaseAnimationActions.SET_ANIMATION({ animation: 'SHOWCASE_SHOW_ANIMATION' }));
    } else {
      openShowcase({
        slug: slug ?? '',
        handle: artist?.profile?.handle ?? '',
      });
      startTransitionToShowcase();
    }
  }, [artist, slug, location.pathname]);

  if (!artworkDetails?.title) {
    return <Skeleton className={classes.skeleton} width={200} height={40} />;
  }

  if (series?.[0]?.collection?.name) {
    return (
      <Links.UnstyledAnchor onClick={openArtShowcase} to={`/${artist?.profile?.handle}/showcase/${slug}`}>
        <Typography className={classes.titleWrapper} variant="subtitle2" style={{ textAlign: 'initial' }}>
          <span className={classes.title}>{artworkDetails?.title}</span>
          <span className={classes.year}>{` (${artworkDetails?.year})`}</span>
        </Typography>
      </Links.UnstyledAnchor>
    );
  }
  return (
    <Links.UnstyledAnchor onClick={openArtShowcase} to={`/${artist?.profile?.handle}/showcase/${slug}`}>
      <Typography variant="subtitle2" className={classes.titleWrapper}>
        <span className={classes.title}>{artworkDetails?.title}</span>
        {!!artworkDetails?.year && <span className={classes.year}>{` (${artworkDetails?.year})`}</span>}
      </Typography>
    </Links.UnstyledAnchor>
  );
};

export default SwipeContainerTitle;
