import React from 'react';
import { Box, Grid } from '@material-ui/core';
import { BasicImageTypePropsExtra } from 'common/components/Image/types';
import { LinkProps } from 'react-router-dom';
import useDebouncedState from 'common/hooks/utils/useDebouncedState';
import ArtworkCardComponents from '../common';
import Links from '../../Links/Links';
import ArtCollectedBadge, { ArtCollectedBadgeProps } from './components/ArtCollectedBadge';
import ArtCollectedInfo, { ArtCollectedChipProps } from './components/ArtCollectedInfo';
import { ArtworkInformationProps } from '../common/ArtworkInformation';
import ProvenancePanel from './components/ProvenancePanel';
import ArtworkCardOnHover from '../common/ArtworkCardOnHover';
import SwipeInfoContainer, { SwipeInfoContainerProps } from '../common/SwipeInfoContainer/SwipeInfoContainer';

export type ArtCollectedCardProps = {
  cardTargetLinksProps: {
    top: LinkProps;
    bottom: LinkProps;
  };
  artCollectedBadgeProps: ArtCollectedBadgeProps;
  artCollectedChipProps: ArtCollectedChipProps;
  imageProps?: BasicImageTypePropsExtra<{
    aspectRatio?: React.CSSProperties['aspectRatio'];
  }>;
  artworkInfoProps?: ArtworkInformationProps;
  isSquare?: boolean;
  artworkId: number;
  swipeContainerProps?: SwipeInfoContainerProps;
  isSwipeable?: boolean;
};

export const ArtCollectedCard: React.FC<ArtCollectedCardProps> = React.memo(
  ({
    cardTargetLinksProps,
    isSquare,
    imageProps,
    artworkInfoProps,
    artCollectedChipProps,
    artCollectedBadgeProps,
    artworkId,
    swipeContainerProps,
    isSwipeable,
  }) => {
    const [showPanel, setShowPanel] = useDebouncedState(false, 300);

    return (
      <>
        <ArtworkCardComponents.SwipeCardTopBottomLayout
          topProps={{
            ...swipeContainerProps?.touchProps,
            style: undefined,
          }}
        >
          {isSwipeable ? <SwipeInfoContainer {...swipeContainerProps} /> : <></>}
          <Links.UnstyledAnchor
            style={{
              cursor: showPanel ? 'auto' : 'pointer',
              ...swipeContainerProps?.touchProps?.style,
            }}
            to={showPanel ? '#' : cardTargetLinksProps.top.to}
            onClick={cardTargetLinksProps.top.onClick}
          >
            <ArtCollectedBadge {...artCollectedBadgeProps} />

            <ArtworkCardOnHover>
              <ArtworkCardComponents.ArtCollectedImageInfoLayout>
                <ArtworkCardComponents.ArtworkInformation {...(artworkInfoProps ?? {})} />
                {!isSquare ? (
                  <ArtworkCardComponents.SoloAspectRatioImage {...(imageProps ?? {})} />
                ) : (
                  <ArtworkCardComponents.SoloSquareImage {...(imageProps ?? {})} />
                )}

                {showPanel ? <ProvenancePanel onClose={() => setShowPanel(false)} artworkId={artworkId} /> : <></>}
              </ArtworkCardComponents.ArtCollectedImageInfoLayout>
            </ArtworkCardOnHover>
          </Links.UnstyledAnchor>

          <Box onClick={() => setShowPanel(!showPanel)} style={{ cursor: 'pointer' }}>
            <ArtworkCardComponents.FooterLayout>
              <Grid item style={{ width: '100%' }}>
                <ArtCollectedInfo {...(artCollectedChipProps ?? {})} />
              </Grid>
            </ArtworkCardComponents.FooterLayout>
          </Box>
        </ArtworkCardComponents.SwipeCardTopBottomLayout>
      </>
    );
  }
);
