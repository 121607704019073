import { Grid } from '@material-ui/core';
import { SkeletonImage } from '../../Image/SkeletonImage';
import { BasicImageType } from '../../Image/types';

export const SoloSquareImage: BasicImageType = (props) => (
  <Grid
    container
    style={{
      width: '100%',
      height: '100%',
      maxHeight: '100%',
      overflow: 'hidden',
      borderRadius: '4px',
      aspectRatio: '1',
    }}
  >
    <SkeletonImage {...props} skeletonProps={{ style: { minHeight: '75px', aspectRatio: '1' } }} />
  </Grid>
);
