import { logger } from '@sentry/utils';
import { CurrencyTypeEnum } from 'common/components/CurrencySelector/CurrencySelector.formik';
import { GetProvenanceByArtworkIdQuery } from 'common/schema/commonSchemaRemoteOperationTypes';
import { Enum_Position_Type_Enum, Enum_Profile_Public_Status_Enum } from 'common/schema/commonSchemaRemoteTypes';
import { DEFAULT_MEASURE_UNIT } from 'common/utils/constants';
import { ArtworkMeasureUnit } from 'common/utils/types';
import { ProvenanceData } from 'stacks/ProfileArtShowcase/components/ProfileArtShowcaseLeftSide/hooks/helpers';
import { getCDNImageUrl } from 'utils/CDNImages';
import { CDNUrl } from 'utils/constants';
import { formatNumberToLocale, isSomeEnum } from 'utils/utilFunctions';
import {
  ArtPrizeType,
  ArtworkDimensions,
  ArtworkToCollectionType,
  Collector,
  CollectorContextType,
  Gallery,
  GalleryContextType,
  SecondaryTagsType,
  SeriesArtworkType,
  ShowcaseDetailsQueryType,
  ShowcaseDetailsType,
  TransactionHistoriesType,
} from './types';

export const doesArtworkHaveCollector = (transactionHistories?: TransactionHistoriesType): boolean => {
  if (!transactionHistories?.length) {
    return false;
  }
  return transactionHistories?.[0]?.buyer_context?.profile?.id !== null;
};

export const getShowcaseSecondaryTags = (secondaryTags?: SecondaryTagsType): string[] => {
  const tags: string[] = [];

  secondaryTags?.forEach((item) => {
    const tag = item?.tag;
    tags.push(tag?.name);
  });

  return tags;
};

const getCollectorImageBasedOnPrivacy = (
  collectorContext: CollectorContextType,
  privacy: Enum_Profile_Public_Status_Enum
): string => {
  if (privacy === Enum_Profile_Public_Status_Enum.Private) {
    return `${CDNUrl}/images/artDetailsIcons/newProfileArtShowcase/privateCollector.png`;
  }

  const collectorProfile = collectorContext?.profile;
  return getCDNImageUrl({
    target: 'Profile',
    purpose: 'Avatar',
    targetId: collectorProfile?.id ?? 0,
    fileId: collectorProfile?.avatar ?? 0,
    meta: {
      size: 'large',
    },
  });
};

const getCollectorNameBasedOnPrivacy = (
  collectorContext: CollectorContextType,
  privacy: Enum_Profile_Public_Status_Enum
): string => {
  const collectorProfile = collectorContext?.profile;

  if (privacy === Enum_Profile_Public_Status_Enum.Private) {
    return 'Private Collector'; // TODO: hardcoded string
  }

  if (privacy === Enum_Profile_Public_Status_Enum.PublicLegalName) {
    return `${collectorProfile?.first_name} ${collectorProfile?.last_name}`;
  }

  return collectorProfile?.handle || '';
};

export const getCurrentCollector = (transactionHistories?: TransactionHistoriesType): Collector | undefined => {
  if (!transactionHistories?.length) {
    return undefined;
  }
  const collector: Collector = {
    image: '',
    handle: '',
    title: '',
    isPublic: false,
  };
  const collectorContext = transactionHistories?.[0]?.buyer_context;
  const collectorProfile = collectorContext?.profile;

  const profilePrivacy = collectorProfile?.is_public || Enum_Profile_Public_Status_Enum.Public;

  collector.isPublic = profilePrivacy !== Enum_Profile_Public_Status_Enum.Private;
  collector.handle = collectorProfile?.handle || '';

  collector.image = getCollectorImageBasedOnPrivacy(collectorContext, profilePrivacy);

  collector.title = getCollectorNameBasedOnPrivacy(collectorContext, profilePrivacy);

  return collector;
};

export const getPartnerGalleryInformation = (galleryContext: GalleryContextType): Gallery | undefined => {
  if (!galleryContext) {
    return undefined;
  }

  const galleryProfile = galleryContext?.profile;

  const gallery: Gallery = {
    handle: '',
    image: '',
    title: '',
  };

  gallery.handle = galleryProfile?.handle || '';
  gallery.image = getCDNImageUrl({
    target: 'Profile',
    purpose: 'Avatar',
    targetId: galleryProfile?.id ?? 0,
    fileId: galleryProfile?.avatar ?? 0,
    meta: {
      size: 'large',
    },
  });
  gallery.title = galleryProfile?.title || '';

  return gallery;
};

export const getArtworksWithinSeries = (artworksWithinSeries: ArtworkToCollectionType[]): SeriesArtworkType[] => {
  const artworks: SeriesArtworkType[] = [];

  if (!artworksWithinSeries?.length) {
    return artworks;
  }

  artworksWithinSeries.forEach((item) => {
    artworks.push(item.Artwork);
  });

  return artworks;
};

export const mapEditionsData = (showcaseData: ShowcaseDetailsQueryType): ShowcaseDetailsType['editionsTab'] => {
  const { edition_type, editions } = showcaseData;

  const editionsTab: ShowcaseDetailsType['editionsTab'] = {
    type: edition_type ?? null,
    editionSizesDetails: {},
  };

  editions.forEach((edition) => {
    edition.Edition_By_Sizes.forEach((size) => {
      const possibleCurrency = edition.currency ?? size.currency;
      editionsTab.editionSizesDetails[size.print_size] = {
        id: size.id,
        currency: isSomeEnum(CurrencyTypeEnum)(possibleCurrency) ? possibleCurrency : undefined,
        isPricePublic: size.isPricePublic ?? edition.isPricePublic ?? undefined,
        fixedPrice: size.price ?? edition.price ?? undefined,
        height: size.height ?? 0,
        width: size.width ?? 0,
        material: size.materials ?? '',
        technique: size.technique ?? '',
        isForSale: edition.isForSale,
        measuringUnit: isSomeEnum(ArtworkMeasureUnit)(size.measuring_unit) ? size.measuring_unit : DEFAULT_MEASURE_UNIT,
        editionByTypes: size.Edition_By_Types.map(({ id, Edition_Type, print_amount }) => ({
          id,
          name: Edition_Type.value,
          printsAmount: print_amount ?? 0,
        })),
        createdOn: {
          year: size.year ?? new Date().getFullYear(),
          month: size.month ?? undefined,
          day: size.day ?? undefined,
        },
      };
    });
  });

  return editionsTab;
};

export const mapProvenanceData = (
  artworkId: number,
  data?: GetProvenanceByArtworkIdQuery['getArtworkEventByArtworkId']
): ProvenanceData[] => {
  const provenanceData: ProvenanceData[] = [];
  if (!data?.length) {
    return provenanceData;
  }

  data.forEach((p, i) => {
    let image = 'https://cdn.kaleido.art/CDN/Default/kaleido_logo_small.png';
    if (p?.photoURL?.length) {
      image = p.photoURL;
    }
    if (p?.date && p.event_type && p.text) {
      provenanceData.push({
        date: p.date,
        id: i,
        image,
        text: p.text,
      });
    } else {
      logger.warn(`No provenance details for artwork ${artworkId} at index ${i}: ${JSON.stringify(p)}`);
    }
  });

  return provenanceData;
};

export const getArtworkDimensionsString = (dimensions: ArtworkDimensions): string =>
  [
    dimensions.width ? formatNumberToLocale(dimensions.width) : null,
    dimensions.height ? formatNumberToLocale(dimensions.height) : null,
    dimensions.depth ? formatNumberToLocale(dimensions.depth) : null,
  ]
    .filter((x) => x)
    .join(' x ');

export const artPrizeRanking = [
  Enum_Position_Type_Enum.BestInShow,
  Enum_Position_Type_Enum.FirstGrandPrize,
  Enum_Position_Type_Enum.SecondGrandPrize,
  Enum_Position_Type_Enum.ThirdGrandPrize,
  Enum_Position_Type_Enum.HonorableMention,
];

export const getBestArtPrize = (artPrizes: ArtPrizeType[]): ArtPrizeType | undefined => {
  if (!artPrizes.length) {
    return undefined;
  }
  const sorted = [...artPrizes].sort(
    (artPrize1: ArtPrizeType, artPrize2: ArtPrizeType) =>
      artPrizeRanking.indexOf(artPrize1.place) - artPrizeRanking.indexOf(artPrize2.place)
  );

  return sorted[0];
};
