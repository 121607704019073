import { getDefaultYearsFilter } from 'common/features/Filters/predefined/Year/store/year.defaults';
import { getDefaultArtistsFilter } from 'common/features/Filters/predefined/Artists/store/artists.defaults';
import { getDefaultSortOption } from 'common/features/Filters/predefined/SortGroup/store/recent.defaults';
import { SortOptionsEnum } from 'common/features/VirtualList/VirtualList.config';
import { ProfileCollectorFiltersState } from './filter.types';

export const getDefaultFilters = (): ProfileCollectorFiltersState => ({
  ...getDefaultSortOption(SortOptionsEnum.COLLECTOR_SELECTION),
  ...getDefaultYearsFilter(),
  ...getDefaultArtistsFilter(),
});

export const filterInitialState: ProfileCollectorFiltersState = getDefaultFilters();
