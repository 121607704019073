import { Grid } from '@material-ui/core';
import React from 'react';
import { BasicImageType } from '../../Image/types';

export const TripleImageLayout: React.FC<{
  children: [
    React.ReactElement<BasicImageType>,
    React.ReactElement<BasicImageType>,
    React.ReactElement<BasicImageType>
  ];
}> = ({ children }) => (
  <Grid container>
    <Grid item xs={8} style={{ aspectRatio: '1', padding: '4px 4px 4px 0px' }}>
      {children[0]}
    </Grid>
    <Grid item container xs={4} style={{ padding: '4px 0px 4px 4px' }}>
      <Grid item xs={12} style={{ aspectRatio: '1', padding: '0px 0px 4px 0px' }}>
        {children[1]}
      </Grid>
      <Grid item xs={12} style={{ aspectRatio: '1', padding: '4px 0px 0px 0px' }}>
        {children[2]}
      </Grid>
    </Grid>
  </Grid>
);
