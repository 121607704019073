import useAppSelector from 'common/hooks/useAppSelector';
import { useEffect, useMemo } from 'react';
import useDebouncedState from 'common/hooks/utils/useDebouncedState';
import { artistContextIdFilterHash } from 'common/features/Filters/predefined/ArtistContextId/artistContextId.utils';
import { HomeArtworksFiltersHash } from '../store/list.types';
import { HomeArtworksFiltersState } from '../store/filter.types';

// exported for use in the initial state
export const filtersHash = (state: HomeArtworksFiltersState, suffix: string | number = ''): HomeArtworksFiltersHash =>
  // using template string to match typing
  `${state.sort}_${[state.refresh, artistContextIdFilterHash(state.selectedArtistContextId), suffix].join(
    '_'
  )}` as const;

export const useHomeArtworksFiltersIdentifier = (contextId?: string | number): HomeArtworksFiltersHash => {
  const filters = useAppSelector((store) => store.homeArtworks.filter);
  const currentIdentifier = useMemo(() => filtersHash(filters, contextId), [filters, contextId]);
  const [appliedIdentifier, setAppliedIdentifier] = useDebouncedState(currentIdentifier, 500);
  useEffect(() => {
    setAppliedIdentifier(currentIdentifier);
  }, [currentIdentifier]);
  return appliedIdentifier;
};
