import useAppSelector from 'common/hooks/useAppSelector';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { filterHashResetMarker, ListingIdentifier, ListReduxFragment } from '../components/listReduxFragment';
import { ReduxListSliceActions } from '../slices/createListSlice';
import { KnownVirtualListEnum, SortOptionsEnum } from '../VirtualList.config';

export type UseColdStartType<SortSubset extends SortOptionsEnum> = (props: {
  requestedSort: SortSubset;
  requestedListingIdentifier: ListingIdentifier<SortSubset>;
  filterApplyDelayMillis?: number;
  canStart?: boolean;
}) => {
  isColdStart: boolean;
  appliedListingIdentifier: ListingIdentifier<SortSubset>;
};

export type GenerateUseColdStartProps<SortSubset extends SortOptionsEnum, CursorType> = {
  list: KnownVirtualListEnum;
  getSpecificCursor: (sort: SortSubset) => [CursorType, CursorType];
  actions: ReduxListSliceActions<SortSubset, CursorType>;
  // RootState doesn't work - creates circularity ???
  selector: (reduxStore: any) => ListReduxFragment<SortSubset, CursorType>;
};

export const generateUseColdStart =
  <SortSubset extends SortOptionsEnum, CursorType>({
    list,
    getSpecificCursor,
    actions,
    selector,
  }: GenerateUseColdStartProps<SortSubset, CursorType>): UseColdStartType<SortSubset> =>
  ({ requestedSort, requestedListingIdentifier, filterApplyDelayMillis = 500, canStart = true }) => {
    const dispatch = useDispatch();
    const currentListedStatus = useAppSelector((store) => selector(store).data.listed.status);
    const appliedListingIdentifier = useAppSelector((store) => selector(store).identifier);
    const listLength = useAppSelector((store) => selector(store).data.listed.values.length);
    const [announceRender, setAnnounceRender] = useState(0);
    const state = useRef({
      isRequestApplied: false,
      shouldRequestBeAppliedImmediately: false,
      sort: requestedSort,
      identifier: requestedListingIdentifier,
    });
    const [coldStartIdentifier, setColdStartIdentifier] = useState('');

    state.current.shouldRequestBeAppliedImmediately =
      requestedListingIdentifier.split(filterHashResetMarker)[0] ===
      appliedListingIdentifier.split(filterHashResetMarker)[0];
    state.current.isRequestApplied = requestedListingIdentifier === appliedListingIdentifier;
    state.current.identifier = requestedListingIdentifier;
    state.current.sort = requestedSort;

    useEffect(() => {
      if (!canStart) {
        return () => undefined;
      }

      const { identifier, sort } = state.current;

      const dispatchCall = () => {
        dispatch(
          actions.COLD_START({
            list,
            identifier,
            startList: ['FORWARD', sort, getSpecificCursor(sort)],
          })
        );
        setColdStartIdentifier(state.current.identifier);
      };

      if (state.current.shouldRequestBeAppliedImmediately) {
        dispatchCall();
        return () => undefined;
      }

      const timeout = window.setTimeout(dispatchCall, filterApplyDelayMillis);
      return () => window.clearTimeout(timeout);
    }, [dispatch, state.current.identifier, filterApplyDelayMillis, canStart]);

    useEffect(() => {
      if (
        coldStartIdentifier !== state.current.identifier ||
        !canStart ||
        !coldStartIdentifier ||
        !['IDLE', 'FINISHED'].includes(currentListedStatus)
      ) {
        return;
      }
      setColdStartIdentifier('');
      dispatch(actions.REQUEST_DETAILED({ list, direction: 'FORWARD' }));
      if (!listLength) {
        setAnnounceRender((prev) => prev + 1);
      }
    }, [dispatch, currentListedStatus, canStart, coldStartIdentifier]);

    return {
      isColdStart: !!coldStartIdentifier,
      appliedListingIdentifier,
      announceRender,
    };
  };
