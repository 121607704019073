import { combineReducers, compose, applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import { topNavbarSlice } from 'common/components/MobileTopNavbar/store/TopNavbar.slice';
import { accountReducer } from 'store/slices/account';
import { permissionsReducer } from 'store/slices/permissions';
import { editArtworkReducer } from 'store/slices/editArtwork';
import { themeReducer } from 'store/slices/theme';
import { inviteReducer } from 'store/slices/invite';
import { filterReducer } from 'store/slices/filter';
import { featureFlagSlice } from 'common/features/FeatureFlag/store/featureFlagSlice';
import eventTriggerSlice from 'common/features/EventTrigger/store/eventTriggerSlice';
import profileArtShowcaseSlice from 'stacks/ProfileArtShowcase/store/profileArtShowcaseSlice';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import { homeArtistsSlice } from 'stacks/Home/Artists/store';
import { recognitionsV2Slice } from 'stacks/RecognitionsV2/store';
import collectionsPageSlice from 'stacks/Collections/store/collectionsPageSlice';
import artPrizesSlice from 'stacks/ArtPrizesPage/store/artPrizesPageSlice';
import { artFeedNavbarSlice } from 'common/components/ArtFeedNavbar/store/artFeedNavbar.slice';
import { homeArtworksSlice } from 'stacks/Home/Artworks/store';
import { favoritesSlice } from 'stacks/FavoritesV2/store';
import adminWidgetSlice from 'stacks/Admin/Tools/AdminWidget/store/adminWidgetSlice';
import pullDownSlice from 'common/features/PullDownToRefresh/store/pullDownSlice';
import { consumerProductMenuButtonSlice } from 'common/components/ArtFeedMobileMenuButton/store/consumerProductMenuButtonSlice';
import { consumerProductFTuSlice } from 'common/components/ConsumerProductFTU/store/consumerProductFTUSlice';
import contextFlagSlice from 'common/features/useContextFlag/contextFlag.store';
import { nearbyArtistsSlice } from 'stacks/Nearby/Artists/store';
import { favoritesEngagementSlice } from 'common/features/FavoritesEngagement/store/favoritesEngagementSlice';
import { favoritesModalSlice } from 'common/features/FavoritesEngagement/FavoritesModal/components/FavoritesModalContent/store';
import { recognitionsEngagementSlice } from 'common/features/RecognitionsEngagement/store/recognitionsEngagementSlice';
import { artistProfileArtworksSlice } from 'stacks/ArtistProfile/ArtistProfileArtworks/store';
import { artistManageArtworksSlice } from 'stacks/ArtistProfile/ArtistManageArtworks/store';
import darkModeSlice from 'common/features/DarkMode/store/darkMode';
import { nearbyGalleriesSlice } from 'stacks/Nearby/Galleries/store';
import { recognitionsModalSlice } from 'common/features/RecognitionsEngagement/RecognitionsModal/components/RecognitionsModalContent/store';
import { artworkTypeSlice } from 'common/features/FormikConfigs/components/store/artworkTypeSlice';
import { chatSlice } from 'common/features/DirectMessaging/store/chatSlice';
import permissionsStateSlice from 'stacks/PermissionsModal/store/permissionsStateSlice';
import { artShowArtworksSlice } from 'stacks/ArtShow/store';
import { artistSidebarV2Slice } from 'stacks/ArtistSidebarV2/store/artistSidebarV2Slice';
import screenspaceSlice from 'common/features/Screenspace/store/screenspaceSlice';
import { notificationCenterSlice } from 'common/features/NotificationCenter/store';
import { bottomNavbarSlice } from 'common/components/MobileBottomNavbar//store/BottomNavbar.slice';
import { recognitionsSlice } from 'common/features/RecognitionsList/store';
import { artworkDetailsSidebarSlice } from 'stacks/ShowcaseNewV2/components/MobileArtShowcase/store/artworkDetailsSidebarSlice';
import { universalSwipeHandlersSlice } from 'common/features/UniversalSwipeHandlers/store/UniversalSwipeHandlers.slice';
import { profileMuseumArtworksSlice } from 'stacks/ProfileMuseum/store';
import { shopArtworksSlice } from 'stacks/Shop/store';
import { kaliAutomatizationSlice } from 'common/features/KaliAutomatization/store/KaliAutomatization.slice';
import { artistSpotlightSlice } from 'stacks/HomeV2/components/ArtistSpotlight/store/artistSpotlightSlice';
import { followersDashboardSlice } from 'stacks/Dashboard/common/MyFollowers/store/followers';
import { collectorsDashboardSlice } from 'stacks/Dashboard/common/MyFollowers/store/collectors';
import launchedMyStudioModalSlice from 'common/components/LunchedMyStudioModal/store/launchedMyStudioModalSlice';
import routingSlice from './slices/routing';
import { domainReducer } from './slices/domainSlice';
import visitingAccount from './slices/visitingAccount';
import tutorialsSlice from './slices/tutorialsSlice';
import artworkListModifiers from './slices/artworkListModifiers';
import profileListModifiers from './slices/profileListModifiers';
import collectionsListModifiers from './slices/collectionListModifiers';
import artworkShowcaseSlice from './slices/artworkShowcase';
import milestonesSlice from './slices/milestonesSlice';
import userTrapSlice from './slices/userTrapSlice';
import completeProfileSlice from './slices/completeProfileSlice';
import notificationsSlice from './slices/notificationsSlice';
import publishSlice from './slices/publishSlice';
import admirationsModifiersSlice from './slices/admirationsModifiersSlice';
import audioPlayerSlice from './slices/audioPlayer';
import headerSliceSlice from './slices/headerSlice';
import registerSlice from './slices/registerSlice';
import claimCertificateRegisterSlice from './slices/claimCertificateRegisterSlice';
import favoritesModifierSlice from './slices/favoritesSlice';
import profileToShowcaseAnimationSlice from './slices/profileToShowcaseAnimationSlice';
import citiesSlice from './slices/citiesSlice';
import certificatesSlice from './slices/certificatesSlice';
import invitationsSlice from './slices/invitationsSlice';
import registerParamsSlice from './slices/registerParamsSlice';
import persistentSlice from './slices/persistentSlice';
import appNotificationsSlice from './slices/appNotificationsSlice';
import persistentVerticalScrollSlice from './slices/persistentVerticalScroll';
import interestsSlice from './slices/interestsSlice';
import priceRangesSlice from './slices/priceRangesSlice';
import { entitiesSlice } from './slices/entities';
import locationSlice from './slices/locationSlice';
import recognitionsPageSlice from './slices/recognitionsPageSlice';
import artistProfileDetailsSlice from './slices/artistProfileDetailsSlice';
import artPrizesWinnersPageSlice from './slices/artPrizeWinnersSlice';
import socialLinksSlice from './slices/socialLinksSlice';
import galleriesProfileSlice from './slices/galleriesProfileSlice';
import { profileGallerySlice } from '../stacks/ProfileGallery/store';
import { profileCollectorSlice } from '../stacks/ProfileCollector/store';
import collectorProfileSlice from './slices/collectorProfile';
import destinationsSlice from './slices/destinationsSlice';
import backgroundSlice from './slices/background';
import featuredPageSlice from '../stacks/FeaturedPage/store/featuredPageSlice';
import geolocationSlice from './slices/geolocationSlice';
import suggestedTagsSlice from './slices/suggestedTagsSlice';
import { domainSetupReducer } from './slices/domainSetupSlice';
import buyModalSlice from './slices/buyModal';
import statesSlice from './slices/statesSlice';
import authSlice from './slices/authSlice';
import { shoppingCartSlice } from './slices/shoppingCartSlice';
import artistByInterestSlice from './slices/artistsByInterest';
import upgradeToMyStudioModalSlice from './slices/upgradeToMyStudioModal';
import subscriptionsSlice from './slices/subscriptionsSlice';
import generateNewCertificateModalSlice from './slices/generateNewCertificateModal';
import myDocumentsSlice from './slices/myDocumentsSlice';
import myFollowersSlice from './slices/myFollowersSlice';
import dynamicCVSlice from './slices/dynamicCVSlice';
import arrangeArtworksModalSlice from './slices/arrangeArtworksModal';
import { myWebsiteSettingsSlice } from './slices/myWebsiteSettings';
import newsLetterModalSlice from './newsLetterModal';
import discountCodesSlice from '../stacks/Admin/DiscountCodes/store/discountCodes.slice';
import biolinkSectionsSlice from './slices/biolinkSectionsSlice';
import onboardingArtworkSlideshowSlice from './slices/onboardingArtworkSlideshow';
import securitySlice from './slices/security';
import dashboardSidebarSlice from './slices/dashboardSidebar';
import myProvenanceSlice from './slices/myProvenanceSlice';
import myAugmentationsSlice from './slices/myAugmentationsSlice';

const combinedReducer = combineReducers({
  editArtwork: editArtworkReducer,
  account: accountReducer,
  domain: domainReducer,
  domainSetup: domainSetupReducer,
  permissions: permissionsReducer,
  theme: themeReducer,
  invite: inviteReducer,
  filter: filterReducer,
  [registerParamsSlice.name]: registerParamsSlice.reducer,
  [artworkTypeSlice.name]: artworkTypeSlice.reducer,
  [citiesSlice.name]: citiesSlice.reducer,
  [statesSlice.name]: statesSlice.reducer,
  [routingSlice.name]: routingSlice.reducer,
  [visitingAccount.name]: visitingAccount.reducer,
  [featureFlagSlice.name]: featureFlagSlice.reducer,
  [eventTriggerSlice.name]: eventTriggerSlice.reducer,
  [profileArtShowcaseSlice.name]: profileArtShowcaseSlice.reducer,
  [tutorialsSlice.name]: tutorialsSlice.reducer,
  [artworkListModifiers.name]: artworkListModifiers.reducer,
  [profileListModifiers.name]: profileListModifiers.reducer,
  [artworkShowcaseSlice.name]: artworkShowcaseSlice.reducer,
  [milestonesSlice.name]: milestonesSlice.reducer,
  [myWebsiteSettingsSlice.name]: myWebsiteSettingsSlice.reducer,
  [userTrapSlice.name]: userTrapSlice.reducer,
  [completeProfileSlice.name]: completeProfileSlice.reducer,
  [registerSlice.name]: registerSlice.reducer,
  [notificationsSlice.name]: notificationsSlice.reducer,
  [publishSlice.name]: publishSlice.reducer,
  [audioPlayerSlice.name]: audioPlayerSlice.reducer,
  [headerSliceSlice.name]: headerSliceSlice.reducer,
  [admirationsModifiersSlice.name]: admirationsModifiersSlice.reducer,
  [claimCertificateRegisterSlice.name]: claimCertificateRegisterSlice.reducer,
  [favoritesModifierSlice.name]: favoritesModifierSlice.reducer,
  [profileToShowcaseAnimationSlice.name]: profileToShowcaseAnimationSlice.reducer,
  [certificatesSlice.name]: certificatesSlice.reducer,
  [invitationsSlice.name]: invitationsSlice.reducer,
  [persistentSlice.name]: persistentSlice.reducer,
  [collectionsListModifiers.name]: collectionsListModifiers.reducer,
  [artPrizesSlice.name]: artPrizesSlice.reducer,
  [artPrizesWinnersPageSlice.name]: artPrizesWinnersPageSlice.reducer,
  [galleriesProfileSlice.name]: galleriesProfileSlice.reducer,
  [collectorProfileSlice.name]: collectorProfileSlice.reducer,
  [artistByInterestSlice.name]: artistByInterestSlice.reducer,
  [appNotificationsSlice.name]: appNotificationsSlice.reducer,
  [featuredPageSlice.name]: featuredPageSlice.reducer,
  [topNavbarSlice.name]: topNavbarSlice.reducer,
  [universalSwipeHandlersSlice.name]: universalSwipeHandlersSlice.reducer,
  [buyModalSlice.name]: buyModalSlice.reducer,
  [upgradeToMyStudioModalSlice.name]: upgradeToMyStudioModalSlice.reducer,
  [newsLetterModalSlice.name]: newsLetterModalSlice.reducer,
  [generateNewCertificateModalSlice.name]: generateNewCertificateModalSlice.reducer,
  [myDocumentsSlice.name]: myDocumentsSlice.reducer,
  [myFollowersSlice.name]: myFollowersSlice.reducer,
  [arrangeArtworksModalSlice.name]: arrangeArtworksModalSlice.reducer,
  [discountCodesSlice.name]: discountCodesSlice.reducer,
  [securitySlice.name]: securitySlice.reducer,
  [myProvenanceSlice.name]: myProvenanceSlice.reducer,
  [myAugmentationsSlice.name]: myAugmentationsSlice.reducer,
  [dashboardSidebarSlice.name]: dashboardSidebarSlice.reducer,
  ...homeArtistsSlice,
  ...homeArtworksSlice,
  ...nearbyArtistsSlice,
  ...nearbyGalleriesSlice,
  ...favoritesSlice,
  ...recognitionsV2Slice,
  ...followersDashboardSlice,
  ...collectorsDashboardSlice,
  ...entitiesSlice,
  ...profileGallerySlice,
  ...profileCollectorSlice,
  ...favoritesModalSlice,
  ...recognitionsModalSlice,
  ...recognitionsSlice,
  ...artistProfileArtworksSlice,
  ...shopArtworksSlice,
  ...artistManageArtworksSlice,
  ...artShowArtworksSlice,
  ...profileMuseumArtworksSlice,
  ...notificationCenterSlice,
  [collectionsPageSlice.name]: collectionsPageSlice.reducer,
  [persistentVerticalScrollSlice.name]: persistentVerticalScrollSlice.reducer,
  [interestsSlice.name]: interestsSlice.reducer,
  [backgroundSlice.name]: backgroundSlice.reducer,
  [priceRangesSlice.name]: priceRangesSlice.reducer,
  [locationSlice.name]: locationSlice.reducer,
  [destinationsSlice.name]: destinationsSlice.reducer,
  [socialLinksSlice.name]: socialLinksSlice.reducer,
  [artistProfileDetailsSlice.name]: artistProfileDetailsSlice.reducer,
  [recognitionsPageSlice.name]: recognitionsPageSlice.reducer,
  [artFeedNavbarSlice.name]: artFeedNavbarSlice.reducer,
  [adminWidgetSlice.name]: adminWidgetSlice.reducer,
  [pullDownSlice.name]: pullDownSlice.reducer,
  [consumerProductMenuButtonSlice.name]: consumerProductMenuButtonSlice.reducer,
  [consumerProductFTuSlice.name]: consumerProductFTuSlice.reducer,
  [contextFlagSlice.name]: contextFlagSlice.reducer,
  [darkModeSlice.name]: darkModeSlice.reducer,
  [favoritesEngagementSlice.name]: favoritesEngagementSlice.reducer,
  [recognitionsEngagementSlice.name]: recognitionsEngagementSlice.reducer,
  [chatSlice.name]: chatSlice.reducer,
  [geolocationSlice.name]: geolocationSlice.reducer,
  [suggestedTagsSlice.name]: suggestedTagsSlice.reducer,
  [artistSidebarV2Slice.name]: artistSidebarV2Slice.reducer,
  [permissionsStateSlice.name]: permissionsStateSlice.reducer,
  [screenspaceSlice.name]: screenspaceSlice.reducer,
  [bottomNavbarSlice.name]: bottomNavbarSlice.reducer,
  [artworkDetailsSidebarSlice.name]: artworkDetailsSidebarSlice.reducer,
  [shoppingCartSlice.name]: shoppingCartSlice.reducer,
  [authSlice.name]: authSlice.reducer,
  [kaliAutomatizationSlice.name]: kaliAutomatizationSlice.reducer,
  [artistSpotlightSlice.name]: artistSpotlightSlice.reducer,
  [subscriptionsSlice.name]: subscriptionsSlice.reducer,
  [dynamicCVSlice.name]: dynamicCVSlice.reducer,
  [launchedMyStudioModalSlice.name]: launchedMyStudioModalSlice.reducer,
  [biolinkSectionsSlice.name]: biolinkSectionsSlice.reducer,
  [onboardingArtworkSlideshowSlice.name]: onboardingArtworkSlideshowSlice.reducer,
});

const composeEnhancers =
  ((window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true })) ||
  compose;

const persistConfig = {
  key: 'root',
  storage,
  whitelist: [persistentSlice.name, appNotificationsSlice.name, shoppingCartSlice.name],
};

const persistedReducer = persistReducer(persistConfig, combinedReducer);

export const store = createStore(persistedReducer, composeEnhancers(applyMiddleware(thunk)));

export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
