import { CurrencyTypeEnum } from 'common/components/CurrencySelector/CurrencySelector.formik';
import { BrowserLocale } from 'common/hooks/useLanguage/types';
import { TypeUtils } from 'common/utils/generalTypeUtils';

/**
 * https://en.wikipedia.org/wiki/ISO_4217
 */
export enum CurrencyIsoCodesEnum {
  'USD' = 'USD',
  'CAD' = 'CAD',
  'EUR' = 'EUR',
  'GBP' = 'GBP',
  'ETH' = 'ETH',
  'BTC' = 'BTC',
  'RON' = 'RON',
}

const currencySymbolsToCurrencyCodes: Record<CurrencyTypeEnum, CurrencyIsoCodesEnum> = {
  $: CurrencyIsoCodesEnum.USD,
  C$: CurrencyIsoCodesEnum.CAD,
  '£': CurrencyIsoCodesEnum.GBP,
  Ξ: CurrencyIsoCodesEnum.ETH,
  '€': CurrencyIsoCodesEnum.EUR,
  '₿': CurrencyIsoCodesEnum.BTC,
  lei: CurrencyIsoCodesEnum.RON,
};

const currencyCodesToSymbols: Record<CurrencyIsoCodesEnum, string> = {
  [CurrencyIsoCodesEnum.BTC]: CurrencyTypeEnum['₿'],
  [CurrencyIsoCodesEnum.ETH]: CurrencyTypeEnum.Ξ,
  [CurrencyIsoCodesEnum.RON]: CurrencyTypeEnum.lei,
  [CurrencyIsoCodesEnum.CAD]: 'CA$',
  [CurrencyIsoCodesEnum.USD]: 'US$',
  [CurrencyIsoCodesEnum.GBP]: CurrencyTypeEnum['£'],
  [CurrencyIsoCodesEnum.EUR]: CurrencyTypeEnum['€'],
};

const replaceCurrencyCodeWithSymbol = (formattedValue: string, amount: number) => {
  let finalFormattedValue = formattedValue;
  const codes = TypeUtils.Object.keys(currencyCodesToSymbols);
  codes.forEach((c) => {
    if (!formattedValue.includes(c)) {
      return;
    }

    const symbol = currencyCodesToSymbols[c];
    if (amount === 1 && symbol === CurrencyTypeEnum.lei) {
      finalFormattedValue = formattedValue.replace(c, 'leu');
    } else {
      finalFormattedValue = formattedValue.replace(c, `${symbol}`);
    }
  });

  return finalFormattedValue;
};

const currencyFormatter = (
  currency?: CurrencyTypeEnum,
  amount?: number,
  options?: {
    preferredMinimumFractionDigits?: number;
    preferredLocale?: BrowserLocale;
    showZeroAmount?: boolean;
  }
): {
  formattedAmount: string | null;
} => {
  const isoCurrency = currencySymbolsToCurrencyCodes[currency || '$'];

  const hasDecimals = amount && amount % 1 !== 0;
  const minimumFractionDigits = options?.preferredMinimumFractionDigits ?? (hasDecimals ? 2 : 0);
  const finalLocale = options?.preferredLocale ?? 'en-US';
  const formatter = new Intl.NumberFormat(finalLocale, {
    style: 'currency',
    currencyDisplay: 'code',
    currency: isoCurrency,
    minimumFractionDigits,
    maximumFractionDigits: 5,
  });

  // we don't want to show 0
  if (amount === undefined || (!options?.showZeroAmount && amount === 0)) {
    return { formattedAmount: null };
  }
  const formatted = formatter.format(amount);
  return { formattedAmount: replaceCurrencyCodeWithSymbol(formatted, amount) };
};

export default currencyFormatter;
