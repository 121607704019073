import PrizeWonBadge from 'common/components/ArtworkCard/common/PrizeWonBadge/PrizeWonBadge';
import { Enum_Position_Type_Enum } from 'common/schema/commonSchemaRemoteTypes';
import React from 'react';
import { isSomeEnum } from 'utils/utilFunctions';
import { artworksArtPrizesType } from 'store/slices/collectorProfile';

type PrizesContainerPropsV2 = {
  artPrizes: artworksArtPrizesType[];
  isVisible: boolean;
};

const PrizesContainer: React.FC<PrizesContainerPropsV2> = ({ artPrizes, isVisible }) => (
  <>
    {!!artPrizes?.length &&
      artPrizes?.map((prize, idx) => (
        <PrizeWonBadge
          key={`${prize.prize}:${prize.artworkId}`}
          artPrizeTitle={prize.prizeTitle}
          place={isSomeEnum(Enum_Position_Type_Enum)(prize.prize) ? prize.prize : undefined}
          slug={prize.prizeSlug}
          offsetTop={!idx ? 10 : idx * 55 + 10}
          isVisible={isVisible}
        />
      ))}
  </>
);

export default PrizesContainer;
