import { getDefaultCountryFilter } from 'common/features/Filters/predefined/Country/store/country.defaults';
import { getDefaultInterestFilter } from 'common/features/Filters/predefined/Interests/store/interests.defaults';
import { CollectorsFiltersState } from './filter.types';

export const getDefaultFilters = (): CollectorsFiltersState => ({
  sort: 'byDate',
  ...getDefaultInterestFilter(),
  ...getDefaultCountryFilter(),
});

export const filterInitialState: CollectorsFiltersState = getDefaultFilters();
