import { interestsFilterHash } from 'common/features/Filters/predefined/Interests/interests.utils';
import useAppSelector from 'common/hooks/useAppSelector';
import { useEffect, useMemo } from 'react';
import useDebouncedState from 'common/hooks/utils/useDebouncedState';
import { countryFilterHash } from 'common/features/Filters/predefined/Country/country.utils';
import useAnalytics from 'common/features/Analytics/Analytics';
import { CollectorsFiltersHash, LOADING_STATES } from '../../store/collectors/list.types';
import { CollectorsFiltersState } from '../../store/collectors/filter.types';

export const extractIdFromIdentifier = (identifier: CollectorsFiltersHash) =>
  identifier.substring(identifier.lastIndexOf('_') + 1);

// exported for use in the initial state
export const filtersHash = (state: CollectorsFiltersState, suffix: string | number = ''): CollectorsFiltersHash =>
  // using template string to match typing
  `${state.sort}_${[interestsFilterHash(state.interests), countryFilterHash(state.country), suffix].join(
    '_'
  )}` as const;

export const useCollectorsFiltersIdentifier = (contextId?: string | number): CollectorsFiltersHash => {
  const filters = useAppSelector((store) => store.collectorsDashboard.filter);
  const currentIdentifier = useMemo(() => filtersHash(filters, contextId), [filters, contextId]);
  const analytics = useAnalytics();
  const [appliedIdentifier, setAppliedIdentifier] = useDebouncedState(currentIdentifier, 750);

  useEffect(() => {
    analytics.postData('SUCCESS', 'CONSUMER_PRODUCT_FOOTER', 'CLICK_FILTER_APPLY', {
      filterSort: filters,
    });
  }, [appliedIdentifier]);

  const listStatus = useAppSelector((store) => store.collectorsDashboard.list.data.listed.status);
  useEffect(() => {
    if (LOADING_STATES.includes(listStatus)) return;

    setAppliedIdentifier(currentIdentifier);
  }, [currentIdentifier, listStatus]);
  return appliedIdentifier;
};
