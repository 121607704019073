import { getDefaultCountryFilter } from 'common/features/Filters/predefined/Country/store/country.defaults';
import { getDefaultInterestFilter } from 'common/features/Filters/predefined/Interests/store/interests.defaults';
import { getDefaultPriceRangeFilter } from 'common/features/Filters/predefined/PriceRange/store/priceRange.defaults';
import { getDefaultRefreshFilter } from 'common/features/Filters/predefined/Refresh/refresh.defaults';
import { getDefaultTitleFilter } from 'common/features/Filters/predefined/Title/store/title.defaults';
import { getDefaultRadiusFilter } from 'stacks/Nearby/RadiusFilter/store/radius.defaults';
import { NearbyArtistsFiltersState } from './filter.types';

export const getDefaultFilters = (): NearbyArtistsFiltersState => ({
  sort: 'byDate',
  ...getDefaultInterestFilter(),
  ...getDefaultPriceRangeFilter(),
  ...getDefaultCountryFilter(),
  ...getDefaultTitleFilter(),
  ...getDefaultRefreshFilter(),
  ...getDefaultRadiusFilter(),
  ...{ isBoostSkewed: true },
});

export const filterInitialState: NearbyArtistsFiltersState = getDefaultFilters();
