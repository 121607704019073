import { CollectionsListType } from 'store/slices/collectionListModifiers';
import { categoriesButtonsListTypeConfig, getAppliedCategoriesFilter } from './categories';
import { getAppliedSort, sortButtonsListTypeConfig } from './sort';
import { AppliedListingModifier, filterTypes, ListingModifierButtonsConfig } from './types';

export const listModifierDefaultFunctions = {
  SORT: getAppliedSort,
  CATEGORIES: getAppliedCategoriesFilter,
};

export const groupTitlesKeys: { [key in filterTypes]: string } = {
  SORT: 'filterStrings.sort.title',
  CATEGORIES: 'filterStrings.filter.collections.type',
};

export const groupTitlesFeaturedKeys: { [key in filterTypes]: string } = {
  SORT: 'filterStrings.sort.title',
  CATEGORIES: 'filterStrings.filter.collections',
};

export type ListModifierDefaultsParams = {
  variant?: CollectionsListType;
};

type DefaultModifiers = { [key in filterTypes]: AppliedListingModifier };

export const getListModifierDefaults = (params: ListModifierDefaultsParams): DefaultModifiers => ({
  SORT: listModifierDefaultFunctions.SORT(0, params.variant),
  CATEGORIES: listModifierDefaultFunctions.CATEGORIES(params.variant),
});

export const filtersButtonsConfig: {
  [filterTypeKey in filterTypes]: {
    [listTypeKey in CollectionsListType]: ListingModifierButtonsConfig;
  };
} = {
  SORT: sortButtonsListTypeConfig,
  CATEGORIES: categoriesButtonsListTypeConfig,
};

export const visibleFilters: { [listTypeKey in CollectionsListType]: filterTypes[] } = {
  COLLECTIONS: ['SORT', 'CATEGORIES'],
};

export const visibleFiltersFeatured: { [listTypeKey in CollectionsListType]: filterTypes[] } = {
  COLLECTIONS: ['CATEGORIES'],
};
