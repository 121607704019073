import React, { useMemo, useRef } from 'react';
import { ArtistInfoChipProps } from 'common/components/ArtistInfoChip/ArtistInfoChip';
import { Grid } from '@material-ui/core';
import { SkeletonImageTypeProps } from 'common/components/Image/SkeletonImage';
import ArtistCardComponents from '../common';
import CustomCardComponents from './components';
import Links from '../../Links/Links';

export type GenericArtistCardProps = {
  cardTargetSrc?: string;
  onImagesLoadEnd?: () => void;
  artistChipProps?: ArtistInfoChipProps;
  tripleImageProps?: [
    Omit<SkeletonImageTypeProps, 'onImgLoadEnd'>,
    Omit<SkeletonImageTypeProps, 'onImgLoadEnd'>,
    Omit<SkeletonImageTypeProps, 'onImgLoadEnd'>
  ];
  onCardClick?: () => void;
};

export const GenericArtistCard: React.FC<React.PropsWithChildren<GenericArtistCardProps>> = ({
  cardTargetSrc = '#',
  onImagesLoadEnd,
  artistChipProps,
  tripleImageProps,
  children,
}) => {
  const loaded = useRef(0);
  const commonImgProps = useMemo(
    () => ({
      onImgLoadEnd: () => {
        loaded.current += 1;

        if (loaded.current < 3 || !onImagesLoadEnd) {
          return;
        }

        onImagesLoadEnd();
      },
    }),
    [onImagesLoadEnd]
  );

  return (
    <Links.UnstyledAnchor to={cardTargetSrc}>
      <CustomCardComponents.TopBottomLayout>
        <ArtistCardComponents.TripleImageLayout>
          <ArtistCardComponents.SoloImage {...(tripleImageProps?.[0] ?? {})} {...commonImgProps} />
          <ArtistCardComponents.SoloImage {...(tripleImageProps?.[1] ?? {})} {...commonImgProps} />
          <ArtistCardComponents.SoloImage {...(tripleImageProps?.[2] ?? {})} {...commonImgProps} />
        </ArtistCardComponents.TripleImageLayout>
        <ArtistCardComponents.FooterLayout>
          <Grid item style={{ minWidth: '40%' }}>
            <CustomCardComponents.ArtistInfoChip {...(artistChipProps ?? {})} />
          </Grid>
          <Grid item style={{ flexShrink: 0 }}>
            {children}
          </Grid>
        </ArtistCardComponents.FooterLayout>
      </CustomCardComponents.TopBottomLayout>
    </Links.UnstyledAnchor>
  );
};
