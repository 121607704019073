import { TypeUtils } from 'common/utils/generalTypeUtils';
import { isSomeEnum } from 'utils/utilFunctions';
import { getDefaultSizeFilter } from './store/size.defaults';
import { SizeEnum, SizeFilterState } from './store/size.types';

export const defaultSizeOptions = [SizeEnum.ALL, SizeEnum.SMALL, SizeEnum.MEDIUM, SizeEnum.LARGE];

export const sizeFilterHash = (options: SizeFilterState['size']): string =>
  TypeUtils.Object.keys(options ?? {})
    .filter((size) => !!options[size])
    .sort()
    .join('_');

const SERIALIZATION_KEY = 'size';

export const sizeFilterSerialize = (options: SizeFilterState): string => {
  const serialized = new URLSearchParams();
  serialized.set(
    SERIALIZATION_KEY,
    JSON.stringify(
      TypeUtils.Object.keys(options.size ?? {})
        .filter((size) => !!options.size[size])
        .sort()
    )
  );
  return serialized.toString();
};

export const sizeFilterDeserialize = (serializedFilters: string): SizeFilterState => {
  const serialized = new URLSearchParams(serializedFilters);
  const sizeOptions = serialized.get(SERIALIZATION_KEY);
  const sizeFilter = getDefaultSizeFilter();
  if (!sizeOptions) {
    return sizeFilter;
  }
  try {
    const parsedSize = JSON.parse(sizeOptions);
    if (!Array.isArray(parsedSize)) {
      return sizeFilter;
    }
    sizeFilter.size[SizeEnum.ALL] = false;
    parsedSize.forEach((size) => {
      if (!isSomeEnum(SizeEnum)(size)) {
        return;
      }
      sizeFilter.size[size] = true;
    });
  } catch (e) {
    console.warn('Failed to parse size filter', e);
  }
  return sizeFilter;
};
