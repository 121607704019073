import { Maybe, Profile_Location, Profile_Settings, Profile_Phones } from 'common/schema/commonSchemaRemoteTypes';
import { useMemo } from 'react';
import { ProfileSubType } from 'store/slices/account';
import useAppSelector from './useAppSelector';

export type ProfileType = 'ARTIST' | 'GALLERY' | 'COLLECTOR';

export type SelectedProfile = {
  contextId: number;
  profileId: number;
  handle: string;
  has_used_card: boolean;
  title: string;
  type: ProfileType;
  displayType?: string;
  profilePhoto?: string;
  settings: Pick<Profile_Settings, 'id' | 'preferred_language' | 'currency' | 'crop_image'>;
  locations: Pick<
    Profile_Location,
    | 'name'
    | 'country'
    | 'city'
    | 'address'
    | 'zip_code'
    | 'id'
    | 'region'
    | 'location'
    | 'location_map_id'
    | 'city_id'
    | 'WorldCity'
  >[];
  phones: Pick<Profile_Phones, 'phone'>[];
  firstName?: string;
  lastName?: string;
  onboardingStep?: number;
  profileSubType?: ProfileSubType;
  statement?: string;
  quote?: string;
  description?: string;
  email?: string;
} & Record<string, any>;

export const useSelectedProfile = (): Maybe<SelectedProfile> => {
  const profileSubType = useAppSelector((store) => store.account.profileSubType);
  const profiles = useAppSelector((store) => store.account.profiles);
  const selectedProfileId = useAppSelector((store) => store.account.selectedProfileId);

  return useMemo(() => {
    const profile = profiles?.find((someProfile) => someProfile.profileId === selectedProfileId);
    if (!profile) {
      return null;
    }
    return { ...profile, profileSubType };
  }, [profileSubType, profiles, selectedProfileId]);
};
