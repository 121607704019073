import { IState } from '@old/shared/LocationInput/hooks/useStates';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface StatesState {
  list: IState[];
}

const initialState: StatesState = {
  list: [],
};

const statesSlice = createSlice({
  name: 'states',
  initialState,
  reducers: {
    SET_STATES: (state, action: PayloadAction<IState[]>) => {
      state.list = action.payload;
    },
  },
});

export const statesActions = statesSlice.actions;
export default statesSlice;
