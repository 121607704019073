import firebase from 'firebase';
import FirebaseState from '.';
import { AuthStatusEnum } from '../AuthHandler/types';

// Used to determine if the app is in a final authentication state.
export const FINAL_AUTH_STATUS = [AuthStatusEnum.REGISTERED, AuthStatusEnum.LOGGED_IN, AuthStatusEnum.LOGGED_OUT];
// Used to determine if the app is in an onboarding state. This will be expanded in the future to include multiple onboarding steps.
export const ONBOARDING_AUTH_STATUS = [AuthStatusEnum.REGISTERED];
// This is used by Apollo before sending a request to renew JWT if needed (Should include all states except LOGGED_OUT and PENDING)
export const STATUS_WITH_JWT = Object.values(AuthStatusEnum).filter(
  (status) => ![AuthStatusEnum.LOGGED_OUT, AuthStatusEnum.PENDING].includes(status)
);

export const updateFirebaseJWTandClaims = async (): Promise<void> => {
  if (!FirebaseState.account) {
    return;
  }

  const firebaseUser = firebase.auth().currentUser;
  if (firebaseUser) {
    const fetchTokenAndClaims = async (): Promise<void> => {
      await firebaseUser.reload();
      const idTokenResult = await firebaseUser.getIdTokenResult(true);

      if (idTokenResult.claims.hasura) {
        FirebaseState.account = firebaseUser;

        FirebaseState.jwt = idTokenResult.token;
        FirebaseState.claims = idTokenResult.claims.hasura;
        FirebaseState.firebaseUserId = idTokenResult.claims.hasura.user_id;
        FirebaseState.jwtExpirationDate = new Date(idTokenResult.expirationTime).getTime();
      } else {
        // Introduce a delay before retrying
        await new Promise((resolve) => setTimeout(resolve, 500)); // Wait for 1 second before re-checking
        await fetchTokenAndClaims();
      }
    };

    await fetchTokenAndClaims();
  }
};
