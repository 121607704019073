import { SortOptionsEnum } from 'common/features/VirtualList/VirtualList.config';
import { Enum_Context_Type_Enum, Scalars } from 'common/schema/commonSchemaRemoteTypes';
import { isSomeEnum } from 'utils/utilFunctions';
import { RecognitionsModalSort } from './filter.types';

export type RecognitionsModalFiltersHash = `${SortOptionsEnum}_${string}`;

export const listingDirections = ['BACKWARD', 'FORWARD'] as const;
export type ListDirection = (typeof listingDirections)[0] | (typeof listingDirections)[1];

export const loadingIdentifier = 'LOADING' as const;
export type ListLoadingId = typeof loadingIdentifier;

export type ListLoaderStatus = 'COLD' | 'FINISHED' | 'IDLE' | `${ListLoadingId}_${ListDirection}`;

export type ListWindowLoaderStateType<ValuesType, StatusType> = {
  batchSize: number;
  values: Array<ValuesType>;
  status: StatusType;
  hasMore: [boolean, boolean]; // [backward, forward]
};

export type ListLoaderStateType<Typename extends RecognitionsModalSort, CursorType, IdType = Scalars['bigint']> = {
  __typename: Typename;
  listed: ListWindowLoaderStateType<CursorType, ListLoaderStatus>;
  detailed: ListWindowLoaderStateType<IdType, ListLoaderStatus>;
  cursor: [CursorType, CursorType]; // [backward, forward]
  detailedToListedStartOffset: number;
  lastScrollPosition: number;
};

export type RecognitionsModalListByDateCursor = {
  recognizedByType: Enum_Context_Type_Enum;
  recognizedBy: Scalars['bigint'];
  recognizedAt: Scalars['date'];
};
export type RecognitionsModalListMostRecent = ListLoaderStateType<
  SortOptionsEnum.MOST_RECENT,
  RecognitionsModalListByDateCursor
>;

export type MostRecentListActionArgs =
  | [ListDirection, RecognitionsModalListMostRecent['__typename'], RecognitionsModalListMostRecent['listed']['values']];

export type MostRecentDetailActionArgs =
  | [
      ListDirection,
      RecognitionsModalListMostRecent['__typename'],
      RecognitionsModalListMostRecent['detailed']['values']
    ];
export type RecognitionsModalListState = {
  identifier: RecognitionsModalFiltersHash;
  type: RecognitionsModalSort;
} & {
  type: SortOptionsEnum.MOST_RECENT;
  data: RecognitionsModalListMostRecent;
};

export type RecognitionsModalListingCursor = RecognitionsModalListByDateCursor;

export const isRecognitionsModalListByDateCursor = (
  cursor: RecognitionsModalListingCursor
): cursor is RecognitionsModalListByDateCursor =>
  (cursor as RecognitionsModalListByDateCursor)?.recognizedAt !== undefined;

export const isContextTypeEnum = isSomeEnum(Enum_Context_Type_Enum);

export const LOADING_STATES: ListLoaderStatus[] = ['LOADING_BACKWARD', 'LOADING_FORWARD'];
