import * as SentryClient from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { ENV_TYPE, RELEASE } from 'utils/constants';

type KnownEnv = 'local' | 'staging' | 'production';

export const SentryConfig: {
  enabled: boolean;
  logLocal: boolean;
  envTypeSampling: Record<KnownEnv, number>;
} = {
  enabled: false,
  logLocal: false,
  envTypeSampling: {
    production: 1,
    staging: 0,
    local: 0,
  },
};

const getEnvList: () => Array<KnownEnv> = () => {
  if (SentryConfig.logLocal) {
    return ['local', 'production', 'staging'];
  }
  return ['production', 'staging'];
};

const isKnownEnv = (env: string): env is KnownEnv => typeof SentryConfig.envTypeSampling[env as KnownEnv] === 'number';

const setupSentry = (): void => {
  let tracesSampleRate = 0;
  const environment = ENV_TYPE;
  if (isKnownEnv(environment) && getEnvList().includes(environment)) {
    SentryConfig.enabled = true;
    tracesSampleRate = SentryConfig.envTypeSampling[environment];
  }

  const initialScope = new SentryClient.Scope();
  initialScope.setTag('auth', 'anon');

  SentryClient.init({
    dsn: 'https://421b1c082b9e4c9a8e72727cdaf58d1c@o1221228.ingest.sentry.io/6364364',
    integrations: [new BrowserTracing()],

    enabled: SentryConfig.enabled,
    environment,
    initialScope,
    release: RELEASE,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate,

    // beforeSend: (event, hint) => {
    //   console.log('BEFORE SEND SENTRY EVENT', event);
    //   console.log('BEFORE SEND SENTRY HINT', hint);
    //   return null;
    // },

    maxBreadcrumbs: 50,
    beforeBreadcrumb: (breadcrumb) => {
      if (breadcrumb.type === 'http' && breadcrumb.category !== 'graphql') {
        return null;
      }
      if (
        breadcrumb.category === 'console' &&
        (!breadcrumb.level ||
          ![
            SentryClient.Severity.Error,
            SentryClient.Severity.Fatal,
            SentryClient.Severity.Critical,
            SentryClient.Severity.Info,
          ].includes(breadcrumb.level))
      ) {
        return null;
      }
      return breadcrumb;
    },

    beforeSend: (event) => {
      if (event.message?.includes('Inserted code does not match with the validation code for email')) {
        console.info('ignored wrong validation code event');
        return null;
      }
      return event;
    },

    // mutually exclusive with the samplingRate above
    // tracesSampler: (trace) => {
    //   console.log('BEFORE SEND TRACE SENTRY', trace);
    //   return 1;
    // },
  });

  // eslint-disable-next-line no-console
  console.log(`Sentry logging is: ${SentryConfig.enabled ? 'ENABLED' : 'DISABLED'}`);
};

export default setupSentry;
