import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MarkerData } from 'common/components/InteractiveMap/utils/interactiveMapTypes';
import { placeToMarkerData } from 'common/components/InteractiveMap/utils/interactiveMapUtils';
import { Place } from 'common/schema/commonSchemaRemoteTypes';
import { ViewState } from 'react-map-gl';

export interface destinationsState {
  places: MarkerData[];
  mapCenterRadiusInMeters: number;
  initialMapCenter: Pick<ViewState, 'latitude' | 'longitude'> | null;
  userDeniedLocationPermissions: boolean;
  shouldFetchPlaces: boolean;
}

const initialState: destinationsState = {
  places: [],
  mapCenterRadiusInMeters: 15000,
  initialMapCenter: null,
  userDeniedLocationPermissions: false,
  shouldFetchPlaces: true,
};

const destinationsSlice = createSlice({
  name: 'destinations',
  initialState,
  reducers: {
    RESET_PLACES: (state) => {
      state.places = [];
    },
    SET_PLACES: (state, action: PayloadAction<Place[]>) => {
      state.places = [...action.payload.map((place) => placeToMarkerData(place))];
      state.shouldFetchPlaces = false;
    },
    SET_INITIAL_MAP_CENTER: (state, action: PayloadAction<Pick<ViewState, 'latitude' | 'longitude'>>) => {
      state.initialMapCenter = action.payload;
    },
    SET_USER_DENIED_LOCATION_PERMISSION: (state, action: PayloadAction<boolean>) => {
      state.userDeniedLocationPermissions = action.payload;
    },
    SET_MAP_CENTER_RADIUS_IN_METERS: (state, action: PayloadAction<number>) => {
      state.mapCenterRadiusInMeters = action.payload;
    },
    REFETCH_PLACES: (state) => {
      state.shouldFetchPlaces = true;
    },
  },
});

export const destinationsActions = destinationsSlice.actions;
export default destinationsSlice;
