import { ChannelEntity, UserEntity } from 'common/@pubnub/components';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Pubnub, { MessageEvent } from 'pubnub';
import { TypeUtils } from 'common/utils/generalTypeUtils';
import { buildChannelName } from '../utils/buildChannelName';

export interface ChatState {
  channels?: ChannelEntity[];
  channelsMessages: Record<string, MessageData>;
  unsentMessages: Record<string, string>;
  pubnubToken: string;
  unreadCount: number;
  isChatListOpen: boolean;
  isChannelLoading: boolean;
  messagingSenderContextId: number; // when 0, chat is not ready to run - we don't have a sender
  messagingTargetContextId: number; // when 0, it means the messaging panel is closed
  currentChannel?: string;
  senderEntity?: UserEntity;
}

const getInitialState = (): ChatState => ({
  channels: undefined,
  channelsMessages: {},
  unsentMessages: {},
  pubnubToken: '',
  unreadCount: 0,
  isChatListOpen: false,
  isChannelLoading: false,
  messagingSenderContextId: 0,
  messagingTargetContextId: 0,
  currentChannel: undefined,
});

export type MessageData = {
  createdAt: string;
  id: string;
  text: string;
  type: string;
  timetoken: string;
  unreadCount: number;
  isMine?: boolean;
};

export const chatSlice = createSlice({
  name: 'chat',
  initialState: getInitialState(),
  reducers: {
    RESET: () => getInitialState(),
    ADD_MESSAGE: (state, action: PayloadAction<MessageEvent>) => {
      const { message, channel, timetoken, publisher } = action.payload;
      if (!message || !channel) {
        return;
      }

      state.channelsMessages[channel] = {
        ...message,
        timetoken,
        unreadCount:
          state.messagingSenderContextId.toString() === publisher || state.currentChannel === channel ? 0 : 1,
        isMine: state.messagingSenderContextId.toString() === publisher,
      };

      state.unreadCount = Object.values(state.channelsMessages).reduce((sum, msg) => sum + msg.unreadCount, 0);
    },
    ADD_MESSAGES: (
      state,
      action: PayloadAction<{
        messages: Pubnub.FetchMessagesResponse;
        channels: ChannelEntity[];
        userContextId: number;
      }>
    ) => {
      const messagesToAdd = TypeUtils.Object.values(action.payload.messages.channels)
        .flat()
        .reduce((acc, singleMessage) => {
          const { message, channel, timetoken } = singleMessage;
          if (!message || !channel) {
            return acc;
          }
          const unreadCount = Math.min(1, state.channelsMessages[channel]?.unreadCount ?? 0);
          return {
            ...acc,
            [channel]: {
              ...message,
              timetoken,
              unreadCount,
              isMine: message.sender?.id === state.messagingSenderContextId.toString(),
            },
          };
        }, {} as Record<string, MessageData>);
      state.channelsMessages = {
        ...state.channelsMessages,
        ...messagesToAdd,
      };
    },
    ADD_UNREAD_COUNTS: (state, action: PayloadAction<Record<string, number>>) => {
      const { payload } = action;
      Object.entries(payload).forEach(([channel, unreadCount]) => {
        if (!channel || !unreadCount) {
          return;
        }
        state.channelsMessages[channel] = state.channelsMessages[channel] ?? {};
        state.channelsMessages[channel].unreadCount = unreadCount ? 1 : 0;
      });
      state.unreadCount = Object.values(state.channelsMessages).reduce((sum, msg) => sum + msg.unreadCount, 0);
    },
    READ_CHANNEL: (state, action: PayloadAction<{ channel: string }>) => {
      const { channel } = action.payload;
      if (!channel || !state.channelsMessages[channel]) {
        return;
      }
      state.channelsMessages[channel].unreadCount = 0;
      state.unreadCount = Object.values(state.channelsMessages).reduce((sum, msg) => sum + msg.unreadCount, 0);
    },
    SET_CHANNELS: (state, action: PayloadAction<ChannelEntity[]>) => {
      state.channels = action.payload;
    },
    SET_CHANNELS_LOADING: (
      state,
      action: PayloadAction<{
        loading: boolean;
      }>
    ) => {
      state.isChannelLoading = action.payload.loading;
    },
    ADD_CHANNEL: (state, action: PayloadAction<ChannelEntity>) => {
      if (!state.channels) {
        state.channels = [action.payload];
        return;
      }
      const existingChannelIdx = state.channels.findIndex((c) => c.name === action.payload.name) ?? -1;
      const newChannelList =
        existingChannelIdx === -1 ? [...state.channels] : state.channels.filter((c) => c.name !== action.payload.name);
      newChannelList?.unshift(existingChannelIdx === -1 ? action.payload : state.channels[existingChannelIdx]);
      state.channels = newChannelList;
    },
    INIT_SENDER: (state, action: PayloadAction<{ contextId?: number; senderEntity?: UserEntity }>) => {
      state.messagingSenderContextId = action.payload.contextId ?? 0;
      state.currentChannel = undefined;
      state.senderEntity = action.payload.senderEntity;
      if (state.messagingSenderContextId && state.messagingTargetContextId) {
        state.currentChannel = buildChannelName({
          userContextId: state.messagingSenderContextId,
          targetContextId: state.messagingTargetContextId,
        });
      }
    },
    START_DM: (state, action: PayloadAction<{ targetContextId: number }>) => {
      state.messagingTargetContextId = action.payload.targetContextId;
      state.currentChannel = undefined;
      if (state.messagingSenderContextId && state.messagingTargetContextId) {
        state.currentChannel = buildChannelName({
          userContextId: state.messagingSenderContextId,
          targetContextId: state.messagingTargetContextId,
        });
      }
    },
    CLOSE_DM: (state) => {
      state.messagingTargetContextId = 0;
    },
    TOGGLE_CHAT_LIST: (state) => {
      state.isChatListOpen = !state.isChatListOpen;
    },
    SET_PUBNUB_TOKEN: (state, action: PayloadAction<string>) => {
      state.pubnubToken = action.payload;
    },
    CLOSE_AND_DEFAULT_LIST: (state) => {
      state.isChatListOpen = false;
    },
    ADD_UNSENT_MESSAGE: (state, action: PayloadAction<{ targetContextId: number; message: string }>) => {
      const { targetContextId, message } = action.payload;
      state.unsentMessages[targetContextId] = message;
    },
    CLEAR_UNSENT_MESSAGE: (state, action: PayloadAction<number>) => {
      const targetContextId = action.payload;
      delete state.unsentMessages[targetContextId];
    },
  },
});

export const chatActions = chatSlice.actions;
