import { LIST_RADIUS_SEARCH_OPTIONS } from 'stacks/Nearby/NearbyRadiusDropdown';
import { filtersHash } from '../hooks/useNearbyGalleriesFiltersIdentifier';
import { getDefaultFilters } from './filter.defaults';
import {
  NearbyGalleriesListState,
  ListDirection,
  listingDirections,
  ListWindowLoaderStateType,
  NearbyGalleryListByTitle,
} from './list.types';

export const defaults = {
  byTitle: (): {
    type: 'byTitle';
    data: NearbyGalleryListByTitle;
  } => ({
    type: 'byTitle',
    data: {
      __typename: 'byTitle',
      lastScrollPosition: 0,
      detailedToListedStartOffset: 0,
      cursor: [
        { title: 'zzzzzzzzz', contextId: 0 },
        { title: '', contextId: 0 },
      ],
      listed: {
        batchSize: 64,
        status: 'COLD',
        values: [],
        hasMore: [false, false],
      },
      detailed: {
        batchSize: 16,
        status: 'COLD',
        values: [],
        hasMore: [false, false],
      },
    },
  }),
};

const defaultFilters = getDefaultFilters();
export const listInitialState: NearbyGalleriesListState = {
  identifier: filtersHash({ ...defaultFilters, currentRadius: LIST_RADIUS_SEARCH_OPTIONS[0] }),
  ...defaults[defaultFilters.sort](),
};

export const hasMore = (loader: ListWindowLoaderStateType<unknown, unknown>, direction: ListDirection): boolean =>
  loader.hasMore[listingDirections.indexOf(direction)];
