import { SortState } from 'common/features/Filters/predefined/SortGroup/store/sort.types';
import { ListingIdentifier, filterHashResetMarker } from 'common/features/VirtualList/components/listReduxFragment';
import { RefreshFilterState } from 'common/features/Filters/predefined/Refresh/refresh.types';
import { getDefaultRefreshFilter } from 'common/features/Filters/predefined/Refresh/refresh.defaults';
import { SortOptionsEnum } from 'common/features/VirtualList/VirtualList.config';
import { createRefreshFilterCaseReducer } from 'common/features/Filters/predefined/Refresh/refresh.reducers';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ByMostRecentSortOptions, NotificationCenterSortOptions } from './listNotificationCenter.slice';

export type NotificationCenterFiltersState = SortState<ByMostRecentSortOptions> & {
  filterHash: ListingIdentifier<ByMostRecentSortOptions>;
} & RefreshFilterState;

export const getHashForNotificationCenterFilters = (
  filters: Omit<NotificationCenterFiltersState, 'filterHash'>,
  customSuffix?: string
): ListingIdentifier<ByMostRecentSortOptions> =>
  `${filters.currentSort}_${[
    filterHashResetMarker, // user-applied filters above this point
    customSuffix ?? 'nosuffix',
    filters.refresh,
  ].join('_')}` as const;

export const getDefaultNotificationCenterFilters = (customSuffix?: string): NotificationCenterFiltersState => {
  const noHashFilterState: Omit<NotificationCenterFiltersState, 'filterHash'> = {
    ...getDefaultRefreshFilter(),
    currentSort: SortOptionsEnum.MOST_RECENT,
  };
  const filtersState: NotificationCenterFiltersState = {
    ...noHashFilterState,
    filterHash: getHashForNotificationCenterFilters(noHashFilterState, customSuffix),
  };
  return filtersState;
};

export const filterInitialState: NotificationCenterFiltersState = getDefaultNotificationCenterFilters();

export const filterNotificationCenterSlice = createSlice({
  name: 'filter',
  initialState: filterInitialState,
  reducers: {
    RESET_NotificationCenter: (_, action: PayloadAction<{ customSuffix?: string }>) => ({
      ...getDefaultNotificationCenterFilters(action.payload.customSuffix),
    }),
    REFRESH_NotificationCenter: createRefreshFilterCaseReducer<NotificationCenterFiltersState>(),
    SORT_NotificationCenter: (state, action: PayloadAction<NotificationCenterSortOptions>) => ({
      ...state,
      currentSort: action.payload,
    }),
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      (action) => !!action?.type && action.type.startsWith('filter/') && action.type.endsWith('_NotificationCenter'),
      (state) => {
        state.filterHash = getHashForNotificationCenterFilters(state);
      }
    );
  },
});
