import { createSlice } from '@reduxjs/toolkit';
import { ROUTES } from 'routes/_definition';
import { BottomNavbarModuleProps, MODULES } from '../utils/BottomNavbarTypes';

type PropsWithKey<T extends MODULES> = BottomNavbarModuleProps[T] & {
  key: string;
};
export type BottomNavbarState = {
  modules: {
    // Ideally we would make the modules Optional, but I was unable to make it work due to typescript error
    [Module in MODULES]?: {
      [Route in ROUTES]?: PropsWithKey<Module> | null;
    };
  };
};
export const bottomNavbarInitialState: BottomNavbarState = {
  modules: {
    [MODULES.CONSUMER_PRODUCT_STYLES]: {
      [ROUTES.LIVE]: null,
      [ROUTES.EXPLORE]: null,
      [ROUTES.NEARBY]: null,
      [ROUTES.FEATURED]: null,
      [ROUTES.LIVE]: null,
    },
    [MODULES.NAVIGATION_BUTTONS]: {
      [ROUTES.LIVE]: null,
      [ROUTES.EXPLORE]: null,
      [ROUTES.NEARBY]: null,
      [ROUTES.FEATURED]: null,
      [ROUTES.LIVE]: null,
    },
  },
};
export const bottomNavbarSlice = createSlice({
  name: 'bottomNavbar',
  initialState: bottomNavbarInitialState,
  reducers: {
    MOUNT_MODULE: <T extends MODULES>(
      state: BottomNavbarState,
      action: {
        payload: {
          module: T;
          page: string;
          props: BottomNavbarModuleProps[T];
        };
      }
    ) => {
      const { module, page, props } = action.payload;
      state.modules[module] = {
        ...state.modules[module],
        [page]: {
          ...props,
          key: module,
        },
      };
    },
  },
});
export const BottomNavbarActions = bottomNavbarSlice.actions;
