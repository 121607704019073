import React, { useMemo } from 'react';
import useAppSelector from 'common/hooks/useAppSelector';
import { getArtworkDimensionsString } from 'stacks/ShowcaseNew/context/ShowcaseDetailsProvider/utils/helpers';
import { artworkTypeNameToEnum } from 'stacks/Dashboard/common/ArtworkPublish/components/common/Details/config/addArtowrkDetailsOptions';
import { Enum_Art_Type_Enum } from 'common/schema/commonSchemaRemoteTypes';
import { useTranslation } from 'react-i18next';
import { CDNUrl } from 'utils/constants';
import { Box } from '@material-ui/core';
import ArtworkArRating from 'common/components/ArtworkArRating/ArtworkArRating';
import Flagged from 'common/features/FeatureFlag/components/Flagged';
import { SwipeContainerComponentsProps } from '.';
import { useSwipeContainerComponentsStyles } from './useSwipeContainerComponents.styles';
import SwipeContainerDetailElement from './SwipeContainerDetailElement';
import SwipeContainerArElement from './SwipeContainerArElement';

const SwipeContainerDetails: React.FC<SwipeContainerComponentsProps> = ({ artworkId }) => {
  const classes = useSwipeContainerComponentsStyles();
  const { t } = useTranslation();
  const { artworkDetails, ar, pendingVuforiaImagePath, vuforiaTargetId } =
    useAppSelector((store) => store.entities.artworkSwipeContainerCache[artworkId]) ?? {};
  const { discipline, depth, width, height, medium, measuringUnit, artType } = artworkDetails ?? {};

  const artworkDimensions = useMemo<string>(
    () =>
      getArtworkDimensionsString({
        depth: depth ?? 0,
        width: width ?? 0,
        height: height ?? 0,
        measuringUnit: measuringUnit ?? '',
      }),
    [depth, width, height, measuringUnit]
  );

  const discplineName = useMemo(() => {
    if (artworkTypeNameToEnum(artType) !== Enum_Art_Type_Enum.Physical) {
      const otherText = t('addArtworkStrings.artDetails.select.discipline.Other');
      if (discipline?.description.includes(`${otherText}: `)) {
        return discipline?.description.replace(`${otherText}: `, '');
      }
    }
    return discipline?.description;
  }, [artType, discipline?.description, t]);

  return (
    <div className={classes.detailsWrapper}>
      <Box className={classes.detailElement}>
        <SwipeContainerDetailElement icon={`${CDNUrl}/images/showcase-3/resize-larger.svg`} text={discplineName} />
      </Box>

      <Box className={classes.detailElement}>
        <SwipeContainerDetailElement
          icon={`${CDNUrl}/images/showcase-3/resize-diagonal.svg`}
          text={
            artworkDimensions
              ? t(`showcase.details.dimensions.dimensionsDescription.${measuringUnit?.toUpperCase()}`, {
                  artworkDimensions,
                })
              : undefined
          }
        />
      </Box>

      <Box className={classes.detailElement}>
        <SwipeContainerDetailElement icon={`${CDNUrl}/images/showcase-3/paint-brush.svg`} text={medium} />
      </Box>

      <Flagged.Inactive feature="arScore">
        <SwipeContainerArElement hasAr={!!ar} />
      </Flagged.Inactive>

      <Flagged.Active feature="arScore">
        <ArtworkArRating
          vuforiaInfo={{
            vuforiaRating: ar,
            targetId: vuforiaTargetId,
            pendingVuforiaImagePath,
          }}
          arIconContainerOverrideClassName={classes.arIconContainerOverride}
          ratingScoreOverrideClassName={classes.ratingScoreOverride}
          showInfoIcon
        />
      </Flagged.Active>
    </div>
  );
};

export default SwipeContainerDetails;
