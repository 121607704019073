import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  appAccess: true,
  galeryAccess: false,
  buyNFTs: true,
};

const permissionsSlice = createSlice({
  initialState,
  name: 'permissions',
  reducers: {
    SET_PERMISSION: (_, action) => ({
      ...action.payload,
    }),
  },
});

export const permissionsActions = permissionsSlice.actions;
export const permissionsReducer = permissionsSlice.reducer;
