import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { getDefaultSizeFilter } from './size.defaults';
import { SizeEnum, SizeFilterState } from './size.types';

type ToggleSizeAction = PayloadAction<{
  size: SizeEnum;
}>;

export const createToggleSizeCaseReducer =
  <State extends SizeFilterState>(): CaseReducer<State, ToggleSizeAction> =>
  (state, action) => {
    const { size } = action.payload ?? {};

    if (size === SizeEnum.ALL) {
      state.size = getDefaultSizeFilter().size;
      return;
    }

    state.size[SizeEnum.ALL] = false;

    state.size[size] = !state.size[size];

    if (!Object.values(state.size).includes(true)) {
      state.size[SizeEnum.ALL] = true;
    }
  };
