import useAnalytics from 'common/features/Analytics/Analytics';
import { useOnScreenImpressions } from 'common/hooks/useOnScreen';
import { useRef, useEffect } from 'react';

// needs to get the ref of the first child of the masonic element (the absolute one with a set top)
const getCardVisibility = (ref: HTMLDivElement | null): null | boolean => {
  const cardElement = ref?.parentElement;
  const gridElement = cardElement?.parentElement;

  if (!cardElement || !gridElement) {
    return null;
  }

  const cardHeight = cardElement.getBoundingClientRect().height;
  const cardTop = Number(cardElement.style.getPropertyValue('top')?.replace('px', '') ?? 0);
  const cardBottom = cardTop + cardHeight;

  const windowHeight = window.innerHeight;

  const gridTop = gridElement.getBoundingClientRect().top;
  const scrollTop = window.scrollY;
  const visibleTop = scrollTop + gridTop;
  const visibleBottom = scrollTop + windowHeight;

  return cardBottom > visibleTop && cardTop < visibleBottom;
};

export const useHomeArtworksCardWasVisible = (
  index: number,
  columnCount: number,
  ref: React.MutableRefObject<HTMLDivElement | null>,
  artistHandle?: string | null,
  artworkId?: number
): {
  wasVisible: boolean;
  wasVisibleAfterMounting: boolean;
  isVisible: boolean;
} => {
  const wasVisible = useRef(index < columnCount * 2);
  useEffect(() => {
    const isVisible = getCardVisibility(ref.current);
    if (!isVisible) {
      return;
    }
    wasVisible.current = wasVisible.current || isVisible;
  }, [ref.current, ref]);
  const { postImpressions } = useAnalytics();
  const isVisible = useOnScreenImpressions(ref, 0.5);

  useEffect(() => {
    if (isVisible) {
      wasVisible.current = true;
    }
  }, [isVisible]);

  useEffect(() => {
    if (artistHandle && isVisible && artworkId) {
      postImpressions({
        hitType: 'SUCCESS',
        eventCategory: 'LIVE_PAGE',
        eventAction: 'IMPRESSIONS',
        tags: {},
        impressions: {
          handle: artistHandle,
          artworks: [artworkId],
          eventTimestamp: new Date().getTime(),
        },
      });
    }
  }, [isVisible, artworkId, artistHandle, postImpressions]);

  return {
    wasVisible: wasVisible.current,
    isVisible,
    wasVisibleAfterMounting: wasVisible.current || isVisible,
  };
};
