import { createSlice, CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { TypeUtils } from 'common/utils/generalTypeUtils';
import { defaultFeatureFlags, FeatureFlagsState, runtimeFlagUpdate } from '../config/features';

const setFlags: CaseReducer<FeatureFlagsState, PayloadAction<Partial<FeatureFlagsState>>> = (oldState, action) => {
  TypeUtils.Object.keys(action.payload).forEach((k) => {
    runtimeFlagUpdate[k] = true;
  });
  return {
    ...oldState,
    ...action.payload,
  };
};

const featureFlagSlice = createSlice({
  name: 'featureFlags',
  initialState: defaultFeatureFlags,
  reducers: {
    setFlags,
  },
});

const featureFlagActions = featureFlagSlice.actions;
export { featureFlagSlice, featureFlagActions };
