import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SuggestedTagsType } from 'common/schema/commonSchemaRemoteTypes';

export interface suggestedTagsState {
  isLoaded: boolean;
  primarySuggestedTags: SuggestedTagsType[];
  secondarySuggestedTags: SuggestedTagsType[];
}

const initialState: suggestedTagsState = {
  primarySuggestedTags: [],
  secondarySuggestedTags: [],
  isLoaded: false,
};

const suggestedTagsSlice = createSlice({
  name: 'suggestedTags',
  initialState,
  reducers: {
    SET_PRIMARY_SECONDARY_TAGS: (
      state,
      action: PayloadAction<{ primaryTags: SuggestedTagsType[]; secondaryTags: SuggestedTagsType[] }>
    ) => {
      state.isLoaded = true;
      state.primarySuggestedTags = action.payload.primaryTags;
      state.secondarySuggestedTags = action.payload.secondaryTags;
    },
    RESET_SUGGESTED_TAGS: () => initialState,
  },
});

export const suggestedTagsActions = suggestedTagsSlice.actions;
export default suggestedTagsSlice;
