import useAppSelector from 'common/hooks/useAppSelector';
import { FeatureFlags, FeatureStatus } from '../config/features';
import { shouldShowFeature } from '../utils/utils';

/**
 * How to use:
 * const [flag1Status, flag2Status] = useFeatureFlag(flag1Status, flag2Status);
 * - gate logic accordingly based on: ../utils > shouldShowFeature()
 * @param flags list of flags to watch
 */
const useFeatureFlag = (...flags: FeatureFlags[]): FeatureStatus[] =>
  useAppSelector(
    (state) => flags.map((flag) => state.featureFlags[flag] || 'inactive'),
    (left, right) => left.join('_') === right.join('_')
  );

export const useShouldShowFeatureFlag = (...flags: FeatureFlags[]): boolean[] =>
  useAppSelector(
    (state) => flags.map((flag) => shouldShowFeature(state.featureFlags[flag] || 'inactive')),
    (left, right) => left.length === right.length && left.findIndex((_, idx) => left[idx] !== right[idx]) === -1
  );

export default useFeatureFlag;
