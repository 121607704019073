import * as coldReset from './cold.reset';
import * as coldStart from './cold.start';
import * as listedRequest from './listed.request';
import * as listedAppend from './listed.append';
import * as detailedRequest from './detailed.request';
import * as detailedAppend from './detailed.append';

const reducers = {
  ...coldReset,
  ...coldStart,
  ...listedRequest,
  ...listedAppend,
  ...detailedRequest,
  ...detailedAppend,
};

export default reducers;
