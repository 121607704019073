import { makeStyles } from '@material-ui/core';

export const loadingLogoAnimationStyles = makeStyles(() => ({
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  svgContainer: {
    display: 'inline-block',
    position: 'relative',
    width: '150px',
    height: '150px',
    verticalAlign: 'middle',
    overflow: 'hidden',
    maxWidth: '150px',
    maxHeight: '150px',
  },
  svgContent: {
    display: 'inline-block',
    position: 'absolute',
    top: 0,
    left: 0,
  },
}));
