import { SortState } from 'common/features/Filters/predefined/SortGroup/store/sort.types';
import { ListingIdentifier, filterHashResetMarker } from 'common/features/VirtualList/components/listReduxFragment';
import { RefreshFilterState } from 'common/features/Filters/predefined/Refresh/refresh.types';
import { ArtistContextIdFilterState } from 'common/features/Filters/predefined/ArtistContextId/store/artistContextId.types';
import { artistContextIdFilterHash } from 'common/features/Filters/predefined/ArtistContextId/artistContextId.utils';
import { getDefaultRefreshFilter } from 'common/features/Filters/predefined/Refresh/refresh.defaults';
import { getDefaultArtistContextIdFilter } from 'common/features/Filters/predefined/ArtistContextId/store/artistContextId';
import { SortOptionsEnum } from 'common/features/VirtualList/VirtualList.config';
import { createRefreshFilterCaseReducer } from 'common/features/Filters/predefined/Refresh/refresh.reducers';
import { createToggleArtistContextIdCaseReducer } from 'common/features/Filters/predefined/ArtistContextId/store/artistContextId.reducers';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TitleFilterState } from 'common/features/Filters/predefined/Title/store/title.types';
import { titleFilterHash } from 'common/features/Filters/predefined/Title/title.utils';
import { getDefaultTitleFilter } from 'common/features/Filters/predefined/Title/store/title.defaults';
import { createFilterTitleCaseReducer } from 'common/features/Filters/predefined/Title/store/title.reducers';
import { ArtistManageArtworksSortOptions } from './listArtistManageArtworks.slice';

export type ArtistManageArtworksFiltersState = SortState<ArtistManageArtworksSortOptions> & {
  filterHash: ListingIdentifier<ArtistManageArtworksSortOptions>;
} & RefreshFilterState &
  ArtistContextIdFilterState &
  TitleFilterState;

export const getHashForArtistManageArtworksFilters = (
  filters: Omit<ArtistManageArtworksFiltersState, 'filterHash'>,
  customSuffix?: string
): ListingIdentifier<ArtistManageArtworksSortOptions> =>
  `${filters.currentSort}_${[
    titleFilterHash(filters.title),
    filterHashResetMarker, // user-applied filters above this point
    artistContextIdFilterHash(filters.selectedArtistContextId),
    customSuffix ?? 'nosuffix',
    filters.refresh,
  ].join('_')}` as const;

export const getDefaultArtistManageArtworksFilters = (customSuffix?: string): ArtistManageArtworksFiltersState => {
  const noHashFilterState: Omit<ArtistManageArtworksFiltersState, 'filterHash'> = {
    ...getDefaultRefreshFilter(),
    ...getDefaultArtistContextIdFilter(),
    ...getDefaultTitleFilter(),
    currentSort: SortOptionsEnum.ARTIST_SELECTION,
  };
  const filtersState: ArtistManageArtworksFiltersState = {
    ...noHashFilterState,
    filterHash: getHashForArtistManageArtworksFilters(noHashFilterState, customSuffix),
  };
  return filtersState;
};

export const filterInitialState: ArtistManageArtworksFiltersState = getDefaultArtistManageArtworksFilters();

export const filterArtistManageArtworksSlice = createSlice({
  name: 'filter',
  initialState: filterInitialState,
  reducers: {
    RESET_ArtistManageArtworks: (state, action: PayloadAction<{ customSuffix?: string }>) => ({
      ...getDefaultArtistManageArtworksFilters(action.payload.customSuffix),
      selectedArtistContextId: state.selectedArtistContextId,
    }),
    REFRESH_ArtistManageArtworks: createRefreshFilterCaseReducer<ArtistManageArtworksFiltersState>(),
    ARTIST_CONTEXT_ID_ArtistManageArtworks: createToggleArtistContextIdCaseReducer<ArtistManageArtworksFiltersState>(),
    SORT_ArtistManageArtworks: (state, action: PayloadAction<ArtistManageArtworksSortOptions>) => ({
      ...state,
      currentSort: action.payload,
    }),
    SET_TITLE_ArtistManageArtworks: createFilterTitleCaseReducer<ArtistManageArtworksFiltersState>(),
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      (action) => !!action?.type && action.type.startsWith('filter/') && action.type.endsWith('_ArtistManageArtworks'),
      (state) => {
        state.filterHash = getHashForArtistManageArtworksFilters(state);
      }
    );
  },
});
