import { CSSProperties } from '@material-ui/styles';
import { Maybe } from 'common/schema/commonSchemaRemoteTypes';

export const squareAspectRatio = '1 / 1';
export const getKnownAspectRatio = (aspectRatio?: Maybe<number>): CSSProperties['aspectRatio'] => {
  if (!aspectRatio) {
    return undefined;
  }
  return `${aspectRatio / 10000} / 1`;
};
