import { combineReducers } from 'redux';
import { listArtistProfileArtworksSlice } from './listArtistProfileArtworks.slice';
import { filterArtistProfileArtworksSlice } from './filterArtistProfileArtworks.slice';
import { displayArtistProfileArtworksSlice } from './displayArtistProfileArtworks.slice';

export const actions = {
  ...listArtistProfileArtworksSlice.actions,
  ...filterArtistProfileArtworksSlice.actions,
  ...displayArtistProfileArtworksSlice.actions,
};

export const artistProfileArtworksSlice = {
  artistProfileArtworks: combineReducers({
    [listArtistProfileArtworksSlice.name]: listArtistProfileArtworksSlice.reducer,
    [filterArtistProfileArtworksSlice.name]: filterArtistProfileArtworksSlice.reducer,
    [displayArtistProfileArtworksSlice.name]: displayArtistProfileArtworksSlice.reducer,
  }),
};
