import { makeStyles } from '@material-ui/core';

export const useFavoritesButtonStyles = makeStyles((theme) => ({
  '@keyframes heart': {
    // '0%': {
    //   transform: 'scale(0.7)',
    // },
    '17.5%': {
      transform: 'scale(1.5)',
    },
  },

  '@keyframes particles': {
    '40%': {
      transform: 'scale(0.7)',
      opacity: 1,
    },
    '80%': {
      transform: 'scale(1.5)',
      opacity: 1,
    },
    to: {
      transform: 'scale(1.8)',
      opacity: 0,
    },
  },
  buttonStyle: {
    border: '1px solid',
    borderColor: 'rgba(0, 0, 0, 0.16)',
    backgroundColor: theme.palette.common.white,
    borderRadius: '6px',
  },
  button: {
    height: 'fit-content',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  favButton: {
    height: '30px',
    width: '27.5px',
    transform: 'scale(1)',
    // willChange: 'font-size',
    // animation: '$heart 3s cubic-bezier(.17, .89, .32, 1.49)',
    [theme.breakpoints.down('xs')]: {
      transform: 'scale(1)',
      height: '28px',
      width: '26px',
    },
  },
  favButtonChecked: {
    height: '30px',
    width: '27.5px',
    transform: 'scale(1)',
    // transform: 'translateY(20%)',
    [theme.breakpoints.down('xs')]: {
      transform: 'scale(1)',
      height: '28px',
      width: '26px',
    },
  },
  favButtonCheckedAnimated: {
    animation: '$heart 1s cubic-bezier(.17, .89, .32, 1.49)',
  },
  inverse: {
    filter: 'invert(1)',
  },
  particles: {
    border: '3px solid #A33EC5',
    position: 'absolute',
    borderRadius: '100%',
    filter: 'blur(2px)',
    height: '30px',
    width: '27.5px',
    opacity: 0,
    animation: '$particles 1s ease-out',
    transform: 'scale(1)',
  },
  particlesPlaceholder: {
    height: '30px',
    width: '27.5px',
    opacity: 0,
    position: 'absolute',
    transform: 'scale(1)',
  },
  text: {
    minWidth: theme.spacing(20),
    marginLeft: theme.spacing(1),
  },
}));
