import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  Enum_My_Website_Artwork_Info_Enum,
  Enum_My_Website_Filters_Enum,
  Enum_My_Website_Layout_Enum,
  Enum_My_Website_Navigation_Enum,
  Enum_My_Website_Selected_Avatar_Type_Enum,
  Enum_My_Website_Theme_Enum,
} from 'common/schema/commonSchemaRemoteTypes';
import { isSomeEnum } from 'utils/utilFunctions';

const NAVIGATION_ORDER = [
  Enum_My_Website_Navigation_Enum.SideNavigation,
  Enum_My_Website_Navigation_Enum.TopNavigation,
  Enum_My_Website_Navigation_Enum.FloatingNavigation,
];

const SELECTED_AVATAR_TYPE_ORDER = [
  Enum_My_Website_Selected_Avatar_Type_Enum.PersonalIcon,
  Enum_My_Website_Selected_Avatar_Type_Enum.KaleidoProfilePicture,
  Enum_My_Website_Selected_Avatar_Type_Enum.None,
];

export const isMyWebsiteNavigationEnum = isSomeEnum(Enum_My_Website_Navigation_Enum);

export const isMyWebsiteSelectedAvatarTypeEnum = isSomeEnum(Enum_My_Website_Selected_Avatar_Type_Enum);

export const isMyWebsiteFilterEnum = isSomeEnum(Enum_My_Website_Filters_Enum);

export const isMyWebsiteArtworkInfoEnum = isSomeEnum(Enum_My_Website_Artwork_Info_Enum);

// TODO: Add 1 Column
export const ARTWORK_COLUMN_OPTIONS = [2, 3, 4];
export const PROFILE_ARTWORK_COLUMN_OPTIONS = [2, 3];
export const LAYOUT_OPTIONS = Object.values(Enum_My_Website_Layout_Enum);
export const ARTWORK_INFO_OPTIONS = Object.values(Enum_My_Website_Artwork_Info_Enum);
export const IMAGERY_DISPLAY_OPTIONS = Object.values(Enum_My_Website_Selected_Avatar_Type_Enum).sort(
  (a, b) => SELECTED_AVATAR_TYPE_ORDER.indexOf(a) - SELECTED_AVATAR_TYPE_ORDER.indexOf(b)
);
export const NAVIGATION_TYPES = Object.values(Enum_My_Website_Navigation_Enum).sort(
  (a, b) => NAVIGATION_ORDER.indexOf(a) - NAVIGATION_ORDER.indexOf(b)
);
export const FILTER_OPTIONS = Object.values(Enum_My_Website_Filters_Enum);

export type MyWebsiteFiltersType = {
  [key in Enum_My_Website_Filters_Enum]: boolean;
};

export type MyWebsitePersonalImage = {
  id: number;
  file_metadata_id: number;
  order: number;
};

export type MyWebsiteMiscellaneousType = {
  hideCollected: boolean;
  hideUnavailable: boolean;
};

export type MyWebsiteSettingsState = {
  kaleidoAvatar?: string;
  id?: number;
  state?: 'IDLE' | 'LOADED';
  layout: Enum_My_Website_Layout_Enum;
  columns: number;
  artworkInfo: Enum_My_Website_Artwork_Info_Enum;
  theme: Enum_My_Website_Theme_Enum;
  overrideCustomDomain?: string;
  applyMyWebsiteSettings?: boolean;
  artworksOrderChanged: number; // its a counter, can't be boolean, otherwise it will trigger the iframe to reload every time it sets to false again
  personalImagesOrderChanged?: number; // its a counter, can't be boolean, otherwise it will trigger the iframe to reload every time it sets to false again
  hidePreviewBanner?: boolean;
  artworksLength: number;
  filters: MyWebsiteFiltersType;
  navigation: Enum_My_Website_Navigation_Enum;
  eCommerceEnabled: boolean;
  miscellaneous: MyWebsiteMiscellaneousType;
  coverImage?: number;
  mobileCoverImage?: number;
  publishedSettings?: string;
  isCustomize?: boolean;
  lastAutoUpdate?: number;
  lastUserUpdate?: number;
  myWebsitePersonalImages?: MyWebsitePersonalImage[];
  myWebsitePersonalImagesCache?: string[];
  selectedAvatarType?: Enum_My_Website_Selected_Avatar_Type_Enum;
  profileIcon?: string;
};

export const getDefaultMyWebsiteSettings = (): MyWebsiteSettingsState => ({
  kaleidoAvatar: '',
  id: 0,
  layout: Enum_My_Website_Layout_Enum.Grid,
  columns: 3,
  artworkInfo: Enum_My_Website_Artwork_Info_Enum.Overlay,
  theme: Enum_My_Website_Theme_Enum.Light,
  artworksOrderChanged: 0,
  personalImagesOrderChanged: 0,
  hidePreviewBanner: true,
  artworksLength: 0,
  filters: {
    SHOW_SERIES: false,
    SHOW_YEARS: false,
  },
  miscellaneous: {
    hideCollected: false,
    hideUnavailable: false,
  },
  navigation: Enum_My_Website_Navigation_Enum.SideNavigation,
  eCommerceEnabled: true,
  coverImage: 0,
  mobileCoverImage: 0,
  lastAutoUpdate: 0,
  lastUserUpdate: 0,
  selectedAvatarType: Enum_My_Website_Selected_Avatar_Type_Enum.None,
  profileIcon: '',
  myWebsitePersonalImages: [],
  myWebsitePersonalImagesCache: [],
});

export const defaultMyWebsiteSettingsState = {
  state: 'IDLE',
  overrideCustomDomain: '',
  applyMyWebsiteSettings: false,
  publishedSettings: '',
  ...getDefaultMyWebsiteSettings(),
};

export const myWebsiteSettingsSlice = createSlice({
  name: 'myWebsiteSettings',
  initialState: defaultMyWebsiteSettingsState,
  reducers: {
    SET_MY_WEBSITE_SETTINGS: (state, action: PayloadAction<Partial<MyWebsiteSettingsState>>) => {
      const { payload } = action;
      return {
        ...state,
        ...payload,
        lastUserUpdate: Date.now(),
      };
    },
    SET_OVERRIDE_CUSTOM_DOMAIN: (state, action: PayloadAction<string>) => {
      state.overrideCustomDomain = action.payload;
    },
    SET_APPLY_MY_WEBSITE_SETTINGS: (state, action: PayloadAction<boolean>) => {
      state.applyMyWebsiteSettings = action.payload;
    },
    SET_MY_WEBSITE_INITIAL_SETTINGS: (state, action: PayloadAction<Partial<MyWebsiteSettingsState>>) => {
      const { payload } = action;
      return {
        ...state,
        ...payload,
        lastAutoUpdate: Date.now(),
      };
    },
    SET_COVER_IMAGES: (state, action: PayloadAction<Partial<MyWebsiteSettingsState>>) => ({
      ...state,
      coverImage: action.payload.coverImage || 0,
      mobileCoverImage: action.payload.mobileCoverImage || 0,
    }),
    UPDATE_PERSONAL_IMAGES: (state, action: PayloadAction<MyWebsitePersonalImage[]>) => {
      state.myWebsitePersonalImages = action.payload;
    },
    DELETE_MY_WEBSITE_COVERS: (state) => ({
      ...state,
      coverImage: 0,
      mobileCoverImage: 0,
    }),
    SET_KALEIDO_AVATAR: (state, action: PayloadAction<string>) => {
      state.kaleidoAvatar = action.payload;
    },
  },
});
