import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ArtShow } from 'stacks/ArtShow/utils/artShowArtworksTypes';

export type ArtShowDataType = ArtShow;

type ArtShowEntities = Record<string, ArtShowDataType>;

const initialState: ArtShowEntities = {};

export const artShowsSlice = createSlice({
  name: 'artShowsCache',
  initialState,
  reducers: {
    UPDATE_ART_SHOWS: (state, action: PayloadAction<ArtShowDataType[]>) => {
      action.payload.forEach((artShowInfo) => {
        if (!artShowInfo.uri) {
          return;
        }
        state[artShowInfo.uri] = artShowInfo;
      });
    },
  },
});
