import { makeStyles, Theme } from '@material-ui/core';

interface ThemeProps {
  matches: boolean;
  middleTextLength: number;
}
export const useStyles = makeStyles<Theme, ThemeProps>((theme) => ({
  addBlackColor: {
    color: 'black',
  },
  addBorderRadius: {
    borderRadius: '10px',
    border: theme.palette.common.white === 'white' ? '1px solid #D0D0D0' : '',
  },
  disablePointerEvent: {
    pointerEvents: 'none',
  },
  image: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
  imageCardContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  imageContainer: {
    height: 'auto',
    width: 'auto',
  },
  topRightText: {
    position: 'absolute',
    top: '75px',
    right: '50px',
  },
  topLeftText: {
    position: 'absolute',
    top: '60px',
    left: '50px',
  },
  middleContainer: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  textColor: {
    color: 'black',
  },
  middleText: {
    fontWeight: 300,
    fontSize: ({ middleTextLength }: ThemeProps) => `${165 / middleTextLength}vw`,
    whiteSpace: 'pre',
    [theme.breakpoints.down('xs')]: {
      fontSize: ({ middleTextLength }: ThemeProps) => `${370 / middleTextLength}vw`,
    },
  },
  boldText: {
    fontWeight: 'bold',
  },
  bottomLeftContainer: {
    position: 'absolute',
    zIndex: 0,
    bottom: '30px',
    left: '30px',
  },
  bottomRightContainer: {
    position: 'absolute',
    zIndex: 0,
    bottom: '30px',
    right: '30px',
  },
  topText: {
    fontSize: '4vw',

    [theme.breakpoints.down('xs')]: {
      fontSize: '5.5vw',
    },
  },
  abreviationPosition: {
    fontWeight: 700,
    top: '32px',
    left: '40px',
    position: 'relative',
  },

  locationPosition: {
    position: 'relative',
    top: '57px',
    right: '30px',
    fontSize: '18px',
  },
  startImageCardContainer: {
    aspectRatio: '1',
    borderRadius: '4px',
    border: theme.palette.common.white === 'white' ? '1px solid #D0D0D0' : '',
    pointerEvents: 'none',
    color: 'black',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '32px',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    [theme.breakpoints.down('xs')]: {
      padding: '16px',
    },
  },
}));
