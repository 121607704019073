import useAppSelector from 'common/hooks/useAppSelector';
import { useMemo } from 'react';
import {
  ExtraSpace,
  extraSpaceToReservedSpace,
  getTotalReservedSpace,
  KnownSpacesOrder,
  ReservedSpace,
} from '../store/screenspaceSlice';

export type CSSPropsResult = {
  all: React.CSSProperties['margin'];
  top: React.CSSProperties['marginTop'];
  right: React.CSSProperties['marginRight'];
  bottom: React.CSSProperties['marginBottom'];
  left: React.CSSProperties['marginLeft'];
  reserved: ReservedSpace;
};
type ToCSSPropsFuncResult = {
  withoutSafeArea: CSSPropsResult;
  withSafeArea: CSSPropsResult;
};
type ToCSSPropsFunc = (props?: {
  extraSpace?: ExtraSpace;
  skipExtraWhenReserved?: SkipExtraWhenReserved;
}) => ToCSSPropsFuncResult;

type SkipExtraWhenReserved = Partial<Record<keyof ReservedSpace, boolean>>;

const genCSSProp = (
  key: keyof ReservedSpace,
  space: ReservedSpace,
  extraSpace?: ReservedSpace,
  skipExtraWhenReserved?: SkipExtraWhenReserved
): React.CSSProperties['margin'] =>
  space[key] && skipExtraWhenReserved?.[key]
    ? `${space[key]}px`
    : `calc(${space[key]}px + ${extraSpace?.[key] ?? 0}px)`;

const generateToCSSProps = (space: ReservedSpace) => {
  const toCSSPropsWithSafeArea: ToCSSPropsFunc = (props) => {
    const { extraSpace, skipExtraWhenReserved } = props ?? {};
    const extraReserved = extraSpaceToReservedSpace(extraSpace);
    let top = genCSSProp('top', space, extraReserved, skipExtraWhenReserved);
    let right = genCSSProp('right', space, extraReserved, skipExtraWhenReserved);
    let bottom = genCSSProp('bottom', space, extraReserved, skipExtraWhenReserved);
    let left = genCSSProp('left', space, extraReserved, skipExtraWhenReserved);
    const withoutSafeArea = {
      all: `${top} ${right} ${bottom} ${left}`,
      top,
      right,
      bottom,
      left,
      reserved: space,
    };
    top = `calc(${top} + calc(max(env(safe-area-inset-top), 5px) - 5px))`;
    right = `calc(${right} + env(safe-area-inset-right, 0px))`;
    bottom = `calc(${bottom} + env(safe-area-inset-bottom, 0px))`;
    left = `calc(${left} + env(safe-area-inset-left, 0px))`;
    const withSafeArea = {
      all: `${top} ${right} ${bottom} ${left}`,
      top,
      right,
      bottom,
      left,
      reserved: space,
    };
    return {
      withSafeArea,
      withoutSafeArea,
    };
  };
  return toCSSPropsWithSafeArea;
};

const useGetReservedScreenspace = (props?: {
  untilOrder?: number; // only sum up to this order
}): {
  reservedSpace: ReservedSpace;
  toCSSProps: ToCSSPropsFunc;
} => {
  const { untilOrder = KnownSpacesOrder.Last } = props ?? {};
  const reservations = useAppSelector((store) => store.screenspace.reservations);
  const total = useAppSelector((store) => store.screenspace.total);

  return useMemo(() => {
    const reservedSpace = untilOrder !== undefined ? getTotalReservedSpace(reservations, untilOrder) : total;
    const toCSSProps = generateToCSSProps(reservedSpace);
    return { reservedSpace, toCSSProps };
  }, [reservations, total, untilOrder]);
};

export default useGetReservedScreenspace;
