import React from 'react';
import _ from 'lodash';
import { DeepPartial } from '@reduxjs/toolkit';
import { FollowContainerProps } from '../common/FollowContainer';
import { FollowIconProps } from '../common/FollowIcon';
import { FollowTextProps } from '../common/FollowText';

export type FollowButtonState = {
  container: FollowContainerProps;
  icon: FollowIconProps;
  text: FollowTextProps;
};

export type PartialFollowButtonState = DeepPartial<FollowButtonState>;

const defaultContainerStyle: React.CSSProperties = {
  height: '100%',
  maxWidth: '100%',
  padding: '10px 16px',
};
const iconOnlyContainerStyle: React.CSSProperties = {
  ...defaultContainerStyle,
  padding: '10px 12px',
};
const textOnlyContainerStyle: React.CSSProperties = {
  ...defaultContainerStyle,
};
const noText: FollowButtonState['text'] = {
  variant: 'NONE',
};
const noIcon: FollowButtonState['icon'] = {
  variant: 'NONE',
};

const FOLLOW_TEXT_ONLY: FollowButtonState = {
  container: textOnlyContainerStyle,
  text: {
    variant: 'FOLLOW',
  },
  icon: noIcon,
};
const FOLLOW_ICON_ONLY: FollowButtonState = {
  container: iconOnlyContainerStyle,
  text: noText,
  icon: {
    variant: 'PLUS',
    color: 'BLACK',
  },
};
const FOLLOW_FULL: FollowButtonState = {
  container: defaultContainerStyle,
  icon: FOLLOW_ICON_ONLY.icon,
  text: FOLLOW_TEXT_ONLY.text,
};

const FOLLOWING_TEXT_ONLY: FollowButtonState = {
  container: textOnlyContainerStyle,
  text: {
    variant: 'FOLLOWING',
  },
  icon: noIcon,
};
const FOLLOWING_ICON_ONLY: FollowButtonState = {
  container: iconOnlyContainerStyle,
  text: noText,
  icon: {
    variant: 'CHECKED',
    color: 'COLOR',
  },
};
const FOLLOWING_FULL: FollowButtonState = {
  container: defaultContainerStyle,
  icon: FOLLOWING_ICON_ONLY.icon,
  text: FOLLOWING_TEXT_ONLY.text,
};

const UNFOLLOW_TEXT_ONLY: FollowButtonState = {
  container: textOnlyContainerStyle,
  text: {
    variant: 'UNFOLLOW',
  },
  icon: noIcon,
};
const FOLLOW_BACK_TEXT_ONLY: FollowButtonState = {
  container: textOnlyContainerStyle,
  text: {
    variant: 'FOLLOW_BACK',
  },
  icon: noIcon,
};
const UNFOLLOW_ICON_ONLY: FollowButtonState = {
  container: iconOnlyContainerStyle,
  text: noText,
  icon: {
    variant: 'CHECKED',
    color: 'BLACK',
  },
};
const UNFOLLOW_FULL: FollowButtonState = {
  container: defaultContainerStyle,
  icon: UNFOLLOW_ICON_ONLY.icon,
  text: UNFOLLOW_TEXT_ONLY.text,
};

const NONE: FollowButtonState = {
  container: defaultContainerStyle,
  icon: noIcon,
  text: noText,
};

const FOLLOW_BACK: FollowButtonState = {
  container: FOLLOW_BACK_TEXT_ONLY.container,
  icon: FOLLOW_BACK_TEXT_ONLY.icon,
  text: FOLLOW_BACK_TEXT_ONLY.text,
};

const switchFlavor =
  (flavor: 'RECOGNIZE' | 'FOLLOW') =>
  (props: FollowButtonState): void => {
    const newFlavorState: PartialFollowButtonState = { text: { flavor } };
    _.merge(props, newFlavorState);
  };

const variantProps = {
  FOLLOW_FULL,
  FOLLOW_TEXT_ONLY,
  FOLLOW_ICON_ONLY,
  FOLLOWING_FULL,
  FOLLOWING_TEXT_ONLY,
  FOLLOWING_ICON_ONLY,
  UNFOLLOW_FULL,
  UNFOLLOW_TEXT_ONLY,
  UNFOLLOW_ICON_ONLY,
  FOLLOW_BACK,
  NONE,
};

export const switchRecognizeButtonFlavor = (flavor: 'RECOGNIZE' | 'FOLLOW'): void => {
  const swapFlavor = switchFlavor(flavor);
  Object.values(variantProps).forEach(swapFlavor);
};

export type FollowButtonVariants = keyof typeof variantProps;

export default variantProps;
