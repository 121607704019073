import { combineReducers } from 'redux';
import { favoritesModalListSlice } from './list.slice';
import { favoritesModalFiltersSlice } from './filter.slice';

export const actions = {
  ...favoritesModalListSlice.actions,
  ...favoritesModalFiltersSlice.actions,
};

export const favoritesModalSlice = {
  myFavoritesModal: combineReducers({
    [favoritesModalListSlice.name]: favoritesModalListSlice.reducer,
    [favoritesModalFiltersSlice.name]: favoritesModalFiltersSlice.reducer,
  }),
};
