import useAnalytics from 'common/features/Analytics/Analytics';
import { useEventTriggerCaller } from 'common/features/EventTrigger/hooks/useEventTrigger';
import { EventTriggers } from 'common/features/EventTrigger/store/eventTriggerSlice';
import useAppSelector from 'common/hooks/useAppSelector';
import { useSelectedProfile } from 'common/hooks/useSelectedProfile';
import useAsyncState from 'common/hooks/utils/useAsyncState';
import { useIdGen } from 'common/hooks/utils/useIdGen';
import { Enum_Social_Graph_Followers_State_Enum } from 'common/schema/commonSchemaRemoteTypes';
import { useEffect, useCallback } from 'react';
import _ from 'lodash';
import { useLocation } from 'react-router';
import Auth from 'common/services/Auth';
import { setFollowRelation } from '../utils/setFollowRelation';
import { useArtistAdmirationsActions } from './useArtistAdmirationsActions';

export type UseUpdateRecognitionProps = {
  isInitiallyRecognizing: boolean;
  targetContextId: number;
  onRecognitionUpdate?: (props: { userContextId: number; targetContextId: number; isRecognizing: boolean }) => void;
};

export type UseUpdateRecognitionResult = {
  isRecognizing: boolean;
  isTrigger: boolean;
  onClickHandler: (e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
};

// can only process one targetContextId recognition at a time
const lockedRequests = new Set();
const lastUpdatedByInstance = {
  id: 0,
};

const useUpdateRecognition = ({
  isInitiallyRecognizing,
  targetContextId,
  onRecognitionUpdate,
}: UseUpdateRecognitionProps): UseUpdateRecognitionResult => {
  const isLoggedIn = Auth.useIsLoggedIn();
  const currentArtistProfile = useSelectedProfile();
  const contextId = currentArtistProfile?.contextId || 0;
  const { pathname } = useLocation();
  const [isRecognizing, setIsRecognizing] = useAsyncState(isInitiallyRecognizing);
  const simpleRecognitions = useAppSelector((store) => store.recognitions.simpleRecognitions);
  const instanceId = useIdGen();

  // get cached recognize state
  // TODO rafactor this to use the recognitions entities
  useEffect(() => {
    // the old state management gets triggered in weird ways
    // the todo above would fix a few issues including this, but this is a quick fix
    if (lastUpdatedByInstance.id === instanceId) {
      return;
    }

    const filter = simpleRecognitions.filter(
      (recognition) =>
        recognition.target_context_id === targetContextId &&
        recognition.state === Enum_Social_Graph_Followers_State_Enum.Active
    );

    const newIsRecognized = filter?.length !== 0;
    setIsRecognizing(newIsRecognized);
  }, [simpleRecognitions, targetContextId, setIsRecognizing]);

  const { handleFollowAction } = useArtistAdmirationsActions();
  const analytics = useAnalytics();
  const [triggerRecognizeButtonPressed] = useEventTriggerCaller(EventTriggers.RecognizeButtonPressed);

  const onClickHandler = useCallback(
    (e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e?.stopPropagation();
      e?.preventDefault();

      if (!isLoggedIn || !contextId || lockedRequests.has(targetContextId)) {
        return;
      }

      lastUpdatedByInstance.id = instanceId;
      setIsRecognizing(!isRecognizing);
      lockedRequests.add(targetContextId);
      setFollowRelation(
        contextId,
        targetContextId,
        isRecognizing,
        isRecognizing ? Enum_Social_Graph_Followers_State_Enum.Deleted : Enum_Social_Graph_Followers_State_Enum.Active,
        handleFollowAction,
        triggerRecognizeButtonPressed,
        true
      ).finally(() => {
        lockedRequests.delete(targetContextId);
      });

      onRecognitionUpdate?.({
        userContextId: contextId,
        targetContextId,
        isRecognizing: !isRecognizing,
      });

      analytics.postData('SUCCESS', 'RECOGNIZE', 'CLICK_CTA', {
        recognize_target_context_id: targetContextId,
        recognize_state: !isRecognizing,
        currentPage: pathname,
        isGuestUser: false,
      });
    },
    [isLoggedIn, isRecognizing, contextId, targetContextId]
  );

  return { onClickHandler, isRecognizing, isTrigger: lastUpdatedByInstance.id === instanceId };
};

export default useUpdateRecognition;
