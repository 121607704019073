import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SocialLinksFormValues } from '@old/MainApp/Account/Profile/util/useSocialLinks';

const initialState: SocialLinksFormValues = {
  WEBSITE: '',
  INSTAGRAM: '',
  FACEBOOK: '',
  TWITTER: '',
  YOUTUBE: '',
  TIKTOK: '',
  PINTEREST: '',
};

const socialLinksSlice = createSlice({
  name: 'socialLinks',
  initialState,
  reducers: {
    SET_LINKS: (state, action: PayloadAction<Partial<SocialLinksFormValues>>) => ({ ...state, ...action.payload }),
    RESET_LINKS: () => initialState,
  },
});

export const socialLinksActions = socialLinksSlice.actions;
export default socialLinksSlice;
