import { SortOptionsEnum } from 'common/features/VirtualList/VirtualList.config';
import { Enum_Context_Type_Enum, Scalars } from 'common/schema/commonSchemaRemoteTypes';
import { isSomeEnum } from 'utils/utilFunctions';
import { FavoritesModalSort } from './filter.types';

export type FavoritesModalFiltersHash = `${SortOptionsEnum}_${string}`;

export const listingDirections = ['BACKWARD', 'FORWARD'] as const;
export type ListDirection = (typeof listingDirections)[0] | (typeof listingDirections)[1];

export const loadingIdentifier = 'LOADING' as const;
export type ListLoadingId = typeof loadingIdentifier;

export type ListLoaderStatus = 'COLD' | 'FINISHED' | 'IDLE' | `${ListLoadingId}_${ListDirection}`;

export type ListWindowLoaderStateType<ValuesType, StatusType> = {
  batchSize: number;
  values: Array<ValuesType>;
  status: StatusType;
  hasMore: [boolean, boolean]; // [backward, forward]
};

export type ListLoaderStateType<Typename extends FavoritesModalSort, CursorType, IdType = Scalars['bigint']> = {
  __typename: Typename;
  listed: ListWindowLoaderStateType<CursorType, ListLoaderStatus>;
  detailed: ListWindowLoaderStateType<IdType, ListLoaderStatus>;
  cursor: [CursorType, CursorType]; // [backward, forward]
  detailedToListedStartOffset: number;
  lastScrollPosition: number;
};

export type FavoritesModalListByDateCursor = {
  favoritedByType: Enum_Context_Type_Enum;
  favoritedBy: Scalars['bigint'];
  favoritedAt: Scalars['date'];
};
export type FavoritesModalListMostRecent = ListLoaderStateType<
  SortOptionsEnum.MOST_RECENT,
  FavoritesModalListByDateCursor
>;

export type MostRecentListActionArgs =
  | [ListDirection, FavoritesModalListMostRecent['__typename'], FavoritesModalListMostRecent['listed']['values']];

export type MostRecentDetailActionArgs =
  | [ListDirection, FavoritesModalListMostRecent['__typename'], FavoritesModalListMostRecent['detailed']['values']];
export type FavoritesModalListState = {
  identifier: FavoritesModalFiltersHash;
  type: FavoritesModalSort;
} & {
  type: SortOptionsEnum.MOST_RECENT;
  data: FavoritesModalListMostRecent;
};

export type FavoritesModalListingCursor = FavoritesModalListByDateCursor;

export const isFavoritesModalListByDateCursor = (
  cursor: FavoritesModalListingCursor
): cursor is FavoritesModalListByDateCursor => (cursor as FavoritesModalListByDateCursor)?.favoritedAt !== undefined;

export const isContextTypeEnum = isSomeEnum(Enum_Context_Type_Enum);

export const LOADING_STATES: ListLoaderStatus[] = ['LOADING_BACKWARD', 'LOADING_FORWARD'];
