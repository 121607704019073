import { makeStyles } from '@material-ui/core';
import zIndex from 'common/utils/zIndex';
import { CSSProperties } from 'react';

const useCustomizableDropdownStyles = makeStyles({
  root: {
    width: '100%',
  },
  header: {
    width: '100%',
    backgroundColor: 'white',
    minHeight: '40px',

    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    border: '1px solid rgba(0, 0, 0, 0.16)',
    borderRadius: 6,
    padding: '5px 16px',
    boxSizing: 'border-box',
    zIndex: zIndex.values.DROPDOWN,
    position: 'relative',

    cursor: 'pointer',
  },
  selected: {
    display: 'flex',
    flexDirection: 'column',
    gap: '2px',
  },
  paper: {
    width: '100%',
    backgroundColor: 'white',

    border: '1px solid rgba(0, 0, 0, 0.16)',
    borderRadius: 12,
    padding: '8px 16px',
    boxSizing: 'border-box',
    marginTop: 8,

    display: 'flex',
    flexDirection: 'column',
    gap: 16,
  },
  popoverRoot: {
    zIndex: `${zIndex.values.POPOVER} !important` as CSSProperties['zIndex'],
  },
});

export default useCustomizableDropdownStyles;
