import { Artwork_Bool_Exp } from '../../../schema/commonSchemaRemoteTypes';
import {
  AppliedListingModifier,
  PROFILE_QUERY_CONDITION_IN,
  FILTERS_DEFAULT_VALUE,
  isOptionType,
  ListingModifier,
  ListingModifierOption,
} from './types';

export type ArtistOptionType = {
  id: number;
  name: string;
};

export const generatArtistFilterOption = ({ id, name }: ArtistOptionType): ListingModifierOption => ({
  name,
  value: id,
  key: 'PROFILE_QUERY_CONDITION_IN',
  payload: {},
});

export const getArtistFilter = (): ListingModifier => ({
  type: 'ARTIST',
  allOptions: [
    {
      value: FILTERS_DEFAULT_VALUE,
      name: 'filterStrings.artist.all',
      key: 'PROFILE_QUERY_CONDITION_IN',
      payload: {},
    },
  ],
});

export const getAppliedArtistFilter = (
  seriesOptions: ArtistOptionType[]
): AppliedListingModifier<PROFILE_QUERY_CONDITION_IN> => {
  const artistFilter = getArtistFilter();
  const newSeriesFilterOptions = seriesOptions.map(generatArtistFilterOption);
  artistFilter.allOptions.push(...newSeriesFilterOptions);
  return {
    ...artistFilter,
    selectionType: 'MULTIPLE',
    selectedOptions: [FILTERS_DEFAULT_VALUE],
    flattenSelectedOptions: (options, selectedOptions) => {
      const selectedModifiers = options.filter((option) => selectedOptions.includes(option.value));
      const flattenedModifiers = selectedModifiers.reduce<Artwork_Bool_Exp>((flattenedOptions, currentOption) => {
        if (
          isOptionType<PROFILE_QUERY_CONDITION_IN>(currentOption, 'PROFILE_QUERY_CONDITION_IN') &&
          currentOption.payload.context_id?._in
        ) {
          return {
            artist_context_id: {
              _in: [...(flattenedOptions.id?._in ?? []), ...currentOption.payload.context_id?._in],
            },
          };
        }
        return flattenedOptions;
      }, {});

      return {
        key: 'PROFILE_QUERY_CONDITION_IN',
        name: 'APPLIED_SERIES_FILTER',
        value: -1,
        payload: flattenedModifiers,
      };
    },
  };
};
