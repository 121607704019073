import { RefreshFilterState } from 'common/features/Filters/predefined/Refresh/refresh.types';

export const sorts = ['byTitle'] as const;
export type NearbyGalleriesSort = (typeof sorts)[0];

export const isNearbyGallerySort = (v: string): v is NearbyGalleriesSort => sorts.includes(v as NearbyGalleriesSort);

export type NearbyGalleriesFiltersState = RefreshFilterState & {
  sort: NearbyGalleriesSort;
};
