import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  GetShowcaseGeneralInfoByHandleAndSlugQuery,
  GetShowcaseImagesByHandleAndSlugQuery,
  GetShowcaseDetailsByHandleAndSlugQuery,
} from 'common/schema/commonSchemaRemoteOperationTypes';

type SliceType = {
  showcaseGeneralInfoByHandleAndSlug: Record<string, GetShowcaseGeneralInfoByHandleAndSlugQuery>;
  getShowcaseImagesByHandleAndSlugQuery: Record<string, GetShowcaseImagesByHandleAndSlugQuery>;
  getShowcaseDetailsByHandleAndSlugQuery: Record<string, GetShowcaseDetailsByHandleAndSlugQuery>;
};

const initialState: SliceType = {
  showcaseGeneralInfoByHandleAndSlug: {},
  getShowcaseImagesByHandleAndSlugQuery: {},
  getShowcaseDetailsByHandleAndSlugQuery: {},
};

export const showcaseCache = createSlice({
  name: 'showcaseCache',
  initialState,
  reducers: {
    UPDATE_SHOWCASE_GENERAL_INFO_BY_HANDLE_AND_SLUG: (
      state,
      action: PayloadAction<{
        key?: string;
        value?: GetShowcaseGeneralInfoByHandleAndSlugQuery;
      }>
    ) => {
      const { key, value } = action.payload;
      if (!key || !value) {
        return;
      }
      state.showcaseGeneralInfoByHandleAndSlug[key] = value;
    },
    UPDATE_SHOWCASE_IMAGES_BY_HANDLE_AND_SLUG: (
      state,
      action: PayloadAction<{
        key?: string;
        value?: GetShowcaseImagesByHandleAndSlugQuery;
      }>
    ) => {
      const { key, value } = action.payload;
      if (!key || !value) {
        return;
      }
      state.getShowcaseImagesByHandleAndSlugQuery[key] = value;
    },
    UPDATE_SHOWCASE_DETAILS_BY_HANDLE_AND_SLUG: (
      state,
      action: PayloadAction<{
        key?: string;
        value?: GetShowcaseDetailsByHandleAndSlugQuery;
      }>
    ) => {
      const { key, value } = action.payload;
      if (!key || !value) {
        return;
      }
      state.getShowcaseDetailsByHandleAndSlugQuery[key] = value;
    },
  },
});
