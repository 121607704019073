import { logger } from '@old/services/logger';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isFavoritesModalort } from './filter.types';
import { defaults, hasMore, listInitialState, getDefaultListState } from './list.defaults';
import {
  FavoritesModalFiltersHash,
  listingDirections,
  loadingIdentifier,
  ListDirection,
  MostRecentDetailActionArgs,
  MostRecentListActionArgs,
} from './list.types';

export const favoritesModalListSlice = createSlice({
  name: 'list',
  initialState: listInitialState,
  reducers: {
    RESET_LIST_FavoritesModal: getDefaultListState,
    COLD_START_FavoritesModal: (
      state,
      action: PayloadAction<{
        identifier: FavoritesModalFiltersHash;
        startList: MostRecentListActionArgs;
      }>
    ) => {
      const {
        identifier,
        startList: [direction, sort, [cursor]],
      } = action.payload;

      if (state.identifier !== identifier) {
        if (!isFavoritesModalort(sort)) {
          throw new Error('Unsupported listing identifier HALCS');
        }

        state.data = defaults[sort]().data;
        state.type = sort;
      }

      if (state.data.listed.status !== 'COLD') {
        return;
      }

      const directionIdx = listingDirections.indexOf(direction);
      state.data.listed.hasMore[directionIdx] = true;

      // starting from an offset page
      if (cursor) {
        const oppositeDirectionIdx = (directionIdx + 1) % listingDirections.length;
        state.data.cursor[directionIdx] = cursor;
        state.data.listed.hasMore[oppositeDirectionIdx] = true;
      }

      state.identifier = identifier;
      state.data.listed.status = `${loadingIdentifier}_${direction}` as const;
    },
    REQUEST_LISTED_FavoritesModal: (state, action: PayloadAction<ListDirection>) => {
      if (!['COLD', 'IDLE'].includes(state.data.listed.status)) {
        return;
      }

      const { payload: direction } = action;
      if (!hasMore(state.data.listed, direction)) {
        return;
      }

      state.data.listed.status = `${loadingIdentifier}_${direction}` as const;
    },
    APPEND_LISTED_FavoritesModal: (state, action: PayloadAction<MostRecentListActionArgs>) => {
      if (!state.data.listed.status.startsWith(loadingIdentifier)) {
        return;
      }

      const [direction, sort, values] = action.payload;
      if (state.type !== sort) {
        logger.warn('wrong sort');
        return;
      }

      const directionIdx = listingDirections.indexOf(direction);
      state.data.listed.hasMore[directionIdx] = state.data.listed.batchSize === values.length;

      state.data.cursor[directionIdx] = values[values.length - 1];
      state.data.detailed.hasMore[directionIdx] = state.data.detailed.hasMore[directionIdx] || values.length > 0;

      const appendValues = [
        // backward
        () => {
          state.data.detailedToListedStartOffset += values.length;
          state.data.listed.values = [
            ...[...values].reverse(), // reverse might not be needed
            ...state.data.listed.values,
          ] as any[]; // typescript can't see the data associations checked above: (state.type !== sort)
        },
        // forward
        () => {
          state.data.listed.values = [...state.data.listed.values, ...values] as any[]; // typescript can't see the data associations checked above: (state.type !== sort)
        },
      ][directionIdx];

      appendValues();

      if (hasMore(state.data.listed, 'FORWARD') || hasMore(state.data.listed, 'BACKWARD')) {
        state.data.listed.status = 'IDLE';
      } else {
        state.data.listed.status = 'FINISHED';
      }

      if (!hasMore(state.data.detailed, 'FORWARD') && !hasMore(state.data.detailed, 'BACKWARD')) {
        state.data.detailed.status = 'FINISHED';
      }
    },
    REQUEST_DETAILED_FavoritesModal: (state, action: PayloadAction<ListDirection>) => {
      if (!['COLD', 'IDLE'].includes(state.data.detailed.status)) {
        return;
      }

      const { payload: direction } = action;
      if (!hasMore(state.data.detailed, direction)) {
        return;
      }

      const directionIdx = listingDirections.indexOf(direction);

      const batchSize = [
        // backward
        Math.min(state.data.detailedToListedStartOffset, state.data.detailed.batchSize),
        // forward
        Math.min(
          state.data.listed.values.length - state.data.detailedToListedStartOffset - state.data.detailed.values.length,
          state.data.detailed.batchSize
        ),
      ][directionIdx];

      const addPlaceholders = [
        // backward
        () => {
          state.data.detailed.values = [...Array.from(Array(batchSize)).fill(0), ...state.data.detailed.values];
        },
        // forward
        () => {
          state.data.detailed.values = [...state.data.detailed.values, ...Array.from(Array(batchSize)).fill(0)];
        },
      ][directionIdx];

      if (batchSize <= 0 && hasMore(state.data.listed, direction)) {
        // possibly waiting on ids
        return;
      }

      addPlaceholders();

      state.data.detailed.status = `${loadingIdentifier}_${direction}` as const;
    },
    APPEND_DETAILED_FavoritesModal: (state, action: PayloadAction<MostRecentDetailActionArgs>) => {
      if (!state.data.detailed.status.startsWith(loadingIdentifier)) {
        return;
      }

      const [direction, sort, values] = action.payload;
      if (state.type !== sort) {
        logger.warn('wrong sort');
        return;
      }

      const directionIdx = listingDirections.indexOf(direction);
      const replacePlaceholders = [
        // backward
        () => {
          state.data.detailedToListedStartOffset -= values.length;
          state.data.detailed.values.splice(0, values.length, ...values);
        },
        // forward
        () => {
          state.data.detailed.values.splice(
            state.data.detailed.values.length - values.length,
            values.length,
            ...values
          );
        },
      ][directionIdx];

      replacePlaceholders();

      state.data.detailed.hasMore[directionIdx] =
        state.data.detailed.batchSize === values.length || hasMore(state.data.listed, direction);

      if (hasMore(state.data.detailed, 'FORWARD') || hasMore(state.data.detailed, 'BACKWARD')) {
        state.data.detailed.status = 'IDLE';
      } else {
        state.data.detailed.status = 'FINISHED';
      }
    },
  },
});
