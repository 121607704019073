import clsx from 'clsx';
import _ from 'lodash';
import React from 'react';
import FollowContainer, { FollowContainerProps } from '../common/FollowContainer';
import FollowIcon from '../common/FollowIcon';
import FollowText from '../common/FollowText';
import variantProps, {
  FollowButtonState,
  FollowButtonVariants,
  PartialFollowButtonState,
} from '../config/variantProps';
import followButtonPredefines, { FollowButtonPredefines } from '../config/followButtonPredefines';

export type FollowButtonBaseProps = {
  predefine: FollowButtonPredefines;
  variant: FollowButtonVariants;
  onClick?: FollowContainerProps['onClick'];
  isDarkMode?: boolean;
  withoutTransition?: boolean;
  customStyles?: string;
};

const FollowButtonBase: React.FC<FollowButtonBaseProps> = ({
  variant,
  predefine,
  onClick,
  isDarkMode,
  withoutTransition,
  customStyles,
}) => {
  const transition = React.useRef<{
    from: FollowButtonVariants;
    to: FollowButtonVariants;
    class: string;
  }>({
    from: variant,
    to: variant,
    class: '',
  });

  const variantChangeClass = React.useMemo(() => {
    transition.current.to = variant;

    if (transition.current.to !== transition.current.from) {
      transition.current.class = `${transition.current.from.split('_')[0]}_TO_${
        transition.current.to.split('_')[0]
      }`.toLowerCase();
      transition.current.from = transition.current.to;
    }

    return withoutTransition ? '' : transition.current.class;
  }, [variant, withoutTransition]);

  const followButtonState = React.useMemo<FollowButtonState>(() => {
    // get the pre-configured state
    const variantState: FollowButtonState = variantProps[variant];
    const predefinedState: PartialFollowButtonState = followButtonPredefines[predefine].override;
    const knownState = _.merge({}, variantState, predefinedState);

    // edge cases to avoid in dark mode
    const noBlackOnBlack = knownState.icon.color === 'BLACK' && isDarkMode ? 'WHITE' : null;
    const noWhiteOnWhite = knownState.icon.color === 'WHITE' && !isDarkMode ? 'BLACK' : null;

    // compute the dynamic state (onclick + dark mode)
    const containerClickHandling: PartialFollowButtonState = {
      container: {
        onClick:
          onClick ||
          ((e) => {
            e?.preventDefault();
            e?.stopPropagation();
          }),
        className: clsx(
          { dark: isDarkMode === true },
          { light: isDarkMode !== true },
          knownState.container.className,
          variantChangeClass
        ),
      },
      icon: {
        color: noBlackOnBlack ?? noWhiteOnWhite ?? knownState.icon.color,
      },
    };

    // compute the final state
    return _.merge({}, variantState, predefinedState, containerClickHandling);
  }, [variant, predefine, onClick, variantChangeClass, isDarkMode]);

  return (
    <FollowContainer {...followButtonState.container} customStyles={customStyles}>
      <FollowIcon {...followButtonState.icon} />
      <FollowText {...followButtonState.text} />
    </FollowContainer>
  );
};

// export default FollowButtonBase;
export default React.memo(FollowButtonBase);
