import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { ListingDirection } from '../components/listingDirections';
import { listingStatusLoadingIdentifier } from '../components/listingStatus';
import { canLoadMore } from '../components/listLoader';
import { ListReduxFragment } from '../components/listReduxFragment';
import { SortOptionsEnum } from '../VirtualList.config';

export type RequestListedReducer<State extends ListReduxFragment<SortOptionsEnum, unknown>> = CaseReducer<
  State,
  PayloadAction<{ list: string; identifier: State['identifier']; direction: ListingDirection }>
>;

export const createRequestListedCaseReducer =
  <State extends ListReduxFragment<SortOptionsEnum, unknown>>(
    virtualListIdentifier: string
  ): RequestListedReducer<State> =>
  (state, action) => {
    if (!['COLD', 'IDLE'].includes(state.data.listed.status)) {
      return;
    }

    const {
      payload: { direction, list, identifier },
    } = action;
    if (list !== virtualListIdentifier || !canLoadMore(state.data.listed, direction)) {
      return;
    }
    if (identifier !== state.identifier) {
      console.warn('listed request identifier mismatch, should be cold restarting', identifier, state.identifier);
      return;
    }

    state.data.listed.status = `${listingStatusLoadingIdentifier}_${direction}` as const;
  };
