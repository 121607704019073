export enum AvailabilityEnum {
  ALL = 'ALL',
  AVAILABLE = 'AVAILABLE',
  UNAVAILABLE = 'UNAVAILABLE',
  COLLECTED = 'COLLECTED',
  HIDE_COLLECTED = 'HIDE_COLLECTED',
  HIDE_UNAVAILABLE = 'HIDE_UNAVAILABLE',
}

export type AvailabilityFilterState = {
  availability: Record<
    AvailabilityEnum,
    boolean // is toggled
  >;
};
