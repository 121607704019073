import { combineReducers } from 'redux';
import { profileCollectorListSlice } from './list.slice';
import { profileCollectorFiltersSlice } from './filter.slice';
import { profileCollectorDisplaySlice } from './display.slice';

export const profileCollectorActions = {
  ...profileCollectorListSlice.actions,
  ...profileCollectorFiltersSlice.actions,
  ...profileCollectorDisplaySlice.actions,
};

export const profileCollectorSlice = {
  profileCollector: combineReducers({
    [profileCollectorListSlice.name]: profileCollectorListSlice.reducer,
    [profileCollectorFiltersSlice.name]: profileCollectorFiltersSlice.reducer,
    [profileCollectorDisplaySlice.name]: profileCollectorDisplaySlice.reducer,
  }),
};
