import { ROUTES } from 'routes/_definition';
import { PUBLISH_CONGRATS_ROUTE } from '../../ArtworkPublish/hooks/usePublishRoutes';

// TODO: update routes to ROUTES DEFINITIONS
export const DashboardRoutesConfig = {
  manageArt: ROUTES.DASHBOARD_MANAGE_ART,
  openUpgradeToMyStudioModal: '/dashboard/manage-art?openUpgrade=true',
  mobileManageArt: ROUTES.MOBILE_MANAGE_ARTWORK,
  profile: '/dashboard/profile', // TODO: WHAT IS THIS???
  kaleidoCard: ROUTES.DASHBOARD_KALEIDO_CARD_MANAGER,
  connections: ROUTES.DASHBOARD_CONNECTIONS,
  bioLink: ROUTES.DASHBOARD_BIO_LINK_MANAGER,
  bioLinkManage: ROUTES.DASHBOARD_BIO_LINK_MANAGER_MANAGE,
  community: ROUTES.DASHBOARD_COMMUNITY,
  artistPartners: ROUTES.DASHBOARD_ARTIST_PARTNERSHIPS,
  galleryPartners: ROUTES.DASHBOARD_COMMUNITY_GALLERIES,
  collectorList: ROUTES.DASHBOARD_CONNECTIONS_COLLECTORS,
  followers: ROUTES.DASHBOARD_COMMUNITY_FOLLOWERS,
  ignoredRecognitions: ROUTES.DASHBOARD_CONNECTIONS_IGNORED,
  links: ROUTES.DASHBOARD_COMMUNITY_LINKS,
  allArtistsFollowersTab: ROUTES.DASHBOARD_FOLLOWERS_ARTISTS,
  allCollectorsFollowersTab: ROUTES.DASHBOARD_FOLLOWERS_COLLECTORS,
  allArtistsFollowers: ROUTES.DASHBOARD_FOLLOWERS_ALL_FOLLOWERS,
  series: ROUTES.DASHBOARD_SERIES,
  createSeries: ROUTES.DASHBOARD_SERIES_CREATE,
  editSeries: ROUTES.DASHBOARD_SERIES_EDIT_ID,
  certificates: '/dashboard/certificates/:artworkId/:editionId?', // TODO: ROUTES.DASHBOARD_CERTIFICATES_ARTWORK_ID_EDITION_ID doesn't have the ? at the end, could it affect??
  invites: ROUTES.DASHBOARD_INVITES,
  inviteAudience: ROUTES.DASHBOARD_INVITE_AUDIENCE,
  settings: ROUTES.DASHBOARD_SETTINGS,
  settings_general: ROUTES.DASHBOARD_SETTINGS_GENERAL,
  settings_emails: ROUTES.DASHBOARD_SETTINGS_EMAILS,
  share: ROUTES.DASHBOARD_SHARE,
  // requested by Ian on Slack : https://amplified-software.slack.com/archives/CLJD5L46P/p1663049945369819?thread_ts=1663049381.892859&cid=CLJD5L46P
  // kaleidoCoins: '/dashboard/kaleido-coins',
  addArtwork: ROUTES.DASHBOARD_ADD_ARTWORK,
  editArtwork: ROUTES.DASHBOARD_EDIT_ARTWORK,
  mobileEditArtwork: ROUTES.MOBILE_ART_PUBLICATION,
  manageShowrooms: ROUTES.DASHBOARD_SHOWROOMS,
  createShowroom: ROUTES.DASHBOARD_CREATE_SHOWROOM,
  editShowroom: ROUTES.DASHBOARD_EDIT_SHOWROOM_ID,
  congrats: ROUTES.DASHBOARD_PUBLISH_ARTWORK_CONGRATS_ID,
  transfer: '/dashboard/transfer-artwork/:artworkId/:printId?', // TODO: ROUTES.DASHBOARD_TRANSFER_ARTWORK_ARTWORK_ID_PRINT_ID doesn't have the ? at the end, could it affect??
  manageTransfer: '/dashboard/manage-transfer/:artworkId/:printId?', // TODO: DASHBOARD_MANAGE_TRANSFER_ARTWORK_ID_PRINT_ID doesn't have the ? at the end, could it affect??
  transferPrint: ROUTES.DASHBOARD_TRANSFER_PRINT_ARTWORK_ID_EDITION_BY_TYPE_ID_PRINT_NUMBER,
  helpCenter: ROUTES.DASHBOARD_HELP,
  publish: ROUTES.DASHBOARD_PUBLISH,
  publishCongrats: `/dashboard/publish/${PUBLISH_CONGRATS_ROUTE}/:artworkId/:editionId?`,
  edit: ROUTES.DASHBOARD_EDIT, // TODO: WHAT IS THIS??
  completeProfile: ROUTES.COMPLETE_PROFILE_TYPE,
  completeProfileRegister: ROUTES.COMPLETE_PROFILE_REGISTER,
  discovery: ROUTES.DASHBOARD_DISCOVERY,
  myWebsite: ROUTES.DASHBOARD_MY_WEBSITE,
  analytics: ROUTES.DASHBOARD_ANALYTICS,
  myDocuments: ROUTES.DASHBOARD_MY_DOCUMENTS,
  myAugmentations: ROUTES.DASHBOARD_MY_AUGMENTATIONS,
  mySubscribers: ROUTES.DASHBOARD_MY_SUBSCRIBERS,
  myProvenance: ROUTES.DASHBOARD_MY_PROVENANCE,
  applyToArtUniverse: ROUTES.DASHBOARD_APPLY_TO_MY_UNIVERSE,
  myAnalytics: '/dashboard/my-analytics', // TODO: WHAT'S THE DIFFERENCE BETWEEN ANALYTICS AND MY ANALYTICS???
};
