import React from 'react';
import useAppSelector from 'common/hooks/useAppSelector';
import { Box, Typography } from '@material-ui/core';
import useShowPreferredCurrency from 'common/hooks/useShowPreferredCurrency';
import currencyFormatter from 'common/utils/currencyFormatter';
import { SwipeContainerComponentsProps } from '.';
import { useSwipeContainerComponentsStyles } from './useSwipeContainerComponents.styles';
import SwipeContainerInquiryButton from './SwipeContainerInquiryButton';

const SwipeContainerPrice: React.FC<SwipeContainerComponentsProps> = ({ artworkId }) => {
  const classes = useSwipeContainerComponentsStyles();
  const { prices } = useAppSelector((store) => store.entities.artworkSwipeContainerCache[artworkId]) ?? {};
  const { convertToPreferredCurrency } = useShowPreferredCurrency();
  const { price: convertedPrice, currency: preferredCurr } = convertToPreferredCurrency({
    price: prices?.[0]?.price ?? 0,
    currency: prices?.[0]?.currency,
  });
  const { formattedAmount } = currencyFormatter(preferredCurr, convertedPrice);

  return (
    <Box className={classes.priceWrapper}>
      <Typography variant="h5" className={classes.price}>
        {prices?.[0]?.price !== undefined && prices?.[0]?.price !== null ? formattedAmount : ''}
      </Typography>
      <SwipeContainerInquiryButton artworkId={artworkId} />
    </Box>
  );
};

export default SwipeContainerPrice;
