import useAppSelector from 'common/hooks/useAppSelector';
import { useEffect, useMemo } from 'react';
import useDebouncedState from 'common/hooks/utils/useDebouncedState';
import { priceRangeFilterHash } from 'common/features/Filters/predefined/PriceRange/priceRange.utils';
import { orientationFilterHash } from 'common/features/Filters/predefined/Orientation/orientation.utils';
import { availabilityFilterHash } from 'common/features/Filters/predefined/Availability/availability.utils';
import { FavoritesFiltersHash } from '../store/list.types';
import { FavoritesFiltersState } from '../store/filter.types';

// exported for use in the initial state
export const filtersHash = (state: FavoritesFiltersState, suffix: string | number = ''): FavoritesFiltersHash =>
  // using template string to match typing
  `${state.currentSort}_${[
    priceRangeFilterHash(state.priceRange),
    orientationFilterHash(state.orientation),
    availabilityFilterHash(state.availability),
    suffix,
  ].join('_')}` as const;

export const useFavoritesFiltersIdentifier = (contextId?: string | number): FavoritesFiltersHash => {
  const filters = useAppSelector((store) => store.myFavorites.filter);
  const currentIdentifier = useMemo(() => filtersHash(filters, contextId), [filters, contextId]);
  const [appliedIdentifier, setAppliedIdentifier] = useDebouncedState(currentIdentifier, 500);
  useEffect(() => {
    setAppliedIdentifier(currentIdentifier);
  }, [currentIdentifier]);
  return appliedIdentifier;
};
