import { makeStyles, Theme } from '@material-ui/core';
import zIndex from 'common/utils/zIndex';
import './keyframes.css';

const INITIAL_BADGE_DURATION = 4;
const INITIAL_ICON_DURATION = 0.7;
const INITIAL_ICON_DELAY = 0.5;

export const usePrizeWonBadgeStyles = makeStyles<Theme, { top: number }>((theme) => ({
  wrapper: ({ top }) => ({
    background: 'rgba(255, 255, 255, 0.8)',
    boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.12)',
    backdropFilter: 'blur(24px)',
    cursor: 'pointer',
    borderRadius: '8px',
    position: 'absolute',
    zIndex: zIndex.values.CARD_BADGE,
    top,
    left: 8,
    height: '44px',
    maxWidth: '44px',
    minWidth: '44px',
    overflow: 'hidden',
    transition: 'max-width 0.6s ease-in-out',
    '&:hover': {
      maxWidth: 'var(--cardWidth)',
      '& div': {
        opacity: 1,
      },
    },
  }),
  wrapperAnimation: {
    animation: `prize-card-expand ${INITIAL_BADGE_DURATION}s`,
    animationDelay: `${INITIAL_ICON_DELAY + INITIAL_ICON_DURATION}s`,
  },
  badge: {
    ...theme.mixins.flexCentered,
    padding: '0px 9px',

    width: 'max-content',
    height: '100%',
  },
  icon: {
    height: '28px',
    width: '28px',
    marginRight: theme.spacing(1.75),
    opacity: 0,
    position: 'relative',
    right: 1,
  },
  iconAnimation: {
    animation: `prize-card-zoom-in ${INITIAL_ICON_DURATION}s`,
    animationDelay: `${INITIAL_ICON_DELAY}s`,
    animationFillMode: 'forwards',
  },
  prizeInfo: {
    ...theme.mixins.flexColumn,
    transition: 'opacity 0.5s',
    transitionDelay: '0.2s',
    opacity: 0,
    '& h5': {
      fontSize: theme.typography.pxToRem(16),
      color: 'rgba(0, 0, 0, 0.64) !important',
    },
    '& h6': {
      fontSize: theme.typography.pxToRem(14),
      fontWeight: 'normal',
      color: 'rgba(0, 0, 0, 0.5) !important',
    },
  },
  prizeInfoAnimation: {
    animation: `prize-card-expand-fade ${INITIAL_BADGE_DURATION}s`,
    animationDelay: `${INITIAL_ICON_DELAY + INITIAL_ICON_DURATION}s`,
  },
}));
