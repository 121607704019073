import { makeStyles, Theme } from '@material-ui/core';

const maxLeftOffset = window.innerWidth - (2 * window.innerWidth) / 2.6 - 30;

type SwipeInfoContainerStylesProps = { maxRightOffset: number; isSquare?: boolean; rightOffset?: number };

export const useSwipeInfoContainerStyles = makeStyles<Theme, SwipeInfoContainerStylesProps>((theme) => ({
  wrapper: ({ isSquare }) => ({
    overflowX: 'hidden',
    position: isSquare ? 'relative' : undefined,
    top: '-100%',
    marginBottom: '-100%',
    width: '100%',
    height: '100%',
    paddingBottom: '100%',
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  }),
  swipeContainer: ({ maxRightOffset }) => ({
    [theme.breakpoints.between('sm', 'md')]: {
      width: (props: SwipeInfoContainerStylesProps) => `calc(100% - ${props.rightOffset}px)`,
      minWidth: (props: SwipeInfoContainerStylesProps) => `calc(100% - ${props.rightOffset}px)`,
    },
    boxSizing: 'border-box',
    position: 'absolute',
    width: `calc(100% - ${maxLeftOffset}px)`,
    minWidth: `calc(100% - ${maxLeftOffset}px)`,
    height: '100%',
    left: maxRightOffset,
    transition: 'left 0.5s',
    borderRadius: '6px',
    padding: '8px 4px 8px 12px',
    border: theme.palette.common.black === 'white' ? '1px solid #343434' : '1px solid rgba(0, 0, 0, 0.12)',
    overflowY: 'scroll',
  }),
  contentWrapper: {
    display: 'table',
    height: '100%',
  },
  contentCenter: {
    display: 'table-cell',
    verticalAlign: 'middle',
  },
}));
