import { useMemo } from 'react';
import useAppSelector from 'common/hooks/useAppSelector';
import { useSelectedProfile } from 'common/hooks/useSelectedProfile';
import { Enum_Social_Graph_Followers_State_Enum } from 'common/schema/commonSchemaRemoteTypes';
import { FollowButtonPreview } from 'common/components/RecognizeButtonV2/components/FollowButton';
import { GenericArtworkCardPreviewProps } from '../GenericArtworkCard/GenericArtworkCardPreview';
import { getAspectArtworkImageSrc, getProfileAvatarSrc, getSquareArtworkImageSrc } from './common/imageGetters';
import { getArtistLocationText, getArtistTitleText } from './common/typographyTextTypeGetters';
import { getKnownAspectRatio, squareAspectRatio } from './common/aspectRatio';
import getAvailabilityDot from './common/getAvailabilityDot';
import { FavoriteCTAPreview } from './common/CTAPreviews';
import {
  oneColumnSpeedToAspectImgSize,
  oneColumnSpeedToSquareImgSize,
  speedToAspectImgSize,
  speedToBlur,
  speedToSquareImgSize,
} from './common/scrollSpeedImageSizeMap';

const cardCTAStyle: React.CSSProperties = {
  width: '37.5px',
  alignSelf: 'flex-end',
  marginRight: '-6.5px', // 1.5 + 5
};

const artistChipPropsOverride: {
  superFastScroll: GenericArtworkCardPreviewProps['artistChipProps'];
} = {
  superFastScroll: {
    image: undefined,
    textItems: [],
  },
};

const artworkInfoPropsOverride: {
  superFastScroll: GenericArtworkCardPreviewProps['artworkInfoProps'];
} = {
  superFastScroll: {
    prefix: undefined,
    title: '',
    infoCTA: undefined,
  },
};

const cardCTAPreviewOverride: {
  superFastScroll: GenericArtworkCardPreviewProps['cardCTAPreview'];
} = {
  superFastScroll: <></>,
};

const useGenericCardPreviewProps = (
  artworkId: number,
  width: number,
  columnCount: number,
  scrollSpeed: number,
  aspectRatio?: number,
  isSquare?: boolean
): GenericArtworkCardPreviewProps => {
  const { artwork, artist } = useAppSelector((store) => store.entities.artworkCardsCache[artworkId]) ?? {};
  const hasSmallCTA = width <= 600;
  const isSuperFastScroll = scrollSpeed > 4;
  const hasOnlyOneColumn = columnCount === 1;
  const currentContextId = useSelectedProfile()?.contextId;

  // fav cta
  const isFavorite = useAppSelector(
    (store) =>
      !!store.favorites.favorites.find((v) => v.artworkId === artwork?.artworkId && v.contextId === currentContextId)
  );

  // recognize cta
  const isRecognizing = useAppSelector(
    (store) =>
      store.entities.recognitionsCache.outgoing[artist?.contextId ?? 0]?.state ===
      Enum_Social_Graph_Followers_State_Enum.Active
  );

  // has data
  const hasArtist = !!artist;
  const hasArtwork = !!artwork;

  const props = useMemo<GenericArtworkCardPreviewProps>(() => {
    // artist chip
    const artistAvatarSrc = getProfileAvatarSrc(
      hasOnlyOneColumn ? 'large' : 'medium',
      artist?.profileId,
      artist?.avatar
    );
    const avatarSize = hasSmallCTA ? '40px' : '40px';
    const artistTitleTextProps = getArtistTitleText(artist?.title);
    const artistLocationTextProps = getArtistLocationText(artist?.city, artist?.country);

    // artwork image
    const squareImageSize = (hasOnlyOneColumn ? oneColumnSpeedToSquareImgSize : speedToSquareImgSize)[scrollSpeed];
    const aspectImageSize = (hasOnlyOneColumn ? oneColumnSpeedToAspectImgSize : speedToAspectImgSize)[scrollSpeed];
    const shouldBeSquare = isSquare === undefined ? hasOnlyOneColumn : isSquare;
    const finalAspectRatio = shouldBeSquare ? squareAspectRatio : getKnownAspectRatio(aspectRatio);
    const squareImageSrc = getSquareArtworkImageSrc(squareImageSize, artwork?.artworkId, artwork?.primaryMetadataId);
    const aspectImageSrc = getAspectArtworkImageSrc(aspectImageSize, artwork?.artworkId, artwork?.primaryMetadataId);
    const finalSrc = shouldBeSquare ? squareImageSrc : aspectImageSrc;
    const finalBlur = speedToBlur[scrollSpeed];

    // artwork info
    const availabilityDot = getAvailabilityDot(
      artwork?.lastTransactionId,
      artwork?.isAvailableSale,
      artwork?.editionIsAvailableSale,
      artwork?.availablePrintAmount
    );

    const cardCTAPreview =
      isSuperFastScroll || !artist?.contextId ? (
        cardCTAPreviewOverride.superFastScroll
      ) : (
        <FollowButtonPreview
          predefine={hasSmallCTA ? 'SHORT_MEDIUM_LISTING_CARD_CTA' : 'TALL_WIDE_LISTING_CARD_CTA'}
          isRecognizing={isRecognizing}
          targetContextId={artist.contextId ?? 0}
        />
      );

    const infoCTA = <FavoriteCTAPreview isFavorite={isFavorite} noShow={!artwork?.artworkId} />;

    return {
      artistChipProps: {
        avatarSize,
        image: artistAvatarSrc,
        textItems: [artistTitleTextProps, artistLocationTextProps],
        ...(isSuperFastScroll ? artistChipPropsOverride.superFastScroll : {}),
      },
      imageProps: {
        src: finalSrc,
        aspectRatio: finalAspectRatio,
        blur: finalBlur,
      },
      isSquare: shouldBeSquare,
      artworkInfoProps: {
        infoCTA,
        title: artwork?.title ?? '',
        details: 'preview',
        prefix: {
          title: availabilityDot,
        },
        ctaStyle: cardCTAStyle,
        ...(isSuperFastScroll ? artworkInfoPropsOverride.superFastScroll : {}),
      },
      cardCTAPreview,
    };
    // controlled memo recalculations
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasArtist, hasArtwork, hasSmallCTA, isRecognizing, isFavorite, currentContextId]);
  return props;
};

export default useGenericCardPreviewProps;
