import { makeStyles } from '@material-ui/core';
import zIndex from 'common/utils/zIndex';
import './common/keyframes.css';

const INITIAL_BADGE_DURATION = 4;
const INITIAL_ICON_DURATION = 0.7;
const INITIAL_ICON_DELAY = 0.5;
const INITIAL_ARROW_DURATION = 1;
const INITIAL_AVATAR_DURATION = 0.1;

export const useArtCollectedBadgeStyles = makeStyles((theme) => ({
  wrapper: {
    background: 'rgba(255, 255, 255, 0.8)',
    boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.12)',
    backdropFilter: 'blur(24px)',
    borderRadius: '8px',
    position: 'absolute',
    zIndex: zIndex.values.CARD_BADGE,
    top: 10,
    left: 8,
    height: '44px',
    minWidth: '44px',
    maxWidth: '44px',
    overflow: 'hidden',
    transition: 'max-width 0.6s ease-in-out',
    '&:hover': {
      maxWidth: 'var(--cardWidth)',
      '& div': {
        opacity: 1,
      },
    },
  },
  wrapperAnimation: {
    animation: `collected-card-expand ${INITIAL_BADGE_DURATION}s`,
    animationDelay: `${INITIAL_ICON_DELAY + INITIAL_ICON_DURATION}s`,
  },
  badge: {
    ...theme.mixins.flexCentered,
    padding: '0px 9px',
    width: 'max-content',
    height: '100%',
  },
  icon: {
    marginRight: theme.spacing(1.75),
    height: '16px',
    width: '16px',
    margin: '0px 5px',
    opacity: 0,
    backgroundColor: '#B93C2D',
    borderRadius: '16px',
  },
  iconAnimation: {
    animation: `collected-card-zoom-in ${INITIAL_ICON_DURATION}s`,
    animationDelay: `${INITIAL_ICON_DELAY}s`,
    animationFillMode: 'forwards',
  },
  badgeInfo: {
    ...theme.mixins.flexColumn,
    transition: 'opacity 0.5s',
    transitionDelay: '0.2s',
    opacity: 0,
    '& h5': {
      fontSize: theme.typography.pxToRem(16),
      color: 'rgba(0, 0, 0, 0.64)',
    },
    '& h6': {
      fontSize: theme.typography.pxToRem(14),
      color: 'rgba(0, 0, 0, 0.5)',
    },
  },
  badgeInfoAnimation: {
    animation: `collected-card-expand-fade ${INITIAL_BADGE_DURATION}s`,
    animationDelay: `${INITIAL_ICON_DELAY + INITIAL_ICON_DURATION}s`,
  },
  chipWrapper: {
    ...theme.mixins.flexRow,
    width: '100%',
  },
  sellerInfo: {
    ...theme.mixins.flexColumn,
    marginLeft: theme.spacing(1.25),
    justifyContent: 'center',
    flexGrow: 1,
    flexShrink: 0,
    maxWidth: 'calc(100% - 160px)',
    minWidth: '20px',
    '& h5': {
      fontSize: theme.typography.pxToRem(18),
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      textWrap: 'nowrap',
    },
    '& h6': {
      fontSize: theme.typography.pxToRem(16),
      color: 'rgba(0, 0, 0, 0.5)',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      textWrap: 'nowrap',
    },
  },
  collectorAvatar: {
    marginLeft: 'auto',
    opacity: 0,
  },
  collectorAvatarAnimation: {
    animation: `collected-card-collector-zoom-in ${INITIAL_AVATAR_DURATION}s`,
    animationDelay: `${INITIAL_ICON_DELAY + INITIAL_ICON_DURATION + 0.8}s`,
    animationFillMode: 'forwards',
  },
  arrow: {
    ...theme.mixins.flexCentered,
    width: 0,
    opacity: 0,
    margin: '0px 10px',
  },
  arrowAnimation: {
    animation: `collected-card-arrow-expand ${INITIAL_ARROW_DURATION}s`,
    animationDelay: `${INITIAL_ICON_DELAY + INITIAL_ICON_DURATION + 0.4}s`,
    animationFillMode: 'forwards',
  },
  arrowLine: {
    background: 'rgba(0, 0, 0, 0.16)',
    borderRadius: '1px',
    height: '2px',
    width: '100%',
  },
  arrowHead: {
    position: 'relative',
    right: 9,
    bottom: 0.01,
    border: 'solid rgba(0, 0, 0, 0.16)',
    background: 'transparent',
    borderWidth: '0 2px 2px 0',
    height: '8px',
    width: '8px',
    transform: 'rotate(-45deg)',
  },
  buyerContainer: {
    width: 'calc(100% - 90px)',
    display: 'flex',
    flexDirection: 'row',
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));
