import { Grid } from '@material-ui/core';
import { ColumnText } from 'common/components/TypographyText/ColumnText';
import { TypographyTextType } from 'common/components/TypographyText/SkeletonText';
import React from 'react';
import { mixins } from 'utils/customTheme';
import { defaultAnimationState } from './ArtworkCardOnHover';

const defaultTitleTextProps: TypographyTextType = {
  variant: 'h4',
  style: {
    color: 'white',
    fontWeight: 700,
    maxWidth: '100%',
    ...mixins.textEllipsis,
  },
};

const defaultDetailsTextProps: TypographyTextType = {
  variant: 'h5',
  style: {
    ...defaultAnimationState.toAnimate,

    color: 'rgba(255, 255, 255, 0.8)',
    fontWeight: 400,
    fontSize: '16px !important',
    lineHeight: '22px',
    maxWidth: '100%',

    ...mixins.textEllipsis,
  },
};

export type ArtworkInformationProps = {
  ctaStyle?: React.CSSProperties;
  title?: string;
  details?: string;
  infoCTA?: React.ReactNode;
  overrideArtworkInfoTextProps?: {
    title?: TypographyTextType;
    details?: TypographyTextType;
  };
  prefix?: {
    title?: React.ReactNode;
  };
};
export type ArtworkInformationType = React.FC<ArtworkInformationProps>;
export const ArtworkInformation: ArtworkInformationType = ({
  ctaStyle,
  infoCTA,
  title,
  details,
  overrideArtworkInfoTextProps,
  prefix,
}) => (
  <Grid
    container
    spacing={1}
    alignItems="center"
    style={{
      padding: 16,
      paddingBottom: 8,
      position: 'relative',
      flexWrap: 'nowrap',
      maxWidth: 'calc(100% + 8px)',
    }}
  >
    <ColumnText style={defaultAnimationState.details} className="details">
      {[
        {
          text: title,
          ...defaultTitleTextProps,
          ...(overrideArtworkInfoTextProps?.title ?? {}),
          prefixText: prefix?.title,
        },
        {
          text: details,
          ...defaultDetailsTextProps,
          ...(overrideArtworkInfoTextProps?.details ?? {}),
          className: 'toAnimate',
        },
      ]}
    </ColumnText>

    {infoCTA && <div style={ctaStyle}>{infoCTA}</div>}
  </Grid>
);
