import { ArtistContextIdFilterState } from 'common/features/Filters/predefined/ArtistContextId/store/artistContextId.types';
import { ArtworksFilterState } from 'common/features/Filters/predefined/Artworks/store/artworks.types';
import { DateFilterState } from 'common/features/Filters/predefined/Date/store/date.types';
import { RefreshFilterState } from 'common/features/Filters/predefined/Refresh/refresh.types';

export const sorts = ['byDate'] as const;
export type HomeArtworksSort = (typeof sorts)[0];
export const isHomeArtworkSort = (v: string): v is HomeArtworksSort => sorts.includes(v as HomeArtworksSort);

// currently auto-applies on every change
// maybe todo: add flag to apply on demand
export type HomeArtworksFiltersState = {
  sort: HomeArtworksSort;
} & RefreshFilterState &
  ArtistContextIdFilterState & { since: DateFilterState } & { until: DateFilterState } & {
    artworks: ArtworksFilterState;
  };
