import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { windowReloadWithFlags } from 'common/utils/windowReload';

type PullDownSliceType = {
  canPull: boolean;
  refreshFn: () => void;
};

const initialState: PullDownSliceType = {
  canPull: false,
  refreshFn: () => {
    windowReloadWithFlags();
  },
};

const pullDownSlice = createSlice({
  initialState,
  name: 'pullDown',
  reducers: {
    UPDATE: (state, action: PayloadAction<Partial<PullDownSliceType>>) => ({
      canPull: action.payload.canPull ?? state.canPull,
      refreshFn: action.payload.refreshFn ?? state.refreshFn,
    }),
  },
});

export const pullDownActions = pullDownSlice.actions;
export default pullDownSlice;
