import { createSlice } from '@reduxjs/toolkit';
import { ArtworksListType } from './artworkListModifiers';

export type ShowcasePayload = Partial<
  Pick<
    ArtworkShowcaseStateType,
    'handle' | 'slug' | 'listIdentifier' | 'listType' | 'openedFrom' | 'openedFromDarkMode' | 'parentPrefix'
  >
>;

type ArtworkShowcaseStateType = {
  listIdentifier: string;
  listType: ArtworksListType;
  handle: string;
  slug: string;
  triggerValue: number;
  openedFrom: string;
  openedFromDarkMode: boolean;
  parentPrefix: string;
};

const getInitialState = (): ArtworkShowcaseStateType => ({
  listIdentifier: '',
  listType: ArtworksListType.ARTIST_PROFILE,
  handle: '',
  slug: '',
  triggerValue: 0,
  openedFrom: '',
  openedFromDarkMode: false,
  parentPrefix: '',
});

const artworkShowcaseSlice = createSlice({
  name: 'artworkShowcase',
  initialState: getInitialState(),
  reducers: {
    OPEN_SHOWCASE: (
      state,
      action: {
        payload: ShowcasePayload;
      }
    ) => ({
      ...state,
      ...action.payload,
      triggerValue: state.triggerValue + 1,
    }),
    PREP_SHOWCASE: (
      state,
      action: {
        payload: ShowcasePayload;
      }
    ) => ({
      ...state,
      ...action.payload,
    }),
  },
});

export const artworkShowcaseActions = artworkShowcaseSlice.actions;

export default artworkShowcaseSlice;
