import { ENV_TYPE } from 'utils/constants';

interface SlideshowArtworkLibraryType {
  artworkId: number;
  metadataId: number;
  artistName: string;
}

export const SLIDESHOW_ARTWORK_LIBRARY: SlideshowArtworkLibraryType[] =
  ENV_TYPE !== 'production'
    ? [
        {
          artworkId: 231128,
          metadataId: 13405,
          artistName: 'Robert',
        },
        {
          artworkId: 363913,
          metadataId: 22879,
          artistName: 'Rahela',
        },
        {
          artworkId: 364221,
          metadataId: 23948,
          artistName: 'Alucard',
        },
        {
          artworkId: 366738,
          metadataId: 35238,
          artistName: 'Maria artist name',
        },
        {
          artworkId: 22651,
          metadataId: 11382,
          artistName: 'Andreea Test',
        },
        {
          artworkId: 368299,
          metadataId: 39952,
          artistName: 'Leonel Barberena',
        },
        {
          artworkId: 12596,
          metadataId: 8564,
          artistName: 'Diego Caicedo',
        },
      ]
    : [
        {
          artworkId: 148182,
          metadataId: 658878,
          artistName: 'Alisa Sibirskaya',
        },
        {
          artworkId: 149380,
          metadataId: 664582,
          artistName: 'Mark Thompson',
        },
        {
          artworkId: 143212,
          metadataId: 644946,
          artistName: 'Gil Bruvel',
        },
        {
          artworkId: 40317,
          metadataId: 190460,
          artistName: 'Ofelia Andrades Madariaga',
        },
        {
          artworkId: 143736,
          metadataId: 647453,
          artistName: 'Anna Dart',
        },
        {
          artworkId: 65057,
          metadataId: 295224,
          artistName: 'Tzu Chen Chen',
        },
        {
          artworkId: 141093,
          metadataId: 633582,
          artistName: 'Daan Noppen',
        },
        {
          artworkId: 125281,
          metadataId: 561676,
          artistName: 'Luiz Pasqualini',
        },
        {
          artworkId: 125071,
          metadataId: 560711,
          artistName: 'Albert Gonzales',
        },
        {
          artworkId: 141552,
          metadataId: 636216,
          artistName: 'Jan Valdes Tellez',
        },

        {
          artworkId: 152463,
          metadataId: 681002,
          artistName: 'Robin Jack',
        },
        {
          artworkId: 142682,
          metadataId: 642323,
          artistName: 'Ali Cavanaugh',
        },
        {
          artworkId: 162693,
          metadataId: 725851,
          artistName: 'TocoDeco',
        },
        {
          artworkId: 143520,
          metadataId: 646378,
          artistName: 'ANTOINE RENAULT',
        },
        {
          artworkId: 30207,
          metadataId: 141965,
          artistName: 'William D Higginson',
        },
        {
          artworkId: 141723,
          metadataId: 637153,
          artistName: 'Alice Zilberberg',
        },
      ];
