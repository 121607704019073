import { createSlice } from '@reduxjs/toolkit';
import { ROUTES, CUSTOM_DOMAIN_ROUTES } from 'routes/_definition';
import { MODULES } from '../utils/UniversalSwipeHandlersTypes';

type Key = {
  key: string;
};

export type UniversalSwipeHandlersState = {
  modules?: Partial<
    Record<MODULES, Partial<Record<ROUTES, Key | null>> & Partial<Record<CUSTOM_DOMAIN_ROUTES, Key | null>>>
  >;
};

export const universalSwipeHandlersInitialState: UniversalSwipeHandlersState = {
  modules: {},
};

export const universalSwipeHandlersSlice = createSlice({
  name: 'universalSwipeHandlers',
  initialState: universalSwipeHandlersInitialState,
  reducers: {
    MOUNT_MODULE: <T extends MODULES>(
      state: UniversalSwipeHandlersState,
      action: {
        payload: {
          module: T;
          page: string;
        };
      }
    ) => {
      const { module, page } = action.payload;
      state.modules = state.modules ?? {};
      state.modules[module] = {
        ...state.modules[module],
        [page]: {
          key: module,
        },
      };
    },
  },
});
export const universalSwipeHandlersActions = universalSwipeHandlersSlice.actions;
