import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isOnAccountPage: false,
  isOnCreateArtPage: false,
  isOnAdminPage: false,
  isOnLandingPage: false,
  hideProfileMenu: 0,
};

const themeSlice = createSlice({
  initialState,
  name: 'theme',
  reducers: {
    SET_ON_PROFILE_PAGE: (state, action) => {
      state.isOnAccountPage = action.payload;
    },
    SET_ON_CREATE_ART_PAGE: (state, action) => {
      state.isOnCreateArtPage = action.payload;
    },
    SET_ON_ADMIN_PAGE: (state, action) => {
      state.isOnAdminPage = action.payload;
    },
    SET_ON_LANDING_PAGE: (state, action) => {
      state.isOnLandingPage = action.payload;
    },
    HIDE_PROFILE_MENU: (state) => {
      state.hideProfileMenu += 1;
    },
  },
});

export const themeActions = themeSlice.actions;
export const themeReducer = themeSlice.reducer;
