import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GetArtworkDetailsForSwipeContainerQuery } from 'common/schema/commonSchemaRemoteOperationTypes';
import { Scalars } from 'common/schema/commonSchemaRemoteTypes';

type ArtworkSwipeContainerEntities = Record<Scalars['bigint'], GetArtworkDetailsForSwipeContainerQuery['Artwork'][0]>;

const initialState: ArtworkSwipeContainerEntities = {};

export const artworkSwipeContainerSlice = createSlice({
  name: 'artworkSwipeContainerCache',
  initialState,
  reducers: {
    UPDATE_SWIPE_DETAILS: (state, action: PayloadAction<GetArtworkDetailsForSwipeContainerQuery['Artwork'][0]>) => {
      state[action.payload.id] = action.payload;
    },
  },
});
