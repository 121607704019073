import { useMemo } from 'react';
import useAppSelector from 'common/hooks/useAppSelector';
import { useSelectedProfile } from 'common/hooks/useSelectedProfile';
import { ArtCollectedPreviewChipProps } from '../ArtCollectedCard/components/ArtCollectedInfoPreview';
import { ArtCollectedCardPreviewProps } from '../ArtCollectedCard/ArtCollectedCardPreview';
import { GenericArtworkCardPreviewProps } from '../GenericArtworkCard/GenericArtworkCardPreview';
import {
  oneColumnSpeedToAspectImgSize,
  oneColumnSpeedToSquareImgSize,
  speedToAspectImgSize,
  speedToBlur,
  speedToSquareImgSize,
} from './common/scrollSpeedImageSizeMap';
import {
  unknownProfileImageSrc,
  getAspectArtworkImageSrc,
  getProfileAvatarSrc,
  getSquareArtworkImageSrc,
} from './common/imageGetters';
import { getKnownAspectRatio, squareAspectRatio } from './common/aspectRatio';
import getAvailabilityDot from './common/getAvailabilityDot';
import { FavoriteCTAPreview } from './common/CTAPreviews';

const artCollectedChipPropsOverride: {
  superFastScroll: ArtCollectedPreviewChipProps;
} = {
  superFastScroll: {
    seller: {
      title: '',
      avatar: '',
    },
    gallery: undefined,
  },
};

const artworkInfoPropsOverride: {
  superFastScroll: GenericArtworkCardPreviewProps['artworkInfoProps'];
} = {
  superFastScroll: {
    prefix: undefined,
    title: '',
    infoCTA: undefined,
  },
};

const artworkInfoCTAStyle: React.CSSProperties = {
  width: '37.5px',
  alignSelf: 'flex-end',
  paddingLeft: '6.75px', // 1.5 + 5.25
  marginRight: '-6.75px', // 1.5 + 5.25
  marginBottom: '1.5px',
};

const useArtCollectedCardPreviewProps = (
  artworkId: number,
  columnCount: number,
  galleryContextId: number,
  scrollSpeed: number,
  width: number,
  aspectRatio?: number,
  isSquare?: boolean
): ArtCollectedCardPreviewProps => {
  const { artwork, artist } = useAppSelector((store) => store.entities.artworkCardsCache[artworkId]) ?? {};
  const gallery = useAppSelector((store) => store.entities.collectedArtworkChipInfoCache.galleries[galleryContextId]);
  const isSuperFastScroll = scrollSpeed > 4;
  const hasOnlyOneColumn = columnCount === 1;

  const hasGallery = !!gallery;
  const hasArtwork = !!artwork;
  const hasArtist = !!artist;

  const currentContextId = useSelectedProfile()?.contextId;

  // fav cta
  const isFavorite = useAppSelector(
    (store) =>
      !!store.favorites.favorites.find((v) => v.artworkId === artwork?.artworkId && v.contextId === currentContextId)
  );

  return useMemo(() => {
    const hasSmallCTA = width <= 600;
    const avatarSize = hasSmallCTA ? 40 : 48;
    // artwork image
    const squareImageSize = (hasOnlyOneColumn ? oneColumnSpeedToSquareImgSize : speedToSquareImgSize)[scrollSpeed];
    const aspectImageSize = (hasOnlyOneColumn ? oneColumnSpeedToAspectImgSize : speedToAspectImgSize)[scrollSpeed];
    const shouldBeSquare = isSquare === undefined ? hasOnlyOneColumn : isSquare;
    const finalAspectRatio = shouldBeSquare ? squareAspectRatio : getKnownAspectRatio(aspectRatio);
    const squareImageSrc = getSquareArtworkImageSrc(squareImageSize, artwork?.artworkId, artwork?.primaryMetadataId);
    const aspectImageSrc = getAspectArtworkImageSrc(aspectImageSize, artwork?.artworkId, artwork?.primaryMetadataId);
    const finalSrc = shouldBeSquare ? squareImageSrc : aspectImageSrc;
    const finalBlur = speedToBlur[scrollSpeed];

    const infoCTA = <FavoriteCTAPreview isFavorite={isFavorite} noShow={!artwork?.artworkId} />;
    const availabilityDot = getAvailabilityDot(artwork?.lastTransactionId, artwork?.isAvailableSale);

    const sellerAvatarSrc =
      getProfileAvatarSrc(hasOnlyOneColumn ? 'large' : 'medium', artist?.profileId, artist?.avatar) ??
      unknownProfileImageSrc;
    const galleryAvatarSrc =
      getProfileAvatarSrc(hasOnlyOneColumn ? 'large' : 'medium', gallery?.profileId, gallery?.avatar) ??
      unknownProfileImageSrc;
    const artCollectedChipProps: ArtCollectedPreviewChipProps = {
      seller: {
        title: artist?.title ?? '',
        avatar: sellerAvatarSrc,
      },
      gallery: hasGallery
        ? {
            title: gallery?.title ?? '',
            avatar: galleryAvatarSrc,
          }
        : undefined,
      avatarSize,
    };
    return {
      artCollectedChipProps: {
        ...artCollectedChipProps,
        ...(isSuperFastScroll ? artCollectedChipPropsOverride.superFastScroll : {}),
      },
      imageProps: {
        src: finalSrc,
        blur: finalBlur,
        aspectRatio: finalAspectRatio,
      },
      isSquare: shouldBeSquare,
      artworkInfoProps: {
        title: artwork?.title ?? '',
        details: 'preview',
        prefix: {
          title: availabilityDot,
        },
        ctaStyle: artworkInfoCTAStyle,
        infoCTA,
        ...(isSuperFastScroll ? artworkInfoPropsOverride.superFastScroll : {}),
      },
    };
    // controlled re-computation
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasArtist, hasArtwork, hasGallery, hasOnlyOneColumn, isFavorite, isSquare]);
};

export default useArtCollectedCardPreviewProps;
