import { Enum_Tutorial_Status_Enum, Enum_Tutorial_Type_Enum } from 'common/schema/commonSchemaRemoteTypes';
import { TutorialRequirementsType, TutorialTypeMap } from './tutorialsTypes';

export const TUTORIAL_COMPLETED_STATUSES = [Enum_Tutorial_Status_Enum.Finished, Enum_Tutorial_Status_Enum.Skipped];

export const tutorialOverlays = [Enum_Tutorial_Type_Enum.CertificatesIntro, Enum_Tutorial_Type_Enum.DashboardIntro];

// TODO: change this to real youtube link
export const KALEIDO_CERTIFICATES_TUTORIAL_VIDEO_URL_EN = 'https://www.youtube.com/watch?v=VfZ2n84n7Mg';
export const KALEIDO_CERTIFICATES_TUTORIAL_EMBEDED_VIDEO_URL_EN =
  'https://www.youtube.com/embed/VfZ2n84n7Mg?autoplay=1&cc_load_policy=1';
export const KALEIDO_CERTIFICATES_TUTORIAL_VIDEO_URL_ES = 'https://www.youtube.com/watch?v=pR322Ayu4rs';
export const KALEIDO_CERTIFICATES_TUTORIAL_EMBEDED_VIDEO_URL_ES =
  'https://www.youtube.com/embed/pR322Ayu4rs?autoplay=1&cc_load_policy=1';

export const tutorialConditions: TutorialTypeMap<TutorialRequirementsType> = {
  DASHBOARD_INTRO: {
    completedTutorials: [],
    profileType: ['ARTIST'],
  },
  DASHBOARD_INTRO_PAGES: {
    completedTutorials: [],
    profileType: ['ARTIST'],
  },
  MANAGE_ART: {
    completedTutorials: [Enum_Tutorial_Type_Enum.DashboardIntro],
    profileType: ['ARTIST'],
  },
  EDIT_PROFILE: {
    completedTutorials: [Enum_Tutorial_Type_Enum.DashboardIntro],
    profileType: ['ARTIST'],
  },
  KALEIDO_COINS: {
    completedTutorials: [Enum_Tutorial_Type_Enum.DashboardIntro],
    profileType: ['ARTIST'],
  },
  KALEIDO_CARD: {
    completedTutorials: [Enum_Tutorial_Type_Enum.DashboardIntro],
    profileType: ['ARTIST'],
  },
  SHARE_KALEIDO: {
    completedTutorials: [Enum_Tutorial_Type_Enum.DashboardIntro],
    profileType: ['ARTIST'],
  },
  CERTIFICATES_INTRO: {
    completedTutorials: [Enum_Tutorial_Type_Enum.DashboardIntro],
    profileType: ['ARTIST'],
  },
  MY_WEBSITE: {
    completedTutorials: [Enum_Tutorial_Type_Enum.DashboardIntro],
    profileType: ['ARTIST'],
  },
  CONSUMER_PRODUCT_LIVE: {
    completedTutorials: [],
    profileType: ['ARTIST', 'COLLECTOR', 'GALLERY'],
  },
  CONSUMER_PRODUCT_EXPLORE: {
    completedTutorials: [],
    profileType: ['ARTIST', 'COLLECTOR', 'GALLERY'],
  },
  CONSUMER_PRODUCT_COLLECTIONS: {
    completedTutorials: [],
    profileType: ['ARTIST', 'COLLECTOR', 'GALLERY'],
  },
  CONSUMER_PRODUCT_PRIZES: {
    completedTutorials: [],
    profileType: ['ARTIST', 'COLLECTOR', 'GALLERY'],
  },
  CONSUMER_PRODUCT_DOTS: {
    completedTutorials: [],
    profileType: ['ARTIST', 'COLLECTOR', 'GALLERY'],
  },
  ART_SHOWCASE: {
    completedTutorials: [],
    profileType: ['ARTIST', 'COLLECTOR', 'GALLERY'],
  },
  ART_SHOWCASE_V2: {
    completedTutorials: [],
    profileType: ['ARTIST', 'COLLECTOR', 'GALLERY'],
  },
  ART_SHOWCASE_V3: {
    completedTutorials: [],
    profileType: ['ARTIST', 'COLLECTOR', 'GALLERY'],
  },
  ART_SHOWCASE_V2_SLIDER: {
    completedTutorials: [Enum_Tutorial_Type_Enum.ArtShowcaseV2],
    profileType: ['ARTIST', 'COLLECTOR', 'GALLERY'],
  },
};
