import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type HomeFeaturedCollectionType = {
  image: string;
  name: string;
  cardType: string;
  redirectTo: string;
  id: number;
};

const initialState: Record<number, HomeFeaturedCollectionType> = {};

export const homeFeaturedCollectionsSlice = createSlice({
  name: 'homeFeaturedCollections',
  initialState,
  reducers: {
    UPDATE_COLLECTIONS: (state, action: PayloadAction<HomeFeaturedCollectionType[]>) => {
      action.payload.forEach((collectionInfo) => {
        state[collectionInfo.id] = collectionInfo;
      });
    },
  },
});
