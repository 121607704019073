import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MY_AUGMENTATIONS_DEFAULT_ITEMS_PER_PAGE } from 'common/components/MyStudioPagination/utils/myStudioPaginationUtils';

export interface myAugmentationsSliceState {
  isLearnMoreModalOpen: boolean;
  currentPage: number;
  itemsPerPage: number;
  totalArtworks: number;
  hasArtworks: boolean;
}

const initialState: myAugmentationsSliceState = {
  isLearnMoreModalOpen: false,
  currentPage: 1,
  itemsPerPage: MY_AUGMENTATIONS_DEFAULT_ITEMS_PER_PAGE,
  totalArtworks: 0,
  hasArtworks: false,
};

const myAugmentationsSlice = createSlice({
  name: 'myAugmentations',
  initialState,
  reducers: {
    SET_ITEMS_PER_PAGE: (state, action: PayloadAction<number>) => ({ ...state, itemsPerPage: action.payload }),
    SET_CURRENT_PAGE: (state, action: PayloadAction<number>) => ({ ...state, currentPage: action.payload }),
    SET_TOTAL_ARTWORKS: (state, action: PayloadAction<number>) => ({ ...state, totalArtworks: action.payload }),
    SET_HAS_ARTWORKS: (state, action: PayloadAction<boolean>) => ({ ...state, hasArtworks: action.payload }),
  },
});

export const myAugmentationsActions = myAugmentationsSlice.actions;
export default myAugmentationsSlice;
