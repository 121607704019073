import {
  GetFilteredCollectionArtistsLazyQueryHookResult,
  GetFilteredExploreArtistsLazyQueryHookResult,
} from 'common/schema/commonSchemaRemoteGraphqlQueries';
import {
  ExploreArtistCommonFilterFieldsFragment,
  GetFilteredCollectionArtistsQuery,
  GetFilteredCollectionArtistsQueryVariables,
  GetFilteredExploreArtistsQuery,
  GetFilteredExploreArtistsQueryVariables,
} from 'common/schema/commonSchemaRemoteOperationTypes';
import { Explore_Artists_Bool_Exp, Explore_Artists_Order_By } from 'common/schema/commonSchemaRemoteTypes';
import { ProfileListType } from 'store/slices/profileListModifiers';

export interface FilteredProfileQueryHandlers {
  [ProfileListType.EXPLORE]: {
    queryHandler: GetFilteredExploreArtistsLazyQueryHookResult;
    queryProfileType: GetFilteredExploreArtistsQuery['Explore_artists'][0];
    queryVariables: GetFilteredExploreArtistsQueryVariables;
  };
  [ProfileListType.COLLECTION]: {
    queryHandler: GetFilteredCollectionArtistsLazyQueryHookResult;
    queryProfileType: GetFilteredCollectionArtistsQuery['Explore_artists'][0];
    queryVariables: Omit<GetFilteredCollectionArtistsQueryVariables, 'where'>;
  };
}

export type ExploreArtistsReturnType = GetFilteredExploreArtistsQuery | GetFilteredCollectionArtistsQuery;

export const FILTERS_DEFAULT_VALUE = 0;

type BaseOptionType = {
  name: string;
  value: number;
  key: string;
  payload: unknown;
};

export type BaseOptionValueType = BaseOptionType['value'];

export type PROFILE_QUERY_CONDITION = BaseOptionType & {
  key: 'PROFILE_QUERY_CONDITION';
  payload: Explore_Artists_Bool_Exp;
};

export type PROFILE_QUERY_CONDITION_IN = BaseOptionType & {
  key: 'PROFILE_QUERY_CONDITION_IN';
  payload: Explore_Artists_Bool_Exp;
};

export type PROFILE_LOCAL_CONDITION_PAYLOAD = (value: ExploreArtistCommonFilterFieldsFragment) => boolean;

export type PROFILE_LOCAL_CONDITION = BaseOptionType & {
  key: 'PROFILE_LOCAL_CONDITION';
  payload: PROFILE_LOCAL_CONDITION_PAYLOAD;
};

export type PROFILE_LOCAL_SORT_PAYLOAD = (
  a: ExploreArtistCommonFilterFieldsFragment,
  b: ExploreArtistCommonFilterFieldsFragment
) => number;

export type PROFILE_LOCAL_SORT = BaseOptionType & {
  key: 'PROFILE_LOCAL_SORT';
  payload: PROFILE_LOCAL_SORT_PAYLOAD;
};

export type PROFILE_QUERY_SORT = BaseOptionType & {
  key: 'PROFILE_QUERY_SORT';
  payload: Explore_Artists_Order_By;
};

export const isOptionType = <T extends BaseOptionType>(option: BaseOptionType, type: T['key']): option is T =>
  option.key === type;

export type filterTypes = 'SORT' | 'ARTIST' | 'DISCIPLINE' | 'REGION';

// export interface FiltersProfileType extends Partial<Context> {order?: number, price?: number}

export type ListingModifier = {
  type: filterTypes;
  allOptions: ListingModifierOption[];
};

export type ListingModifierOption =
  | PROFILE_QUERY_CONDITION
  | PROFILE_QUERY_CONDITION_IN
  | PROFILE_LOCAL_CONDITION
  | PROFILE_LOCAL_SORT
  | PROFILE_QUERY_SORT;

export type ListingModifierButton = {
  specialWidth?: 'FULL' | 'HALF';
  image?: string;
};

export type ListingModifierButtonsConfig = { [optionName: string]: ListingModifierButton };

export type AppliedListingModifier<T extends ListingModifierOption = ListingModifierOption> = {
  selectedOptions: ListingModifierOption['value'][];
  selectionType: 'SINGLE' | 'MULTIPLE';
  flattenSelectedOptions: (options: ListingModifierOption[], selectedOptions: ListingModifierOption['value'][]) => T;
} & ListingModifier;
