import React from 'react';
import { Skeleton } from '@material-ui/lab';

type SwipeContainerDetailElementProps = {
  text?: string | null;
  icon?: string;
};

const SwipeContainerDetailElement: React.FC<SwipeContainerDetailElementProps> = ({ text, icon }) => {
  if (!text) return <Skeleton width={150} height={40} style={{ borderRadius: '6px' }} />;

  return (
    <>
      <img src={icon} alt="icon" />
      {text}
    </>
  );
};

export default SwipeContainerDetailElement;
