import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface locationState {
  country: string;
  city: string;
  showAlternativeOptions?: boolean;
  address?: string;
  city_id?: string;
  admin_code?: string;
}

const initialState: locationState = {
  country: '',
  city: '',
  address: '',
  city_id: undefined,
  admin_code: undefined,
  showAlternativeOptions: false,
};

const locationSlice = createSlice({
  name: 'location',
  initialState,
  reducers: {
    SET_LOCATION: (state, action: PayloadAction<locationState>) => ({
      ...action.payload,
      showAlternativeOptions: state.showAlternativeOptions,
    }),
    SET_SHOW_ALTERNATIVE_OPTIONS: (state, action: PayloadAction<boolean>) => {
      state.showAlternativeOptions = action.payload;
    },
    RESET_LOCATION: () => initialState,
  },
});

export const locationActions = locationSlice.actions;
export default locationSlice;
