import { makeStyles } from '@material-ui/core';

const customStyle = makeStyles((theme) => ({
  provenanceLine: {
    borderLeft:
      theme.palette.common.black === '#0D0D0D' ? '1px solid rgba(0, 0, 0, 0.4)' : '1px solid rgba(255, 255, 255, 0.4)',
    height: 10,

    [theme.breakpoints.down('xs')]: {
      height: 17,
    },
  },
}));

const ProvenanceLine: React.FC = () => {
  const customClasses = customStyle();
  return (
    <div style={{ display: 'flex' }} className="line">
      <div style={{ width: '50%' }} />
      <div className={customClasses.provenanceLine} />
    </div>
  );
};

export default ProvenanceLine;
