import { useIdGen } from 'common/hooks/utils/useIdGen';
import { useIsArtUniverseRelated } from 'common/utils/isArtUniverseRelated';
import React from 'react';
import { CDNUrl } from 'utils/constants';
import { useSkeletonImageLogic } from '../Image/SkeletonImage';

const SVG_COMPONENTS_ID = 'svg-definitions';
const ROUND_IMG_MASK_ID = `${SVG_COMPONENTS_ID}-round-image`;
const BADGE_CUTOUT = {
  TOP_LEFT: { ROUND: `${SVG_COMPONENTS_ID}-cutout-top-left-round` },
  TOP_RIGHT: { ROUND: `${SVG_COMPONENTS_ID}-cutout-top-right-round` },
  BOTTOM_LEFT: { ROUND: `${SVG_COMPONENTS_ID}-cutout-bottom-left-round` },
  BOTTOM_RIGHT: { ROUND: `${SVG_COMPONENTS_ID}-cutout-bottom-right-round` },
};

const ART_UNIVERSE_GRADIENT_ID = `${SVG_COMPONENTS_ID}-art-universe-border-gradient`;
const ART_UNIVERSE_BORDER_CONTAINED_MASK_ID = `${SVG_COMPONENTS_ID}-art-universe-border-contained-mask`;
const ART_UNIVERSE_BORDER = `${SVG_COMPONENTS_ID}-art-universe-border`;
const ART_UNIVERSE_APP_ICON_ID = `${SVG_COMPONENTS_ID}-art-universe-app-icon`;

const ART_UNIVERSE_BORDER_FILL_WIDTH = (128 / 100) * 3.57; // 3.57% of full size (128)
const ART_UNIVERSE_BORDER_SPACE_WIDTH = (128 / 100) * 3.57;
const ART_UNIVERSE_BORDER_TOTAL_WIDTH = ART_UNIVERSE_BORDER_FILL_WIDTH + ART_UNIVERSE_BORDER_SPACE_WIDTH;
const ART_UNIVERSE_BORDER_RADIUS = 64 - ART_UNIVERSE_BORDER_FILL_WIDTH / 2;
const ART_UNIVERSE_BORDER_CUTOUT_RADIUS = 64 - ART_UNIVERSE_BORDER_TOTAL_WIDTH;

const BADGE_SIZE = (128 / 100) * 35.72; // 35.7% of full size (128)
const BADGE_RADIUS = BADGE_SIZE / 2;
const POSITIONS: Record<Badge['position'], { x: number; y: number; cx: number; cy: number }> = {
  topLeft: { x: 0, y: 0, cx: BADGE_SIZE / 2, cy: BADGE_SIZE / 2 },
  topRight: { x: 128 - BADGE_SIZE, y: 0, cx: 128 - BADGE_SIZE / 2, cy: BADGE_SIZE / 2 },
  bottomLeft: { x: 0, y: 128 - BADGE_SIZE, cx: BADGE_SIZE / 2, cy: 128 - BADGE_SIZE / 2 },
  bottomRight: { x: 128 - BADGE_SIZE, y: 128 - BADGE_SIZE, cx: 128 - BADGE_SIZE / 2, cy: 128 - BADGE_SIZE / 2 },
};

const EMPTY = `${SVG_COMPONENTS_ID}-empty`;

export const SVGComponents: React.FC = () => (
  <span style={{ opacity: 0, position: 'fixed', zIndex: -1, pointerEvents: 'none' }}>
    <svg id={SVG_COMPONENTS_ID} width="128" height="128" viewBox="0 0 128 128">
      <defs>
        <linearGradient
          id={ART_UNIVERSE_GRADIENT_ID}
          x1="121.6"
          y1="63.939"
          x2="6.466"
          y2="66.699"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".017" stopColor="#E466A5" />
          <stop offset=".295" stopColor="#3032A0" />
          <stop offset=".676" stopColor="#4D98DA" />
          <stop offset="1" stopColor="#9155E5" />
        </linearGradient>

        <mask id={ROUND_IMG_MASK_ID}>
          <circle cx="64" cy="64" r="64" fill="white" />
        </mask>
        <mask id={ART_UNIVERSE_BORDER_CONTAINED_MASK_ID}>
          <circle
            className="art-universe-border-contained"
            cx="64"
            cy="64"
            r={ART_UNIVERSE_BORDER_CUTOUT_RADIUS}
            fill="white"
          />
        </mask>
        <mask id={BADGE_CUTOUT.TOP_LEFT.ROUND}>
          <rect fill="white" x="0" y="0" width={128} height={128} />
          <circle fill="black" cx={POSITIONS.topLeft.cx} cy={POSITIONS.topLeft.cy} r={BADGE_RADIUS} />
        </mask>
        <mask id={BADGE_CUTOUT.BOTTOM_LEFT.ROUND}>
          <rect fill="white" x="0" y="0" width={128} height={128} />
          <circle fill="black" cx={POSITIONS.bottomLeft.cx} cy={POSITIONS.bottomLeft.cy} r={BADGE_RADIUS} />
        </mask>
        <mask id={BADGE_CUTOUT.TOP_RIGHT.ROUND}>
          <rect fill="white" x="0" y="0" width={128} height={128} />
          <circle fill="black" cx={POSITIONS.topRight.cx} cy={POSITIONS.topRight.cy} r={BADGE_RADIUS} />
        </mask>
        <mask id={BADGE_CUTOUT.BOTTOM_RIGHT.ROUND}>
          <rect fill="white" x="0" y="0" width={128} height={128} />
          <circle fill="black" cx={POSITIONS.bottomRight.cx} cy={POSITIONS.bottomRight.cy} r={BADGE_RADIUS} />
        </mask>
        <g id={EMPTY} />
      </defs>
    </svg>

    <svg id={ART_UNIVERSE_APP_ICON_ID} width="128" height="128" fill="none" viewBox="0 0 128 128">
      <circle cx="64" cy="64" r="52.8" stroke={`url(#${ART_UNIVERSE_GRADIENT_ID})`} strokeWidth="9.6" />
      <ellipse
        cx="74.071"
        cy="79.19"
        rx="8.383"
        ry="19.951"
        transform="rotate(144 74.071 79.19)"
        fill="#F7C852"
        fillOpacity=".55"
      />
      <ellipse
        cx="81.639"
        cy="60.403"
        rx="8.383"
        ry="19.951"
        transform="rotate(77.552 81.64 60.403)"
        fill="#F7C852"
        fillOpacity=".55"
      />
      <ellipse
        cx="8.383"
        cy="19.951"
        rx="8.383"
        ry="19.951"
        transform="scale(-1 1) rotate(77.552 -44.167 6.104)"
        fill="#F7C852"
        fillOpacity=".55"
      />
      <ellipse
        cx="8.383"
        cy="19.951"
        rx="8.383"
        ry="19.951"
        transform="matrix(-1 0 0 1 72.33 27.632)"
        fill="#F7C852"
        fillOpacity=".55"
      />
      <ellipse
        cx="53.787"
        cy="79.19"
        rx="8.383"
        ry="19.951"
        transform="rotate(-144 53.787 79.19)"
        fill="#F7C852"
        fillOpacity=".55"
      />
    </svg>

    <svg id={ART_UNIVERSE_BORDER} width="128" height="128" fill="none" viewBox="0 0 128 128">
      <circle
        cx="64"
        cy="64"
        className="art-universe-border"
        r={ART_UNIVERSE_BORDER_RADIUS}
        stroke={`url(#${ART_UNIVERSE_GRADIENT_ID})`}
        strokeWidth={ART_UNIVERSE_BORDER_FILL_WIDTH}
      />
    </svg>
  </span>
);

type Border = { type: 'none' | 'artUniverse'; withoutCutout?: boolean };

type Badge = {
  type: 'none' | 'artUniverse';
  position: 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight';
  withoutCutout?: boolean;
  text?: string;
};

type BaseProps = {
  src?: string;
  fallbackSrc?: string;
  withFallbackWhileLoading?: boolean;
};
const Base: React.FC<BaseProps> = ({ src, fallbackSrc, withFallbackWhileLoading }) => {
  const loadedSrc = useSkeletonImageLogic(src, fallbackSrc) ?? (withFallbackWhileLoading ? fallbackSrc : undefined);
  return (
    <g mask={`url(#${ROUND_IMG_MASK_ID})`}>
      <image width="128" height="128" xlinkHref={loadedSrc} preserveAspectRatio="xMidYMid slice" />
    </g>
  );
};

const BORDER_IDS: Record<Border['type'], string> = {
  none: EMPTY,
  artUniverse: ART_UNIVERSE_BORDER,
};
const BORDER_MASK_IDS: Record<Border['type'], string> = {
  none: ROUND_IMG_MASK_ID,
  artUniverse: ART_UNIVERSE_BORDER_CONTAINED_MASK_ID,
};

type BorderedProps = { border?: Border; children: ReturnType<typeof Base> };
const Bordered: React.FC<BorderedProps> = ({ border, children }) => {
  if (!border) {
    return <>{children}</>;
  }
  return (
    <g>
      {border.withoutCutout ? children : <g mask={`url(#${BORDER_MASK_IDS[border.type]})`}>{children}</g>}
      <use xlinkHref={`#${BORDER_IDS[border.type]}`} />
    </g>
  );
};

const BADGE_IDS: Record<Badge['type'], string> = {
  none: EMPTY,
  artUniverse: ART_UNIVERSE_APP_ICON_ID,
};
const BADGE_MASK_IDS: Record<Badge['position'], string> = {
  topLeft: BADGE_CUTOUT.TOP_LEFT.ROUND,
  topRight: BADGE_CUTOUT.TOP_RIGHT.ROUND,
  bottomLeft: BADGE_CUTOUT.BOTTOM_LEFT.ROUND,
  bottomRight: BADGE_CUTOUT.BOTTOM_RIGHT.ROUND,
};

type BadgedProps = { id?: number; badges?: Badge[]; children: ReturnType<typeof Bordered> };
const Badged: React.FC<BadgedProps> = ({ id, badges, children }) => {
  let innerContent = <>{children}</>;
  badges?.forEach((badge) => {
    innerContent = (
      <g key={`${badge.position}-mask-${id}`} mask={`url(#${BADGE_MASK_IDS[badge.position]})`}>
        {innerContent}
      </g>
    );
  }, []);
  return (
    <>
      {innerContent}
      {badges?.map((badge) =>
        !BADGE_IDS[badge.type] ? null : (
          <use
            width={BADGE_SIZE}
            height={BADGE_SIZE}
            x={POSITIONS[badge.position].x}
            y={POSITIONS[badge.position].y}
            key={`${badge.type}-badge-${id}`}
            xlinkHref={`#${BADGE_IDS[badge.type]}`}
          />
        )
      )}
    </>
  );
};

type RoundImageProps = BaseProps &
  Omit<BorderedProps, 'children'> &
  Omit<BadgedProps, 'children'> & {
    size: React.CSSProperties['width'];
    className?: string;
  };
const RoundImage: React.FC<RoundImageProps> = React.memo(({ className, size, ...borderBadgeBaseProps }) => {
  const id = useIdGen();
  return (
    <svg
      className={className}
      width="128"
      height="128"
      fill="none"
      viewBox="0 0 128 128"
      style={{ width: size, height: size }}
    >
      <Badged {...borderBadgeBaseProps} id={id}>
        <Bordered {...borderBadgeBaseProps}>
          <Base {...borderBadgeBaseProps} />
        </Bordered>
      </Badged>
    </svg>
  );
});

type TemplateProps = Omit<RoundImageProps, 'size' | 'src'>;

const BASIC_PROFILE_IMAGE_PROPS: TemplateProps = {
  fallbackSrc: `${CDNUrl}/images/profilePictures/ProfilePicture09.svg`,
  withFallbackWhileLoading: true,
};

const DEFAULT_AVATAR_SIZE = '40px';

export type ProfilePictureProps = { src?: string; size?: React.CSSProperties['width']; className?: string };

export const SimpleProfilePicture: React.FC<ProfilePictureProps> = ({ src, size = DEFAULT_AVATAR_SIZE }) => (
  <RoundImage {...React.useMemo(() => ({ ...BASIC_PROFILE_IMAGE_PROPS, src, size }), [src, size])} />
);

const BASIC_PROFILE_IMAGE_ART_UNIVERSE_PROPS: TemplateProps = {
  ...BASIC_PROFILE_IMAGE_PROPS,
  // TODO add new border and badges
  border: { type: 'artUniverse' },
  // badges: [{ type: 'artUniverse', position: 'bottomLeft' }],
};

export const ArtUniverseProfilePicture: React.FC<ProfilePictureProps> = ({ src, size = DEFAULT_AVATAR_SIZE }) => (
  <RoundImage
    className="art-universe-profile-picture"
    {...React.useMemo(() => ({ ...BASIC_PROFILE_IMAGE_ART_UNIVERSE_PROPS, src, size }), [src, size])}
  />
);
// .art-universe-profile-picture .art-universe-border-contained
// .art-universe-profile-picture .art-universe-border

export const ProfilePicture: React.FC<ProfilePictureProps> = ({ src, size = DEFAULT_AVATAR_SIZE }) => {
  const isOnArtUniverse = useIsArtUniverseRelated({ src });
  return isOnArtUniverse ? (
    <ArtUniverseProfilePicture src={src} size={size} />
  ) : (
    <SimpleProfilePicture src={src} size={size} />
  );
};

export const ArtUniverseAppIconOld: React.FC<{ fill?: string; size?: number }> = ({ fill = 'none', size }) => (
  <svg width="128" height="128" fill="none" viewBox="0 0 128 128" style={{ width: size, height: size }}>
    <rect width="128" height="128" rx="64" fill={fill} />
    <use xlinkHref={`#${ART_UNIVERSE_APP_ICON_ID}`} />
  </svg>
);

export const ArtUniverseAppIcon: React.FC<{ fill?: string; size?: number }> = ({ fill, size }) => (
  <div style={{ display: 'flex' }}>
    <svg width="128" height="128" fill={fill} viewBox="0 0 128 128" style={{ width: size, height: size }}>
      <image width="128" height="128" xlinkHref={`${CDNUrl}/images/artUniverse/badge.png`} />
    </svg>
  </div>
);

const CompositeImage = {
  SVGComponents,
  ArtUniverseAppIcon,
  ProfilePicture,
  ArtUniverseProfilePicture,
  SimpleProfilePicture,
};

export default CompositeImage;
