import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GetShopArtworkInfoQuery } from 'common/schema/commonSchemaRemoteOperationTypes';
import { Scalars } from 'common/schema/commonSchemaRemoteTypes';
import { NotNullish } from 'common/utils/types';

export type ShopCardDataType = {
  artist: NotNullish<GetShopArtworkInfoQuery['artists']>[0];
  artwork: NotNullish<GetShopArtworkInfoQuery['artworks']>[0];
};

export const shopCardDataTypeGuard = {
  isArtist: (data: unknown): data is ShopCardDataType['artist'] => {
    if (!data) {
      return false;
    }
    const artist = data as ShopCardDataType['artist'];
    return !!artist.contextId && !!artist.profileId;
  },
  isArtwork: (data: unknown): data is ShopCardDataType['artwork'] => {
    if (!data) {
      return false;
    }
    const artwork = data as ShopCardDataType['artwork'];
    return !!artwork.artworkId && !!artwork.artistContextId;
  },
};

type ArtworkCardEntities = Record<Scalars['bigint'], ShopCardDataType>;

const initialState: ArtworkCardEntities = {};

export const shopArtworkCardsSlice = createSlice({
  name: 'shopArtworkCards',
  initialState,
  reducers: {
    UPDATE_SHOP_CARDS: (state, action: PayloadAction<ShopCardDataType[]>) => {
      action.payload.forEach((card) => {
        if (!card.artwork.artworkId) {
          return;
        }
        state[card.artwork.artworkId] = card;
      });
    },
  },
});
