import { Enum_Tutorial_Step_Type_Enum } from 'common/schema/commonSchemaRemoteTypes';
import { TypeUtils } from 'common/utils/generalTypeUtils';
import orderBy from 'lodash/orderBy';
import { compareByArrayOrder } from 'utils/utilFunctions';
import { milestoneStatuses, milestoneUnlockStatuses } from './milestonesConstants';
import {
  Milestone,
  MilestoneFeature,
  MilestoneIndicatorType,
  MilestonesMap,
  MilestoneStatus,
  TooltipContext,
  TooltipEvent,
  TooltipLocalStorageProps,
  TooltipLocalStorageSetProps,
} from './milestonesTypes';
import { isMilestoneFeatureType } from './typeGuards';

export const getMilestoneStatus = (
  value: number | null,
  milestone?: Pick<Milestone, 'minValue' | 'status'>
): MilestoneStatus => {
  if (value === null) {
    return 'ONGOING';
  }
  const isCompleted = milestone && milestone.minValue <= value;
  if (milestone?.status && milestoneUnlockStatuses.includes(milestone.status)) {
    return milestone.status;
  }
  return isCompleted ? 'COMPLETED' : 'ONGOING';
};

/**
 * Finds the current locked milestone feature
 * @param milestones Current milestones map
 * @returns MilestoneFeature or null
 */
export const getCurrentLockedFeature = (milestones: MilestonesMap): MilestoneFeature | null => {
  const milestoneFeaturesOrderedByMinValue = [...orderBy(Object.values(milestones), ['minValue'])].map(
    (milestone) => milestone?.feature
  );

  return TypeUtils.Object.keys(milestones)
    .sort(compareByArrayOrder(milestoneFeaturesOrderedByMinValue))
    .reduce<MilestoneFeature | null>((currentLockedFeature, feature) => {
      const milestone = milestones[feature];
      if (!currentLockedFeature && milestone && milestone.status !== 'UNLOCKED') {
        return milestone.feature;
      }
      return currentLockedFeature;
    }, null);
};

export const getNextUnlockableFeature = (
  milestones: MilestonesMap,
  current?: MilestoneFeature | null
): MilestoneFeature | null => {
  const currentLockedFeature = current ?? getCurrentLockedFeature(milestones);
  if (!currentLockedFeature) {
    return null;
  }
  return milestones[currentLockedFeature]?.nextFeature ?? null;
};

// TODO: funciton no longer used, mark for delete
export const getMilestoneFeature = (step?: Enum_Tutorial_Step_Type_Enum | null): MilestoneFeature | null => {
  if (!step) {
    return null;
  }
  if (isMilestoneFeatureType(step)) {
    return step;
  }
  if (step === Enum_Tutorial_Step_Type_Enum.EditProfile) {
    return Enum_Tutorial_Step_Type_Enum.Appearance;
  }
  return null;
};

export const isUnlockedStatus = (status?: MilestoneStatus | null): boolean => {
  if (!status) {
    return false;
  }
  return milestoneUnlockStatuses.includes(status);
};

export const isMilestoneStatuses = (status?: MilestoneStatus | null): boolean => {
  if (!status) {
    return false;
  }
  return milestoneStatuses.includes(status);
};

const getTooltipLocalStorageKey = ({
  feature,
  context = TooltipContext.MILESTONE,
  event = TooltipEvent.DIMISSED,
  profileId,
  extra,
}: TooltipLocalStorageProps) => {
  let key = `${MilestoneIndicatorType.TOOLTIPS}.${context}.${feature}.${event}`;
  if (extra) {
    key += `.${extra}`;
  }
  return `${key}.${profileId}`;
};

const getGreenDotLocalStorageKey = ({
  feature,
  context = TooltipContext.MILESTONE,
  event = TooltipEvent.DIMISSED,
  profileId,
  extra,
}: TooltipLocalStorageProps) => {
  let key = `${MilestoneIndicatorType.GREEN_DOTS}.${context}.${feature}.${event}`;
  if (extra) {
    key += `.${extra}`;
  }
  return `${key}.${profileId}`;
};

export const getTooltipLocalStorageValue = (params: TooltipLocalStorageProps): string | null =>
  localStorage.getItem(getTooltipLocalStorageKey(params));

export const setTooltipLocalStorageValue = ({ value, ...params }: TooltipLocalStorageSetProps): void => {
  localStorage.setItem(getTooltipLocalStorageKey(params), value);
};

export const getGreenDotLocalStorageValue = (params: TooltipLocalStorageProps): string | null =>
  localStorage.getItem(getGreenDotLocalStorageKey(params));

export const setGreenDotLocalStorageValue = ({ value, ...params }: TooltipLocalStorageSetProps): void => {
  localStorage.setItem(getGreenDotLocalStorageKey(params), value);
};
