import { combineReducers } from 'redux';
import { listShopArtworksSlice } from './listShopArtworks.slice';
import { filterShopArtworksSlice } from './filterShopArtworks.slice';
import { displayShopArtworksSlice } from './displayShopArtworks.slice';

export const actions = {
  ...listShopArtworksSlice.actions,
  ...filterShopArtworksSlice.actions,
  ...displayShopArtworksSlice.actions,
};

export const shopArtworksSlice = {
  shopArtworks: combineReducers({
    [listShopArtworksSlice.name]: listShopArtworksSlice.reducer,
    [filterShopArtworksSlice.name]: filterShopArtworksSlice.reducer,
    [displayShopArtworksSlice.name]: displayShopArtworksSlice.reducer,
  }),
};
