import { CurrencyTypeEnum } from 'common/components/CurrencySelector/CurrencySelector.formik';
import { useGetCurrencyConversionQuery } from 'common/schema/commonSchemaRemoteGraphqlQueries';
import React, { useCallback, useContext } from 'react';
import { isSomeEnum } from 'utils/utilFunctions';

type System = 'imperial' | 'metric';

type UnitConversionContextType = {
  userSystem: System | null;
  setUserSystem: (userSystem: string) => void;
  transformUnits: (unit: Unit, value: number[]) => { unit: Unit; value: number[] };
  currencyConversionValues: Record<CurrencyTypeEnum, number>;
};

const getDefaultRates = (): Record<CurrencyTypeEnum, number> => ({
  // rough initial values
  $: 1,
  C$: 1.25,
  '€': 1.1,
  '£': 1.27,
  '₿': 0.000022,
  Ξ: 0.00066,
  lei: 4.1,
});

const DefaultUnitConversionContext: UnitConversionContextType = {
  userSystem: null,
  setUserSystem: () => null,
  transformUnits: (unit, value) => ({ unit, value }),
  currencyConversionValues: getDefaultRates(),
};

export type Unit = 'cm' | 'centimeter' | 'in' | 'inch' | 'px';
const allUnits: Unit[] = ['cm', 'centimeter', 'in', 'inch', 'px'];
export const isUnit = (unit?: unknown): unit is Unit => allUnits.includes(unit as Unit);
export const toMeasuringUnit = (unit?: unknown, fallbackUnit: Unit = 'px'): Unit =>
  isUnit(unit) ? unit : fallbackUnit;

const ImperialCountries = new Set(['US', 'LR', 'MM']);

const ConversionMap: Record<System, Record<Unit, Unit>> = {
  imperial: {
    in: 'in',
    inch: 'in',
    cm: 'in',
    centimeter: 'in',
    px: 'px',
  },
  metric: {
    in: 'cm',
    inch: 'cm',
    cm: 'cm',
    centimeter: 'cm',
    px: 'px',
  },
};

const unitMetric = new Set(['cm', 'centimeter']);
const unitImperial = new Set(['in', 'inch']);

export const UnitConversionContext = React.createContext<UnitConversionContextType>(DefaultUnitConversionContext);
export const useUnitConversionFunction = (): UnitConversionContextType['transformUnits'] =>
  useContext(UnitConversionContext).transformUnits;
export const viewerUnitConversion = {
  convert: (unit: Unit, value: number[]) => ({ value, unit }),
};

export const UnitConversionContextProvider: React.FC = ({ children }) => {
  const [userSystem, setUserSystem] = React.useState<System | null>(null);
  const [currencyConversionValues, setConversionValues] = React.useState(getDefaultRates());
  useGetCurrencyConversionQuery({
    onCompleted: (data) => {
      const dbRates = data?.Currency_conversion.reduce<Record<CurrencyTypeEnum, number>>((acc, curr) => {
        if (isSomeEnum(CurrencyTypeEnum)(curr.currency)) {
          acc[curr.currency] = curr.rate;
        }
        return acc;
      }, getDefaultRates());
      setConversionValues(dbRates);
    },
  });

  const setUserSystemCB = useCallback((country: string) => {
    if (ImperialCountries.has(country)) {
      setUserSystem('imperial');
    } else {
      setUserSystem('metric');
    }
  }, []);
  const transformUnits = useCallback(
    (unit: Unit, value: number[]) => {
      if (!userSystem) {
        return { value, unit };
      }
      if (!unit || !['cm', 'centimeter', 'in', 'inch', 'px'].includes(unit)) {
        return { value, unit };
      }
      const newUnit = ConversionMap[userSystem][unit];
      if ((unitMetric.has(unit) && unitMetric.has(newUnit)) || (unitImperial.has(unit) && unitImperial.has(newUnit))) {
        return { value, unit: newUnit };
      }
      if (newUnit === 'in') {
        return {
          value: value.map((v) => Number((v / 2.54).toFixed(1))),
          unit: newUnit,
        };
      }
      if (newUnit === 'cm') {
        return {
          value: value.map((v) => Number((v * 2.54).toFixed(1))),
          unit: newUnit,
        };
      }
      return { value, unit: newUnit };
    },
    [userSystem]
  );
  viewerUnitConversion.convert = transformUnits;

  return (
    <UnitConversionContext.Provider
      value={{ userSystem, setUserSystem: setUserSystemCB, transformUnits, currencyConversionValues }}
    >
      {children}
    </UnitConversionContext.Provider>
  );
};
