import { createSlice, PayloadAction, current } from '@reduxjs/toolkit';
import { UserProfile } from 'utils/utilFunctions';
import {
  Enum_Context_State_Enum,
  Enum_Context_Type_Enum,
  Enum_Profile_Public_Status_Enum,
  Profile_Location,
  Profile_Settings,
} from 'common/schema/commonSchemaRemoteTypes';
import Sentry from 'common/features/Sentry/Sentry';

export type FullProfileType = {
  type: Enum_Context_Type_Enum;
  contextId: number;
  state: Enum_Context_State_Enum;
  profileId: number;
  firstName: string;
  lastName: string;
  handle: string;
  title: string;
  displayType: Enum_Profile_Public_Status_Enum;
  profilePhoto: string;
  locations: Partial<Profile_Location>[];
  settings: Partial<Profile_Settings>;
  statement: string;
  quote: string;
  socialLinks: { [key: string]: string };
};
export type ProfileSubType = 'SIMPLE_USER' | 'PSEUDO_COLLECTOR' | '';

const initialState = {
  profileProviderFinished: false,
  isDialogProfileOpen: false,
  isCheckingAccount: true,
  roles: [] as Enum_Context_Type_Enum[],
  profiles: [] as any[],
  profilePhotoLink: null as string | null,
  selectedProfileId: parseInt(localStorage.getItem('selectedProfileId') ?? '0', 10),
  selectedContextId: 0,
  status: null as null | string,
  isAdmin: false,
  inquiryDate: null as null | string,
  incompleteProfiles: [] as UserProfile[],
  shouldVerifyAccount: false, // using this just to make the difference between unverified account and incomplete onboarding
  preferredLanguage: '',
  preferredCurrency: '',
  cropImage: null as null | boolean,
  profileSubType: '' as ProfileSubType,
  isProfileRecentlyCreated: false,
};

const typeSort = {
  ARTIST: 0,
  GALLERY: 1,
  COLLECTOR: 2,
};

const accountSlice = createSlice({
  initialState,
  name: 'account',
  reducers: {
    // TODO this is a temporary solution to fix the onobardingStatus, this should be removed with ProfileProvider refactor
    SET_PROFILE_PROVIDER_FINISHED: (state) => {
      state.profileProviderFinished = true;
    },
    SET_IS_CHECKING_ACCOUNT: (state, action) => {
      state.isCheckingAccount = action.payload;
    },
    SET_PROFILE_DATA: (state, action) => {
      const profileIndex = state.profiles.findIndex((x) => x.profileId === action.payload.profileId);
      if (profileIndex !== -1) {
        state.profiles[profileIndex] = {
          ...state.profiles[profileIndex],
          ...action.payload,
          profileId: parseInt(state.profiles[profileIndex].profileId, 10),
        };
      } else {
        state.profiles.push({
          ...action.payload,
          profileId: parseInt(action.payload.profileId, 10),
        });
      }
      state.isCheckingAccount = false;
    },
    SET_PROFILE_DATA_BY_CONTEXT_ID: (state, action) => {
      const profileIndex = state.profiles.findIndex((x) => x.contextId === action.payload.contextId);
      if (profileIndex !== -1) {
        state.profiles[profileIndex] = {
          ...state.profiles[profileIndex],
          ...action.payload,
          contextId: parseInt(state.profiles[profileIndex].contextId, 10),
        };
      } else {
        state.profiles.push({
          ...action.payload,
          contextId: parseInt(action.payload.contextId, 10),
        });
      }
      state.isCheckingAccount = false;
    },
    SET_PROFILE_STATUS: (state, action) => {
      state.status = action.payload;
    },
    SET_PROFILES: (state, action) => {
      // todo : fix typing
      state.profiles = action.payload.sort(
        (a: { type: keyof typeof typeSort }, b: { type: keyof typeof typeSort }) => typeSort[a.type] - typeSort[b.type]
      );
      const selectedProfile =
        state.profiles.find((v) => v.contextId === state.selectedContextId) ||
        state.incompleteProfiles.find((v) => v.contextId === state.selectedContextId);
      state.isCheckingAccount = false;
      state.selectedProfileId = selectedProfile?.profileId;
      if (selectedProfile?.contextId && selectedProfile?.profileId) {
        Sentry.setUser(
          {
            id: String(selectedProfile.contextId),
            username: String(selectedProfile.profileId),
          },
          selectedProfile.type
        );
      }
    },
    SET_SELECTED_PROFILE: (state, action) => {
      const id = parseInt(action.payload, 10);
      state.selectedContextId = id;
      const selectedProfile =
        state.profiles.find((v) => v.contextId === state.selectedContextId) ||
        state.incompleteProfiles.find((v) => v.contextId === state.selectedContextId);
      state.selectedProfileId = selectedProfile?.profileId;
      if (selectedProfile?.contextId && selectedProfile?.profileId) {
        Sentry.setUser(
          {
            id: String(selectedProfile.contextId),
            username: String(selectedProfile.profileId),
          },
          selectedProfile.type
        );
      }
    },
    SET_PROFILE_PHOTO_LINK: (state, action) => {
      state.profilePhotoLink = action.payload;
    },
    LOG_OUT: () => initialState,
    SET_IS_ADMIN: (state, action) => {
      state.isAdmin = action.payload;
    },
    SET_PREFERRED_LANGUAGE: (state, action) => {
      state.preferredLanguage = action.payload;
    },
    SET_PREFERRED_CURRENCY: (state, action) => {
      const array = current(state.profiles);
      const profileIdx = array.findIndex((v) => v.contextId === state.selectedContextId);
      if (typeof profileIdx === 'number' && profileIdx > -1) {
        state.profiles[profileIdx].settings.currency = action.payload;
      }
      state.preferredCurrency = action.payload;
    },
    SET_CROP_IMAGE: (state, action) => {
      const array = current(state.profiles);
      const profileIdx = array.findIndex((v) => v.contextId === state.selectedContextId);
      if (typeof profileIdx === 'number' && profileIdx > -1) {
        state.profiles[profileIdx].settings.crop_image = action.payload;
      }
    },
    SET_INQUIRY_DATE: (state, action) => {
      state.inquiryDate = action.payload;
    },
    SET_INCOMPLETE_PROFILES: (state, action) => {
      state.incompleteProfiles = action.payload;
      state.selectedProfileId =
        state.profiles.find((v) => v.contextId === state.selectedContextId)?.profileId ||
        state.incompleteProfiles.find((v) => v.contextId === state.selectedContextId)?.profileId ||
        0;
    },
    SET_INCOMPLETE_PROFILE_DATA: (state, action) => {
      const profileIndex = state.incompleteProfiles.findIndex((x) => x.profileId === action.payload.profileId);
      if (profileIndex !== -1) {
        state.incompleteProfiles[profileIndex] = {
          ...state.incompleteProfiles[profileIndex],
          ...action.payload,
        };
      } else {
        state.incompleteProfiles.push({
          ...action.payload,
          profileId: parseInt(action.payload.profileId, 10),
        });
      }
    },
    SET_SHOULD_VERIFY_ACOUNT: (state, action) => {
      state.shouldVerifyAccount = action.payload;
    },
    SET_SUB_PROFILE_TYPE: (state, action) => {
      state.profileSubType = action.payload;
    },
    SET_USER_ROLES: (state, action: PayloadAction<Enum_Context_Type_Enum[]>) => {
      state.roles = action.payload;
    },
    SET_IS_DIALOG_PROFILE_OPEN: (state, action: PayloadAction<boolean>) => {
      state.isDialogProfileOpen = action.payload;
    },
    SET_PROFILE_RECENTLY_CREATED: (state, action: PayloadAction<boolean>) => {
      state.isProfileRecentlyCreated = action.payload;
    },
  },
});

export const accountActions = accountSlice.actions;
export const accountReducer = accountSlice.reducer;
