import { CUSTOM_DOMAIN_ROUTES, ROUTES } from 'routes/_definition';
import { IS_CUSTOM_DOMAINS_APP } from 'utils/constants';
import createPath from './createPath';

const createShowcasePath = (currentPath: string, artworkSlug: string, artistHandle?: string): string => {
  if (IS_CUSTOM_DOMAINS_APP) {
    return createPath(CUSTOM_DOMAIN_ROUTES.SHOWCASE_ARTWORK_SLUG, {
      artworkSlug: artworkSlug ?? '',
    });
  }

  // TODO: remove this
  if (currentPath.includes('/my-website/')) {
    return createPath(ROUTES.MY_WEBSITE_ARTIST_HANDLE_SHOWCASE_ARTWORK_SLUG, {
      artistHandle: artistHandle ?? '',
      artworkSlug: artworkSlug ?? '',
    });
  }

  return createPath(ROUTES.ARTIST_HANDLE_SHOWCASE_ARTWORK_SLUG, {
    artistHandle: artistHandle ?? '',
    artworkSlug: artworkSlug ?? '',
  });
};

export default createShowcasePath;
