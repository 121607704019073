import MultilineInput from 'common/components/MultilineInput/MultilineInput';
import Section from 'common/components/Section/Section';
import { ArtworkStatementConfigValues } from 'common/features/FormikConfigs/components/Artwork';
import { UseFormikSection } from 'common/features/FormikConfigs/utils/formikTypeUtils';
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { CDNUrl } from 'utils/constants';
import { IconButton, makeStyles } from '@material-ui/core';
import { MOBILE_BREAKPOINT } from 'common/utils/constants';
import { useIsMobileScreen } from '../../../../../../../../common/hooks/utils/useIsMobileScreen';

const customStyle = makeStyles((theme) => ({
  container: {
    backgroundColor: 'white',
    [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
      '& textarea': {
        fontSize: '16px',
      },
    },
  },
  section: {
    width: '100%',
  },
  banner: {
    position: 'relative',
    background: '#E4F3FC',
    padding: '16px',
    borderRadius: '12px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '12px',
    marginTop: '-12px',
    marginBottom: '24px',
    [theme.breakpoints.down('xs')]: {
      borderRadius: '4px',
      marginTop: '-24px',
      gap: '16px',
    },
  },
}));

export const ARTWORK_STATEMENT_LENGTH = 5000;

export const ArtworkStatementSection = <T extends ArtworkStatementConfigValues = ArtworkStatementConfigValues>({
  formik,
  title,
  description,
  isAIGenerated,
}: UseFormikSection<T> & { title?: string; description?: string; isAIGenerated?: boolean }): ReturnType<
  React.FC<UseFormikSection<T> & { title: string; description: string }>
> => {
  const { t } = useTranslation();
  const customClasses = customStyle();
  const isMobile = useIsMobileScreen();
  const [isBannerOpen, setIsBannerOpen] = useState(true);

  return (
    <Section
      title={title !== undefined ? title : t('addArtworkStrings.storytelling.statement')}
      description={
        description && description?.length > 0 ? description : t('addArtworkStrings.storytelling.statementDescription')
      }
      className={customClasses.section}
      style={isMobile ? {} : { marginTop: '40px' }}
    >
      {isBannerOpen && isAIGenerated && (
        <div className={customClasses.banner}>
          <img src={`${CDNUrl}/images/dashboardIcons/InfoIconBlack.svg`} alt="info" height="20px" />

          {t('addArtworkStrings.storytelling.StatementDeleteBanner')}

          {!isMobile && (
            <IconButton
              onClick={() => setIsBannerOpen(false)}
              style={{
                position: 'absolute',
                right: '8px',
                padding: '4px',
              }}
            >
              <img src={`${CDNUrl}/images/CloseIcon.svg`} alt="close-button" height="20px" width="20px" />
            </IconButton>
          )}
        </div>
      )}
      <MultilineInput
        name="statement"
        variant="outlined"
        className={customClasses.container}
        value={formik.values.statement}
        maxLength={ARTWORK_STATEMENT_LENGTH}
        maxRows={isMobile ? 20 : 5}
        minRows={isMobile ? 20 : undefined}
        onChange={formik.handleChange}
        placeholder={t('addArtworkStrings.storytelling.statementPlaceholder')}
        error={!!formik.errors.statement}
        helperText={formik.errors.statement}
      />
    </Section>
  );
};
