import { Enum_Art_Type_Enum } from 'common/schema/commonSchemaRemoteTypes';
import { t } from 'i18next';
import { isSomeEnum } from 'utils/utilFunctions';

export const artworkTypeOptions = (translate: typeof t): string[] => [
  translate('addArtworkStrings.artDetails.button.physical'),
  // 'addArtworkStrings.artDetails.button.digital',
];

export const artworkTypeNameToEnum = (possibleArtworkType: unknown): Enum_Art_Type_Enum | undefined => {
  if (possibleArtworkType && isSomeEnum(Enum_Art_Type_Enum)(possibleArtworkType)) {
    return possibleArtworkType;
  }
  return undefined;
};
