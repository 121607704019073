import { Scalars } from 'common/schema/commonSchemaRemoteTypes';
import { NearbyArtistsSort } from './filter.types';

export type NearbyArtistsFiltersHash = `${NearbyArtistsSort}_${string}`;

export const listingDirections = ['BACKWARD', 'FORWARD'] as const;
export type ListDirection = (typeof listingDirections)[0] | (typeof listingDirections)[1];

export const loadingIdentifier = 'LOADING' as const;
export type ListLoadingId = typeof loadingIdentifier;

export type ListLoaderStatus = 'COLD' | 'FINISHED' | 'IDLE' | `${ListLoadingId}_${ListDirection}`;

export type ListWindowLoaderStateType<ValuesType, StatusType> = {
  batchSize: number;
  values: Array<ValuesType>;
  status: StatusType;
  hasMore: [boolean, boolean]; // [backward, forward]
};

export type ListLoaderStateType<Typename extends NearbyArtistsSort, CursorType, IdType = Scalars['bigint']> = {
  __typename: Typename;
  listed: ListWindowLoaderStateType<CursorType, ListLoaderStatus>;
  detailed: ListWindowLoaderStateType<IdType, ListLoaderStatus>;
  cursor: [CursorType, CursorType]; // [backward, forward]
  detailedToListedStartOffset: number;
  lastScrollPosition: number;
};

export type NearbyArtworksListByDateCursor = {
  contextId: Scalars['bigint'];
  appearedAt: Scalars['date'];
};
export type NearbyArtworksListByDate = ListLoaderStateType<'byDate', NearbyArtworksListByDateCursor>;

export type NearbyArtworksListByTitleCursor = {
  contextId: Scalars['bigint'];
  title: Scalars['String'];
};
export type NearbyArtworksListByTitle = ListLoaderStateType<'byTitle', NearbyArtworksListByTitleCursor>;

export type ListActionArgs =
  | [ListDirection, NearbyArtworksListByDate['__typename'], NearbyArtworksListByDate['listed']['values']]
  | [ListDirection, NearbyArtworksListByTitle['__typename'], NearbyArtworksListByTitle['listed']['values']];

export type DetailActionArgs =
  | [ListDirection, NearbyArtworksListByDate['__typename'], NearbyArtworksListByDate['detailed']['values']]
  | [ListDirection, NearbyArtworksListByTitle['__typename'], NearbyArtworksListByTitle['detailed']['values']];

export type NearbyArtistsListState = {
  identifier: NearbyArtistsFiltersHash;
  type: NearbyArtistsSort;
} & (
  | {
      type: 'byDate';
      data: NearbyArtworksListByDate;
    }
  | {
      type: 'byTitle';
      data: NearbyArtworksListByTitle;
    }
);

export type NearbyArtistsListingCursor = NearbyArtworksListByDateCursor | NearbyArtworksListByTitleCursor;

export const isNearbyArtworksListByDateCursor = (
  cursor: NearbyArtistsListingCursor
): cursor is NearbyArtworksListByDateCursor => (cursor as NearbyArtworksListByDateCursor)?.appearedAt !== undefined;

export const isNearbyArtworksListByTitleCursor = (
  cursor: NearbyArtistsListingCursor
): cursor is NearbyArtworksListByTitleCursor => (cursor as NearbyArtworksListByTitleCursor)?.title !== undefined;

export const LOADING_STATES: ListLoaderStatus[] = ['LOADING_BACKWARD', 'LOADING_FORWARD'];
