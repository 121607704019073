import { Scalars } from 'common/schema/commonSchemaRemoteTypes';
import { RecognitionsSort } from './filter.types';

export type RecognitionsFiltersHash = `${RecognitionsSort}_${string}`;

export const listingDirections = ['BACKWARD', 'FORWARD'] as const;
export type ListDirection = (typeof listingDirections)[0] | (typeof listingDirections)[1];

export const loadingIdentifier = 'LOADING' as const;
export type ListLoadingId = typeof loadingIdentifier;

export type ListLoaderStatus = 'COLD' | 'FINISHED' | 'IDLE' | `${ListLoadingId}_${ListDirection}`;

export type ListWindowLoaderStateType<ValuesType, StatusType> = {
  batchSize: number;
  values: Array<ValuesType>;
  status: StatusType;
  hasMore: [boolean, boolean]; // [backward, forward]
};

export type ListLoaderStateType<Typename extends RecognitionsSort, CursorType, IdType = Scalars['bigint']> = {
  __typename: Typename;
  listed: ListWindowLoaderStateType<CursorType, ListLoaderStatus>;
  detailed: ListWindowLoaderStateType<IdType, ListLoaderStatus>;
  cursor: [CursorType, CursorType]; // [backward, forward]
  detailedToListedStartOffset: number;
  lastScrollPosition: number;
};

export type RecognitionsListByDateCursor = {
  contextId: Scalars['bigint'];
  appearedAt: Scalars['date'];
};
export type RecognitionsListByDate = ListLoaderStateType<'byDate', RecognitionsListByDateCursor>;

export type ListActionArgs = [
  ListDirection,
  RecognitionsListByDate['__typename'],
  RecognitionsListByDate['listed']['values']
];

export type DetailActionArgs = [
  ListDirection,
  RecognitionsListByDate['__typename'],
  RecognitionsListByDate['detailed']['values']
];

export type RecognitionsListState = {
  identifier: RecognitionsFiltersHash;
  type: RecognitionsSort;
} & {
  type: 'byDate';
  data: RecognitionsListByDate;
};

export type RecognitionsListingCursor = RecognitionsListByDateCursor;

export const isRecognitionsListByDateCursor = (
  cursor: RecognitionsListingCursor
): cursor is RecognitionsListByDateCursor => (cursor as RecognitionsListByDateCursor)?.appearedAt !== undefined;

export const LOADING_STATES: ListLoaderStatus[] = ['LOADING_BACKWARD', 'LOADING_FORWARD'];
