import { useGetCustomDomainInfoQuery } from 'common/schema/commonSchemaRemoteGraphqlQueries';
import { Enum_Connected_Domain_State_Enum, Enum_Language_Preference_Enum } from 'common/schema/commonSchemaRemoteTypes';
import { useMemo } from 'react';
import { ConnectedDomainInfo, DomainState } from 'store/slices/domainSlice';
import { isSomeEnum } from 'utils/utilFunctions';

export const isConnectedDomainInfo = (value: unknown): value is ConnectedDomainInfo => {
  if (!value) {
    return false;
  }
  if (typeof value !== 'object') {
    return false;
  }
  if (typeof (value as ConnectedDomainInfo).host !== 'string') {
    return false;
  }
  if (typeof (value as ConnectedDomainInfo).profileHandle !== 'string') {
    return false;
  }
  if (!isSomeEnum(Enum_Connected_Domain_State_Enum)((value as ConnectedDomainInfo).status)) {
    return false;
  }
  return true;
};

export const getConnectedDomainFromURL = (url?: URL): ConnectedDomainInfo | null => {
  const finalUrl = url ?? new URL(window.location.href);
  const customDomainParam = finalUrl.searchParams.get('connected-domain');
  const customState: ConnectedDomainInfo | null = customDomainParam
    ? {
        host: customDomainParam,
        profileHandle: '',
        status: Enum_Connected_Domain_State_Enum.Pending,
        preferredLanguage: Enum_Language_Preference_Enum.English,
      }
    : null;
  const kdomain = window?.kdomain ? (JSON.parse(window.kdomain) as ConnectedDomainInfo | null) : null;
  const kDomainState: ConnectedDomainInfo | null = isConnectedDomainInfo(kdomain) ? kdomain : null;
  return customState ?? kDomainState ?? null;
};

const useConnectedDomain = (url: URL): DomainState['domain'] => {
  const { host: customDomain } = getConnectedDomainFromURL(url) ?? {};
  if (!customDomain) {
    return null;
  }

  const {
    data,
    error,
    loading: isLoading,
  } = useGetCustomDomainInfoQuery({
    variables: { domainName: customDomain },
  });

  if (error) {
    console.error('Error in useCustomDomain:', error);
    return null;
  }

  const connectedDomain = data?.Connected_domain?.[0];

  return useMemo(
    () => ({
      isLoading,
      host: connectedDomain?.domain_name ?? '',
      profileHandle: connectedDomain?.context?.profile?.handle ?? '',
      status: connectedDomain?.state ?? Enum_Connected_Domain_State_Enum.Disabled,
      preferredLanguage: connectedDomain?.context?.profile?.preferred_language ?? Enum_Language_Preference_Enum.English,
    }),
    [connectedDomain, isLoading]
  );
};

export default useConnectedDomain;
