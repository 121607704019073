import { GetPrintSizesByArtworkIdQuery } from 'common/schema/commonSchemaRemoteOperationTypes';
import { Maybe } from 'common/schema/commonSchemaRemoteTypes';
import { TFunction } from 'i18next';
import _ from 'lodash';
import { formatDimensions } from 'stacks/Dashboard/common/ArtworkPublish/components/PublishArtwork/utils/publishArtworkUtils';

// todo : fix this stupid workaround
const toStartCase = (enumValue: string) => {
  const str = enumValue.trim();
  return _.startCase(_.lowerCase(str.startsWith('D_') ? `3${str}` : str));
};
export const getDisciplineValue = (discipline: string, t: TFunction) => {
  if (!discipline) {
    return t('addArtworkStrings.artDetails.select.discipline.undefined');
  }
  if (discipline === 'OTHER_DIGITAL') {
    return t('addArtworkStrings.artDetails.select.discipline.OtherDigital');
  }
  if (discipline === 'OTHER_STREET_ART') {
    return t('addArtworkStrings.artDetails.select.discipline.OtherStreet');
  }
  return t(`addArtworkStrings.artDetails.select.discipline.${toStartCase(discipline ?? '')}`);
};
export const getArtworkCardDetailsString = (
  t: TFunction,
  discipline?: Maybe<string>,
  width?: Maybe<number>,
  height?: Maybe<number>,
  measuringUnit?: Maybe<string>
): string =>
  [
    getDisciplineValue(discipline ?? '', t),
    width && height
      ? formatDimensions({
          values: [width ?? 0, height ?? 0],
          unit: measuringUnit ?? '',
        })
      : t('addArtworkStrings.artDetails.select.discipline.undefined'),
  ].join('  |  ');

export const getArtistProfileArtworkCardDetailsString = (
  t: TFunction,
  price?: Maybe<string>,
  width?: Maybe<number>,
  height?: Maybe<number>,
  measuringUnit?: Maybe<string>,
  isCollected?: Maybe<boolean>
): string => {
  const dimensions =
    width && height
      ? formatDimensions({
          values: [width ?? 0, height ?? 0],
          unit: measuringUnit ?? '',
        })
      : t('addArtworkStrings.artDetails.select.discipline.undefined');

  if (!price) {
    return dimensions;
  }
  if (isCollected) {
    return dimensions;
  }
  return [price, dimensions].join('  |  ');
};

export const getArtistProfileAvailabilityOrPriceText = <
  T extends { isCollected?: Maybe<boolean>; isAvailable?: Maybe<boolean> }
>(
  availabilityBools: T,
  formattedPrice: string | null,
  t: TFunction
): string => {
  const { isCollected, isAvailable } = availabilityBools;

  if (isCollected) {
    return t('filterStrings.avalability.collected');
  }

  if (!isAvailable) {
    return t('filterStrings.avalability.unavailable');
  }

  return formattedPrice ?? t('filterStrings.avalability.available');
};

const sizeToValue = {
  XS: 1,
  S: 2,
  M: 3,
  L: 4,
  XL: 5,
  XXL: 6,
};
export const getPrintCardDetailsString = (
  t: TFunction,
  sizes?: GetPrintSizesByArtworkIdQuery['editions'][0]['edition'][0]['sizes']
): string =>
  sizes
    ? [
        t('publish.prints.PrintEdition.title'),
        sizes
          .map((size) => size.print_size)
          .sort((a, b) => sizeToValue[a] - sizeToValue[b])
          .join('  |  '),
      ].join('  |  ')
    : [
        t('addArtworkStrings.artDetails.select.discipline.undefined'),
        t('addArtworkStrings.artDetails.select.discipline.undefined'),
      ].join('  |  ');

const cardStringGetters = {
  getArtworkCardDetailsString,
  getPrintCardDetailsString,
};
export default cardStringGetters;
