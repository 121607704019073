/* eslint-disable react-hooks/exhaustive-deps */
/// ------------THIS NEEDS A REFACTOR-----------
import { useState, useEffect, useCallback, useMemo } from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';
import { GET_ARTIST_ART } from 'common/graphql/manageArtQueries';
import { GET_ALL_SERIES } from 'common/graphql/addArtworkQueries';
import { useSelector, useDispatch } from 'react-redux';
import { filterAction } from 'store/slices/filter';
import { capitalizeFirstLetter } from 'utils/utilFunctions';
import { useProfileArtworksListingQuery } from 'common/schema/commonSchemaRemoteGraphqlQueries';
import { Artwork_To_Collection } from 'common/schema/commonSchemaRemoteTypes';
import { getCollectionTypeForProfileType } from 'stacks/Dashboard/common/ProfileAppearance/ArrangeArtworks/utils/arrangeArtworksUtils';
import {
  availabilityOptions,
  defaultYears,
  defaultSeries,
  FilterOption,
  orientationOptions,
  orderOptions,
  defaultOrientation,
} from './utils/filterUtils';
import { ProfileType } from './useSelectedProfile';

/** ************* Helper Functions *********************** */

export const buildYearsList = (artworks: any) => {
  const years: number[] = [];

  if (artworks && artworks.Artwork.length) {
    artworks.Artwork.map((art: any) => art?.artwork_details).forEach((artDetails: any) => {
      if (artDetails && artDetails.creation_date_year) {
        if (!years.includes(artDetails.creation_date_year)) {
          years.push(artDetails.creation_date_year);
        }
      }
    });
  }

  return Array.from(new Set(years));
};

/** ******************************************************** */

export const DEFAULT_ARTWORK_ORDER = 999999999;
type ArtworkWithOrder = { artwork_to_collections?: Pick<Artwork_To_Collection, 'order'>[] };

export const sortArtworksByOrder = (a: ArtworkWithOrder, b: ArtworkWithOrder): number => {
  let orderA = DEFAULT_ARTWORK_ORDER;
  let orderB = DEFAULT_ARTWORK_ORDER;

  if (a.artwork_to_collections?.length) {
    orderA = a.artwork_to_collections[0].order;
  }
  if (b.artwork_to_collections?.length) {
    orderB = b.artwork_to_collections[0].order;
  }

  return orderA - orderB;
};

/** ******************************************************** */

/* Deprecated. Might throw errors at run time due to possible null in query expression */
export function useStartFilters(artistData: any, availability?: string, collectionType?: ProfileType) {
  // console.log('start Filters ', availability);

  const dispatch = useDispatch();
  const currentArtistData = useSelector((store: any) => store.filter.artistData);
  const availableFilter = useSelector((store: any) => store.filter.filterAvailability);
  const orderFilter = useSelector((store: any) => store.filter.orderBy);
  const seriesFilter = useSelector((store: any) => store.filter.filterSeries);
  const seriesIds = seriesFilter.map((s: any) => s.id);
  const yearsFilter = useSelector((store: any) => store.filter.filterYears);

  const {
    data: artworksData,
    loading: artworksLoading,
    error: artworksError,
  } = useProfileArtworksListingQuery({
    fetchPolicy: 'network-only',
    skip: !artistData?.Profile?.[0]?.id,
    variables: {
      id: artistData?.Profile?.[0]?.id,
      availability: availableFilter.query, // {_eq: false}
      orderBy: orderFilter.query, // {artwork_details: {creation_date_year: asc} }
      series: seriesIds.includes(0) ? {} : { artwork_to_collections: { collection_id: { _in: seriesIds } } }, // artwork_to_collections: {collection_id: {_in:[344, 345]}} //{collection_id: {_in: seriesArr}}
      years: yearsFilter.includes(defaultYears)
        ? {}
        : { artwork_details: { creation_date_year: { _in: yearsFilter } } },
      collectionType: collectionType && getCollectionTypeForProfileType(collectionType),
    },
  });

  useEffect(() => {
    // If the artist is a new one, reset the filters and set the new artist data
    if (artistData && currentArtistData && artistData?.Profile[0]?.id !== currentArtistData?.Profile[0]?.id) {
      dispatch(filterAction.RESET_FILTERS());
    }
    if (artistData) {
      dispatch(filterAction.SET_ARTIST_DATA(artistData));
    }
  }, [artistData, currentArtistData]);

  useEffect(() => {
    if (availability === 'available' || availability === 'sold') {
      const capAvailability = capitalizeFirstLetter(availability);
      // availability = capitalizeFirstLetter(availability);

      const opts = availabilityOptions.filter((opt) => opt.desc === capAvailability);
      dispatch(filterAction.SET_AVAILABILITY_FILTER(opts[0]));
      // console.log('url option ', opts[0]);
    }
  }, [availability]);

  const artworks = artworksData?.Artwork;

  const { data: seriesData } = useQuery(GET_ALL_SERIES, {
    fetchPolicy: 'network-only',
    skip: !artistData,
    variables: {
      owner_id: artistData && artistData.Profile[0]?.context.id,
    },
  });

  const [getDataForYears] = useLazyQuery(GET_ARTIST_ART, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      // console.log('loading years');

      const years: number[] = buildYearsList(data);

      dispatch(filterAction.SET_YEARS(years ? [defaultYears, ...years] : [defaultYears]));
    },
  });

  /** ******* Load Years only once ********************* */

  useEffect(() => {
    if (artistData && artistData.Profile[0]?.id) {
      // pass variables to function and not to the hook to stop it from refetching
      getDataForYears({
        variables: {
          id: artistData && artistData.Profile[0]?.id,
        },
      });
      // console.log('calling years query');
    }
  }, [artistData]);

  useEffect(() => {
    dispatch(
      filterAction.SET_SERIES(
        seriesData
          ? [
              defaultSeries,
              ...seriesData?.Collection.map((x: any) => ({
                id: x.id,
                name: x.name,
              })),
            ]
          : [defaultSeries]
      )
    );
  }, [seriesData?.Collection]);

  return { artworks, artworksLoading, artworksError };
}
export function useFilter() {
  const dispatch = useDispatch();
  const artistsArray = useSelector((store: any) => store.filter.artists);
  const artistsFilter = useSelector((store: any) => store.filter.filterArtists);
  const orderFilter = useSelector((store: any) => store.filter.orderBy);
  const seriesFilter = useSelector((store: any) => store.filter.filterSeries);
  const yearsFilter = useSelector((store: any) => store.filter.filterYears);
  const orientationFilter = useSelector((store: any) => store.filter.orientationFilters);
  const initialized = useSelector((store: any) => store.filter.initialized);

  // const [available, setAvailable] = useState(availableFilter);
  const [orderBy, setOrderBy] = useState(orderFilter);
  const [series, setSeries] = useState(seriesFilter);
  const [years, setYears] = useState<(string | number)[]>(yearsFilter);
  const [orientation, setOrientation] = useState(orientationFilter);
  // console.log('defualt series', series);
  // console.log('useFilter available Filter ', availableFilter, available);

  useEffect(() => {
    if (orderBy !== orderFilter) {
      setOrderBy(orderFilter);
    }
  }, [orderFilter]);

  useEffect(() => {
    if (years !== yearsFilter) {
      setYears(yearsFilter);
    }
  }, [yearsFilter]);

  useEffect(() => {
    if (series[0] !== seriesFilter[0]) {
      setSeries(seriesFilter);
    }
  }, [seriesFilter]);

  useEffect(() => {
    if (orientation[0] !== orientationFilter[0]) {
      setOrientation(orientationFilter);
    }
  }, [orientationFilter]);
  /** **************direct redux********************************** */
  const available = useSelector((store: any) => store.filter.filterAvailability);
  function setAvailable(avalableOpt: FilterOption) {
    dispatch(filterAction.SET_AVAILABILITY_FILTER(avalableOpt));
  }

  /** ************** When filters change ************************ */
  useEffect(() => {
    dispatch(filterAction.SET_ORIENTATION_FILTER(orientation));
  }, [orientation]);

  useEffect(() => {
    // console.log('order by', orderBy);
    dispatch(filterAction.SET_ORDER_FILTER(orderBy));
  }, [orderBy]);

  // console.log('dasdadsad ', orderBy);

  useEffect(() => {
    // console.log('Filter by series', series);
    dispatch(filterAction.SET_SERIES_FILTER(series));
  }, [series]);

  useEffect(() => {
    // console.log('Filter by years', years);
    dispatch(filterAction.SET_YEARS_FILTER(years));
  }, [years]);

  /** ******************Change handlers******************************** */

  const handleSelectYear = (year: number | 'All Years') => {
    if (year === defaultYears) {
      setYears([defaultYears]);
      return;
    }
    if (years.indexOf(year) > -1) {
      if (years.length === 1) {
        setYears([defaultYears]);
        return;
      }
      setYears((prev: any) => prev.filter((val: any) => val !== year));
    } else {
      setYears((prev: any) => [...prev.filter((val: any) => val !== defaultYears), year]);
    }
  };

  const handleSelectSeries = (newSeries: { id: number; name: string }) => {
    if (newSeries.id === 0) {
      setSeries([newSeries]);
      return;
    }
    if (series.some((s: any) => s.id === newSeries.id)) {
      if (series.length === 1) {
        setSeries([defaultSeries]);
        return;
      }
      setSeries((prev: any) => prev.filter((val: any) => val.id !== newSeries.id));
    } else {
      setSeries((prev: any) => [...prev.filter((val: any) => val.id !== 0), newSeries]);
    }
  };

  const handleChangeOrientation = (newOrientation: any) => {
    if (newOrientation.id === 0 && !orientation.some((x: any) => x.id === 0)) {
      setOrientation([newOrientation]);
      return;
    }
    if (orientation.some((x: any) => x.id === newOrientation.id)) {
      if (orientation.length > 1) {
        setOrientation(orientation.filter((x: any) => x.id !== newOrientation.id));
        return;
      }
      setOrientation([defaultOrientation]);
      return;
    }
    if (!orientation.some((x: any) => x.id === newOrientation.id)) {
      setOrientation([...orientation.filter((x: any) => x.id !== 0), newOrientation]);
    }
  };

  const handleSetArtists = (artist: any) => {
    const id = artist?.id;
    if (id === undefined || id === null) return;
    if (id === 0) {
      if (!artistsFilter.includes(id)) {
        dispatch(filterAction.SET_FILTER_ARTISTS([...artistsArray.map((el: any) => el.id), id]));
      } else {
        dispatch(filterAction.SET_FILTER_ARTISTS([]));
      }
    } else if (initialized && id !== 0) {
      dispatch(filterAction.SET_INITIALIZED(false));
      dispatch(filterAction.SET_FILTER_ARTISTS([id]));
    } else if (artistsFilter.includes(id)) {
      dispatch(filterAction.SET_FILTER_ARTISTS(artistsFilter.filter((x: number) => x !== id)));
    } else {
      dispatch(filterAction.SET_FILTER_ARTISTS([...artistsFilter, id]));
    }
  };

  return [
    available,
    setAvailable,
    orderBy,
    setOrderBy,
    series,
    handleSelectSeries,
    years,
    handleSelectYear,
    orientation,
    handleChangeOrientation,
    handleSetArtists,
    artistsArray,
  ];
}

/** ************* Filter Options *********************** */

export function useFilterOptions(): [FilterOption[], FilterOption[], any[], any[], FilterOption[], any[]] {
  const seriesOptions = useSelector((store: any) => store.filter.series);
  const yearsOptions = useSelector((store: any) => store.filter.years);
  const artistsFilter = useSelector((store: any) => store.filter.filterArtists);
  return [availabilityOptions, orderOptions, seriesOptions, yearsOptions, orientationOptions, artistsFilter];
}

export const useArtworkOrderFnc = () => {
  const sortFnc = useSelector((store: any) => store.filter.orderBy.sortFnc);

  const orderFnc = useCallback(
    (artworks: any[]) => {
      if (!sortFnc) {
        return artworks;
      }

      return sortFnc(artworks);
    },
    [sortFnc]
  );
  return { orderFnc };
};

// TODO: type this and see how it works after finish dark mode
export const useArtworkFilterFnc = () => {
  const orientationFilters = useSelector((store: any) => store.filter.orientationFilters);

  const filterFnc = useCallback(
    (x: any) =>
      orientationFilters
        .map((artwork: any) => artwork.filterFnc)
        .reduce((prev: boolean, next: any) => prev || next(x), false),
    [orientationFilters]
  );

  return { filterFnc };
};

export const useResetFilters = () => {
  const [
    selectedAvailability,
    setAvailable,
    selectedOrderBy,
    setOrderBy,
    selectedSeries,
    handleSelectSeries,
    selectedYear,
    handleSelectYear,
    selectedOrientation,
    handleChangeOrientation,
    handleSetArtists,
  ] = useFilter();

  const [availabilityOpts, ordersOptions, seriesOptions, yearsOptions, orientationOpts, artistOptions] =
    useFilterOptions();

  const resetAvalabilityFilter = () => setAvailable(availabilityOpts[0]);

  const canReset = useMemo(() => {
    if (
      selectedAvailability.id !== 0 ||
      selectedOrderBy.id !== 0 ||
      selectedSeries?.[0]?.id !== 0 ||
      typeof selectedYear[0] !== 'string' ||
      selectedOrientation?.[0].id !== 0
    ) {
      return true;
    }

    return false;
  }, [selectedAvailability, selectedOrderBy, selectedSeries, selectedYear, selectedOrientation]);

  const resetFilters = () => {
    resetAvalabilityFilter();
    setOrderBy(ordersOptions[0]);
    handleSelectSeries(seriesOptions[0]);
    handleSelectYear(yearsOptions[0]);
    handleChangeOrientation(orientationOpts[0]);
    handleSetArtists(artistOptions[0]);
  };

  return {
    resetFilters,
    canReset,
    resetAvalabilityFilter,
  };
};
