import { logger } from '@old/services/logger';
import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { ListingDirection, listingDirections } from '../components/listingDirections';
import { listingStatusLoadingIdentifier } from '../components/listingStatus';
import { canLoadMore } from '../components/listLoader';
import { ListReduxFragment } from '../components/listReduxFragment';
import { SortOptionsEnum } from '../VirtualList.config';

export type AppendDetailedReducer<State extends ListReduxFragment<SortOptionsEnum, unknown>> = CaseReducer<
  State,
  PayloadAction<{
    list: string;
    append: [direction: ListingDirection, sort: State['currentSort'], values: Array<State['data']['cursor'][0]>];
  }>
>;

export const createAppendDetailedCaseReducer =
  <State extends ListReduxFragment<SortOptionsEnum, unknown>>(
    virtualListIdentifier: string
  ): AppendDetailedReducer<State> =>
  (state, action) => {
    if (!state.data.detailed.status.startsWith(listingStatusLoadingIdentifier)) {
      return;
    }

    const {
      list,
      append: [direction, sort, values],
    } = action.payload;
    if (list !== virtualListIdentifier) {
      return;
    }
    if (state.currentSort !== sort) {
      logger.warn('wrong sort');
      return;
    }

    state.data.detailedUntilListedIndex = state.data.detailed.values.length;

    const directionIdx = listingDirections.indexOf(direction);
    state.data.listed.hasMore = [...state.data.listed.hasMore];
    state.data.detailed.hasMore[directionIdx] =
      state.data.detailed.batchSize === values.length || canLoadMore(state.data.listed, direction);

    if (canLoadMore(state.data.detailed, 'FORWARD') || canLoadMore(state.data.detailed, 'BACKWARD')) {
      state.data.detailed.status = 'IDLE';
    } else {
      state.data.detailed.status = 'FINISHED';
    }
  };
