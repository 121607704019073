import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { ListingDirection, listingDirections } from '../components/listingDirections';
import { listingStatusLoadingIdentifier } from '../components/listingStatus';
import { canLoadMore } from '../components/listLoader';
import { ListReduxFragment } from '../components/listReduxFragment';
import { SortOptionsEnum } from '../VirtualList.config';

export type RequestDetailedReducer<State extends ListReduxFragment<SortOptionsEnum, unknown>> = CaseReducer<
  State,
  PayloadAction<{ list: string; direction: ListingDirection }>
>;

export const createRequestDetailedCaseReducer =
  <State extends ListReduxFragment<SortOptionsEnum, unknown>>(
    virtualListIdentifier: string
  ): RequestDetailedReducer<State> =>
  (state, action) => {
    if (!['COLD', 'IDLE'].includes(state.data.detailed.status)) {
      return;
    }

    const {
      payload: { direction, list },
    } = action;

    if (list !== virtualListIdentifier || !canLoadMore(state.data.detailed, direction)) {
      return;
    }

    const directionIdx = listingDirections.indexOf(direction);

    const batchSize = [
      // backward
      0,
      // forward
      Math.min(state.data.listed.values.length - state.data.detailed.values.length, state.data.detailed.batchSize),
    ][directionIdx];

    if (batchSize <= 0 && canLoadMore(state.data.listed, direction)) {
      // possibly waiting on ids
      return;
    }

    const addPlaceholders = [
      // backward
      () => undefined,
      // forward
      () => {
        state.data.detailed.values = [
          ...state.data.detailed.values,
          ...state.data.listed.values.slice(
            state.data.detailed.values.length,
            state.data.detailed.values.length + batchSize
          ),
        ];
      },
    ][directionIdx];

    addPlaceholders();

    state.data.detailed.status = `${listingStatusLoadingIdentifier}_${direction}` as const;
  };
