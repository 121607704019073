import { SortOptionsEnum } from 'common/features/VirtualList/VirtualList.config';
import { Enum_Context_Type_Enum, Scalars } from 'common/schema/commonSchemaRemoteTypes';
import { isSomeEnum } from 'utils/utilFunctions';
import { RecognitionsSort } from './filter.types';

export type RecognitionsFiltersHash = `${SortOptionsEnum}_${string}`;

export const listingDirections = ['BACKWARD', 'FORWARD'] as const;
export type ListDirection = (typeof listingDirections)[0] | (typeof listingDirections)[1];

export const loadingIdentifier = 'LOADING' as const;
export type ListLoadingId = typeof loadingIdentifier;

export type ListLoaderStatus = 'COLD' | 'FINISHED' | 'IDLE' | `${ListLoadingId}_${ListDirection}`;

export type ListWindowLoaderStateType<ValuesType, StatusType> = {
  batchSize: number;
  values: Array<ValuesType>;
  status: StatusType;
  hasMore: [boolean, boolean]; // [backward, forward]
};

export type ListLoaderStateType<Typename extends RecognitionsSort, CursorType, IdType = Scalars['bigint']> = {
  __typename: Typename;
  listed: ListWindowLoaderStateType<CursorType, ListLoaderStatus>;
  detailed: ListWindowLoaderStateType<IdType, ListLoaderStatus>;
  cursor: [CursorType, CursorType]; // [backward, forward]
  detailedToListedStartOffset: number;
  lastScrollPosition: number;
};

export type RecognitionsListByDateCursor = {
  recognizedByType: Enum_Context_Type_Enum;
  recognizedBy: Scalars['bigint'];
  recognizedAt: Scalars['date'];
};
export type RecognitionsListMostRecent = ListLoaderStateType<SortOptionsEnum.MOST_RECENT, RecognitionsListByDateCursor>;

export type MostRecentListActionArgs =
  | [ListDirection, RecognitionsListMostRecent['__typename'], RecognitionsListMostRecent['listed']['values']];

export type MostRecentDetailActionArgs =
  | [ListDirection, RecognitionsListMostRecent['__typename'], RecognitionsListMostRecent['detailed']['values']];
export type RecognitionsListState = {
  identifier: RecognitionsFiltersHash;
  type: RecognitionsSort;
} & {
  type: SortOptionsEnum.MOST_RECENT;
  data: RecognitionsListMostRecent;
};

export type RecognitionsListingCursor = RecognitionsListByDateCursor;

export const isRecognitionsListByDateCursor = (
  cursor: RecognitionsListingCursor
): cursor is RecognitionsListByDateCursor => (cursor as RecognitionsListByDateCursor)?.recognizedAt !== undefined;

export const isContextTypeEnum = isSomeEnum(Enum_Context_Type_Enum);

export const LOADING_STATES: ListLoaderStatus[] = ['LOADING_BACKWARD', 'LOADING_FORWARD'];
