import { IconButton, IconButtonProps, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { useSelectedProfile } from 'common/hooks/useSelectedProfile';
import { useSetFavoriteArtworkMutation } from 'common/schema/commonSchemaRemoteGraphqlQueries';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { CDNUrl } from 'utils/constants';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { userTrapActions, UserTrapView } from 'store/slices/userTrapSlice';
import { Enum_Flags_Type_Enum, Enum_Pseudo_User_Action_Enum } from 'common/schema/commonSchemaRemoteTypes';
import { actions as favortiesActions } from 'stacks/FavoritesV2/store';
import { useCustomDomain } from 'common/hooks/useCustomDomain';
import {
  NotificationModalState,
  permissionsStateActions,
  PermissionTypes,
} from 'stacks/PermissionsModal/store/permissionsStateSlice';
import { useLocation } from 'react-router';
import Auth from 'common/services/Auth';
import { ROUTES } from 'routes/_definition';
import { useFavoritesButtonStyles } from './FavoritesButtonStyle';
import { favoritesModifiersActions } from '../../../store/slices/favoritesSlice';
import useAnalytics from '../Analytics/Analytics';

type FavoriteButtonProps = {
  artworkId: number;
  isFavorite: boolean;
  buttonMode?: boolean;
  buttonProps?: IconButtonProps;
  emptyIcon?: string;
  expanded?: boolean;
  className?: string;
  skipFavoritesListingReset?: boolean;
};

const FavoritesButton: React.FC<FavoriteButtonProps> = ({
  artworkId,
  isFavorite,
  buttonMode = false,
  buttonProps,
  emptyIcon,
  expanded,
  className,
  skipFavoritesListingReset = false,
}) => {
  const classes = useFavoritesButtonStyles();
  const profile = useSelectedProfile();
  const { pathname } = useLocation();
  const [favorite, setFavorite] = useState(isFavorite);
  const [setFavoriteMutation] = useSetFavoriteArtworkMutation();
  const shouldAnimate = useRef<boolean>(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const analytics = useAnalytics();
  const isCustomDomain = !!useCustomDomain();
  const isLoggedIn = Auth.useIsLoggedIn();
  const isOnboarding = useLocation().pathname.startsWith(ROUTES.ONBOARDING);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    if (profile?.contextId === undefined) {
      dispatch(userTrapActions.SET_CURRENT_VIEW(UserTrapView.SIMPLE_CONTENT));
      analytics.postData('STARTED', 'FAVORITES', 'CREATE_ACCOUNT_CLICK_CTA', { target_artwork_id: artworkId });
      // TODO: added new analytics as requested but we need to decide wether to remove the old analytics
      analytics.postData('STARTED', 'GUEST_MODALS', 'OPEN_FAVORITES', {
        tryToFavoriteArtworkId: artworkId,
        currentPage: pathname,
        isGuestUser: !profile,
      });
      dispatch(
        userTrapActions.SET_USER_TRAP_DATA({
          data: {
            artworkId,
          },
        })
      );
      dispatch(userTrapActions.OPEN(Enum_Pseudo_User_Action_Enum.Favorite));
      return;
    }
    setFavoriteMutation({
      variables: {
        artwork_id: artworkId,
        context_id: profile?.contextId,
      },
    });
    if (favorite) {
      dispatch(
        favoritesModifiersActions.REMOVE_FAVORITE({
          contextId: profile.contextId,
          artworkId,
        })
      );
    } else {
      if (isLoggedIn && !isOnboarding) {
        dispatch(
          permissionsStateActions.UPDATE_NOTIFICATIONS_MODAL_STATE({
            permissionType: PermissionTypes.Notifications,
            modalType: Enum_Flags_Type_Enum.NotificationsFavoriteModal,
            status: NotificationModalState.Open,
            artworkId,
          })
        );
      }
      dispatch(
        favoritesModifiersActions.ADD_FAVORITE({
          contextId: profile.contextId,
          artworkId,
        })
      );
    }
    analytics.postData('SUCCESS', 'FAVORITES', 'CLICK_CTA', {
      target_artwork_id: artworkId,
      favorite_state: !favorite,
      currentPage: pathname,
      isGuestUser: !profile,
    });

    if (!skipFavoritesListingReset) {
      dispatch(favortiesActions.RESET_LIST_Favorites());
    }
    setFavorite((prev) => !prev);
  };

  const animationClass = useMemo(() => {
    if (favorite && shouldAnimate.current) {
      return classes.particles;
    }
    shouldAnimate.current = true;
    return classes.particlesPlaceholder;
  }, [favorite]);

  const heartAnimationClasses = useMemo(() => {
    const isButton = buttonMode && !favorite;
    let favClass: string | undefined;
    if (favorite && shouldAnimate.current) {
      favClass = classes.favButtonCheckedAnimated;
    } else {
      shouldAnimate.current = true;
    }

    return clsx(classes.favButton, favorite && classes.favButtonChecked, favClass, isButton && classes.inverse);
  }, [
    favorite,
    buttonMode,
    classes.favButton,
    classes.favButtonChecked,
    classes.favButtonCheckedAnimated,
    classes.inverse,
  ]);

  useEffect(() => {
    setFavorite((prev) => {
      if (isFavorite && !prev) {
        return true;
      }
      return prev;
    });
  }, [isFavorite]);

  if (isCustomDomain || profile?.type === 'GALLERY') {
    return <></>;
  }

  return (
    <IconButton
      {...buttonProps}
      onClick={handleClick}
      className={clsx(classes.button, buttonMode && classes.buttonStyle, 'favorites-button', className)}
      style={{ justifyContent: expanded ? 'flex-start' : 'center' }}
    >
      <img
        className={clsx('fav', heartAnimationClasses, favorite ? 'filled' : 'outline')}
        src={favorite ? `${CDNUrl}/images/favoriteFilledBig.svg` : emptyIcon || `${CDNUrl}/images/favoriteEmpty.svg`}
        alt="favorites"
      />
      <div className={animationClass} style={{ marginLeft: expanded ? -4 : 0 }} />
      {expanded && (
        <Typography variant="h6" className={classes.text}>
          {!isFavorite && t('showcase.cta.addToFavorites')}
          {isFavorite && t('showcase.cta.addedToFavorites')}
        </Typography>
      )}
    </IconButton>
  );
};

export default FavoritesButton;
