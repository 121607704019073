import { interestsFilterHash } from 'common/features/Filters/predefined/Interests/interests.utils';
import { priceRangeFilterHash } from 'common/features/Filters/predefined/PriceRange/priceRange.utils';
import useAppSelector from 'common/hooks/useAppSelector';
import { useEffect, useMemo } from 'react';
import useDebouncedState from 'common/hooks/utils/useDebouncedState';
import { countryFilterHash } from 'common/features/Filters/predefined/Country/country.utils';
import { titleFilterHash } from 'common/features/Filters/predefined/Title/title.utils';
import { boostSkewFilterHash } from 'common/features/Filters/predefined/BoostSkew/boostSkew.utils';
import { HomeArtistsFiltersHash, LOADING_STATES } from '../store/list.types';
import { HomeArtistsFiltersState } from '../store/filter.types';

// exported for use in the initial state
export const filtersHash = (state: HomeArtistsFiltersState, suffix: string | number = ''): HomeArtistsFiltersHash =>
  // using template string to match typing
  `${state.sort}_${[
    interestsFilterHash(state.interests),
    priceRangeFilterHash(state.priceRange),
    countryFilterHash(state.country),
    titleFilterHash(state.title),
    boostSkewFilterHash(state.isBoostSkewed),
    state.refresh,
    suffix,
  ].join('_')}` as const;

export const useHomeArtistsFiltersIdentifier = (contextId?: string | number): HomeArtistsFiltersHash => {
  const filters = useAppSelector((store) => store.homeArtists.filter);
  const currentIdentifier = useMemo(() => filtersHash(filters, contextId), [filters, contextId]);
  const [appliedIdentifier, setAppliedIdentifier] = useDebouncedState(currentIdentifier, 750);
  const listStatus = useAppSelector((store) => store.homeArtists.list.data.listed.status);

  useEffect(() => {
    if (LOADING_STATES.includes(listStatus)) {
      return;
    }
    setAppliedIdentifier(currentIdentifier);
  }, [currentIdentifier, listStatus]);
  return appliedIdentifier;
};
