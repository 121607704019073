import { Theme, useMediaQuery } from '@material-ui/core';
import useAppSelector from 'common/hooks/useAppSelector';
import { useEffect, useMemo, useState } from 'react';
import { SwipeInfoContainerProps } from '../SwipeInfoContainer';
import { useSwipeableContainer } from './useSwipeableContainer';

let isSwipeTutorialDone = false;
const TABLET_EXTRA_RIGHT_OFFSET = 80;

const useGetSwipeContainerProps = (index: number, artworkId: number): SwipeInfoContainerProps => {
  const maxLeftOffset = window.innerWidth - window.innerWidth / 4.5 + 30;
  const [shouldPreview, setShouldPreview] = useState(false);
  const isButtonTutorialDone = useAppSelector((store) => store.consumerProductMenuButton.tutorialDone);

  const isTablet = useMediaQuery((theme: Theme) => theme.breakpoints.between('sm', 'md'));

  useEffect(() => {
    if (index === 0 && !isSwipeTutorialDone && isButtonTutorialDone && artworkId) {
      isSwipeTutorialDone = true;
      const previewOpenTimeout = setTimeout(() => setShouldPreview(true), 1000);
      const previewCloseTimeout = setTimeout(() => setShouldPreview(false), 3000);
      return () => {
        clearTimeout(previewCloseTimeout);
        clearTimeout(previewOpenTimeout);
      };
    }
    return () => null;
  }, [artworkId, index, isButtonTutorialDone]);

  const touchProps = useSwipeableContainer(maxLeftOffset, shouldPreview);

  return useMemo<SwipeInfoContainerProps>(() => {
    const rightOffset = window.innerWidth - Number(touchProps?.style.right) ?? 0;
    return {
      artworkId,
      rightOffset: isTablet ? rightOffset - TABLET_EXTRA_RIGHT_OFFSET : rightOffset,
      touchProps,
    };
  }, [artworkId, touchProps, isTablet]);
};

export default useGetSwipeContainerProps;
