import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { DisciplinesFilterState } from './disciplines.types';
import { DISCIPLINE_ALL_OPTION, getDefaultDisciplineFilter } from './disciplines.defaults';

type ToggleDisciplineAction = PayloadAction<{
  disciplineValue: string;
}>;
export const createToggleDisciplineCaseReducer =
  <State extends DisciplinesFilterState>(): CaseReducer<State, ToggleDisciplineAction> =>
  (state, action) => {
    const { disciplineValue } = action.payload ?? {};

    if (disciplineValue === DISCIPLINE_ALL_OPTION && !!state.disciplines[disciplineValue]) {
      return;
    }

    if (disciplineValue === DISCIPLINE_ALL_OPTION) {
      state.disciplines = getDefaultDisciplineFilter().disciplines;
      return;
    }

    state.disciplines[disciplineValue] = !state.disciplines[disciplineValue];
    state.disciplines[DISCIPLINE_ALL_OPTION] = false;
  };
