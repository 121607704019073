import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NewNotificationType } from 'common/features/Notifications/components/AllNotificationsTab/components/NotificationInfo';
import useAppSelector from 'common/hooks/useAppSelector';

export type AppNotificationsState = {
  appNotifications: NewNotificationType[];
};

export type UpdateNotificationType = {
  notification: NewNotificationType;
  index: number;
};

const initialState: AppNotificationsState = {
  appNotifications: [],
};

const sortedMerge = (oldData: NewNotificationType[], newData: NewNotificationType[]): NewNotificationType[] => {
  const finalData = [];
  const indices = {
    old: 0,
    new: 0,
    current: 0,
  };
  const isNeitherFinished = () => indices.old < oldData.length && indices.new < newData.length;
  while (isNeitherFinished()) {
    const newDate = newData[indices.new].updatedMessageAt ?? '2001-04-18T12:53:37.013326+00:00';
    const oldDate = oldData[indices.old].updatedMessageAt ?? '2000-04-18T12:53:37.013326+00:00';
    if (newDate > oldDate) {
      finalData.push(newData[indices.new]);
      indices.new += 1;
    } else if (oldDate > newDate) {
      finalData.push(oldData[indices.old]);
      indices.old += 1;
    } else if (oldDate === newDate) {
      finalData.push(newData[indices.new]);
      indices.old += 1;
      indices.new += 1;
    }
  }
  while (indices.old < oldData.length) {
    finalData.push(oldData[indices.old]);
    indices.old += 1;
  }
  while (indices.new < newData.length) {
    finalData.push(newData[indices.new]);
    indices.new += 1;
  }
  return finalData;
};

const appNotificationsSlice = createSlice({
  name: 'appNotifications',
  initialState,
  reducers: {
    LOAD_NOTIFICATIONS: (state, action: PayloadAction<NewNotificationType[]>) => ({
      ...state,
      appNotifications: sortedMerge(state.appNotifications, action.payload),
    }),
    LOAD_NOTIFICATION_BY_SUBSCRIPTION: (state, action: PayloadAction<NewNotificationType>) => ({
      ...state,
      appNotifications: sortedMerge(state.appNotifications, [action.payload]),
    }),
    DISMISS_NOTIFICATION: (state, action: PayloadAction<NewNotificationType['id']>) => ({
      ...state,
      appNotifications: state.appNotifications.filter((notification) => notification.id !== action.payload),
    }),
    DISMISS_ALL_NOTIFICATIONS: (state) => ({
      ...state,
      appNotifications: state.appNotifications.filter((notification) => notification.id === 0),
    }),
    MARK_ALL_NOTIFICATIONS_AS_READ: (state) => ({
      ...state,
      appNotifications: state.appNotifications.map((notification) => ({
        ...notification,
        readAt: new Date().toDateString(),
      })),
    }),
  },
});

export const useAppNotifications = () => useAppSelector((state) => state.appNotifications);

export const appNotificationsActions = appNotificationsSlice.actions;

export default appNotificationsSlice;
