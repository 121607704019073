import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createSelectCountryCaseReducer } from 'common/features/Filters/predefined/Country/store/country.reducers';
import { createToggleInterestCaseReducer } from 'common/features/Filters/predefined/Interests/store/interests.reducers';
import { createTogglePriceRangeCaseReducer } from 'common/features/Filters/predefined/PriceRange/store/priceRange.reducers';
import { filterInitialState, getDefaultFilters } from './filter.defaults';
import { RecognitionsFiltersState } from './filter.types';

export const recognitionsFiltersSlice = createSlice({
  name: 'filter',
  initialState: filterInitialState,
  reducers: {
    RESET_Recognitions: getDefaultFilters,
    SORT_Recognitions: (state, action: PayloadAction<RecognitionsFiltersState['sort']>) => ({
      ...state,
      sort: action.payload,
    }),
    TOGGLE_INTEREST_Recognitions: createToggleInterestCaseReducer<RecognitionsFiltersState>(),
    TOGGLE_PRICE_RANGE_Recognitions: createTogglePriceRangeCaseReducer<RecognitionsFiltersState>(),
    CHANGE_COUNTRY_Recognitions: createSelectCountryCaseReducer<RecognitionsFiltersState>(),
  },
});
