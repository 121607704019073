import { Button, makeStyles, Typography } from '@material-ui/core';
import { ChevronRight } from '@material-ui/icons';
import { logger } from '@old/services/logger';
import PaginatedList, { PaginatedListProps } from 'common/components/PaginatedList/PaginatedList';
import useApolloErrorHandler from 'common/features/ErrorHandling/hooks/useApolloErrorHandler';
import { useGetEditionByTypePrintsPaginatedLazyQuery } from 'common/schema/commonSchemaRemoteGraphqlQueries';
import { PrintDetailsFragment } from 'common/schema/commonSchemaRemoteOperationTypes';
import { Enum_Artwork_Showroom_Status_Enum, Enum_Size_Enum } from 'common/schema/commonSchemaRemoteTypes';
import { TFunction } from 'i18next';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePrintsList } from 'stacks/Dashboard/common/ArtworkPublish/components/PublishArtwork/utils/publishArtworkUtils';
import PrintName from 'stacks/Dashboard/common/ArtworkPublish/components/PublishPrints/components/common/PrintName';
import {
  DEFAULT_PRINT_STATUS,
  PRINTS_PER_PAGE,
} from 'stacks/Dashboard/common/ArtworkPublish/utils/artworkPublishConstants';
import { useShowcaseDetailsContext } from 'stacks/ShowcaseNewV2/context/ShowcaseDetailsProvider/ShowcaseDetailsProvider';
import { getProfileFullName, isProfilePublic } from 'utils/utilFunctions';

const useStyles = makeStyles((theme) => ({
  printProvenanceButton: {
    ...theme.mixins.flexSpaceBetween,
  },
  printDetails: {
    display: 'flex',
    width: '100%',
    color: theme.palette.common.black,
  },
  printName: {
    textAlign: 'start',
    flex: 1,
  },
  printStatus: {
    textAlign: 'start',
    flex: 3,
    [theme.breakpoints.down('md')]: {
      flex: 2,
    },
    [theme.breakpoints.down('xs')]: {
      flex: 1,
    },
  },
  chevron: {
    color: theme.palette.common.black,
    opacity: 0.5,
  },
}));

type PrintCollectorContext = PrintDetailsFragment['acceptedTransactions'][0]['buyer_context'];

export type PrintProvenance = Pick<PrintDetailsFragment, 'status' | 'number' | 'editionByTypeId'> & {
  id?: PrintDetailsFragment['id'];
  collector?: PrintCollectorContext;
};

export type EditionByType = {
  id: number;
  type: string;
  printsAmount: number;
};

export type PrintsProvenanceListProps = {
  onPrintClick: (print: PrintProvenance) => void;
  editionByType: EditionByType;
  size: Enum_Size_Enum;
};

export const getCollectorName = (
  data: PrintCollectorContext | PrintDetailsFragment['acceptedTransactions'],
  t: TFunction
): string | undefined => {
  let collectorContext: PrintCollectorContext;
  if (Array.isArray(data)) {
    if (data.length) {
      collectorContext = data[0].buyer_context;
    }
  } else {
    collectorContext = data;
  }
  if (!collectorContext?.profile) {
    return undefined;
  }
  const collectorName = getProfileFullName(collectorContext?.profile);
  if (!isProfilePublic(collectorContext?.profile)) {
    return t('myCollectorsStrings.private');
  }
  if (collectorName.length || collectorContext?.profile.handle?.length) {
    return collectorName ?? collectorContext?.profile.handle;
  }
  return undefined;
};

const PrintsProvenanceList: React.FC<PrintsProvenanceListProps> = ({ editionByType, onPrintClick, size }) => {
  const { t } = useTranslation();
  const { onError } = useApolloErrorHandler();
  const [getPaginatedPrints] = useGetEditionByTypePrintsPaginatedLazyQuery();
  const classes = useStyles();
  const {
    editionsTab: { editionSizesDetails },
  } = useShowcaseDetailsContext();
  const render = useCallback<PaginatedListProps<PrintProvenance>['render']>(
    (print) => {
      const initialStatus = editionSizesDetails[size]?.isForSale
        ? print.status ?? DEFAULT_PRINT_STATUS
        : Enum_Artwork_Showroom_Status_Enum.Unavailable;
      let collectorOrStatus = t(`artwork.availability.${initialStatus}`);
      if (print.collector) {
        collectorOrStatus = Enum_Artwork_Showroom_Status_Enum.Sold;
        if (!isProfilePublic(print.collector?.profile)) {
          collectorOrStatus = t('myCollectorsStrings.private');
        } else {
          const collectorName = getCollectorName(print.collector, t);
          if (collectorName?.length || print.collector.profile?.handle?.length) {
            collectorOrStatus = collectorName ?? print.collector.profile?.handle ?? '';
          }
        }
      }
      return (
        <Button
          onClick={() => onPrintClick(print)}
          className={classes.printProvenanceButton}
          fullWidth
          endIcon={<ChevronRight className={classes.chevron} />}
        >
          <div className={classes.printDetails}>
            <PrintName className={classes.printName} index={print.number} />
            <Typography className={classes.printStatus}>{collectorOrStatus}</Typography>
          </div>
        </Button>
      );
    },
    [editionSizesDetails, onPrintClick, t]
  );

  const fetchPageItems = useCallback<PaginatedListProps<PrintProvenance>['fetchPageItems']>(
    async (page) => {
      try {
        const result = await getPaginatedPrints({
          variables: {
            edition_by_type_id: editionByType.id,
            limit: PRINTS_PER_PAGE,
            offset: (page - 1) * PRINTS_PER_PAGE,
          },
        });
        if (result.error) {
          onError(result.error);
          return [];
        }
        if (!result.data) {
          return [];
        }
        const fetchedPrints = result.data.Print.map<PrintProvenance>(({ acceptedTransactions, ...p }) => ({
          ...p,
          size,
          collector: acceptedTransactions.length ? acceptedTransactions[0].buyer_context : undefined,
        }));
        return generatePrintsList(page, editionByType.printsAmount, fetchedPrints, editionByType.id, size);
      } catch (error) {
        if (error instanceof Error) {
          logger.error(error);
        }
        return [];
      }
    },
    [editionByType.id, editionByType.printsAmount]
  );

  return (
    <div style={{ width: '100%' }}>
      <PaginatedList
        totalItems={editionByType.printsAmount}
        render={render}
        fetchPageItems={fetchPageItems}
        cachePageItems
      />
    </div>
  );
};

export default PrintsProvenanceList;
