import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export enum GEOLOCATION_PERMISSIONS {
  GRANTED = 'granted',
  DENIED = 'denied',
  PROMPT = 'prompt',
}

type GeolocationSuccess = {
  coords: Pick<GeolocationCoordinates, 'latitude' | 'longitude'>;
  permission: GEOLOCATION_PERMISSIONS.GRANTED;
};

type GeolocationError = {
  coords: null;
  permission: GEOLOCATION_PERMISSIONS.DENIED | GEOLOCATION_PERMISSIONS.PROMPT;
};

export type CurrentLocationState = {
  status: GeolocationError | GeolocationSuccess;
};

// This is used as a placeholder for the geolocation
// Values are for new york
// TODO - remove this when we have a proper geolocation
export const TEMP_NEARBY_FILTER_VALUES = {
  lat: 44.287688,
  lng: 26.056297,
};

const initialState: CurrentLocationState = {
  status: {
    coords: null,
    permission: GEOLOCATION_PERMISSIONS.PROMPT,
  },
};

const geolocationSlice = createSlice({
  name: 'geolocation',
  initialState,
  reducers: {
    UPDATE_COORDS: (state, action: PayloadAction<Pick<GeolocationCoordinates, 'latitude' | 'longitude'>>) => {
      state.status = {
        coords: action.payload,
        permission: GEOLOCATION_PERMISSIONS.GRANTED,
      };
    },
    UPDATE_STATUS: (state, action: PayloadAction<GEOLOCATION_PERMISSIONS.DENIED | GEOLOCATION_PERMISSIONS.PROMPT>) => {
      state.status = {
        coords: null,
        permission: action.payload,
      };
    },
  },
});

export const geolocationActions = geolocationSlice.actions;
export default geolocationSlice;
