import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createSelectCountryCaseReducer } from 'common/features/Filters/predefined/Country/store/country.reducers';
import { createToggleInterestCaseReducer } from 'common/features/Filters/predefined/Interests/store/interests.reducers';
import { createTogglePriceRangeCaseReducer } from 'common/features/Filters/predefined/PriceRange/store/priceRange.reducers';
import { createRefreshFilterCaseReducer } from 'common/features/Filters/predefined/Refresh/refresh.reducers';
import { createFilterTitleCaseReducer } from 'common/features/Filters/predefined/Title/store/title.reducers';

import { createChangeRadiusFilterReducer } from 'stacks/Nearby/RadiusFilter/store/radius.reducers';
import { createSetBoostSkewCaseReducer } from 'common/features/Filters/predefined/BoostSkew/store/boostSkew.reducers';
import { filterInitialState, getDefaultFilters } from './filter.defaults';
import { NearbyArtistsFiltersState } from './filter.types';

export const nearbyArtistsFiltersSlice = createSlice({
  name: 'filter',
  initialState: filterInitialState,
  reducers: {
    RESET_NearbyArtists: getDefaultFilters,
    SORT_NearbyArtists: (state, action: PayloadAction<NearbyArtistsFiltersState['sort']>) => ({
      ...state,
      sort: action.payload,
    }),
    TOGGLE_INTEREST_NearbyArtists: createToggleInterestCaseReducer<NearbyArtistsFiltersState>(),
    TOGGLE_PRICE_RANGE_NearbyArtists: createTogglePriceRangeCaseReducer<NearbyArtistsFiltersState>(),
    CHANGE_COUNTRY_NearbyArtists: createSelectCountryCaseReducer<NearbyArtistsFiltersState>(),
    SEARCH_TITLE_NearbyArtists: createFilterTitleCaseReducer<NearbyArtistsFiltersState>(),
    REFRESH_NearbyArtists: createRefreshFilterCaseReducer<NearbyArtistsFiltersState>(),
    CHANGE_RADIUS_NearbyArtists: createChangeRadiusFilterReducer<NearbyArtistsFiltersState>(),
    SET_BoostSkewNearbyArtists: createSetBoostSkewCaseReducer<NearbyArtistsFiltersState>(),
  },
});
