import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ArtistRecognitionByUserListElementProps } from 'common/features/RecognitionsEngagement/RecognitionsModal/components/ArtistRecognitionByUserListElement/ArtistRecognitionByUserListElement';

type FollowerToRemoveType = Pick<ArtistRecognitionByUserListElementProps, 'userData' | 'artistArtworksData'>;

export interface myFollowersSliceState {
  clickedFollowButton: boolean;
  followToRemove: number;
  followerToRemove: FollowerToRemoveType;
  followersCount: number;
}

const initialState: myFollowersSliceState = {
  clickedFollowButton: false,
  followToRemove: -1,
  followerToRemove: {},
  followersCount: 0,
};

const myFollowersSlice = createSlice({
  name: 'myFollowers',
  initialState,
  reducers: {
    SET_CLICKED_FOLLOW_BUTTON: (state, action: PayloadAction<boolean>) => ({
      ...state,
      clickedFollowButton: action.payload,
    }),
    SET_FOLLOW_TO_REMOVE: (state, action: PayloadAction<number>) => ({
      ...state,
      followToRemove: action.payload,
    }),
    SET_FOLLOWERS_COUNT: (state, action: PayloadAction<number>) => ({
      ...state,
      followersCount: action.payload,
    }),
    SET_FOLLOWER_TO_REMOVE: (state, action: PayloadAction<FollowerToRemoveType>) => ({
      ...state,
      followerToRemove: action.payload,
      followersCount: state.followersCount + 1,
    }),
  },
});

export const myFollowersSliceActions = myFollowersSlice.actions;
export default myFollowersSlice;
