import { ApolloError } from '@apollo/client';
import { logger } from '@old/services/logger';
import { useSnackbar } from 'common/contexts/ToastProvider';
import { useCallback, useEffect } from 'react';

const useApolloErrorHandler = (error?: ApolloError, options: { silent: boolean } = { silent: false }) => {
  const snackbar = useSnackbar();

  const onError = useCallback(
    (e?: ApolloError) => {
      if (!e) {
        return;
      }
      if (!options.silent) {
        snackbar.showErrorSnackbar(e.message);
      }
      logger.error(e);
    },
    [options.silent, snackbar]
  );

  useEffect(() => {
    onError(error);
  }, [error]);

  return { onError };
};

export default useApolloErrorHandler;
