import { CountryFilterState } from 'common/features/Filters/predefined/Country/store/country.types';
import { InterestsFilterState } from 'common/features/Filters/predefined/Interests/store/interests.types';

export const sorts = ['byDate'] as const;
export type FollowersSort = (typeof sorts)[0];

export const isFollowersSort = (v: string): v is FollowersSort => sorts.includes(v as FollowersSort);

// currently auto-applies on every change
// maybe todo: add flag to apply on demand
export type FollowersFiltersState = InterestsFilterState &
  CountryFilterState & {
    sort: FollowersSort;
  };
