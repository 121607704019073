import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getDefaultState } from './artFeedNavbar.defaults';
import { CurrentNavbarStatus, NavbarVisibilityReason } from './artFeedNavbar.types';

const getCurrentStatus = (locations: Record<string, boolean>, scrolled: Record<string, boolean>): CurrentNavbarStatus =>
  Object.keys(locations)
    .filter((key) => locations[key])
    .map<CurrentNavbarStatus>((key) => ({
      isOnLocation: locations[key],
      shouldBeScrolled: scrolled[key] ?? false,
    }))
    .reduce<CurrentNavbarStatus>(
      (result, current) => ({
        isOnLocation: result.isOnLocation || current.isOnLocation,
        shouldBeScrolled: result.shouldBeScrolled || current.shouldBeScrolled,
      }),
      {
        isOnLocation: false,
        shouldBeScrolled: false,
      }
    );

export const artFeedNavbarSlice = createSlice({
  initialState: getDefaultState(),
  name: 'artFeedNavbar',
  reducers: {
    SHOW_NAVBAR: (
      store,
      action: PayloadAction<{
        page: string;
        reasons: NavbarVisibilityReason[];
      }>
    ) => {
      const { page, reasons } = action.payload;
      reasons.forEach((reason) => {
        switch (reason) {
          case 'location':
            if (store.currentlyScrolled[page] === undefined) {
              store.currentlyScrolled[page] = true;
            }
            store.currentlyOnLocation[page] = true;
            break;
          case 'scroll':
            store.currentlyScrolled[page] = true;
            break;
          default:
            break;
        }
      });
      const { isOnLocation, shouldBeScrolled } = getCurrentStatus(store.currentlyOnLocation, store.currentlyScrolled);
      store.isOnLocation = isOnLocation;
      store.shouldBeScrolled = shouldBeScrolled;
    },
    HIDE_NAVBAR: (
      store,
      action: PayloadAction<{
        page: string;
        reasons: NavbarVisibilityReason[];
      }>
    ) => {
      const { page, reasons } = action.payload;
      reasons.forEach((reason) => {
        switch (reason) {
          case 'location':
            store.currentlyOnLocation[page] = false;
            break;
          case 'scroll':
            store.currentlyScrolled[page] = false;
            break;
          default:
            break;
        }
      });
      const { isOnLocation, shouldBeScrolled } = getCurrentStatus(store.currentlyOnLocation, store.currentlyScrolled);
      store.isOnLocation = isOnLocation;
      store.shouldBeScrolled = shouldBeScrolled;
    },
  },
});

export const { actions } = artFeedNavbarSlice;
