import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GetExploreGalleryInfoQuery } from 'common/schema/commonSchemaRemoteOperationTypes';
import { Scalars } from 'common/schema/commonSchemaRemoteTypes';

export type GalleryCardDataType = {
  gallery: GetExploreGalleryInfoQuery['galleries'][0];
  cardBanner: GetExploreGalleryInfoQuery['galleries'][0]['kard_banner'];
};

type GalleryCardEntities = Record<Scalars['bigint'], GalleryCardDataType>;

const initialState: GalleryCardEntities = {};

export const galleryCardsSlice = createSlice({
  name: 'galleryCardsCache',
  initialState,
  reducers: {
    UPDATE_GALLERY_CARDS: (state, action: PayloadAction<GalleryCardDataType[]>) => {
      action.payload.forEach((card) => {
        if (!card.gallery.contextId) {
          return;
        }
        state[card.gallery.contextId] = card;
      });
    },
  },
});
