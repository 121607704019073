import useAppSelector from 'common/hooks/useAppSelector';
import { useDispatch } from 'react-redux';
import { useCallback, useEffect, useMemo } from 'react';
import { profileToShowcaseAnimationActions } from 'store/slices/profileToShowcaseAnimationSlice';
import { useHistory } from 'react-router';

// Animation States:
// Profile to Showcase Animation: PROFILE_DISASSEMBLE -> SHOWCASE_ASSEMBLE -> SHOWCASE_QUERY_LOADING_DONE -> SHOWCASE_SHOW_BUTTONS
// Special Case: SHOWCASE_SHOW_LOADING -> SHOWCASE_SHOW_BUTTONS
type ProfileAnimationStateReturnType = {
  onAnimationEnd: () => void;
  startTransitionToShowcase: () => void;
  showLogoAnimation: boolean;
};

export const useProfileAnimationState = (skipEffects?: boolean): ProfileAnimationStateReturnType => {
  const {
    nextLocation: animationNextLocation,
    animation,
    isShowcaseQueryLoading,
  } = useAppSelector((store) => store.profileToShowcaseAnimation);
  const dispatch = useDispatch();
  const history = useHistory();

  const startShowcaseAnimation = useCallback(() => {
    dispatch(profileToShowcaseAnimationActions.SET_ANIMATION({ animation: 'SHOWCASE_ASSEMBLE' }));
    dispatch(
      profileToShowcaseAnimationActions.SET_CURRENT_LOCATION({
        currentLocation: animationNextLocation,
      })
    );
  }, [animationNextLocation]);

  useEffect(() => {
    if (skipEffects) {
      return;
    }

    history.listen((nextLocation, action) => {
      if (action !== 'POP' || !nextLocation.pathname.includes('/showcase/')) {
        return;
      }

      dispatch(
        profileToShowcaseAnimationActions.SET_CURRENT_LOCATION({
          currentLocation: nextLocation,
        })
      );

      // Pop Action Case#1: From Profile/Live/.. back to Showcase
      if (!nextLocation.pathname.includes('/showcase/')) {
        dispatch(profileToShowcaseAnimationActions.SET_ANIMATION({ animation: 'SHOWCASE_ASSEMBLE' }));
        return;
      }

      // Pop Action Case#2: From one Showcase back to previous Showcase
      if (nextLocation.pathname.includes('/showcase/')) {
        dispatch(profileToShowcaseAnimationActions.SET_ANIMATION({ animation: 'SHOWCASE_FADE' }));
      }
    });
  }, []);

  const startTransitionToShowcase = useCallback(() => {
    dispatch(
      profileToShowcaseAnimationActions.SET_ANIMATION({
        animation: 'PROFILE_DISASSEMBLE',
      })
    );
  }, []);

  const onAnimationEnd = useCallback(() => {
    if (animation === 'PROFILE_DISASSEMBLE') {
      startShowcaseAnimation();
    }
  }, [animation, startShowcaseAnimation]);

  const showLogoAnimation = useMemo(() => {
    if (animation !== 'SHOWCASE_SHOW_LOADING') {
      return false;
    }

    if (isShowcaseQueryLoading) {
      return true;
    }

    return false;
  }, [animation, isShowcaseQueryLoading]);

  useEffect(() => {
    if (skipEffects) {
      return;
    }

    if (!showLogoAnimation) {
      return;
    }

    setTimeout(() => {
      dispatch(
        profileToShowcaseAnimationActions.SET_ANIMATION({
          animation: 'SHOWCASE_SHOW_BUTTONS',
        })
      );
    }, 2000);
  }, [showLogoAnimation, dispatch]);

  return {
    onAnimationEnd,
    startTransitionToShowcase,
    showLogoAnimation,
  };
};
