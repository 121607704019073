import { YearFilterState } from 'common/features/Filters/predefined/Year/store/year.types';
import { ArtistsFilterState } from 'common/features/Filters/predefined/Artists/store/artists.types';
import { SortState } from 'common/features/Filters/predefined/SortGroup/store/sort.types';
import { SortOptionsEnum } from 'common/features/VirtualList/VirtualList.config';

export const sorts = [
  SortOptionsEnum.MOST_RECENT,
  SortOptionsEnum.LEAST_RECENT,
  SortOptionsEnum.COLLECTOR_SELECTION,
] as const;

export type ProfileCollectorSort = (typeof sorts)[0] | (typeof sorts)[1] | (typeof sorts)[2];

export const isProfileCollectorSort = (v: string): v is ProfileCollectorSort =>
  sorts.includes(v as ProfileCollectorSort);

// currently auto-applies on every change
// maybe todo: add flag to apply on demand
export type ProfileCollectorFiltersState = YearFilterState &
  ArtistsFilterState &
  // & { sort: ProfileCollectorSort }
  SortState;
