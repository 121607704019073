import { useParams } from 'react-router';
import { ROUTES, CUSTOM_DOMAIN_ROUTES, RouteParamsType, CustomDomainRouteParamsType } from 'routes/_definition';

export type PublicDomainRouteParams<T extends ROUTES> = Extract<RouteParamsType, [T, unknown]>[1];
export type CustomDomainRouteParams<T extends CUSTOM_DOMAIN_ROUTES> = Extract<
  CustomDomainRouteParamsType,
  [T, unknown]
>[1];

const usePathParams = <T extends ROUTES | CUSTOM_DOMAIN_ROUTES>() =>
  useParams<
    T extends ROUTES ? PublicDomainRouteParams<T> : T extends CUSTOM_DOMAIN_ROUTES ? CustomDomainRouteParams<T> : never
  >();

export default usePathParams;
