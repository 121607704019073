import { CDNUrl } from 'utils/constants';
import { useRatingStarsTextStyles } from './RatingStarsText.styles';
import RatingStars from '../RatingStars/RatingStars';
import { RatingStarsTextType } from '../../utils/types';

const RatingStarsText: React.FC<RatingStarsTextType> = ({
  ratingText,
  iconImage,
  score,
  maxScore,
  showInfoIcon,
  onClickInfoIcon,
  isDarkMode,
}) => {
  const classes = useRatingStarsTextStyles({ isDarkMode });
  return (
    <div className={classes.container}>
      <span className={classes.ratingText}>{ratingText}</span>
      <RatingStars score={score} maxScore={maxScore} iconImage={iconImage} isDarkMode={isDarkMode} />
      {showInfoIcon && (
        <div role="button" tabIndex={0} onClick={onClickInfoIcon} className={classes.infoIconContainer}>
          <img alt="info-icon" src={`${CDNUrl}/images/dashboardIcons/InfoIcon.svg`} className={classes.infoIcon} />
        </div>
      )}
    </div>
  );
};
export default RatingStarsText;
