import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type ArtworkInfo = {
  artworkId: number;
  imageId: number;
  artistName: string;
  title: string;
  slug: string;
  artistHandle: string;
};

export interface ClaimCertificateRegisterState {
  email: string | null;
  artworkInfo: ArtworkInfo | null;
}

const initialState: ClaimCertificateRegisterState = {
  email: null,
  artworkInfo: null,
};

const claimCertificateRegisterSlice = createSlice({
  name: 'claimCertificateRegister',
  initialState,
  reducers: {
    RESET: (state) => {
      state.email = null;
      state.artworkInfo = null;
    },
    SET_EMAIL: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    SET_ARTWORK_INFO: (state, action: PayloadAction<ArtworkInfo>) => {
      state.artworkInfo = action.payload;
    },
  },
});

export const claimCertificateRegisterActions = claimCertificateRegisterSlice.actions;
export default claimCertificateRegisterSlice;
