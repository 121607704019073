import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RecordPluginState } from 'common/components/AudioRecorder/context/AudioRecorderContext';
import { useShouldShowFeatureFlag } from 'common/features/FeatureFlag/hooks/useFeatureFlag';
import useAppSelector from 'common/hooks/useAppSelector';
import { GetPublishEditionDataQuery } from 'common/schema/commonSchemaRemoteOperationTypes';
import {
  Edition_Type,
  Enum_Artwork_Edition_Type_Enum,
  Enum_Print_Technique,
} from 'common/schema/commonSchemaRemoteTypes';
import {
  PublishArtworkFlowEnum,
  PublishArtworkStep,
  PublishArtworkType,
  PublishFlowStep,
  PublishPrintStep,
  PublishTypeEnum,
  RegisteredCallback,
} from 'stacks/Dashboard/common/ArtworkPublish/utils/artworkPublishTypes';
import { ISelectableArtwork } from 'stacks/Dashboard/common/SelectArtworksModal/components/SelectableArtwork';
import { isBlob } from 'utils/utilFunctions';

export type PublishState = PublishCommonState & (PublishArtworkState | PublishPrintState);

export enum ImageUploadStatus {
  NONE,
  UPLOADING,
  DONE,
  FAILED,
}

export type PublishCommonState = {
  type?: PublishTypeEnum;
  flowType?: PublishArtworkFlowEnum;
  artwork?: PublishArtworkType;
  partenerArtistId?: number;
  thumbnail?: string;
  progressStep: PublishFlowStep | null;
  stepsCallbacks: { [key in PublishFlowStep]?: RegisteredCallback };
  isEdit: boolean;
  isPublished: boolean;
  isLoading: boolean;
  audioRecordingState: RecordPluginState;
  imageUploadStatus: ImageUploadStatus;
  isPublic?: boolean;
  imageSelectedByUser: boolean;
};

export type PublishArtworkState = {
  type?: PublishTypeEnum.UNIQUE_ARTWORK;
  stepsCallbacks: { [key in PublishArtworkStep]?: RegisteredCallback };
};

export type EditionType = Pick<Edition_Type, 'id' | 'creator_context_id' | 'value' | 'deleted_at'>;

export type PublishPrintState = {
  type?: PublishTypeEnum.PRINT_EDITION;
  stepsCallbacks: { [key in PublishPrintStep]?: RegisteredCallback };
  printType?: Enum_Artwork_Edition_Type_Enum;
  selectedPrintArtwork?: ISelectableArtwork;
  editionId?: number;
  printTechniques?: Enum_Print_Technique[];
  editionTypes?: EditionType[];
  transferredPrints?: GetPublishEditionDataQuery['transferredPrints'];
};

const initialState: PublishState = {
  stepsCallbacks: {},
  // flowType: PublishArtworkFlowEnum.FULL_FLOW,
  progressStep: null,
  isEdit: false,
  isLoading: false,
  isPublished: false,
  audioRecordingState: RecordPluginState.NONE,
  isPublic: true,
  imageUploadStatus: ImageUploadStatus.DONE,
  imageSelectedByUser: false,
};

const blockUnload = (e: BeforeUnloadEvent) => {
  e.preventDefault();
  return "We are still uploading your artwork images. Please don't leave the page.";
};

const publishSlice = createSlice({
  name: 'publish',
  initialState,
  reducers: {
    SET_RECORDING_STATE: (
      state,
      action: {
        payload: { audioRecordingState: RecordPluginState };
      }
    ) => {
      state.audioRecordingState = action.payload.audioRecordingState;
    },
    SET_STATE: (state, action: PayloadAction<Partial<PublishState>>) => ({
      ...state,
      ...action.payload,
    }),
    SET_ARTWORK: (state, action: PayloadAction<Partial<PublishArtworkType> | undefined>) => {
      if (action.payload === undefined) {
        return {
          ...state,
          artwork: undefined,
        };
      }
      return {
        ...state,
        artwork: { ...state.artwork, ...action.payload },
      };
    },
    SET_PUBLISH_TYPE: (state, action: PayloadAction<PublishTypeEnum>) => {
      state.type = action.payload;
      return state;
    },
    SET_FLOW_TYPE: (state, action: PayloadAction<PublishArtworkFlowEnum>) => {
      if (state.type !== PublishTypeEnum.UNIQUE_ARTWORK) {
        return state;
      }
      state.flowType = action.payload;
      return state;
    },
    SET_PARTNER_ARTIST_ID: (state, action: PayloadAction<number>) => ({
      ...state,
      partenerArtistId: action.payload,
    }),
    SET_STEP_CALLBACK: (
      state,
      action: PayloadAction<{
        step: PublishFlowStep;
        callback: RegisteredCallback;
      }>
    ) => {
      const { step, callback } = action.payload;
      state.stepsCallbacks = { ...state.stepsCallbacks, [step]: callback };
      return state;
    },
    SET_PRINT_TYPE: (state, action: PayloadAction<Enum_Artwork_Edition_Type_Enum>) => {
      if (state.type !== PublishTypeEnum.PRINT_EDITION) {
        return state;
      }
      state.printType = action.payload;
      return state;
    },
    CLEAR_STATE: () => ({ ...initialState }),
    SET_EDITION_ID: (state, action: PayloadAction<number | undefined>) => {
      if (state.type !== PublishTypeEnum.PRINT_EDITION) {
        return state;
      }
      state.editionId = action.payload;
      return state;
    },
    SET_PRINT_TECHNIQUES: (state, action: PayloadAction<Enum_Print_Technique[] | undefined>) => {
      if (state.type !== PublishTypeEnum.PRINT_EDITION) {
        return state;
      }
      state.printTechniques = action.payload;
      return state;
    },
    SET_EDITION_TYPES: (state, action: PayloadAction<EditionType[] | undefined>) => {
      if (state.type !== PublishTypeEnum.PRINT_EDITION) {
        return state;
      }
      state.editionTypes = action.payload ? [...action.payload] : undefined;
      return state;
    },
    SET_PRINT_ORIGINAL_ARTWORK: (state, action: PayloadAction<ISelectableArtwork | undefined>) => {
      if (state.type !== PublishTypeEnum.PRINT_EDITION) {
        return state;
      }
      state.selectedPrintArtwork = action.payload;
      state.thumbnail = action.payload?.image;
      return state;
    },
    INIT_EDITION_DATA: (
      state,
      action: PayloadAction<
        Pick<
          PublishCommonState & PublishPrintState,
          'thumbnail' | 'isPublished' | 'progressStep' | 'editionId' | 'printType' | 'transferredPrints'
        >
      >
    ) => ({
      ...state,
      ...action.payload,
      type: PublishTypeEnum.PRINT_EDITION,
      isLoading: false,
      thumbnail: isBlob(state.thumbnail) ? state.thumbnail : action.payload.thumbnail,
    }),
    INIT_ARTWORK_DATA: (
      state,
      action: PayloadAction<
        Pick<
          PublishCommonState & PublishArtworkState,
          'thumbnail' | 'isPublished' | 'progressStep' | 'artwork' | 'imageUploadStatus'
        >
      >
    ) => ({
      ...state,
      ...action.payload,
      type: PublishTypeEnum.UNIQUE_ARTWORK,
      isLoading: false,
      thumbnail: isBlob(state.thumbnail) ? state.thumbnail : action.payload.thumbnail,
      imageUploadStatus: action.payload.imageUploadStatus,
    }),
    SET_IMAGE_UPLOAD_STATUS: (state, action: PayloadAction<ImageUploadStatus>) => {
      if (action.payload === ImageUploadStatus.UPLOADING) {
        window.addEventListener('beforeunload', blockUnload);
      } else {
        window.removeEventListener('beforeunload', blockUnload);
      }
      return {
        ...state,
        imageUploadStatus: action.payload,
      };
    },
  },
});

export const publishActions = publishSlice.actions;

export const usePublishState = (): PublishState => useAppSelector((state) => state.publish);

export const usePublishPrintState = () => {
  const [showPrintsFeature] = useShouldShowFeatureFlag('prints');
  const state = usePublishState();
  if (!showPrintsFeature || state.type !== PublishTypeEnum.PRINT_EDITION) {
    return null;
  }
  return state;
};

export const usePublishArtworkState = () => {
  const state = usePublishState();
  if (state.type !== PublishTypeEnum.UNIQUE_ARTWORK) {
    return null;
  }
  return state;
};

export default publishSlice;
