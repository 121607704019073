import { BoostSkewFilterState } from 'common/features/Filters/predefined/BoostSkew/store/boostSkew.types';
import { CountryFilterState } from 'common/features/Filters/predefined/Country/store/country.types';
import { InterestsFilterState } from 'common/features/Filters/predefined/Interests/store/interests.types';
import { PriceRangeFilterState } from 'common/features/Filters/predefined/PriceRange/store/priceRange.types';
import { RefreshFilterState } from 'common/features/Filters/predefined/Refresh/refresh.types';
import { TitleFilterState } from 'common/features/Filters/predefined/Title/store/title.types';
import { RadiusFilterState } from 'stacks/Nearby/RadiusFilter/store/radius.types';

export const sorts = ['byDate', 'byTitle'] as const;
export type NearbyArtistsSort = (typeof sorts)[0] | (typeof sorts)[1];

export const isNearbyArtistSort = (v: string): v is NearbyArtistsSort => sorts.includes(v as NearbyArtistsSort);

// currently auto-applies on every change
// maybe todo: add flag to apply on demand
export type NearbyArtistsFiltersState = InterestsFilterState &
  PriceRangeFilterState &
  CountryFilterState &
  TitleFilterState &
  RefreshFilterState & {
    sort: NearbyArtistsSort;
  } & RadiusFilterState &
  BoostSkewFilterState;
