import useApolloErrorHandler from 'common/features/ErrorHandling/hooks/useApolloErrorHandler';
import { DefaultEditionType } from 'common/features/FormikConfigs/components/Prints';
import { useSelectedProfile } from 'common/hooks/useSelectedProfile';
import { useGetEditionTypesQuery } from 'common/schema/commonSchemaRemoteGraphqlQueries';
import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { publishActions, usePublishPrintState } from 'store/slices/publishSlice';
import { compareByArrayOrder, isSomeEnum } from 'utils/utilFunctions';

const orderedEditionTypes = Object.keys(DefaultEditionType);

export const sortEditionTypes = (a: string, b: string) => {
  const defaultA = isSomeEnum(DefaultEditionType)(a);
  const defaultB = isSomeEnum(DefaultEditionType)(b);
  // default types before user types
  if (defaultA && !defaultB) {
    return -1;
  }
  if (!defaultA && defaultB) {
    return 1;
  }
  if (defaultA && defaultB) {
    // default edition types order
    return compareByArrayOrder(orderedEditionTypes)(a, b);
  }
  // user created edition types order
  return a.localeCompare(b);
};

const useEditionTypes = () => {
  const state = usePublishPrintState();
  const profile = useSelectedProfile();
  const dispatch = useDispatch();
  const { data, error, loading } = useGetEditionTypesQuery({
    variables: { context_id: profile?.contextId ?? 0 },
    skip: !profile?.contextId || !!state?.editionTypes?.length,
  });
  useApolloErrorHandler(error);
  useEffect(() => {
    if (loading || !data || !data.Edition_Type) {
      return;
    }
    dispatch(publishActions.SET_EDITION_TYPES(data.Edition_Type.sort((a, b) => sortEditionTypes(a.value, b.value))));
  }, [data, data?.Edition_Type, loading]);

  return useMemo(
    () => ({
      editionTypes: state?.editionTypes?.filter((e) => !e.deleted_at),
      deletedEditionTypes: state?.editionTypes?.filter((e) => e.deleted_at),
    }),
    [state?.editionTypes]
  );
};

export default useEditionTypes;
