export const speedToAspectImgSize: Record<number, 'large' | 'medium' | 'thumbnail-small' | 'thumbnail-large'> = {
  0: 'medium',
  1: 'medium',
  2: 'medium',
  3: 'thumbnail-large',
  4: 'thumbnail-small',
};
export const speedToSquareImgSize: Record<number, 'medium' | 'small' | 'large'> = {
  0: 'medium',
  1: 'medium',
  2: 'medium',
  3: 'small',
  4: 'small',
};
export const oneColumnSpeedToAspectImgSize: Record<number, 'large' | 'medium' | 'thumbnail-small' | 'thumbnail-large'> =
  {
    0: 'large',
    1: 'large',
    2: 'large',
    3: 'medium',
    4: 'thumbnail-small',
  };
export const oneColumnSpeedToSquareImgSize: Record<number, 'medium' | 'small' | 'large'> = {
  0: 'large',
  1: 'large',
  2: 'large',
  3: 'medium',
  4: 'medium',
};
export const speedToBlur: Record<number, number> = {
  3: 1,
  4: 5,
};

const scrollSpeedImageSizeMap = {
  speedToAspectImgSize,
  speedToSquareImgSize,
  speedToBlur,
};
export default scrollSpeedImageSizeMap;
