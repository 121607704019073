import VirtualList from 'common/features/VirtualList/VirtualList';
import { KnownVirtualListEnum, SortOptionsEnum } from 'common/features/VirtualList/VirtualList.config';
import { GetNotificationCenterListingQuery } from 'common/schema/commonSchemaRemoteOperationTypes';

const {
  listingCursor: { getDefaultListingCursor, nextPage, prevPage },
  sortOptions: { generateSortOptionsAndDefault },
} = VirtualList;

export const [, optionsByMostRecent] = generateSortOptionsAndDefault(SortOptionsEnum.MOST_RECENT);
export type ByMostRecentSortOptions = (typeof optionsByMostRecent)[number];
export type ByMostRecentCursor = GetNotificationCenterListingQuery['notifications'][0];

export const cursorsByMostRecent: Record<ByMostRecentSortOptions, [ByMostRecentCursor, ByMostRecentCursor]> = {
  [SortOptionsEnum.MOST_RECENT]: getDefaultListingCursor<ByMostRecentCursor>(
    { updatedAt: prevPage.date.future },
    { updatedAt: nextPage.date.past }
  ),
};

export const optionsForNotificationCenter = [...optionsByMostRecent];
export const [defaultSortForArtistManageArtworks] = generateSortOptionsAndDefault(...optionsForNotificationCenter);
export type NotificationCenterSortOptions = ByMostRecentSortOptions;
export type NotificationCenterCursor = ByMostRecentCursor;

export const cursorsForNotificationCenter: Record<
  NotificationCenterSortOptions,
  [NotificationCenterCursor, NotificationCenterCursor]
> = {
  ...cursorsByMostRecent,
};

export const {
  listSlice: listNotificationCenterSlice,
  getSpecificCursor: getSpecificNotificationCenterCursor,
  getDefaultVirtualList: getDefaultNotificationCenterVirtualList,
} = VirtualList.slices.createListSlice<NotificationCenterSortOptions, NotificationCenterCursor>({
  defaultBatchSizes: {
    listedBatchSize: 128,
    detailedBatchSize: 32,
  },
  allSpecificCursors: cursorsForNotificationCenter,
  virtualListIdentifier: KnownVirtualListEnum.NOTIFICATION_CENTER,
  defaultFilterHash: '',
  defaultSortOption: SortOptionsEnum.MOST_RECENT,
});
