/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Collapse, Popover } from '@material-ui/core';
import React, { CSSProperties, useRef, useState } from 'react';
import { typographyStyles } from 'utils/typographyStyles';
import { CDNUrl } from 'utils/constants';
import clsx from 'clsx';
import useCustomizableDropdownStyles from './CustomizableDropdown.styles';

type CustomizableDropdownProps<T> = {
  label?: string; // label to be displayed above the selected option
  selectedOption: T; // the selected option
  options: T[]; // the options to be displayed in the dropdown
  width: CSSProperties['width']; // width of the dropdown
  withHeaderIcon?: boolean; // whether to display the header icon
  disabled?: boolean; // whether the dropdown is disabled
  onSelect: (value: T) => void; // callback to be called when an option is selected
  keyExtractor: (value: T) => string; // function to extract the key from the option
  renderOption: (value: T) => React.ReactNode; // function to render the option
  renderSelected: (value: T) => React.ReactNode; // function to render the selected option
};
/**
 * CustomizableDropdown component is a dropdown that can be customized with
 * any type of data and any type of rendering for the selected option and the options list.
 */
const CustomizableDropdown = <T extends unknown>(props: CustomizableDropdownProps<T>): JSX.Element => {
  const {
    label,
    selectedOption,
    width,
    withHeaderIcon,
    disabled,
    renderSelected,
    options,
    keyExtractor,
    renderOption,
    onSelect,
  } = props;
  const ref = useRef<HTMLDivElement | null>(null);

  const [open, setOpen] = useState<boolean>(false);

  const headerIcon = `${CDNUrl}/images/${open ? 'artDetailsIcons/newProfileArtShowcase/close' : 'edit'}.svg`;
  const typographyClasses = typographyStyles();
  const classes = useCustomizableDropdownStyles({ open });
  return (
    <div className={classes.root}>
      <div
        ref={ref}
        className={classes.header}
        style={disabled ? { color: 'grey' } : {}}
        onClick={() => !disabled && setOpen(true)}
      >
        <div className={classes.selected}>
          {label && <span className={typographyClasses.fontSize14}>{label}</span>}
          <span>{renderSelected(selectedOption)}</span>
        </div>
        {withHeaderIcon && <img alt="" src={headerIcon} />}
      </div>
      <Popover
        open={open}
        anchorEl={ref.current}
        style={{ width }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        TransitionComponent={Collapse}
        TransitionProps={{ timeout: 500 }}
        onClose={() => setOpen(false)}
        classes={{
          paper: clsx(classes.paper, open ? 'scale-up-tr' : 'scale-down-tr'),
          root: classes.popoverRoot,
        }}
        BackdropProps={{ invisible: false }}
      >
        {options.map((option) => (
          <div
            style={{ cursor: 'pointer' }}
            key={keyExtractor(option)}
            onClick={() => {
              onSelect(option);
              setOpen(false);
            }}
          >
            {renderOption(option)}
          </div>
        ))}
      </Popover>
    </div>
  );
};

export default CustomizableDropdown;
