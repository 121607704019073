import VirtualList from 'common/features/VirtualList/VirtualList';
import { KnownVirtualListEnum, SortOptionsEnum } from 'common/features/VirtualList/VirtualList.config';
import {
  GetArtShowArtworksListingByPriceQuery,
  GetArtShowArtworksListingBySizeQuery,
} from 'common/schema/commonSchemaRemoteOperationTypes';

type RequiredData = { artworkId: number; artistContextId: number };
const placeholder = { artworkId: Number.MAX_SAFE_INTEGER, artistContextId: 0, aspectRatio: 0, isCollected: false };
const {
  listingCursor: { getDefaultListingCursor, nextPage, prevPage },
  sortOptions: { generateSortOptionsAndDefault },
} = VirtualList;

export const [, optionsByPrice] = generateSortOptionsAndDefault(
  SortOptionsEnum.MOST_EXPENSIVE,
  SortOptionsEnum.LEAST_EXPENSIVE
);
export type ByPriceSortOptions = (typeof optionsByPrice)[number];
export type ByPriceCursor = RequiredData & GetArtShowArtworksListingByPriceQuery['artworks'][0];
export const cursorsByPrice: Record<ByPriceSortOptions, [ByPriceCursor, ByPriceCursor]> = {
  [SortOptionsEnum.MOST_EXPENSIVE]: getDefaultListingCursor<ByPriceCursor>(
    { ...placeholder, price: prevPage.bigint.bigger },
    { ...placeholder, price: nextPage.bigint.smaller }
  ),
  [SortOptionsEnum.LEAST_EXPENSIVE]: getDefaultListingCursor<ByPriceCursor>(
    { ...placeholder, price: prevPage.bigint.smaller },
    { ...placeholder, price: nextPage.bigint.bigger }
  ),
};

export const [, optionsBySize] = generateSortOptionsAndDefault(
  SortOptionsEnum.BIGGEST_SIZE,
  SortOptionsEnum.SMALLEST_SIZE
);
export type BySizeSortOptions = (typeof optionsBySize)[number];
export type BySizeCursor = RequiredData & GetArtShowArtworksListingBySizeQuery['artworks'][0];
export const cursorsBySize: Record<BySizeSortOptions, [BySizeCursor, BySizeCursor]> = {
  [SortOptionsEnum.BIGGEST_SIZE]: getDefaultListingCursor<BySizeCursor>(
    { ...placeholder, area: prevPage.int.bigger },
    { ...placeholder, area: nextPage.int.smaller }
  ),
  [SortOptionsEnum.SMALLEST_SIZE]: getDefaultListingCursor<BySizeCursor>(
    { ...placeholder, area: prevPage.int.smaller },
    { ...placeholder, area: nextPage.int.bigger }
  ),
};

export const optionsForArtShowArtworks = [...optionsByPrice, ...optionsBySize];
export const [defaultSortForArtShowArtworks] = generateSortOptionsAndDefault(...optionsForArtShowArtworks);
export type ArtShowArtworksSortOptions = ByPriceSortOptions | BySizeSortOptions;
export type ArtShowArtworksCursor = ByPriceCursor | BySizeCursor;
export const cursorsForArtShowArtworks: Record<
  ArtShowArtworksSortOptions,
  [ArtShowArtworksCursor, ArtShowArtworksCursor]
> = {
  ...cursorsByPrice,
  ...cursorsBySize,
};

export const {
  listSlice: listArtShowArtworksSlice,
  getSpecificCursor: getSpecificArtShowArtworksCursor,
  getDefaultVirtualList: getDefaultArtShowArtworksVirtualList,
} = VirtualList.slices.createListSlice<ArtShowArtworksSortOptions, ArtShowArtworksCursor>({
  defaultBatchSizes: {
    listedBatchSize: 128,
    detailedBatchSize: 32,
  },
  allSpecificCursors: cursorsForArtShowArtworks,
  virtualListIdentifier: KnownVirtualListEnum.ART_SHOW_ARTWORKS,
  defaultFilterHash: '',
  defaultSortOption: defaultSortForArtShowArtworks,
});
