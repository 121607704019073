import React from 'react';
import useAppSelector from 'common/hooks/useAppSelector';
import { Skeleton } from '@material-ui/lab';
import { SwipeContainerComponentsProps } from '.';
import SwipeContainerUnavailableButton from './SwipeContainerUnavailableButton';
import SwipeContainerPrice from './SwipeContainerPrice';
import SwipeContainerCollectedButton from './SwipeContainerCollectedButton';
import SwipeContainerInquiryButton from './SwipeContainerInquiryButton';
import { useSwipeContainerComponentsStyles } from './useSwipeContainerComponents.styles';

const SwipeContainerAvailability: React.FC<SwipeContainerComponentsProps> = ({ artworkId }) => {
  const classes = useSwipeContainerComponentsStyles();
  const { isAvailable, provenance, prices, id, printAmounts } =
    useAppSelector((store) => store.entities.artworkSwipeContainerCache[artworkId]) ?? {};
  const { currency, price, isPublic } = prices?.[0] ?? {};
  const totalAvailablePrints = printAmounts?.reduce((acc, print) => acc + (print.available_print_amount ?? 0), 0);
  const hasAvailablePrint = totalAvailablePrints > 0;

  if (!id) {
    return <Skeleton width={180} height={50} className={classes.skeleton} />;
  }

  if (provenance?.length && !hasAvailablePrint) {
    return <SwipeContainerCollectedButton />;
  }

  if (!isAvailable && !hasAvailablePrint) {
    return <SwipeContainerUnavailableButton />;
  }

  if ((isAvailable && isPublic && !!currency && !!price) || hasAvailablePrint) {
    return <SwipeContainerPrice artworkId={artworkId} />;
  }

  return (
    <div className={classes.extraMarginBottom}>
      <SwipeContainerInquiryButton artworkId={artworkId} />
    </div>
  );
};

export default SwipeContainerAvailability;
