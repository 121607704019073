import { Grid } from '@material-ui/core';
import React from 'react';
import { BasicImageType } from '../../Image/types';

export const TripleImageLayout: React.FC<{
  children: [
    React.ReactElement<BasicImageType>,
    React.ReactElement<BasicImageType>,
    React.ReactElement<BasicImageType>
  ];
}> = ({ children }) => (
  <Grid container spacing={1}>
    <Grid item xs={8} style={{ aspectRatio: '1' }}>
      {children[0]}
    </Grid>
    <Grid item spacing={1} container xs={4} style={{ paddingRight: 0 }}>
      <Grid item xs={12} style={{ aspectRatio: '1', paddingRight: 0 }}>
        {children[1]}
      </Grid>
      <Grid item xs={12} style={{ aspectRatio: '1', paddingRight: 0 }}>
        {children[2]}
      </Grid>
    </Grid>
  </Grid>
);
