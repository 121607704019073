import useAnalytics from 'common/features/Analytics/Analytics';
import { Theme, useMediaQuery } from '@material-ui/core';
import useAppSelector from 'common/hooks/useAppSelector';
import { useOnScreenImpressions } from 'common/hooks/useOnScreen';
import { RenderComponentProps } from 'masonic';
import { useEffect, useMemo, useRef } from 'react';
import AdminWidget from 'stacks/Admin/Tools/AdminWidget/AdminWidget';
import useGalleryInfo from 'stacks/ProfileGallery/hooks/useGalleryInfo';
import { isSomeEnum } from 'utils/utilFunctions';
import { GalleryInfoSection } from '../GalleryInfoSection/GalleryInfoSection';
import ProfileGalleryArtistCard from './ProfileGalleryArtistCard';

export type ProfileGalleryArtistsElementProps = {
  artistContextId: number;
  eventType?: string;
};

export enum ProfileGalleryCardEventsEnum {
  ARTIST = 'ARTIST',
  STATEMENT = 'STATEMENT',
}

const isProfileGalleryCardEventsEnum = isSomeEnum(ProfileGalleryCardEventsEnum);

type ProfileGalleryListElementType = React.ComponentType<RenderComponentProps<ProfileGalleryArtistsElementProps>>;
type ProfileGalleryListEventType = React.ComponentType<
  RenderComponentProps<ProfileGalleryArtistsElementProps> & { isVisible?: boolean }
>;

const CardEventsComponents: Record<ProfileGalleryCardEventsEnum, ProfileGalleryListEventType> = {
  [ProfileGalleryCardEventsEnum.ARTIST]: (props) => <ProfileGalleryArtistCard {...props} />,
  [ProfileGalleryCardEventsEnum.STATEMENT]: ({ width }) => {
    const galleryInfo = useGalleryInfo();
    const isPhone = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));
    return (
      <>
        {width > 400 && !isPhone && (
          <div
            style={{
              // (imgsRatio * (width - (paddings of 4 * 4))) + two paddings of 4 + height of the artist chip
              height: (2 / 3) * (width - 16) + 8 + 58,
              width,
              borderRadius: '4px',
            }}
          >
            <GalleryInfoSection
              galleryImg={galleryInfo.profileLogo || ''}
              galleryStatement={galleryInfo.profileStatement || ''}
            />
          </div>
        )}
      </>
    );
  },
};

const ProfileGalleryListElement: ProfileGalleryListElementType = (props) => {
  const {
    data: { artistContextId, eventType },
    index,
  } = props;
  const currentEventType = useMemo<ProfileGalleryCardEventsEnum>(
    () => (isProfileGalleryCardEventsEnum(eventType) ? eventType : ProfileGalleryCardEventsEnum.ARTIST),
    [eventType]
  );

  const ref = useRef(null);
  const isVisible = useOnScreenImpressions(ref, 0.5);
  const analytics = useAnalytics();

  const EventCard = CardEventsComponents[currentEventType];

  const { artist, artworks } = useAppSelector((store) => store.entities.artistCardsCache[artistContextId]) ?? {};

  useEffect(() => {
    if (artist?.handle && isVisible) {
      analytics.postImpressions({
        hitType: 'SUCCESS',
        eventCategory: 'PROFILE_GALLERY',
        eventAction: 'IMPRESSIONS',
        tags: {},
        impressions: {
          handle: artist.handle,
          artworks: [artworks[0]?.id, artworks[1]?.id, artworks[2]?.id],
          eventTimestamp: new Date().getTime(),
        },
      });
    }
  }, [isVisible, artist, artworks]);

  return (
    <div style={{ position: 'relative' }} ref={ref}>
      {(window as any).showScrollIndex === true && (
        <h3 style={{ position: 'absolute', color: 'yellowgreen' }}>{`[${index}] ${artistContextId}`}</h3>
      )}
      <AdminWidget artistContextId={artist?.contextId ?? undefined} />
      <EventCard {...props} />
    </div>
  );
};

export default ProfileGalleryListElement;
