import { TypeGuard } from 'common/utils/types';
import { getDefaultListingCursor } from './listingCursor';
import { defaultListLoader, getDefaultListLoader, ListLoader } from './listLoader';

export type ListState<CursorType> = {
  listed: ListLoader<CursorType>;
  detailed: ListLoader<CursorType>;
  cursor: [CursorType, CursorType]; // [backward, forward]
  detailedUntilListedIndex: number;
};
export const generateListStateTypeGuard =
  <CursorType>(
    possibleValuesCheck: (castedValues: ListState<CursorType>) => boolean
  ): TypeGuard<ListState<CursorType>> =>
  (possibleListState): possibleListState is ListState<CursorType> => {
    if (!possibleListState) {
      return false;
    }
    return possibleValuesCheck(possibleListState as ListState<CursorType>);
  };
export const defaultListState: ListState<unknown> = {
  listed: { ...defaultListLoader },
  detailed: { ...defaultListLoader },
  cursor: [undefined, undefined],
  detailedUntilListedIndex: 0,
};
type Props<CursorType> = {
  listedBatchSize: number;
  detailedBatchSize: number;
  backwardCursor: CursorType;
  forwardCursor: CursorType;
};
export const getDefaultListState = <CursorType>({
  listedBatchSize = 32,
  detailedBatchSize = 128,
  backwardCursor,
  forwardCursor,
}: Props<CursorType>): ListState<CursorType> => ({
  ...defaultListState,
  listed: getDefaultListLoader<CursorType>(listedBatchSize),
  detailed: getDefaultListLoader<CursorType>(detailedBatchSize),
  cursor: getDefaultListingCursor(backwardCursor, forwardCursor),
});
