import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { ListReduxFragment } from '../components/listReduxFragment';
import { KnownVirtualListEnum, SortOptionsEnum } from '../VirtualList.config';

export type ColdResetReducer<State extends ListReduxFragment<SortOptionsEnum, unknown>> = CaseReducer<
  State,
  PayloadAction<{
    list: KnownVirtualListEnum;
  }>
>;

export const createColdResetVirtualListReducer =
  <State extends ListReduxFragment<SortOptionsEnum, unknown>>(
    virtualListIdentifier: KnownVirtualListEnum,
    getDefaultListState: () => State
  ): ColdResetReducer<State> =>
  (_, { payload }) => {
    if (payload?.list !== virtualListIdentifier) {
      return;
    }
    getDefaultListState();
  };
