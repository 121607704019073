import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { BoostSkewFilterState } from './boostSkew.types';

export type SetBoostSkewAction = PayloadAction<BoostSkewFilterState>;

export const createSetBoostSkewCaseReducer =
  <State extends BoostSkewFilterState>(): CaseReducer<State, SetBoostSkewAction> =>
  (state, action) => {
    state.isBoostSkewed = action.payload.isBoostSkewed;
  };
