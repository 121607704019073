import { makeStyles, Typography } from '@material-ui/core';
import Links from 'common/components/Links/Links';
import KaleidoAvatar from 'common/components/KaleidoAvatar/KaleidoAvatar';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import ProvenanceLine from './ProvenanceLine';
import { MONTHSTRINGS } from './utils';

const customStyle = makeStyles((theme) => ({
  image: {
    objectFit: 'contain',
    height: '40px',
    width: '40px',
    border: '2px solid transparent',
  },
  colorDefault: {
    border: theme.palette.common.black === 'black' ? '4px solid white !important' : '4px solid black !important',
    zIndex: -10000,
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    textAlign: 'left',

    '& h4': {
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '18px',
      marginBottom: 4,
      color: theme.palette.common.black === '#0D0D0D' ? 'rgba(0, 0, 0, 0.64)' : 'rgba(255, 255, 255, 0.64)',
    },

    '& h5': {
      fontWeight: '400',
      fontSize: '16px',
      lineHeight: '20px',
      color: theme.palette.common.black === '#0D0D0D' ? 'black' : 'white',

      [theme.breakpoints.down('xs')]: {
        fontSize: '14px',
      },
    },
  },
}));

type ProvenanceItemProps = {
  image: string;
  topText: string;
  bottomText: string;
  to?: string;
};

const ProvenanceItem: React.FC<ProvenanceItemProps> = ({ image, topText, bottomText, to = '#' }) => {
  const customClasses = customStyle();
  const { t } = useTranslation();

  const formated = useMemo(() => {
    const dateParts = topText.split('-');
    // If it only has year
    if (dateParts.length === 1) {
      return topText;
    }

    const year = dateParts[0];
    const month = Number(dateParts[1]);
    const day = dateParts[2];
    let monthName = '';
    if (month) {
      monthName = MONTHSTRINGS[month - 1];
    }

    if (day && monthName) {
      return `${t(monthName)} ${day}, ${year}`;
    }
    return `${t(monthName)}, ${year}`;
  }, [topText, t]);

  return (
    <div className={customClasses.container}>
      <div style={{ marginRight: 16 }}>
        <ProvenanceLine />
        <Links.UnstyledAnchor style={{ cursor: to === '#' ? 'auto' : '' }} to={to}>
          <KaleidoAvatar className={customClasses.image} src={image} />
        </Links.UnstyledAnchor>
        <ProvenanceLine />
      </div>

      <div>
        <Typography variant="h4">{formated}</Typography>
        <Typography variant="h5">
          <Links.UnstyledAnchor style={{ cursor: to === '#' ? 'auto' : '' }} to={to}>
            {bottomText}
          </Links.UnstyledAnchor>
        </Typography>
      </div>
    </div>
  );
};

export default ProvenanceItem;
