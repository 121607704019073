import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface RegisterState {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  language: string;
  isPseudoUser: boolean;
}

const initialState: RegisterState = {
  email: '',
  password: '',
  firstName: '',
  lastName: '',
  language: '',
  isPseudoUser: false,
};

const registerSlice = createSlice({
  name: 'register',
  initialState,
  reducers: {
    RESET: (state) => {
      state.email = '';
      state.password = '';
      state.firstName = '';
      state.lastName = '';
      state.language = '';
      state.isPseudoUser = false;
    },
    SET_REGISTER_SUCCESS: (state, action: PayloadAction<RegisterState>) => {
      state.email = action.payload.email;
      state.password = action.payload.password;
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
      state.language = action.payload.language;
      state.isPseudoUser = action.payload.isPseudoUser;
    },
    SET_EMAIL: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
  },
});

export const registerActions = registerSlice.actions;
export default registerSlice;
