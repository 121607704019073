import SwipeContainerAvailability from './SwipeContainerAvailability';
import SwipeContainerDetails from './SwipeContainerDetails';
import SwipeContainerTitle from './SwipeContainerTitle';

export type SwipeContainerComponentsProps = {
  artworkId: number;
};

export const SwipeContainerComponents = {
  Title: SwipeContainerTitle,
  AvailabilityButton: SwipeContainerAvailability,
  Details: SwipeContainerDetails,
};
