import React, { useEffect, useMemo, useRef } from 'react';
import { getAbbreviation, isSomeEnum } from 'utils/utilFunctions';
import { RenderComponentProps } from 'masonic';
import { useOnScreenImpressions } from 'common/hooks/useOnScreen';
import useAnalytics from 'common/features/Analytics/Analytics';
import AdminWidget from 'stacks/Admin/Tools/AdminWidget/AdminWidget';
import useAppSelector from 'common/hooks/useAppSelector';
import { LayoutType } from 'stacks/Dashboard/common/ProfileAppearance/context/ProfileAppearanceSettingsProvider';
import { useCollectorProfileSlice } from 'store/slices/collectorProfile';
import { ProfileCollectorArtworkCard } from './ProfileCollectorArtworkCard';
import { StartImageCardV2 } from '../cards/startcard/StartImageCardV2';

export enum ProfileCollectorCardEventsEnum {
  ARTWORK = 'ARTWORK',
  FIRST_CARD = 'FIRST_CARD',
}

export type ProfileCollectorCardProps = {
  artworkId: number;
  eventType?: ProfileCollectorCardEventsEnum;
  columnCount: number;
  onCardClick?: () => void;
};

const isProfileCollectorCardEventsEnum = isSomeEnum(ProfileCollectorCardEventsEnum);

type ProfileCollectorListElementType = React.ComponentType<RenderComponentProps<ProfileCollectorCardProps>>;
type ProfileCollectorListEventType = React.ComponentType<
  RenderComponentProps<ProfileCollectorCardProps> & { isVisible: boolean; isSquare?: boolean }
>;

const CardEventsComponents: Record<ProfileCollectorCardEventsEnum, ProfileCollectorListEventType> = {
  [ProfileCollectorCardEventsEnum.ARTWORK]: (props) => <ProfileCollectorArtworkCard {...props} />,
  [ProfileCollectorCardEventsEnum.FIRST_CARD]: ({ width }) => {
    const { collectorData, filters, totalArtworks } = useCollectorProfileSlice();

    return (
      <div style={{ height: width + 58, width }}>
        <StartImageCardV2
          name={collectorData.name}
          abreviation={getAbbreviation(collectorData.name, '/')}
          artists={filters.artistsContextIds.length - 1}
          artworks={totalArtworks}
          location={collectorData.location}
          height={width + 58 - 64}
          width={width - 64}
        />
      </div>
    );
  },
};

export const ProfileCollectorArtworkListElement: ProfileCollectorListElementType = (props) => {
  const {
    data: { artworkId, eventType, columnCount },
    index,
    width,
  } = props;
  const currentEventType = isProfileCollectorCardEventsEnum(eventType)
    ? eventType
    : ProfileCollectorCardEventsEnum.ARTWORK;

  const ref = useRef(null);
  const isVisible = useOnScreenImpressions(ref, 0.25);
  const wasVisible = useRef(index < columnCount * 2 - 1);
  const analytics = useAnalytics();
  const { collectorData } = useCollectorProfileSlice();

  useEffect(() => {
    if (isVisible) {
      wasVisible.current = true;
    }
  }, [isVisible]);

  const EventCard = useMemo(() => CardEventsComponents[currentEventType], [currentEventType]);

  const { artist, artwork } = useAppSelector((store) => store.entities.artworkCardsCache[artworkId]) ?? {};

  useEffect(() => {
    if (artist?.handle && isVisible) {
      analytics.postImpressions({
        hitType: 'SUCCESS',
        eventCategory: 'PROFILE_COLLECTOR',
        eventAction: 'IMPRESSIONS',
        tags: {},
        impressions: {
          handle: artist.handle,
          artworks: [artwork?.artworkId],
          eventTimestamp: new Date().getTime(),
        },
      });
    }
  }, [isVisible, artist, artwork]);

  return (
    <div style={{ position: 'relative', width, height: width + 60 }} ref={ref}>
      {(window as any).showScrollIndex === true && (
        <h3 style={{ position: 'absolute', color: 'yellowgreen' }}>{`[${index}] ${artworkId}`}</h3>
      )}
      <AdminWidget artistContextId={artist?.contextId ?? undefined} />
      <EventCard
        {...props}
        isSquare={collectorData?.appearanceSettings?.layoutType === LayoutType.GRID}
        isVisible={wasVisible.current || isVisible}
      />
    </div>
  );
};
