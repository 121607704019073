import { TypeGuard } from 'common/utils/types';
import { isSomeEnum } from 'utils/utilFunctions';
import { SortOptionsEnum } from '../VirtualList.config';

export const isSortOptionsEnum: TypeGuard<SortOptionsEnum> = isSomeEnum(SortOptionsEnum);

export const generateSortOptions = <SortSubset extends readonly SortOptionsEnum[]>(
  ...options: SortSubset
): readonly [...SortSubset] => options;

export const generateSortOptionsAndDefault = <SortSubset extends readonly SortOptionsEnum[]>(
  ...sortOptions: SortSubset
): readonly [SortSubset[number], readonly [...SortSubset]] => [sortOptions[0], sortOptions];

export const generateSortOptionTypeGuard =
  <SortSubset extends SortOptionsEnum>(...values: SortSubset[]): TypeGuard<SortSubset> =>
  (sort): sort is SortSubset =>
    !!sort && values.includes(sort as SortSubset);
