import {
  GetArtistFilteredArtworksLazyQueryHookResult,
  GetShowroomFilteredArtworksLazyQueryHookResult,
  GetCollectorFilteredArtworksLazyQueryHookResult,
  GetFavoritesFilteredArtworksLazyQueryHookResult,
} from 'common/schema/commonSchemaRemoteGraphqlQueries';
import {
  GetArtistFilteredArtworksQuery,
  GetArtistFilteredArtworksQueryVariables,
  GetShowroomFilteredArtworksQuery,
  GetShowroomFilteredArtworksQueryVariables,
  GetCollectorFilteredArtworksQuery,
  GetCollectorFilteredArtworksQueryVariables,
  LocalCommonModifiersRequiredFieldsFragment,
  GetFavoritesFilteredArtworksQuery,
  GetFavoritesFilteredArtworksQueryVariables,
} from 'common/schema/commonSchemaRemoteOperationTypes';
import { ArtworksListType } from 'store/slices/artworkListModifiers';
import { Artwork, Artwork_Bool_Exp } from '../../../schema/commonSchemaRemoteTypes';

export interface FilteredArtworkQueryHandlers {
  [ArtworksListType.SHOWROOM]: {
    queryHandler: GetShowroomFilteredArtworksLazyQueryHookResult;
    queryArtworkType: GetShowroomFilteredArtworksQuery['Artwork'][0];
    queryVariables: Omit<GetShowroomFilteredArtworksQueryVariables, 'where'>;
  };
  [ArtworksListType.COLLECTOR_PROFILE]: {
    queryHandler: GetCollectorFilteredArtworksLazyQueryHookResult;
    queryArtworkType: GetCollectorFilteredArtworksQuery['Artwork'][0];
    queryVariables: Omit<GetCollectorFilteredArtworksQueryVariables, 'where'>;
  };
  [ArtworksListType.ARTIST_PROFILE]: {
    queryHandler: GetArtistFilteredArtworksLazyQueryHookResult;
    queryArtworkType: GetArtistFilteredArtworksQuery['Artwork'][0];
    queryVariables: Omit<GetArtistFilteredArtworksQueryVariables, 'where'>;
  };
  [ArtworksListType.FAVORITES]: {
    queryHandler: GetFavoritesFilteredArtworksLazyQueryHookResult;
    queryArtworkType: GetFavoritesFilteredArtworksQuery['Artwork'][0];
    queryVariables: Omit<GetFavoritesFilteredArtworksQueryVariables, 'where'>;
  };
}

export const FILTERS_DEFAULT_VALUE = 0;

type BaseOptionType = {
  name: string;
  value: number;
  key: string;
  payload: unknown;
};

export type BaseOptionValueType = BaseOptionType['value'];

export type ARTWORK_QUERY_CONDITION = BaseOptionType & {
  key: 'ARTWORK_QUERY_CONDITION';
  payload: Artwork_Bool_Exp;
};

export type ARTWORK_QUERY_CONDITION_IN = BaseOptionType & {
  key: 'ARTWORK_QUERY_CONDITION_IN';
  payload: Artwork_Bool_Exp;
};

export type ARTWORK_LOCAL_CONDITION_PAYLOAD = (value: LocalCommonModifiersRequiredFieldsFragment) => boolean;

export type ARTWORK_LOCAL_CONDITION = BaseOptionType & {
  key: 'ARTWORK_LOCAL_CONDITION';
  payload: ARTWORK_LOCAL_CONDITION_PAYLOAD;
};

export type ARTWORK_LOCAL_SORT_PAYLOAD = (
  a: LocalCommonModifiersRequiredFieldsFragment,
  b: LocalCommonModifiersRequiredFieldsFragment
) => number;

export type ARTWORK_LOCAL_SORT = BaseOptionType & {
  key: 'ARTWORK_LOCAL_SORT';
  payload: ARTWORK_LOCAL_SORT_PAYLOAD;
};

export const isOptionType = <T extends BaseOptionType>(option: BaseOptionType, type: T['key']): option is T =>
  option.key === type;

export type filterTypes =
  | 'SERIES'
  | 'YEARS'
  | 'ORIENTATION'
  | 'SORT'
  | 'AVAILABILITY'
  | 'ARTIST'
  | 'PRICE'
  | 'PRIZES'
  | 'GALLERIES';

export interface FiltersArtworkType extends Partial<Artwork> {
  order?: number;
  price?: number;
}

export type ListingModifier = {
  type: filterTypes;
  allOptions: ListingModifierOption[];
};

export type ListingModifierOption =
  | ARTWORK_QUERY_CONDITION
  | ARTWORK_QUERY_CONDITION_IN
  | ARTWORK_LOCAL_CONDITION
  | ARTWORK_LOCAL_SORT;

export type ListingModifierButton = {
  specialWidth?: 'FULL' | 'HALF';
  image?: string;
  dot?: 'AVAILABLE' | 'UNAVAILABLE' | 'COLLECTED';
};

export type ListingModifierButtonsConfig = { [optionName: string]: ListingModifierButton };

export type AppliedListingModifier<T extends ListingModifierOption = ListingModifierOption> = {
  selectedOptions: ListingModifierOption['value'][];
  selectionType: 'SINGLE' | 'MULTIPLE';
  flattenSelectedOptions: (options: ListingModifierOption[], selectedOptions: ListingModifierOption['value'][]) => T;
} & ListingModifier;
