import { useShouldShowFeatureFlag } from 'common/features/FeatureFlag/hooks/useFeatureFlag';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { buyModalActions } from 'store/slices/buyModal';

const useBuyModalActions = () => {
  const [shouldShowBuyModal] = useShouldShowFeatureFlag('buyModal');
  const dispatch = useDispatch();

  const closeBuyModal = useCallback(() => {
    if (!shouldShowBuyModal) {
      return;
    }

    dispatch(buyModalActions.RESET_STATE());
  }, [shouldShowBuyModal]);

  const openBuyModal = useCallback(
    (artworkId?: number) => {
      if (!shouldShowBuyModal) {
        return;
      }
      if (artworkId) {
        dispatch(buyModalActions.SET_ARTWORK_ID(artworkId));
      }
      dispatch(buyModalActions.OPEN_BUY_MODAL());
    },
    [shouldShowBuyModal]
  );
  const setArtworkId = useCallback(
    (artworkId: number) => {
      if (!shouldShowBuyModal) {
        return;
      }
      dispatch(buyModalActions.SET_ARTWORK_ID(artworkId));
    },
    [shouldShowBuyModal]
  );

  return { closeBuyModal, openBuyModal, setArtworkId, shouldShowBuyModal };
};

export default useBuyModalActions;
