import React from 'react';
import { Grid } from '@material-ui/core';
import { BasicImageTypePropsExtra } from 'common/components/Image/types';
import ArtworkCardComponents from '../common';
import ArtCollectedInfoPreview, { ArtCollectedPreviewChipProps } from './components/ArtCollectedInfoPreview';
import { ArtworkInformationProps } from '../common/ArtworkInformation';
import BadgePreview from '../common/BadgePreview';

export type ArtCollectedCardPreviewProps = {
  isSquare?: boolean;
  imageProps?: BasicImageTypePropsExtra<{
    aspectRatio?: React.CSSProperties['aspectRatio'];
    blur?: number;
  }>;
  artCollectedChipProps: ArtCollectedPreviewChipProps;
  artworkInfoProps: ArtworkInformationProps;
};

export const ArtCollectedCardPreview: React.FC<ArtCollectedCardPreviewProps> = React.memo(
  ({ isSquare, imageProps, artCollectedChipProps, artworkInfoProps }) => (
    <ArtworkCardComponents.SwipeCardTopBottomLayout>
      <BadgePreview />
      <ArtworkCardComponents.ImageInfoLayout>
        <ArtworkCardComponents.ArtworkInformation {...(artworkInfoProps ?? {})} />
        {!isSquare ? (
          <ArtworkCardComponents.SoloAspectRatioImage {...(imageProps ?? {})} />
        ) : (
          <ArtworkCardComponents.SoloSquareImage {...(imageProps ?? {})} />
        )}
      </ArtworkCardComponents.ImageInfoLayout>
      <ArtworkCardComponents.FooterLayout>
        <Grid item style={{ width: '100%' }}>
          <ArtCollectedInfoPreview {...(artCollectedChipProps ?? {})} />
        </Grid>
      </ArtworkCardComponents.FooterLayout>
    </ArtworkCardComponents.SwipeCardTopBottomLayout>
  )
);
