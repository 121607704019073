export type SingleAudioSourceType = {
  name: string;
  artistTitle: string;
  subtitle: 'audioWidget.artist.artist.title' | 'audioWidget.artist.artwork.title';
  source: string;
  hash: string;
  duration: number;
};

export type AudioPlaylist = {
  id: string;
  audios: string[];
};

export const getAudioHash = (audio: SingleAudioSourceType): string =>
  [audio.name, audio.artistTitle, audio.subtitle, audio.source].join('-');

const PLAYLIST_NAME_SEPARATOR = ':::';
export const buildPlaylistName = (...parts: string[]): string => parts.join(PLAYLIST_NAME_SEPARATOR);
export const temporaryPlaylistName = (playlistName: string): string => buildPlaylistName(playlistName, 'temporary');
export const splitPlaylistName = (hash: string): string[] => hash.split(PLAYLIST_NAME_SEPARATOR);
export const getPlaylistNameOverlap = (playlist1: string, playlist2: string): number => {
  const [playlist1Parts, playlist2Parts] = [playlist1, playlist2].map(splitPlaylistName);
  let overlap = 0;
  while (playlist1Parts[overlap] === playlist2Parts[overlap] && playlist1Parts[overlap] && playlist2Parts[overlap]) {
    overlap += 1;
  }
  return overlap;
};
