import { CountryFilterState } from 'common/features/Filters/predefined/Country/store/country.types';
import { TitleFilterState } from 'common/features/Filters/predefined/Title/store/title.types';

export const sorts = ['byTitle', 'byId', 'byDate'] as const;
export type ProfileGallerySort = (typeof sorts)[0] | (typeof sorts)[1] | (typeof sorts)[2];

export const isProfileGallerySort = (v: string): v is ProfileGallerySort => sorts.includes(v as ProfileGallerySort);

// currently auto-applies on every change
// maybe todo: add flag to apply on demand
export type ProfileGalleryFiltersState = CountryFilterState &
  TitleFilterState & {
    sort: ProfileGallerySort;
  };
