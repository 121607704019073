import { getDefaultTagFilter } from './tags.default';
import { TagsFilterState } from './tags.types';

export const tagFilterHash = (option: TagsFilterState['tags']): string => option ?? 'anyt';

const SERIALIZATION_KEY = 'tags';

export const tagsFilterSerialize = (state: TagsFilterState): string => {
  const serialized = new URLSearchParams();
  serialized.set(SERIALIZATION_KEY, state.tags ?? '');
  return serialized.toString();
};

export const tagsFilterDeserialize = (serializedFilters: string): TagsFilterState => {
  const serialized = new URLSearchParams(serializedFilters);
  const tagOption = serialized.get(SERIALIZATION_KEY);
  const tagsFilter = getDefaultTagFilter();
  if (!tagOption) {
    return tagsFilter;
  }
  tagsFilter.tags = tagOption;
  return tagsFilter;
};
