import { Typography, TypographyProps, TypographyTypeMap } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

export type TypographyTextType = TypographyProps<
  TypographyTypeMap['defaultComponent'],
  {
    text?: string;
    suffix?: React.ReactNode;
    prefixText?: React.ReactNode;
  }
>;

export const SkeletonText: React.FC<
  React.PropsWithChildren<
    TypographyTextType & {
      skeletonStyle?: React.CSSProperties;
    }
  >
> = ({ children, text, suffix, prefixText, skeletonStyle, ...typographyProps }) => {
  if ((text === undefined || text === null) && (children === undefined || children === null)) {
    return <Skeleton variant="text" animation="wave" style={{ maxWidth: '50%', ...skeletonStyle }} />;
  }

  if (children) {
    return (
      <Typography {...typographyProps}>
        {prefixText}
        {children}
        {suffix}
      </Typography>
    );
  }

  return (
    <Typography {...typographyProps}>
      {prefixText}
      {text}
      {suffix}
    </Typography>
  );
};
