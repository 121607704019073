import { apolloClient } from 'common/features/Apollo/clients/apolloClients';
import { useCustomDomain } from 'common/hooks/useCustomDomain';
import useAsyncState from 'common/hooks/utils/useAsyncState';
import { GetArtUniverseArtistsDocument } from 'common/schema/commonSchemaRemoteGraphqlQueries';
import {
  GetArtUniverseArtistsQuery,
  GetArtUniverseArtistsQueryVariables,
} from 'common/schema/commonSchemaRemoteOperationTypes';
import { useEffect } from 'react';
import { IS_CUSTOM_DOMAINS_APP } from 'utils/constants';

/**
 *
 * THIS IS A TEMPORARY SOLUTION
 * THIS FILE NEEDS TO BE DELETED AND THE CHECK IMPLEMENTED CORRECTLY
 *
 */

type ArtUniverseArtistsCache = {
  contextIds: Set<number>;
  profileIds: Set<number>;
  initialization: {
    status: 'NOT_STARTED' | 'IN_PROGRESS' | 'COMPLETE';
    promise: Promise<void>;
    start: () => Promise<void>;
  };
};

const cacheResolver = { function: () => undefined as void };

const cache: ArtUniverseArtistsCache = {
  contextIds: new Set(),
  profileIds: new Set(),
  initialization: {
    status: 'NOT_STARTED',
    promise: new Promise((resolve) => {
      cacheResolver.function = resolve;
    }),
    start: async () => {
      try {
        const result = await apolloClient.query<GetArtUniverseArtistsQuery, GetArtUniverseArtistsQueryVariables>({
          query: GetArtUniverseArtistsDocument,
        });
        result.data?.listing.forEach((listing) => {
          if (listing.contextId) {
            cache.contextIds.add(listing.contextId);
          }
          if (listing.profileId) {
            cache.profileIds.add(listing.profileId);
          }
        });
      } catch (err) {
        console.log("Couldn't fetch art universe artists", err);
      } finally {
        cache.initialization.status = 'COMPLETE';
      }
      cacheResolver.function();
    },
  },
};

export const initializeIsArtUniverseRelatedCache = (): void => {
  const isCustomDomain = IS_CUSTOM_DOMAINS_APP;
  if (isCustomDomain) {
    cache.initialization.status = 'COMPLETE';
    return;
  }

  if (cache.initialization.status === 'NOT_STARTED') {
    cache.initialization.status = 'IN_PROGRESS';
    cache.initialization.start();
  }
};

type IsArtUniverseRelaterParams = {
  contextId?: number;
  profileId?: number;
  cdnProfileRelatedUrl?: string; // can be avatar, banner, etc from the "Profile" CDN target
};

type IsArtUniverseRelatedFunc = (props: IsArtUniverseRelaterParams) => boolean | Promise<boolean>;

const PROFILE_URL_CHUNK = '/Profile/';

const isArtUniverseRelated: IsArtUniverseRelatedFunc = (props) => {
  if (cache.initialization.status !== 'COMPLETE') {
    return (async () => {
      await cache.initialization.promise;
      if (cache.initialization.status !== 'COMPLETE') {
        return false;
      }
      return await isArtUniverseRelated(props);
    })();
  }

  const { contextId, profileId, cdnProfileRelatedUrl } = props;
  if (contextId) {
    return cache.contextIds.has(contextId);
  }
  if (profileId) {
    return cache.profileIds.has(profileId);
  }
  if (cdnProfileRelatedUrl) {
    // extract profile id from avatar url
    const profileUrlIdPrefixPosition = cdnProfileRelatedUrl.indexOf(PROFILE_URL_CHUNK);
    if (profileUrlIdPrefixPosition === -1) {
      return false;
    }
    const startPosition = profileUrlIdPrefixPosition + PROFILE_URL_CHUNK.length;
    const endPosition = cdnProfileRelatedUrl.indexOf('/', startPosition);
    const profileIdStr = cdnProfileRelatedUrl.substring(startPosition, endPosition);
    return cache.profileIds.has(Number(profileIdStr));
  }
  return false;
};

export const useIsArtUniverseRelated = ({ src, contextId }: { src?: string; contextId?: number }): boolean => {
  const [isOnArtUniverse, setIsOnArtUniverse] = useAsyncState(false);
  const isCustomDomain = !!useCustomDomain();
  useEffect(() => {
    (async () => {
      setIsOnArtUniverse(
        await isArtUniverseRelated({
          cdnProfileRelatedUrl: src,
          contextId,
        })
      );
    })();
  }, [src, contextId, isCustomDomain, setIsOnArtUniverse]);
  return isCustomDomain ? false : isOnArtUniverse;
};

export default isArtUniverseRelated;
