import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { TitleFilterState } from './title.types';

type FilterTitleAction = PayloadAction<TitleFilterState['title']>;
export const createFilterTitleCaseReducer =
  <State extends TitleFilterState>(): CaseReducer<State, FilterTitleAction> =>
  (state, action) => {
    if (state.title === action.payload) {
      return;
    }
    state.title = action.payload;
  };
