import { createSlice } from '@reduxjs/toolkit';
import { ROUTES } from 'routes/_definition';
import { TopNavbarModuleProps, MODULES } from '../utils/TopNavbarTypes';

type PropsWithKey<T extends MODULES> = TopNavbarModuleProps[T] & {
  key: string;
};
export type TopNavbarState = {
  modules: {
    // Ideally we would make the modules Optional, but I was unable to make it work due to typescript error
    [Module in MODULES]?: {
      [Route in ROUTES]?: PropsWithKey<Module> | null;
    };
  };
};
export const topNavbarInitialState: TopNavbarState = {
  modules: {},
};
export const topNavbarSlice = createSlice({
  name: 'topnavbar',
  initialState: topNavbarInitialState,
  reducers: {
    MOUNT_MODULE: <T extends MODULES>(
      state: TopNavbarState,
      action: {
        payload: {
          module: T;
          page: string;
          props: TopNavbarModuleProps[T];
        };
      }
    ) => {
      const { module, page, props } = action.payload;
      state.modules[module] = {
        ...state.modules[module],
        [page]: {
          ...props,
          key: module,
        },
      };
    },
  },
});
export const TopNavbarActions = topNavbarSlice.actions;
