import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GetRecognizeModalDetailsQuery } from 'common/schema/commonSchemaRemoteOperationTypes';
import { Enum_Context_Type_Enum, Scalars } from 'common/schema/commonSchemaRemoteTypes';

export type RecognizeModalCardDataType = {
  user: {
    type: Enum_Context_Type_Enum;
    contextId?: number | null;
    profileId?: number | null;
    title?: string | null;
    handle?: string | null;
    avatar?: number | null;
    firstName?: string | null;
    lastName?: string | null;
    isPublic?: string | null;
  };
  artworks?: GetRecognizeModalDetailsQuery['Artworks'];
};

type RecognizeModalCardEntities = Record<Scalars['bigint'], RecognizeModalCardDataType>;

const initialState: RecognizeModalCardEntities = {};

export const recognizeModalCardsSlice = createSlice({
  name: 'recognizeModalCardsCache',
  initialState,
  reducers: {
    UPDATE_RECOGNIZE_MODAL_CARDS: (state, action: PayloadAction<RecognizeModalCardDataType[]>) => {
      action.payload.forEach((card) => {
        if (!card.user.contextId || state[card.user.contextId]) {
          return;
        }
        state[card.user.contextId] = card;
      });
    },
  },
});
