import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GetArtShowArtworkInfoQuery } from 'common/schema/commonSchemaRemoteOperationTypes';
import _ from 'lodash';

type ArtworkCollectedByEntities = Record<string, number>;

const initialState: ArtworkCollectedByEntities = {};

export const artworkCollectedBySlice = createSlice({
  name: 'artworkCollectedByCache',
  initialState,
  reducers: {
    UPDATE_ARTWORK_COLLECTED_BY: (state, action: PayloadAction<GetArtShowArtworkInfoQuery['collectedDetails']>) => {
      const distinctArtworkCollectedBy = _.chain(action.payload)
        .orderBy('publishedDate', 'desc')
        .uniqBy('artworkId')
        .value();
      distinctArtworkCollectedBy.forEach((artwork) => {
        if (!artwork.collector?.contextId || !artwork.artworkId) {
          return;
        }
        state[artwork.artworkId] = artwork.collector.contextId;
      });
    },
  },
});
