import { LinkProps } from 'react-router-dom';
import { BasicImageType } from '../../Image/types';
import Links from '../../Links/Links';
import { SoloImage } from './SoloImage';

export const SoloClickableImage: BasicImageType<{ linkProps: LinkProps }> = ({ linkProps, ...basicCardProps }) => (
  <Links.UnstyledAnchor {...linkProps}>
    <SoloImage {...basicCardProps} />
  </Links.UnstyledAnchor>
);
