import { useMemo } from 'react';
import useAppSelector from 'common/hooks/useAppSelector';
import { useSelectedProfile } from 'common/hooks/useSelectedProfile';
import FavoritesButton from 'common/features/FavoritesButton/FavoritesButton';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import FollowButton from 'common/components/RecognizeButtonV2/components/FollowButton';
import { Enum_Social_Graph_Followers_State_Enum } from 'common/schema/commonSchemaRemoteTypes';
import { GenericArtworkCardProps } from '../GenericArtworkCard/GenericArtworkCard';
import useGetSwipeContainerProps from '../common/SwipeInfoContainer/hooks/useGetSwipeContainerProps';
import { useArtistProfileTargetLink, useArtworkShowcaseTargetLink } from './common/cardTargetLinksPropsHooks';
import { getAspectArtworkImageSrc, getProfileAvatarSrc, getSquareArtworkImageSrc } from './common/imageGetters';
import { getArtistLocationText, getArtistTitleText } from './common/typographyTextTypeGetters';
import { getKnownAspectRatio, squareAspectRatio } from './common/aspectRatio';
import getAvailabilityDot from './common/getAvailabilityDot';
import useGetArtworkDetails from './common/useGetArtworkDetails';

const artworkInfoCTAStyle: React.CSSProperties = {
  width: '37.5px',
  marginBottom: '2px',
  alignSelf: 'flex-end',
  paddingLeft: '1.5px',
  marginRight: '-1.5px',
};

const useGenericCardProps = (
  artworkId: number,
  width: number,
  columnCount: number,
  index: number,
  aspectRatio?: number,
  isSquare?: boolean
): GenericArtworkCardProps => {
  const { t } = useTranslation();
  const hasOnlyOneColumn = columnCount === 1;
  const hasSmallCTA = width <= 600;
  const currentContextId = useSelectedProfile()?.contextId;
  const { artwork, artist } = useAppSelector((store) => store.entities.artworkCardsCache[artworkId]) ?? {};
  const isRecognizing = useAppSelector(
    (store) =>
      store.entities.recognitionsCache.outgoing[artist?.contextId ?? 0]?.state ===
      Enum_Social_Graph_Followers_State_Enum.Active
  );

  // card top and bottom links
  const artistProfileLinkProps = useArtistProfileTargetLink(artist?.handle);
  const artworkShowcaseLinkProps = useArtworkShowcaseTargetLink(
    artist?.handle,
    artwork?.slug,
    artworkId,
    artwork?.primaryMetadataId
  );

  // artwork info cta
  const isFavorite = useAppSelector(
    (store) =>
      !!store.favorites.favorites.find((v) => v.artworkId === artwork?.artworkId && v.contextId === currentContextId)
  );
  // swipe container props
  const swipeContainerProps = useGetSwipeContainerProps(index, artwork?.artworkId ?? 0);

  // artist and artwork data is available
  const hasArtist = !!artist;
  const hasArtwork = !!artwork;

  const artworkDetails = useGetArtworkDetails(artwork);

  return useMemo<GenericArtworkCardProps>(() => {
    // artwork card image
    const shouldBeSquare = isSquare === undefined ? hasOnlyOneColumn : isSquare;
    const squareImageSrc = getSquareArtworkImageSrc(
      hasOnlyOneColumn ? 'large' : 'medium',
      artwork?.artworkId,
      artwork?.primaryMetadataId
    );
    const aspectImageSrc = getAspectArtworkImageSrc(
      hasOnlyOneColumn ? 'large' : 'medium',
      artwork?.artworkId,
      artwork?.primaryMetadataId
    );
    const finalAspectRatio = shouldBeSquare ? squareAspectRatio : getKnownAspectRatio(aspectRatio);
    const finalSrc = shouldBeSquare ? squareImageSrc : aspectImageSrc;
    const avatarSize = hasSmallCTA ? '40px' : '40px';
    const availabilityDot = getAvailabilityDot(
      artwork?.lastTransactionId,
      artwork?.isAvailableSale,
      artwork?.editionIsAvailableSale,
      artwork?.availablePrintAmount
    );

    const artistAvatar = getProfileAvatarSrc(hasOnlyOneColumn ? 'large' : 'medium', artist?.profileId, artist?.avatar);
    const artistChipTextItems = [
      getArtistTitleText(artist?.title),
      getArtistLocationText(artist?.city, artist?.country),
    ];

    const cardCTA = !artist?.contextId ? undefined : (
      <FollowButton
        targetContextId={artist.contextId}
        predefine={hasSmallCTA ? 'SHORT_MEDIUM_LISTING_CARD_CTA' : 'TALL_WIDE_LISTING_CARD_CTA'}
        isInitiallyRecognizing={isRecognizing}
      />
    );

    const infoCTA = !artwork?.artworkId ? undefined : (
      <FavoritesButton artworkId={artwork.artworkId} isFavorite={isFavorite} />
    );

    return {
      cardTargetLinksProps: {
        top: artworkShowcaseLinkProps,
        bottom: artistProfileLinkProps,
      },
      artistChipProps: {
        image: artistAvatar,
        avatarSize,
        textItems: artistChipTextItems,
      },
      imageProps: {
        aspectRatio: finalAspectRatio,
        src: finalSrc,
      },
      cardCTA,
      artworkInfoProps: {
        title: artwork?.title ?? undefined,
        details: artworkDetails,
        ctaStyle: artworkInfoCTAStyle,
        prefix: {
          title: availabilityDot,
        },
        infoCTA,
      },
      swipeContainerProps: hasOnlyOneColumn ? swipeContainerProps : undefined,
      isSquare: shouldBeSquare,
      isSwipeable: hasOnlyOneColumn,
    };
    // controlled re-computation of the props
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    hasArtist,
    hasArtwork,
    hasSmallCTA,
    hasOnlyOneColumn,
    isSquare,
    aspectRatio,
    t,
    width,
    artistProfileLinkProps,
    artworkShowcaseLinkProps,
    isFavorite,
    artworkDetails,
  ]);
};

export default useGenericCardProps;
