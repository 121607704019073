import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Discount_Code_View } from 'common/schema/commonSchemaRemoteTypes';
import { t } from 'i18next';
import { MONTHSTRINGS } from 'stacks/ProfileArtShowcase/components/ProfileArtShowcaseLeftSide/components/ProfileArtShowcaseSidebar/tabs/helpers/utils';

type appliedDiscountCodeType = {
  applied: boolean;
  code: string;
  finalDiscount?: number;
  discountValue?: number;
  discountValueType?: string;
  originalPrice?: number;
  finalPrice?: number;
  trialPeriodDays?: number;
  endTrialPeriod?: string;
  error: boolean;
};

export interface discountCodesState {
  isOpen: boolean;
  discountCodes: Discount_Code_View[];
  appliedDiscountCode: appliedDiscountCodeType;
}

const initialState: discountCodesState = {
  isOpen: false,
  discountCodes: [],
  appliedDiscountCode: {
    applied: false,
    code: '',
    finalDiscount: 0,
    discountValue: 0,
    discountValueType: '',
    originalPrice: 0,
    finalPrice: 0,
    trialPeriodDays: 0,
    endTrialPeriod: '',
    error: false,
  },
};

const calculateEndTrialPeriod = (trialPeriodDays: number) => {
  const currentDate = new Date();
  const endDate = new Date(currentDate.getTime() + trialPeriodDays * 24 * 60 * 60 * 1000);

  const day = new Date(endDate).getDate();
  const month = new Date(endDate).getMonth();
  const monthName = MONTHSTRINGS[month];
  const year = new Date(endDate).getFullYear();

  return `${t(monthName)} ${day}, ${year}`;
};

const discountCodesSlice = createSlice({
  name: 'discountCodes',
  initialState,
  reducers: {
    OPEN_DISCOUNT_CODES_MODAL: (state) => ({ ...state, isOpen: true }),
    CLOSE_DISCOUNT_CODES_MODAL: (state) => ({ ...state, isOpen: false }),
    SET_DISCOUNT_CODES: (state, action: PayloadAction<Discount_Code_View[]>) => ({
      ...state,
      discountCodes: action.payload,
    }),
    SET_DISCOUNT_CODE: (state, action: PayloadAction<Discount_Code_View>) => ({
      ...state,
      discountCodes: [action.payload, ...state.discountCodes],
    }),
    DELETE_DISCOUNT_CODE: (state, action: PayloadAction<string>) => ({
      ...state,
      discountCodes: state.discountCodes.filter((code) => code.code !== action.payload),
    }),
    SET_APPLIED_DISCOUNT_CODE: (state, action: PayloadAction<appliedDiscountCodeType>) => ({
      ...state,
      appliedDiscountCode: {
        applied: action.payload.applied,
        code: action.payload.code,
        finalDiscount: action.payload.finalDiscount ?? 0,
        discountValue: action.payload.discountValue ?? 0,
        discountValueType: action.payload.discountValueType ?? '',
        originalPrice: action.payload.originalPrice ?? 0,
        finalPrice: action.payload.finalPrice ?? 0,
        trialPeriodDays: action.payload.trialPeriodDays,
        endTrialPeriod: calculateEndTrialPeriod(action.payload.trialPeriodDays ?? 0),
        error: action.payload.error ?? false,
      },
    }),
    CLEAR_DISCOUNT_CODE_APPLIED: (state) => ({
      ...state,
      appliedDiscountCode: {
        applied: false,
        code: '',
        finalDiscount: 0,
        discountValue: 0,
        discountValueType: '',
        originalPrice: 0,
        finalPrice: 0,
        trialPeriodDays: 0,
        endTrialPeriod: '',
        error: false,
      },
    }),
  },
});

export const discountCodesActions = discountCodesSlice.actions;
export default discountCodesSlice;
