import { Grid, GridProps, GridTypeMap } from '@material-ui/core';
import { SkeletonText, TypographyTextType } from './SkeletonText';

export const ColumnText: React.FC<
  GridProps<GridTypeMap['defaultComponent']> & {
    children?: (React.PropsWithChildren<TypographyTextType> | null)[];
  }
> = ({ children, ...others }) => (
  <Grid container direction="column" {...others}>
    {children?.map((v, i) => (
      <SkeletonText key={v?.key ?? `cti-${i}`} {...v} />
    ))}
  </Grid>
);
