import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelectedProfile } from 'common/hooks/useSelectedProfile';
import {
  useGetAdmirationsForProviderLazyQuery,
  useGetCollectorsRecognizingArtistForProviderLazyQuery,
  useGetIgnoredRecognitionsForProviderLazyQuery,
  useGetMutualAdmirationsForProviderLazyQuery,
  useGetNewRecognitionsForProviderLazyQuery,
} from 'common/schema/commonSchemaRemoteGraphqlQueries';
import { admirationsModifiersActions } from 'store/slices/admirationsModifiersSlice';

export const RecognitionsProvider = ({ children }: any) => {
  const contextId = useSelectedProfile()?.contextId ?? 0;
  const dispatch = useDispatch();

  const [getNewRecognitions, { data: newRecognitionsData }] = useGetNewRecognitionsForProviderLazyQuery({
    variables: {
      contextId,
    },
  });

  const [getMutualAdmirations, { data: mutualRecognitionsData }] = useGetMutualAdmirationsForProviderLazyQuery({
    variables: {
      context_id: contextId,
    },
  });

  const [getAdmirations, { data: recognitionsData }] = useGetAdmirationsForProviderLazyQuery({
    variables: {
      context_id: contextId,
    },
  });

  const [getCollectorRecognitions, { data: collectorRecognitionsData }] =
    useGetCollectorsRecognizingArtistForProviderLazyQuery({
      variables: {
        contextId,
      },
    });

  const [getIgnoredRecognitions, { data: ignoredRecognitionsData }] = useGetIgnoredRecognitionsForProviderLazyQuery({
    variables: {
      contextId,
    },
  });

  useEffect(() => {
    if (contextId) {
      getNewRecognitions({
        variables: {
          contextId,
        },
      });
      getMutualAdmirations({
        variables: {
          context_id: contextId,
        },
      });
      getAdmirations({
        variables: {
          context_id: contextId,
        },
      });
      getCollectorRecognitions({
        variables: {
          contextId,
        },
      });
      getIgnoredRecognitions({
        variables: {
          contextId,
        },
      });
    }
  }, [contextId, getNewRecognitions, getMutualAdmirations, getCollectorRecognitions, getAdmirations]);

  useEffect(() => {
    if (
      mutualRecognitionsData &&
      newRecognitionsData &&
      collectorRecognitionsData &&
      recognitionsData &&
      ignoredRecognitionsData
    ) {
      const targetsContextIdList = ignoredRecognitionsData?.Social_graph_followers.map(
        (recognition) => recognition.target_context_id
      );
      const filteredNewRecognitions = newRecognitionsData?.Social_graph_followers?.filter(
        (recognition) => !targetsContextIdList.includes(recognition.context_id)
      );
      dispatch(
        admirationsModifiersActions.LOAD_RECOGNITIONS_AND_ADMIRATIONS({
          newRecognitions: filteredNewRecognitions,
          mutualRecognitions: mutualRecognitionsData?.Social_graph_followers,
          collectorRecognitions: collectorRecognitionsData?.Social_graph_followers,
          simpleRecognitions: recognitionsData?.Social_graph_followers,
          ignoredRecognitions: ignoredRecognitionsData?.Social_graph_followers,
        })
      );
    }
  }, [
    newRecognitionsData,
    mutualRecognitionsData,
    collectorRecognitionsData,
    recognitionsData,
    ignoredRecognitionsData,
  ]);

  return children;
};
