import * as SentryClient from '@sentry/react';

const Sentry = {
  client: SentryClient,
  setUser: (user: SentryClient.User, userType: string): void => {
    SentryClient.setUser(user);
    SentryClient.setTag('auth', 'user');
    SentryClient.setTag('usertype', userType);
  },
};

export default Sentry;
