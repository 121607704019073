import gql from 'graphql-tag';

export const checkVerificationCode = gql`
  query MyQuery($code: String = "") {
    Account_validation(where: { code: { _eq: $code } }) {
      id
      contexts {
        id
      }
    }
  }
`;

export const UPDATE_PROFILE_FIRST_NAME_LAST_NAME = gql`
  mutation MyMutation($id: bigint!, $first_name: String!, $last_name: String!) {
    update_Profile_by_pk(
      pk_columns: { id: $id }
      _set: { first_name: $first_name, last_name: $last_name, is_public: false }
    ) {
      id
    }
  }
`;

export const GET_REGISTRATION_PROFILE = gql`
  query MyQuery {
    Flat_context_access {
      granter {
        profile {
          id
        }
      }
    }
  }
`;

export const GET_PROFILE_PHOTO = gql`
  query MyQuery($id: bigint!) {
    Profile_by_pk(id: $id) {
      id
      avatar
      banner
    }
  }
`;

/** @deprecated */
export const GET_ALL_PROFILES = gql`
  query MyQuery($id: bigint!) {
    Flat_context_access(
      where: {
        receiver_id: { _eq: $id }
        granter: {
          _and: [{ type: { _in: [ARTIST, COLLECTOR, GALLERY] } }, { profile: { status: { _nin: [REJECTED] } } }]
        }
      }
    ) {
      depth
      role
      granter_id
      receiver_id
      granter {
        id
        type
        state
        profile {
          id
          profile_theme
          first_name
          is_public
          last_name
          quote
          statement
          status
          title
          handle
          avatar
        }
        profile_social_links {
          URL
          id
          platform
          custom_link
        }
        locations {
          name
          country
          city
          address
          zip_code
          id
        }
      }
    }
    Context_by_pk(id: $id) {
      state
    }
  }
`;

/** @deprecated */
export const UPDATE_PROFILE_LOCATIONS = gql`
  mutation MyMutation($contextId: bigint!, $objects: [Profile_location_insert_input!]!) {
    delete_Profile_location(where: { context_id: { _eq: $contextId } }) {
      affected_rows
    }
    insert_Profile_location(objects: $objects) {
      affected_rows
      returning {
        address
        city
        country
        id
        name
        zip_code
        location
      }
    }
  }
`;

export const CHECK_VALIDATION_CODE = gql`
  query MyQuery($code: String = "", $email: String = "") {
    checkValidationCode(code: $code, email: $email)
  }
`;

export const GET_EMAIL = gql`
  query MyQuery($id: bigint!) {
    Firebase_profile(where: { auth: { auth_context_accesses: { context_id: { _eq: $id } } } }) {
      email
    }
  }
`;

export const PENDING_ARTWORKS_COLLECTOR = gql`
  query PendingArtworksCollector {
    getMyTransactionsPreview {
      transactions {
        id
        slug
        primary_image_metadata_id
        primary_image_metadata_extra
        artwork_details_title
        artist_context_profile_title
        artist_context_profile_handle
      }
      number_of_pending_transactions
    }
  }
`;

export const ACCEPTED_ARTWORKS_COLLECTOR = gql`
  query MyQuery($email: String!) {
    Artwork_transactions(where: { buyer_email: { _eq: $email }, status: { _eq: ACCEPTED } }, limit: 1) {
      id
      artwork {
        id
        slug
        primary_image_metadata_id
        artwork_details {
          title
        }
        artist_context {
          profile {
            title
            handle
          }
        }
      }
    }
  }
`;

export const CREATE_APP_ACCOUNT = gql`
  mutation CreateAppAccount(
    $email: String!
    $first_name: String!
    $last_name: String!
    $password: String!
    $language: String!
    $invitationToken: String
  ) {
    firebaseSignUp(
      email: $email
      first_name: $first_name
      last_name: $last_name
      password: $password
      preferred_language: $language
      invitationToken: $invitationToken
    ) {
      valid
      message
    }
  }
`;
