import { Explore_Artists_Bool_Exp } from 'common/schema/commonSchemaRemoteTypes';
import {
  AppliedListingModifier,
  FILTERS_DEFAULT_VALUE,
  isOptionType,
  ListingModifier,
  PROFILE_QUERY_CONDITION_IN,
} from './types';

enum KnownOptions {
  DISCIPLINE_ALL = 'filterStrings.discipline.all',
  DISCIPLINE_PAINTING = 'filterStrings.discipline.painting',
  DISCIPLINE_INSTALLATION = 'filterStrings.discipline.installation',
  DISCIPLINE_PRINTMAKING = 'filterStrings.discipline.printMaking',
  DISCIPLINE_DRAWING = 'filterStrings.discipline.drawing',
  DISCIPLINE_MIXED_MEDIA = 'filterStrings.discipline.mixedMedia',
  DISCIPLINE_COLLAGE = 'filterStrings.discipline.collage',
  DISCIPLINE_OTHER = 'filterStrings.discipline.other',
}

export const getDisciplineFilter = (): ListingModifier => ({
  type: 'DISCIPLINE',
  allOptions: [
    {
      value: FILTERS_DEFAULT_VALUE,
      name: KnownOptions.DISCIPLINE_ALL,
      key: 'PROFILE_QUERY_CONDITION_IN',
      payload: {},
    },
    {
      value: 1,
      name: KnownOptions.DISCIPLINE_PAINTING,
      key: 'PROFILE_QUERY_CONDITION_IN',
      payload: { disciplines: { discipline: { _in: ['PAINTING'] } } },
    },
    {
      value: 2,
      name: KnownOptions.DISCIPLINE_INSTALLATION,
      key: 'PROFILE_QUERY_CONDITION_IN',
      payload: { disciplines: { discipline: { _in: ['INSTALLATION'] } } },
    },
    {
      value: 3,
      name: KnownOptions.DISCIPLINE_PRINTMAKING,
      key: 'PROFILE_QUERY_CONDITION_IN',
      payload: { disciplines: { discipline: { _in: ['PRINTMAKING'] } } },
    },
    {
      value: 4,
      name: KnownOptions.DISCIPLINE_DRAWING,
      key: 'PROFILE_QUERY_CONDITION_IN',
      payload: { disciplines: { discipline: { _in: ['DRAWING'] } } },
    },
    {
      value: 5,
      name: KnownOptions.DISCIPLINE_MIXED_MEDIA,
      key: 'PROFILE_QUERY_CONDITION_IN',
      payload: { disciplines: { discipline: { _in: ['MIXED_MEDIA'] } } },
    },
    {
      value: 6,
      name: KnownOptions.DISCIPLINE_COLLAGE,
      key: 'PROFILE_QUERY_CONDITION_IN',
      payload: { disciplines: { discipline: { _in: ['COLLAGE'] } } },
    },
    {
      value: 7,
      name: KnownOptions.DISCIPLINE_OTHER,
      key: 'PROFILE_QUERY_CONDITION_IN',
      payload: { disciplines: { discipline: { _in: ['OTHER'] } } },
    },
  ],
});

export const getAppliedDisciplineFilter = (): AppliedListingModifier<PROFILE_QUERY_CONDITION_IN> => ({
  ...getDisciplineFilter(),
  selectionType: 'MULTIPLE',
  selectedOptions: [FILTERS_DEFAULT_VALUE],
  flattenSelectedOptions: (options, selectedOptions) => {
    const selectedModifiers = options.filter((option) => selectedOptions.includes(option.value));
    const flattenedModifiers = selectedModifiers.reduce<Explore_Artists_Bool_Exp>((flattenedOptions, currentOption) => {
      if (
        isOptionType<PROFILE_QUERY_CONDITION_IN>(currentOption, 'PROFILE_QUERY_CONDITION_IN') &&
        currentOption.payload.disciplines?.discipline?._in
      ) {
        return {
          disciplines: {
            discipline: {
              _in: [
                ...(flattenedOptions.disciplines?.discipline?._in ?? []),
                ...currentOption.payload.disciplines.discipline._in,
              ],
            },
          },
        };
      }
      return flattenedOptions;
    }, {});

    return {
      key: 'PROFILE_QUERY_CONDITION_IN',
      name: 'APPLIED_DISCIPLINE_FILTER',
      value: -1,
      payload: flattenedModifiers,
    };
  },
});
