import { Scalars } from 'common/schema/commonSchemaRemoteTypes';
import { CollectorsSort } from './filter.types';

export type CollectorsFiltersHash = `${CollectorsSort}_${string}`;

export const listingDirections = ['BACKWARD', 'FORWARD'] as const;
export type ListDirection = (typeof listingDirections)[0] | (typeof listingDirections)[1];

export const loadingIdentifier = 'LOADING' as const;
export type ListLoadingId = typeof loadingIdentifier;

export type ListLoaderStatus = 'COLD' | 'FINISHED' | 'IDLE' | `${ListLoadingId}_${ListDirection}`;

export type ListWindowLoaderStateType<ValuesType, StatusType> = {
  batchSize: number;
  values: Array<ValuesType>;
  status: StatusType;
  hasMore: [boolean, boolean]; // [backward, forward]
};

export type ListLoaderStateType<Typename extends CollectorsSort, CursorType, IdType = Scalars['bigint']> = {
  __typename: Typename;
  listed: ListWindowLoaderStateType<CursorType, ListLoaderStatus>;
  detailed: ListWindowLoaderStateType<IdType, ListLoaderStatus>;
  cursor: [CursorType, CursorType]; // [backward, forward]
  detailedToListedStartOffset: number;
  lastScrollPosition: number;
};

export type CollectorsListByDateCursor = {
  contextId: Scalars['bigint'];
  appearedAt: Scalars['date'];
};
export type CollectorsListByDate = ListLoaderStateType<'byDate', CollectorsListByDateCursor>;

export type ListActionArgs = [
  ListDirection,
  CollectorsListByDate['__typename'],
  CollectorsListByDate['listed']['values']
];

export type DetailActionArgs = [
  ListDirection,
  CollectorsListByDate['__typename'],
  CollectorsListByDate['detailed']['values']
];

export type CollectorsListState = {
  identifier: CollectorsFiltersHash;
  type: CollectorsSort;
} & {
  type: 'byDate';
  data: CollectorsListByDate;
};

export type CollectorsListingCursor = CollectorsListByDateCursor;

export const isCollectorsListByDateCursor = (cursor: CollectorsListingCursor): cursor is CollectorsListByDateCursor =>
  (cursor as CollectorsListByDateCursor)?.appearedAt !== undefined;

export const LOADING_STATES: ListLoaderStatus[] = ['LOADING_BACKWARD', 'LOADING_FORWARD'];
