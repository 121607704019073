import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CurrencyTypeEnum } from 'common/components/CurrencySelector/CurrencySelector.formik';
import useApolloErrorHandler from 'common/features/ErrorHandling/hooks/useApolloErrorHandler';
import useAppSelector from 'common/hooks/useAppSelector';
import { useSelectedProfile } from 'common/hooks/useSelectedProfile';
import useShowPreferredCurrency from 'common/hooks/useShowPreferredCurrency';
import {
  useAddItemToCartMutation,
  useGetCartItemsListForAnonUsersLazyQuery,
  useGetCartItemsListLazyQuery,
  useRemoveItemFromCartMutation,
} from 'common/schema/commonSchemaRemoteGraphqlQueries';
import { ShoppingCartItemType } from 'common/schema/commonSchemaRemoteTypes';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

export type ShoppingCartItem = ShoppingCartItemType;
export interface ShoppingCartState {
  anonBuyerId?: number;
  showEmailStep?: boolean;
  artworksId: number[];
  items: ShoppingCartItem[];
  total: number;
  totalCurrency: CurrencyTypeEnum;
  isPopperOpenOnDesktop: boolean;
  popperTimer: NodeJS.Timeout | undefined;
}

const initialState: ShoppingCartState = {
  artworksId: [],
  items: [],
  total: 0,
  totalCurrency: CurrencyTypeEnum.$,
  isPopperOpenOnDesktop: false,
  popperTimer: undefined,
};

export const shoppingCartSlice = createSlice({
  name: 'shoppingCart',
  initialState,
  reducers: {
    ADD_ITEMS_LIST: (state, action: PayloadAction<{ items: ShoppingCartItem[]; artworksId?: number[] }>) => ({
      ...state,
      items: action.payload.items,
      artworksId: action.payload.artworksId ?? state.artworksId,
    }),
    UPDATE_ITEMS_TOTAL: (state, action: PayloadAction<{ total: number; currency: CurrencyTypeEnum }>) => ({
      ...state,
      total: action.payload.total,
      totalCurrency: action.payload.currency,
    }),
    SET_POPPER_IS_OPEN_ON_DESKTOP: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isPopperOpenOnDesktop: action.payload,
    }),
    SET_POPPER_TIMER: (state, action: PayloadAction<NodeJS.Timeout | undefined>) => ({
      ...state,
      popperTimer: action.payload,
    }),
    SET_ANON_ID: (state, action: PayloadAction<number>) => ({
      ...state,
      anonBuyerId: action.payload,
    }),
    SET_SHOW_EMAIL_STEP: (state, action: PayloadAction<boolean>) => ({
      ...state,
      showEmailStep: action.payload,
    }),
    RESET_STATE: () => initialState,
  },
});

export const shoppingCartActions = shoppingCartSlice.actions;

export const useShoppingCart = () => {
  const dispatch = useDispatch();
  const contextId = useSelectedProfile()?.contextId;
  const { items, popperTimer, artworksId } = useAppSelector((store) => store.shoppingCart);
  const [getCartArtworks, { error: getCartItemsError }] = useGetCartItemsListLazyQuery({
    fetchPolicy: 'network-only',
  });
  const [getCartItemsListForAnonUsers, { error: getCartItemsErrorForAnon }] = useGetCartItemsListForAnonUsersLazyQuery({
    fetchPolicy: 'network-only',
  });
  const [addArtworkToCart, { error: addArtworkError }] = useAddItemToCartMutation();
  const [removeCartItem, { error: removeItemError }] = useRemoveItemFromCartMutation();
  useApolloErrorHandler(addArtworkError ?? getCartItemsError ?? removeItemError ?? getCartItemsErrorForAnon);

  const { convertToPreferredCurrency } = useShowPreferredCurrency();

  useEffect(() => {
    if (!items || items.length === 0) {
      return;
    }
    const itemsInPreferredCurr = items.map((item) => {
      const { price: convertedPrice, currency: preferredCurr } = convertToPreferredCurrency({
        price: item.prices?.[0]?.price ?? 0,
        currency: item.prices?.[0]?.currency,
      });
      const { price: convertedShippingPrice } = convertToPreferredCurrency({
        price: item.shippingPrice,
        currency: item.prices?.[0]?.currency,
      });
      return {
        id: item.artworkId,
        price: convertedPrice,
        shipping: convertedShippingPrice,
        currency: preferredCurr,
        isAvailable: item.isAvailableForSale && !item.isInAnInitiatedCheckout,
      };
    });

    const total = itemsInPreferredCurr.reduce((subtotal, item) => {
      if (!item.isAvailable) {
        return subtotal;
      }
      return subtotal + item.price + item.shipping;
    }, 0);
    dispatch(shoppingCartActions.UPDATE_ITEMS_TOTAL({ total, currency: itemsInPreferredCurr[0].currency }));
  }, [items, dispatch, convertToPreferredCurrency]);

  const getCartDetails = async () => {
    if (!contextId) {
      const artworks = (await getCartItemsListForAnonUsers({ variables: { artworksId } })).data
        ?.getCartItemsByArtworksId;
      dispatch(shoppingCartActions.ADD_ITEMS_LIST({ items: artworks ?? [] }));
      return;
    }
    const artworks = (await getCartArtworks({ variables: { contextId } })).data?.getCartItems;
    dispatch(shoppingCartActions.ADD_ITEMS_LIST({ items: artworks ?? [] }));
  };

  const removeItemFromCart = async (artworkId: number) => {
    if (!contextId) {
      dispatch(
        shoppingCartActions.ADD_ITEMS_LIST({
          items: items.filter((item) => item.artworkId !== artworkId),
          artworksId: artworksId.filter((id) => id !== artworkId),
        })
      );
      return;
    }
    const artworks = (await removeCartItem({ variables: { contextId, artworkId } })).data?.removeArtworkFromCart;
    dispatch(shoppingCartActions.ADD_ITEMS_LIST({ items: artworks ?? [] }));
  };

  const addItemToCart = async (artworkId: number) => {
    if (!contextId) {
      const artwork = (await getCartItemsListForAnonUsers({ variables: { artworksId: [artworkId] } })).data
        ?.getCartItemsByArtworksId?.[0];
      if (!artwork) {
        return false;
      }
      dispatch(
        shoppingCartActions.ADD_ITEMS_LIST({ items: [artwork, ...items], artworksId: [artworkId, ...artworksId] })
      );
      return true;
    }

    const artworks = (await addArtworkToCart({ variables: { artworkId, contextId } })).data?.addArtworkToCart;
    dispatch(shoppingCartActions.ADD_ITEMS_LIST({ items: artworks ?? [] }));
    return true;
  };
  const clearTimer = () => {
    if (!popperTimer) {
      return;
    }
    clearTimeout(popperTimer);
    dispatch(shoppingCartActions.SET_POPPER_TIMER(undefined));
  };

  const toggleCartPopperOnDesktop = (status: boolean) => {
    dispatch(shoppingCartActions.SET_POPPER_IS_OPEN_ON_DESKTOP(status));
    clearTimer();
    if (!status) {
      return;
    }
    const timer = setTimeout(() => {
      toggleCartPopperOnDesktop(false);
    }, 5000);
    dispatch(shoppingCartActions.SET_POPPER_TIMER(timer));
  };

  return { addItemToCart, getCartDetails, removeItemFromCart, toggleCartPopperOnDesktop };
};
