import { getDefaultAvailabilityFilter } from 'common/features/Filters/predefined/Availability/store/availability.defaults';
import { getDefaultOrientationFilter } from 'common/features/Filters/predefined/Orientation/store/orientation.defaults';
import { getDefaultPriceRangeFilter } from 'common/features/Filters/predefined/PriceRange/store/priceRange.defaults';
import { getDefaultSortOption } from 'common/features/Filters/predefined/SortGroup/store/recent.defaults';
import { SortOptionsEnum } from 'common/features/VirtualList/VirtualList.config';
import { FavoritesFiltersState } from './filter.types';

export const getDefaultFilters = (): FavoritesFiltersState => ({
  ...getDefaultSortOption(SortOptionsEnum.MOST_RECENT),
  ...getDefaultAvailabilityFilter(),
  ...getDefaultOrientationFilter(),
  ...getDefaultPriceRangeFilter(),
});

export const filterInitialState: FavoritesFiltersState = getDefaultFilters();
