import { createSlice } from '@reduxjs/toolkit';
import { createToggleOrientationCaseReducer } from 'common/features/Filters/predefined/Orientation/store/orientation';
import { createTogglePriceRangeCaseReducer } from 'common/features/Filters/predefined/PriceRange/store/priceRange.reducers';
import { createToggleAvailabilityCaseReducer } from 'common/features/Filters/predefined/Availability/store/availability.reducers';
import { createSortMostRecentCaseReducer } from 'common/features/Filters/predefined/SortMostRecent/store/recent';
import { createSortLeastRecentCaseReducer } from 'common/features/Filters/predefined/SortLeastRecent/store/recent';
import { filterInitialState, getDefaultFilters } from './filter.defaults';
import { FavoritesFiltersState } from './filter.types';

export const favoritesFiltersSlice = createSlice({
  name: 'filter',
  initialState: filterInitialState,
  reducers: {
    RESET_Favorites: getDefaultFilters,
    TOGGLE_AVAILABILITY_Favorites: createToggleAvailabilityCaseReducer<FavoritesFiltersState>(),
    TOGGLE_PRICE_RANGE_Favorites: createTogglePriceRangeCaseReducer<FavoritesFiltersState>(),
    TOGGLE_ORIENTATION_Favorites: createToggleOrientationCaseReducer<FavoritesFiltersState>(),
    SORT_BY_MOST_RECENT_Favorites: createSortMostRecentCaseReducer<FavoritesFiltersState>(),
    SORT_BY_LEAST_RECENT_Favorites: createSortLeastRecentCaseReducer<FavoritesFiltersState>(),
  },
});
