import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { artworkShowcaseActions, ShowcasePayload } from 'store/slices/artworkShowcase';

const useOpenShowcase = (showcasePayload?: ShowcasePayload): ((payload: ShowcasePayload) => void) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (!showcasePayload) {
      return;
    }

    dispatch(artworkShowcaseActions.PREP_SHOWCASE(showcasePayload));
  }, [showcasePayload]);

  const openShowcase = useCallback((payload: ShowcasePayload) => {
    dispatch(
      artworkShowcaseActions.OPEN_SHOWCASE({
        ...payload,
      })
    );
  }, []);

  return openShowcase;
};

export default useOpenShowcase;
