import { makeStyles, Theme } from '@material-ui/core';

export const typographyStyles = makeStyles((theme: Theme) => ({
  fontSize32: {
    fontSize: '32px',
    lineHeight: '48px',
    fontWeight: 700,
    fontFamily: 'Poppins',
    [theme.breakpoints.down('xs')]: {
      fontSize: '24px',
      lineHeight: '36px',
    },
  },
  fontSize16: {
    fontFamily: 'Poppins',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '24px',
  },
  fontSize24: {
    fontFamily: 'Poppins',
    fontSize: '24px',
    lineHeight: '36px',
  },
  fontSize20: {
    fontFamily: 'Poppins',
    fontSize: '20px',
    lineHeight: '30px',
    fontWeight: 700,
  },
  fontSize18: {
    fontFamily: 'Poppins',
    fontSize: '18px',
    lineHeight: '27px',
  },
  fontSize16xs12: {
    fontFamily: 'Poppins',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '24px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
  },
  fontSize14: {
    fontFamily: 'Poppins',
    fontSize: '14px',
    lineHeight: '18px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
  },
  fontSize14Always: {
    fontFamily: 'Poppins',
    fontSize: '14px',
    lineHeight: '18px',
  },
  fontSize12: {
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontSize: '12px',
    color: '#353945',
    lineHeight: '16px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '10px',
    },
  },
}));
