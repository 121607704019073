import { NetworkStatus } from '@apollo/client';
import { Enum_Tutorial_Step_Type_Enum, Maybe } from 'common/schema/commonSchemaRemoteTypes';
import { milestoneFeatures, milestoneStatuses } from './milestonesConstants';

export type MilestoneFeature = (typeof milestoneFeatures)[number];
export type MilestoneStatus = (typeof milestoneStatuses)[number];

export interface Milestone {
  id: number;
  feature: MilestoneFeature;
  minValue: number;
  requiredMilestone: Maybe<Omit<Milestone, 'isHidden'>>;
  contextMilestoneId?: number;
  status: Maybe<MilestoneStatus>;
  networkStatus: NetworkStatus;
  nextFeature?: MilestoneFeature;
  isHidden: boolean;
  showTooltip?: boolean;
}

export type MilestonesMap = { [key in MilestoneFeature]?: Milestone };

export type MilestoneUpdateValues = Pick<
  Milestone,
  'contextMilestoneId' | 'status' | 'networkStatus' | 'isHidden' | 'showTooltip'
>;

export const TRUE_STRING = 'true';

export enum TooltipContext {
  'TUTORIAL' = 'TUTORIAL',
  'MILESTONE' = 'MILESTONE',
}

export enum TooltipEvent {
  'DIMISSED' = 'DISMISSED',
}

export enum MilestoneIndicatorType {
  GREEN_DOTS = 'greendots',
  TOOLTIPS = 'tooltips',
}

export type TooltipLocalStorageProps = {
  feature: Enum_Tutorial_Step_Type_Enum;
  context?: TooltipContext;
  profileId: number;
  event?: TooltipEvent;
  extra?: string | null;
};

export type TooltipLocalStorageSetProps = TooltipLocalStorageProps & { value: string };
