import { makeStyles, Theme } from '@material-ui/core';

interface ThemeProps {
  middleTextLength: number;
  width: number;
}

export const startCardMiddleSectionStyles = makeStyles<Theme, ThemeProps>((theme) => ({
  startCardMiddleSectionContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  middleText: {
    fontWeight: 400,
    fontSize: ({ width }: ThemeProps) => `calc(${width}px / 10)`,
    whiteSpace: 'pre',
    [theme.breakpoints.down('sm')]: {
      fontSize: ({ middleTextLength }: ThemeProps) => `${270 / middleTextLength}vw`,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: ({ middleTextLength }: ThemeProps) => `${370 / middleTextLength}vw`,
    },
  },
}));
