import { SnackbarContextType } from 'common/contexts/ToastProvider';
import { DefaultEditionType, PrintPriceType } from 'common/features/FormikConfigs/components/Prints';
import { Edition, Enum_Artwork_Showroom_Status_Enum } from 'common/schema/commonSchemaRemoteTypes';
import { TypeUtils } from 'common/utils/generalTypeUtils';
import { TFunction } from 'react-i18next';
import { isSomeEnum } from 'utils/utilFunctions';
import { CommercialFormConfigValues } from '../../common/Commercial/views/PublishCommercialForm';

export const validatePrintsCommercialForm = (
  { printPriceType, isForSale, printSizes }: CommercialFormConfigValues,
  showErrorSnackbar: SnackbarContextType['showErrorSnackbar'],
  t: TFunction
): boolean => {
  if (!isForSale) {
    return true;
  }

  const printSizesValues = printSizes ?? {};
  const sizes = TypeUtils.Object.keys(printSizesValues);

  if (!sizes.length) {
    let validationError = t('addArtworkStrings.errors.priceRequired');
    if (printPriceType === PrintPriceType.CUSTOM) {
      validationError = t('publish.prints.commercial.requiredCustomPrices');
    }
    showErrorSnackbar(validationError);
    return false;
  }

  if (printPriceType === PrintPriceType.FIXED) {
    // validate edition sizes fixed prices
    const invalidSize = sizes.find((size) => {
      const sizeValues = printSizesValues[size];
      return !sizeValues || (sizeValues.showPrice && !sizeValues.price);
    });
    if (invalidSize) {
      showErrorSnackbar(t('addArtworkStrings.errors.priceRequired'));
      return false;
    }
    return true;
  }

  // validate prints custom prices
  const sizeWithMissingPrintPrice = sizes.find((size) => {
    const sizeEditions = printSizesValues[size];
    if (!sizeEditions) {
      return true; // TODO: we might want to ignore sizes with no editions because there is nothing to check
    }
    const editions = Object.keys(sizeEditions.printPrices);
    return editions.some((edition) => {
      const editionPrices = sizeEditions.printPrices[edition];
      if (!editionPrices) {
        return true;
      }
      const { prices, printAmount } = editionPrices;
      if (
        !prices?.length ||
        (printAmount && prices.length < printAmount) ||
        !!prices.some((p) => p.status !== Enum_Artwork_Showroom_Status_Enum.Unavailable && !p.price)
      ) {
        return true;
      }
      return false;
    });
  });

  if (sizeWithMissingPrintPrice) {
    showErrorSnackbar(t('publish.prints.commercial.requiredCustomPrices'));
    return false;
  }

  return true;
};

export const getEditionTypeName = (editionType: string, t: TFunction): string => {
  if (isSomeEnum(DefaultEditionType)(editionType)) {
    return t(`publish.prints.editionType.${editionType}.label`);
  }
  return editionType;
};

export const isEditionPublished = (edition?: Pick<Edition, 'publish_date'> | null) => {
  if (!edition?.publish_date) {
    return false;
  }
  const publishDate = new Date(edition.publish_date);
  return publishDate.getTime() < Date.now();
};
