import clsx from 'clsx';
import useAsyncState from 'common/hooks/utils/useAsyncState';
import { useEffect, useMemo, useRef } from 'react';
import { LOADING_STATUS } from 'common/utils/loading';
import { useLoadingLogoStyles } from './LoadingLogoGif.styles';
import { LOADING_ANIMATION_DURATION } from './utils/constants';
import { LoadingProps } from './utils/types';
import LoadingLogoAnimation from './components/LoadingLogoAnimation';

const getNumberMultiple = (
  number: number,
  multiple: number,
  type: 'nearest' | 'next' | 'previous' = 'next'
): number => {
  switch (type) {
    case 'nearest':
      return Math.round(number / multiple) * multiple;
    case 'previous':
      return Math.floor(number / multiple) * multiple;
    case 'next':
    default:
      return Math.ceil(number / multiple) * multiple;
  }
};

/**
 * @param childrenMountingCondition when to mount the children
 * @param isDarkMode sets background to black !important (true) or white (false) or no background (undefined)
 * @param isContained when true, sets the container to position: absolute, height: 100%, width: 100%
 * @param isFullscreen when true, sets the container to position: fixed, height: 100vh, width: 100vw
 * @param className add custom classes
 * @param hasFixedLogo : sets fixed on the gif - allowing it to appear at a static spot (for scrollable loading overlays)
 *
 * @interface LoadingLogoProps
 * @extends {LoadingProps}
 */
export interface LoadingLogoProps extends LoadingProps {
  childrenMountingCondition?: boolean;
  isDarkMode?: boolean;
  isContained?: boolean;
  isFullscreen?: boolean;
  className?: string;
  hasFixedLogo?: boolean;
  isSuspense?: boolean;
}

const useMinimumPlayTimeLogic = (minimumAnimationPlayTime: number, reset: boolean) => {
  const minimumPlayTime = useMemo(() => {
    const videoDuration = LOADING_ANIMATION_DURATION / 2;
    if (minimumAnimationPlayTime > 0 && minimumAnimationPlayTime < videoDuration) {
      // play at least half a loop
      return getNumberMultiple(minimumAnimationPlayTime, videoDuration);
    }
    return minimumAnimationPlayTime;
  }, [minimumAnimationPlayTime]);

  const [hasPlayedMinTime, setHasPlayedMinTime] = useAsyncState(!minimumPlayTime);

  const skipMinPlayTime = minimumPlayTime === 0;

  const timeoutRef = useRef(0);
  useEffect(() => {
    if (skipMinPlayTime || !reset) {
      return;
    }

    setHasPlayedMinTime(() => {
      window.clearTimeout(timeoutRef.current);
      timeoutRef.current = window.setTimeout(() => setHasPlayedMinTime(true), minimumPlayTime);
      return false;
    });
  }, [skipMinPlayTime, setHasPlayedMinTime, reset]);

  return { hasPlayedMinTime, minimumPlayTime };
};

const LoadingLogo: React.FC<LoadingLogoProps> = ({
  childrenMountingCondition = true,
  isDarkMode = false,
  isFullscreen = false,
  isContained = !isFullscreen,
  children,
  minimumAnimationPlayTime = 0, // LOADING_ANIMATION_DURATION, // testing disabled across the board
  open = false,
  hasFixedLogo = false,
  className,
  isSuspense = false,
}) => {
  const svgRef = useRef<SVGSVGElement>(null);
  const { hasPlayedMinTime } = useMinimumPlayTimeLogic(minimumAnimationPlayTime, open);
  const showLoading = useMemo(() => open || !hasPlayedMinTime, [hasPlayedMinTime, open]);

  const classes = useLoadingLogoStyles();

  const id = useRef(0);
  id.current = id.current || LOADING_STATUS.getId();

  useEffect(() => {
    if (!isFullscreen) {
      return () => undefined;
    }

    LOADING_STATUS.changeStatus(id.current, showLoading);
    const finalId = id.current;

    return () => {
      LOADING_STATUS.changeStatus(finalId, false);
    };
  }, [showLoading]);

  const containedClasses = useMemo(() => {
    if (!isContained) {
      return [];
    }
    return [classes.absolute, classes.containerSize];
  }, [classes.absolute, classes.containerSize, isContained]);

  const fullscreenClasses = useMemo(() => {
    if (!isFullscreen) {
      return [];
    }
    return [classes.fixed, classes.viewportSize];
  }, [classes.fixed, classes.viewportSize, isFullscreen]);

  const darkModeClasses = useMemo(() => {
    if (isDarkMode) {
      return [classes.backgroundDark];
    }
    return [classes.backgroundLight];
  }, [classes.backgroundDark, classes.backgroundLight, isDarkMode]);

  const fixedLogoClasses = useMemo(() => {
    if (hasFixedLogo) {
      return [classes.fixedLogoChild];
    }
    return [];
  }, [classes.fixedLogoChild, hasFixedLogo]);

  const containerClasses = useMemo(() => [classes.container, className], [classes.container, className]);

  const containerClass = useMemo(
    () => clsx(...containedClasses, ...fullscreenClasses, ...darkModeClasses, ...containerClasses, ...fixedLogoClasses),
    [containedClasses, fullscreenClasses, darkModeClasses, containerClasses, fixedLogoClasses]
  );

  const mountedChildren = childrenMountingCondition ? children : null;

  return (
    <>
      <div className={clsx(containerClass, { loading: showLoading && !isFullscreen, suspense: isSuspense })}>
        {showLoading && !isFullscreen && !isSuspense && <LoadingLogoAnimation svgRef={svgRef} />}
      </div>
      {mountedChildren}
    </>
  );
};

export default LoadingLogo;
