import { GetFeaturedCollectionsQuery } from 'common/schema/commonSchemaRemoteOperationTypes';
import { CollectionsListType } from 'store/slices/collectionListModifiers';
import {
  AppliedListingModifier,
  COLLECTION_LOCAL_SORT,
  FILTERS_DEFAULT_VALUE,
  isOptionType,
  ListingModifier,
  ListingModifierButtonsConfig,
  ListingModifierOption,
} from './types';

export enum KnownOptions {
  SORT_MOST_RECENT = 'filterStrings.sort.collections.recent',
  SORT_LARGEST_SIZE = 'filterStrings.sort.collections.largest',
  SORT_ALPHABETICAL = 'filterStrings.sort.collections.alphabetical',
}

const getSize = (collection: GetFeaturedCollectionsQuery['Artist_Collection'][0]) =>
  collection.Artist_Collection_to_Artist_Contexts.length;

const getDateValue = (collection: GetFeaturedCollectionsQuery['Artist_Collection'][0]): number =>
  new Date(collection.updated_at).getTime();

const getAlphabetical = (collection: GetFeaturedCollectionsQuery['Artist_Collection'][0]) => collection.title;

type SortVariant = CollectionsListType;
type SortVariantOptions = {
  [key in SortVariant]: (collectionId: number) => ListingModifierOption[];
};

const defaultSortOptions: ListingModifierOption[] = [
  {
    value: 1,
    name: KnownOptions.SORT_LARGEST_SIZE,
    key: 'COLLECTION_LOCAL_SORT',
    payload: (a, b) => getSize(b) - getSize(a),
  },
  {
    value: 2,
    name: KnownOptions.SORT_ALPHABETICAL,
    key: 'COLLECTION_LOCAL_SORT',
    payload: (a, b) => getAlphabetical(a).localeCompare(getAlphabetical(b)),
  },
];

const variants: SortVariantOptions = {
  COLLECTIONS: () => [
    {
      value: FILTERS_DEFAULT_VALUE,
      name: KnownOptions.SORT_MOST_RECENT,
      key: 'COLLECTION_LOCAL_SORT',
      payload: (a, b) => getDateValue(b) - getDateValue(a),
    },
    {
      value: 2,
      name: KnownOptions.SORT_ALPHABETICAL,
      key: 'COLLECTION_LOCAL_SORT',
      payload: (a, b) => getAlphabetical(a).localeCompare(getAlphabetical(b)),
    },
    {
      value: 1,
      name: KnownOptions.SORT_LARGEST_SIZE,
      key: 'COLLECTION_LOCAL_SORT',
      payload: (a, b) => getSize(b) - getSize(a),
    },
  ],
};

export const getSorter = (collectionId = 0, variant?: SortVariant): ListingModifier => {
  const computedSorters: ListingModifier = {
    type: 'SORT',
    allOptions: [...(variant ? variants[variant](collectionId) : defaultSortOptions)],
  };
  computedSorters.allOptions.sort((a, b) => a.value - b.value);
  return computedSorters;
};

export const sortButtonsDefaultConfig: ListingModifierButtonsConfig = {
  [KnownOptions.SORT_MOST_RECENT]: {
    specialWidth: 'FULL',
  },
  [KnownOptions.SORT_LARGEST_SIZE]: {
    specialWidth: 'FULL',
  },
  [KnownOptions.SORT_ALPHABETICAL]: {
    specialWidth: 'FULL',
  },
};
export const sortButtonsListTypeConfig: {
  [key in CollectionsListType]: ListingModifierButtonsConfig;
} = {
  COLLECTIONS: { ...sortButtonsDefaultConfig },
};

export const getAppliedSort = (
  collectionId?: number,
  variant?: SortVariant
): AppliedListingModifier<COLLECTION_LOCAL_SORT> => ({
  ...getSorter(collectionId, variant),
  selectionType: 'SINGLE',
  selectedOptions: [FILTERS_DEFAULT_VALUE],
  flattenSelectedOptions: (options, selectedOptions) => {
    const flattenedOption = options.find((option) => selectedOptions.includes(option.value));
    if (!flattenedOption || !isOptionType<COLLECTION_LOCAL_SORT>(flattenedOption, 'COLLECTION_LOCAL_SORT')) {
      throw new Error('Wrong option type');
    }
    return flattenedOption;
  },
});
