import { useShouldShowFeatureFlag } from 'common/features/FeatureFlag/hooks/useFeatureFlag';
import React from 'react';
import { useTranslation } from 'react-i18next';

type TextVariant = 'FOLLOW' | 'FOLLOWING' | 'UNFOLLOW' | 'FOLLOW_BACK' | 'NONE';

const followVariants: Record<TextVariant, string> = {
  NONE: '',
  FOLLOW: 'artistProfile.sidebar.recognitions.RecognizeBtn.text.follow',
  FOLLOWING: 'artistProfile.sidebar.recognitions.RecognizingBtn.text.follow',
  UNFOLLOW: 'artistProfile.sidebar.recognitions.RecognizingBtn.hover.text.follow',
  FOLLOW_BACK: 'artistProfile.sidebar.recognitions.RecognizingBtn.text.followBack',
};

const recognizeVariants: Record<TextVariant, string> = {
  NONE: '',
  FOLLOW: 'artistProfile.sidebar.recognitions.RecognizeBtn.text',
  FOLLOWING: 'artistProfile.sidebar.recognitions.RecognizingBtn.text',
  UNFOLLOW: 'artistProfile.sidebar.recognitions.RecognizingBtn.hover.text',
  FOLLOW_BACK: 'artistProfile.sidebar.recognitions.RecognizingBtn.text.followBack',
};

const variants = {
  FOLLOW: followVariants,
  RECOGNIZE: recognizeVariants,
};

export type FollowTextProps = {
  variant: TextVariant;
  flavor?: 'RECOGNIZE' | 'FOLLOW';
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>;

const FollowText: React.FC<FollowTextProps> = ({
  variant,
  flavor,
  style = {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    fontSize: '16px',
    lineHeight: '20px',
  },
  ...spanProps
}) => {
  const { t } = useTranslation();
  const [defaultFollowFlavor] = useShouldShowFeatureFlag('followInsteadOfRecognize');

  if (variant === 'NONE') {
    return null;
  }

  const finalFlavor = flavor ?? defaultFollowFlavor ? 'FOLLOW' : 'RECOGNIZE';
  const textKey = variants[finalFlavor][variant];
  return (
    <span {...spanProps} style={style}>
      {t(textKey)}
    </span>
  );
};

export default FollowText;
