import clsx from 'clsx';
import { useMemo } from 'react';
import { formatNumberToLocale } from 'utils/utilFunctions';
import { useStyles } from './RoundRectangle.styles';

export type RoundRectangleProps = {
  number: number;
  title: string;
  id?: number;
  onClick?: any;
  isActive?: boolean;
  isBorder?: boolean;
  disabled?: boolean;
  className?: string;
};

const RoundRectangle: React.FC<RoundRectangleProps> = ({
  number,
  title,
  onClick,
  isActive = false,
  isBorder = true,
  disabled,
  className,
}) => {
  const classes = useStyles({});

  const displayedNumber = useMemo(() => formatNumberToLocale(number), [number]);

  return (
    <div
      role="button"
      aria-hidden="true"
      className={clsx(
        classes.button,
        !disabled && isBorder ? classes.addBorderOnhover : '',
        !disabled && isActive ? classes.addBorder : '',
        className
      )}
      onClick={onClick}
    >
      <div className={[classes.rectangleContainer, classes.rectangleColor].join(' ')}>
        <div style={{ paddingLeft: '5px', paddingRight: '5px' }}>
          <div className={[classes.circleContainer, classes.roundCircleColor].join(' ')}>
            <div className={[classes.innerContainerText, classes.addFontFamilly].join(' ')}>{displayedNumber}</div>
          </div>
        </div>

        <div
          className={[classes.innerContainer1, classes.addFontFamilly].join(' ')}
          style={{ padding: '10px 30px 10px 10px' }}
        >
          {title}
        </div>
      </div>
    </div>
  );
};

type RoundRectangleWithoutCircleProps = {
  number: number;
  title: string;
  isCircle?: boolean;
};

export const RoundRectangleWithoutCircle: React.FC<RoundRectangleWithoutCircleProps> = ({ number, title }) => {
  const classes = useStyles();

  const displayedNumber = useMemo(() => formatNumberToLocale(number), [number]);

  return (
    <div className={clsx(classes.rectangleContainer1, classes.whiteRectangleColor)}>
      <div className={classes.innerContainer}>{displayedNumber}</div>
      <div className={classes.innerContainer1}>{title}</div>
    </div>
  );
};

export default RoundRectangle;
