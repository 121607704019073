type ThreadedCounter = {
  counters: Record<string | number, boolean>;
  increment: (threadId: number | string) => void;
  reset: () => void;
  resetThread: (threadId: number | string) => void;
  count: number;
  setCount: (count: number) => void;
  setThreads: (threads: Array<string | number>) => void;
};

const createThreadedCounter = (): ThreadedCounter => {
  const threadedCounter: ThreadedCounter = {
    counters: {},
    increment: (threadId: number | string) => {
      threadedCounter.counters[threadId] = true;
      threadedCounter.count = Object.keys(threadedCounter.counters).length;
    },
    reset: () => {
      threadedCounter.counters = {};
      threadedCounter.count = 0;
    },
    resetThread: (threadId: number | string) => {
      delete threadedCounter.counters[threadId];
      threadedCounter.count = Object.keys(threadedCounter.counters).length;
    },
    count: 0,
    setCount: (count: number) => {
      threadedCounter.count = count;
    },
    setThreads: (threads: Array<string | number>) => {
      threadedCounter.counters = threads.reduce((acc, threadId) => {
        acc[threadId] = true;
        return acc;
      }, {} as Record<string | number, boolean>);
      threadedCounter.count = Object.keys(threadedCounter.counters).length;
    },
  };
  return threadedCounter;
};
export default createThreadedCounter;
