import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { PrizesFilterState } from './prizes.types';
import { PRIZE_ALL_OPTION, getDefaultPrizeFilter } from './prizes.defaults';

type TogglePrizeAction = PayloadAction<{
  prizeValue: string;
}>;
export const createTogglePrizeCaseReducer =
  <State extends PrizesFilterState>(): CaseReducer<State, TogglePrizeAction> =>
  (state, action) => {
    const { prizeValue } = action.payload ?? {};

    if (prizeValue === PRIZE_ALL_OPTION && !!state.prizes[prizeValue]) {
      return;
    }

    if (prizeValue === PRIZE_ALL_OPTION) {
      state.prizes = getDefaultPrizeFilter().prizes;
      return;
    }

    state.prizes[prizeValue] = !state.prizes[prizeValue];
    state.prizes[PRIZE_ALL_OPTION] = false;
  };
