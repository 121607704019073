import { useSnackbar } from 'common/contexts/ToastProvider';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useAsyncState from './utils/useAsyncState';

const AUDIO_CONSTRAINTS: MediaStreamConstraints = {
  audio: true,
  video: false,
};

export enum MediaStreamErrors {
  Denied = 'Permission denied',
  Dismissed = 'Permission dismissed',
  Unknown = 'Unknown',
}

export type MediaStreamAllowedCallback = (stream: MediaStream) => void;
export type MediaStreamErrorCallback = (error: any) => void;

const useMediaStreamPermission = (checkOnMount = false, silent = true, constraints?: MediaStreamConstraints) => {
  const [stream, setStream] = useAsyncState<MediaStream | null>(null);
  const [error, setError] = useAsyncState<MediaStreamErrors | null>(null);
  const { t } = useTranslation();
  const { showErrorSnackbar } = useSnackbar();

  const checkPermission = async (
    successCallback?: MediaStreamAllowedCallback,
    errorCallback?: MediaStreamErrorCallback
  ) => {
    try {
      let mediaStream = stream;
      setError(null);
      if (!mediaStream) {
        mediaStream = await navigator.mediaDevices.getUserMedia(constraints || AUDIO_CONSTRAINTS);
        setStream(mediaStream);
      }
      if (successCallback) {
        successCallback(mediaStream);
      }
    } catch (err) {
      if (err instanceof Error) {
        if (err.message.includes(MediaStreamErrors.Denied)) {
          setError(MediaStreamErrors.Denied);
        } else if (err.message.includes(MediaStreamErrors.Dismissed)) {
          setError(MediaStreamErrors.Dismissed);
        } else {
          setError(MediaStreamErrors.Unknown);
        }
      }
      if (errorCallback) {
        errorCallback(error);
      }
    }
  };

  useEffect(() => {
    if (silent || !error) {
      return;
    }
    showErrorSnackbar(t('useMediaStreamPermission'));
  }, [silent, error]);

  useEffect(() => {
    if (checkOnMount) {
      checkPermission();
    }
  }, []);
  return {
    stream,
    checkPermission,
    error,
  };
};

export default useMediaStreamPermission;
