import useAppSelector from 'common/hooks/useAppSelector';
import { useEffect, useMemo } from 'react';
import useDebouncedState from 'common/hooks/utils/useDebouncedState';
import { yearsFilterHash } from 'common/features/Filters/predefined/Year/year.utils';
import { artistsFilterHash } from 'common/features/Filters/predefined/Artists/artists.utils';
import { ProfileCollectorFiltersHash, LOADING_STATES } from '../store/list.types';
import { ProfileCollectorFiltersState } from '../store/filter.types';

// exported for use in the initial state
export const filtersHash = (
  state: ProfileCollectorFiltersState,
  suffix: string | number = ''
): ProfileCollectorFiltersHash =>
  // using template string to match typing
  `${state.currentSort}_${[yearsFilterHash(state.selectedYears), artistsFilterHash(state.selectedArtists), suffix].join(
    '_'
  )}` as const;

export const useProfileCollectorFiltersIdentifier = (contextId?: string | number): ProfileCollectorFiltersHash => {
  const filters = useAppSelector((store) => store.profileCollector.filter);
  const currentIdentifier = useMemo(() => filtersHash(filters, contextId), [filters, contextId]);
  const [appliedIdentifier, setAppliedIdentifier] = useDebouncedState(currentIdentifier, 750);
  const listStatus = useAppSelector((store) => store.profileCollector.list.data.listed.status);

  useEffect(() => {
    if (LOADING_STATES.includes(listStatus)) return;
    setAppliedIdentifier(currentIdentifier);
  }, [currentIdentifier, listStatus]);
  return appliedIdentifier;
};
