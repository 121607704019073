import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createSetBoostSkewCaseReducer } from 'common/features/Filters/predefined/BoostSkew/store/boostSkew.reducers';
import { createSelectCountryCaseReducer } from 'common/features/Filters/predefined/Country/store/country.reducers';
import { createToggleInterestCaseReducer } from 'common/features/Filters/predefined/Interests/store/interests.reducers';
import { createTogglePriceRangeCaseReducer } from 'common/features/Filters/predefined/PriceRange/store/priceRange.reducers';
import { createRefreshFilterCaseReducer } from 'common/features/Filters/predefined/Refresh/refresh.reducers';
import { createFilterTitleCaseReducer } from 'common/features/Filters/predefined/Title/store/title.reducers';
import { filterInitialState, getDefaultFilters } from './filter.defaults';
import { HomeArtistsFiltersState } from './filter.types';

export const homeArtistsFiltersSlice = createSlice({
  name: 'filter',
  initialState: filterInitialState,
  reducers: {
    RESET_HomeArtists: getDefaultFilters,
    SORT_HomeArtists: (state, action: PayloadAction<HomeArtistsFiltersState['sort']>) => ({
      ...state,
      sort: action.payload,
    }),
    TOGGLE_INTEREST_HomeArtists: createToggleInterestCaseReducer<HomeArtistsFiltersState>(),
    TOGGLE_PRICE_RANGE_HomeArtists: createTogglePriceRangeCaseReducer<HomeArtistsFiltersState>(),
    CHANGE_COUNTRY_HomeArtists: createSelectCountryCaseReducer<HomeArtistsFiltersState>(),
    SEARCH_TITLE_HomeArtists: createFilterTitleCaseReducer<HomeArtistsFiltersState>(),
    REFRESH_HomeArtists: createRefreshFilterCaseReducer<HomeArtistsFiltersState>(),
    SET_BoostSkew: createSetBoostSkewCaseReducer<HomeArtistsFiltersState>(),
  },
});
