import { SortState } from 'common/features/Filters/predefined/SortGroup/store/sort.types';
import { SortOptionsEnum } from 'common/features/VirtualList/VirtualList.config';
import { RecognitionsFiltersEnum } from './filter.defaults';

export const sorts = [SortOptionsEnum.MOST_RECENT] as const;
export type RecognitionsSort = (typeof sorts)[0];
export const isRecognitionsSort = (v: string): v is RecognitionsSort => sorts.includes(v as RecognitionsSort);
export type FilterState = {
  chosenFilter: keyof typeof RecognitionsFiltersEnum;
};

// currently auto-applies on every change
// maybe todo: add flag to apply on demand
export type RecognitionsFiltersState = SortState & FilterState;
