import { makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { HtmlDivProps } from 'common/utils/types';
import React, { useMemo } from 'react';

const useStyles = makeStyles((theme) => ({
  noMarginLastSection: {
    '&:last-child:not(:only-of-type)': {
      marginBottom: 0,
    },
  },
  section: {
    marginBottom: theme.spacing(5),
    display: 'flex',
    flexDirection: 'column',
  },
  text: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(3),
  },
  inlineComponentHolder: {
    flex: 1,
  },
  title: {
    ...theme.mixins.sectionTitle,
    marginRight: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
  description: {
    fontSize: theme.typography.pxToRem(16),
    lineHeight: `${theme.spacing(2.5)}px`,
    marginTop: theme.spacing(1),
    fontWeight: 'normal',
    [theme.breakpoints.down('xs')]: {
      fontSize: theme.typography.pxToRem(14),
    },
  },
  footer: {
    color: 'gray',
    fontSize: theme.typography.pxToRem(14),
    lineHeight: `${theme.spacing(2.5)}px`,
    marginTop: theme.spacing(1),
    fontWeight: 'normal',
    [theme.breakpoints.down('xs')]: {
      fontSize: theme.typography.pxToRem(12),
    },
  },
  headerHolder: {
    display: 'flex',
    flexDirection: 'row',
    // marginBottom: theme.spacing(3),
  },
}));

interface Props extends Omit<HtmlDivProps, 'title'> {
  title?: string | JSX.Element;
  description?: string | JSX.Element;
  inlineComponent?: JSX.Element;
  inlineFineTunePX?: number;
  footer?: string | JSX.Element;
  noMarginLastSection?: boolean;
}

const Section: React.FC<Props> = ({
  title,
  description,
  inlineComponent,
  children,
  inlineFineTunePX,
  className,
  footer,
  noMarginLastSection = false,
  ...props
}) => {
  const classes = useStyles({});

  const hasTitleOrDesctiption = !!title || !!description;

  const titleElement = useMemo(() => {
    if (!title) return null;
    return (
      <Typography variant="h5" className={classes.title}>
        {title}
      </Typography>
    );
  }, [title, classes.title]);

  const descriptionElement = useMemo(() => {
    if (!description) return null;
    return (
      <Typography variant="h6" className={classes.description}>
        {description}
      </Typography>
    );
  }, [description, classes.description]);

  const footerElement = useMemo(() => {
    if (!footer) {
      return null;
    }
    return (
      <Typography variant="h6" className={classes.footer}>
        {footer}
      </Typography>
    );
  }, [footer]);

  return (
    <div
      {...props}
      className={clsx(classes.section, className, noMarginLastSection ? classes.noMarginLastSection : '')}
    >
      <div
        className={hasTitleOrDesctiption ? classes.text : undefined}
        style={{ display: 'flex', flexDirection: 'column' }}
      >
        <div className={classes.headerHolder}>
          {titleElement}
          {!!inlineComponent && (
            <div
              className={classes.inlineComponentHolder}
              style={inlineFineTunePX ? { marginTop: `${inlineFineTunePX}px` } : {}}
            >
              {inlineComponent}
            </div>
          )}
        </div>
        <div>{descriptionElement}</div>
      </div>
      {children}
      {footerElement}
    </div>
  );
};

export default Section;
