import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  id: null,
  artworkStoryId: null,
  artworkDetailsId: null,
  type: null,
  title: null,
  width: null,
  height: null,
  depth: null,
  creationYear: null,
  creationMonth: null,
  creationDay: null,
  materialsAndMedium: null,
  discipline_other: null,
  discipline: null,
  tools: null,
  primaryImageLink: null,
  thumbnailImageLink: null,
  audio: null,
  secondaryImages: [],
  statement: null,
  processImagesDownloadLinks: [],
  displayPrice: null,
  price: null,
  currency: null,
  is_dark_mode: false,
  partner_gallery: null,
  partner_artist: null,
  isPartner: null,
  hasSeries: null,
  forSale: null,
  isInvite: null,
  slug: null,
  selectedArtist: null,
  editFromArtShowcase: null as number | null, // null | artworkId
  showcaseUrl: null as string | null,
};

const artworkSlice = createSlice({
  initialState,
  name: 'editArtwork',
  reducers: {
    // These don't seem to be used anymore
    // CREATE_NEW_ART: (state, action) => ({
    //   ...initialState,
    //   ...action.payload,
    // }),
    // SET_ARTWORK_DATA: (state, action) => ({
    //   ...state,
    //   ...action.payload,
    // }),
    RESET: () => ({
      ...initialState,
    }),
    EDIT_FROM_ART_SHOWCASE: (state, action) => {
      state.editFromArtShowcase = action.payload;
    },
    SET_SHOWCASE_URL: (state, action) => {
      state.showcaseUrl = action.payload;
    },
  },
});

export const editArtworkActions = artworkSlice.actions;
export const editArtworkReducer = artworkSlice.reducer;
