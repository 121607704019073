import { createSlice } from '@reduxjs/toolkit';

export type ArtistProfileArtworksDisplayState = {
  isFiltersDialogOpen: boolean;
  isMyWebsiteFiltersDialogOpen: boolean;
};

export const getDefaultDisplay = (): ArtistProfileArtworksDisplayState => ({
  isFiltersDialogOpen: false,
  isMyWebsiteFiltersDialogOpen: false,
});

export const displayInitialState: ArtistProfileArtworksDisplayState = getDefaultDisplay();

export const displayArtistProfileArtworksSlice = createSlice({
  name: 'display',
  initialState: displayInitialState,
  reducers: {
    OPEN_FILTERS_ArtistProfileArtworks: (state) => {
      state.isFiltersDialogOpen = true;
    },
    CLOSE_FILTERS_ArtistProfileArtworks: (state) => {
      state.isFiltersDialogOpen = false;
    },
    OPEN_FILTERS_MyWebsiteArtistProfileArtworks: (state) => {
      state.isMyWebsiteFiltersDialogOpen = true;
    },
    CLOSE_FILTERS_MyWebsiteArtistProfileArtworks: (state) => {
      state.isMyWebsiteFiltersDialogOpen = false;
    },
  },
});
