import { SortOptionsEnum } from 'common/features/VirtualList/VirtualList.config';
import { Scalars } from 'common/schema/commonSchemaRemoteTypes';
import { FavoritesSort } from './filter.types';

export type FavoritesFiltersHash = `${SortOptionsEnum}_${string}`;

export const listingDirections = ['BACKWARD', 'FORWARD'] as const;
export type ListDirection = (typeof listingDirections)[0] | (typeof listingDirections)[1];

export const loadingIdentifier = 'LOADING' as const;
export type ListLoadingId = typeof loadingIdentifier;

export type ListLoaderStatus = 'COLD' | 'FINISHED' | 'IDLE' | `${ListLoadingId}_${ListDirection}`;

export type ListWindowLoaderStateType<ValuesType, StatusType> = {
  batchSize: number;
  values: Array<ValuesType>;
  status: StatusType;
  hasMore: [boolean, boolean]; // [backward, forward]
};

export type ListLoaderStateType<Typename extends FavoritesSort, CursorType, IdType = Scalars['bigint']> = {
  __typename: Typename;
  listed: ListWindowLoaderStateType<CursorType, ListLoaderStatus>;
  detailed: ListWindowLoaderStateType<IdType, ListLoaderStatus>;
  cursor: [CursorType, CursorType]; // [backward, forward]
  detailedToListedStartOffset: number;
  lastScrollPosition: number;
};

export type FavoritesListByDateCursor = {
  artistContextId: Scalars['bigint'];
  artworkId: Scalars['bigint'];
  publishedDate: Scalars['date'];
};
export type FavoritesListMostRecent = ListLoaderStateType<SortOptionsEnum.MOST_RECENT, FavoritesListByDateCursor>;

export type FavoritesListLeastRecent = ListLoaderStateType<SortOptionsEnum.LEAST_RECENT, FavoritesListByDateCursor>;

export type MostRecentListActionArgs = [
  ListDirection,
  FavoritesListMostRecent['__typename'],
  FavoritesListMostRecent['listed']['values']
];

export type MostRecentDetailActionArgs = [
  ListDirection,
  FavoritesListMostRecent['__typename'],
  FavoritesListMostRecent['detailed']['values']
];

export type LeastRecentListActionArgs = [
  ListDirection,
  FavoritesListLeastRecent['__typename'],
  FavoritesListLeastRecent['listed']['values']
];

export type LeastRecentDetailActionArgs = [
  ListDirection,
  FavoritesListLeastRecent['__typename'],
  FavoritesListLeastRecent['detailed']['values']
];

export type FavoritesListState = {
  identifier: FavoritesFiltersHash;
  type: FavoritesSort;
} & (
  | {
      type: SortOptionsEnum.MOST_RECENT;
      data: FavoritesListMostRecent;
    }
  | {
      type: SortOptionsEnum.LEAST_RECENT;
      data: FavoritesListLeastRecent;
    }
);

export type FavoritesListingCursor = FavoritesListByDateCursor;

export const isFavoritesListByDateCursor = (cursor: FavoritesListingCursor): cursor is FavoritesListByDateCursor =>
  (cursor as FavoritesListByDateCursor)?.publishedDate !== undefined;

export const LOADING_STATES: ListLoaderStatus[] = ['LOADING_BACKWARD', 'LOADING_FORWARD'];
