import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GetBuyModalDetailsQuery } from 'common/schema/commonSchemaRemoteOperationTypes';

export interface buyModalState {
  isOpen: boolean;
  artworkId?: number;
  data?: GetBuyModalDetailsQuery['Artwork'][0] & { shippingPrice: number };
}

const initialState: buyModalState = {
  isOpen: false,
};

const buyModalSlice = createSlice({
  name: 'buyModal',
  initialState,
  reducers: {
    OPEN_BUY_MODAL: (state) => ({ ...state, isOpen: true }),
    SET_ARTWORK_ID: (state, action: PayloadAction<number>) => {
      state.artworkId = action.payload;
    },
    SET_DATA: (state, action: PayloadAction<buyModalState['data']>) => {
      state.data = action.payload;
    },
    RESET_STATE: () => initialState,
  },
});

export const buyModalActions = buyModalSlice.actions;
export default buyModalSlice;
