import { t } from 'i18next';

export const getDimensions = (width: number, height: number, unit: string, depth?: number) => {
  let dimensions = '';
  if (!width || !height || !unit) {
    return '';
  }

  dimensions = `${width} x ${height} ${unit}`;
  if (depth) {
    dimensions = `${width} x ${height} x ${depth} ${unit}`;
  }

  return dimensions;
};

export const creationDate = (year?: number, month?: number, day?: number) => {
  const monthName = typeof month === 'number' ? t(`dateStrings.monthNames.${month}`) : undefined;

  let date = '';
  if (!year) {
    return '';
  }

  if (monthName === undefined) {
    date = `${year}`;
  } else {
    if (day !== undefined && day !== null) {
      date = `${monthName} ${day}, ${year}`;
      return date;
    }

    date = `${monthName} ${year}`;
  }

  return date;
};

export const getDuration = (secondsData?: number) => {
  if (!secondsData) {
    return '';
  }

  return `${secondsData}s`;
};

export const replaceBreakTagsWithNewline = (text?: string): string => {
  if (!text) {
    return '';
  }

  return text?.replaceAll('<br/>', '\n') ?? '';
};

export const MONTHSTRINGS = [
  'dateStrings.monthNames.0',
  'dateStrings.monthNames.1',
  'dateStrings.monthNames.2',
  'dateStrings.monthNames.3',
  'dateStrings.monthNames.4',
  'dateStrings.monthNames.5',
  'dateStrings.monthNames.6',
  'dateStrings.monthNames.7',
  'dateStrings.monthNames.8',
  'dateStrings.monthNames.9',
  'dateStrings.monthNames.10',
  'dateStrings.monthNames.11',
];
