import { useMemo } from 'react';
import useAppSelector from 'common/hooks/useAppSelector';
import { useSelectedProfile } from 'common/hooks/useSelectedProfile';
import FavoritesButton from 'common/features/FavoritesButton/FavoritesButton';
import { useTranslation } from 'react-i18next';
import { isSomeEnum } from 'utils/utilFunctions';
import { Enum_Position_Type_Enum, Enum_Social_Graph_Followers_State_Enum } from 'common/schema/commonSchemaRemoteTypes';
import FollowButton from 'common/components/RecognizeButtonV2/components/FollowButton';
import useGetSwipeContainerProps from '../common/SwipeInfoContainer/hooks/useGetSwipeContainerProps';
import { useArtistProfileTargetLink, useArtworkShowcaseTargetLink } from './common/cardTargetLinksPropsHooks';
import { getAspectArtworkImageSrc, getProfileAvatarSrc, getSquareArtworkImageSrc } from './common/imageGetters';
import { getArtistLocationText, getArtistTitleText } from './common/typographyTextTypeGetters';
import { getKnownAspectRatio, squareAspectRatio } from './common/aspectRatio';
import getAvailabilityDot from './common/getAvailabilityDot';
import { ArtPrizeCardProps } from '../ArtPrizeCard/ArtPrizeCard';
import useGetArtworkDetails from './common/useGetArtworkDetails';

const isPositionType = isSomeEnum(Enum_Position_Type_Enum);

const artworkInfoCTAStyle: React.CSSProperties = {
  width: '37.5px',
  marginBottom: '2px',
  alignSelf: 'flex-end',
  paddingLeft: '1.5px',
  marginRight: '-1.5px',
};

const useArtPrizeCardProps = (
  artworkId: number,
  width: number,
  columnCount: number,
  artworkPrizeIds: string,
  index: number,
  aspectRatio?: number,
  isSquare?: boolean
): ArtPrizeCardProps => {
  const { t } = useTranslation();
  const hasOnlyOneColumn = columnCount === 1;
  const hasSmallCTA = width <= 600;
  const currentContextId = useSelectedProfile()?.contextId;
  const { artwork, artist } = useAppSelector((store) => store.entities.artworkCardsCache[artworkId]) ?? {};
  const isRecognizing = useAppSelector(
    (store) =>
      store.entities.recognitionsCache.outgoing[artist?.contextId ?? 0]?.state ===
      Enum_Social_Graph_Followers_State_Enum.Active
  );

  // card top and bottom links
  const artistProfileLinkProps = useArtistProfileTargetLink(artist?.handle);
  const artworkShowcaseLinkProps = useArtworkShowcaseTargetLink(
    artist?.handle,
    artwork?.slug,
    artworkId,
    artwork?.primaryMetadataId
  );

  // artwork info cta
  const isFavorite = useAppSelector(
    (store) =>
      !!store.favorites.favorites.find((v) => v.artworkId === artwork?.artworkId && v.contextId === currentContextId)
  );
  // swipe container props
  const swipeContainerProps = useGetSwipeContainerProps(index, artwork?.artworkId ?? 0);

  // prize props
  const artPrize = useAppSelector((store) => store.entities.prizeBadgesCache[artworkPrizeIds]);

  // artist and artwork data is available
  const hasArtist = !!artist;
  const hasArtwork = !!artwork;
  const hasPrize = !!artPrize;

  const artworkDetailsString = useGetArtworkDetails(artwork);

  return useMemo<ArtPrizeCardProps>(() => {
    // artist chip info and avatar
    const artistAvatarSrc = getProfileAvatarSrc(
      hasOnlyOneColumn ? 'large' : 'medium',
      artist?.profileId,
      artist?.avatar
    );
    const avatarSize = hasSmallCTA ? '40px' : '40px';
    const artistTitleTextProps = getArtistTitleText(artist?.title);
    const artistLocationTextProps = getArtistLocationText(artist?.city, artist?.country);

    // artwork card image
    const shouldBeSquare = isSquare === undefined ? hasOnlyOneColumn : isSquare;
    const squareImageSrc = getSquareArtworkImageSrc(
      hasOnlyOneColumn ? 'large' : 'medium',
      artwork?.artworkId,
      artwork?.primaryMetadataId
    );
    const aspectImageSrc = getAspectArtworkImageSrc(
      hasOnlyOneColumn ? 'large' : 'medium',
      artwork?.artworkId,
      artwork?.primaryMetadataId
    );
    const finalAspectRatio = shouldBeSquare ? squareAspectRatio : getKnownAspectRatio(aspectRatio);
    const finalSrc = shouldBeSquare ? squareImageSrc : aspectImageSrc;

    // artwork card info
    const availabilityDot = getAvailabilityDot(
      artwork?.lastTransactionId,
      artwork?.isAvailableSale,
      artwork?.editionIsAvailableSale,
      artwork?.availablePrintAmount
    );

    const cardCTA = !artist?.contextId ? undefined : (
      <FollowButton
        predefine={hasSmallCTA ? 'SHORT_MEDIUM_LISTING_CARD_CTA' : 'TALL_WIDE_LISTING_CARD_CTA'}
        targetContextId={artist.contextId}
        isInitiallyRecognizing={isRecognizing}
      />
    );

    const infoCTA = !artwork?.artworkId ? undefined : (
      <FavoritesButton artworkId={artwork.artworkId} isFavorite={isFavorite} />
    );
    const prizeProps: ArtPrizeCardProps['prizeProps'] = {
      artPrizeTitle: artPrize?.title ?? '',
      slug: artPrize?.slug ?? '',
      place: artPrize?.position && isPositionType(artPrize?.position) ? artPrize?.position : undefined,
      maxWidth: `${width}px`,
    };
    return {
      prizeProps,
      cardTargetLinksProps: {
        top: artworkShowcaseLinkProps,
        bottom: artistProfileLinkProps,
      },
      artistChipProps: {
        image: artistAvatarSrc,
        avatarSize,
        textItems: [artistTitleTextProps, artistLocationTextProps],
      },
      imageProps: {
        aspectRatio: finalAspectRatio,
        src: finalSrc,
      },
      cardCTA,
      artworkInfoProps: {
        title: artwork?.title ?? undefined,
        details: artworkDetailsString,
        ctaStyle: artworkInfoCTAStyle,
        prefix: {
          title: availabilityDot,
        },
        infoCTA,
      },
      swipeContainerProps: hasOnlyOneColumn ? swipeContainerProps : undefined,
      isSquare: shouldBeSquare,
      isSwipeable: hasOnlyOneColumn,
    };
    // controlled re-computation
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    artistProfileLinkProps,
    artworkShowcaseLinkProps,
    hasPrize,
    hasArtist,
    hasArtwork,
    isFavorite,
    t,
    width,
    columnCount,
    isSquare,
    aspectRatio,
    width,
    artworkDetailsString,
    isRecognizing,
  ]);
};

export default useArtPrizeCardProps;
