import { GenericArtistCard } from 'common/components/ArtistCard/GenericArtistCard/GenericArtistCard';
import useAppSelector from 'common/hooks/useAppSelector';
import { useSelectedProfile } from 'common/hooks/useSelectedProfile';
import { Enum_Social_Graph_Followers_State_Enum } from 'common/schema/commonSchemaRemoteTypes';
import { RenderComponentProps } from 'masonic';
import FollowButton from 'common/components/RecognizeButtonV2/components/FollowButton';
import { useGetProfileGalleryArtistCardProps } from 'stacks/ProfileGallery/hooks/useGetProfileGalleryArtistCardProps';
import { ProfileGalleryArtistsElementProps } from './ProfileGalleryArtistsListElement';

const ProfileGalleryArtistCard: React.ComponentType<RenderComponentProps<ProfileGalleryArtistsElementProps>> = ({
  data: { artistContextId },
  width,
}) => {
  const { artist } = useAppSelector((store) => store.entities.artistCardsCache[artistContextId]) ?? {};
  const recognitionState = useAppSelector((store) => store.entities.recognitionsCache.outgoing[artistContextId]?.state);

  const currentContextId = useSelectedProfile()?.contextId;
  const isRecognizing =
    recognitionState === undefined ? undefined : recognitionState === Enum_Social_Graph_Followers_State_Enum.Active;
  const hasSmallCTA = width <= 600;
  const cardProps = useGetProfileGalleryArtistCardProps(artistContextId ?? 0, width);

  return (
    <GenericArtistCard {...cardProps}>
      {!!currentContextId && !!artist?.contextId && (
        <FollowButton
          predefine={hasSmallCTA ? 'SHORT_MEDIUM_LISTING_CARD_CTA' : 'TALL_WIDE_LISTING_CARD_CTA'}
          targetContextId={artist.contextId}
          isInitiallyRecognizing={isRecognizing}
        />
      )}
    </GenericArtistCard>
  );
};

export default ProfileGalleryArtistCard;
