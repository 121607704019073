import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type InfractionStatus = {
  blockFeatures: boolean;
  infractionsCounter: number;
};

export interface arrangeArtworksModalState {
  scammer: InfractionStatus;
}

const initialState: arrangeArtworksModalState = {
  scammer: {
    blockFeatures: false,
    infractionsCounter: 0,
  },
};

const securitySlice = createSlice({
  name: 'security',
  initialState,
  reducers: {
    SET_SCAMMER_STATUS: (state, action: PayloadAction<InfractionStatus>) => {
      state.scammer = action.payload;
    },
  },
});

export const securityActions = securitySlice.actions;
export default securitySlice;
