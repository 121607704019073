import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';
import useCooldownState from 'common/hooks/utils/useCooldownState';
import { Dispatch, SetStateAction, useEffect } from 'react';

type WindowSize = { width: number; height: number };

export const getWindowSize = (): WindowSize => ({
  width: window.innerWidth ?? window.visualViewport?.width ?? 1920,
  height: window.innerHeight ?? window.visualViewport?.height ?? 1080,
});

// xs      sm       md       lg       xl
// 0px     600px    960px    1280px   1920px
const breakpoints: {
  map: Record<Breakpoint, number>;
  order: Breakpoint[];
  values: number[];
} = {
  map: {
    xl: 0,
    lg: 1,
    md: 2,
    sm: 3,
    xs: 4,
  },
  order: ['xl', 'lg', 'md', 'sm', 'xs'],
  values: [1920, 1280, 960, 600, 0],
};

export const getBreakpoint = (value?: number): Breakpoint => {
  const size = value ?? getWindowSize().width;
  return breakpoints.order[breakpoints.values.findIndex((v) => v <= size)];
};

type CurrentWindowSize = {
  values: WindowSize;
  breakpoint: Breakpoint;
  down: (value: Breakpoint | number) => boolean;
  up: (value: Breakpoint | number) => boolean;
};

export const currentWindowSize: CurrentWindowSize = {
  values: getWindowSize(),
  breakpoint: getBreakpoint(),
  down(value) {
    if (typeof value === 'number') {
      return this.values.width <= value;
    }
    return breakpoints.map[this.breakpoint] >= breakpoints.map[value];
  },
  up(value) {
    if (typeof value === 'number') {
      return this.values.width >= value;
    }
    return breakpoints.map[this.breakpoint] <= breakpoints.map[value];
  },
};

let lastObserverId = 0;
const getObserverId = () => {
  lastObserverId += 1;
  return lastObserverId.toString();
};
const observers: Record<string, Dispatch<SetStateAction<number>>> = {};
export const useCurrentWindowSize = (cooldown?: number): CurrentWindowSize => {
  const [_, update] = useCooldownState(0, cooldown ?? 100);
  useEffect(() => {
    const id = getObserverId();
    observers[id] = update;
    return () => {
      delete observers[id];
    };
  }, [update]);
  return currentWindowSize;
};

if (window) {
  let timeoutRef = 0;
  window.addEventListener('resize', () => {
    if (timeoutRef) {
      window.clearTimeout(timeoutRef);
    }
    timeoutRef = window.setTimeout(() => {
      currentWindowSize.values = getWindowSize();
      currentWindowSize.breakpoint = getBreakpoint(currentWindowSize.values.width);
      Object.values(observers).forEach((update) => update((v) => v + 1));
    }, 50);
  });
}
