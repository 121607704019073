import { createSlice } from '@reduxjs/toolkit';
import { Enum_Dynamic_Cv_Section_Type_Enum, Enum_Dynamic_Cv_Status_Enum } from 'common/schema/commonSchemaRemoteTypes';
import {
  CVState,
  DynamicCVArtistDetails,
  DynamicCVAwardsData,
  DynamicCVCollectionsData,
  DynamicCVContactData,
  DynamicCVEducationData,
  DynamicCVExhibitionsData,
  DynamicCVPersonalInfoData,
  DynamicCVPublicationsData,
  DynamicCVStatus,
} from 'stacks/DynamicCV/utils/types';
import { arrayMove } from '@dnd-kit/sortable';

const initialState: CVState = {
  status: Enum_Dynamic_Cv_Status_Enum.Unpublished,
  sectionsWithUnsavedChanges: [],
  published_at: null,
  artistDetails: undefined,
  cards: {
    [Enum_Dynamic_Cv_Section_Type_Enum.PersonalInfo]: {
      data: null,
      index: 0,
      validateCallback: null,
    },
    [Enum_Dynamic_Cv_Section_Type_Enum.Education]: {
      data: [],
      index: 1,
      validateCallback: null,
    },
    [Enum_Dynamic_Cv_Section_Type_Enum.Awards]: {
      data: [],
      index: 3,
      validateCallback: null,
    },
    [Enum_Dynamic_Cv_Section_Type_Enum.Collections]: {
      data: [],
      index: 4,
      validateCallback: null,
    },
    [Enum_Dynamic_Cv_Section_Type_Enum.Contact]: {
      data: null,
      index: 6,
      validateCallback: null,
    },
    [Enum_Dynamic_Cv_Section_Type_Enum.Exhibitions]: {
      data: [],
      index: 2,
      validateCallback: null,
    },
    [Enum_Dynamic_Cv_Section_Type_Enum.Publications]: {
      data: [],
      index: 5,
      validateCallback: null,
    },
  },
  initialCards: null,
};

const dynamicCVSlice = createSlice({
  name: 'dynamicCV',
  initialState,
  reducers: {
    RESET: () => ({
      ...initialState,
    }),
    SET_CV_STATUS: (
      state,
      action: {
        payload: {
          status: DynamicCVStatus;
          published_at?: Date;
        };
      }
    ) => {
      state.status = action.payload.status;
      if (action.payload.published_at !== undefined) {
        state.published_at = action.payload.published_at;
      }
    },
    INIT_DYNAMIC_CV: (
      state,
      action: {
        payload: {
          status: Enum_Dynamic_Cv_Status_Enum;
          cards: Partial<CVState['cards']>;
          published_at: Date | null;
        };
      }
    ) => ({
      ...state,
      sectionsWithUnsavedChanges: [],
      status: action.payload.status,
      published_at: action.payload.published_at,
      cards: { ...state.cards, ...action.payload.cards },
      initialCards: { ...state.cards, ...action.payload.cards },
    }),
    CANCEL_EDIT: (state) => {
      if (state.initialCards) {
        return {
          ...state,
          cards: { ...state.initialCards },
        };
      }
      return state;
    },
    UPDATE_INITIAL_CARDS: (state) => ({
      ...state,
      initialCards: { ...state.cards },
    }),
    UPDATE_VALIDATE_CALLBACK: (
      state,
      action: {
        payload: {
          cardType: Enum_Dynamic_Cv_Section_Type_Enum;
          callback: () => void;
        };
      }
    ) => {
      state.cards[action.payload.cardType].validateCallback = action.payload.callback;
    },
    MARK_SECTION_DIRTY: (
      state,
      action: {
        payload: {
          cardType: Enum_Dynamic_Cv_Section_Type_Enum;
        };
      }
    ) => {
      if (!state.sectionsWithUnsavedChanges.includes(action.payload.cardType)) {
        state.sectionsWithUnsavedChanges.push(action.payload.cardType);
      }
    },
    MARK_SECTION_CLEAN: (
      state,
      action: {
        payload: {
          cardType: Enum_Dynamic_Cv_Section_Type_Enum;
        };
      }
    ) => {
      state.sectionsWithUnsavedChanges = state.sectionsWithUnsavedChanges.filter(
        (section) => section !== action.payload.cardType
      );
    },
    RESET_SECTIONS_WITH_UNSAVED_CHANGES: (state) => {
      state.sectionsWithUnsavedChanges = [];
    },
    UPSERT_EDUCATION: (state, action: { payload: { education: DynamicCVEducationData } }) => {
      const existingEducationIndex = state.cards[Enum_Dynamic_Cv_Section_Type_Enum.Education].data.findIndex(
        (education) => education.id === action.payload.education.id
      );
      if (existingEducationIndex === -1) {
        state.cards[Enum_Dynamic_Cv_Section_Type_Enum.Education].data.push(action.payload.education);
        return;
      }
      state.cards[Enum_Dynamic_Cv_Section_Type_Enum.Education].data[existingEducationIndex] = action.payload.education;
    },
    DELETE_EDUCATION: (state, action: { payload: { educationId: number } }) => {
      state.cards[Enum_Dynamic_Cv_Section_Type_Enum.Education].data = state.cards[
        Enum_Dynamic_Cv_Section_Type_Enum.Education
      ].data.filter((education) => education.id !== action.payload.educationId);
    },
    UPSERT_EXHIBITION: (state, action: { payload: { exhibition: DynamicCVExhibitionsData } }) => {
      const existingExhibitionIndex = state.cards[Enum_Dynamic_Cv_Section_Type_Enum.Exhibitions].data.findIndex(
        (exhibition) => exhibition.id === action.payload.exhibition.id
      );
      if (existingExhibitionIndex === -1) {
        state.cards[Enum_Dynamic_Cv_Section_Type_Enum.Exhibitions].data.push(action.payload.exhibition);
        return;
      }
      state.cards[Enum_Dynamic_Cv_Section_Type_Enum.Exhibitions].data[existingExhibitionIndex] =
        action.payload.exhibition;
    },
    DELETE_EXHIBITION: (state, action: { payload: { exhibitionId: number } }) => {
      state.cards[Enum_Dynamic_Cv_Section_Type_Enum.Exhibitions].data = state.cards[
        Enum_Dynamic_Cv_Section_Type_Enum.Exhibitions
      ].data.filter((exhibition) => exhibition.id !== action.payload.exhibitionId);
    },
    UPSERT_AWARD: (state, action: { payload: { award: DynamicCVAwardsData } }) => {
      const existingAwardIndex = state.cards[Enum_Dynamic_Cv_Section_Type_Enum.Awards].data.findIndex(
        (award) => award.id === action.payload.award.id
      );
      if (existingAwardIndex === -1) {
        state.cards[Enum_Dynamic_Cv_Section_Type_Enum.Awards].data.push(action.payload.award);
        return;
      }
      state.cards[Enum_Dynamic_Cv_Section_Type_Enum.Awards].data[existingAwardIndex] = action.payload.award;
    },
    DELETE_AWARD: (state, action: { payload: { awardId: number } }) => {
      state.cards[Enum_Dynamic_Cv_Section_Type_Enum.Awards].data = state.cards[
        Enum_Dynamic_Cv_Section_Type_Enum.Awards
      ].data.filter((award) => award.id !== action.payload.awardId);
    },
    UPSERT_COLLECTION: (state, action: { payload: { collection: DynamicCVCollectionsData } }) => {
      const existingCollectionIndex = state.cards[Enum_Dynamic_Cv_Section_Type_Enum.Collections].data.findIndex(
        (collection) => collection.id === action.payload.collection.id
      );
      if (existingCollectionIndex === -1) {
        state.cards[Enum_Dynamic_Cv_Section_Type_Enum.Collections].data.push(action.payload.collection);
        return;
      }
      state.cards[Enum_Dynamic_Cv_Section_Type_Enum.Collections].data[existingCollectionIndex] =
        action.payload.collection;
    },
    DELETE_COLLECTION: (state, action: { payload: { collectionId: number } }) => {
      state.cards[Enum_Dynamic_Cv_Section_Type_Enum.Collections].data = state.cards[
        Enum_Dynamic_Cv_Section_Type_Enum.Collections
      ].data.filter((collection) => collection.id !== action.payload.collectionId);
    },
    UPSERT_PUBLICATION: (state, action: { payload: { publication: DynamicCVPublicationsData } }) => {
      const existingPublicationIndex = state.cards[Enum_Dynamic_Cv_Section_Type_Enum.Publications].data.findIndex(
        (publication) => publication.id === action.payload.publication.id
      );
      if (existingPublicationIndex === -1) {
        state.cards[Enum_Dynamic_Cv_Section_Type_Enum.Publications].data.push(action.payload.publication);
        return;
      }
      state.cards[Enum_Dynamic_Cv_Section_Type_Enum.Publications].data[existingPublicationIndex] =
        action.payload.publication;
    },
    DELETE_PUBLICATION: (state, action: { payload: { publicationId: number } }) => {
      state.cards[Enum_Dynamic_Cv_Section_Type_Enum.Publications].data = state.cards[
        Enum_Dynamic_Cv_Section_Type_Enum.Publications
      ].data.filter((publication) => publication.id !== action.payload.publicationId);
    },
    UPSERT_CONTACT: (state, action: { payload: { contact: DynamicCVContactData } }) => {
      state.cards[Enum_Dynamic_Cv_Section_Type_Enum.Contact].data = action.payload.contact;
    },
    UPDATE_PERSONAL_INFO: (state, action: { payload: DynamicCVPersonalInfoData }) => {
      state.cards[Enum_Dynamic_Cv_Section_Type_Enum.PersonalInfo].data = action.payload;
    },
    UPDATE_ARTIST_DETAILS: (state, action: { payload: DynamicCVArtistDetails }) => {
      state.artistDetails = action.payload;
    },
    CHANGE_SECTION_ORDER: (
      state,
      action: {
        payload: {
          fromIndex: number;
          toIndex: number;
        };
      }
    ) => {
      const cardsOrdered = Object.entries(state.cards);
      cardsOrdered.sort(([, a], [, b]) => a.index - b.index);
      const cardsNewOrder = arrayMove(cardsOrdered, action.payload.fromIndex, action.payload.toIndex);
      cardsNewOrder.forEach(([key], index) => {
        state.cards[key as Enum_Dynamic_Cv_Section_Type_Enum].index = index;
      });
      state.cards = { ...state.cards };
    },
  },
});

export const dynamicCVActions = dynamicCVSlice.actions;
export default dynamicCVSlice;
