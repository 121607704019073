import { EventTriggers } from 'common/features/EventTrigger/store/eventTriggerSlice';
import useAppSelector from 'common/hooks/useAppSelector';
import _ from 'lodash';
import { RenderComponentProps } from 'masonic';
import { useMemo, useRef } from 'react';
import AdminWidget from 'stacks/Admin/Tools/AdminWidget/AdminWidget';
import { isSomeEnum } from 'utils/utilFunctions';
import { useHomeArtworksCardWasVisible } from '../hooks/useHomeArtworksCardWasVisible';
import { HomeArtworksListElementProps } from '../utils/homeArtworksTypes';
import HomeCardEventsEnum from '../utils/HomeCardEventsEnum';
import HomeArtworksEventPreviewCards from './HomeArtworksEventPreviewCards';
import HomeArtworksEventCards from './HomeArtworksEventCards';

// todo : fix this stupid workaround
export const toStartCase = (enumValue: string) => {
  const str = enumValue.trim();
  return _.startCase(_.lowerCase(str.startsWith('D_') ? `3${str}` : str));
};

const isHomeCardEventsEnum = isSomeEnum(HomeCardEventsEnum);

type HomeArtworksListElementType = React.ComponentType<RenderComponentProps<HomeArtworksListElementProps>>;

const ARTWORK_NOT_CACHED = {};

const footerSizes: Record<HomeCardEventsEnum, number> = {
  [HomeCardEventsEnum.PUBLISH]: 58,
  [HomeCardEventsEnum.PRIZE]: 58,
  [HomeCardEventsEnum.COLLECT]: 52,
};

const HomeArtworksListElement: HomeArtworksListElementType = (props) => {
  const {
    data: { artworkId, eventType, columnCount = 0, aspectRatio, publishedDate },
    index,
    width,
  } = props;

  const windowScrollSpeed = useAppSelector((store) => store.eventTriggers[EventTriggers.WindowIsScrolling]);

  const currentEventType = useMemo<HomeCardEventsEnum>(
    () => (isHomeCardEventsEnum(eventType) ? eventType : HomeCardEventsEnum.PUBLISH),
    [eventType]
  );

  const isMasonicView = columnCount > 1;
  const height =
    aspectRatio && isMasonicView ? width / (aspectRatio / 10000) + footerSizes[currentEventType] : undefined;

  const EventCard = useMemo(() => HomeArtworksEventCards[currentEventType], [currentEventType]);
  const EventCardPreview = useMemo(() => HomeArtworksEventPreviewCards[currentEventType], [currentEventType]);
  const { handle: artistHandle, contextId: artistContextId } =
    useAppSelector((store) => store.entities.artworkCardsCache[artworkId]?.artist ?? ARTWORK_NOT_CACHED) ?? {};

  const ref = useRef<HTMLDivElement | null>(null);
  const { wasVisibleAfterMounting: wasVisible } = useHomeArtworksCardWasVisible(
    index,
    columnCount,
    ref,
    artistHandle,
    artworkId
  );

  const usedSuperFastScroll = useRef(false);
  const isSuperFastScroll = windowScrollSpeed >= 4;
  usedSuperFastScroll.current = usedSuperFastScroll.current || isSuperFastScroll;

  const showCard = useRef(false);
  showCard.current =
    showCard.current || (wasVisible && !windowScrollSpeed) || (usedSuperFastScroll.current && windowScrollSpeed === 0);
  const onScreenRef = isSuperFastScroll ? {} : { ref };
  return (
    <div
      style={{
        position: 'relative',
        width,
        height,
      }}
      {...onScreenRef}
    >
      {(window as any).showScrollIndex === true && (
        <h3
          style={{
            position: 'absolute',
            color: 'yellowgreen',
            backgroundColor: 'black',
            zIndex: 999,
          }}
        >
          {`[${index}] ${artworkId} ${eventType} ${publishedDate} ${showCard.current}`}
        </h3>
      )}
      <AdminWidget artistContextId={artistContextId ?? undefined} artworkId={artworkId ?? undefined} />
      {!showCard.current && <EventCardPreview {...props} scrollSpeed={windowScrollSpeed} />}
      {showCard.current && <EventCard {...props} isVisible />}
    </div>
  );
};

export default HomeArtworksListElement;
