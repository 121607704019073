import { NetworkStatus } from '@apollo/client';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DEFAULT_MILESTONE_HIDDEN_VALUE } from 'common/features/Milestones/utils/milestonesConstants';
import {
  Milestone,
  MilestoneFeature,
  MilestonesMap,
  MilestoneStatus,
  MilestoneUpdateValues,
} from 'common/features/Milestones/utils/milestonesTypes';
import { getCurrentLockedFeature, getNextUnlockableFeature } from 'common/features/Milestones/utils/milestoneUtils';
import { isMilestoneFeatureType, isMilestoneStatus } from 'common/features/Milestones/utils/typeGuards';
import useAppSelector from 'common/hooks/useAppSelector';
import { GetMilestonesQuery } from 'common/schema/commonSchemaRemoteOperationTypes';
import { Maybe } from 'common/schema/commonSchemaRemoteTypes';

export type MilestonesStateType = {
  /**
   * The number of public artworks.
   */
  artworksCount: number | null;
  /**
   * Initial milestones values to check if we need to save any changes.
   */
  initialMilestones: MilestonesMap;
  /**
   * The milestones values used to manage the milestones logic.
   */
  milestones: MilestonesMap;
  /**
   * Current locked milestone feature
   */
  currentLockedFeature: MilestoneFeature | null;
  /**
   * Next milestone feature to unlock
   */
  nextUnlockableFeature: MilestoneFeature | null;
};

const initialState: MilestonesStateType = {
  artworksCount: null,
  initialMilestones: {},
  milestones: {},
  currentLockedFeature: null,
  nextUnlockableFeature: null,
};

const milestonesSlice = createSlice({
  name: 'milestones',
  initialState,
  reducers: {
    SET_ARTWORKS_COUNT: (state, action: PayloadAction<number | null>) => ({
      ...state,
      artworksCount: action.payload,
    }),
    INCREMENT_ARTWORKS_COUNT: (state, action: PayloadAction<number | undefined>) => ({
      ...state,
      artworksCount: (state.artworksCount ?? 0) + (action?.payload ?? 1),
    }),
    DECREMENT_ARTWORKS_COUNT: (state, action: PayloadAction<number | undefined>) => ({
      ...state,
      artworksCount: (state.artworksCount ?? 0) - (action?.payload ?? 1),
    }),
    UPDATE_MILESTONE: (
      state,
      action: PayloadAction<{
        feature: MilestoneFeature;
        values: Partial<MilestoneUpdateValues>;
        updateInitial?: boolean;
      }>
    ) => {
      const { feature, values, updateInitial } = action.payload;
      const existingMilestone = state.milestones[feature];
      if (!existingMilestone) {
        return state;
      }

      const updatedMilestone = {
        ...existingMilestone,
        ...values,
      };

      state.milestones[feature] = updatedMilestone;
      if (updateInitial) {
        state.initialMilestones[feature] = updatedMilestone;
      }

      if (updatedMilestone.status === 'UNLOCKED') {
        const current = updatedMilestone.nextFeature ?? getCurrentLockedFeature(state.milestones);
        const next = getNextUnlockableFeature(state.milestones, current);
        state.currentLockedFeature = current;
        state.nextUnlockableFeature = next;

        // update required milestone status too
        if (current && state.milestones[current]?.requiredMilestone) {
          const currentMilestone = state.milestones[current];
          if (currentMilestone?.requiredMilestone) {
            currentMilestone.requiredMilestone.status = updatedMilestone.status;
            state.milestones[current] = currentMilestone;
          }
        }
      }

      return state;
    },
    SET_MILESTONES: (state, action: PayloadAction<GetMilestonesQuery>) => {
      // SET MILESTONES
      action.payload.Milestone.forEach((m) => {
        const { id, feature, min_value, context_to_milestones } = m;

        if (isMilestoneFeatureType(feature)) {
          let contextMilestoneId: number | undefined;
          let status: Maybe<MilestoneStatus> = null; // why null and not ONGOING??
          const milestoneContexts = context_to_milestones.filter((c) => c.milestone_id === id);
          if (milestoneContexts.length) {
            contextMilestoneId = milestoneContexts[0].id;
            if (isMilestoneStatus(milestoneContexts[0].status)) {
              status = milestoneContexts[0].status;
            }
          }

          const milestone: Milestone = {
            id,
            feature,
            requiredMilestone: null,
            minValue: min_value,
            contextMilestoneId,
            status,
            networkStatus: NetworkStatus.ready,
            isHidden: DEFAULT_MILESTONE_HIDDEN_VALUE,
            showTooltip: status !== 'UNLOCKED',
          };

          state.milestones[feature] = milestone;
          state.initialMilestones[feature] = milestone;
        }
      });
      // SET MILESTONE'S REQUIRED MILESTONE && IS HIDDEN VALUE
      action.payload.Milestone.forEach((m) => {
        if (m.required_milestone) {
          const { id, feature: requiredFeature, min_value: minValue } = m.required_milestone;
          if (isMilestoneFeatureType(requiredFeature) && isMilestoneFeatureType(m.feature)) {
            const currentMilestone = state.milestones[m.feature];
            const requiredMilestone = state.milestones[requiredFeature];
            if (currentMilestone && requiredMilestone) {
              const milestone: Milestone = {
                ...currentMilestone,
                isHidden: requiredMilestone.status !== 'UNLOCKED',
                showTooltip: requiredMilestone.status === 'UNLOCKED',
                requiredMilestone: {
                  ...requiredMilestone,
                  id,
                  feature: requiredFeature,
                  minValue,
                  requiredMilestone: null,
                  networkStatus: NetworkStatus.ready,
                },
              };
              state.milestones[requiredFeature] = {
                ...requiredMilestone,
                nextFeature: currentMilestone.feature,
              };
              state.milestones[m.feature] = milestone;
            }
          }
        }
      });
      state.currentLockedFeature = getCurrentLockedFeature(state.milestones);
      state.nextUnlockableFeature = getNextUnlockableFeature(state.milestones, state.currentLockedFeature);
      return state;
    },
    SET_CURRENT_LOCKED_FEATURE: (state, action: PayloadAction<MilestoneFeature | null>) => ({
      ...state,
      currentLockedFeature: action.payload,
    }),
  },
});

export const useMilestonesState = () => useAppSelector((state) => state.milestones);

export const milestonesActions = milestonesSlice.actions;

export default milestonesSlice;
