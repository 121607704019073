import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useArtCollectedBadgeStyles } from './ArtCollectedBadge.styles';

export type ArtCollectedBadgeProps = {
  isVisible?: boolean;
  maxWidth?: string;
};

const ArtCollectedBadge: React.FC<ArtCollectedBadgeProps> = ({ isVisible, maxWidth }) => {
  const classes = useArtCollectedBadgeStyles();
  const { t } = useTranslation();
  return (
    <Box
      style={{ '--cardWidth': maxWidth ?? '200px' } as React.CSSProperties}
      className={clsx(classes.wrapper, isVisible ? classes.wrapperAnimation : '')}
    >
      <Box className={classes.badge}>
        <span className={clsx(classes.icon, isVisible ? classes.iconAnimation : '')} />
        <Box className={clsx(classes.badgeInfo, isVisible ? classes.badgeInfoAnimation : '')}>
          <Typography variant="h5">{t('cardStrings.badge.collected')}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default ArtCollectedBadge;
