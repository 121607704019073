import { SelectedProfile, useSelectedProfile } from 'common/hooks/useSelectedProfile';
import { useCallback } from 'react';
import { ANALYTICS_LINK, ENV_TYPE } from 'utils/constants';
import { getCookie, setCookie } from 'utils/utilFunctions';
import ReactGA4 from 'react-ga4';
import { Maybe } from 'yup/lib/types';
import reportWebVitals from 'reportWebVitals';
import { getWindowFlag, WindowFlags } from 'common/utils/constants';
import { store } from 'store/store';
import Auth from 'common/services/Auth';
import { Action, AnalyticsBody, Category, HitType } from './utils/analyticsTypes';
import { isMobile, shouldExcludeAnalytic, safeStringify } from './utils/analyticsUtils';
import { runtimeFlagUpdate } from '../FeatureFlag/config/features';

let cacheData: any;
let cacheCompactData: any = [];
let interval;

// Clear interval if it already exists
if (interval) {
  clearInterval(interval);
}

// parse query params
function parseQuery(queryString: string) {
  if (typeof queryString !== 'string' || queryString === '') {
    return {};
  }

  const query: Record<string, string> = {};
  const pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
  for (let i = 0; i < pairs.length; i++) {
    const pair = pairs[i].split('=');
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
  }
  return query;
}

// Build common data
const buildData = <T extends Category>(
  hit: HitType,
  eventCategory: T,
  eventAction: string,
  data: Record<string, string>,
  currentAnalyticsUser: string,
  selectedProfile: Maybe<SelectedProfile> | any
) => {
  const body: AnalyticsBody = {
    tags: data,
    hitType: hit,
    eventCategory,
    eventAction,
  };

  const visitorIdCookie = getCookie('visitorId') || '';

  body.tags = {
    ...body.tags,
    ...{
      mobile: isMobile(),
      currentUser: currentAnalyticsUser,
      currentUserProfileId: selectedProfile?.profileId || '',
      currentUserProfileContextId: selectedProfile?.contextId || '',
      currentUserProfile: selectedProfile?.type || '',
      currentUserProfileSubType: selectedProfile?.profileSubType || '',
      currentUserLanguage: navigator.language,
      env: ENV_TYPE,
      eventSide: store.getState().featureFlags.unityWebView === 'active' ? 'KAR' : 'FE',
      currentUrl: window.location.href,
      host: window.location.host,
      visitorID: visitorIdCookie,
      previousPage: document.referrer || '',
      queryParams: parseQuery(window.location.search),
      currentTime: Date.now(),
      customFeatures: runtimeFlagUpdate,
      isPremiumUser: store.getState().subscriptions.isPremium,
      userSubscriptions: store.getState().subscriptions.subscriptions,
      subscriptionStatus: store.getState().subscriptions.subscriptionStatus,
      subscriptionPeriod: store.getState().subscriptions.subscriptionPeriod,
    },
  };

  return body;
};

const sendData = (body: AnalyticsBody[]) => {
  if (getWindowFlag(WindowFlags.SKIP_KALEIDO_ANALYTICS)) {
    return;
  }

  // Initiate a beacon request
  // This was disabled because we use the response to set the visitor_id. The visitor_ID kept changing with this approach
  // navigator.sendBeacon(`${ANALYTICS_LINK}/collector`, JSON.stringify({ data: body }));
  fetch(`${ANALYTICS_LINK}/collector`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: safeStringify({ data: body }),
  })
    .then((response) => response.json())
    .then((dataResulted) => {
      const visitorId = getCookie('visitorId');

      if (!visitorId || visitorId === 'null') {
        setCookie('visitorId', String(dataResulted.visitorID));
      }
    })
    .catch((error) => {
      console.error('Error:', error);
    });

  // this only sends the first event of the array, currently we only have this action in the future we need to extend this if we send multiple events
  ReactGA4.event({
    category: body[0].eventCategory,
    action: body[0].eventAction,
    label: `${body[0].hitType}:${body[0].eventAction}`,
    transport: 'beacon', // optional, beacon/xhr/image
  });
};

// extended old way
const useAnalytics = () => {
  const currentAnalyticsUser = Auth.useFirebaseUserId()?.toString() || 'guest';
  const selectedProfile = useSelectedProfile();

  const postData = useCallback(
    <T extends Category>(hit: HitType, eventCategory: T, eventAction: Action<T>, data: any) => {
      const body = buildData(hit, eventCategory, String(eventAction), data, currentAnalyticsUser, selectedProfile);

      if (shouldExcludeAnalytic(body)) {
        return;
      }

      sendData([body]);
    },
    [currentAnalyticsUser, selectedProfile]
  );

  // alternative way to push impressions
  const postImpressions = useCallback(
    (event: any) => {
      const body = buildData(
        event.hitType,
        event.eventCategory,
        event.eventAction,
        event.tags,
        currentAnalyticsUser,
        selectedProfile
      );
      if (cacheData && !shouldExcludeAnalytic(body) && body.eventCategory !== cacheData.eventCategory) {
        if (cacheData.tags.currentUrl === body.tags.currentUrl) {
          cacheData.tags.impressions = cacheCompactData;
          sendData([cacheData]);
        }

        cacheData = null;
        cacheCompactData = [];
      }

      if (!cacheData && !shouldExcludeAnalytic(body)) {
        cacheData = body;
      }

      if (!shouldExcludeAnalytic(body)) {
        cacheCompactData.push(event.impressions);
      }
    },
    [currentAnalyticsUser, selectedProfile]
  );

  return {
    postData,
    postImpressions,
  };
};

// Send data
interval = setInterval(() => {
  if (cacheData) {
    cacheData.tags.impressions = cacheCompactData;
    sendData([cacheData]);
  }
  cacheData = null;
  cacheCompactData = [];
}, 5000);

function sendPerformanceAnalytics(metricData: any) {
  const body = buildData('STARTED', 'PERFORMANCE', 'WEB_VITALS', { metricData }, '0', {
    contextId: window.localStorage.selectedContextId || 0,
    profileId: window.localStorage.selectedProfileId || 0,
  });

  sendData([body]);
}

try {
  reportWebVitals(sendPerformanceAnalytics);
} catch (e) {
  console.log(e);
}

// TODO: Use something like this in the future
// const attachEventClick = () => {
//   document.addEventListener('click', (element: any) => {
//     console.log(element?.target.getAttribute('js-analytics'));
//   });
// };
// attachEventClick();

export default useAnalytics;
