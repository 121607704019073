import { combineReducers } from 'redux';
import { collectorsListSlice } from './list.slice';
import { collectorsFiltersSlice } from './filter.slice';
import { collectorsDisplaySlice } from './display.slice';

export const actions = {
  ...collectorsListSlice.actions,
  ...collectorsFiltersSlice.actions,
  ...collectorsDisplaySlice.actions,
};

export const collectorsDashboardSlice = {
  collectorsDashboard: combineReducers({
    [collectorsListSlice.name]: collectorsListSlice.reducer,
    [collectorsFiltersSlice.name]: collectorsFiltersSlice.reducer,
    [collectorsDisplaySlice.name]: collectorsDisplaySlice.reducer,
  }),
};
