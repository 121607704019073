import { combineReducers } from 'redux';
import { nearbyArtistsListSlice } from './list.slice';
import { nearbyArtistsFiltersSlice } from './filter.slice';
import { nearbyArtistsDisplaySlice } from './display.slice';

export const nearbyArtistsActions = {
  ...nearbyArtistsListSlice.actions,
  ...nearbyArtistsFiltersSlice.actions,
  ...nearbyArtistsDisplaySlice.actions,
};

export const nearbyArtistsSlice = {
  nearbyArtists: combineReducers({
    [nearbyArtistsListSlice.name]: nearbyArtistsListSlice.reducer,
    [nearbyArtistsFiltersSlice.name]: nearbyArtistsFiltersSlice.reducer,
    [nearbyArtistsDisplaySlice.name]: nearbyArtistsDisplaySlice.reducer,
  }),
};
