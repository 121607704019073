import { Link } from 'react-router-dom';
import GoBack from './GoBack';
import UnstyledAnchor from './UnstyledAnchor';

const Links = {
  GoBack,
  UnstyledAnchor,
  Anchor: Link,
};

export default Links;
