import React, { useMemo } from 'react';
import { CDNUrl } from 'utils/constants';
import { RatingScoreType } from '../../utils/types';
import { useRatingStarsStyles } from './RatingStars.styles';

const RatingStars: React.FC<RatingScoreType> = ({ score, maxScore = 5, isDarkMode }) => {
  const classes = useRatingStarsStyles({ isDarkMode });
  const starRatingIcons = useMemo(() => {
    if (!score) {
      return null;
    }

    const starsIcons = Array.from({ length: maxScore }, (_, i) => (
      <img
        key={i + 1}
        src={`${CDNUrl}/images/artFeed/${i + 1 <= (score || 0) ? 'filled' : 'empty'}-round-star.svg`}
        alt={`${i + 1 <= (score || 0) ? 'filled' : 'empty'}-star-icon`}
      />
    ));

    return starsIcons;
  }, [score, maxScore]);

  return <div className={classes.container}>{starRatingIcons}</div>;
};

export default RatingStars;
