import { TypeGuard } from 'common/utils/types';
import { ListingDirection, listingDirections } from './listingDirections';

export const listingStatusLoadingIdentifier = 'LOADING' as const;
export type LoadingIdentifier = typeof listingStatusLoadingIdentifier;
export type ListingStatus = 'COLD' | 'FINISHED' | 'IDLE' | `${LoadingIdentifier}_${ListingDirection}`;
export const isListingStatus: TypeGuard<ListingStatus> = (possibleStatus: unknown): possibleStatus is ListingStatus => {
  if (!possibleStatus) {
    return false;
  }
  return (
    ['COLD', 'FINISHED', 'IDLE'].includes(possibleStatus as ListingStatus) ||
    (possibleStatus as string).startsWith(listingStatusLoadingIdentifier) ||
    listingDirections.some((direction) => (possibleStatus as string).endsWith(direction))
  );
};
export const defaultListingStatus: ListingStatus = 'COLD';
export const LIST_LOADING_STATES: ListingStatus[] = ['LOADING_BACKWARD', 'LOADING_FORWARD'];
