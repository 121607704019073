import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NotificationDataType } from 'common/features/NotificationCenter/utils/notificationCenterTypes';

type NotificationEntities = Record<string, NotificationDataType>;

const initialState: NotificationEntities = {};

export const notificationsSlice = createSlice({
  name: 'notificationsCache',
  initialState,
  reducers: {
    UPDATE_NOTIFICATIONS: (state, action: PayloadAction<NotificationDataType[]>) => {
      action.payload.forEach((notif) => {
        if (!notif.id) {
          return;
        }
        state[notif.id] = notif;
      });
    },
    DISMISS_NOTIFICATION: (state, action: PayloadAction<number>) => {
      state[action.payload].isDismissed = true;
    },
    INVALIDATE_NOTIFICATION: (state, action: PayloadAction<number>) => {
      delete state[action.payload];
    },
  },
});
