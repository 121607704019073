import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GetCollectedArtworksQuery } from 'common/schema/commonSchemaRemoteOperationTypes';

export const collectedArtworksSlice = createSlice({
  name: 'homeCollected',
  initialState: null as GetCollectedArtworksQuery | null,
  reducers: {
    UPDATE_COLLECTED_ARTWORKS: (_, action: PayloadAction<GetCollectedArtworksQuery>) => action.payload,
  },
});
