export enum socialMediaEnum {
  'WEBSITE' = 'WEBSITE',
  'INSTAGRAM' = 'INSTAGRAM',
  'TWITTER' = 'TWITTER',
  'FACEBOOK' = 'FACEBOOK',
  'PINTEREST' = 'PINTEREST',
  'TIKTOK' = 'TIKTOK',
  'YOUTUBE' = 'YOUTUBE',
  'CUSTOMLINK' = 'CUSTOMLINK',
}

export enum profileSocialTypesEnum {
  'WEBSITE' = 'WEBSITE',
  'INSTAGRAM' = 'INSTAGRAM',
  'TWITTER' = 'TWITTER',
  'FACEBOOK' = 'FACEBOOK',
  'PINTEREST' = 'PINTEREST',
  'TIKTOK' = 'TIKTOK',
  'YOUTUBE' = 'YOUTUBE',
}
