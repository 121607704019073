import { createSlice } from '@reduxjs/toolkit';

export enum StaticPages {
  FAVORITES = 'FAVORITES',
  RECOGNITIONS = 'RECOGNITIONS',
  PRIZES = 'PRIZES',
  COLLECTIONS = 'COLLECTIONS',
  EXPLORE = 'EXPLORE',
  PROFILE_GALLERY = 'PROFILE_GALLERY',
  PROFILE_COLLECTOR = 'PROFILE_COLLECTOR',
  LIVE = 'LIVE',
}

export enum DynamicPages {
  ARTIST_PROFILE = 'ARTIST_PROFILE',
  COLLECTOR_PROFILE = 'COLLECTOR_PROFILE',
  SHOWROOM = 'SHOWROOM',
  WINNERS_PAGE = 'WINNERS_PAGE',
  COLLECTION_PAGE = 'COLLECTION_PAGE',
}

type PersistentScrollDynamicType = {
  [identifier in string]?: number;
};

type PersistentScrollSliceType = {
  [scrollType in StaticPages]: number;
} & {
  [scrollType in DynamicPages]: PersistentScrollDynamicType;
};

const getInitialState: () => PersistentScrollSliceType = () => ({
  [StaticPages.FAVORITES]: 0,
  [StaticPages.RECOGNITIONS]: 0,
  [StaticPages.PRIZES]: 0,
  [StaticPages.COLLECTIONS]: 0,
  [StaticPages.EXPLORE]: 0,
  [StaticPages.PROFILE_GALLERY]: 0,
  [StaticPages.PROFILE_COLLECTOR]: 0,
  [StaticPages.LIVE]: 0,
  [DynamicPages.ARTIST_PROFILE]: {},
  [DynamicPages.COLLECTOR_PROFILE]: {},
  [DynamicPages.SHOWROOM]: {},
  [DynamicPages.WINNERS_PAGE]: {},
  [DynamicPages.COLLECTION_PAGE]: {},
});

const persistentVerticalScrollSlice = createSlice({
  name: 'persistentScroll',
  initialState: getInitialState(),
  reducers: {
    UPDATE_SCROLL_Y_POSITION_STATIC_PAGE: (
      state,
      action: {
        payload: {
          type: StaticPages;
          scrollYPosition: number;
        };
      }
    ) => ({
      ...state,
      [action.payload.type]: action.payload.scrollYPosition,
    }),
    UPDATE_SCROLL_Y_POSITION_DYNAMIC_PAGE: (
      state,
      action: {
        payload: {
          type: DynamicPages;
          scrollYPosition: number;
          extra: {
            identifier: string;
          };
        };
      }
    ) => ({
      ...state,
      [action.payload.type]: {
        [action.payload.extra.identifier]: action.payload.scrollYPosition,
      },
    }),
  },
});

export const persistentVerticalScrollActions = persistentVerticalScrollSlice.actions;

export default persistentVerticalScrollSlice;
