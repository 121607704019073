import { IS_CUSTOM_DOMAINS_APP } from 'utils/constants';
import { useDispatch } from 'react-redux';
import { domainActions } from 'store/slices/domainSlice';
import { useEffect } from 'react';
import useConnectedDomain from './hooks/useConnectedDomain';

const CustomDomainProvider: React.FC = ({ children }) => {
  const dispatch = useDispatch();
  const customDomainData = useConnectedDomain(new URL(window.location.href));

  // constant if - comparing a constant, so we can put a hook in it
  if (IS_CUSTOM_DOMAINS_APP) {
    useEffect(() => {
      dispatch(
        domainActions.SET_DOMAIN_DATA({
          domain: customDomainData,
        })
      );
    }, [customDomainData, dispatch]);
  }
  return <>{children}</>;
};

export default CustomDomainProvider;
