import { combineReducers } from 'redux';
import { nearbyGalleriesListSlice } from './list.slice';
import { nearbyGalleriesFiltersSlice } from './filter.slice';

export const nearbyGalleriesActions = {
  ...nearbyGalleriesListSlice.actions,
  ...nearbyGalleriesFiltersSlice.actions,
};

export const nearbyGalleriesSlice = {
  nearbyGalleries: combineReducers({
    [nearbyGalleriesListSlice.name]: nearbyGalleriesListSlice.reducer,
    [nearbyGalleriesFiltersSlice.name]: nearbyGalleriesFiltersSlice.reducer,
  }),
};
