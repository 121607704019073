import { InterestsFilterState } from './interests.types';

// 0 = All option
export const getDefaultInterestFilter = (): InterestsFilterState => ({
  interests: {
    Discipline: { 0: true },
    Genres: { 0: true },
    'Identity Based': { 0: true },
    Miscellaneous: { 0: true },
  },
});
export const defaultInterestFilter = getDefaultInterestFilter();
