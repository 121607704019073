import Sentry from 'common/features/Sentry/Sentry';
import { ExpectedError } from 'utils/ExpectedError';
// import store from 'store/store';

const log = {
  info: console.info,
  log: console.log,
  warn: console.warn,
  error: console.error,
};

export const logger = {
  info: (message: string) => {
    log.info(`Time: ${Date()}`);
    log.info(message);
  },
  warn: (message: string) => {
    log.warn(`Warn Time: ${Date()}`);
    log.warn(message);
  },
  error: (error: Error, ...rest: unknown[]): void => {
    log.error(error);
    log.info(...rest);

    if (error instanceof ExpectedError) {
      return;
    }

    // TODO : fix/improve this
    Sentry.client.withScope((scope) => {
      let possibleFingerprint = [JSON.stringify(error)];
      if (possibleFingerprint[0] === '{}') {
        possibleFingerprint = [error.name, error.message, error.stack ?? 'no-stack'];
      }
      scope.setFingerprint(possibleFingerprint);
      Sentry.client.captureException(error);
    });
  },
  handleCatch: (error: Error) => {
    log.error(`catch error, time:  ${Date()}. error: ${error.name} - ${error.message}`);
    log.error(error.stack);
  },
  handleHttpError: async (httpResponse: Response) => {
    // .json() can only be used once
    const json = await httpResponse.json();
    log.error(
      `http error, time:  ${Date()}. status code: ${httpResponse.statusText}-${httpResponse.statusText}, response:`
    );
    log.error(json);
  },
};
