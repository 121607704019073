import { OrientationFilterState } from 'common/features/Filters/predefined/Orientation/store/orientation.types';
import { PriceRangeFilterState } from 'common/features/Filters/predefined/PriceRange/store/priceRange.types';
import { AvailabilityFilterState } from 'common/features/Filters/predefined/Availability/store/availability.types';
import { SortState } from 'common/features/Filters/predefined/SortGroup/store/sort.types';
import { SortOptionsEnum } from 'common/features/VirtualList/VirtualList.config';

export const sorts = [SortOptionsEnum.MOST_RECENT, SortOptionsEnum.LEAST_RECENT] as const;
export type FavoritesSort = (typeof sorts)[0] | (typeof sorts)[1];
export const isFavoritesort = (v: string): v is FavoritesSort => sorts.includes(v as FavoritesSort);

// currently auto-applies on every change
// maybe todo: add flag to apply on demand
export type FavoritesFiltersState = OrientationFilterState &
  PriceRangeFilterState &
  AvailabilityFilterState &
  SortState;
