import React, { useCallback, useRef } from 'react';
import useAsyncState from './useAsyncState';

const useDebouncedState = <T>(initialValue: T, debounceTime = 500): [T, React.Dispatch<React.SetStateAction<T>>] => {
  const [state, setState] = useAsyncState(initialValue);
  const timer = useRef<number | null>(null);

  const setDebouncedState: React.Dispatch<React.SetStateAction<T>> = useCallback((value: React.SetStateAction<T>) => {
    if (timer.current !== null) {
      window.clearTimeout(timer.current);
    }
    timer.current = window.setTimeout(() => {
      setState(value);
      timer.current = null;
    }, debounceTime);
  }, []);

  return [state, setDebouncedState];
};

export default useDebouncedState;
