import React, { CSSProperties } from 'react';
import { Grid } from '@material-ui/core';
import { ArtistInfoChipProps } from 'common/components/ArtistInfoChip/ArtistInfoChip';
import { BasicImageTypePropsExtra } from 'common/components/Image/types';
import ArtworkCardComponents from '../common';
import CustomCardComponents from '../GenericArtworkCard/components';
import { ArtworkInformationProps } from '../common/ArtworkInformation';
import BadgePreview from '../common/BadgePreview';

export type ArtPrizeCardPreviewProps = {
  artistChipProps?: ArtistInfoChipProps;
  imageProps?: BasicImageTypePropsExtra<{
    aspectRatio?: CSSProperties['aspectRatio'];
    blur?: number;
  }>;
  isSquare?: boolean;
  artworkInfoProps?: ArtworkInformationProps;
  cardCTAPreview?: React.ReactNode;
};

export const ArtPrizeCardPreview: React.FC<ArtPrizeCardPreviewProps> = React.memo(
  ({ isSquare, imageProps, artistChipProps, artworkInfoProps, cardCTAPreview }) => (
    <ArtworkCardComponents.SwipeCardTopBottomLayout>
      <BadgePreview />
      <ArtworkCardComponents.ImageInfoLayout>
        <ArtworkCardComponents.ArtworkInformation {...(artworkInfoProps ?? {})} />
        {!isSquare ? (
          <ArtworkCardComponents.SoloAspectRatioImage {...(imageProps ?? {})} />
        ) : (
          <ArtworkCardComponents.SoloSquareImage {...(imageProps ?? {})} />
        )}
      </ArtworkCardComponents.ImageInfoLayout>
      <ArtworkCardComponents.FooterLayout>
        <Grid item style={{ minWidth: '40%' }}>
          <CustomCardComponents.ArtistInfoChip {...(artistChipProps ?? {})} />
        </Grid>
        <Grid item style={{ flexShrink: 0 }}>
          {cardCTAPreview}
        </Grid>
      </ArtworkCardComponents.FooterLayout>
    </ArtworkCardComponents.SwipeCardTopBottomLayout>
  )
);
