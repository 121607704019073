import useAppSelector from 'common/hooks/useAppSelector';
import { getCDNImageUrl } from 'utils/CDNImages';
import { fillerImages } from 'common/components/Image/utils/fillerImages';
import { BasicImageTypeProps } from 'common/components/Image/types';
import { getCountryFullName } from 'common/hooks/useCountries';
import { useRouteMatch } from 'react-router';

export const useGetProfileGalleryArtistCardProps = (artistContextId: number, width: number) => {
  const { handle: galleryHandle } = useRouteMatch<{ handle: string }>().params;
  const { artist, artworks } = useAppSelector((store) => store.entities.artistCardsCache[artistContextId]) ?? {};
  const hasSmallCTA = width <= 600;

  return {
    cardTargetSrc: artist?.handle && galleryHandle ? `/${artist?.handle}/gallery/${galleryHandle}` : '/404',
    artistChipProps: {
      image: !artist?.profileId
        ? undefined
        : getCDNImageUrl({
            target: 'Profile',
            purpose: 'Avatar',
            targetId: artist?.profileId ?? 0,
            fileId: artist?.avatar ?? 0,
            meta: {
              size: 'large',
            },
          }),
      avatarSize: hasSmallCTA ? '40px' : '40px',
      textItems: [
        { text: artist?.title ?? undefined },
        {
          text:
            artist === undefined
              ? undefined
              : [artist?.city, getCountryFullName(artist?.country ?? '')].filter((v) => !!v).join(', '),
        },
      ],
    },
    tripleImageProps: fillerImages(
      artworks?.map<BasicImageTypeProps>((piece, idx) => ({
        src: getCDNImageUrl({
          target: 'Artwork',
          purpose: 'Thumbnail',
          meta: {
            size: idx === 0 ? 'large' : 'medium',
          },
          fileId: piece.thumbnailMetadataId ?? 0,
          targetId: piece.id ?? 0,
        }),
      })) ?? []
    ),
  };
};
