import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type HeaderSliceType = {
  artistName: string;
  profileImage: string;
  artistHandle?: string;
};
const initialState: HeaderSliceType = {
  artistName: '',
  profileImage: '',
  artistHandle: '',
};

const headerSlice = createSlice({
  initialState,
  name: 'header',
  reducers: {
    SET_DATA: (state, action: PayloadAction<HeaderSliceType>) => {
      state.artistName = action.payload.artistName;
      state.profileImage = action.payload.profileImage;
      state.artistHandle = action.payload.artistHandle;
    },
  },
});

export const headerActions = headerSlice.actions;
export const headerReducer = headerSlice.reducer;

export default headerSlice;
