import { createSlice } from '@reduxjs/toolkit';
import { SLIDESHOW_ARTWORK_LIBRARY } from 'common/components/RegisterOnboardingLayout/ArtworkSlideshow/utils/artworkLibrary';

export interface onboardingArtworkSlideshowState {
  startTimestamp?: Date;
  currentSlideIndex: number;
}

const initialState: onboardingArtworkSlideshowState = {
  currentSlideIndex: 0,
};

const onboardingArtworkSlideshowSlice = createSlice({
  name: 'onboardingArtworkSlideshow',
  initialState,
  reducers: {
    NEXT_SLIDE: (state) => {
      state.currentSlideIndex = (state.currentSlideIndex + 1) % SLIDESHOW_ARTWORK_LIBRARY.length;
      state.startTimestamp = new Date();
    },
    RESET_SLIDESHOW_STATE: () => initialState,
  },
});

export const onboardingArtworkSlideshowActions = onboardingArtworkSlideshowSlice.actions;
export default onboardingArtworkSlideshowSlice;
