import { Grid } from '@material-ui/core';
import Links from 'common/components/Links/Links';
import { useIsMobileScreen } from 'common/hooks/utils/useIsMobileScreen';
import ArtistCardComponents from '.';
import SwipeInfoContainer from './SwipeInfoContainer/SwipeInfoContainer';

export const SwipeCardTopBottomLayout: React.FC<{
  containerProps?: Parameters<typeof Grid>['0'];
  topProps?: Parameters<typeof Grid>['0'];
  bottomProps?: Parameters<typeof Grid>['0'];
  children: [
    React.ReactElement<typeof SwipeInfoContainer>,
    React.ReactElement<typeof Links.UnstyledAnchor>,
    React.ReactElement<typeof ArtistCardComponents.FooterLayout> | undefined
  ];
}> = ({ children, topProps, bottomProps, containerProps }) => {
  const isMobile = useIsMobileScreen();
  return (
    <Grid container direction="row" justifyContent="space-between" alignItems="center" {...(containerProps ?? {})}>
      <Grid item xs={12} {...(topProps ?? {})}>
        {children[0]}
        {children[1]}
      </Grid>
      {children[2] && (
        <Grid item xs={12} style={{ paddingTop: isMobile ? '8px' : '12px' }} {...(bottomProps ?? {})}>
          {children[2]}
        </Grid>
      )}
    </Grid>
  );
};
