import { ArtistSocialLinks } from 'stacks/ArtistSidebar/components/Tabs/ArtistSidebarData';
import { getSocialLinkURL } from 'utils/utilFunctions';
import { Profile_Social_Links } from 'common/schema/commonSchemaRemoteTypes';

export const getSocialLinks = (
  socialMedias: Pick<Profile_Social_Links, 'id' | 'URL' | 'platform'>[]
): ArtistSocialLinks => ({
  personalWebsite: getSocialLinkURL(socialMedias, 'WEBSITE'),
  instagram: getSocialLinkURL(socialMedias, 'INSTAGRAM'),
  twitter: getSocialLinkURL(socialMedias, 'TWITTER'),
  facebook: getSocialLinkURL(socialMedias, 'FACEBOOK'),
  pinterest: getSocialLinkURL(socialMedias, 'PINTEREST'),
  tiktok: getSocialLinkURL(socialMedias, 'TIKTOK'),
  youtube: getSocialLinkURL(socialMedias, 'YOUTUBE'),
});

export const getCityAndCountryAndZipCode = (city: string, country: string, zipCode: string): string => {
  if (city && country && zipCode) {
    return `${city}, ${country} ${zipCode}`;
  }
  if (!city && country && zipCode) {
    return `${country} ${zipCode}`;
  }
  if (city && !country && zipCode) {
    return `${city}, ${zipCode}`;
  }
  if (!city && !country && zipCode) {
    return zipCode;
  }
  if (!city && country && !zipCode) {
    return country;
  }
  return `${city}, ${country} ${zipCode}`;
};
