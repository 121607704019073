import { Dispatch } from '@reduxjs/toolkit';
import { admirationsModifiersActions, AdmirationsModifiersObjectType } from 'store/slices/admirationsModifiersSlice';
import { Enum_Social_Graph_Followers_State_Enum } from 'common/schema/commonSchemaRemoteTypes';
import { recognitionsSlice } from 'store/slices/entities/recognitions';

// todo : refactor this to use entities
export const updateRecognitionsState = (
  newStateValue: Enum_Social_Graph_Followers_State_Enum,
  resultData: AdmirationsModifiersObjectType,
  isRecognizing: boolean,
  dispatch: Dispatch<any>
) => {
  if (newStateValue === Enum_Social_Graph_Followers_State_Enum.Declined) {
    dispatch(admirationsModifiersActions.REMOVE_NEW_RECOGNITION(resultData));
    dispatch(admirationsModifiersActions.ADD_IGNORED_RECOGNITION(resultData));
    dispatch(recognitionsSlice.actions.DECLINE(resultData.target_context_id));
  } else if (isRecognizing) {
    dispatch(admirationsModifiersActions.REMOVE_ADMIRATION(resultData));
    dispatch(recognitionsSlice.actions.UNRECOGNIZE(resultData.target_context_id));
  } else {
    dispatch(admirationsModifiersActions.RECOGNIZE_BACK(resultData));
    dispatch(recognitionsSlice.actions.RECOGNIZE(resultData.target_context_id));
  }
};
