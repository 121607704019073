import { logger } from '@old/services/logger';
import { useShouldShowFeatureFlag } from 'common/features/FeatureFlag/hooks/useFeatureFlag';
import { useDeactivateMyFcmTokenMutation } from 'common/schema/commonSchemaRemoteGraphqlQueries';
import { PermissionTypes } from 'stacks/PermissionsModal/store/permissionsStateSlice';
import useAppSelector from '../../../hooks/useAppSelector';
import { useSelectedProfile } from '../../../hooks/useSelectedProfile';

export const usePreLogoutActions = () => {
  const [isUnityWebview] = useShouldShowFeatureFlag('unityWebView');
  const permissionsData = useAppSelector((store) => store.permissionsState);
  const selectedProfile = useSelectedProfile();
  const { deviceId } = permissionsData[PermissionTypes.Notifications];
  const [deactivateFCMToken] = useDeactivateMyFcmTokenMutation();

  const handlePreLogoutActions = () => {
    // #1. Deactivate FCM token
    if (isUnityWebview) {
      deactivateFCMToken({ variables: { platform: deviceId } })
        .then((result) => {
          if (!result.data?.deactivateMyFCMToken) {
            logger.info(`No FCM token found for device id: ${deviceId}.`);
          }
        })
        .catch((error) => {
          logger.error(
            error,
            `Error deactivating FCM token on logout, for profile ${selectedProfile?.id}, device id: ${deviceId}.`
          );
        });
    }
  };

  return handlePreLogoutActions;
};
