import { makeStyles } from '@material-ui/core';

export const useLoadingLogoStyles = makeStyles((theme) => ({
  container: {
    transition: theme.transitions.create('background-color', { duration: '300ms' }),
    ...theme.mixins.flexCentered,
    // trick to deal with safari
    // source : https://stackoverflow.com/questions/52508598/html5-video-element-on-iphone-has-border/53779216
    // idt it should affect anything else
    '-webkit-mask-image': '-webkit-radial-gradient(white, black)',
    '-webkit-backface-visibility': 'hidden',
    '-moz-backface-visibility': 'hidden',
    zIndex: -1,
    visibility: 'hidden',
    pointerEvents: 'none',

    '.loading &, &.loading': {
      zIndex: 1400,
      visibility: 'visible',
      pointerEvents: 'auto',
    },
    '&.suspense.loading': {
      zIndex: 1400,
      visibility: 'visible',
      pointerEvents: 'auto',
    },
    '.loading &.suspense.loading': {
      zIndex: -1,
      visibility: 'hidden',
      pointerEvents: 'none',
    },
  },
  fixedLogoChild: {
    '&>img': {
      position: 'fixed',
      top: 'calc(50% - 75px)',
    },
  },
  fixed: {
    position: 'fixed',
  },
  absolute: {
    position: 'absolute',
  },
  viewportSize: {
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
  },
  containerSize: {
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
  backgroundDark: {
    backgroundColor: 'black',
  },
  backgroundLight: {
    backgroundColor: 'white',
  },
}));
