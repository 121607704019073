import { makeStyles } from '@material-ui/core';
import { SAFE_AREA_BOTTOM } from 'common/components/GlassmorphicNotch/GlassmorphicNotch';
import { MOBILE_BREAKPOINT, TABLET_BREAKPOINT } from 'common/utils/constants';
import zIndex from 'common/utils/zIndex';

export const useProfileAppearanceSettingsStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    height: 'calc(100% - 60px)',
    overflowY: 'auto',
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      width: 'inherit',
      height: '100%',
      padding: '0px 12px 90px 12px',
    },
  },
  headerSectionTitle: {
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      '& h5': {
        fontSize: '20px',
        fontWeight: 600,
        lineHeight: '26px',
      },
      '& h6': {
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '22px',
        marginTop: '16px',
      },
    },
  },
  allSettingsContainer: {
    minWidth: '900px',
    width: '40%',
    marginBottom: theme.spacing(4),
    '& > :not(:last-child)': {
      marginBottom: theme.spacing(5),
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      minWidth: 'unset',
      marginTop: '24px',
    },
  },
  section: {
    ...theme.mixins.flexColumn,
    '& > .title': {
      marginBottom: theme.spacing(1),
    },
  },
  buttons: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      '& > button': {
        fontSize: '14px',
        width: '40px',
        padding: '8px 16px',
      },
    },
  },
  component: {
    marginTop: theme.spacing(2),
  },
  title: {
    ...theme.mixins.sectionTitle,
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      fontSize: '20px',
    },
  },
  image: {
    marginTop: theme.spacing(2),
    width: theme.spacing(88),
  },
  saveButton: {
    marginTop: theme.spacing(5),
    alignSelf: 'flex-end',
  },
  changeOrderButton: {
    marginTop: theme.spacing(-1),
    alignSelf: 'flex-start',
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      borderRadius: '6px',
      height: '40px',
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: 400,
    },
  },
  switchDarkMode: {
    marginTop: theme.spacing(2),
    width: theme.spacing(40),
  },
  artworkInfoOptions: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: '200px',
    width: 'auto',
  },
  previewImageContainer: {
    position: 'relative',
  },
  settingsContainer: {
    marginTop: theme.spacing(2),
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      gap: '20px',
    },
  },
  settingsOptionsContainer: {
    marginLeft: theme.spacing(5),
    flex: '40%',
    [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
      marginLeft: 'unset',
    },
    [theme.breakpoints.down('md')]: {
      flex: 'unset',
    },
  },
  previewImage: {
    width: '100%',
  },
  previewImageSquare: {
    aspectRatio: '1 / 1',
  },
  labeledSwitchContainer: {
    width: '100%',
  },
  flexContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: theme.spacing(4.5),
  },
  flexContainerCard: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  multipleSelectorStyleUpdate: {
    marginBottom: theme.spacing(5),
  },
  gridOptionsContainer: {
    ...theme.mixins.flexRow,
    width: '100%',
  },
  gridOptionButton: {
    borderRadius: '8px',
    width: '120px',
    height: '96px',
    display: 'flex',
    alignItems: 'flex-end',
    paddingBottom: '0px',
  },
  gridSquareContainer: {
    paddingTop: theme.spacing(0.5),
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: '1fr 1fr',
    gridGap: theme.spacing(0.5),
    height: '85px',
    width: '100%',
  },
  gridSquareItem: {
    backgroundColor: '#D3D7D8',
    borderRadius: '2px',
  },
  masonaryItem1: {
    gridArea: 'topLeft',
  },
  masonaryItem2: {
    gridArea: 'topRight',
  },
  masonaryItem3: {
    gridArea: 'bottomLeft',
  },
  masonaryItem4: {
    gridArea: 'bottomRight',
  },
  gridMasonaryContainer: {
    display: 'grid',
    grid: ` 'topLeft     topRight'
            'bottomLeft  topRight'
            'bottomLeft  bottomRight'`,
    gridGap: theme.spacing(0.5),
    height: '85px',
    width: '100%',
    '& > div': {
      backgroundColor: '#D3D7D8',
      borderRadius: '2px',
    },
  },
  settingsOptionItem: {
    marginBottom: theme.spacing(4),
  },
  gridOptionContainer: {
    display: 'block',
    marginRight: theme.spacing(2),
  },
  gridOptionsText: {
    textAlign: 'center',
    fontSize: '14px',
    marginTop: theme.spacing(1),
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      color: 'var(--black-bgray-65, #585A62)',
    },
  },
  dialogPaper: {
    paddingTop: '32px',
    margin: '0px',
    padding: '0px',
    height: 'calc(100vh - 48px)',
    maxHeight: '760px',
    minWidth: '960px',
    maxWidth: '1200px',
    borderRadius: '12px',
    position: 'relative',
    transition: 'all 0.3s',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '0px',
      height: '100vh',
      maxHeight: '100vh',
      maxWidth: '100vw',
      minWidth: '100vw',
      borderRadius: '0px',
      margin: '0px',
    },
  },
  dialogTitle: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    background: 'var(rgba(255, 255, 255, 0.80), rgba(255, 255, 255, 0.80))',
    boxShadow: '0px 8px 24px 0px rgba(0, 0, 0, 0.06)',
    backdropFilter: 'blur(24px)',
    padding: '8px 12px',
  },
  dialogCloseButton: {
    borderRadius: '6px',
    border: '1px solid rgba(7, 7, 28, 0.16)',
    padding: '10px',
  },
  dialogBottom: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',

    position: 'fixed',

    left: theme.spacing(0),
    right: theme.spacing(0),
    zIndex: zIndex.values.MODAL_OVERLAY,

    height: theme.spacing(6.75),
    padding: theme.spacing(1.5),
    maxWidth: `calc(100vw - ${theme.spacing(3)}px)`,
    width: `calc(100vw - ${theme.spacing(3)}px)`,

    bottom: `calc(${theme.spacing(0)}px + ${SAFE_AREA_BOTTOM})`,
    backdropFilter: 'blur(20px)',

    background: 'rgba(255, 255, 255, 0.7)',

    [theme.breakpoints.down('sm')]: {
      height: theme.spacing(6),
      padding: theme.spacing(1),
      maxWidth: `calc(100vw - ${theme.spacing(2)}px)`,
      width: `calc(100vw - ${theme.spacing(2)}px)`,
    },
  },
  dialogButtons: {
    padding: '8px 16px',
    fontSize: '16px',
    height: 'auto',
    minWidth: '40%',
    display: 'flex',
    justifyContent: 'center',
  },
  selectedGridOption: {
    border: '1px solid #121112 !important',
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.16)',
  },
  saveChanges: {
    position: 'fixed',
    bottom: theme.spacing(4),
    right: theme.spacing(8),
    float: 'right',
    height: '40px',
    fontSize: '14px',
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      right: theme.spacing(1),
      marginBottom: theme.spacing(4),
    },
  },
  snackbar: {
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      bottom: '85px',
    },
  },
  bottomContainer: {
    position: 'sticky',
    bottom: 0,
    padding: '12px 0px 20px 0px',
    width: '100%',
    background: '#FFF',
    zIndex: zIndex.values.BOTTOM_NAVBAR,
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    borderBottomRightRadius: '12px',
    borderBottomLeftRadius: '12px',
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '12px',
    padding: '0px 16px',
    justifyContent: 'flex-end',
  },
  button: {
    display: 'flex',
    justifyContent: 'center',
    height: '40px',
    width: 'fit-content',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
  },
  buttonDisabled: {
    '&:hover': {
      color: 'rgba(0, 0, 0, 0.26) !important',
      boxShadow: 'none !important',
      backgroundColor: 'rgba(0, 0, 0, 0.12) !important',
    },
  },
}));
