import { Scalars } from 'common/schema/commonSchemaRemoteTypes';
import { SortOptionsEnum } from 'common/features/VirtualList/VirtualList.config';
import { ProfileCollectorSort } from './filter.types';

export type ProfileCollectorFiltersHash = `${SortOptionsEnum}_${string}`;

export const listingDirections = ['BACKWARD', 'FORWARD'] as const;
export type ListDirection = (typeof listingDirections)[0] | (typeof listingDirections)[1];

export const loadingIdentifier = 'LOADING' as const;
export type ListLoadingId = typeof loadingIdentifier;

export type ListLoaderStatus = 'COLD' | 'FINISHED' | 'IDLE' | `${ListLoadingId}_${ListDirection}`;

export type ListWindowLoaderStateType<ValuesType, StatusType> = {
  batchSize: number;
  values: Array<ValuesType>;
  status: StatusType;
  hasMore: [boolean, boolean]; // [backward, forward]
};

export type ListLoaderStateType<Typename extends ProfileCollectorSort, CursorType, IdType = Scalars['bigint']> = {
  __typename: Typename;
  listed: ListWindowLoaderStateType<CursorType, ListLoaderStatus>;
  detailed: ListWindowLoaderStateType<IdType, ListLoaderStatus>;
  cursor: [CursorType, CursorType]; // [backward, forward]
  detailedToListedStartOffset: number;
  lastScrollPosition: number;
};

export type ProfileCollectorListByDateCursor = {
  artworkId: Scalars['bigint'];
  collectedAt: Scalars['date'] | undefined;
  eventType?: string;
};

export type ProfileCollectorListMostRecent = ListLoaderStateType<
  SortOptionsEnum.MOST_RECENT,
  ProfileCollectorListByDateCursor
>;

export type ProfileCollectorListLeastRecent = ListLoaderStateType<
  SortOptionsEnum.LEAST_RECENT,
  ProfileCollectorListByDateCursor
>;

export type ProfileCollectorListCollectorSelection = ListLoaderStateType<
  SortOptionsEnum.COLLECTOR_SELECTION,
  ProfileCollectorListByDateCursor
>;

export type ListActionArgs =
  | [
      ListDirection,
      ProfileCollectorListCollectorSelection['__typename'],
      ProfileCollectorListCollectorSelection['listed']['values']
    ]
  | [ListDirection, ProfileCollectorListMostRecent['__typename'], ProfileCollectorListMostRecent['listed']['values']]
  | [ListDirection, ProfileCollectorListLeastRecent['__typename'], ProfileCollectorListLeastRecent['listed']['values']];

export type DetailActionArgs =
  | [
      ListDirection,
      ProfileCollectorListCollectorSelection['__typename'],
      ProfileCollectorListCollectorSelection['detailed']['values']
    ]
  | [ListDirection, ProfileCollectorListMostRecent['__typename'], ProfileCollectorListMostRecent['detailed']['values']]
  | [
      ListDirection,
      ProfileCollectorListLeastRecent['__typename'],
      ProfileCollectorListLeastRecent['detailed']['values']
    ];

export type ProfileCollectorListState = {
  identifier: ProfileCollectorFiltersHash;
  type: ProfileCollectorSort;
} & (
  | {
      type: SortOptionsEnum.MOST_RECENT;
      data: ProfileCollectorListMostRecent;
    }
  | {
      type: SortOptionsEnum.LEAST_RECENT;
      data: ProfileCollectorListLeastRecent;
    }
  | {
      type: SortOptionsEnum.COLLECTOR_SELECTION;
      data: ProfileCollectorListCollectorSelection;
    }
);

export type ProfileCollectorListingCursor = ProfileCollectorListByDateCursor;

export const isProfileCollectorListByDateCursor = (
  cursor: ProfileCollectorListingCursor
): cursor is ProfileCollectorListByDateCursor =>
  (cursor as ProfileCollectorListByDateCursor)?.collectedAt !== undefined;

export const LOADING_STATES: ListLoaderStatus[] = ['LOADING_BACKWARD', 'LOADING_FORWARD'];
