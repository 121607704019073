import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type AdminWidgetSliceType = {
  isVisible: boolean;
  isDialogOpen: boolean;
  artistContextId?: number;
  artworkId?: number;
};

const initialState: AdminWidgetSliceType = {
  isVisible: false,
  isDialogOpen: false,
};

const adminWidgetSlice = createSlice({
  initialState,
  name: 'adminWidget',
  reducers: {
    TOGGLE_WIDGET: (state) => ({
      ...state,
      isVisible: !state.isVisible,
    }),
    OPEN_DIALOG: (state) => ({
      ...state,
      isDialogOpen: true,
    }),
    CLOSE_DIALOG: (state) => ({
      ...state,
      isDialogOpen: false,
    }),
    MANAGE_ARTIST: (
      state,
      action: PayloadAction<{
        artistContextId?: number;
        artworkId?: number;
        open?: boolean;
      }>
    ) => ({
      ...state,
      artistContextId: action.payload.artistContextId,
      artworkId: action.payload.artworkId,
      isDialogOpen: !!action.payload.open,
    }),
  },
});

export const adminWidgetActions = adminWidgetSlice.actions;
export default adminWidgetSlice;
