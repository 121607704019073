import { makeStyles } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import { FunctionComponent, PropsWithChildren } from 'react';
import { isDarkTheme } from 'stacks/ProfileArtShowcase/components/ProfileArtShowcaseLeftSide/components/common/CardContainer/CardContainer';
import useLazyPaginator, { LazyPaginatorHookProps } from '../../hooks/useLazyPaginator';

const useStyles = makeStyles((theme) => {
  const isDarkMode = isDarkTheme(theme);
  return {
    pagination: {
      filter: isDarkMode ? 'invert(1)' : 'none',
    },
  };
});

export type PaginatedListProps<T> = {
  render: (item: T) => JSX.Element;
} & LazyPaginatorHookProps<T>;

const PaginatedList = <T,>({
  render,
  ...lazyPaginatorProps
}: PropsWithChildren<PaginatedListProps<T>>): ReturnType<FunctionComponent> => {
  const classes = useStyles();
  const [page, items, pageCount, handleChange, loading] = useLazyPaginator({
    ...lazyPaginatorProps,
  });
  return (
    <>
      {items.map(render)}
      {pageCount > 1 && (
        <Pagination
          className={classes.pagination}
          disabled={loading}
          count={pageCount}
          variant="outlined"
          shape="rounded"
          page={page}
          onChange={(_, newPage) => handleChange(newPage)}
          style={{ marginTop: 16, display: 'flex', justifyContent: 'center' }}
        />
      )}
    </>
  );
};

export default PaginatedList;
