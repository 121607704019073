import { withProfiler } from '@sentry/react';
import React from 'react';
import { IS_CUSTOM_DOMAINS_APP } from 'utils/constants';

const PublicDomainRouter = React.lazy(() => import('routes/publicDomain/_router'));
const CustomDomainRouter = React.lazy(() => import('routes/customDomain/_router'));

const AppRouter: React.FC = IS_CUSTOM_DOMAINS_APP ? CustomDomainRouter : PublicDomainRouter;

export default withProfiler(AppRouter, {
  name: 'AppRouter',
  disabled: false,
});
