import { useMemo, useRef } from 'react';
import { Theme, useMediaQuery } from '@material-ui/core';
import { SwipeDirections, SwipeEventData, useSwipeable } from 'react-swipeable';
import useCooldownState from 'common/hooks/utils/useCooldownState';

export type SwipeContainerReturn = {
  style: React.CSSProperties;
} & ReturnType<typeof useSwipeable>;

enum SWIPE_DIRECTION {
  LEFT,
  RIGHT,
}

const PREVIEW_OFFSET = 70;
// const SWIPE_DEADZONE = 50;

const directions: Record<'horizontal' | 'vertical', SwipeDirections[]> = {
  horizontal: ['Left', 'Right'],
  vertical: ['Up', 'Down'],
};

export const useSwipeableContainer = (maxLeftOffset: number, shouldPreview?: boolean): SwipeContainerReturn => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const [swipeDirection, setSwipeDirection] = useCooldownState(-1, 500);
  const swipeLock = useRef<SwipeEventData | null>(null);

  const previousDirection = useRef<SwipeDirections | undefined>(undefined);

  const handlers = useSwipeable({
    onSwipeStart: (e) => {
      swipeLock.current = e;
    },
    onSwipedLeft: (e) => {
      if (previousDirection.current === 'Right') {
        e.event.stopPropagation();
      }

      previousDirection.current = 'Left';
    },
    onSwipedRight: (e) => {
      if (previousDirection.current === 'Left') {
        e.event.stopPropagation();
      }

      previousDirection.current = 'Right';
    },
    onSwiping: (e) => {
      if (!swipeLock.current) {
        return;
      }
      if (!directions.horizontal.includes(swipeLock.current.dir) || !directions.horizontal.includes(e.dir)) {
        return;
      }
      const { dir } = e;
      if (dir === 'Left') {
        setSwipeDirection(SWIPE_DIRECTION.LEFT);
      } else if (dir === 'Right') {
        setSwipeDirection(SWIPE_DIRECTION.RIGHT);
      }

      if (dir !== previousDirection.current && previousDirection.current !== undefined) {
        e.event.stopPropagation();
      }
    },
    preventScrollOnSwipe: true,
    delta: {
      down: 2000,
      up: 2000,
      left: 20,
      right: 20,
    },
    trackTouch: true,
    trackMouse: true,
  });

  const touchLength = useMemo(() => {
    // Restricts swiping right
    // if (swipeLength < 0) return 0;
    // Keeps track of the finger position (swipe in progress length)
    // if (swipeLength > maxLeftOffset) return maxLeftOffset;
    // Evaluates the swipe direction after touch end
    if (swipeDirection === SWIPE_DIRECTION.LEFT) {
      return maxLeftOffset;
    }
    // Limits the minimum swipe length to a deadzone
    // if (swipeLength < SWIPE_DEADZONE) return 0;
    return 0;
  }, [swipeDirection, maxLeftOffset]);

  const style = useMemo<React.CSSProperties>(
    () => ({
      position: 'relative',
      right: shouldPreview && isMobile ? PREVIEW_OFFSET : touchLength,
      transition: touchLength && touchLength !== maxLeftOffset ? '' : 'all 0.5s',
    }),
    [touchLength, maxLeftOffset, shouldPreview, isMobile]
  );

  return { style, ...handlers };
};
