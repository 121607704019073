import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GetManageArtworksInfoQuery } from 'common/schema/commonSchemaRemoteOperationTypes';
import { Scalars } from 'common/schema/commonSchemaRemoteTypes';
import { NotNullish } from 'common/utils/types';

export type ManageArtworkCardDataType = NotNullish<GetManageArtworksInfoQuery['artworks']>[0];

type ArtworkCardEntities = Record<Scalars['String'], ManageArtworkCardDataType>;

const initialState: ArtworkCardEntities = {};

export const artistManageArtworkCardsSlice = createSlice({
  name: 'artistManageArtworkCards',
  initialState,
  reducers: {
    UPDATE_MANAGE_ARTWORK_CARDS: (state, action: PayloadAction<ManageArtworkCardDataType[]>) => {
      action.payload.forEach((card) => {
        if (!card.artworkEditionId) {
          return;
        }
        state[card.artworkEditionId] = card;
      });
    },
    UPDATE_MANAGE_ARTWORK_CARD: (state, action: PayloadAction<Partial<ManageArtworkCardDataType>>) => {
      if (!action.payload.artworkEditionId) {
        return;
      }

      state[action.payload.artworkEditionId] = {
        ...state[action.payload.artworkEditionId],
        ...action.payload,
      };
    },
  },
});
