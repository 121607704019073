import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { YearFilterState } from 'common/features/Filters/predefined/Year/store/year.types';
import { getDefaultYearsFilter } from 'common/features/Filters/predefined/Year/store/year.defaults';
// import { TypeUtils } from '../../../../../utils/generalTypeUtils';

type ToggleYearAction = PayloadAction<{
  year: number;
}>;

export const createToggleYearCaseReducer =
  <State extends YearFilterState>(): CaseReducer<State, ToggleYearAction> =>
  (state, action) => {
    const { year } = action.payload ?? {};
    const prevYears = state.selectedYears;

    // cannot actively turn "All" off when it is on
    if (year === 0 && prevYears[0]) return;

    // can turn "All" on when it is off and turn everything else off
    if (year === 0) {
      state.selectedYears = getDefaultYearsFilter().selectedYears;
      return;
    }

    const nextYears = { ...prevYears };
    nextYears[year] = !nextYears[year];

    // if no priceRanges are on, set "All" on
    nextYears[0] = !Object.values(nextYears).includes(true);

    state.selectedYears = nextYears;
  };
