import { SortOptionsEnum } from 'common/features/VirtualList/VirtualList.config';
import { filtersHash } from '../hooks/useProfileCollectorFiltersIdentifier';
import { getDefaultFilters } from './filter.defaults';
import {
  ProfileCollectorListState,
  ListDirection,
  listingDirections,
  ListWindowLoaderStateType,
  ProfileCollectorListMostRecent,
  ProfileCollectorListLeastRecent,
  ProfileCollectorListCollectorSelection,
} from './list.types';
import { ProfileCollectorCardEventsEnum } from '../components/grid/view/ProfileCollectorArtworkListElement';
import { isProfileCollectorSort, ProfileCollectorSort } from './filter.types';

export const FIRST_CARD_PLACEHOLDER_ID = -1;

export const defaults = {
  most_recent: (): {
    type: SortOptionsEnum.MOST_RECENT;
    data: ProfileCollectorListMostRecent;
  } => ({
    type: SortOptionsEnum.MOST_RECENT,
    data: {
      __typename: SortOptionsEnum.MOST_RECENT,
      lastScrollPosition: 0,
      detailedToListedStartOffset: 0,
      cursor: [
        { collectedAt: '1000-01-01T00:00:00.000000+00:00', artworkId: 0 },
        { collectedAt: '4000-01-01T00:00:00.000000+00:00', artworkId: 0 },
      ],
      listed: {
        batchSize: 256,
        status: 'COLD',
        values: [
          {
            collectedAt: '1000-01-01T00:00:00.000000+00:00',
            artworkId: FIRST_CARD_PLACEHOLDER_ID,
            eventType: ProfileCollectorCardEventsEnum.FIRST_CARD,
          },
        ],
        hasMore: [false, false],
      },
      detailed: {
        batchSize: 128,
        status: 'COLD',
        values: [FIRST_CARD_PLACEHOLDER_ID],
        hasMore: [false, false],
      },
    },
  }),
  least_recent: (): {
    type: SortOptionsEnum.LEAST_RECENT;
    data: ProfileCollectorListLeastRecent;
  } => ({
    type: SortOptionsEnum.LEAST_RECENT,
    data: {
      __typename: SortOptionsEnum.LEAST_RECENT,
      lastScrollPosition: 0,
      detailedToListedStartOffset: 0,
      cursor: [
        { collectedAt: '4000-01-01T00:00:00.000000+00:00', artworkId: 0 },
        { collectedAt: '1000-01-01T00:00:00.000000+00:00', artworkId: 0 },
      ],
      listed: {
        batchSize: 256,
        status: 'COLD',
        values: [
          {
            collectedAt: '1000-01-01T00:00:00.000000+00:00',
            artworkId: FIRST_CARD_PLACEHOLDER_ID,
            eventType: ProfileCollectorCardEventsEnum.FIRST_CARD,
          },
        ],
        hasMore: [false, false],
      },
      detailed: {
        batchSize: 128,
        status: 'COLD',
        values: [FIRST_CARD_PLACEHOLDER_ID],
        hasMore: [false, false],
      },
    },
  }),
  collector_selection: (): {
    type: SortOptionsEnum.COLLECTOR_SELECTION;
    data: ProfileCollectorListCollectorSelection;
  } => ({
    type: SortOptionsEnum.COLLECTOR_SELECTION,
    data: {
      __typename: SortOptionsEnum.COLLECTOR_SELECTION,
      lastScrollPosition: 0,
      detailedToListedStartOffset: 0,
      cursor: [
        { collectedAt: '4000-01-01T00:00:00.000000+00:00', artworkId: 0 },
        { collectedAt: '1000-01-01T00:00:00.000000+00:00', artworkId: 0 },
      ],
      listed: {
        batchSize: 256,
        status: 'COLD',
        values: [
          {
            collectedAt: '1000-01-01T00:00:00.000000+00:00',
            artworkId: FIRST_CARD_PLACEHOLDER_ID,
            eventType: ProfileCollectorCardEventsEnum.FIRST_CARD,
          },
        ],
        hasMore: [false, false],
      },
      detailed: {
        batchSize: 128,
        status: 'COLD',
        values: [FIRST_CARD_PLACEHOLDER_ID],
        hasMore: [false, false],
      },
    },
  }),
};

const defaultFilters = getDefaultFilters();

export const getDefaultListState: () => ProfileCollectorListState = () => {
  const currentSort: ProfileCollectorSort = isProfileCollectorSort(defaultFilters.currentSort)
    ? defaultFilters.currentSort
    : SortOptionsEnum.COLLECTOR_SELECTION;

  return {
    identifier: filtersHash(defaultFilters),
    ...defaults[currentSort](),
  };
};

export const listInitialState = getDefaultListState();

export const hasMore = (loader: ListWindowLoaderStateType<unknown, unknown>, direction: ListDirection): boolean =>
  loader.hasMore[listingDirections.indexOf(direction)];
