import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { CountryFilterState } from './country.types';

type SelectCountryAction = PayloadAction<CountryFilterState['country']>;
export const createSelectCountryCaseReducer =
  <State extends CountryFilterState>(): CaseReducer<State, SelectCountryAction> =>
  (state, action) => {
    if (state.country?.iso2 === action.payload?.iso2) {
      return;
    }
    state.country = action.payload;
  };
