import { Button, Typography } from '@material-ui/core';
import useAppSelector from 'common/hooks/useAppSelector';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';
import { dimenstionStyles } from 'utils/dimensionStyles';
import { typographyStyles } from 'utils/typographyStyles';
import { adminWidgetStyles } from './AdminWidget.styles';
import { adminWidgetActions } from './store/adminWidgetSlice';

type AdminWidgetProps = {
  artistContextId?: number;
  artworkId?: number;
  onOpenWidget?: () => void;
};

const AdminWidget: React.FC<AdminWidgetProps> = ({ artistContextId, artworkId, onOpenWidget }) => {
  const isVisible = useAppSelector((store) => store.adminWidget.isVisible);
  const dispatch = useDispatch();
  const typographyClasses = typographyStyles();
  const dimensionClasses = dimenstionStyles();
  const customClasses = adminWidgetStyles();
  const { t } = useTranslation();

  if (!isVisible) {
    return null;
  }

  return (
    <>
      <Button
        onClick={() => {
          dispatch(
            adminWidgetActions.MANAGE_ARTIST({
              artistContextId,
              artworkId,
              open: true,
            })
          );
          onOpenWidget?.();
        }}
        className={clsx(customClasses.buttonAdminTool, dimensionClasses.marginBottom8)}
      >
        <Typography variant="h4" className={typographyClasses.fontSize18}>
          {t('adminTool.buttonText')}
        </Typography>
      </Button>
    </>
  );
};

export default AdminWidget;
