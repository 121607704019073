import { makeStyles } from '@material-ui/core';

export const dimenstionStyles = makeStyles({
  width100Percent: {
    width: '100%',
  },
  height100Percent: {
    height: '100%',
  },
  heigth48: {
    height: '48px',
  },
  heigth280: {
    height: '280px',
  },
  padding24: {
    padding: '24px',
  },
  padding14: {
    padding: '14px',
  },
  marginLeft4: {
    marginLeft: '4px !important',
  },
  marginLeft8: {
    marginLeft: '8px !important',
  },
  marginLeft0: {
    marginLeft: '0px !important',
  },
  marginLeft12: {
    marginLeft: '12px !important',
  },
  marginLeft16: {
    marginLeft: '16px !important',
  },
  marginLeft24: {
    marginLeft: '24px !important',
  },
  marginLeft48: {
    marginLeft: '48px !important',
  },
  marginLeft80: {
    marginLeft: '80px !important',
  },
  marginTop0: {
    marginTop: '0px !important',
  },
  marginTop4: {
    marginTop: '4px !important',
  },
  marginTop8: {
    marginTop: '8px !important',
  },
  marginTop12: {
    marginTop: '12px !important',
  },
  marginTop14: {
    marginTop: '14px !important',
  },
  marginTop16: {
    marginTop: '16px !important',
  },
  marginTop24: {
    marginTop: '24px !important',
  },
  marginTop32: {
    marginTop: '32px !important',
  },
  marginTop40: {
    marginTop: '40px !important',
  },
  marginTopBottom0: {
    marginTop: '0px !important',
    marginBottom: '0px !important',
  },
  marginBottom4: {
    marginBottom: '4px !important',
  },
  marginBottom8: {
    marginBottom: '8px !important',
  },
  marginBottom12: {
    marginBottom: '12px !important',
  },
  marginBottom32: {
    marginBottom: '32px !important',
  },
  marginBottom36: {
    marginBottom: '36px !important',
  },
  marginBottom24: {
    marginBottom: '24px !important',
  },
  paddingBottom24: {
    paddingBottom: '24px !important',
  },
  marginBottom40: {
    marginBottom: '40px !important',
  },
  marginBottom48: {
    marginBottom: '48px !important',
  },
  marginRight0: {
    marginRight: '0px !important',
  },
  marginRight5: {
    marginRight: '5px !important',
  },
  marginRight8: {
    marginRight: '8px !important',
  },
  marginRight10: {
    marginRight: '10px !important',
  },
  marginRight16: {
    marginRight: '16px !important',
  },
  marginRight24: {
    marginRight: '24px !important',
  },
  marginRight30: {
    marginRight: '30px !important',
  },
  marginRight40: {
    marginRight: '40px !important',
  },
  marginRight100: {
    marginRight: '100px !important',
  },
  marginBottom30: {
    marginBottom: '30px !important',
  },
  marginBottom20: {
    marginBottom: '20px !important',
  },
  marginBottom16: {
    marginBottom: '16px !important',
  },
  marginTop20: {
    marginTop: '20px !important',
  },
  marginRight20: {
    marginRight: '20px !important',
  },
  marginBottom64: {
    marginBottom: '64px !important',
  },
  marginAuto: {
    marginTop: 'auto',
    marginBottom: 'auto',
  },
});
