import { Enum_Language_Preference_Enum } from 'common/schema/commonSchemaRemoteTypes';
import { CDNOrigin, ENV_TYPE } from 'utils/constants';

export type AudioFormats = 'mp3' | 'm4a' | 'wav';
export enum AudioGenerationSource {
  AI_GENERATED = 'AIGenerated',
  ARTIST_MANUAL_UPLOAD = 'ArtistManualUpload',
}
export const AudioFolderBySource: Record<AudioGenerationSource, string> = {
  AIGenerated: 'AIGenerated',
  ArtistManualUpload: '',
};

const supportedAudioFormats: AudioFormats[] = ['mp3', 'm4a', 'wav'];

export const isAudioFormatSupported = (format?: string): format is AudioFormats =>
  !!format && supportedAudioFormats.includes(format as AudioFormats);

type CDNAudioSubTypeProps<
  SubTypeName extends string | number | symbol,
  ExtraProps extends unknown | Record<string, unknown>
> = Record<SubTypeName, ExtraProps>;

type CDNAudioTargetProps<
  AudioTargetName extends string,
  AudioTargetId extends number | string,
  AudioMedatadataId extends number,
  AudioTagetSubtypes extends CDNAudioSubTypeProps<string, unknown> | undefined
> = Record<
  AudioTargetName,
  {
    id: AudioTargetId;
    fileid: AudioMedatadataId;
    subtypes: AudioTagetSubtypes;
  }
>;

type CDNProfileAudiosProps = CDNAudioTargetProps<
  'Profile',
  number, // profile.id
  number, // metadata.fileid
  CDNAudioSubTypeProps<
    'Statement', // purpose
    {
      language: Enum_Language_Preference_Enum;
      source: AudioGenerationSource;
    }
  >
>;

// TODO: The following are examples of how we would implement audio target types for Collections and Artwork.
// which would then be added to CDNAudiosProps
// type CDNCollectionsAudiosProps = CDNAudioTargetProps<
//   'Collections',
//   number, // collections .id
//   number, // metadata.fileid
//   CDNAudioSubTypeProps<
//     '', // NO PURPOSE
//     {
//       language: Enum_Language_Preference_Enum;
//       source?: AudioSource;
//     }
//   >
// >;

// type CDNArtworkAudiosProps = CDNAudioTargetProps<
//   'Artwork',
//   number, // artwork.id
//   number, // metadata.fileid
//   CDNAudioSubTypeProps<
//     'Story', // purpose
//     {
//       language: Enum_Language_Preference_Enum;
//       source: AudioSource;
//     }
//   >
// >;

const isSourceMeta = (meta: unknown): meta is { source: AudioGenerationSource } =>
  !!(meta as { source: AudioGenerationSource })?.source;

const isLanguageMeta = (meta: unknown): meta is { language: Enum_Language_Preference_Enum } =>
  !!(meta as { language: Enum_Language_Preference_Enum })?.language;

const getSourceMetaPath = <
  TargetType extends keyof CDNAudiosProps,
  SubType extends keyof CDNAudiosProps[TargetType]['subtypes']
>(
  type: TargetType,
  subType: SubType,
  meta: unknown
) => {
  if (!isSourceMeta(meta)) {
    return '';
  }

  if (type === 'Profile' && subType === 'Statement') {
    return `/${AudioFolderBySource[meta.source]}`;
  }

  return '';
};

type CDNAudiosProps = CDNProfileAudiosProps;

const defaultAudioFormat: AudioFormats = 'mp3';

export const getCDNAudioUrl = <
  TargetType extends keyof CDNAudiosProps,
  SubType extends keyof CDNAudiosProps[TargetType]['subtypes'],
  SubTarget extends CDNAudiosProps[TargetType]['subtypes'][SubType] = CDNAudiosProps[TargetType]['subtypes'][SubType]
>({
  target,
  targetId,
  fileId,
  purpose,
  meta,
  format = defaultAudioFormat,
}: {
  target: TargetType;
  targetId: CDNAudiosProps[TargetType]['id'];
  fileId?: CDNAudiosProps[TargetType]['fileid'];
  purpose: SubType;
  meta: SubTarget;
  format?: AudioFormats;
}): string => {
  const base = `${CDNOrigin}/${ENV_TYPE === 'production' ? 'CDN' : 'CDN/staging'}/${target}/${targetId}`;

  const purposePath = purpose ? `/${String(purpose)}/Audio` : '/Audio';
  const optionalSourceMetaPath = getSourceMetaPath(target, purpose, meta);
  const file = isLanguageMeta(meta) && `/${meta.language.toLocaleLowerCase()}.${format}?updated=${fileId || 0}`;
  return `${base}${purposePath}${optionalSourceMetaPath}${file}`;
};

// Sample calls:
//  getCDNAudioUrl({
//   target: 'Profile',
//   targetId: 889,
//   purpose: 'Statement',
//   meta: {
//     language: Enum_Language_Preference_Enum.English,
//     source: 'AIGenerated',
//   },
// });

// getCDNAudioUrl({
//   target: 'Artwork',
//   targetId: 889,
//   purpose: 'Story',
//   meta: {
//     language: Enum_Language_Preference_Enum.English,
//     source: 'AIGenerated',
//   },

// });

// getCDNAudioUrl({
//   target: 'Collections',
//   targetId: 889,
//   purpose: '',
//   meta: {
//     language: Enum_Language_Preference_Enum.English,
//   },
// });
