import { makeStyles } from '@material-ui/core';

export const alignmentStyles = makeStyles((theme) => ({
  flex: {
    display: 'flex',
  },
  flexSpaceBetween: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  flexCenter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  flexVerticalAlign: {
    display: 'flex',
    alignItems: 'center',
  },
  flexCenterOnColumn: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  textAlignCenter: {
    textAlign: 'center',
  },
  flexWrap: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  flexRowCenter: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  flexEnd: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  flexColumnStart: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
  },
  blockCenter: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  pageAlignment: {
    padding: '120px',
    paddingTop: '50px',
    [theme.breakpoints.down('md')]: {
      padding: '60px',
    },
  },
  columnGrid3: {
    margin: '40px 0px',
    gridTemplateColumns: '1fr 1fr 1fr',
    display: 'grid',
    gridColumnGap: '40px',
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr 1fr',
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
    },
  },
  marginTop40xs36: {
    marginTop: '40px !important',
    [theme.breakpoints.down('xs')]: {
      marginTop: '36px !important',
    },
  },
  textfieldLabelPositionFix: {
    '&:not()': {
      transform: 'translate(14px, 16px) scale(1)',
    },
  },
  textfieldLabelPositionFixFocused: {
    '&:not([data-shrink="true"])': {
      transform: 'translate(14px, 16px) scale(1) !important',
    },
  },
  textfieldSelectLabelPositionFixFocused: {
    '&:not([data-shrink="true"])': {
      transform: 'translate(14px, 18px) scale(1) !important',
    },
  },
  wordBreakRule: {
    whiteSpace: 'pre-wrap',
  },
  marginTopAuto: {
    marginTop: 'auto',
  },
}));
