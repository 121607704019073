import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { tutorialConditions } from 'common/features/Tutorial/utils/tutorialsConstants';
import { Tutorial, Tutorials, TutorialType } from 'common/features/Tutorial/utils/tutorialsTypes';
import {
  generateNewTutorial,
  getTutorialNextStep,
  isTutorialCompleted,
} from 'common/features/Tutorial/utils/tutorialsUtils';
import useAppSelector from 'common/hooks/useAppSelector';
import {
  Enum_Tutorial_Status_Enum,
  Enum_Tutorial_Step_Type_Enum,
  Enum_Tutorial_Type_Enum,
} from 'common/schema/commonSchemaRemoteTypes';

type TutorialsState = {
  tutorials: Partial<Tutorials>;
  initialTutorials: Partial<Tutorials>;
  openedTooltips: Enum_Tutorial_Step_Type_Enum[];
  currentTutorial: Enum_Tutorial_Type_Enum | null;
  registeredSteps: Enum_Tutorial_Step_Type_Enum[];
};

const initialState: TutorialsState = {
  openedTooltips: [],
  currentTutorial: null,
  tutorials: {
    DASHBOARD_INTRO: null,
    DASHBOARD_INTRO_PAGES: null,
    // MANAGE_ART: null,
    EDIT_PROFILE: null,
    KALEIDO_CARD: null,
    KALEIDO_COINS: null,
    SHARE_KALEIDO: null,
    CERTIFICATES_INTRO: null,
    CONSUMER_PRODUCT_LIVE: null,
    CONSUMER_PRODUCT_EXPLORE: null,
    CONSUMER_PRODUCT_COLLECTIONS: null,
    CONSUMER_PRODUCT_PRIZES: null,
    CONSUMER_PRODUCT_DOTS: null,
    ART_SHOWCASE: null,
    ART_SHOWCASE_V2: null,
    ART_SHOWCASE_V3: null,
    ART_SHOWCASE_V2_SLIDER: null,
    MY_WEBSITE: null,
  },
  initialTutorials: {
    DASHBOARD_INTRO: null,
    DASHBOARD_INTRO_PAGES: null,
    // MANAGE_ART: null,
    EDIT_PROFILE: null,
    KALEIDO_CARD: null,
    KALEIDO_COINS: null,
    SHARE_KALEIDO: null,
    CERTIFICATES_INTRO: null,
    CONSUMER_PRODUCT_LIVE: null,
    CONSUMER_PRODUCT_EXPLORE: null,
    CONSUMER_PRODUCT_COLLECTIONS: null,
    CONSUMER_PRODUCT_PRIZES: null,
    CONSUMER_PRODUCT_DOTS: null,
    ART_SHOWCASE: null,
    ART_SHOWCASE_V2: null,
    ART_SHOWCASE_V3: null,
    ART_SHOWCASE_V2_SLIDER: null,
    MY_WEBSITE: null,
  },
  registeredSteps: [],
};

const tutorialsSlice = createSlice({
  name: 'tutorials',
  initialState,
  reducers: {
    setInitialTutorial: (state, action: PayloadAction<Tutorial>) => {
      const { type } = action.payload;
      state.tutorials[type] = action.payload;
      state.initialTutorials[type] = action.payload;

      if (
        isTutorialCompleted(action.payload) ||
        state.currentTutorial === type ||
        state.currentTutorial === Enum_Tutorial_Type_Enum.DashboardIntro
      ) {
        return state;
      }

      state.currentTutorial = type;
      return state;
    },
    fetchTutorials: (state, action: PayloadAction<TutorialType[]>) => {
      action.payload.forEach((type) => {
        const tutorial =
          state.tutorials[type] ?? generateNewTutorial(type, { status: Enum_Tutorial_Status_Enum.Started });
        tutorial.fetchState = 'FETCH';
        state.tutorials[type] = tutorial;
      });
      return state;
    },
    goToNextStep: (state, action: PayloadAction<TutorialType>) => {
      const tutorial = state.tutorials[action.payload];
      if (!tutorial) {
        console.error(`goToNextStep > ${action.payload} - NOT_FOUND`);
        return state;
      }

      const possibleNextStep = getTutorialNextStep(tutorial, state.registeredSteps);
      if (possibleNextStep) {
        tutorial.currentStep = possibleNextStep.type;
        state.tutorials[action.payload] = tutorial;
        return state;
      }

      // FINISH TUTORIAL
      tutorial.status = Enum_Tutorial_Status_Enum.Finished;

      if (tutorial.steps.some((s) => state.openedTooltips.includes(s.type))) {
        // hide all tooltips for the finished tutorial
        state.openedTooltips = state.openedTooltips.filter((o) => !tutorial.steps.filter((s) => s.type === o));
      }

      if (state.currentTutorial === action.payload) {
        state.currentTutorial = null;
      }

      state.tutorials[action.payload] = tutorial;
      return state;
    },
    startTutorial: (
      state,
      action: PayloadAction<{
        type: Enum_Tutorial_Type_Enum;
        isReset?: boolean;
      }>
    ) => {
      const { type, isReset } = action.payload;
      const existingTutorial = state.tutorials[type];

      if (
        !existingTutorial ||
        // || !existingTutorial.steps.length
        (state.tutorials.DASHBOARD_INTRO?.status === Enum_Tutorial_Status_Enum.Skipped && existingTutorial.steps.length)
      ) {
        return state;
      }

      let tutorialStatus = Enum_Tutorial_Status_Enum.Finished;
      if (existingTutorial.steps.length) {
        const firstStep = existingTutorial.steps[0].type;
        existingTutorial.currentStep = isReset ? firstStep : existingTutorial.currentStep ?? firstStep;
        tutorialStatus = existingTutorial.status ?? Enum_Tutorial_Status_Enum.Started;
      }

      existingTutorial.status = isReset ? Enum_Tutorial_Status_Enum.Started : tutorialStatus;

      const hasCompletedPreviousTutorials = tutorialConditions[type].completedTutorials.every((t) =>
        isTutorialCompleted(state.tutorials[t])
      );

      if (
        existingTutorial.status === Enum_Tutorial_Status_Enum.Started &&
        hasCompletedPreviousTutorials &&
        existingTutorial.currentStep
      ) {
        state.openedTooltips = [existingTutorial.currentStep];
        state.currentTutorial = type;
      }

      state.tutorials[type] = existingTutorial;
      return state;
    },
    skipTutorial: (state, action: PayloadAction<Enum_Tutorial_Type_Enum | undefined>) => {
      const tutorialType = action.payload || state.currentTutorial;
      const skippedStatus = Enum_Tutorial_Status_Enum.Skipped;
      if (!tutorialType) {
        console.error('skipTutorial - no tutorial type');
        return state;
      }
      const existingTutorial = state.tutorials[tutorialType];
      if (!existingTutorial) {
        console.error('skipTutorial - no existing tutorial');
        return state;
      }
      existingTutorial.status = skippedStatus;
      if (action.payload === state.currentTutorial) {
        state.currentTutorial = null;
      }

      state.tutorials[tutorialType] = existingTutorial;

      return state;
    },
    setOpenedTooltips: (
      state,
      action: PayloadAction<{
        step: Enum_Tutorial_Step_Type_Enum;
        isOpen: boolean;
      }>
    ) => {
      const { step, isOpen } = action.payload;
      const stepIndex = state.openedTooltips.indexOf(step);
      if (isOpen && stepIndex === -1) {
        state.openedTooltips = [...state.openedTooltips, step];
      } else if (!isOpen && stepIndex > -1) {
        state.openedTooltips.splice(stepIndex, 1);
      }
      return state;
    },
    setTooltipRegistered: (
      state,
      action: PayloadAction<{
        step: Enum_Tutorial_Step_Type_Enum;
        registered: boolean;
      }>
    ) => {
      const { step, registered } = action.payload;
      const stepIndex = state.registeredSteps.indexOf(step);
      if (registered && stepIndex === -1) {
        state.registeredSteps = [...state.registeredSteps, step];
      } else if (!registered && stepIndex > -1) {
        const registeredSteps = [...state.registeredSteps];
        registeredSteps.splice(stepIndex, 1);
        state.registeredSteps = registeredSteps;
      }
      return state;
    },
    updateOrInitTutorial: (
      state,
      action: PayloadAction<{
        type: TutorialType;
        values: Partial<Tutorial>;
      }>
    ) => {
      const { type, values } = action.payload;
      const existingTutorial = state.tutorials[type];
      const tutorial = existingTutorial ?? generateNewTutorial(type);
      state.tutorials[type] = { ...tutorial, ...values };
      return state;
    },
  },
});

export const useTutorialsState = () => useAppSelector((state) => state.tutorials);

export const tutorialsActions = tutorialsSlice.actions;

export default tutorialsSlice;
