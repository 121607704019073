import { Artwork_Bool_Exp } from '../../../schema/commonSchemaRemoteTypes';
import {
  AppliedListingModifier,
  ARTWORK_QUERY_CONDITION_IN,
  FILTERS_DEFAULT_VALUE,
  isOptionType,
  ListingModifier,
  ListingModifierOption,
} from './types';

export type SeriesOptionType = {
  id: number;
  name: string;
};

export const generateSeriesFilterOption = ({ id, name }: SeriesOptionType): ListingModifierOption => ({
  name,
  value: id,
  key: 'ARTWORK_QUERY_CONDITION_IN',
  payload: { artwork_to_collections: { collection_id: { _in: [id] } } },
});

export const getSeriesFilter = (): ListingModifier => ({
  type: 'SERIES',
  allOptions: [
    {
      value: FILTERS_DEFAULT_VALUE,
      name: 'filterStrings.series.all',
      key: 'ARTWORK_QUERY_CONDITION_IN',
      payload: {},
    },
  ],
});

export const getAppliedSeriesFilter = (
  seriesOptions: SeriesOptionType[]
): AppliedListingModifier<ARTWORK_QUERY_CONDITION_IN> => {
  const seriesFilter = getSeriesFilter();
  const newSeriesFilterOptions = seriesOptions.map(generateSeriesFilterOption);
  seriesFilter.allOptions.push(...newSeriesFilterOptions);
  return {
    ...seriesFilter,
    selectionType: 'MULTIPLE',
    selectedOptions: [FILTERS_DEFAULT_VALUE],
    flattenSelectedOptions: (options, selectedOptions) => {
      const selectedModifiers = options.filter((option) => selectedOptions.includes(option.value));
      const flattenedModifiers = selectedModifiers.reduce<Artwork_Bool_Exp>((flattenedOptions, currentOption) => {
        if (
          isOptionType<ARTWORK_QUERY_CONDITION_IN>(currentOption, 'ARTWORK_QUERY_CONDITION_IN') &&
          currentOption.payload.artwork_to_collections?.collection_id?._in
        ) {
          return {
            artwork_to_collections: {
              collection_id: {
                _in: [
                  ...(flattenedOptions.artwork_to_collections?.collection_id?._in ?? []),
                  ...currentOption.payload.artwork_to_collections.collection_id._in,
                ],
              },
            },
          };
        }
        return flattenedOptions;
      }, {});

      return {
        key: 'ARTWORK_QUERY_CONDITION_IN',
        name: 'APPLIED_SERIES_FILTER',
        value: -1,
        payload: flattenedModifiers,
      };
    },
  };
};
