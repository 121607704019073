import React from 'react';
import { Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import AvailabilityDot from 'common/components/AvailabilityDot/AvailabilityDot';
import { useSwipeContainerComponentsStyles } from './useSwipeContainerComponents.styles';

const SwipeContainerUnavailableButton: React.FC = () => {
  const classes = useSwipeContainerComponentsStyles();
  const { t } = useTranslation();

  return (
    <Button className={classes.unavailableBtn} variant="outlined" color="primary" disabled>
      <AvailabilityDot availability="UNAVAILABLE" />
      {t('artDetailsPageStrings.sidebar.availability.notAvailable')}
    </Button>
  );
};

export default SwipeContainerUnavailableButton;
