import { CDNUrl } from 'utils/constants';
import { LoadingState } from './types';

export const LOADING_ANIMATION_DURATION = 2000; // milliseconds

export const defaultLoadingState: LoadingState = {
  animation: 'PENDING',
  start: 0,
  playing: 0,
  end: 0,
  timer: null,
};

export const loadingLogoSrc = {
  webm: `${CDNUrl}/images/LoadingLogo.webm`,
  safariWhite: `${CDNUrl}/images/LoadingLogoWhite.mov`,
  safariBlack: `${CDNUrl}/images/LoadingLogoBlack.mov`,
  gif: `${CDNUrl}/images/LoadingLogo.gif`,
};
