import { useCallback } from 'react';
import { useSetDefaultContextMutation } from 'common/schema/commonSchemaRemoteGraphqlQueries';
import useApolloErrorHandler from 'common/features/ErrorHandling/hooks/useApolloErrorHandler';

const useSaveDefaultContext = (): ((appAccessId: number, contextId: number) => Promise<void>) => {
  const [setDefaultContext, { error }] = useSetDefaultContextMutation();
  useApolloErrorHandler(error);

  const saveDefaultContext = useCallback(
    async (appAccessId: number, contextId: number) => {
      setDefaultContext({ variables: { appAccessId, contextId } });
    },
    [setDefaultContext]
  );

  return saveDefaultContext;
};

export default useSaveDefaultContext;
