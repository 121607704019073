import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useGetFlaggedUserCounterQuery } from 'common/schema/commonSchemaRemoteGraphqlQueries';
import useApolloErrorHandler from 'common/features/ErrorHandling/hooks/useApolloErrorHandler';
import { securityActions } from 'store/slices/security';
import { useSelectedProfile } from './useSelectedProfile';

export const useFetchUserScamInfractions = () => {
  const contextId = useSelectedProfile()?.contextId ?? 0;
  const dispatch = useDispatch();
  const { onError } = useApolloErrorHandler();

  const { data } = useGetFlaggedUserCounterQuery({
    variables: { contextId },
    fetchPolicy: 'cache-and-network',
    skip: !contextId,
    onError,
  });

  useEffect(() => {
    if (data?.flaggedUsers?.[0]) {
      dispatch(
        securityActions.SET_SCAMMER_STATUS({
          blockFeatures: data.flaggedUsers[0].counter >= 3,
          infractionsCounter: data.flaggedUsers[0].counter,
        })
      );
    }
  }, [data?.flaggedUsers]);
};
