import { ArtCollectedCard } from 'common/components/ArtworkCard/ArtCollectedCard/ArtCollectedCard';
import { ArtPrizeCard } from 'common/components/ArtworkCard/ArtPrizeCard/ArtPrizeCard';
import { GenericArtworkCard } from 'common/components/ArtworkCard/GenericArtworkCard/GenericArtworkCard';
import useArtPrizeCardProps from 'common/components/ArtworkCard/hooks/useArtPrizeCardProps';
import useCollectedArtCardProps from 'common/components/ArtworkCard/hooks/useCollectedArtCardProps';
import useGenericCardProps from 'common/components/ArtworkCard/hooks/useGenericCardProps';
import { RenderComponentProps } from 'masonic';
import { HomeArtworksListElementProps } from '../utils/homeArtworksTypes';
import HomeCardEventsEnum from '../utils/HomeCardEventsEnum';

type HomeArtworksListEventType = React.ComponentType<
  RenderComponentProps<HomeArtworksListElementProps> & { isVisible?: boolean }
>;

const HomeArtworksEventCards: Record<HomeCardEventsEnum, HomeArtworksListEventType> = {
  [HomeCardEventsEnum.PUBLISH]: ({ data: { artworkId, columnCount, aspectRatio }, width, index }) => {
    const genericCardProps = useGenericCardProps(artworkId, width, columnCount ?? 3, index, aspectRatio);

    genericCardProps.isSquare = (window as any).SQUARE_CARD_OVERRIDE ?? genericCardProps.isSquare;
    return <GenericArtworkCard {...genericCardProps} />;
  },
  [HomeCardEventsEnum.PRIZE]: ({
    data: { artworkId, columnCount, artPrizeShowId, aspectRatio },
    width,
    isVisible,
    index,
  }) => {
    const artPrizeCardProps = useArtPrizeCardProps(
      artworkId,
      width,
      columnCount ?? 3,
      `${artworkId}_${artPrizeShowId}`,
      index,
      aspectRatio
    );
    artPrizeCardProps.prizeProps.isVisible = isVisible;

    artPrizeCardProps.isSquare = (window as any).SQUARE_CARD_OVERRIDE ?? artPrizeCardProps.isSquare;
    return <ArtPrizeCard {...artPrizeCardProps} />;
  },
  [HomeCardEventsEnum.COLLECT]: ({
    data: { artworkId, galleryContextId, collectedByCtxId, columnCount, aspectRatio },
    index,
    isVisible,
    width,
  }) => {
    const artCollectedCardProps = useCollectedArtCardProps(
      artworkId,
      columnCount ?? 3,
      galleryContextId,
      collectedByCtxId,
      index,
      width,
      aspectRatio
    );
    artCollectedCardProps.artCollectedBadgeProps.isVisible = isVisible;
    artCollectedCardProps.artCollectedChipProps.isVisible = isVisible;

    artCollectedCardProps.isSquare = (window as any).SQUARE_CARD_OVERRIDE ?? artCollectedCardProps.isSquare;
    return <ArtCollectedCard {...artCollectedCardProps} />;
  },
};

export default HomeArtworksEventCards;
