import { runtimeFlagUpdate } from 'common/features/FeatureFlag/config/features';
import { store } from 'store/store';
import { TypeUtils } from './generalTypeUtils';

export const windowReloadWithFlags = (): void => {
  const runtimeUpdates = TypeUtils.Object.keys(runtimeFlagUpdate);

  const storeFlags = store.getState().featureFlags;

  if (!window.location.hash && runtimeUpdates.length > 0) {
    window.location.hash = runtimeUpdates.map((key) => `${key}:${storeFlags[key]}`).join('.');
  }

  window.location.reload();
};
