import { getDefaultDisciplineFilter } from './store/disciplines.defaults';
import { DisciplinesFilterState } from './store/disciplines.types';

export const disciplinesFilterHash = (options: DisciplinesFilterState['disciplines']): string =>
  Object.keys(options ?? {})
    .sort()
    .filter((disciplineValue) => options[disciplineValue])
    .join('_');

const SERIALIZATION_KEY = 'disciplines';

export const disciplinesFilterSerialize = (options: DisciplinesFilterState): string => {
  const serialized = new URLSearchParams();
  serialized.set(
    SERIALIZATION_KEY,
    JSON.stringify(
      Object.keys(options.disciplines ?? {})
        .filter((disciplineValue) => options.disciplines[disciplineValue])
        .sort()
    )
  );
  return serialized.toString();
};

export const disciplinesFilterDeserialize = (serializedFilters: string): DisciplinesFilterState => {
  const serialized = new URLSearchParams(serializedFilters);
  const disciplineOptions = serialized.get(SERIALIZATION_KEY);
  const disciplinesFilter = getDefaultDisciplineFilter();
  if (!disciplineOptions) {
    return disciplinesFilter;
  }
  try {
    const parsedDiscipline = JSON.parse(disciplineOptions);
    if (!Array.isArray(parsedDiscipline)) {
      return disciplinesFilter;
    }
    parsedDiscipline.forEach((discipline) => {
      if (!discipline) {
        return;
      }
      disciplinesFilter.disciplines[discipline] = true;
    });
  } catch (e) {
    console.warn('Failed to parse discipline filter', e);
  }
  return disciplinesFilter;
};
