import { Grid } from '@material-ui/core';
import { CSSProperties } from '@material-ui/styles';
import { SkeletonImage } from '../../Image/SkeletonImage';
import { BasicImageType } from '../../Image/types';

export const SoloAspectRatioImage: BasicImageType<{
  aspectRatio?: CSSProperties['aspectRatio'];
  blur?: number;
}> = ({ aspectRatio, blur, ...basicProps }) => (
  <Grid
    container
    style={{
      width: '100%',
      height: '100%',
      maxHeight: '100%',
      overflow: 'hidden',
      borderRadius: '4px',
      aspectRatio,
      filter: !blur ? undefined : `blur(${blur}px)`,
    }}
  >
    <SkeletonImage {...basicProps} skeletonProps={{ style: { aspectRatio: aspectRatio || '2 / 1' } }} />
  </Grid>
);
