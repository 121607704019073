import { FormikSectionConfig } from 'common/features/FormikConfigs/utils/formikTypeUtils';
import { Maybe } from 'common/schema/commonSchemaRemoteTypes';
import * as Yup from 'yup';

export enum CurrencyTypeEnum {
  '$' = '$',
  'C$' = 'C$',
  '€' = '€',
  '£' = '£',
  '₿' = '₿',
  'Ξ' = 'Ξ',
  'lei' = 'lei',
}

export const currencyPossibilitiesToTypeMap: Record<string, CurrencyTypeEnum> = {
  USD: CurrencyTypeEnum.$,
  CAD: CurrencyTypeEnum.C$,
  EUR: CurrencyTypeEnum['€'],
  GBP: CurrencyTypeEnum['£'],
  BTC: CurrencyTypeEnum['₿'],
  ETH: CurrencyTypeEnum['Ξ'],
  RON: CurrencyTypeEnum.lei,
  [CurrencyTypeEnum.$]: CurrencyTypeEnum.$,
  [CurrencyTypeEnum.C$]: CurrencyTypeEnum.C$,
  [CurrencyTypeEnum.C$.toLowerCase()]: CurrencyTypeEnum.C$,
  [CurrencyTypeEnum['€']]: CurrencyTypeEnum['€'],
  [CurrencyTypeEnum['£']]: CurrencyTypeEnum['£'],
  [CurrencyTypeEnum['₿']]: CurrencyTypeEnum['₿'],
  [CurrencyTypeEnum['Ξ']]: CurrencyTypeEnum['Ξ'],
  [CurrencyTypeEnum.lei]: CurrencyTypeEnum.lei,
  [CurrencyTypeEnum.lei.toUpperCase()]: CurrencyTypeEnum.lei,
};

// we currently do not allow converting to/from crypto
export const convertibleCurrencies: CurrencyTypeEnum[] = [
  CurrencyTypeEnum.$,
  CurrencyTypeEnum.C$,
  CurrencyTypeEnum['€'],
  CurrencyTypeEnum['£'],
  CurrencyTypeEnum.lei,
];

export const Currencies: { [key in CurrencyTypeEnum]: string } = {
  [CurrencyTypeEnum.$]: '$ US Dollar',
  [CurrencyTypeEnum.C$]: '$ CA Dollar',
  [CurrencyTypeEnum['€']]: '€ Euro',
  [CurrencyTypeEnum['£']]: '£ Pound',
  [CurrencyTypeEnum.lei]: 'lei RON',
  [CurrencyTypeEnum['₿']]: '₿ Bitcoin',
  [CurrencyTypeEnum.Ξ]: 'Ξ Ether',
};

export interface CurrencySelectorConfigValues {
  price?: number;
  priceId?: number;
  currency?: Maybe<CurrencyTypeEnum>;
}

export const CurrencySelectorFormikConfig: FormikSectionConfig<CurrencySelectorConfigValues> = (override) => ({
  initialValues: {
    currency: override?.currency,
    price: override?.price,
  },
  validationSchema: {
    currency: Yup.string().optional(),
    price: Yup.number().optional(),
  },
});
