import { ArtworksListType } from 'store/slices/artworkListModifiers';
import { CDNBaseUrl } from 'utils/constants';
import {
  AppliedListingModifier,
  ARTWORK_LOCAL_CONDITION,
  ARTWORK_LOCAL_CONDITION_PAYLOAD,
  FILTERS_DEFAULT_VALUE,
  isOptionType,
  ListingModifier,
  ListingModifierButtonsConfig,
} from './types';

enum KnownOptions {
  ORIENTATION_ALL = 'filterStrings.orientation.all',
  ORIENTATION_PORTRAIT = 'filterStrings.orientation.portrait',
  ORIENTATION_LANDSCAPE = 'filterStrings.orientation.landscape',
  ORIENTATION_SQUARE = 'filterStrings.orientation.square',
}

export const getOrientationFilter = (): ListingModifier => ({
  type: 'ORIENTATION',
  allOptions: [
    {
      value: FILTERS_DEFAULT_VALUE,
      name: KnownOptions.ORIENTATION_ALL,
      key: 'ARTWORK_LOCAL_CONDITION',
      payload: () => true,
    },
    {
      value: 1,
      name: KnownOptions.ORIENTATION_PORTRAIT,
      key: 'ARTWORK_LOCAL_CONDITION',
      payload: (artwork) => (artwork.details?.width ?? 0) < (artwork.details?.height ?? 0),
    },
    {
      value: 2,
      name: KnownOptions.ORIENTATION_LANDSCAPE,
      key: 'ARTWORK_LOCAL_CONDITION',
      payload: (artwork) => (artwork.details?.width ?? 0) > (artwork.details?.height ?? 0),
    },
    {
      value: 3,
      name: KnownOptions.ORIENTATION_SQUARE,
      key: 'ARTWORK_LOCAL_CONDITION',
      payload: (artwork) => artwork.details?.width === artwork.details?.height,
    },
  ],
});

export const orientationButtonsDefaultConfig: ListingModifierButtonsConfig = {
  [KnownOptions.ORIENTATION_ALL]: { specialWidth: 'HALF' },
  [KnownOptions.ORIENTATION_PORTRAIT]: {
    specialWidth: 'HALF',
    image: `${CDNBaseUrl}/static/images/showroom/portrait.svg`,
  },
  [KnownOptions.ORIENTATION_LANDSCAPE]: {
    specialWidth: 'HALF',
    image: `${CDNBaseUrl}/static/images/showroom/landscape.svg`,
  },
  [KnownOptions.ORIENTATION_SQUARE]: {
    specialWidth: 'HALF',
    image: `${CDNBaseUrl}/static/images/showroom/square.svg`,
  },
};
export const orientationButtonsListTypeConfig: {
  [key in ArtworksListType]: ListingModifierButtonsConfig;
} = {
  SHOWROOM: { ...orientationButtonsDefaultConfig },
  ARTIST_PROFILE: { ...orientationButtonsDefaultConfig },
  COLLECTOR_PROFILE: { ...orientationButtonsDefaultConfig },
  FAVORITES: { ...orientationButtonsDefaultConfig },
};

export const getAppliedShowroomOrientationFilter = (): AppliedListingModifier<ARTWORK_LOCAL_CONDITION> => ({
  ...getOrientationFilter(),
  selectionType: 'MULTIPLE',
  selectedOptions: [FILTERS_DEFAULT_VALUE],
  flattenSelectedOptions: (options, selectedOptions) => {
    const selectedModifiers = options.filter((option) => selectedOptions.includes(option.value));
    const flattenedModifiers: ARTWORK_LOCAL_CONDITION_PAYLOAD =
      selectedModifiers.reduce<ARTWORK_LOCAL_CONDITION_PAYLOAD>(
        (flattened, currentModifier) => {
          if (!isOptionType<ARTWORK_LOCAL_CONDITION>(currentModifier, 'ARTWORK_LOCAL_CONDITION')) {
            return flattened;
          }
          return (artwork) => flattened(artwork) || currentModifier.payload(artwork);
        },
        () => false
      );
    return {
      key: 'ARTWORK_LOCAL_CONDITION',
      name: 'ARTWORK_APPLIED_FILTER',
      value: -1,
      payload: flattenedModifiers,
    };
  },
});
