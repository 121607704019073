import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { myDocumentsActions } from 'store/slices/myDocumentsSlice';
import { myProvenanceActions } from 'store/slices/myProvenanceSlice';
import {
  useGetArtistsArtworksCountLazyQuery,
  useGetMyStudioConfigurationLazyQuery,
} from 'common/schema/commonSchemaRemoteGraphqlQueries';
import useApolloErrorHandler from 'common/features/ErrorHandling/hooks/useApolloErrorHandler';
import { useSelectedProfile } from './useSelectedProfile';
import {
  MY_DOCUMENTS_DEFAULT_ITEMS_PER_PAGE,
  MY_PROVENANCE_DEFAULT_ITEMS_PER_PAGE,
} from '../components/MyStudioPagination/utils/myStudioPaginationUtils';

export const useFetchUserMyProvenanceSettings = () => {
  const dispatch = useDispatch();
  const contextId = useSelectedProfile()?.contextId ?? 0;

  const [getUserMyStudioConfiguration, { error: myStudioConfigurationError }] = useGetMyStudioConfigurationLazyQuery({
    fetchPolicy: 'cache-and-network',
  });
  useApolloErrorHandler(myStudioConfigurationError);

  const [getArtistArtworksCount, { error: artworksCountError }] = useGetArtistsArtworksCountLazyQuery({
    fetchPolicy: 'cache-and-network',
  });
  useApolloErrorHandler(artworksCountError);

  useEffect(() => {
    if (!contextId) {
      return;
    }
    (async () => {
      const { data: artworksCount } = await getArtistArtworksCount({ variables: { contextId } });
      dispatch(myProvenanceActions.SET_HAS_ARTWORKS((artworksCount?.artworks ?? 0) > 0));
    })();
  }, [dispatch, contextId, getArtistArtworksCount]);

  useEffect(() => {
    if (!contextId) {
      return;
    }
    (async () => {
      const { data } = await getUserMyStudioConfiguration({ variables: { contextId } });
      dispatch(
        myDocumentsActions.SET_ITEMS_PER_PAGE(
          data?.My_studio_settings?.[0]?.my_documents_pagination ?? MY_DOCUMENTS_DEFAULT_ITEMS_PER_PAGE
        )
      );
      dispatch(
        myProvenanceActions.SET_ITEMS_PER_PAGE(
          data?.My_studio_settings?.[0]?.my_provenance_pagination ?? MY_PROVENANCE_DEFAULT_ITEMS_PER_PAGE
        )
      );
    })();
  }, [dispatch, contextId, getUserMyStudioConfiguration]);
};
