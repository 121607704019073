import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Enum_Social_Graph_Followers_State_Enum, Scalars } from 'common/schema/commonSchemaRemoteTypes';

export type RecognitionDataType = {
  fromContextId: Scalars['bigint'];
  toContextId: Scalars['bigint'];
  state: Enum_Social_Graph_Followers_State_Enum;
  isMutual: boolean;
  selectedOrder?: number;
  naturalOrder?: number;
};

export type RecognitionsStateType = {
  currentContextId: Scalars['bigint'];
  incoming: Record<RecognitionDataType['fromContextId'], RecognitionDataType>;
  outgoing: Record<RecognitionDataType['toContextId'], RecognitionDataType>;
};

const initialState: RecognitionsStateType = {
  currentContextId: 0,
  incoming: {},
  outgoing: {},
};

export const recognitionsSlice = createSlice({
  name: 'recognitionsCache',
  initialState,
  reducers: {
    FETCHED_RECOGNITIONS: (
      state,
      action: PayloadAction<{
        currentContextId: number;
        recognitions: RecognitionDataType[];
      }>
    ) => {
      const { currentContextId, recognitions } = action.payload;
      if (currentContextId !== state.currentContextId) {
        state.currentContextId = currentContextId;
        state.incoming = {};
        state.outgoing = {};
      }
      recognitions.forEach((recog) => {
        if (recog.fromContextId === currentContextId) {
          // outgoing
          state.outgoing[recog.toContextId] = recog;
          if (state.incoming[recog.toContextId]) {
            state.incoming[recog.toContextId].isMutual = recog.isMutual;
          }
        } else {
          // incoming
          state.incoming[recog.fromContextId] = recog;
          if (state.outgoing[recog.fromContextId]) {
            state.outgoing[recog.fromContextId].isMutual = recog.isMutual;
          }
        }
      });
    },
    RECOGNIZE: (state, action: PayloadAction<Scalars['bigint']>) => {
      const toContextId = action.payload;
      const isMutual = state.incoming[toContextId]?.state === Enum_Social_Graph_Followers_State_Enum.Active;
      state.outgoing[toContextId] = {
        ...(state.outgoing[toContextId] ?? {}),
        isMutual,
        toContextId,
        fromContextId: state.currentContextId,
        state: Enum_Social_Graph_Followers_State_Enum.Active,
      };
      const fromContextId = toContextId;
      if (state.incoming[fromContextId]) {
        state.incoming[fromContextId] = {
          ...state.incoming[fromContextId],
          isMutual,
        };
      }
    },
    UNRECOGNIZE: (state, action: PayloadAction<Scalars['bigint']>) => {
      const toContextId = action.payload;
      state.outgoing[toContextId] = {
        ...(state.outgoing[toContextId] ?? {}),
        isMutual: false,
        toContextId,
        fromContextId: state.currentContextId,
        state: Enum_Social_Graph_Followers_State_Enum.Deleted,
      };
      const fromContextId = toContextId;
      if (state.incoming[fromContextId]) {
        state.incoming[fromContextId] = {
          ...state.incoming[fromContextId],
          isMutual: false,
        };
      }
    },
    DECLINE: (state, action: PayloadAction<Scalars['bigint']>) => {
      const toContextId = action.payload;
      state.outgoing[toContextId] = {
        ...(state.outgoing[toContextId] ?? {}),
        isMutual: false,
        toContextId,
        fromContextId: state.currentContextId,
        state: Enum_Social_Graph_Followers_State_Enum.Declined,
      };
      const fromContextId = toContextId;
      if (state.incoming[fromContextId]) {
        state.incoming[fromContextId] = {
          ...state.incoming[fromContextId],
          isMutual: false,
        };
      }
    },
  },
});
