import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MY_PROVENANCE_DEFAULT_ITEMS_PER_PAGE } from 'common/components/MyStudioPagination/utils/myStudioPaginationUtils';

export interface myProvenanceSliceState {
  isLearnMoreModalOpen: boolean;
  currentPage: number;
  itemsPerPage: number;
  totalArtworks: number;
  hasArtworks: boolean;
}

const initialState: myProvenanceSliceState = {
  isLearnMoreModalOpen: false,
  currentPage: 1,
  itemsPerPage: MY_PROVENANCE_DEFAULT_ITEMS_PER_PAGE,
  totalArtworks: 0,
  hasArtworks: false,
};

const myProvenanceSlice = createSlice({
  name: 'myProvenance',
  initialState,
  reducers: {
    OPEN_MY_PROVENANCE_LEARN_MORE_MODAL: (state) => ({ ...state, isLearnMoreModalOpen: true }),
    CLOSE_MY_PROVENANCE_LEARN_MORE_MODAL: (state) => ({ ...state, isLearnMoreModalOpen: false }),
    SET_ITEMS_PER_PAGE: (state, action: PayloadAction<number>) => ({ ...state, itemsPerPage: action.payload }),
    SET_CURRENT_PAGE: (state, action: PayloadAction<number>) => ({ ...state, currentPage: action.payload }),
    SET_TOTAL_ARTWORKS: (state, action: PayloadAction<number>) => ({ ...state, totalArtworks: action.payload }),
    SET_HAS_ARTWORKS: (state, action: PayloadAction<boolean>) => ({ ...state, hasArtworks: action.payload }),
  },
});

export const myProvenanceActions = myProvenanceSlice.actions;
export default myProvenanceSlice;
