import { getDefaultFilters } from './filter.defaults';
import {
  CollectorsListState,
  CollectorsListByDate,
  ListDirection,
  listingDirections,
  ListWindowLoaderStateType,
} from './list.types';
import { filtersHash } from '../../hooks/collectors/useCollectorsFiltersIdentifier';

export const defaults = {
  byDate: (): {
    type: 'byDate';
    data: CollectorsListByDate;
  } => ({
    type: 'byDate',
    data: {
      __typename: 'byDate',
      lastScrollPosition: 0,
      detailedToListedStartOffset: 0,
      cursor: [
        { appearedAt: '1000-01-01T00:00:00.000000+00:00', contextId: 0 },
        { appearedAt: '4000-01-01T00:00:00.000000+00:00', contextId: 0 },
      ],
      listed: {
        batchSize: 64,
        status: 'COLD',
        values: [],
        hasMore: [false, false],
      },
      detailed: {
        batchSize: 16,
        status: 'COLD',
        values: [],
        hasMore: [false, false],
      },
    },
  }),
};

const defaultFilters = getDefaultFilters();
export const listInitialState: CollectorsListState = {
  identifier: filtersHash(defaultFilters),
  ...defaults[defaultFilters.sort](),
};

export const getDefaultListState = (): CollectorsListState => ({
  identifier: filtersHash(defaultFilters),
  ...defaults[defaultFilters.sort](),
});

export const hasMore = (loader: ListWindowLoaderStateType<unknown, unknown>, direction: ListDirection): boolean =>
  loader.hasMore[listingDirections.indexOf(direction)];
