import { createSlice } from '@reduxjs/toolkit';

export enum EventTriggers {
  ExampleEvent,
  AuthState,
  OpenRecognitions,
  OpenCV,
  RecognizeButtonPressed,
  addArtworkSeriesNextButtonPressed,
  ExplorePageHack,
  profileGalleryStatementViewMoreButtonPressed,
  WindowIsScrolling,
  ArtShowcaseCloseSecondaryContentSlider,
  ArtistProfileIsAtTop,
  IsProfile,
  isShareableLinksOpen,
  ShopFindThePerfectArtwork,
  ShouldShowAudioTooltip,
  RefetchDomainSetupProviderQuery,
  ArtworkUploaded,
  Is4ColumnsTooltipOpen,
  RearrangeSectionsDynamicCV,
  IsMyWebsiteMobileTopBannerOpen,
}

export interface EventTriggerState {
  [EventTriggers.ExampleEvent]: number;
  [EventTriggers.RecognizeButtonPressed]: number;
  [EventTriggers.OpenRecognitions]: boolean;
  [EventTriggers.OpenCV]: boolean;
  [EventTriggers.addArtworkSeriesNextButtonPressed]: number;
  [EventTriggers.ExplorePageHack]: boolean;
  [EventTriggers.AuthState]: 'PENDING' | 'AUTHENTICATED' | 'LOGGED_IN' | 'LOGGED_OUT';
  [EventTriggers.profileGalleryStatementViewMoreButtonPressed]: number;
  [EventTriggers.WindowIsScrolling]: number;
  [EventTriggers.ArtShowcaseCloseSecondaryContentSlider]: number;
  [EventTriggers.ArtistProfileIsAtTop]: boolean;
  [EventTriggers.IsProfile]: boolean;
  [EventTriggers.isShareableLinksOpen]: boolean;
  [EventTriggers.ShopFindThePerfectArtwork]: boolean;
  [EventTriggers.ShouldShowAudioTooltip]: boolean;
  [EventTriggers.RefetchDomainSetupProviderQuery]: boolean;
  [EventTriggers.ArtworkUploaded]: number;
  [EventTriggers.Is4ColumnsTooltipOpen]: boolean;
  [EventTriggers.RearrangeSectionsDynamicCV]: boolean;
  [EventTriggers.IsMyWebsiteMobileTopBannerOpen]: boolean;
}

const initialState: EventTriggerState = {
  [EventTriggers.OpenRecognitions]: false,
  [EventTriggers.OpenCV]: false,
  [EventTriggers.ExplorePageHack]: false,
  [EventTriggers.RecognizeButtonPressed]: 0,
  [EventTriggers.addArtworkSeriesNextButtonPressed]: 0,
  [EventTriggers.ExampleEvent]: 0,
  [EventTriggers.AuthState]: 'PENDING',
  [EventTriggers.profileGalleryStatementViewMoreButtonPressed]: 0,
  [EventTriggers.WindowIsScrolling]: 0,
  [EventTriggers.ArtShowcaseCloseSecondaryContentSlider]: 0,
  [EventTriggers.ArtistProfileIsAtTop]: true,
  [EventTriggers.IsProfile]: false,
  [EventTriggers.isShareableLinksOpen]: false,
  [EventTriggers.ShopFindThePerfectArtwork]: false,
  [EventTriggers.ShouldShowAudioTooltip]: true,
  [EventTriggers.RefetchDomainSetupProviderQuery]: false,
  [EventTriggers.ArtworkUploaded]: 0,
  [EventTriggers.Is4ColumnsTooltipOpen]: false,
  [EventTriggers.RearrangeSectionsDynamicCV]: false,
  [EventTriggers.IsMyWebsiteMobileTopBannerOpen]: true,
};

const defaultSetters: Record<EventTriggers, (state: EventTriggerState) => unknown> = {
  [EventTriggers.OpenRecognitions]: (state) => state[EventTriggers.OpenRecognitions],
  [EventTriggers.OpenCV]: (state) => state[EventTriggers.OpenCV],
  [EventTriggers.ExplorePageHack]: (state) => state[EventTriggers.ExplorePageHack],
  [EventTriggers.addArtworkSeriesNextButtonPressed]: (state) =>
    state[EventTriggers.addArtworkSeriesNextButtonPressed] + 1,
  [EventTriggers.RecognizeButtonPressed]: (state) => state[EventTriggers.RecognizeButtonPressed] + 1,
  [EventTriggers.ExampleEvent]: (state) => state[EventTriggers.ExampleEvent] + 1,
  [EventTriggers.AuthState]: (state) => state[EventTriggers.AuthState],
  [EventTriggers.profileGalleryStatementViewMoreButtonPressed]: (state) =>
    state[EventTriggers.profileGalleryStatementViewMoreButtonPressed] + 1,
  [EventTriggers.WindowIsScrolling]: (state) => {
    state[EventTriggers.WindowIsScrolling] = 1;
  },
  [EventTriggers.ArtShowcaseCloseSecondaryContentSlider]: (state) =>
    state[EventTriggers.ArtShowcaseCloseSecondaryContentSlider] + 1,
  [EventTriggers.ArtistProfileIsAtTop]: (state) => state[EventTriggers.ArtistProfileIsAtTop],
  [EventTriggers.IsProfile]: (state) => state[EventTriggers.IsProfile],
  [EventTriggers.isShareableLinksOpen]: (state) => state[EventTriggers.isShareableLinksOpen],
  [EventTriggers.ShopFindThePerfectArtwork]: (state) => state[EventTriggers.ShopFindThePerfectArtwork],
  [EventTriggers.ShouldShowAudioTooltip]: (state) => state[EventTriggers.ShouldShowAudioTooltip],
  [EventTriggers.RefetchDomainSetupProviderQuery]: (state) => state[EventTriggers.RefetchDomainSetupProviderQuery],
  [EventTriggers.ArtworkUploaded]: (state) => state[EventTriggers.ArtworkUploaded] + 1,
  [EventTriggers.Is4ColumnsTooltipOpen]: (state) => state[EventTriggers.Is4ColumnsTooltipOpen],
  [EventTriggers.RearrangeSectionsDynamicCV]: (state) => state[EventTriggers.RearrangeSectionsDynamicCV],
  [EventTriggers.IsMyWebsiteMobileTopBannerOpen]: (state) => state[EventTriggers.IsMyWebsiteMobileTopBannerOpen],
};

const eventTriggerSlice = createSlice({
  name: 'eventTriggers',
  initialState,
  reducers: {
    reset: (state, { payload }: { payload: EventTriggers }) => ({
      ...state,
      [payload]: initialState[payload],
    }),
    set: <T extends keyof EventTriggerState, V = EventTriggerState[T] | null>(
      state: EventTriggerState,
      {
        payload: { trigger, value },
      }: {
        payload: {
          trigger: T;
          value: V;
        };
      }
    ) => ({
      ...state,
      [trigger]: value === null ? defaultSetters[trigger](state) : value,
    }),
  },
});

export const eventTriggerActions = eventTriggerSlice.actions;
export default eventTriggerSlice;
