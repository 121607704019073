import { Enum_Tutorial_Step_Type_Enum } from 'common/schema/commonSchemaRemoteTypes';
import { DashboardRoutesConfig } from 'stacks/Dashboard/common/DashboardRoot/configs/routesConfigs';
import { MilestoneFeature } from './milestonesTypes';

export const MIN_PROGRESS = 0;
export const MAX_PROGRESS = 100;

export const milestoneFeatures = [
  Enum_Tutorial_Step_Type_Enum.KaleidoCard,
  // Enum_Tutorial_Step_Type_Enum.KaleidoCoins,
  Enum_Tutorial_Step_Type_Enum.Discovery,
  Enum_Tutorial_Step_Type_Enum.Appearance,
  Enum_Tutorial_Step_Type_Enum.Showroom,
  Enum_Tutorial_Step_Type_Enum.AiAudioStatement,
  Enum_Tutorial_Step_Type_Enum.JoinArtUniverse,
  Enum_Tutorial_Step_Type_Enum.AiAudioStatement,
] as const;

export const milestoneStatuses = ['ONGOING', 'COMPLETED', 'UNLOCK', 'UNLOCKED'] as const;
export const milestoneUnlockStatuses = ['UNLOCK', 'UNLOCKED'];

export const featureTypeToTranslationKeyMap: { [key in MilestoneFeature]: string } = {
  APPEARANCE: 'settingsStrings.profileEdit.artistTabs.appearanceLabel',
  KALEIDO_CARD: 'dashboardStrings.menu.artist.MyBioLink',
  // KALEIDO_COINS: 'dashboardStrings.menu.artist.kaleidoCoins',
  SHOWROOM: 'dashboardStrings.shareKaleido.showrooms',
  DISCOVERY: 'dashboardStrings.menu.artist.discovery',
  AI_AUDIO_STATEMENT: 'dashboardStrings.menu.artist.aiAudioStatement',
  JOIN_ART_UNIVERSE: 'dashboardStrings.menu.artist.artUniverse',
};

export const featureToUrl: { [key in Enum_Tutorial_Step_Type_Enum]?: string } = {
  APPEARANCE: `${DashboardRoutesConfig.profile}/appearance`,
  KALEIDO_CARD: DashboardRoutesConfig.bioLink,
  // KALEIDO_COINS: DashboardRoutesConfig.kaleidoCoins,
  SHOWROOM: DashboardRoutesConfig.manageShowrooms,
  DISCOVERY: DashboardRoutesConfig.discovery,
  EDIT_PROFILE: DashboardRoutesConfig.profile,
  AI_AUDIO_STATEMENT: `${DashboardRoutesConfig.profile}/ai-audio-statement`,
  JOIN_ART_UNIVERSE: DashboardRoutesConfig.applyToArtUniverse,
};

export const DEFAULT_MILESTONE_HIDDEN_VALUE = false;

export const milestonesWithCustomUnlockMessage = [
  Enum_Tutorial_Step_Type_Enum.Appearance,
  Enum_Tutorial_Step_Type_Enum.Discovery,
  Enum_Tutorial_Step_Type_Enum.AiAudioStatement,
];

export const featureMilestonesChildren: {
  [key in Enum_Tutorial_Step_Type_Enum]?: MilestoneFeature[];
} = {
  [Enum_Tutorial_Step_Type_Enum.EditProfile]: [
    Enum_Tutorial_Step_Type_Enum.Appearance,
    Enum_Tutorial_Step_Type_Enum.AiAudioStatement,
  ],
};
