import { createSlice } from '@reduxjs/toolkit';
import { Enum_Connected_Domain_State_Enum, Enum_Language_Preference_Enum } from 'common/schema/commonSchemaRemoteTypes';

export interface ConnectedDomainInfo {
  host: string;
  profileHandle: string;
  status: Enum_Connected_Domain_State_Enum;
  preferredLanguage?: Enum_Language_Preference_Enum;
  isLoading?: boolean;
}
export interface DomainState {
  domain: ConnectedDomainInfo | null;
}

const initialState: DomainState = {
  domain: null,
};

const domainSlice = createSlice({
  initialState,
  name: 'domain',
  reducers: {
    SET_DOMAIN_DATA: (state, action) => ({
      ...state,
      ...action.payload,
    }),
  },
});

export const domainActions = domainSlice.actions;
export const domainReducer = domainSlice.reducer;
