import { LIST_RADIUS_SEARCH_OPTIONS } from 'stacks/Nearby/NearbyRadiusDropdown';
import { filtersHash } from '../hooks/useNearbyArtistsFiltersIdentifier';
import { getDefaultFilters } from './filter.defaults';
import {
  NearbyArtistsListState,
  NearbyArtworksListByDate,
  NearbyArtworksListByTitle,
  ListDirection,
  listingDirections,
  ListWindowLoaderStateType,
} from './list.types';

export const defaults = {
  byDate: (): {
    type: 'byDate';
    data: NearbyArtworksListByDate;
  } => ({
    type: 'byDate',
    data: {
      __typename: 'byDate',
      lastScrollPosition: 0,
      detailedToListedStartOffset: 0,
      cursor: [
        { appearedAt: '1000-01-01T00:00:00.000000+00:00', contextId: 0 },
        { appearedAt: '4000-01-01T00:00:00.000000+00:00', contextId: 0 },
      ],
      listed: {
        batchSize: 64,
        status: 'COLD',
        values: [],
        hasMore: [false, false],
      },
      detailed: {
        batchSize: 16,
        status: 'COLD',
        values: [],
        hasMore: [false, false],
      },
    },
  }),
  byTitle: (): {
    type: 'byTitle';
    data: NearbyArtworksListByTitle;
  } => ({
    type: 'byTitle',
    data: {
      __typename: 'byTitle',
      lastScrollPosition: 0,
      detailedToListedStartOffset: 0,
      cursor: [
        { title: 'zzzzzzzzz', contextId: 0 },
        { title: '', contextId: 0 },
      ],
      listed: {
        batchSize: 64,
        status: 'COLD',
        values: [],
        hasMore: [false, false],
      },
      detailed: {
        batchSize: 16,
        status: 'COLD',
        values: [],
        hasMore: [false, false],
      },
    },
  }),
};

const defaultFilters = getDefaultFilters();

const defaultFiltersForEveryRadius = LIST_RADIUS_SEARCH_OPTIONS.map((option) =>
  filtersHash({ ...getDefaultFilters(), currentRadius: option })
);
export const isDefaultFilter = (identifier: string): boolean =>
  defaultFiltersForEveryRadius.some((hash) => identifier.startsWith(hash));

export const listInitialState: NearbyArtistsListState = {
  identifier: filtersHash(defaultFilters),
  ...defaults[defaultFilters.sort](),
};

export const hasMore = (loader: ListWindowLoaderStateType<unknown, unknown>, direction: ListDirection): boolean =>
  loader.hasMore[listingDirections.indexOf(direction)];
