import { SkeletonImage, SkeletonImageType } from '../../Image/SkeletonImage';

export const SoloImage: SkeletonImageType = (props) => (
  <div
    style={{
      width: '100%',
      height: '100%',
      maxHeight: '100%',
      overflow: 'hidden',
      borderRadius: '4px',
    }}
  >
    <SkeletonImage skeletonProps={{ style: { minHeight: '75px' } }} {...props} />
  </div>
);
