import React from 'react';
import { Box } from '@material-ui/core';
import clsx from 'clsx';
import { useArtCollectedBadgeStyles } from './ArtCollectedBadge.styles';

type AnimatedArrowProps = {
  isVisible?: boolean;
  style?: React.CSSProperties;
};

const AnimatedArrow: React.FC<AnimatedArrowProps> = ({ isVisible, style }) => {
  const classes = useArtCollectedBadgeStyles();

  return (
    <Box className={clsx(classes.arrow, isVisible ? classes.arrowAnimation : '')} style={style}>
      <Box className={classes.arrowLine} />
      <Box className={classes.arrowHead} />
    </Box>
  );
};

export default AnimatedArrow;
