export enum SortOptionsEnum {
  MOST_RECENT = 'most_recent',
  LEAST_RECENT = 'least_recent',
  COLLECTOR_SELECTION = 'collector_selection',
  ARTIST_SELECTION = 'artist_selection',
  BIGGEST_SIZE = 'biggest_size',
  SMALLEST_SIZE = 'smallest_size',
  MOST_EXPENSIVE = 'most_expensive',
  LEAST_EXPENSIVE = 'least_expensive',
  ALPHABETICAL = 'alphabetical',
  MOST_PARTICIPANTS = 'most_participants',
  CURATION_SCORE = 'curation_score',

  _EXHAUSTIVE_OPTION = '_EXHAUSTIVE_OPTION',
}

export enum KnownVirtualListEnum {
  ARTIST_PROFILE_ARTWORKS = 'ARTIST_PROFILE_ARTWORKS',
  ARTIST_MANAGE_ARTWORKS = 'ARTIST_MANAGE_ARTWORKS',
  ART_SHOW_ARTWORKS = 'ART_SHOW_ARTWORKS',
  NOTIFICATION_CENTER = 'NOTIFICATION_CENTER',
  PROFILE_MUSEUM_ARTWORKS = 'PROFILE_MUSEUM_ARTWORKS',
  SHOP = 'SHOP',
}
