import { Grid } from '@material-ui/core';
import { CSSProperties } from '@material-ui/styles';
import { ColumnText } from 'common/components/TypographyText/ColumnText';
import React from 'react';
import { useSkeletonImageLogic } from '../Image/SkeletonImage';
import KaleidoAvatar from '../KaleidoAvatar/KaleidoAvatar';
import { TypographyTextType } from '../TypographyText/SkeletonText';

export type ArtistInfoChipProps = {
  image?: string;
  textItems?: (TypographyTextType | null)[];
  avatarSize?: CSSProperties['height'];
  hide?: boolean;
};

export const ArtistInfoChip: React.FC<ArtistInfoChipProps> = ({ image, textItems, avatarSize = '48px' }) => {
  const loadedSrc = useSkeletonImageLogic(image);
  return (
    <Grid container direction="row" wrap="nowrap" alignItems="center">
      <Grid item style={{ paddingRight: 2 }}>
        <KaleidoAvatar
          src={loadedSrc ?? undefined}
          style={{
            height: avatarSize,
            width: avatarSize,
          }}
          size={avatarSize}
        />
      </Grid>
      <Grid
        item
        style={{
          alignSelf: 'center',
          width: '100%',
          paddingLeft: 8,
          maxWidth: `calc('100%' - ${avatarSize})`,
        }}
        zeroMinWidth
      >
        <ColumnText style={{ width: '100%' }}>
          {textItems?.map((textProps: TypographyTextType | null) => textProps)}
        </ColumnText>
      </Grid>
    </Grid>
  );
};
