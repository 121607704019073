import { CustomDomainRouteParamsType, CUSTOM_DOMAIN_ROUTES, RouteParamsType, ROUTES } from 'routes/_definition';

export type PublicDomainRouteParams<T extends ROUTES> = Extract<RouteParamsType, [T, unknown]>[1];
export type CustomDomainRouteParams<T extends CUSTOM_DOMAIN_ROUTES> = Extract<
  CustomDomainRouteParamsType,
  [T, unknown]
>[1];

const getMatchRouteParams = <T extends ROUTES | CUSTOM_DOMAIN_ROUTES>(
  route: T,
  inputPathname?: string
): {
  params: Partial<Record<string, string>>;
  route: T;
  isExact: boolean;
  pathname: string;
} => {
  const pathname = inputPathname ?? window.location.pathname;
  const pathChunks = pathname.split('/');
  const routeChunks = route.split('/');

  let isExact = pathChunks.length === routeChunks.length;
  const rawParams = routeChunks
    .map((chunk, index) => {
      if (chunk.startsWith(':')) {
        return [chunk.slice(1), pathChunks[index]];
      }
      isExact = isExact && chunk === pathChunks[index];
      return null;
    })
    .filter((v): v is [string, string] => v !== null);

  return {
    route,
    isExact,
    params: Object.fromEntries(rawParams),
    pathname,
  };
};
export default getMatchRouteParams;
