import { listingDirections } from 'stacks/Home/Artists/store/list.types';
import { defaultHasMoreInListingDirections, HasMoreInListingDirections, ListingDirection } from './listingDirections';
import { defaultListingStatus, ListingStatus } from './listingStatus';

export type ListLoader<ValuesType> = {
  batchSize: number;
  values: Array<ValuesType>;
  status: ListingStatus;
  hasMore: HasMoreInListingDirections;
};
type ListLoaderTypeGuard<ValuesType> = (
  possibleListLoader: ListLoader<unknown>
) => possibleListLoader is ListLoader<ValuesType>;
export const generateListLoaderTypeGuard =
  <ValuesType>(possibleValuesCheck: (castedValues: ValuesType) => boolean): ListLoaderTypeGuard<ValuesType> =>
  (possibleListLoader): possibleListLoader is ListLoader<ValuesType> => {
    if (!possibleListLoader) {
      return false;
    }
    return possibleListLoader.values.length === 0 || possibleValuesCheck(possibleListLoader.values[0] as ValuesType);
  };
export const defaultListLoader: ListLoader<unknown> = {
  batchSize: 32,
  values: [],
  status: defaultListingStatus,
  hasMore: [...defaultHasMoreInListingDirections],
};
export const getDefaultListLoader = <ValuesType>(customBatchSize?: number): ListLoader<ValuesType> => ({
  ...defaultListLoader,
  values: [] as Array<ValuesType>,
  batchSize: customBatchSize ?? defaultListLoader.batchSize,
});

export const canLoadMore = (loader: ListLoader<unknown>, direction: ListingDirection): boolean =>
  loader.hasMore[listingDirections.indexOf(direction)];
