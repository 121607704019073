import { useSelectedProfile } from 'common/hooks/useSelectedProfile';
import React, { useCallback } from 'react';
import { FollowButtonBaseProps } from '../components/FollowButtonBase';
import useRecognizeUserTrapCallback from './useRecognizeUserTrapCallback';
import useResetMyRecognitionsListing from './useResetMyRecognitionsListing';
import useUpdateRecognition, { UseUpdateRecognitionProps } from './useUpdateRecognition';

export type UseRecognizeButtonLogicPropsType = {
  targetContextId?: number;
  isInitiallyRecognizing?: boolean;
  skipRecognitionsListingReset?: boolean;
  targetExploreImages?: string[];
  onRecognitionUpdate?: UseUpdateRecognitionProps['onRecognitionUpdate'];
  onUserTrapTrigger?: FollowButtonBaseProps['onClick'];
  onValidClick?: FollowButtonBaseProps['onClick'];
  alternativeClickAction?: () => void;
};
type UseRecognizeButtonLogicResultType = {
  isRecognizing: boolean;
  clickHandler: (e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  isTrigger: boolean;
};

type UseRecognizeButtonLogicType = (props: UseRecognizeButtonLogicPropsType) => UseRecognizeButtonLogicResultType;

const stopPropagationAndDoNothing = (e?: React.MouseEvent) => {
  e?.stopPropagation();
  e?.preventDefault();
};

const useRecognizeButtonLogic: UseRecognizeButtonLogicType = ({
  targetContextId = 0,
  isInitiallyRecognizing = false,
  skipRecognitionsListingReset = false,
  targetExploreImages,
  onRecognitionUpdate,
  onUserTrapTrigger,
  onValidClick,
  alternativeClickAction,
}) => {
  const currentUserContextId = useSelectedProfile()?.contextId ?? 0;
  const {
    isRecognizing,
    onClickHandler: updateRecognitionClickHandler,
    isTrigger,
  } = useUpdateRecognition({
    isInitiallyRecognizing,
    targetContextId,
    onRecognitionUpdate,
  });
  const resetRecognitionsListing = useResetMyRecognitionsListing({
    skipReset: skipRecognitionsListingReset,
  });
  const updateRecognitionAndPossiblyResetListing = useCallback<UseRecognizeButtonLogicResultType['clickHandler']>(
    (e) => {
      stopPropagationAndDoNothing(e);
      resetRecognitionsListing();
      updateRecognitionClickHandler(e);
      if (alternativeClickAction) {
        alternativeClickAction();
      }
      onValidClick?.(e);
    },
    [updateRecognitionClickHandler, resetRecognitionsListing, onValidClick, alternativeClickAction]
  );
  const recognizeUserTrap = useRecognizeUserTrapCallback();
  const recognizeUserTrapClickHandler = useCallback<UseRecognizeButtonLogicResultType['clickHandler']>(
    (e) => {
      stopPropagationAndDoNothing(e);
      recognizeUserTrap(targetContextId, targetExploreImages);
      onUserTrapTrigger?.(e);
    },
    [recognizeUserTrap, targetContextId, targetExploreImages, onUserTrapTrigger]
  );

  // data not loaded or something - nothing can be done without a target
  if (!targetContextId) {
    return { isRecognizing, isTrigger: false, clickHandler: stopPropagationAndDoNothing };
  }

  // user is not logged in, trigger the user trap
  if (!currentUserContextId) {
    return { isRecognizing, isTrigger: false, clickHandler: recognizeUserTrapClickHandler };
  }

  // user is logged in, run the recognition logic
  return { isRecognizing, isTrigger, clickHandler: updateRecognitionAndPossiblyResetListing };
};
export default useRecognizeButtonLogic;
