import { Skeleton, SkeletonProps } from '@material-ui/lab';
import useAsyncState from 'common/hooks/utils/useAsyncState';
import { HtmlImgProps } from 'common/utils/types';
import { useEffect } from 'react';
import useAppThemeColors from '../AppThemeProvider/useAppThemeColors';
import loadedImages from './loadedImages';
import { BasicImageTypePropsExtra } from './types';

export type SkeletonImageTypeProps = BasicImageTypePropsExtra<{
  skeletonProps?: SkeletonProps;
  imageProps?: HtmlImgProps;
  fallbackImageSrc?: string;
}>;
export type SkeletonImageType = React.FC<SkeletonImageTypeProps>;

export const useSkeletonImageLogic = (src?: string, fallbackSrc?: string): string | null | undefined => {
  const [loadedSrc, setLoadedSrc] = useAsyncState<string | null | undefined>(
    src && loadedImages.wasLoaded(src) ? src : undefined
  );

  useEffect(() => {
    if (!src || loadedSrc === src) {
      return undefined;
    }

    if (loadedImages.wasLoaded(src)) {
      setLoadedSrc(src);
      return undefined;
    }

    const img = new Image();
    img.onload = () => {
      loadedImages.markLoaded(src);
      setLoadedSrc(src);
    };
    img.onerror = () => {
      setLoadedSrc(fallbackSrc ?? null);
    };
    img.src = src;
    return () => {
      img.src = '';
    };
  }, [src, fallbackSrc, setLoadedSrc, loadedSrc]);

  return loadedSrc;
};

export const SkeletonImage: SkeletonImageType = ({
  src,
  onImgLoadEnd,
  skeletonProps = {
    variant: 'rect',
    style: { width: '100%', height: '100%' },
  },
  imageProps,
  onClick,
  fallbackImageSrc,
}) => {
  const loadedSrc = useSkeletonImageLogic(src, fallbackImageSrc);
  const appThemeColors = useAppThemeColors();

  useEffect(() => {
    if (!onImgLoadEnd || loadedSrc === undefined) {
      return;
    }
    onImgLoadEnd(loadedSrc);
  }, [onImgLoadEnd, loadedSrc]);

  return (
    <>
      {loadedSrc && (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
        <img
          {...imageProps}
          alt={loadedSrc ?? undefined}
          src={loadedSrc ?? undefined}
          onClick={() => {
            if (onClick) {
              onClick();
            }
          }}
          style={{
            objectFit: 'cover',
            maxWidth: '100%',
            maxHeight: '100%',
            width: '100%',
            height: '100%',
            cursor: onClick ? 'pointer' : '',
            ...(imageProps?.style ?? {}),
          }}
        />
      )}
      {!loadedSrc && (
        <Skeleton
          className={appThemeColors.invertWhenDark}
          variant="rect"
          animation="wave"
          {...skeletonProps}
          style={{
            width: '100%',
            height: '100%',
            ...(skeletonProps.style ?? {}),
            backgroundColor: 'rgba(0, 0, 0, 0.11)',
          }}
        />
      )}
    </>
  );
};
