import { TypeGuard } from 'common/utils/types';

export const listingDirections = ['BACKWARD', 'FORWARD'] as const;

export type ListingDirection = (typeof listingDirections)[number];
export const isListingDirection: TypeGuard<ListingDirection> = (
  possibleDirection: unknown
): possibleDirection is ListingDirection => {
  if (!possibleDirection) {
    return false;
  }
  return listingDirections.includes(possibleDirection as ListingDirection);
};
export const defaultListingDirection = listingDirections[1];

export type HasMoreInListingDirections = [boolean, boolean]; // [backward, forward]
export const defaultHasMoreInListingDirections: HasMoreInListingDirections = [false, false];
