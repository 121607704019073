import { SortState } from 'common/features/Filters/predefined/SortGroup/store/sort.types';
import { SortOptionsEnum } from 'common/features/VirtualList/VirtualList.config';

export const sorts = [SortOptionsEnum.MOST_RECENT] as const;
export type FavoritesModalSort = (typeof sorts)[0];
export const isFavoritesModalort = (v: string): v is FavoritesModalSort => sorts.includes(v as FavoritesModalSort);

// currently auto-applies on every change
// maybe todo: add flag to apply on demand
export type FavoritesModalFiltersState = SortState;
