import { ButtonProps } from '@material-ui/core';
import { Enum_Size_Enum } from 'common/schema/commonSchemaRemoteTypes';
import { DetailedHTMLProps, HTMLAttributes, ImgHTMLAttributes } from 'react';

export type TypeGuard<T> = (possibleValue: unknown) => possibleValue is T;

export type HtmlDivProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
export type HtmlImgProps = DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>;

export type FetchState = 'FETCH' | 'FETCHING' | 'FETCHED';

export type ArrayElement<ArrayType extends readonly unknown[]> = ArrayType extends readonly (infer ElementType)[]
  ? ElementType
  : never;

export enum ArtworkMeasureUnit {
  'Metric' = 'cm',
  'American' = 'inch',
  'Digital' = 'px',
}

export type MinimalButtonProps = Pick<ButtonProps, 'onClick' | 'disabled'> & { text?: string };

export type SizeProp = {
  size: Enum_Size_Enum;
};

export type SizeMap<T> = { [size in Enum_Size_Enum]: T };

export type NotNullish<T> = Exclude<T, null | undefined>;
export type ReallyRequired<T> = { [P in keyof T]-?: NotNullish<T[P]> };

export type ArtistProfileCardType = {
  context_id: number;
  profile_id: number;
  profile_avatar: number;
  profile_title: string;
  profile_handle: string;
  location_city: string;
  location_country: string;
  artworks?: ArtworkType[];
};

export type ArtworkType = {
  id: number;
  artist_context_id: number;
  thumbnail_metadata_id: number;
};
