import useAppSelector from 'common/hooks/useAppSelector';
import { useSelectedProfile } from 'common/hooks/useSelectedProfile';
import {
  useGetProvenanceByArtworkIdLazyQuery,
  useGetShowcaseDetailsByHandleAndSlugQuery,
} from 'common/schema/commonSchemaRemoteGraphqlQueries';
import React, { createContext, useContext, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useGetLastInquiryDate } from 'stacks/ProfileArtShowcase/hooks/useGetLastInquiryDate';
import { entityActions } from 'store/slices/entities';
import useTagsLibrary from 'stacks/Dashboard/common/ArtworkPublish/components/common/Storytelling/hooks/useTagsLibrary';
import { useUserPreferredLanguage } from 'common/hooks/useUserPreferredLanguage';
import useGetArtworksInCurrentSeries, {
  ArtworksInCurrentSeries,
  FetchMoreArtworksInCurrentSeriesType,
} from './hooks/useGetArtworksInCurrentSeries';
import useGetArtworksNotInCurrentSeries, {
  ArtworksNotInCurrentSeries,
  FetchMoreArtworksNotInCurrentSeriesType,
} from './hooks/useGetArtworksNotInCurrentSeries';
import useShowcaseDetailsCheapestPrints from './hooks/useShowcaseDetailsCheapestPrints';
import { showcaseDetailsMapper } from './utils/showcaseDetailsMapper';
import { defaultShowcaseDetails, ShowcaseDetailsType } from './utils/types';

type ShowcaseDetailsContextType = {
  isShowcaseDetailsLoading: boolean;
  lastInquiryDate: string | null;
  numArtworksNotInCurrentSeries: number;
  artworksNotInCurrentSeries: ArtworksNotInCurrentSeries;
  fetchArtworksNotInCurrentSeries?: FetchMoreArtworksNotInCurrentSeriesType;
  numArtworksInCurrentSeries: number;
  artworksInCurrentSeries: ArtworksInCurrentSeries;
  fetchArtworksInCurrentSeries?: FetchMoreArtworksInCurrentSeriesType;
  id: number;
} & ShowcaseDetailsType;

const defaultShowcaseDetailsContextValue: ShowcaseDetailsContextType = {
  isShowcaseDetailsLoading: false,
  lastInquiryDate: null,
  numArtworksNotInCurrentSeries: 0,
  artworksNotInCurrentSeries: [],
  fetchArtworksNotInCurrentSeries: undefined,
  numArtworksInCurrentSeries: 0,
  artworksInCurrentSeries: [],
  fetchArtworksInCurrentSeries: undefined,
  id: 0,
  ...defaultShowcaseDetails,
};

const ShowcaseDetailsContext = createContext<ShowcaseDetailsContextType>(defaultShowcaseDetailsContextValue);

export const useShowcaseDetailsContext = () => useContext(ShowcaseDetailsContext);

type ShowcaseDetailsProviderProps = {
  handle: string;
  slug: string;
};

const ShowcaseDetailsProvider: React.FC<ShowcaseDetailsProviderProps> = ({ handle, slug, children }) => {
  const cacheKey = `${handle}-${slug}`;
  const dispatch = useDispatch();
  const preferredLanguage = useUserPreferredLanguage({ shouldFallbackToNavigator: true });
  const cacheHit = useAppSelector(
    (store) => store.entities.showcaseCache.getShowcaseDetailsByHandleAndSlugQuery[cacheKey]
  );
  const { loading, data: networkData } = useGetShowcaseDetailsByHandleAndSlugQuery({
    variables: {
      handle,
      slug,
    },
    fetchPolicy: 'cache-first',
    skip: !!cacheHit,
  });

  useEffect(() => {
    if (!networkData) {
      return;
    }

    dispatch(
      entityActions.UPDATE_SHOWCASE_DETAILS_BY_HANDLE_AND_SLUG({
        key: cacheKey,
        value: networkData,
      })
    );
  }, [networkData, dispatch, cacheKey]);

  const data = cacheHit ?? networkData;
  const [getProvenanceData, { data: provenanceData }] = useGetProvenanceByArtworkIdLazyQuery({
    fetchPolicy: 'cache-first',
  });

  const { categorizedTags } = useTagsLibrary({
    skipProcessingCategorizedTags: false,
    skipProcessingTranslatedCategorizedTags: true,
  });

  const showcaseDetails = useMemo(
    () =>
      showcaseDetailsMapper(
        data?.Artwork?.[0],
        provenanceData?.getArtworkEventByArtworkId,
        preferredLanguage,
        categorizedTags
      ),
    [data?.Artwork, provenanceData?.getArtworkEventByArtworkId, preferredLanguage, categorizedTags]
  );

  useShowcaseDetailsCheapestPrints(showcaseDetails);

  useGetLastInquiryDate(useSelectedProfile()?.contextId, data?.Artwork?.[0]?.id);

  const lastInquiryDate = useAppSelector((store) => store.artShowcase.userLastInquiryDate);

  useEffect(() => {
    if (!data?.Artwork?.[0]?.id) {
      return;
    }
    getProvenanceData({ variables: { artwork_id: data.Artwork[0].id } });
  }, [data?.Artwork, getProvenanceData]);

  const {
    numArtworksNotInCurrentSeries,
    loading: artworksNotInCurrentSeriesLoading,
    artworksNotInCurrentSeries,
    fetchArtworksNotInCurrentSeries,
  } = useGetArtworksNotInCurrentSeries(
    data?.Artwork?.[0]?.artist_context_id ?? 0,
    data?.Artwork?.[0]?.id ?? 0,
    data?.Artwork?.[0]?.artwork_to_collections?.[0]?.Collection?.name ?? undefined
  );

  const {
    numArtworksInCurrentSeries,
    loading: artworksInCurrentSeriesLoading,
    artworksInCurrentSeries,
    fetchArtworksInCurrentSeries,
  } = useGetArtworksInCurrentSeries(
    data?.Artwork?.[0]?.artist_context_id ?? 0,
    data?.Artwork?.[0]?.id ?? 0,
    data?.Artwork?.[0]?.artwork_to_collections?.[0]?.Collection?.name ?? undefined
  );

  return (
    <>
      <ShowcaseDetailsContext.Provider
        value={{
          isShowcaseDetailsLoading:
            loading &&
            artworksNotInCurrentSeriesLoading &&
            artworksInCurrentSeriesLoading &&
            artworksNotInCurrentSeriesLoading &&
            artworksInCurrentSeriesLoading,
          lastInquiryDate,
          numArtworksNotInCurrentSeries,
          artworksNotInCurrentSeries,
          fetchArtworksNotInCurrentSeries,
          numArtworksInCurrentSeries,
          artworksInCurrentSeries,
          fetchArtworksInCurrentSeries,
          ...showcaseDetails,
          id: data?.Artwork?.[0]?.id ?? 0,
        }}
      >
        {children}
      </ShowcaseDetailsContext.Provider>
    </>
  );
};

export default ShowcaseDetailsProvider;
