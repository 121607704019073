import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AuthStateProps, AuthStatusEnum, DEFAULT_AUTH_STATUS } from 'common/services/Auth/handlers/AuthHandler/types';
import { FirebaseStateProps } from 'common/services/Auth/handlers/FirebaseHandler/types';
import { ProfileStateProps } from 'common/services/Auth/handlers/ProfileHandler/types';

type AuthSliceProps = Pick<AuthStateProps, 'status'> &
  Pick<ProfileStateProps, 'email'> &
  Pick<FirebaseStateProps, 'firebaseUserId' | 'firebaseUserEmail'> & { emailInput: string };

const initialState: AuthSliceProps = {
  status: DEFAULT_AUTH_STATUS,
  firebaseUserId: null,
  email: null,
  firebaseUserEmail: null,
  emailInput: '',
};
// This slice is used to store some states from the Auth Handlers, to be accessible from the rest of the app
const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    SET_EMAIL_INPUT: (state, action: PayloadAction<string>) => {
      state.emailInput = action.payload;
    },
    SET_AUTH_STATUS: (state, action: PayloadAction<AuthStatusEnum>) => {
      state.status = action.payload;
    },
    SET_FIREBASE_USER_ID: (state, action: PayloadAction<number | null>) => {
      state.firebaseUserId = action.payload;
    },
    SET_FIREBASE_USER_EMAIL: (state, action: PayloadAction<string | null>) => {
      state.firebaseUserEmail = action.payload;
    },
    SET_PROFILE_EMAIL: (state, action: PayloadAction<string | null>) => {
      state.email = action.payload;
    },
  },
});

export const authActions = authSlice.actions;
export default authSlice;
