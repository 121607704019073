import { Enum_Collection_Type_Enum } from 'common/schema/commonSchemaRemoteTypes';
import { ArtworksListType } from 'store/slices/artworkListModifiers';
import { CDNBaseUrl } from 'utils/constants';
import { LocalCommonModifiersRequiredFieldsFragment } from 'common/schema/commonSchemaRemoteOperationTypes';
import {
  AppliedListingModifier,
  ARTWORK_LOCAL_SORT,
  FILTERS_DEFAULT_VALUE,
  isOptionType,
  ListingModifier,
  ListingModifierButtonsConfig,
  ListingModifierOption,
} from './types';

export enum KnownOptions {
  SORT_ARTIST_SELECTION = 'filterStrings.sort.artistSelection',
  SORT_COLLECTOR_SELECTION = 'filterStrings.sort.collectionSelection',
  SORT_MOST_RECENT = 'filterStrings.sort.mostRecent',
  SORT_LEAST_RECENT = 'filterStrings.sort.leastRecent',
  SORT_LARGEST_SIZE = 'filterStrings.sort.largestSize',
  SORT_SMALLEST_SIZE = 'filterStrings.sort.smallestSize',
  SORT_MOST_EXPENSIVE = 'filterStrings.sort.mostExpensive',
  SORT_LEAST_EXPENSIVE = 'filterStrings.sort.leastExpensive',
}

const getCollectionById = (artwork: LocalCommonModifiersRequiredFieldsFragment, collectionId: number) =>
  artwork.artworkToCollections.filter((collection) => collection.collection_id === collectionId)[0];

const getCreatedCollection = (artwork: LocalCommonModifiersRequiredFieldsFragment) =>
  artwork.artworkToCollections.filter(
    (collection) => collection.Collection?.type === Enum_Collection_Type_Enum.Created
  )[0];

const getOwnedCollection = (artwork: LocalCommonModifiersRequiredFieldsFragment) =>
  artwork.artworkToCollections.filter(
    (collection) => collection.Collection?.type === Enum_Collection_Type_Enum.Owned
  )[0];

const getSize = (artwork: LocalCommonModifiersRequiredFieldsFragment) =>
  (artwork.details?.height || 1) * (artwork.details?.width || 1);

const getDateValue = (artwork: LocalCommonModifiersRequiredFieldsFragment): number =>
  new Date(artwork.publishDate || artwork.creationDate).getTime();

type SortVariant = ArtworksListType;
type SortVariantOptions = {
  [key in SortVariant]: (collectionId: number) => ListingModifierOption[];
};

const defaultSortOptions: ListingModifierOption[] = [
  {
    value: 1,
    name: KnownOptions.SORT_MOST_RECENT,
    key: 'ARTWORK_LOCAL_SORT',
    payload: (a, b) => getDateValue(b) - getDateValue(a),
  },
  {
    value: 2,
    name: KnownOptions.SORT_LEAST_RECENT,
    key: 'ARTWORK_LOCAL_SORT',
    payload: (a, b) => getDateValue(a) - getDateValue(b),
  },
  {
    value: 3,
    name: KnownOptions.SORT_LARGEST_SIZE,
    key: 'ARTWORK_LOCAL_SORT',
    payload: (a, b) => getSize(b) - getSize(a),
  },
  {
    value: 4,
    name: KnownOptions.SORT_SMALLEST_SIZE,
    key: 'ARTWORK_LOCAL_SORT',
    payload: (a, b) => getSize(a) - getSize(b),
  },
];

const variants: SortVariantOptions = {
  SHOWROOM: (collectionId: number) => [
    ...defaultSortOptions,
    {
      value: FILTERS_DEFAULT_VALUE,
      name: KnownOptions.SORT_ARTIST_SELECTION,
      key: 'ARTWORK_LOCAL_SORT',
      payload: (a, b) =>
        (getCollectionById(a, collectionId)?.order || 0) - (getCollectionById(b, collectionId)?.order || 0),
    },
    {
      value: 5,
      name: KnownOptions.SORT_MOST_EXPENSIVE,
      key: 'ARTWORK_LOCAL_SORT',
      payload: (a, b) =>
        (getCollectionById(b, collectionId)?.Artwork_to_showroom_detail?.artwork_showroom_price?.price || 0) -
        (getCollectionById(a, collectionId)?.Artwork_to_showroom_detail?.artwork_showroom_price?.price || 0),
    },
    {
      value: 6,
      name: KnownOptions.SORT_LEAST_EXPENSIVE,
      key: 'ARTWORK_LOCAL_SORT',
      payload: (a, b) =>
        (getCollectionById(a, collectionId)?.Artwork_to_showroom_detail?.artwork_showroom_price?.price || 0) -
        (getCollectionById(b, collectionId)?.Artwork_to_showroom_detail?.artwork_showroom_price?.price || 0),
    },
  ],
  ARTIST_PROFILE: () => [
    ...defaultSortOptions,
    {
      value: FILTERS_DEFAULT_VALUE,
      name: KnownOptions.SORT_ARTIST_SELECTION,
      key: 'ARTWORK_LOCAL_SORT',
      payload: (a, b) => (getCreatedCollection(a)?.order || 0) - (getCreatedCollection(b)?.order || 0),
    },
    {
      value: 5,
      name: KnownOptions.SORT_MOST_EXPENSIVE,
      key: 'ARTWORK_LOCAL_SORT',
      payload: (a, b) => (b.showcasePrice?.[0]?.price || 0) - (a.showcasePrice?.[0]?.price || 0),
    },
    {
      value: 6,
      name: KnownOptions.SORT_LEAST_EXPENSIVE,
      key: 'ARTWORK_LOCAL_SORT',
      payload: (a, b) => (a.showcasePrice?.[0]?.price || 0) - (b.showcasePrice?.[0]?.price || 0),
    },
  ],
  COLLECTOR_PROFILE: () => [
    ...defaultSortOptions,
    {
      value: FILTERS_DEFAULT_VALUE,
      name: KnownOptions.SORT_COLLECTOR_SELECTION,
      key: 'ARTWORK_LOCAL_SORT',
      payload: (a, b) => (getOwnedCollection(a)?.order || 0) - (getOwnedCollection(b)?.order || 0),
    },
    {
      value: 5,
      name: KnownOptions.SORT_MOST_EXPENSIVE,
      key: 'ARTWORK_LOCAL_SORT',
      payload: (a, b) => (b.showcasePrice?.[0]?.price || 0) - (a.showcasePrice?.[0]?.price || 0),
    },
    {
      value: 6,
      name: KnownOptions.SORT_LEAST_EXPENSIVE,
      key: 'ARTWORK_LOCAL_SORT',
      payload: (a, b) => (a.showcasePrice?.[0]?.price || 0) - (b.showcasePrice?.[0]?.price || 0),
    },
  ],
  FAVORITES: () => [
    // ...defaultSortOptions.map((opt) => {
    //   opt.value -= 1;
    //   return opt;
    // }),
    {
      value: FILTERS_DEFAULT_VALUE,
      name: KnownOptions.SORT_MOST_RECENT,
      key: 'ARTWORK_LOCAL_SORT',
      payload: (a, b) => getDateValue(b) - getDateValue(a),
    },
    {
      value: 1,
      name: KnownOptions.SORT_LEAST_RECENT,
      key: 'ARTWORK_LOCAL_SORT',
      payload: (a, b) => getDateValue(a) - getDateValue(b),
    },
    {
      value: 2,
      name: KnownOptions.SORT_LARGEST_SIZE,
      key: 'ARTWORK_LOCAL_SORT',
      payload: (a, b) => getSize(b) - getSize(a),
    },
    {
      value: 3,
      name: KnownOptions.SORT_SMALLEST_SIZE,
      key: 'ARTWORK_LOCAL_SORT',
      payload: (a, b) => getSize(a) - getSize(b),
    },
    {
      value: 4,
      name: KnownOptions.SORT_MOST_EXPENSIVE,
      key: 'ARTWORK_LOCAL_SORT',
      payload: (a, b) => (b.showcasePrice?.[0]?.price || 0) - (a.showcasePrice?.[0]?.price || 0),
    },
    {
      value: 5,
      name: KnownOptions.SORT_LEAST_EXPENSIVE,
      key: 'ARTWORK_LOCAL_SORT',
      payload: (a, b) => (a.showcasePrice?.[0]?.price || 0) - (b.showcasePrice?.[0]?.price || 0),
    },
  ],
};

export const getSorter = (collectionId = 0, variant?: SortVariant): ListingModifier => {
  const computedSorters: ListingModifier = {
    type: 'SORT',
    allOptions: [...(variant ? variants[variant](collectionId) : defaultSortOptions)],
  };
  computedSorters.allOptions.sort((a, b) => a.value - b.value);
  return computedSorters;
};

export const sortButtonsDefaultConfig: ListingModifierButtonsConfig = {
  [KnownOptions.SORT_ARTIST_SELECTION]: { specialWidth: 'FULL' },
  [KnownOptions.SORT_COLLECTOR_SELECTION]: { specialWidth: 'FULL' },
  [KnownOptions.SORT_MOST_RECENT]: {
    specialWidth: 'HALF',
    image: `${CDNBaseUrl}/static/images/showroom/most-recent.svg`,
  },
  [KnownOptions.SORT_LEAST_RECENT]: {
    specialWidth: 'HALF',
    image: `${CDNBaseUrl}/static/images/showroom/least-recent.svg`,
  },
  [KnownOptions.SORT_LARGEST_SIZE]: {
    specialWidth: 'HALF',
    image: `${CDNBaseUrl}/static/images/showroom/largest-size.svg`,
  },
  [KnownOptions.SORT_SMALLEST_SIZE]: {
    specialWidth: 'HALF',
    image: `${CDNBaseUrl}/static/images/showroom/smallest-size.svg`,
  },
  [KnownOptions.SORT_MOST_EXPENSIVE]: {
    specialWidth: 'HALF',
    image: `${CDNBaseUrl}/static/images/showroom/most-expensive.svg`,
  },
  [KnownOptions.SORT_LEAST_EXPENSIVE]: {
    specialWidth: 'HALF',
    image: `${CDNBaseUrl}/static/images/showroom/least-expensive.svg`,
  },
};
export const sortButtonsListTypeConfig: {
  [key in ArtworksListType]: ListingModifierButtonsConfig;
} = {
  SHOWROOM: { ...sortButtonsDefaultConfig },
  ARTIST_PROFILE: { ...sortButtonsDefaultConfig },
  COLLECTOR_PROFILE: { ...sortButtonsDefaultConfig },
  FAVORITES: { ...sortButtonsDefaultConfig },
};

export const getAppliedSort = (
  collectionId?: number,
  variant?: SortVariant
): AppliedListingModifier<ARTWORK_LOCAL_SORT> => ({
  ...getSorter(collectionId, variant),
  selectionType: 'SINGLE',
  selectedOptions: [FILTERS_DEFAULT_VALUE],
  flattenSelectedOptions: (options, selectedOptions) => {
    const flattenedOption = options.find((option) => selectedOptions.includes(option.value));
    if (!flattenedOption || !isOptionType<ARTWORK_LOCAL_SORT>(flattenedOption, 'ARTWORK_LOCAL_SORT')) {
      throw new Error('Wrong option type');
    }
    return flattenedOption;
  },
});
