import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GetExploreArtworkInfoQuery } from 'common/schema/commonSchemaRemoteOperationTypes';
import { NotNullish } from 'common/utils/types';

type GalleryChipInfo = NotNullish<GetExploreArtworkInfoQuery['galleries']>[0];
type CollectorChipInfo = NotNullish<GetExploreArtworkInfoQuery['collectors']>[0];

export type CollectedArtworkChipInfoEntities = {
  galleries: Record<number, GalleryChipInfo>;
  collectors: Record<number, CollectorChipInfo>;
};

const initialState: CollectedArtworkChipInfoEntities = {
  galleries: {},
  collectors: {},
};

export const collectedArtworkChipInfoSlice = createSlice({
  name: 'collectedArtworkChipInfoCache',
  initialState,
  reducers: {
    UPDATE_COLLECTED_ARTWORK_CHIPS: (
      state,
      action: PayloadAction<{
        galleries: GalleryChipInfo[];
        collectors: CollectorChipInfo[];
      }>
    ) => {
      action.payload.galleries.forEach((galleryChipInfo) => {
        if (!galleryChipInfo.contextId) {
          return;
        }
        state.galleries[galleryChipInfo.contextId] = galleryChipInfo;
      });
      action.payload.collectors.forEach((collectorChipInfo) => {
        if (!collectorChipInfo.contextId) {
          return;
        }
        state.collectors[collectorChipInfo.contextId] = collectorChipInfo;
      });
    },
  },
});
