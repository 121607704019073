import { useSelectedProfile } from 'common/hooks/useSelectedProfile';
import { useGetFavoritesLazyQuery } from 'common/schema/commonSchemaRemoteGraphqlQueries';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { GetFavoritesQuery } from '../../common/schema/commonSchemaRemoteOperationTypes';
import { favoritesModifiersActions } from '../../store/slices/favoritesSlice';

const CollectionToFavorites = (data: GetFavoritesQuery, contextId: number) => {
  const favorites = data.Collection[0].artwork_to_collections.map((art) => ({
    contextId,
    artworkId: art.artwork_id,
  }));
  return favorites;
};

export const FavoritesProvider = ({ children }: any) => {
  const contextId = useSelectedProfile()?.contextId;
  const dispatch = useDispatch();

  const [getFavorites] = useGetFavoritesLazyQuery();

  useEffect(() => {
    const retrieveFavorites = async () => {
      if (!contextId) {
        dispatch(favoritesModifiersActions.LOAD_FAVORITES([]));
        return;
      }
      const { data } = await getFavorites({ variables: { context_id: contextId } });
      if (!data?.Collection?.[0]?.artwork_to_collections?.length) {
        dispatch(favoritesModifiersActions.LOAD_FAVORITES([]));
        return;
      }
      dispatch(
        favoritesModifiersActions.LOAD_FAVORITES(CollectionToFavorites(data, data.Collection[0].owner_context_id))
      );
    };

    retrieveFavorites();
  }, [contextId]);

  return children;
};
