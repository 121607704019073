import { Marker_Type, Place } from 'common/schema/commonSchemaRemoteTypes';
import { MarkerData } from './interactiveMapTypes';

export function placeToMarkerData(place: Place): MarkerData {
  return {
    id: place.id,
    type: Marker_Type.Museum,
    address: place.location?.address || '',
    description: place.description || '',
    lat: place.geoCoordinates.latitude || 0,
    lng: place.geoCoordinates.longitude || 0,
    name: place.name,
    phone: place.tel || '',
    website: place.website || '',
    photo: place.photos?.[0],
    hours: place.hours,
  };
}
