import { createSlice } from '@reduxjs/toolkit';

export interface newsLetterModalState {
  isOpen: boolean;
}

const initialState: newsLetterModalState = {
  isOpen: false,
};

const newsLetterModalSlice = createSlice({
  name: 'newsLetterModal',
  initialState,
  reducers: {
    OPEN_NEWS_LETTER_MODAL: (state) => ({ ...state, isOpen: true }),
    CLOSE_NEWS_LETTER_MODAL: (state) => ({ ...state, isOpen: false }),
  },
});

export const newsLetterModalSliceActions = newsLetterModalSlice.actions;
export default newsLetterModalSlice;
