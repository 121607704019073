import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { defaultMinMaxPriceFilter } from './minMaxPrice.defaults';
import { MinMaxPriceFilterState } from './minMaxPrice.types';

type ToggleMinMaxPriceAction = PayloadAction<{
  value: number | null;
  field: 'min' | 'max';
}>;

export const createToggleMinMaxPriceCaseReducer =
  <State extends MinMaxPriceFilterState>(): CaseReducer<State, ToggleMinMaxPriceAction> =>
  (state, action) => {
    const { value, field } = action.payload ?? {};
    const { max, min } = state;

    if (value === null || value === defaultMinMaxPriceFilter[field] || value === -1) {
      state[field] = defaultMinMaxPriceFilter[field];
      return;
    }

    if (field === 'min') {
      if (!!max && value > max) {
        return;
      }

      state.min = value;
    }

    if (field === 'max') {
      if (!!min && value < min) {
        return;
      }

      state.max = value;
    }
  };
