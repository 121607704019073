import { LazyQueryResult, QueryLazyOptions } from '@apollo/client';
import {
  useGetArtworksNotInCurrentSeriesLazyQuery,
  useGetNumArtworksNotInCurrentSeriesQuery,
} from 'common/schema/commonSchemaRemoteGraphqlQueries';
import { GetArtworksNotInCurrentSeriesQuery } from 'common/schema/commonSchemaRemoteOperationTypes';
import { Exact, Maybe } from 'common/schema/commonSchemaRemoteTypes';
import { useEffect, useMemo, useState } from 'react';

export type FetchMoreArtworksNotInCurrentSeriesType = (
  options?:
    | QueryLazyOptions<
        Exact<{
          seriesTitle?: Maybe<string> | undefined;
          artistContextId: number;
          offset: number;
          limit: number;
          artworkId: number;
        }>
      >
    | undefined
) => Promise<
  LazyQueryResult<
    GetArtworksNotInCurrentSeriesQuery,
    Exact<{
      seriesTitle?: Maybe<string> | undefined;
      artistContextId: number;
      offset: number;
      limit: number;
      artworkId: number;
    }>
  >
>;

export type ArtworksNotInCurrentSeries = GetArtworksNotInCurrentSeriesQuery['Artwork'];

type GetArtworksNotInCurrentSeriesReturnType = {
  numArtworksNotInCurrentSeries: number;
  loading: boolean;
  artworksNotInCurrentSeries: ArtworksNotInCurrentSeries;
  fetchArtworksNotInCurrentSeries: FetchMoreArtworksNotInCurrentSeriesType;
};

const useGetArtworksNotInCurrentSeries = (
  artistContextId: number,
  artworkId: number,
  seriesTitle?: string
): GetArtworksNotInCurrentSeriesReturnType => {
  const [displayedArtworks, setDisplayedArtworks] = useState<ArtworksNotInCurrentSeries>([]);

  useEffect(() => {
    setDisplayedArtworks([]);
  }, [artistContextId]);

  const { data: numArtworksNotInCurrentSeriesData, loading: numArtworksNotInCurrentSeriesLoading } =
    useGetNumArtworksNotInCurrentSeriesQuery({
      variables: {
        seriesTitle: seriesTitle ?? '',
        artistContextId: artistContextId ?? 0,
        artworkId,
      },
      fetchPolicy: 'network-only',
      skip: !artistContextId,
    });

  const numArtworksNotInCurrentSeries = useMemo(() => {
    if (!numArtworksNotInCurrentSeriesData) {
      return 0;
    }

    return numArtworksNotInCurrentSeriesData?.Artwork?.length;
  }, [numArtworksNotInCurrentSeriesData]);

  const [fetchArtworks, { data: artworks, loading: artworksLoading }] = useGetArtworksNotInCurrentSeriesLazyQuery({
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    fetchArtworks({
      variables: {
        seriesTitle: seriesTitle ?? '',
        artistContextId,
        offset: 0,
        limit: 8,
        artworkId,
      },
    });
  }, [fetchArtworks, artistContextId, seriesTitle, artworkId]);

  useEffect(() => {
    if (!artworks?.Artwork) {
      return;
    }

    setDisplayedArtworks((prevArtworks) => prevArtworks.concat(artworks?.Artwork));
  }, [artworks?.Artwork]);

  return {
    numArtworksNotInCurrentSeries,
    loading: artworksLoading && numArtworksNotInCurrentSeriesLoading,
    artworksNotInCurrentSeries: displayedArtworks,
    fetchArtworksNotInCurrentSeries: fetchArtworks,
  };
};

export default useGetArtworksNotInCurrentSeries;
