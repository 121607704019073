import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GetExploreArtworkInfoQuery } from 'common/schema/commonSchemaRemoteOperationTypes';
import { NotNullish } from 'common/utils/types';

export type PrizeBadgeDataType = NotNullish<GetExploreArtworkInfoQuery['prizes']>[0];

type PrizeBadgeEntities = Record<string, PrizeBadgeDataType>;

const initialState: PrizeBadgeEntities = {};

export const prizeBadgesSlice = createSlice({
  name: 'prizeBadgesCache',
  initialState,
  reducers: {
    UPDATE_PRIZE_BADGES: (state, action: PayloadAction<PrizeBadgeDataType[]>) => {
      action.payload.forEach((prizeInfo) => {
        if (!prizeInfo.artworkPrizeIds) {
          return;
        }
        state[prizeInfo.artworkPrizeIds] = prizeInfo;
      });
    },
  },
});
