import { Artwork_Bool_Exp } from 'common/schema/commonSchemaRemoteTypes';
import {
  AppliedListingModifier,
  ARTWORK_QUERY_CONDITION_IN,
  FILTERS_DEFAULT_VALUE,
  isOptionType,
  ListingModifier,
  ListingModifierOption,
} from './types';

export type GalleriesOptionType = {
  id: number;
  name: string;
};

export const generateGalleriesFilterOption = ({ id, name }: GalleriesOptionType): ListingModifierOption => ({
  name,
  value: id,
  key: 'ARTWORK_QUERY_CONDITION_IN',
  payload: { artwork_to_collections: { Collection: { owner_context_id: { _in: [id] } } } },
});

export const getGalleriesFilter = (): ListingModifier => ({
  type: 'GALLERIES',
  allOptions: [
    {
      value: FILTERS_DEFAULT_VALUE,
      name: 'filterStrings.gallery.all',
      key: 'ARTWORK_QUERY_CONDITION_IN',
      payload: {},
    },
  ],
});

export const getAppliedGalleriesFilter = (
  galleriesOptions: GalleriesOptionType[]
): AppliedListingModifier<ARTWORK_QUERY_CONDITION_IN> => {
  const galleriesFilter = getGalleriesFilter();
  const newGalleriesFilterOptions = galleriesOptions.map(generateGalleriesFilterOption);
  galleriesFilter.allOptions.push(...newGalleriesFilterOptions);
  return {
    ...galleriesFilter,
    selectionType: 'MULTIPLE',
    selectedOptions: [FILTERS_DEFAULT_VALUE],
    flattenSelectedOptions: (options, selectedOptions) => {
      const selectedModifiers = options.filter((option) => selectedOptions.includes(option.value));
      const flattenedModifiers = selectedModifiers.reduce<Artwork_Bool_Exp>((flattenedOptions, currentOption) => {
        if (
          isOptionType<ARTWORK_QUERY_CONDITION_IN>(currentOption, 'ARTWORK_QUERY_CONDITION_IN') &&
          currentOption.payload.artwork_to_collections?.Collection?.owner_context_id?._in
        ) {
          return {
            artwork_to_collections: {
              Collection: {
                owner_context_id: {
                  _in: [
                    ...(flattenedOptions.artwork_to_collections?.Collection?.owner_context_id?._in ?? []),
                    ...currentOption.payload.artwork_to_collections?.Collection?.owner_context_id?._in,
                  ],
                },
              },
            },
          };
        }
        return flattenedOptions;
      }, {});

      return {
        key: 'ARTWORK_QUERY_CONDITION_IN',
        name: 'APPLIED_GALLERIES_FILTER',
        value: -1,
        payload: flattenedModifiers,
      };
    },
  };
};
