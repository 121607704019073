import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
  },
  multilineRoot: {
    '& > div': {
      paddingTop: theme.spacing(1),
      paddingRight: theme.spacing(0),
      paddingBottom: theme.spacing(2.5),
      paddingLeft: theme.spacing(1.5),
      '& textarea': {
        paddingRight: theme.spacing(0.75),
      },
    },
  },
  multiline: {
    fontSize: 14,
    color: '#000000',
    display: 'flex',
    alignItems: 'baseline',
    whiteSpace: 'pre-wrap',
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },

    '& .MuiFormHelperText-root.MuiFormHelperText-contained.Mui-error': {
      position: 'absolute',
      top: '100%',
    },

    minHeight: `${theme.spacing(17.5)}px`,
  },
  count: {
    fontSize: '12px',
    lineHeight: '16px',
    position: 'absolute',
    color: 'rgba(0, 0, 0, 0.50)',
    right: theme.spacing(0.5),
    bottom: theme.spacing(0.5),
  },
}));

export default useStyles;
