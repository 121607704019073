import React, { useCallback, useEffect, useMemo, useState } from 'react';
import useAppSelector from 'common/hooks/useAppSelector';
import { useDispatch } from 'react-redux';
import { userTrapActions } from 'store/slices/userTrapSlice';
import { Enum_Pseudo_User_Action_Enum } from 'common/schema/commonSchemaRemoteTypes';
import { Button, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import AvailabilityDot from 'common/components/AvailabilityDot/AvailabilityDot';
import { useArtworkExternalBuyLink } from 'common/hooks/useArtworkExternalBuyLink';
import useBuyModalActions from 'common/components/BuyModal/hooks/useBuyModalActions';
import { AVAILABLE_CRYPTOCURRENCIES } from 'common/utils/constants';
import { useSelectedProfile } from 'common/hooks/useSelectedProfile';
import { useSnackbar } from 'common/contexts/ToastProvider';
import { SwipeContainerComponentsProps } from '.';
import { useSwipeContainerComponentsStyles } from './useSwipeContainerComponents.styles';

const ONE_WEEK_MILLIS = 7 * 24 * 60 * 60 * 1000;

const SwipeContainerInquiryButton: React.FC<SwipeContainerComponentsProps> = ({ artworkId }) => {
  const classes = useSwipeContainerComponentsStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { artistContextId, inquires, prices, artworkDetails } =
    useAppSelector((store) => store.entities.artworkSwipeContainerCache[artworkId]) ?? {};
  const { openArtworkBuyLink } = useArtworkExternalBuyLink(artworkId);
  const { openBuyModal, shouldShowBuyModal } = useBuyModalActions();
  const selectedProfile = useSelectedProfile();
  const snackbar = useSnackbar();

  useEffect(() => {
    if (!artworkId) {
      return;
    }

    dispatch(
      userTrapActions.SET_USER_TRAP_DATA({
        data: {
          artworkId,
          artistContextId,
        },
      })
    );
  }, [artworkId, dispatch, artistContextId]);

  const [isInquiredThisSession, setIsInquiredThisSession] = useState(false);

  const onClick = useCallback(async () => {
    if (await openArtworkBuyLink()) {
      return;
    }

    if (selectedProfile?.contextId === artistContextId) {
      snackbar.showErrorSnackbar(t('profileArtShowcaseStrings.errors.cantBuyYourOwnArt'));
      return;
    }

    setIsInquiredThisSession(true);

    if (
      shouldShowBuyModal &&
      !AVAILABLE_CRYPTOCURRENCIES.join('').includes(prices?.[0]?.currency ?? '') &&
      artworkDetails?.artType !== 'Edition' &&
      !!selectedProfile &&
      prices?.[0]?.isPublic
    ) {
      openBuyModal(artworkId);
      return;
    }

    dispatch(
      userTrapActions.SET_USER_TRAP_DATA({
        data: { availability: 'ARTWORK' },
      })
    );
    dispatch(userTrapActions.OPEN(Enum_Pseudo_User_Action_Enum.Inquire));
  }, [dispatch, prices?.[0], artworkDetails, artistContextId, selectedProfile]);

  const isLastInquireWithinWeek =
    useMemo(
      () => !!inquires.find((inquire) => Date.now() - new Date(inquire.inquiredAt).getTime() < ONE_WEEK_MILLIS),
      [inquires]
    ) || isInquiredThisSession;

  return (
    <Button
      variant="contained"
      color="primary"
      onClick={onClick}
      className={classes.inquiryButton}
      disabled={isLastInquireWithinWeek}
    >
      <AvailabilityDot availability="AVAILABLE" />
      <Typography variant="h5">{t('common.buyArtwork')}</Typography>
    </Button>
  );
};

export default SwipeContainerInquiryButton;
