import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { SeriesFilterState } from './series.types';
import { SERIES_ALL_OPTION, getDefaultSeriesFilter } from './series.defaults';

type ToggleSeriesAction = PayloadAction<{
  seriesValue: string;
}>;
export const createToggleSeriesCaseReducer =
  <State extends SeriesFilterState>(): CaseReducer<State, ToggleSeriesAction> =>
  (state, action) => {
    const { seriesValue } = action.payload ?? {};

    if (seriesValue === SERIES_ALL_OPTION && !!state.series[seriesValue]) {
      return;
    }

    if (seriesValue === SERIES_ALL_OPTION) {
      state.series = getDefaultSeriesFilter().series;
      return;
    }

    state.series[seriesValue] = !state.series[seriesValue];
    state.series[SERIES_ALL_OPTION] = !Object.values(state.series).includes(true);
  };
