import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface dashboardSidebarSliceState {
  isMenuCollapsed: boolean;
}

const initialState: dashboardSidebarSliceState = {
  isMenuCollapsed: false,
};

const dashboardSidebarSlice = createSlice({
  name: 'dashboardSidebar',
  initialState,
  reducers: {
    SET_COLLAPSED_MENU: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isMenuCollapsed: action.payload,
    }),
  },
});

export const dashboardSidebarActions = dashboardSidebarSlice.actions;
export default dashboardSidebarSlice;
