import gql from 'graphql-tag';

export const CREATE_ARTWORK = gql`
  mutation CreateArtwork($artist_context_id: Int!, $title: String! = "", $gallery_context_id: Int = null) {
    artistCreateArtwork(artist_context_id: $artist_context_id, title: $title, gallery_context_id: $gallery_context_id) {
      artwork_details_id
      artwork_story_id
      id
    }
  }
`;

export const GALLERY_CREATE_ARTWORK = gql`
  mutation GalleryCreateArtwork($artist_context_id: Int!, $title: String! = "", $gallery_context_id: Int!) {
    galleryCreateArtwork(
      artist_context_id: $artist_context_id
      title: $title
      gallery_context_id: $gallery_context_id
    ) {
      artwork_details_id
      artwork_story_id
      id
    }
  }
`;

export const UPDATE_ARTWORK = gql`
  mutation UpdateArtwork($id: bigint!, $slug: String = "") {
    update_Artwork_by_pk(pk_columns: { id: $id }, _set: { slug: $slug }) {
      id
      slug
    }
  }
`;

export const UPDATE_ARTWORK_SECONDARY_MEDIA_ORDER = gql`
  mutation UpdateArtworkSecondaryMediaOrder($id: bigint!, $order: Int!) {
    update_Artwork_secondary_media_by_pk(pk_columns: { id: $id }, _set: { order: $order }) {
      id
      order
    }
  }
`;

export const UPDATE_ARTWORK_ART_DETAILS = gql`
  mutation UpdateArtworkArtDetails(
    $id: bigint!
    $title: String = ""
    $creation_date_day: Int = 10
    $creation_date_month: Int = 10
    $creation_date_year: Int = 10
    $depth: float8 = 10
    $height: float8 = 10
    $width: float8 = 10
    $measuring_unit: String = ""
    $materials_and_medium: String = ""
    $art_type: String = ""
    $tools: String = ""
    $duration: Int = 10
    $is_dark_mode: Boolean = false
  ) {
    update_Artwork_details_by_pk(
      pk_columns: { id: $id }
      _set: {
        title: $title
        creation_date_day: $creation_date_day
        creation_date_month: $creation_date_month
        creation_date_year: $creation_date_year
        depth: $depth
        height: $height
        width: $width
        measuring_unit: $measuring_unit
        materials_and_medium: $materials_and_medium
        art_type: $art_type
        tools: $tools
        duration: $duration
        is_dark_mode: $is_dark_mode
      }
    ) {
      creation_date_day
      creation_date_month
      creation_date_year
      depth
      duration
      height
      id
      materials_and_medium
      measuring_unit
      title
      tools
      width
      art_type
      is_dark_mode
      artwork {
        artwork_story_id
        artwork_details_id
        slug
      }
    }
  }
`;

export const ADD_ARTWORK_IMAGERY_PRIMARY_PHOTO = gql`
  mutation AddArtworkImageryPrimaryPhoto($artwork_id: bigint!, $file: Upload = "") {
    artworkPrimaryImageUpload(artwork_id: $artwork_id, file: $file) {
      url
    }
  }
`;
export const ADD_ARTWORK_IMAGERY_ORIGINAL_PHOTO = gql`
  mutation AddArtworkImageryOriginalPhoto($artwork_id: bigint!, $file: Upload = "", $processAITags: Boolean = false) {
    artworkOriginalImageUpload(artwork_id: $artwork_id, file: $file, processAITags: $processAITags) {
      url
    }
  }
`;
export const ADD_ARTWORK_IMAGERY_THUMBNAIL_PHOTO = gql`
  mutation AddArtworkImageryThumbnailPhoto($artwork_id: bigint!, $file: Upload = "") {
    artworkThumbnailUpload(artwork_id: $artwork_id, file: $file) {
      url
    }
  }
`;
export const ADD_ARTWORK_IMAGER_SECONDARY_PHOTO = gql`
  mutation AddArtworkImagerySecondaryPhoto($artwork_id: bigint!, $file: Upload!, $order: Int!, $edition_id: bigint) {
    artworkSecondaryImageUpload(artwork_id: $artwork_id, file: $file, order: $order, edition_id: $edition_id) {
      url
      file_metadata_id
      parent_id
    }
  }
`;

export const ADD_ARTWORK_LOCATION_MAP = gql`
  mutation addArtworkLocationMapImage($artwork_details_id: bigint!, $file: Upload = "") {
    artworkLocationMapUpload(artwork_details_id: $artwork_details_id, file: $file) {
      url
    }
  }
`;

export const UPDATE_ARTWORK_STORY_STATEMENT = gql`
  mutation UpdateArtworkStoryStatement($statement: String = "", $id: bigint!) {
    update_Artwork_story_by_pk(pk_columns: { id: $id }, _set: { statement: $statement }) {
      id
    }
  }
`;
export const UPDATE_ARTWORK_STORY_MEDIA_ORDER = gql`
  mutation UpdateArtworkStoryMediaOrder($id: bigint!, $order: Int!) {
    update_Artwork_story_media_by_pk(pk_columns: { id: $id }, _set: { order: $order }) {
      id
      order
    }
  }
`;
export const ADD_ARTWORK_STORY_IMAGE = gql`
  mutation AddArtworkStoryImage($artwork_id: bigint!, $file: Upload = "", $order: Int!) {
    artworkStoryImageUpload(artwork_id: $artwork_id, file: $file, order: $order) {
      url
      parent_id
    }
  }
`;

export const ADD_ARTWORK_STORY_AUDIO = gql`
  mutation AddArtworkStoryAudio($artwork_id: bigint!, $file: Upload = "") {
    artworkStoryAudioUpload(artwork_id: $artwork_id, file: $file) {
      url
    }
  }
`;

/** @deprecated */
export const GET_ARTWORK_STORY_VIDEO_URL = gql`
  query getVideoUrl($id: bigint!) {
    Artwork_story_video_links(where: { artwork_story_id: { _eq: $id } }) {
      id
      platform
      URL
    }
  }
`;

export const UPDATE_ARTWORK_STORY_VIDEO_URL = gql`
  mutation updateVideoUrl($id: bigint!, $objects: [Artwork_story_video_links_insert_input!]!) {
    update_Artwork_story_video_links(
      where: { artwork_story_id: { _eq: $id }, deleted_at: { _is_null: true } }
      _set: { deleted_at: "now()" }
    ) {
      affected_rows
    }
    insert_Artwork_story_video_links(objects: $objects) {
      returning {
        id
        platform
        URL
      }
    }
  }
`;

export const DELETE_ARTWORK_STORY_VIDEO_URL = gql`
  mutation deleteVideoUrl($id: bigint!) {
    update_Artwork_story_video_links(
      where: { artwork_story_id: { _eq: $id }, deleted_at: { _is_null: true } }
      _set: { deleted_at: "now()" }
    ) {
      affected_rows
      returning {
        id
        platform
        URL
      }
    }
  }
`;

export const GET_ALL_ARTWORKS = gql`
  query GetAllPublicArtworks($offset: Int!) {
    Artwork(
      where: { primary_image_metadata_id: { _is_null: false }, is_public: { _eq: true } }
      limit: 10
      offset: $offset
    ) {
      id
      slug
      primary_image_metadata_id
      thumbnail_metadata_id
      artwork_details {
        title
      }
      artist_context {
        profile {
          title
          handle
          first_name
          last_name
        }
      }
    }
  }
`;

export const TOTAL_NUMBER_OF_PUBLIC_ARTWORKS = gql`
  query GetTotalNumberOfPublicArtworks {
    aggregateProxy(type: TotalNumberOfPublicArtworks)
  }
`;

export const GET_ALL_ARTWORKS_NON_LAZY = gql`
  query GetAllPublicArtworksNonLazy {
    Artwork(where: { primary_image_metadata_id: { _is_null: false }, is_public: { _eq: true } }) {
      id
      slug
      primary_image_metadata_id
      thumbnail_metadata_id
      artwork_details {
        title
      }
      artist_context {
        profile {
          title
          handle
          first_name
          last_name
        }
      }
    }
  }
`;

export const CREATE_ARTWORK_SERIES = gql`
  mutation CreateArtworkSeries(
    $description: String = ""
    $name: String!
    $owner_context_id: Int!
    $partner_context_id: Int
  ) {
    createCollection(
      description: $description
      name: $name
      owner_context_id: $owner_context_id
      partner_context_id: $partner_context_id
      type: "SERIES"
    )
  }
`;

export const ADD_ARTWORK_TO_SERIES = gql`
  mutation AddArtworkToSeries($artwork_id: bigint!, $collection_id: bigint!) {
    insert_Artwork_to_Collection_one(
      object: { artwork_id: $artwork_id, collection_id: $collection_id }
      on_conflict: { constraint: Artwork_to_Collection_artwork_id_collection_id_key, update_columns: collection_id }
    ) {
      id
    }
  }
`;

export const GET_ALL_SERIES = gql`
  query GetAllSeries($owner_id: bigint!) {
    Collection(
      where: {
        owner_context_id: { _eq: $owner_id }
        type: { _eq: SERIES }
        artwork_to_collections: { Artwork: { is_public: { _eq: true } } }
      }
    ) {
      name
      id
      description
      collection_audio_download_link
      audio_metadata {
        extra
      }
    }
  }
`;

export const GET_GALLERY_SERIES = gql`
  query getGAllerySeries($gallery_id: bigint!, $artist_id: bigint!) {
    Collection(
      where: {
        type: { _eq: SERIES }
        owner_context: {
          type: { _eq: PARTNERSHIP }
          _and: [
            { granted_access: { role: { _eq: OWNER }, receiver_id: { _eq: $gallery_id } } }
            { granted_access: { role: { _eq: OWNER }, receiver_id: { _eq: $artist_id } } }
          ]
        }
      }
    ) {
      name
      id
      description
      collection_audio_download_link
      audio_metadata {
        extra
      }
    }
  }
`;

/** @deprecated */
export const GET_SERIES_VIDEO_URL = gql`
  query getVideoUrl($id: bigint!) {
    Collection_video_links(where: { collection_id: { _eq: $id } }) {
      platform
      URL
    }
  }
`;

export const UPDATE_SERIES_STORY_VIDEO_URL = gql`
  mutation updateVideoUrl($id: bigint!, $objects: [Collection_video_links_insert_input!]!) {
    update_Collection_video_links(
      where: { collection_id: { _eq: $id }, deleted_at: { _is_null: true } }
      _set: { deleted_at: "now()" }
    ) {
      affected_rows
    }
    insert_Collection_video_links(objects: $objects) {
      returning {
        platform
        URL
      }
    }
  }
`;

export const DELETE_SERIES_STORY_VIDEO_URL = gql`
  mutation deleteVideoUrl($id: bigint!) {
    update_Collection_video_links(
      where: { collection_id: { _eq: $id }, deleted_at: { _is_null: true } }
      _set: { deleted_at: "now()" }
    ) {
      affected_rows
      returning {
        platform
        URL
      }
    }
  }
`;

export const UPDATE_SERIES_BY_ID = gql`
  mutation UpdateSeriesById($collection_id: bigint!, $name: String!, $description: String = "") {
    update_Collection(where: { id: { _eq: $collection_id } }, _set: { name: $name, description: $description }) {
      affected_rows
    }
  }
`;

export const GET_COLLECTION_DESCRIPTION = gql`
  query GetCollectionDescriptionById($id: bigint!) {
    Collection_by_pk(id: $id) {
      description
    }
  }
`;

export const GET_COLLECTIONS = gql`
  query GetCollectionByUserAndType($collectionType: ENUM_collection_type_enum = SERIES, $ownerContextId: bigint!) {
    Collection(where: { type: { _eq: $collectionType }, owner_context_id: { _eq: $ownerContextId } }) {
      id
      name
      collection_audio_download_link
      description
      collection_video_links(where: { deleted_at: { _is_null: true } }) {
        URL
        platform
        id
      }
    }
  }
`;

export const PUBLISH_ARTWORK = gql`
  mutation UpdateArtworkByPK($id: bigint!) {
    update_Artwork_by_pk(pk_columns: { id: $id }, _set: { is_public: true }) {
      id
    }
  }
`;

export const GET_COLLABORATORS_FOR_GALLERY = gql`
  query GetCollaboratorsFforGallery($id: bigint!) {
    Collaboration(where: { gallery_id: { _eq: $id } }) {
      id
      artist_context {
        id
        profile {
          title
          handle
        }
      }
    }
  }
`;

export const GET_COLLABORATORS_FOR_ARTIST = gql`
  query GetCollaboratorsForArtist($id: bigint!) {
    Collaboration(where: { artist_id: { _eq: $id } }) {
      id
      gallery_context {
        id
        profile {
          title
          handle
        }
      }
    }
  }
`;

export const DELETE_ARTWORK_SECONDARY_IMAGE = gql`
  mutation DeleteSecondaryImage($artworkSecondaryMediaId: bigint!) {
    artworkSecondaryImageDelete(artwork_secondary_media_id: $artworkSecondaryMediaId)
  }
`;

export const ADD_COLLLECTION_AUDIO = gql`
  mutation AddCollectionAudio($collectionId: bigint!, $file: Upload!) {
    collectionAudioUpload(collection_id: $collectionId, file: $file) {
      url
      parent_id
    }
  }
`;

export const ADD_COLLECTION_VIDEO = gql`
  mutation AddCollectionVideoLink($URL: String = "", $collection_id: bigint!, $platform: String!) {
    insert_Collection_video_links_one(object: { URL: $URL, collection_id: $collection_id, platform: $platform }) {
      id
    }
  }
`;

export const DELETE_COLLECTION_VIDEO_LINKS = gql`
  mutation DeleteCollectionVideoLinks($deleted_at: timestamptz!, $collection_id: bigint!) {
    update_Collection_video_links(
      where: { deleted_at: { _is_null: true }, collection_id: { _eq: $collection_id } }
      _set: { deleted_at: $deleted_at }
    ) {
      returning {
        id
      }
      affected_rows
    }
  }
`;

export const SET_ARTWORK_SERIES = gql`
  mutation SET_SERIES_FOR_ARTWORK($collection_id: Int!, $context_id: Int!, $artwork_id: Int!) {
    setSeriesForArtwork(collection_id: $collection_id, context_id: $context_id, artwork_id: $artwork_id)
  }
`;

export const DELETE_SERIES = gql`
  mutation DELETE_SERIES($collection_id: Int!) {
    deleteSeries(collection_id: $collection_id)
  }
`;

export const DELETE_ARTWORK_STORY_IMAGE = gql`
  mutation DeleteArtworkStoryImage($artwork_story_media_id: bigint!) {
    artworkStoryImageDelete(artwork_story_media_id: $artwork_story_media_id)
  }
`;

export const DELETE_FEATURED_ART_IMAGE = gql`
  mutation DeleteProfileFeaturedArtImage($profile_id: bigint!) {
    profileFeaturedArtDelete(profile_id: $profile_id)
  }
`;

export const COPY_AUDIO_FROM_SERIES = gql`
  mutation CopyAudioFromSeriesToStory($artwork_id: bigint!) {
    copyAudioFromSeriesToStory(artwork_id: $artwork_id)
  }
`;

export const GET_ARTWORK_IMAGERY = gql`
  query GetArtworkImagery($id: bigint!) {
    Artwork_by_pk(id: $id) {
      id
      primary_image_metadata_id
      thumbnail_metadata_id
      artwork_secondary_media {
        id
        order
        file_metadata_id
      }
      artwork_story {
        artwork_story_media {
          id
          order
          file_metadata_id
        }
      }
      artwork_details {
        is_dark_mode
      }
    }
  }
`;

export const GET_ARTWORK_AUDIO = gql`
  query GetArtworkAudioById($id: bigint!) {
    Artwork_by_pk(id: $id) {
      artwork_story {
        story_audio_download_link
        audio_metadata {
          file_name
          extra
        }
      }
    }
  }
`;

export const GET_ARTWORK_SLUG = gql`
  query GetArtworkSlug($id: bigint!) {
    Artwork_by_pk(id: $id) {
      slug
    }
  }
`;

export const DELETE_ARTWORK_FROM_SERIES = gql`
  mutation DeleteArtworkFromSeries($_eq: bigint!, $_eq1: bigint!) {
    delete_Artwork_to_Collection(where: { Artwork: { id: { _eq: $_eq } }, Collection: { id: { _eq: $_eq1 } } }) {
      affected_rows
    }
  }
`;

export const ADD_COMMERCIAL_FOR_ARTWORK = gql`
  mutation AddComercialForArtwork(
    $currency: String
    $is_public: Boolean = false
    $price: float8
    $artwork_id: bigint!
    $is_available_sale: Boolean = false
  ) {
    insert_Artwork_prices_one(
      object: { currency: $currency, is_public: $is_public, price: $price, artwork_id: $artwork_id }
      on_conflict: { constraint: Artwork_prices_artwork_id_currency_key, update_columns: price }
    ) {
      price
      id
      currency
      artwork_id
      is_public
    }
    update_Artwork_by_pk(pk_columns: { id: $artwork_id }, _set: { is_available_sale: $is_available_sale }) {
      is_available_sale
    }
  }
`;

export const ARTIST_SET_PARTNER_ON_ARTWORK = gql`
  mutation ARTIST_SET_PARTNER_ON_ARTWORK($artwork_id: Int!, $partner_context_id: Int!, $artist_context_id: Int!) {
    artistSetPartnerOnArtwork(
      artwork_id: $artwork_id
      partner_context_id: $partner_context_id
      artist_context_id: $artist_context_id
    )
  }
`;

export const ARTIST_REMOVE_PARTNER_ON_ARTWORK = gql`
  mutation ARTIST_REMOVE_PARTNER_ON_ARTWORK($artwork_id: Int!) {
    artistRemovePartnerOnArtwork(artwork_id: $artwork_id)
  }
`;

export const GET_ACTIVE_COLLABORATORS_FOR_ARTIST = gql`
  query GET_ACTIVE_COLLABORATORS_FOR_ARTIST($artist_id: bigint!) {
    Collaboration(
      where: { artist_id: { _eq: $artist_id }, gallery_status: { _eq: ACCEPTED }, artist_status: { _eq: ACCEPTED } }
    ) {
      id
      gallery_context {
        id
        profile {
          title
        }
      }
    }
  }
`;

export const GET_ARTWORK_DATA_FOR_REVIEW = gql`
  query GetArtworkDataForReview($id: bigint!) {
    Artwork_by_pk(id: $id) {
      artwork_details {
        art_type
        depth
        duration
        height
        is_dark_mode
        materials_and_medium
        measuring_unit
        materials
        medium
        title
        width
      }
      artwork_secondary_media {
        id
        order
        file_metadata_id
      }
      artwork_story {
        artwork_story_media {
          id
          order
          file_metadata_id
        }
        id
        statement
        story_audio_download_link
      }
      artwork_to_collections(where: { Collection: { type: { _eq: SERIES } } }) {
        Collection {
          collection_video_links {
            id
            URL
            platform
          }
        }
      }
    }
  }
`;

export const GET_CONGRATS_ARTWORK_DATA = gql`
  query GetArtworkCongratsData($id: bigint!) {
    Artwork_by_pk(id: $id) {
      id
      slug
      primary_image_metadata_id
      artwork_details {
        title
      }
    }
  }
`;

export const UPDATE_ARTWORK_AVAILABLE_FOR_SALE = gql`
  mutation UpdateArtworkAailableForSale($id: bigint!, $is_available_sale: Boolean = false) {
    update_Artwork_by_pk(pk_columns: { id: $id }, _set: { is_available_sale: $is_available_sale }) {
      id
    }
  }
`;

export const UPDATE_ARTWORK_PRICE_BY_ID = gql`
  mutation UpdateArtworkPriceById(
    $id: bigint!
    $currency: String = ""
    $price: float8 = 0
    $is_public: Boolean = false
  ) {
    update_Artwork_prices_by_pk(
      pk_columns: { id: $id }
      _set: { currency: $currency, price: $price, is_public: $is_public }
    ) {
      artwork_id
    }
  }
`;

export const INSERT_ARTWORK_PRICE = gql`
  mutation InsertArtworkPrice(
    $currency: String = ""
    $artwork_id: bigint!
    $is_public: Boolean = false
    $price: float8 = ""
  ) {
    insert_Artwork_prices_one(
      object: { artwork_id: $artwork_id, currency: $currency, is_public: $is_public, price: $price }
    ) {
      artwork_id
    }
  }
`;
