import { TypographyTextType } from 'common/components/TypographyText/SkeletonText';
import { getCountryFullName } from 'common/hooks/useCountries';
import { Maybe } from 'common/schema/commonSchemaRemoteTypes';

export const getArtistTitleText = (artistTitle?: Maybe<string>): TypographyTextType => ({
  text: artistTitle ?? undefined,
});

export const getArtistLocationText = (city?: Maybe<string>, country?: Maybe<string>): TypographyTextType => ({
  text:
    country === undefined && city === undefined
      ? undefined
      : [city, getCountryFullName(country ?? '')].filter((text) => !!text).join(', '),
});

const typographyTextTypeHooks = {
  getArtistTitleText,
  getArtistLocationText,
};
export default typographyTextTypeHooks;
