import { TextField, TextFieldProps, Typography } from '@material-ui/core';
import { logger } from '@old/services/logger';
import clsx from 'clsx';
import { useMemo } from 'react';
import { formatNumberToLocale } from 'utils/utilFunctions';
import { useTranslation } from 'react-i18next';
import { isNumber } from 'lodash';
import useStyles from './MultilineInput.styles';

const AVGWORDSIZE = 5;

type IProps = TextFieldProps & { maxLength: number; wordCount?: number };

const MultilineInput: React.FC<IProps> = (props) => {
  const classes = useStyles();
  const { value, maxLength, className, wordCount } = props;
  const { t } = useTranslation();

  const countValue = useMemo(() => {
    let count = 0;
    if (value) {
      try {
        count = String(value).length;
      } catch (error) {
        if (error instanceof Error) {
          logger.error(error);
        }
      }
    }
    return `${formatNumberToLocale(count)} / ${formatNumberToLocale(maxLength)}`;
  }, [value, maxLength]);

  return (
    <div className={classes.container}>
      <TextField
        fullWidth
        className={clsx(classes.multiline, className)}
        variant="outlined"
        multiline
        minRows={6}
        maxRows={16}
        inputProps={{
          maxLength: !wordCount ? maxLength : Math.max(AVGWORDSIZE * wordCount, 1000),
        }}
        {...props}
        classes={{
          root: classes.multilineRoot,
        }}
      />
      <Typography variant="h6" className={classes.count}>
        {isNumber(wordCount) && wordCount >= 0
          ? `${formatNumberToLocale(wordCount)} ${t('multineInput.words')}`
          : countValue}
      </Typography>
    </div>
  );
};

export default MultilineInput;
