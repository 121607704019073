import { ProfileType } from 'common/hooks/useSelectedProfile';
import { Enum_Collection_Type_Enum } from 'common/schema/commonSchemaRemoteTypes';

export const getCollectionTypeForProfileType = (profileType?: ProfileType): Enum_Collection_Type_Enum => {
  if (!profileType) {
    return Enum_Collection_Type_Enum.Owned;
  }
  switch (profileType) {
    case 'ARTIST':
      return Enum_Collection_Type_Enum.Created;
    case 'COLLECTOR':
      return Enum_Collection_Type_Enum.Owned;
    case 'GALLERY':
      return Enum_Collection_Type_Enum.Managed;
    default:
      throw Error(`Profile type not handled: ${profileType}`);
  }
};
