import { makeStyles } from '@material-ui/core';

export const useArRatingInfoModalStyles = makeStyles(() => ({
  alert: {
    gap: '16px',
    padding: '16px',
    // index.css styling is messing with the alert (.MuiAlert-standardInfo)
    // TODO: ask if I can remove the !important from index css styling
    backgroundColor: 'rgb(232, 244, 253) !important',
    maxWidth: 'unset !important',
  },
  alertIcon: {
    color: 'rgba(0,0,0,64) !important',
    margin: 'unset',
    padding: 'unset',
  },
  alertMessage: {
    color: '#121112',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '22px',
    padding: 'unset',
  },
  closeIcon: {
    borderRadius: '6px',
    border: '1px solid rgba(0, 0, 0, 0.16)',
    background: 'white',
    padding: '8px',
  },
  closeButton: {
    padding: '8px 16px',
    borderRadius: '6px',
    backgroundColor: '#1B1B21',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    color: '#FFF',
    '&:hover': {
      backgroundColor: '#3A3C41',
      boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.16)',
    },
  },
  dialogActions: {
    padding: '24px',
    display: 'flex',
    justifyContent: 'end',
  },
  dialogContent: {
    padding: '0px 24px',
    gap: '24px',
    display: 'flex',
    flexDirection: 'column',
    margin: 'unset',
  },
  dialogHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '24px',
    alignItems: 'center',
  },
  dialogTitle: {
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '27px',
    color: '#121112',
  },
  textInfoContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    '& p': {
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '22px',
      color: 'rgba(18, 17, 18, 0.90)',
    },
  },
  content: {
    display: 'flex',
  },
  infoContainer: {
    display: 'flex',
    gap: '32px',
    padding: '0px 44px',
    justifyContent: 'center',
  },
  noPadding: {
    padding: 'unset',
  },
  paper: {
    maxWidth: '736px',
    padding: 'unset',
  },
  scoreContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
}));
