import { useCallback, useState } from 'react';
import useIsMountedRef from './useIsMountedRef';

const useAsyncState = <T>(initialValue: T): [T, React.Dispatch<React.SetStateAction<T>>] => {
  const isMounted = useIsMountedRef();
  const [state, setState] = useState<T>(initialValue);
  const setStateAsync: typeof setState = useCallback((value) => {
    if (!isMounted.current) {
      return;
    }
    setState(value);
  }, []);

  return [state, setStateAsync];
};

export default useAsyncState;
