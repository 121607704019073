import { TypeUtils } from 'common/utils/generalTypeUtils';
import { isSomeEnum } from 'utils/utilFunctions';
import { getDefaultOrientationFilter } from './store/orientation.defaults';
import { OrientationEnum, OrientationFilterState } from './store/orientation.types';

export const defaultOrientationOptions = [
  OrientationEnum.ALL,
  OrientationEnum.SQUARE,
  OrientationEnum.LANDSCAPE,
  OrientationEnum.PORTRAIT,
];

export const orientationFilterHash = (options: OrientationFilterState['orientation']): string =>
  TypeUtils.Object.keys(options ?? {})
    .filter((orientation) => !!options[orientation])
    .sort()
    .join('_');

const SERIALIZATION_KEY = 'orientation';

export const orientationFilterSerialize = (options: OrientationFilterState): string => {
  const serialized = new URLSearchParams();
  serialized.set(
    SERIALIZATION_KEY,
    JSON.stringify(
      TypeUtils.Object.keys(options.orientation ?? {})
        .filter((orientation) => !!options.orientation[orientation])
        .sort()
    )
  );
  return serialized.toString();
};

export const orientationFilterDeserialize = (serializedFilters: string): OrientationFilterState => {
  const serialized = new URLSearchParams(serializedFilters);
  const orientationOptions = serialized.get(SERIALIZATION_KEY);
  const orientationFilter = getDefaultOrientationFilter();
  if (!orientationOptions) {
    return orientationFilter;
  }
  try {
    const parsedOrientation = JSON.parse(orientationOptions);
    if (!Array.isArray(parsedOrientation)) {
      return orientationFilter;
    }
    orientationFilter.orientation[OrientationEnum.ALL] = false;
    parsedOrientation.forEach((orientation) => {
      if (!isSomeEnum(OrientationEnum)(orientation)) {
        return;
      }
      orientationFilter.orientation[orientation] = true;
    });
  } catch (e) {
    console.warn('Failed to parse orientation filter', e);
  }
  return orientationFilter;
};
