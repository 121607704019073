import React from 'react';
import useAnalytics from 'common/features/Analytics/Analytics';
import { Link, LinkProps } from 'react-router-dom';
import { noTapHighlightCSSProps } from 'utils/constants';

const UnstyledAnchor: React.FC<LinkProps> = React.forwardRef(
  ({ style, ...others }, ref: React.Ref<HTMLAnchorElement>) => {
    const analytics = useAnalytics();
    return (
      <Link
        {...others}
        ref={ref}
        style={{
          color: 'inherit',
          textDecoration: 'none',
          ...noTapHighlightCSSProps,
          ...(style ?? {}),
        }}
        onClick={(e) => {
          if (others.onClick) {
            others.onClick(e);
          }
          // target may or may not contain all of these.
          const target = e.target as any;
          analytics.postData('STARTED', 'LINK', 'CLICK', {
            linkSendTo: others?.to.toString()[0] === '/' ? others.to.toString().substring(1) : others.to,
            linkSrc: target?.src,
            linkElementType: target?.localName || target?.nodeName,
            linkClientX: e.clientX,
            linkClientY: e.clientY,
            // TODO: add analytics on divs and spans to be used onClick
            // this will be used in the future to add specific analytics on the target image
            // jsAnalyticsData: target && target.hasAttribute('js-analytics-data') ? target.getAttribute('js-analytics-data') : '',
          });
        }}
      />
    );
  }
);

export default UnstyledAnchor;
