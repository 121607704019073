import { Scalars } from 'common/schema/commonSchemaRemoteTypes';
import { ProfileGallerySort } from './filter.types';

export type ProfileGalleryFiltersHash = `${ProfileGallerySort}_${string}`;

export const listingDirections = ['BACKWARD', 'FORWARD'] as const;
export type ListDirection = (typeof listingDirections)[0] | (typeof listingDirections)[1];

export const loadingIdentifier = 'LOADING' as const;
export type ListLoadingId = typeof loadingIdentifier;

export type ListLoaderStatus = 'COLD' | 'FINISHED' | 'IDLE' | `${ListLoadingId}_${ListDirection}`;

export type ListWindowLoaderStateType<ValuesType, StatusType> = {
  batchSize: number;
  values: Array<ValuesType>;
  status: StatusType;
  hasMore: [boolean, boolean]; // [backward, forward]
};

export type ListLoaderStateType<Typename extends ProfileGallerySort, CursorType, IdType = Scalars['bigint']> = {
  __typename: Typename;
  listed: ListWindowLoaderStateType<CursorType, ListLoaderStatus>;
  detailed: ListWindowLoaderStateType<IdType, ListLoaderStatus>;
  cursor: [CursorType, CursorType]; // [backward, forward]
  detailedToListedStartOffset: number;
  lastScrollPosition: number;
};

export type ProfileGalleryListByTitleCursor = {
  contextId: Scalars['bigint'];
  title: Scalars['String'];
  eventType?: string;
};

export type ProfileGalleryListByIdCursor = {
  contextId: Scalars['bigint'];
  id: Scalars['bigint'];
  eventType?: string;
};

export type ProfileGalleryListByDateCursor = {
  contextId: Scalars['bigint'];
  createdAt: Scalars['date'] | undefined;
  eventType?: string;
};

export type ProfileGalleryListByTitle = ListLoaderStateType<'byTitle', ProfileGalleryListByTitleCursor>;

export type ProfileGalleryListById = ListLoaderStateType<'byId', ProfileGalleryListByIdCursor>;

export type ProfileGalleryListByDate = ListLoaderStateType<'byDate', ProfileGalleryListByDateCursor>;

export type ListActionArgs =
  | [ListDirection, ProfileGalleryListByTitle['__typename'], ProfileGalleryListByTitle['listed']['values']]
  | [ListDirection, ProfileGalleryListById['__typename'], ProfileGalleryListById['listed']['values']]
  | [ListDirection, ProfileGalleryListByDate['__typename'], ProfileGalleryListByDate['listed']['values']];

export type DetailActionArgs =
  | [ListDirection, ProfileGalleryListByTitle['__typename'], ProfileGalleryListByTitle['detailed']['values']]
  | [ListDirection, ProfileGalleryListById['__typename'], ProfileGalleryListById['detailed']['values']]
  | [ListDirection, ProfileGalleryListByDate['__typename'], ProfileGalleryListByDate['detailed']['values']];

export type ProfileGalleryListState = {
  identifier: ProfileGalleryFiltersHash;
  type: ProfileGallerySort;
} & (
  | {
      type: 'byTitle';
      data: ProfileGalleryListByTitle;
    }
  | {
      type: 'byId';
      data: ProfileGalleryListById;
    }
  | {
      type: 'byDate';
      data: ProfileGalleryListByDate;
    }
);

export type ProfileGalleryListingCursor =
  | ProfileGalleryListByTitleCursor
  | ProfileGalleryListByIdCursor
  | ProfileGalleryListByDateCursor;

export const isProfileGalleryListByTitleCursor = (
  cursor: ProfileGalleryListingCursor
): cursor is ProfileGalleryListByTitleCursor => (cursor as ProfileGalleryListByTitleCursor)?.title !== undefined;

export const isProfileGalleryListByIdCursor = (
  cursor: ProfileGalleryListingCursor
): cursor is ProfileGalleryListByIdCursor => (cursor as ProfileGalleryListByIdCursor)?.id !== undefined;

export const isProfileGalleryListByDateCursor = (
  cursor: ProfileGalleryListingCursor
): cursor is ProfileGalleryListByDateCursor => (cursor as ProfileGalleryListByDateCursor)?.createdAt !== undefined;

export const LOADING_STATES: ListLoaderStatus[] = ['LOADING_BACKWARD', 'LOADING_FORWARD'];
