import { createSlice } from '@reduxjs/toolkit';
import { orderOptions } from 'common/hooks/utils/filterUtils';

const initialStateGen = () => ({
  artistData: null,
  artData: null,
  series: [],
  years: [],
  filterAvailability: { id: 0, desc: 'All', query: {} },
  orderBy: orderOptions[0],
  orientationFilters: [
    {
      id: 0,
      desc: 'All Orientations',
      mobileDesc: 'All',
      query: {},
      filterFnc: () => true,
    },
  ],
  filterSeries: [{ id: 0, name: 'All Series', query: {} }],
  filterYears: ['All Years'],
  filterArtists: [],
  artists: [
    {
      id: 0,
      desc: 'All Artists',
      mobileDesc: 'All',
      query: {},
    },
  ],
  initialized: true,
});

const initialState = initialStateGen();

const filterSlice = createSlice({
  initialState,
  name: 'filter',
  reducers: {
    SET_INITIALIZED: (state, action) => {
      state.initialized = action.payload;
    },
    SET_ARTISTS_DATA: (state, action) => {
      state.artists = action.payload;
    },
    SET_FILTER_ARTISTS: (state, action) => {
      state.filterArtists = action.payload;
    },
    SET_ARTIST_DATA: (state, action) => {
      state.artistData = action.payload;
    },
    SET_ARTDATA: (state, action) => {
      state.artData = action.payload;
    },
    SET_SERIES: (state, action) => {
      state.series = action.payload;
    },
    SET_YEARS: (state, action) => {
      state.years = action.payload;
    },
    SET_AVAILABILITY_FILTER: (state, action) => {
      state.filterAvailability = action.payload;
    },
    SET_ORIENTATION_FILTER: (state, action) => {
      state.orientationFilters = action.payload;
    },
    SET_ORDER_FILTER: (state, action) => {
      state.orderBy = action.payload;
    },
    SET_SERIES_FILTER: (state, action) => {
      state.filterSeries = action.payload;
    },
    SET_YEARS_FILTER: (state, action) => {
      state.filterYears = action.payload;
    },
    RESET_FILTERS: () => initialStateGen(),
  },
});

export const filterAction = filterSlice.actions;
export const filterReducer = filterSlice.reducer;
