import useAppSelector from 'common/hooks/useAppSelector';
import { useEffect, useMemo } from 'react';
import useDebouncedState from 'common/hooks/utils/useDebouncedState';
import { radiusFilterHash } from 'stacks/Nearby/RadiusFilter/radius.utils';
import { RadiusFilterState } from 'stacks/Nearby/RadiusFilter/store/radius.types';
import { NearbyGalleriesFiltersHash, LOADING_STATES } from '../store/list.types';
import { NearbyGalleriesFiltersState } from '../store/filter.types';

// exported for use in the initial state
export const filtersHash = (
  state: NearbyGalleriesFiltersState & RadiusFilterState,
  suffix: string | number = ''
): NearbyGalleriesFiltersHash =>
  // using template string to match typing
  `${state.sort}_${[state.refresh, radiusFilterHash(state.currentRadius), suffix].join('_')}` as const;

export const useNearbyGalleriesFiltersIdentifier = (contextId?: string | number): NearbyGalleriesFiltersHash => {
  const filters = useAppSelector((store) => store.nearbyGalleries.filter);
  const currentRadius = useAppSelector((store) => store.nearbyArtists.filter.currentRadius);
  const currentIdentifier = useMemo(() => filtersHash({ ...filters, currentRadius }, contextId), [filters, contextId]);
  const [appliedIdentifier, setAppliedIdentifier] = useDebouncedState(currentIdentifier, 750);
  const listStatus = useAppSelector((store) => store.nearbyGalleries.list.data.listed.status);

  useEffect(() => {
    if (LOADING_STATES.includes(listStatus)) {
      return;
    }
    setAppliedIdentifier(currentIdentifier);
  }, [currentIdentifier, listStatus]);
  return appliedIdentifier;
};
