import React, { useMemo } from 'react';
import { Box, Typography } from '@material-ui/core';
import { Enum_Position_Type_Enum } from 'common/schema/commonSchemaRemoteTypes';
import { useTranslation } from 'react-i18next';
import { CDNUrl } from 'utils/constants';
import { ROUTES } from 'routes/_definition';
import { createPath } from 'routes/routeUtils';
import clsx from 'clsx';
import Links from 'common/components/Links/Links';
import { usePrizeWonBadgeStyles } from './PrizeWonBadge.styles';

export type PrizeProps = {
  place?: Enum_Position_Type_Enum;
  artPrizeTitle: string;
  slug: string;
  isVisible?: boolean;
  offsetTop?: number;
  maxWidth?: string;
};

const PrizeWonBadge: React.FC<PrizeProps> = ({ place, artPrizeTitle, slug, offsetTop = 10, isVisible, maxWidth }) => {
  const classes = usePrizeWonBadgeStyles({ top: offsetTop });
  const { t } = useTranslation();
  const prizeUrl = useMemo(() => createPath(ROUTES.PRIZES_SLUG, { slug }), [slug]);

  if (!place) return null;

  return (
    <Links.UnstyledAnchor to={prizeUrl}>
      <Box
        style={{ '--cardWidth': maxWidth ?? '270px' } as React.CSSProperties}
        className={clsx(classes.wrapper, isVisible ? classes.wrapperAnimation : '')}
      >
        <Box className={classes.badge}>
          <img
            className={clsx(classes.icon, isVisible ? classes.iconAnimation : '')}
            src={`${CDNUrl}/images/artPrizes/${place}.gif`}
            alt={`place_${place}`}
          />
          <Box className={clsx(classes.prizeInfo, isVisible ? classes.prizeInfoAnimation : '')}>
            <Typography variant="h5">{t(`showcase.artPrizes.${place}`)}</Typography>
            <Typography variant="h6">{artPrizeTitle}</Typography>
          </Box>
        </Box>
      </Box>
    </Links.UnstyledAnchor>
  );
};

export default PrizeWonBadge;
