import AvailabilityDot from 'common/components/AvailabilityDot/AvailabilityDot';
import { Maybe } from 'common/schema/commonSchemaRemoteTypes';

const getAvailabilityDot = (
  lastTransactionId?: Maybe<number>,
  isAvailableSale?: Maybe<boolean>,
  editionIsAvailableSale?: Maybe<boolean>,
  availablePrintAmount?: Maybe<number>
): React.ReactNode => {
  let availability: 'AVAILABLE' | 'COLLECTED' | 'UNAVAILABLE' = 'UNAVAILABLE';

  if (lastTransactionId && !availablePrintAmount) {
    availability = 'COLLECTED';
  }

  if (isAvailableSale || (availablePrintAmount && editionIsAvailableSale)) {
    availability = 'AVAILABLE';
  }

  return <AvailabilityDot availability={availability} />;
};

export const getAvailabilityDotByBools = <T extends { isCollected?: Maybe<boolean>; isAvailable?: Maybe<boolean> }>(
  availabilityBools: T
): React.ReactNode => {
  const { isCollected, isAvailable } = availabilityBools;
  let availability: 'AVAILABLE' | 'COLLECTED' | 'UNAVAILABLE' = 'UNAVAILABLE';

  if (isCollected) {
    availability = 'COLLECTED';
  }

  if (isAvailable) {
    availability = 'AVAILABLE';
  }

  return <AvailabilityDot availability={availability} />;
};
export default getAvailabilityDot;
