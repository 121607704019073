import React from 'react';
import { Grid } from '@material-ui/core';
import { ArtistInfoChipProps } from 'common/components/ArtistInfoChip/ArtistInfoChip';
import { BasicImageTypePropsExtra } from 'common/components/Image/types';
import { LinkProps } from 'react-router-dom';
import ArtworkCardComponents from '../common';
import Links from '../../Links/Links';
import CustomCardComponents from '../GenericArtworkCard/components';
import PrizeWonBadge, { PrizeProps } from '../common/PrizeWonBadge/PrizeWonBadge';
import { ArtworkInformationProps } from '../common/ArtworkInformation';
import ArtworkCardOnHover from '../common/ArtworkCardOnHover';
import SwipeInfoContainer, { SwipeInfoContainerProps } from '../common/SwipeInfoContainer/SwipeInfoContainer';

export type ArtPrizeCardProps = {
  cardTargetLinksProps: {
    top: LinkProps;
    bottom: LinkProps;
  };
  artistChipProps?: ArtistInfoChipProps;
  imageProps?: BasicImageTypePropsExtra<{
    aspectRatio?: React.CSSProperties['aspectRatio'];
  }>;
  artworkInfoProps?: ArtworkInformationProps;
  cardCTA?: React.ReactNode;
  isSquare?: boolean;
  prizeProps: PrizeProps;
  swipeContainerProps?: SwipeInfoContainerProps;
  isSwipeable?: boolean;
};

export const ArtPrizeCard: React.FC<ArtPrizeCardProps> = React.memo(
  ({
    cardTargetLinksProps,
    cardCTA,
    isSquare,
    imageProps,
    artistChipProps,
    artworkInfoProps,
    prizeProps,
    swipeContainerProps,
    isSwipeable,
  }) => (
    <>
      <ArtworkCardComponents.SwipeCardTopBottomLayout
        topProps={{
          ...swipeContainerProps?.touchProps,
          style: undefined,
        }}
      >
        {isSwipeable ? <SwipeInfoContainer {...swipeContainerProps} /> : <></>}
        <Links.UnstyledAnchor {...cardTargetLinksProps.top} style={swipeContainerProps?.touchProps?.style}>
          <PrizeWonBadge {...prizeProps} />
          <ArtworkCardOnHover>
            <ArtworkCardComponents.ImageInfoLayout>
              <ArtworkCardComponents.ArtworkInformation {...(artworkInfoProps ?? {})} />
              {!isSquare ? (
                <ArtworkCardComponents.SoloAspectRatioImage {...(imageProps ?? {})} />
              ) : (
                <ArtworkCardComponents.SoloSquareImage {...(imageProps ?? {})} />
              )}
            </ArtworkCardComponents.ImageInfoLayout>
          </ArtworkCardOnHover>
        </Links.UnstyledAnchor>

        <Links.UnstyledAnchor {...cardTargetLinksProps.bottom}>
          <ArtworkCardComponents.FooterLayout>
            <Grid item style={{ minWidth: '40%' }}>
              <CustomCardComponents.ArtistInfoChip {...(artistChipProps ?? {})} />
            </Grid>
            <Grid item style={{ flexShrink: 0 }}>
              {cardCTA}
            </Grid>
          </ArtworkCardComponents.FooterLayout>
        </Links.UnstyledAnchor>
      </ArtworkCardComponents.SwipeCardTopBottomLayout>
    </>
  )
);
