import { Artwork_Bool_Exp } from '../../../schema/commonSchemaRemoteTypes';
import {
  AppliedListingModifier,
  ARTWORK_LOCAL_CONDITION,
  ARTWORK_LOCAL_CONDITION_PAYLOAD,
  ARTWORK_LOCAL_SORT,
  ARTWORK_LOCAL_SORT_PAYLOAD,
  ARTWORK_QUERY_CONDITION,
  ARTWORK_QUERY_CONDITION_IN,
  BaseOptionValueType,
  FILTERS_DEFAULT_VALUE,
  isOptionType,
} from '../config/types';

type AppliedFilters = {
  artworkQueryCondition: Artwork_Bool_Exp;
  localSort: ARTWORK_LOCAL_SORT_PAYLOAD;
  localFilter: ARTWORK_LOCAL_CONDITION_PAYLOAD;
};
export const concatAppliedListModifiers = (
  appliedListingModifiers: AppliedListingModifier[],
  extraFilters?: Artwork_Bool_Exp[]
): AppliedFilters => ({
  artworkQueryCondition: appliedListingModifiers.reduce<Artwork_Bool_Exp>(
    (appliedCondition, currentModifier) => {
      const flattenedOptions = currentModifier.flattenSelectedOptions(
        currentModifier.allOptions,
        currentModifier.selectedOptions
      );
      if (
        !isOptionType<ARTWORK_QUERY_CONDITION>(flattenedOptions, 'ARTWORK_QUERY_CONDITION') &&
        !isOptionType<ARTWORK_QUERY_CONDITION_IN>(flattenedOptions, 'ARTWORK_QUERY_CONDITION_IN')
      ) {
        return appliedCondition;
      }
      return {
        _and: [...(appliedCondition._and ?? []), flattenedOptions.payload],
      };
    },
    extraFilters?.length ? { _and: [...extraFilters] } : {}
  ),
  localFilter: appliedListingModifiers.reduce<ARTWORK_LOCAL_CONDITION_PAYLOAD>(
    (appliedLocalFilter, currentModifier) => {
      const flattenedOptions = currentModifier.flattenSelectedOptions(
        currentModifier.allOptions,
        currentModifier.selectedOptions
      );
      if (!isOptionType<ARTWORK_LOCAL_CONDITION>(flattenedOptions, 'ARTWORK_LOCAL_CONDITION')) {
        return appliedLocalFilter;
      }
      return (artwork) => appliedLocalFilter(artwork) && flattenedOptions.payload(artwork);
    },
    () => true
  ),
  localSort: appliedListingModifiers.reduce<ARTWORK_LOCAL_SORT_PAYLOAD>(
    (appliedLocalSort, currentModifier) => {
      const flattenedOptions = currentModifier.flattenSelectedOptions(
        currentModifier.allOptions,
        currentModifier.selectedOptions
      );
      if (!isOptionType<ARTWORK_LOCAL_SORT>(flattenedOptions, 'ARTWORK_LOCAL_SORT')) {
        return appliedLocalSort;
      }
      return (a, b) => appliedLocalSort(a, b) || flattenedOptions.payload(a, b);
    },
    () => 0
  ),
});

export const selectOptionFromAppliedModifier = (
  appliedListingModifier: AppliedListingModifier,
  optionValue: BaseOptionValueType
): BaseOptionValueType[] => {
  if (optionValue === FILTERS_DEFAULT_VALUE) return [FILTERS_DEFAULT_VALUE];
  const type = appliedListingModifier.selectionType;
  const { selectedOptions } = appliedListingModifier;
  const isAtIndex = selectedOptions.indexOf(optionValue);
  const usedOptions = [...selectedOptions];

  if (isAtIndex > -1) {
    usedOptions.splice(isAtIndex, 1);
    if (!usedOptions.length) return [FILTERS_DEFAULT_VALUE];
    return usedOptions;
  }

  if (type === 'SINGLE') {
    return [optionValue];
  }

  return [...usedOptions.filter((x) => x !== FILTERS_DEFAULT_VALUE), optionValue];
};
