import CustomizableDropdown from 'common/components/CustomizableDropdown/CustomizableDropdown';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CDNUrl } from 'utils/constants';
import { mixins } from 'utils/customTheme';
import { typographyStyles } from 'utils/typographyStyles';
import { useDispatch } from 'react-redux';
import useAppSelector from 'common/hooks/useAppSelector';
import { ListRadiusSearchOption } from 'stacks/Destinations/components/ListRadiusSearch/ListRadiusSearchUtils';
import { useSelectedProfile } from 'common/hooks/useSelectedProfile';
import useAnalytics from 'common/features/Analytics/Analytics';
import { nearbyArtistsActions } from './Artists/store';

export const LIST_RADIUS_SEARCH_OPTIONS: ListRadiusSearchOption[] = [
  { value: 50, label: '50 km' },
  { value: 100, label: '100 km' },
  { value: 150, label: '150 km' },
];

const COUNTRIES_THAT_USE_MILES = ['US', 'LR', 'MM'];

const NearbyRadiusDropdown: React.FC = () => {
  const { t } = useTranslation();
  const typographyClasses = typographyStyles();
  const currentRadius = useAppSelector((store) => store.nearbyArtists.filter.currentRadius);
  const analytics = useAnalytics();
  const profile = useSelectedProfile();
  const dispatch = useDispatch();
  const width = '400px';

  const localizeRadiusOption = (option: ListRadiusSearchOption) => {
    const country = profile?.locations.filter((location) => location.name === 'Primary Location')?.[0]?.country;
    if (country && COUNTRIES_THAT_USE_MILES.includes(country)) {
      return {
        value: option.value,
        label: `${Math.round(option.value * 0.6)} mi`,
      };
    }
    return option;
  };

  return (
    <div style={{ maxWidth: width, textAlign: 'left' }}>
      <CustomizableDropdown
        width={width}
        options={LIST_RADIUS_SEARCH_OPTIONS.map((option) => localizeRadiusOption(option))}
        selectedOption={localizeRadiusOption(currentRadius)}
        keyExtractor={(option) => option.label}
        renderOption={(option) => (
          <div style={{ ...mixins.flexSpaceBetween }}>
            <span>{option.label}</span>
            <span>
              {option.value === localizeRadiusOption(currentRadius).value && (
                <img src={`${CDNUrl}/images/artistProfileFooterIcons/checkmark.svg`} alt="checkmark" />
              )}
            </span>
          </div>
        )}
        renderSelected={(option) => (
          <div style={{ ...mixins.flexCentered, gap: 10 }}>
            <img src={`${CDNUrl}/images/edit.svg`} alt="Edit radius" />
            <span style={{ flex: 3 }}>{t('nearby.radiusDropdown')}</span>
            <span className={typographyClasses.fontSize18} style={{ fontWeight: 600 }}>
              {option.label}
            </span>
          </div>
        )}
        onSelect={(option) => {
          dispatch(nearbyArtistsActions.CHANGE_RADIUS_NearbyArtists(option));
          analytics.postData('SUCCESS', 'NEARBY_ARTISTS_PAGE', 'CHANGE_RADIUS', {
            nearbyRadius: option,
          });
        }}
      />
    </div>
  );
};

export default NearbyRadiusDropdown;
