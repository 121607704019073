import { Enum_Language_Preference_Enum } from 'common/schema/commonSchemaRemoteTypes';

export const languageLocalesList: { [key: string]: string } = {
  ENGLISH: 'en',
  SPANISH: 'es',
  ROMANIAN: 'ro',
  FRENCH: 'fr',
  PORTUGUESE: 'pt',
};

export const getUserLanguageFromBrowser = (locale: string): Enum_Language_Preference_Enum => {
  switch (locale) {
    case 'es':
      return Enum_Language_Preference_Enum.Spanish;
    case 'ro':
      return Enum_Language_Preference_Enum.Romanian;
    case 'fr':
      return Enum_Language_Preference_Enum.French;
    case 'pt':
    case 'pt-BR':
    case 'pt-PT':
      return Enum_Language_Preference_Enum.Portuguese;
    default:
      return Enum_Language_Preference_Enum.English;
  }
};
