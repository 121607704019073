import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createRefreshFilterCaseReducer } from 'common/features/Filters/predefined/Refresh/refresh.reducers';
import { filterInitialState, getDefaultFilters } from './filter.defaults';
import { NearbyGalleriesFiltersState } from './filter.types';

export const nearbyGalleriesFiltersSlice = createSlice({
  name: 'filter',
  initialState: filterInitialState,
  reducers: {
    RESET_NearbyGalleries: getDefaultFilters,
    SORT_NearbyGalleries: (state, action: PayloadAction<NearbyGalleriesFiltersState['sort']>) => ({
      ...state,
      sort: action.payload,
    }),
    REFRESH_NearbyGalleries: createRefreshFilterCaseReducer<NearbyGalleriesFiltersState>(),
  },
});
