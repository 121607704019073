import { createSlice } from '@reduxjs/toolkit';
import { ArtistSpotlightCommonProps } from '../ArtistSpotlight';

export const artistSpotlightSlice = createSlice({
  name: 'artistSpotlight',
  initialState: null as ArtistSpotlightCommonProps | null,
  reducers: {
    UPDATE_ARTIST_SPOTLIGHT: (_, action: { payload: ArtistSpotlightCommonProps }) => action.payload,
  },
});

export const artistSpotlightActions = artistSpotlightSlice.actions;
