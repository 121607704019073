import { createSlice } from '@reduxjs/toolkit';

export type ArtShowArtworksDisplayState = {
  isFiltersDialogOpen: boolean;
};

export const getDefaultDisplay = (): ArtShowArtworksDisplayState => ({
  isFiltersDialogOpen: false,
});

export const displayInitialState: ArtShowArtworksDisplayState = getDefaultDisplay();

export const displayArtShowArtworksSlice = createSlice({
  name: 'display',
  initialState: displayInitialState,
  reducers: {
    OPEN_FILTERS_ArtShowArtworks: (state) => {
      state.isFiltersDialogOpen = true;
    },
    CLOSE_FILTERS_ArtShowArtworks: (state) => {
      state.isFiltersDialogOpen = false;
    },
  },
});
