const matchRoutesWithHandle = <T extends string>(
  route: T,
  routeMatchesConfig: {
    indexes: number[];
    route: string;
  }[]
): string | null => {
  const sections = route.split('/');
  let matchedRoute = null;
  let foundMatch = false;

  routeMatchesConfig.forEach(({ indexes, route: configRoute }) => {
    if (foundMatch) {
      return;
    }
    const routeSections = configRoute.split('/');
    if (indexes.every((index) => sections[index] === routeSections[index])) {
      matchedRoute = configRoute;
      foundMatch = true;
    }
  });

  return matchedRoute;
};
export default matchRoutesWithHandle;
