import { Grid } from '@material-ui/core';
import { SkeletonText } from 'common/components/TypographyText/SkeletonText';
import React from 'react';
import { mixins } from 'utils/customTheme';

const overflowEllipsis: React.CSSProperties = {
  maxWidth: '100%',
  ...mixins.textEllipsis,
};

const defaultTextStyles: React.CSSProperties = {
  color: 'white',
  fontSize: '16px',
  lineHeight: '20px',
};

export type ArtworkInformationRowProps = {
  left?: string;
  right?: string;
  overrideTextStyles?: {
    left?: React.CSSProperties;
    right?: React.CSSProperties;
  };
};
export type ArtworkInformationRowType = React.FC<ArtworkInformationRowProps>;
export const ArtworkInformationRow: ArtworkInformationRowType = ({ left, right, overrideTextStyles }) => (
  <Grid container direction="row" justifyContent="space-between" alignItems="flex-end" wrap="nowrap">
    <SkeletonText
      text={left}
      style={{ ...defaultTextStyles, fontWeight: 600, ...overflowEllipsis, ...overrideTextStyles?.left }}
      skeletonStyle={{ width: '100%' }}
    />
    <SkeletonText text={right} style={{ ...defaultTextStyles, fontWeight: 400, ...overrideTextStyles?.right }} />
  </Grid>
);
