import { createSlice } from '@reduxjs/toolkit';

export enum Enum_Payment_Method_Type_Enum {
  MONTHLY = 'MONTHLY',
  YEARLY = 'YEARLY',
}

export enum Enum_Selected_Payment_Plan_Type_Enum {
  FREE = 'FREE',
  PREMIUM = 'PREMIUM',
  PRO = 'PRO',
}

export interface upgradeToMyStudioModalState {
  isOpen: boolean;
  openSuccessPaymentModal: boolean;
  openPromptModal: boolean;
  paymentMethod: Enum_Payment_Method_Type_Enum;
  selectedPaymentPlan: Enum_Selected_Payment_Plan_Type_Enum;
  isLearnMoreModalOpen: boolean;
}

const initialState: upgradeToMyStudioModalState = {
  isOpen: false,
  openSuccessPaymentModal: false,
  openPromptModal: false,
  paymentMethod: Enum_Payment_Method_Type_Enum.YEARLY,
  selectedPaymentPlan: Enum_Selected_Payment_Plan_Type_Enum.PRO,
  isLearnMoreModalOpen: false,
};

const upgradeToMyStudioModalSlice = createSlice({
  name: 'upgradeToMyStudioModal',
  initialState,
  reducers: {
    OPEN_UPGRADE_TO_MY_STUDIO_MODAL: (state) => ({ ...state, isOpen: true }),
    CLOSE_UPGRADE_TO_MY_STUDIO_MODAL: (state) => ({ ...state, isOpen: false }),
    SET_SELECTED_PAYMENT_PLAN: (state, action) => ({ ...state, selectedPaymentPlan: action.payload }),
    SET_PAYMENT_METHOD: (state, action) => ({ ...state, paymentMethod: action.payload }),
    RESET_UPGRADE_TO_MY_STUDIO_MODAL_STATE: () => initialState,
    OPEN_SUCCESS_PAYMENT_MODAL: (state) => ({ ...state, openSuccessPaymentModal: true }),
    CLOSE_SUCCESS_PAYMENT_MODAL: (state) => ({ ...state, openSuccessPaymentModal: false }),
    OPEN_PROMPT_MODAL: (state) => ({ ...state, openPromptModal: true }),
    CLOSE_PROMPT_MODAL: (state) => ({ ...state, openPromptModal: false }),
    OPEN_LEARN_MORE_MODAL: (state) => ({ ...state, isLearnMoreModalOpen: true }),
    CLOSE_LEARN_MORE_MODAL: (state) => ({ ...state, isLearnMoreModalOpen: false }),
  },
});

export const upgradeToMyStudioModalActions = upgradeToMyStudioModalSlice.actions;
export default upgradeToMyStudioModalSlice;
