import firebase from 'firebase/app';
import { HasuraRole } from 'utils/constants';

export interface KaleidoHasuraClaims {
  hasura: {
    all_roles: HasuraRole[];
    user_id: string;
  };
}

export type FirebaseStateProps = {
  account: firebase.User | null;
  jwt: string | null;
  jwtExpirationDate: number | null;
  claims: KaleidoHasuraClaims | null;
  firebaseUserId: number | null;
  firebaseUserEmail: string | null;
  skipUpdateFirebaseJWTAndClaims: boolean;
};

export type FirebaseUserCredential = firebase.auth.UserCredential;

export enum ThirdPartyProviderId {
  GOOGLE = 'google.com',
  APPLE = 'apple.com',
}
