import React, { useMemo } from 'react';
import { Grid } from '@material-ui/core';
import { ArtistInfoChipProps } from 'common/components/ArtistInfoChip/ArtistInfoChip';
import { BasicImageTypeProps } from 'common/components/Image/types';
import { LinkProps } from 'react-router-dom';
// import { useOnScreenImpressions } from 'common/hooks/useOnScreen';
import SwipeInfoContainer, {
  SwipeInfoContainerProps,
} from 'common/components/ArtworkCard/common/SwipeInfoContainer/SwipeInfoContainer';
import ArtworkCardOnHover from 'common/components/ArtworkCard/common/ArtworkCardOnHover';
import ArtworkCardComponents from 'common/components/ArtworkCard/common/index';
import { ArtworkInformationProps } from 'common/components/ArtworkCard/common/ArtworkInformation';
import Links from 'common/components/Links/Links';
import CustomCardComponents from 'common/components/ArtworkCard/GenericArtworkCard/components/index';
import { useCollectorProfileSlice } from 'store/slices/collectorProfile';
import PrizesContainerV2 from '../../../../../common/components/ArtworkInfoWrapper/components/PrizesContainerV2';

export type ArtPrizeCardProps = {
  cardTargetLinksProps: {
    top: LinkProps;
    bottom: LinkProps;
  };
  artistChipProps?: ArtistInfoChipProps;
  imageProps?: BasicImageTypeProps;
  artworkInfoProps?: ArtworkInformationProps;
  cardCTA?: React.ReactNode;
  isSquare?: boolean;
  artworkId: number;
  swipeContainerProps?: SwipeInfoContainerProps;
  showArtPrizes?: boolean;
  isVisible: boolean;
};

export const CollectorArtPrizeCard: React.FC<ArtPrizeCardProps> = ({
  cardTargetLinksProps,
  cardCTA,
  isSquare = true,
  imageProps,
  artistChipProps,
  artworkInfoProps,
  swipeContainerProps,
  artworkId,
  showArtPrizes = false,
  isVisible,
}) => {
  const { artworksArtPrizes } = useCollectorProfileSlice();

  const filteredArtworkPrizes = useMemo(
    () => artworksArtPrizes.filter((prize) => prize.artworkId === artworkId),
    [artworksArtPrizes]
  );

  return (
    <>
      <ArtworkCardComponents.SwipeCardTopBottomLayout
        topProps={{
          ...swipeContainerProps?.touchProps,
          style: undefined,
        }}
      >
        <SwipeInfoContainer {...swipeContainerProps} />
        <Links.UnstyledAnchor
          {...cardTargetLinksProps.top}
          style={{ ...swipeContainerProps?.touchProps?.style, position: 'relative', overflow: 'hidden' }}
        >
          {showArtPrizes && <PrizesContainerV2 artPrizes={filteredArtworkPrizes} isVisible={isVisible} />}
          <ArtworkCardOnHover>
            <ArtworkCardComponents.ImageInfoLayout>
              <ArtworkCardComponents.ArtworkInformation {...(artworkInfoProps ?? {})} />
              {!isSquare ? (
                <ArtworkCardComponents.SoloAspectRatioImage {...(imageProps ?? {})} />
              ) : (
                <ArtworkCardComponents.SoloSquareImage {...(imageProps ?? {})} />
              )}
            </ArtworkCardComponents.ImageInfoLayout>
          </ArtworkCardOnHover>
        </Links.UnstyledAnchor>

        <Links.UnstyledAnchor {...cardTargetLinksProps.bottom}>
          <ArtworkCardComponents.FooterLayout>
            <Grid item style={{ minWidth: '40%' }}>
              <CustomCardComponents.ArtistInfoChip {...(artistChipProps ?? {})} />
            </Grid>
            <Grid item style={{ flexShrink: 0 }}>
              {cardCTA}
            </Grid>
          </ArtworkCardComponents.FooterLayout>
        </Links.UnstyledAnchor>
      </ArtworkCardComponents.SwipeCardTopBottomLayout>
    </>
  );
};
