import React, { useState } from 'react';
import { Box, IconButton } from '@material-ui/core';
import { getProvenancePanelInfo } from 'stacks/ProfileArtShowcase/components/ProfileArtShowcaseLeftSide/hooks/utils';
import ProvenanceContainer from 'stacks/CertificateOfAuthenticity/components/LayoutCertificateOfAuthenticity/components/ProvenanceARExperience/components/ProvenanceContainer/ProvenanceContainer';
import { CDNUrl } from 'utils/constants';
import clsx from 'clsx';
import { useGetProvenanceAndHandlesByArtworkIdQuery } from 'common/schema/commonSchemaRemoteGraphqlQueries';
import { useProvenancePanelStyles } from './ProvenancePanel.styles';

type ProvenancePanelProps = {
  artworkId: number;
  onClose: () => void;
};

const ProvenancePanel: React.FC<ProvenancePanelProps> = ({ artworkId, onClose }) => {
  const [shouldFadeOut, setShouldFadeOut] = useState(false);
  const classes = useProvenancePanelStyles();
  const { data } = useGetProvenanceAndHandlesByArtworkIdQuery({ variables: { artwork_id: artworkId, artworkId } });

  return (
    <Box className={clsx(classes.panel, shouldFadeOut ? classes.panelFadeOut : '')}>
      <IconButton
        className={classes.closeButton}
        onClick={() => {
          setShouldFadeOut(true);
          onClose();
        }}
      >
        <img src={`${CDNUrl}/images/closeButton.svg`} alt="closeButton" />
      </IconButton>
      <ProvenanceContainer hasAnimationStarted provenance={getProvenancePanelInfo(data)} />
    </Box>
  );
};

export default ProvenancePanel;
