import clsx from 'clsx';
import React, { useMemo } from 'react';
import { useAvailabilityDotStyles } from './AvailabilityDot.styles';

export type AvailabilityDotProps = {
  availability: 'COLLECTED' | 'AVAILABLE' | 'UNAVAILABLE' | 'HIDE_COLLECTED' | 'HIDE_UNAVAILABLE';
  size?: 'SMALL' | 'MEDIUM' | 'LARGE';
};

const AvailabilityDot: React.FC<AvailabilityDotProps> = ({ availability, size = 'MEDIUM' }) => {
  const classes = useAvailabilityDotStyles();

  const buttonColor = useMemo(() => {
    switch (availability) {
      case 'AVAILABLE': {
        return '#499BE7';
      }
      case 'UNAVAILABLE': {
        return '#CDCAC4';
      }
      case 'COLLECTED': {
        return '#C82626';
      }
      default: {
        return '';
      }
    }
  }, [availability]);

  const buttonSize = useMemo(() => {
    switch (size) {
      case 'SMALL': {
        return classes.smallDot;
      }
      case 'MEDIUM': {
        return classes.mediumDot;
      }
      case 'LARGE': {
        return classes.largeDot;
      }
      default: {
        return classes.smallDot;
      }
    }
  }, [classes, size]);

  return <div className={clsx(buttonSize, classes.container)} style={{ backgroundColor: buttonColor }} />;
};

export default AvailabilityDot;
