import { ROUTES, CUSTOM_DOMAIN_ROUTES } from 'routes/_definition';
import { IS_CUSTOM_DOMAINS_APP } from 'utils/constants';
import { isSomeEnum } from 'utils/utilFunctions';

const publicDomainChunks = Object.entries(ROUTES).map(([_, value]) => [value, value.split('/').slice(1)]) as Array<
  [string, string[]]
>;
const customDomainChunks = Object.entries(CUSTOM_DOMAIN_ROUTES).map(([_, value]) => [
  value,
  value.split('/').slice(1),
]) as Array<[string, string[]]>;

export const commonFindRouteFromPath = (pathname: string): string[] => {
  // get the path chunks
  const pathChunks = pathname.split('/').slice(1);

  // get all the routes chunks depending on custom domain
  const definitions = IS_CUSTOM_DOMAINS_APP ? customDomainChunks : publicDomainChunks;

  // get all route matches with same length (number of chunks)
  const sizeMatches = definitions.filter(([, routeChunks]) => routeChunks.length === pathChunks.length);

  // try to find a static match
  if (sizeMatches.find(([route]) => route === pathname)) {
    return [pathname];
  }

  const search = (index: number, pool: typeof sizeMatches): string[] => {
    // all indexes processed, return filtered results
    if (index === pathChunks.length) {
      return pool.map(([route]) => route);
    }

    // try to match with a static chunk
    const staticMatches = pool.filter(([, routeChunks]) => routeChunks[index] === pathChunks[index]);
    if (staticMatches.length) {
      return search(index + 1, staticMatches);
    }

    // no static matches, filter by dynamic chunks
    const dynamicMatches = pool.filter(([, routeChunks]) => routeChunks[index].startsWith(':'));
    if (dynamicMatches.length) {
      return search(index + 1, dynamicMatches);
    }

    // nothing found, return empty array
    return [];
  };

  // search through the size matches for dynamic route matches
  return search(0, sizeMatches);
};

export const findPublicDomainRouteFromPath = (pathname: string): ROUTES[] => {
  const possibleRoutes = commonFindRouteFromPath(pathname);
  const isRoute = isSomeEnum(ROUTES);
  return possibleRoutes.filter(isRoute);
};

export const findCustomDomainRouteFromPath = (pathname: string): CUSTOM_DOMAIN_ROUTES[] => {
  const possibleRoutes = commonFindRouteFromPath(pathname);
  const isRoute = isSomeEnum(CUSTOM_DOMAIN_ROUTES);
  return possibleRoutes.filter(isRoute);
};

const findRouteFromPath = (pathname = window.location.pathname): CUSTOM_DOMAIN_ROUTES[] | ROUTES[] => {
  if (IS_CUSTOM_DOMAINS_APP) {
    return findCustomDomainRouteFromPath(pathname);
  }
  return findPublicDomainRouteFromPath(pathname);
};

export default findRouteFromPath;
