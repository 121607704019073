import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { TagsFilterState } from './tags.types';

type SelectTagAction = PayloadAction<TagsFilterState['tags']>;
export const createSelectTagCaseReducer =
  <State extends TagsFilterState>(): CaseReducer<State, SelectTagAction> =>
  (state, action) => {
    if (state.tags === action.payload) {
      return;
    }
    state.tags = action.payload;
  };
