import { logger } from '@old/services/logger';
import { useSnackbar } from 'common/contexts/ToastProvider';
import { useSelectedProfile } from 'common/hooks/useSelectedProfile';
import {
  useInsertProfileAppearanceSettinsMutation,
  useUpdateProfileAppearanceSettingsMutation,
  useUpdateProfileThemeMutation,
} from 'common/schema/commonSchemaRemoteGraphqlQueries';
import { Enum_Profile_Theme_Enum, Profile_Appearance_Settings_Set_Input } from 'common/schema/commonSchemaRemoteTypes';
import { TypeUtils } from 'common/utils/generalTypeUtils';
import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Beforeunload } from 'react-beforeunload';
import { useTranslation } from 'react-i18next';
import useAnalytics from 'common/features/Analytics/Analytics';
import { useProfileAppearanceSettingsStyles } from '../views/ProfileAppearanceSettings.styles';

export enum LayoutType {
  'GRID' = 'GRID',
  'MASONRY' = 'MASONRY',
}

export enum ArtworkInfoTrigger {
  'ALWAYS' = 'ALWAYS',
  'HOVER' = 'HOVER',
}

export type AppearanceArtworkInfo = {
  showYear: boolean;
  showSize: boolean;
  showPrice: boolean;
};

export type ProfileColorTheme = 'LIGHT' | 'DARK';

export type ProfileAppearanceSettings = {
  id: number | null;
  colorTheme: ProfileColorTheme;
  layoutType: LayoutType;
  columnsCount: number;
  showYear: boolean;
  showSize: boolean;
  showPrice: boolean;
  artworkInfoTrigger: ArtworkInfoTrigger;
};

type ProfileAppearanceSettingContext = {
  settings: ProfileAppearanceSettings;
  setSettings: Dispatch<SetStateAction<ProfileAppearanceSettings>>;
  saveSettings: () => void;
  hasUnsavedChanges?: boolean;
};

export const defaultSettings: ProfileAppearanceSettings = {
  id: null,
  showYear: false,
  showSize: false,
  showPrice: false,
  artworkInfoTrigger: ArtworkInfoTrigger.ALWAYS,
  colorTheme: 'LIGHT',
  layoutType: LayoutType.GRID,
  columnsCount: 2,
};

const initialProfileAppearanceSettingsContext: ProfileAppearanceSettingContext = {
  settings: defaultSettings,
  setSettings: () => null,
  saveSettings: () => null,
};

const ProfileAppearanceSettingContext = createContext<ProfileAppearanceSettingContext>(
  initialProfileAppearanceSettingsContext
);

export const useProfileAppearanceSettingsContext = () => useContext(ProfileAppearanceSettingContext);

type CreateProfileAppearanceSettingsProviderProps = {
  initialSettings: ProfileAppearanceSettings;
};

export const ProfileAppearanceSettingsProvider: React.FC<CreateProfileAppearanceSettingsProviderProps> = ({
  children,
  initialSettings,
}) => {
  const snackbar = useSnackbar();
  const [initSettings, setInitSettings] = useState(initialSettings);
  const [settings, setSettings] = useState(initialSettings);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const { t } = useTranslation();

  const profile = useSelectedProfile();
  const [insertSettings, insertResult] = useInsertProfileAppearanceSettinsMutation();
  const [updateSettings] = useUpdateProfileAppearanceSettingsMutation();
  const [updateProfileTheme] = useUpdateProfileThemeMutation();
  const classes = useProfileAppearanceSettingsStyles();
  const { postData: postAnalytics } = useAnalytics();

  useEffect(() => {
    setSettings(initialSettings);
    setInitSettings(initialSettings);
  }, [initialSettings]);

  const settingsObj: Profile_Appearance_Settings_Set_Input = useMemo(
    () => ({
      artwork_info: JSON.stringify({
        showYear: settings.showYear,
        showSize: settings.showSize,
        showPrice: settings.showPrice,
      }),
      artwork_info_trigger: settings.artworkInfoTrigger,
      profile_id: profile?.profileId,
      profile_columns_count: settings.columnsCount,
      profile_layout_type: settings.layoutType,
    }),
    [
      profile?.profileId,
      settings.artworkInfoTrigger,
      settings.columnsCount,
      settings.layoutType,
      settings.showPrice,
      settings.showSize,
      settings.showYear,
    ]
  );

  const saveSettings = useCallback(async () => {
    try {
      if (settings.id === null) {
        await insertSettings({ variables: { object: settingsObj } });
      } else {
        await updateSettings({
          variables: {
            profile_id: profile?.profileId ?? 0,
            object: settingsObj,
          },
        });
      }
      await updateProfileTheme({
        variables: {
          id: profile?.profileId ?? 0,
          profile_theme: settings.colorTheme as Enum_Profile_Theme_Enum,
        },
      });

      snackbar.showExtendedOptionsSnackbar({
        newMessage: t('artistProfileStrings.appearance.settingsOptions.saveChangesSuccessMessage'),
        newSeverity: 'success',
        newPosition: {
          horizontal: 'center',
          vertical: 'bottom',
        },
        newDuration: 2000,
        newClassName: classes.snackbar,
      });

      setInitSettings({ ...settings });
      setHasUnsavedChanges(false);
      postAnalytics('SUCCESS', 'EDIT_PROFILE', 'EDIT_APPEARANCE', { settings });
    } catch (error) {
      logger.error(error);
    }
  }, [profile?.profileId, settings, settingsObj, snackbar, t]);
  useEffect(() => {
    if (insertResult.loading) {
      return;
    }
    const { data } = insertResult;
    if (data && settings.id === null) {
      setSettings((prev) => ({
        ...prev,
        id: data.insert_Profile_appearance_settings_one?.id || null,
      }));
    }
  }, [insertResult, settings.id]);

  useEffect(() => {
    const hasChanges = TypeUtils.Object.keys(settings).some((k) => settings[k] !== initSettings[k]);
    setHasUnsavedChanges(hasChanges);
  }, [initSettings, settings]);

  const onBeforeUnload = useCallback(
    () => (hasUnsavedChanges ? t('dashboard.unsavedChangesAlert') : false),
    [hasUnsavedChanges, t]
  );

  return (
    <ProfileAppearanceSettingContext.Provider
      value={{
        settings,
        setSettings,
        saveSettings,
        hasUnsavedChanges,
      }}
    >
      <Beforeunload onBeforeunload={onBeforeUnload}>{children}</Beforeunload>
    </ProfileAppearanceSettingContext.Provider>
  );
};

export default ProfileAppearanceSettingsProvider;
