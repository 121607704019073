import { ApolloProvider } from '@apollo/client';
import ProfileProvider from '@old/services/ProfileProvider';
import { ErrorBoundary } from '@sentry/react';
import AppRouterSplitter from 'common/components/AppRouterSplitter/AppRouterSplitter';
import ConfirmationModalProvider from 'common/contexts/ConfirmationModalProvider';
import ToastProvider from 'common/contexts/ToastProvider';
import { apolloClient } from 'common/features/Apollo/clients/apolloClients';
import CustomDomainProvider from 'common/features/CustomDomain/CustomDomainProvider';
import setupSentry from 'common/features/Sentry/config/setupSentry';
import i18n from 'i18n';
import 'index.css';
import 'keyframes.css';
import 'transitions.rtg.css';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import { Provider as ReduxProvider } from 'react-redux';
import { store, persistor } from 'store/store';
import { OnScrollEventHandler } from 'utils/scrollUtil';
import { PersistGate } from 'redux-persist/integration/react';
import { RecognitionsProvider } from '@old/services/RecognitionsProvider';
import { FavoritesProvider } from '@old/services/FavoritesProvider';
import { UnitConversionContextProvider } from 'common/contexts/UnitConversionProvider';
import AppThemeProvider from 'common/components/AppThemeProvider/AppThemeProvider';
import DeepLinkingController from 'common/components/DeepLinkingController/DeepLinkingController';
import LoadingLogo from 'common/components/LoadingLogo/LoadingLogo';
import FirebaseLoader from 'common/services/Auth/components/FirebaseLoader';
import { BrowserRouter as Router } from 'react-router-dom';

setupSentry();

export const getRender = () => (
  <React.StrictMode>
    <ErrorBoundary>
      <DeepLinkingController>
        <OnScrollEventHandler />
        <Suspense fallback={<LoadingLogo open isSuspense />}>
          <ReduxProvider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <ApolloProvider client={apolloClient}>
                <FirebaseLoader>
                  <UnitConversionContextProvider>
                    <ProfileProvider>
                      <AppThemeProvider applyGlobalStyles>
                        <ConfirmationModalProvider>
                          <ToastProvider>
                            <CustomDomainProvider>
                              <I18nextProvider i18n={i18n}>
                                <RecognitionsProvider>
                                  <FavoritesProvider>
                                    <Router>
                                      <AppRouterSplitter />
                                    </Router>
                                  </FavoritesProvider>
                                </RecognitionsProvider>
                              </I18nextProvider>
                            </CustomDomainProvider>
                          </ToastProvider>
                        </ConfirmationModalProvider>
                      </AppThemeProvider>
                    </ProfileProvider>
                  </UnitConversionContextProvider>
                </FirebaseLoader>
              </ApolloProvider>
            </PersistGate>
          </ReduxProvider>
        </Suspense>
      </DeepLinkingController>
    </ErrorBoundary>
  </React.StrictMode>
);
ReactDOM.render(getRender(), document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
