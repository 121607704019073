import { Enum_Tutorial_Step_Type_Enum } from 'common/schema/commonSchemaRemoteTypes';
import { TypeUtils } from 'common/utils/generalTypeUtils';
import { TUTORIAL_COMPLETED_STATUSES } from './tutorialsConstants';
import { Tutorial, Tutorials, TutorialStep, TutorialType } from './tutorialsTypes';

export const getTutorialNextStep = (
  tutorial: Tutorial | null | undefined,
  registeredSteps: Enum_Tutorial_Step_Type_Enum[]
): TutorialStep | undefined => {
  if (!tutorial) {
    return undefined;
  }
  const availableSteps = tutorial.steps.filter((x) => registeredSteps.includes(x.type));
  const currentStep = availableSteps.find((s) => s.type === tutorial.currentStep);
  const possibleNextStep = availableSteps.find((s) => s.order > (currentStep?.order || -1));
  return possibleNextStep;
};

export const isTutorialCompleted = (tutorial: Tutorial | null | undefined): boolean => {
  if (!tutorial || tutorial.id < 0 || !tutorial.status) {
    return false;
  }
  return TUTORIAL_COMPLETED_STATUSES.includes(tutorial.status);
};

let newTutorialId = 0;

export const generateNewTutorial = (type: TutorialType, values?: Partial<Omit<Tutorial, 'type'>>): Tutorial => {
  newTutorialId -= 1;
  return {
    id: newTutorialId,
    type,
    status: null,
    steps: [],
    currentStep: null,
    fetchState: 'FETCHED',
    ...values,
  };
};

export const getUnfinishedTutorials = (tutorials: Tutorials) =>
  TypeUtils.Object.keys(tutorials).filter((type) => !isTutorialCompleted(tutorials[type]));
