import { HtmlDivProps } from 'common/utils/types';
import Lottie from 'react-lottie';
import ARAnimation from './animation/ar-icon-animation-white.json';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: ARAnimation,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const ARIconAnimation: React.FC<HtmlDivProps> = (props) => (
  <div {...props}>
    <Lottie options={defaultOptions} height={32} width={32} />
  </div>
);

export default ARIconAnimation;
