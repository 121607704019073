import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// same numbers are supposed to occupy the same space and not be on the screen at the same time (in theory)
export enum KnownSpacesOrder {
  First = Number.MIN_SAFE_INTEGER,
  PaymentBanner = 10,
  ConsumerPageNavbar = 20,
  ArtistPageNavbar = 20,
  MobileBottomNavbar = 20,
  MobileTopNavbar = 20,
  MyWebsiteTopMenuNavbar = 20,
  MobilePublishArtworkHeader = 25,
  MobilePublishArtworkFooter = 25,
  MobileShoppingCartHeader = 25,
  MobileShoppingCartFooter = 25,
  ArtShowcaseNavbar = 30,
  DashboardNavbar = 30,
  ChatList = 30,
  NotificationCenter = 30,
  ArtistManageArtworkFilters = 40,
  ChatMessagingBox = 40,
  GenericFooter = 200,
  Last = Number.MAX_SAFE_INTEGER,
}

export type KnownScreenSpace = keyof Omit<typeof KnownSpacesOrder, 'First' | 'Last'>;

type Position = 'top' | 'right' | 'bottom' | 'left';
export type ReservedSpace = Record<Position, number>;
export type ExtraSpace = Partial<ReservedSpace> | number;
export type SpaceReservation = {
  id: string;
  order: number;
  reservedSpace: ReservedSpace;
};
export type ScreenspaceState = {
  reservations: SpaceReservation[];
  total: ReservedSpace;
};

export const createReservedSpace = (override?: Partial<ReservedSpace>): ReservedSpace => ({
  top: override?.top ?? 0,
  right: override?.right ?? 0,
  bottom: override?.bottom ?? 0,
  left: override?.left ?? 0,
});

export const createAllAroundReservedSpace = (value: number): ReservedSpace => ({
  top: value,
  right: value,
  bottom: value,
  left: value,
});

export const extraSpaceToReservedSpace = (extraSpace?: ExtraSpace): ReservedSpace => {
  if (typeof extraSpace === 'number') {
    return createAllAroundReservedSpace(extraSpace);
  }
  return createReservedSpace(extraSpace);
};

const getMaxValueInOrderOrZero = (
  index: number,
  key: keyof ReservedSpace,
  reservations: SpaceReservation[]
): number => {
  const reservation = reservations[index];
  const { order } = reservation;
  const { [key]: value } = reservation.reservedSpace;
  if (reservations.some((r) => r.order === order && r.reservedSpace[key] > value)) {
    return 0;
  }
  return value;
};

export const getTotalReservedSpace = (
  reservations: SpaceReservation[],
  upToOrder: number = KnownSpacesOrder.Last
): ReservedSpace =>
  reservations
    .filter((reservation) => reservation.order < upToOrder)
    .map((_, index, self) => ({
      top: getMaxValueInOrderOrZero(index, 'top', self),
      right: getMaxValueInOrderOrZero(index, 'right', self),
      bottom: getMaxValueInOrderOrZero(index, 'bottom', self),
      left: getMaxValueInOrderOrZero(index, 'left', self),
    }))
    .reduce(
      (acc, reservedSpace) => ({
        top: acc.top + reservedSpace.top,
        right: acc.right + reservedSpace.right,
        bottom: acc.bottom + reservedSpace.bottom,
        left: acc.left + reservedSpace.left,
      }),
      createReservedSpace()
    );

const getInitialState = (): ScreenspaceState => ({
  reservations: [],
  total: createReservedSpace(),
});

// this is a hacky solution so we don't have to refactor the whole app's layout
const screenspaceSlice = createSlice({
  name: 'screenspace',
  initialState: getInitialState(),
  reducers: {
    RESERVE_SCREENSPACE: (state, action: PayloadAction<SpaceReservation>) => {
      if (state.reservations.some((reservation) => reservation.id === action.payload.id)) {
        console.warn('screenspace reservation already exists', action.payload);
        return;
      }
      state.reservations = [...state.reservations, action.payload];
      state.reservations.sort((a, b) => a.order - b.order);
      state.total = getTotalReservedSpace(state.reservations);
    },
    RELEASE_SCREENSPACE: (state, action: PayloadAction<SpaceReservation>) => {
      state.reservations = state.reservations.filter((reservation) => reservation.id !== action.payload.id);
      state.total = getTotalReservedSpace(state.reservations);
    },
  },
});

export const screenspaceActions = screenspaceSlice.actions;
export default screenspaceSlice;
