import { combineReducers } from '@reduxjs/toolkit';
import { filterNotificationCenterSlice } from './filterNotificationCenter.slice';
import { listNotificationCenterSlice } from './listNotificationCenter.slice';

export const actions = {
  ...listNotificationCenterSlice.actions,
  ...filterNotificationCenterSlice.actions,
};

export const notificationCenterSlice = {
  notificationCenter: combineReducers({
    [listNotificationCenterSlice.name]: listNotificationCenterSlice.reducer,
    [filterNotificationCenterSlice.name]: filterNotificationCenterSlice.reducer,
  }),
};
