import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ArtistProfileCardType } from 'common/utils/types';
import useAppSelector from 'common/hooks/useAppSelector';
import {
  ArtistFilterOptionType,
  DEFAULT_ARTIST_ID_VALUE,
} from 'common/features/Filters/predefined/Artists/store/artists.types';
import { ProfileAppearanceSettings } from 'stacks/Dashboard/common/ProfileAppearance/context/ProfileAppearanceSettingsProvider';
import { Enum_Profile_Background_Type_Enum } from 'common/schema/commonSchemaRemoteTypes';

export type artworksArtPrizesType = {
  artworkId: number;
  prize: string;
  prizeSlug: string;
  prizeTitle: string;
};

export type collectorDataType = {
  handle: string;
  id: number;
  name: string;
  avatar: string;
  avatarFileName: string;
  location: string;
  darkMode: boolean;
  appearanceSettings?: ProfileAppearanceSettings;
  backgrounds: Enum_Profile_Background_Type_Enum[];
  interests: string[];
};

export type followStatsType = {
  total: number;
  totalArtists?: number;
  totalCollectors?: number;
};

type collectorProfileSliceType = {
  collectorData: collectorDataType;
  artistsSidebar: ArtistProfileCardType[];
  totalArtworks: number;
  followAnalytics: {
    following: followStatsType;
    followers: followStatsType;
  };
  filters: {
    years: number[];
    artistsContextIds: ArtistFilterOptionType[];
  };
  artworksArtPrizes: artworksArtPrizesType[];
};

const initialState: collectorProfileSliceType = {
  collectorData: {
    handle: '',
    id: 0,
    name: '',
    avatar: '',
    avatarFileName: '',
    location: '',
    darkMode: false,
    appearanceSettings: undefined,
    interests: [],
    backgrounds: [],
  },
  artistsSidebar: [],
  totalArtworks: 0,
  followAnalytics: {
    following: {
      total: 0,
      totalArtists: 0,
      totalCollectors: 0,
    },
    followers: {
      total: 0,
      totalArtists: 0,
      totalCollectors: 0,
    },
  },
  filters: {
    years: [0],
    artistsContextIds: [
      {
        artistContextId: DEFAULT_ARTIST_ID_VALUE,
        artistTitle: '',
      },
    ],
  },
  artworksArtPrizes: [
    {
      artworkId: 0,
      prize: '',
      prizeSlug: '',
      prizeTitle: '',
    },
  ],
};

const collectorProfileSlice = createSlice({
  name: 'collectorProfile',
  initialState,
  reducers: {
    SET_COLLECTOR_DATA: (state, action: PayloadAction<collectorDataType>) => ({
      ...state,
      collectorData: { ...action.payload },
    }),
    SET_COLLECTOR_TOTAL_ARTWORKS: (state, action: PayloadAction<number>) => ({
      ...state,
      totalArtworks: action.payload,
    }),
    SET_COLLECTOR_FOLLOW_ANALYTICS: (
      state,
      action: PayloadAction<{ following: followStatsType; followers: followStatsType }>
    ) => ({
      ...state,
      followAnalytics: action.payload,
    }),
    LOAD_COLLECTOR_ARTISTS: (state, action: PayloadAction<ArtistProfileCardType[]>) => ({
      ...state,
      artistsSidebar: [...state.artistsSidebar, ...action.payload],
    }),
    RESET_COLLECTOR_ARTISTS: (state) => {
      state.artistsSidebar = [];
    },
    LOAD_COLLECTOR_YEARS_FILTER_VALUES: (state, action: PayloadAction<Pick<collectorProfileSliceType, 'filters'>>) => ({
      ...state,
      filters: {
        years: [...state.filters.years, ...action.payload.filters.years],
        artistsContextIds: [...state.filters.artistsContextIds],
      },
    }),
    LOAD_COLLECTOR_ARTISTS_FILTERS_VALUES: (
      state,
      action: PayloadAction<Pick<collectorProfileSliceType, 'filters'>>
    ) => ({
      ...state,
      filters: {
        years: [...state.filters.years],
        artistsContextIds: [...state.filters.artistsContextIds, ...action.payload.filters.artistsContextIds],
      },
    }),
    LOAD_COLLECTOR_ARTWORK_ART_PRIZES: (state, action: PayloadAction<artworksArtPrizesType[]>) => ({
      ...state,
      artworksArtPrizes: [...action.payload],
    }),
  },
});

export const useCollectorProfileSlice = () => useAppSelector((state) => state.collectorProfile);

export const collectorProfileActions = collectorProfileSlice.actions;

export default collectorProfileSlice;
