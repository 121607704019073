import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { getDefaultOrientationFilter } from './orientation.defaults';
import { OrientationEnum, OrientationFilterState } from './orientation.types';

type ToggleOrientationAction = PayloadAction<{
  orientation: OrientationEnum;
}>;
export const createToggleOrientationCaseReducer =
  <State extends OrientationFilterState>(): CaseReducer<State, ToggleOrientationAction> =>
  (state, action) => {
    const { orientation } = action.payload ?? {};

    if (orientation === OrientationEnum.ALL) {
      state.orientation = getDefaultOrientationFilter().orientation;
      return;
    }

    state.orientation[OrientationEnum.ALL] = false;

    state.orientation[orientation] = !state.orientation[orientation];

    if (!Object.values(state.orientation).includes(true)) {
      state.orientation[OrientationEnum.ALL] = true;
    }
  };
