import { Theme, makeStyles } from '@material-ui/core';

export const useRatingStarsStyles = makeStyles<
  Theme,
  {
    isDarkMode?: boolean;
  }
>(() => ({
  container: ({ isDarkMode }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    filter: isDarkMode ? 'invert(1)' : 'none',
  }),
}));
