import { useMemo } from 'react';
import { useLocation } from 'react-router';
import { CUSTOM_DOMAIN_ROUTES, ROUTES } from 'routes/_definition';
import findRouteFromPath from './findRouteFromPath';
import getMatchRouteParams from './getMatchRouteParams';

const NO_ROUTE = {
  route: null,
  pathname: '',
  params: {},
  isExact: false,
};

const getRoute = (
  pathname?: string
): {
  route: ROUTES | CUSTOM_DOMAIN_ROUTES | null;
  pathname: string;
  params: Partial<Record<string, string>>;
  isExact: boolean;
} => {
  const routes = findRouteFromPath(pathname);
  if (!routes.length) {
    return NO_ROUTE;
  }
  return getMatchRouteParams(routes[0]);
};

export default getRoute;

export const useGetRoute = (): ReturnType<typeof getRoute> => {
  const { pathname } = useLocation() ?? {};
  return useMemo(() => getRoute(pathname), [pathname]);
};
