import { IconButton } from '@material-ui/core';
import { Maybe } from 'common/schema/commonSchemaRemoteTypes';
import { CDNUrl } from 'utils/constants';
import React from 'react';
import { useCustomDomain } from 'common/hooks/useCustomDomain';
import clsx from 'clsx';
import { useFavoritesCounterButtonStyles } from 'common/features/FavoritesEngagement/FavoritesCounterButton/FavoritesCounterButtonStyle';
import { useCurrentWindowSize } from 'common/utils/windowSize';

export const FavoriteCTAPreview: React.FC<{ isFavorite?: Maybe<boolean>; noShow?: boolean; emptyIcon?: string }> = ({
  isFavorite,
  noShow,
  emptyIcon,
}) => {
  const isCustomDomain = !!useCustomDomain();
  const isXS = useCurrentWindowSize().down('xs');
  if (noShow || isCustomDomain) {
    return null;
  }

  return (
    <img
      src={isFavorite ? `${CDNUrl}/images/favoriteFilledBig.svg` : emptyIcon || `${CDNUrl}/images/favoriteEmpty.svg`}
      alt="favorites"
      width={isXS ? '26px' : '27.5px'}
      height={isXS ? '28px' : '30px'}
    />
  );
};

export const FavoriteCountCTAPreview: React.FC<{
  noShow?: boolean;
  isDarkMode?: boolean;
  artworkFavoritesCount?: number;
}> = ({ isDarkMode, noShow, artworkFavoritesCount }) => {
  const isCustomDomain = !!useCustomDomain();
  const classes = useFavoritesCounterButtonStyles({ isDarkMode: isDarkMode || false });
  if (noShow || isCustomDomain) {
    return null;
  }

  return (
    <IconButton
      className={clsx(classes.button, classes.root)}
      style={{
        backgroundColor: isDarkMode ? 'rgba(18, 17, 18, 0.9)' : 'rgba(255, 255, 255, 0.8)',
        borderRadius: '8px',
        padding: '4px 6px',
        border: isDarkMode ? '0.8px solid rgba(255, 255, 255, 0.24)' : '0.8px solid rgba(255, 255, 255, 0.6)',
      }}
    >
      <img
        className={classes.favButton}
        src={`${CDNUrl}/images/favorite/Favorite.svg`}
        alt="favorites"
        style={{ width: '28px', height: '28px', marginLeft: '4px' }}
      />
      <p className={classes.artworkFavoritesCount} style={{ margin: '0px 0px 0px 4px' }}>
        {artworkFavoritesCount}
      </p>
    </IconButton>
  );
};
