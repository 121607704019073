import { Grid } from '@material-ui/core';
import React from 'react';
import clsx from 'clsx';
import { ImageInfoLayoutProps } from './ImageInfoLayout';

export const ImageInfoLayoutMobile: React.FC<ImageInfoLayoutProps> = ({ children, hideBackgroundGradient = false }) => (
  <Grid container style={{ position: 'relative' }}>
    <Grid
      container
      style={{
        position: 'absolute',
        width: '100%',
        zIndex: 1,
        height: '100%',
        overflow: 'hidden',
      }}
      direction="column"
      justifyContent="flex-end"
      alignItems="flex-end"
    >
      <Grid
        item
        className={clsx({ 'image-info-layout-animated-gradient': hideBackgroundGradient })}
        style={{
          width: '100%',
          backgroundImage: !hideBackgroundGradient
            ? 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.48) 100%)'
            : 'none',
          borderRadius: 4,
        }}
      >
        {children[0]}
      </Grid>
    </Grid>
    <Grid item xs={12} style={{ width: '100%' }}>
      {children[1]}
    </Grid>
  </Grid>
);
