import { FooterLayout } from './FooterLayout';
import { SoloImage } from './SoloImage';
import { SoloClickableImage } from './SoloClickableImage';
import { TripleImageLayout } from './TripleImageLayout';
import TripleLinearImageLayout from './TripleLinearImageLayout';

const ArtistCardComponents = {
  FooterLayout,
  SoloImage,
  SoloClickableImage,
  TripleImageLayout,
  TripleLinearImageLayout,
};

export default ArtistCardComponents;
