import { ArtCollectedCardPreview } from 'common/components/ArtworkCard/ArtCollectedCard/ArtCollectedCardPreview';
import { ArtPrizeCardPreview } from 'common/components/ArtworkCard/ArtPrizeCard/ArtPrizeCardPreview';
import { GenericArtworkCardPreview } from 'common/components/ArtworkCard/GenericArtworkCard/GenericArtworkCardPreview';
import useArtCollectedCardPreviewProps from 'common/components/ArtworkCard/hooks/useArtCollectedCardPreviewProps';
import useGenericCardPreviewProps from 'common/components/ArtworkCard/hooks/useGenericCardPreviewProps';
import { HomeArtworksListEventPreviewType } from '../utils/homeArtworksTypes';
import HomeCardEventsEnum from '../utils/HomeCardEventsEnum';

const HomeArtworksEventPreviewCards: Record<HomeCardEventsEnum, HomeArtworksListEventPreviewType> = {
  [HomeCardEventsEnum.PUBLISH]: ({ data: { artworkId, columnCount, aspectRatio }, width, scrollSpeed }) => {
    const genericCardProps = useGenericCardPreviewProps(artworkId, width, columnCount ?? 3, scrollSpeed, aspectRatio);

    genericCardProps.isSquare = (window as any).SQUARE_CARD_OVERRIDE ?? genericCardProps.isSquare;
    return <GenericArtworkCardPreview {...genericCardProps} />;
  },
  [HomeCardEventsEnum.PRIZE]: ({ data: { artworkId, columnCount, aspectRatio }, width, scrollSpeed }) => {
    const genericCardProps = useGenericCardPreviewProps(artworkId, width, columnCount ?? 3, scrollSpeed, aspectRatio);

    genericCardProps.isSquare = (window as any).SQUARE_CARD_OVERRIDE ?? genericCardProps.isSquare;
    return <ArtPrizeCardPreview {...genericCardProps} />;
  },
  [HomeCardEventsEnum.COLLECT]: ({
    data: { artworkId, galleryContextId, columnCount, aspectRatio },
    scrollSpeed,
    width,
  }) => {
    const artCollectedCardProps = useArtCollectedCardPreviewProps(
      artworkId,
      columnCount ?? 3,
      galleryContextId,
      scrollSpeed,
      width,
      aspectRatio
    );

    artCollectedCardProps.isSquare = (window as any).SQUARE_CARD_OVERRIDE ?? artCollectedCardProps.isSquare;
    return <ArtCollectedCardPreview {...artCollectedCardProps} />;
  },
};

export default HomeArtworksEventPreviewCards;
