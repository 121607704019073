import { LinkProps } from 'react-router-dom';
import { useSkeletonImageLogic } from 'common/components/Image/SkeletonImage';
import type { LocationDescriptor } from 'history';
import { getTransitionToShowcaseState } from './showcaseTransitionConfig';

export const isHistoryPushable = (v: LinkProps['to']): v is string | LocationDescriptor<unknown> =>
  typeof v === 'string' || typeof v === 'object';

const useShowcaseLink = (
  pathname: string,
  artworkId?: number,
  fileId?: number,
  searchParams?: string
): LinkProps['to'] => {
  const state = getTransitionToShowcaseState(artworkId, fileId);
  useSkeletonImageLogic(state.showcasePreviewSrc);
  return { pathname, state, search: searchParams };
};

export default useShowcaseLink;
