import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface priceRangesState {
  rangeIds: number[];
}

const initialState: priceRangesState = {
  rangeIds: [] as number[],
};

const priceRangesSlice = createSlice({
  name: 'priceRanges',
  initialState,
  reducers: {
    SET_PRICE_RANGES: (state, action: PayloadAction<number[]>) => {
      state.rangeIds = action.payload;
    },
    RESET_PRICE_RANGES: (state) => {
      state.rangeIds = [];
    },
  },
});

export const priceRangesActions = priceRangesSlice.actions;
export default priceRangesSlice;
