import { defaultFeatureFlags, runtimeFlagUpdate } from 'common/features/FeatureFlag/config/features';

const mobileHelper = {
  isUnityWebView: null as null | boolean,
  hasAnnouncedLoadingDone: false,
  delayedAnnouncement: null as null | number,
  detect: () => {
    if (mobileHelper.isUnityWebView !== null) {
      return;
    }
    mobileHelper.isUnityWebView =
      defaultFeatureFlags.unityWebView === 'active' ||
      runtimeFlagUpdate.unityWebView ||
      window.location.hash.includes('unityWebView') ||
      false;
  },
  announce: () => {
    mobileHelper.detect();
    if (!mobileHelper.isUnityWebView || mobileHelper.hasAnnouncedLoadingDone) {
      return;
    }
    if (mobileHelper.delayedAnnouncement) {
      window.clearTimeout(mobileHelper.delayedAnnouncement);
    }
    mobileHelper.delayedAnnouncement = window.setTimeout(() => {
      mobileHelper.hasAnnouncedLoadingDone = true;
      mobileHelper.delayedAnnouncement = null;
      mobileHelper.callUnity();
    }, 250);
  },
  cancelAnnouncement: () => {
    if (mobileHelper.delayedAnnouncement) {
      window.clearTimeout(mobileHelper.delayedAnnouncement);
    }
    mobileHelper.delayedAnnouncement = null;
  },
  callUnity: () => {
    // Call the unity link to trigger the unity function
    const link = document.createElement('a');
    link.href = `uniwebview://webLoadingDone`;
    link.click();
  },
};

const loadingClassManager = {
  finishDelayMillis: 100,
  finishTimer: null as null | number,
  clearTimer: () => {
    if (!loadingClassManager.finishTimer) {
      return;
    }
    window.clearTimeout(loadingClassManager.finishTimer);
    loadingClassManager.finishTimer = null;
  },
  startTimer: (cb: () => void) => {
    loadingClassManager.clearTimer();
    loadingClassManager.finishTimer = window.setTimeout(cb, loadingClassManager.finishDelayMillis);
  },
  startLoading: () => {
    loadingClassManager.clearTimer();
    if (document.body.classList.contains('loading')) {
      return;
    }
    document.body.classList.add('loading');
  },
  finishLoading: () => {
    loadingClassManager.startTimer(() => {
      document.body.classList.remove('loading');
    });
  },
};

// This is a little bit hacky TODO: improve this
export const LOADING_STATUS = {
  idGenerator: 1,
  container: window.document.getElementById('ll-container'),
  loading: {} as Record<number, boolean>,
  isVisible: true,
  changeStatus(id: number, status?: boolean): void {
    if (this.loading[id] === (status ?? false)) {
      return;
    }

    this.loading[id] = status ?? false;
    this.isVisible = Object.values(this.loading).includes(true);

    if (this.isVisible) {
      mobileHelper.cancelAnnouncement();
      loadingClassManager.startLoading();
    } else {
      mobileHelper.announce();
      loadingClassManager.finishLoading();
    }
  },
  getId(): number {
    this.idGenerator += 1;
    return this.idGenerator;
  },
};
