import { useEffect } from 'react';
import useAppSelector from 'common/hooks/useAppSelector';
import { useGetArtworkDetailsForSwipeContainerQuery } from 'common/schema/commonSchemaRemoteGraphqlQueries';
import { useDispatch } from 'react-redux';
import { entityActions } from 'store/slices/entities';
import { useSelectedProfile } from 'common/hooks/useSelectedProfile';
import useAnalytics from 'common/features/Analytics/Analytics';
import { useTranslation } from 'react-i18next';
import { isCustomEditionType } from 'utils/utilFunctions';

const useGetArtworkDetails = (artworkId: number, skip: boolean, rightOffset?: number): void => {
  const dispatch = useDispatch();
  const userContextId = useSelectedProfile()?.contextId;
  const analytics = useAnalytics();
  const artworkDetails = useAppSelector((store) => store.entities.artworkSwipeContainerCache[artworkId ?? 0]);
  const { data } = useGetArtworkDetailsForSwipeContainerQuery({
    variables: { artworkId: artworkId ?? 0, userContextId },
    skip: skip || !artworkId || !!artworkDetails,
    fetchPolicy: 'cache-first',
  });
  const { t } = useTranslation();

  useEffect(() => {
    if (artworkDetails) {
      return;
    }
    if (data?.Artwork?.[0]) {
      const artwork = data.Artwork[0];
      analytics.postData('STARTED', 'SWIPE', 'LIVE_ARTWORK_LEFT', { artworkId: artwork.id, rightOffset });
      dispatch(entityActions.UPDATE_SWIPE_DETAILS(artwork));
    }
    if (data?.Edition?.[0]) {
      const edition = data.Edition[0];
      analytics.postData('STARTED', 'SWIPE', 'LIVE_ARTWORK_LEFT', { artworkId: edition.id, rightOffset });
      const pickedEditionSize = edition.Editions.flatMap((e) => e.sizes).reduce((min, ebs) => {
        if (!min.price) {
          return ebs;
        }
        if (!ebs.price) {
          return min;
        }
        if (min.price <= ebs.price) {
          return min;
        }
        return ebs;
      }, edition.Editions[0]?.sizes?.[0]);
      const pickedEditionType = pickedEditionSize.byTypes[0]?.type.value;

      dispatch(
        entityActions.UPDATE_SWIPE_DETAILS({
          id: edition.id,
          ar: edition.ar,
          pendingVuforiaImagePath: edition.pendingVuforiaImagePath,
          vuforiaTargetId: edition.vuforiaTargetId,
          artist: edition.artist,
          artistContextId: edition.artistContextId,
          inquires: edition.inquires,
          isAvailable: !!edition.Editions.find((e) => e.isAvailable),
          provenance: [],
          series: edition.series,
          slug: edition.slug,
          prices: edition.Editions.flatMap((e) =>
            e.sizes.map((s) => ({
              isPublic: s.isPublic,
              currency: s.currency,
              price: s.price,
            }))
          ),
          artworkDetails: {
            artType: 'Edition',
            measuringUnit: pickedEditionSize.measuringUnit,
            medium: pickedEditionSize.medium,
            title: edition.artworkDetails?.title ?? edition.Editions.map((e) => e.title).find((title) => !!title) ?? '',
            year: pickedEditionSize.year,
            discipline: {
              description: isCustomEditionType(pickedEditionType ?? '')
                ? pickedEditionType
                : t(`publish.prints.editionType.${pickedEditionType}.label`),
            },
            width: pickedEditionSize.width,
            height: pickedEditionSize.height,
            depth: pickedEditionSize.depth,
          },
          printAmounts: edition.printAmounts,
        })
      );
    }
  }, [analytics, artworkDetails, data, dispatch, t]);
};

export default useGetArtworkDetails;
