import gql from 'graphql-tag';

export const GET_ARTIST_PROFILE = gql`
  query MyQuery($id: bigint!) {
    Context_by_pk(id: $id) {
      profile {
        id
        handle
        avatar
        title
        first_name
        last_name
      }
    }
  }
`;

export const GET_ARTIST_ID_FROM_HANDLE = gql`
  query GetArtistIdFromHandle($handle: String!) {
    Profile(where: { handle: { _ilike: $handle } }) {
      id
      handle
      title
      first_name
      last_name
      context {
        id
      }
    }
  }
`;

export const GET_MY_ART = gql`
  query GetMyArt($id: bigint!) {
    Artwork(
      where: {
        artwork_to_collections: { Collection: { type: { _in: [CREATED, OWNED] }, owner_context_id: { _eq: $id } } }
      }
    ) {
      id
      slug
      primary_image_metadata_id
      artwork_to_collections {
        Collection {
          type
          owner_context_id
        }
      }
      is_public
      is_hard_minted
      is_transaction_locked
      artwork_details {
        title
      }
      transaction_histories(order_by: { id: desc }, limit: 1) {
        context_buyer_id
        status
        status_at
        id
      }
    }
  }
`;

export const GET_GALLERY_ART = gql`
  query GetArtworksOfGallery($galleryId: bigint!) {
    Collaboration(
      where: { gallery_id: { _eq: $galleryId }, gallery_status: { _eq: ACCEPTED }, artist_status: { _eq: ACCEPTED } }
    ) {
      artist_context {
        id
        profile {
          id
          title
          avatar
        }
        collections(where: { type: { _in: [CREATED, OWNED] } }) {
          type
          artwork_to_collections(where: { Artwork: { gallery_context_id: { _eq: $galleryId } } }) {
            Artwork {
              id
              slug
              is_hard_minted
              primary_image_metadata_id
              is_public
              artwork_details {
                title
              }
              is_transaction_locked
              transaction_histories(order_by: { id: desc }, limit: 1) {
                status_at
                status
                id
                context_buyer_id
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_ARTIST_ART = gql`
  query GetArtistArt($id: bigint!) {
    Artwork(
      where: { is_public: { _eq: true }, artist_context: { profile: { id: { _eq: $id } } } }
      order_by: { artwork_details: { creation_date_year: asc } }
    ) {
      id
      slug
      is_available_sale
      primary_image_metadata_id
      artwork_details {
        title
        creation_date_day
        creation_date_month
        creation_date_year
      }
      artist_context {
        id
        profile {
          title
          handle
          first_name
          last_name
        }
      }
    }
  }
`;

// collection_id: {_in: [344, 345]},
export const GET_ARTIST_FILTERED_ART2 = gql`
  query MyQuery(
    $id: bigint!
    $availability: Artwork_to_Collection_bool_exp
    $series: Artwork_to_Collection_bool_exp
    $orderBy: Artwork_to_Collection_order_by!
  ) {
    Artwork_to_Collection(
      where: { Artwork: { artist_context: { profile_id: { _eq: $id } } }, _and: [$availability, $series] }
      distinct_on: artwork_id
      order_by: [$orderBy]
    ) {
      Artwork {
        id
        slug
        is_available_sale
        primary_image_metadata_id
        artwork_details {
          title
          creation_date_day
          creation_date_month
          creation_date_year
        }
        artist_context {
          id
          profile {
            title
            handle
            first_name
            last_name
          }
        }
      }
      Collection {
        id
        name
        description
      }
    }
  }
`;

export const GET_ARTIST_FILTERED_ART = gql`
  query ProfileArtworksListing(
    $id: bigint!
    $availability: Artwork_bool_exp
    $series: Artwork_bool_exp
    $years: Artwork_bool_exp
    $orderBy: Artwork_order_by!
  ) {
    Artwork(
      where: {
        artist_context: { profile: { id: { _eq: $id } } }
        _and: [$availability, $series, $years]
        is_public: { _eq: true }
      }
      order_by: [$orderBy]
    ) {
      id
      slug
      is_available_sale
      primary_image_metadata_id
      Artwork_prices {
        price
      }
      thumbnail_metadata {
        extra
      }
      primary_image_metadata {
        extra
      }
      artwork_details {
        title
        creation_date_day
        creation_date_month
        creation_date_year
        width
        height
      }
      artist_context {
        id
        profile {
          title
          handle
          first_name
          last_name
        }
      }
    }
  }
`;

export const DELETE_ARTWORK = gql`
  mutation MyMutation($id: bigint!, $deleted_at: timestamptz!) {
    update_Artwork_by_pk(pk_columns: { id: $id }, _set: { deleted_at: $deleted_at }) {
      id
    }
  }
`;

// export const GET_MY_AVALABILE_ART = gql`
// query MyQuery($id: bigint!) {
//   Artwork(where: {artist_context_id: {_eq: $id}}) {
//     primary_image_download_link
//     id
//     is_public
//     is_hard_minted
//     artwork_details {
//       title
//     }
//   }
// }
// `

export const GET_ARTWORK_DETAILS_FOR_EDIT_BY_ID = gql`
  query MyQuery($id: bigint!) {
    Artwork_by_pk(id: $id) {
      id
      slug
      is_available_sale
      artwork_story_id
      primary_image_metadata_id
      thumbnail_metadata_id
      gallery_context_id
      gallery_context {
        profile {
          title
        }
      }
      # artist_context_id
      artist_context {
        id
        profile {
          title
        }
      }
      artwork_details_id
      artwork_details {
        art_type
        creation_date_day
        creation_date_month
        creation_date_year
        depth
        duration
        height
        materials_and_medium
        measuring_unit
        discipline_other
        discipline
        title
        tools
        width
        is_dark_mode
      }
      artwork_to_collections(
        where: { Collection: { type: { _eq: SERIES } } }
        limit: 1
        order_by: { updated_at: desc }
      ) {
        collection_id
      }
      artwork_secondary_media {
        id
        order
        file_metadata_id
      }
      artwork_story {
        story_audio_download_link
        id
        audio_metadata {
          extra
        }
        artwork_story_media {
          id
          order
          file_metadata_id
        }
        statement
      }
      Artwork_prices(limit: 1, order_by: { updated_at: desc }) {
        is_public
        price
        currency
      }
    }
  }
`;

export const GET_OWNED_ART = gql`
  query MyQuery($_eq: bigint!) {
    Collection(where: { owner_context_id: { _eq: $_eq }, type: { _eq: OWNED }, artwork_to_collections: {} }) {
      artwork_to_collections(
        where: { Artwork: { primary_image_metadata: { id: { _is_null: false } } } }
        order_by: { Artwork: { artist_context: { profile: { title: asc } } } }
      ) {
        Artwork {
          id
          slug
          is_hard_minted
          primary_image_metadata_id
          thumbnail_metadata_id
          artwork_details {
            title
          }
          artist_context {
            profile {
              id
              avatar
              title
              handle
            }
          }
        }
      }
    }
  }
`;
export const GET_COLLECTOR_TRANSFER_ARTWORK = gql`
  query MyQuery($id: bigint!) {
    Artwork(
      where: { transaction_histories: { _and: { context_seller_id: { _eq: $id }, status: { _eq: ACCEPTED } } } }
    ) {
      id
      slug
      is_hard_minted
      primary_image_metadata_id
      artwork_details {
        title
      }
      artist_context {
        profile {
          id
          avatar
          title
          handle
        }
      }
      # artwork_to_collections {
      #   Collection {
      #     type
      #     owner_context_id
      #   }
      # }
      is_public
    }
  }
`;

export const GET_COLLECTOR_EMAIL = gql`
  query MyQuery($id: bigint!) {
    All_collectors(where: { context_id: { _eq: $id } }) {
      email
    }
  }
`;
