import { createSlice } from '@reduxjs/toolkit';

type InitialStateType = {
  isOpen: boolean;
};

const initialState: InitialStateType = {
  isOpen: false,
};

export const artistSidebarV2Slice = createSlice({
  name: 'artistSidebarV2',
  initialState,
  reducers: {
    OPEN_ARTIST_SIDEBAR: (state) => {
      state.isOpen = true;
    },
    CLOSE_ARTIST_SIDEBAR: (state) => {
      state.isOpen = false;
    },
  },
});

export const { actions } = artistSidebarV2Slice;
