import { PseudoAuthStatusEnum } from 'common/schema/commonSchemaRemoteTypes';
import {
  AuthHandlerCreateAppAccountMutationFn,
  AuthHandlerRequestFirebaseCustomAuthMutationFn,
} from 'common/schema/commonSchemaRemoteGraphqlQueries';
import { externalGoogleAuth } from 'common/services/Auth/commons/mobileGoogleLogin';
import { apolloClient } from 'common/features/Apollo/clients/apolloClients';
import { store } from 'store/store';
import { PermissionTypes } from 'stacks/PermissionsModal/store/permissionsStateSlice';
import { logger } from '@old/services/logger';
import { runtimeFlagUpdate } from 'common/features/FeatureFlag/config/features';
import { isMobile } from 'react-device-detect';
import { artistProfileDetailsActions } from 'store/slices/artistProfileDetailsSlice';
import { persistentActions } from 'store/slices/persistentSlice';
import firebase from 'firebase';
import {
  AUTH_PUBLISHER,
  FirebaseEmailCreds,
  FirebaseTokenLoginCreds,
  FirebaseTokenRegisterCreds,
  SignUpResult,
} from '../../commons/types';
import { getProxyHandler } from '../../commons/utils';
import FirebaseState, { FirebaseCallbacks } from '../FirebaseHandler';
import { AccountDetails, AuthStateProps, AuthStatusEnum, DEFAULT_AUTH_STATUS } from './types';
import { FirebaseUserCredential, ThirdPartyProviderId } from '../FirebaseHandler/types';
import {
  ACTIVATE_FCM_TOKEN,
  addEncodedIntentToURL,
  FINALIZE_THIRD_PARTY_SIGN_IN,
  parseUserInfoForThirdPartyLogin,
} from './utils';

const AuthState: AuthStateProps = new Proxy(
  {
    status: DEFAULT_AUTH_STATUS,
  },
  getProxyHandler(AUTH_PUBLISHER.AUTH_HANDLER)
);

export const AuthCallbacks = {
  emailAndPasswordLogin: async (creds: FirebaseEmailCreds): Promise<void> => {
    await FirebaseCallbacks.signInWithEmailAndPassword(creds);
  },
  customTokenLogin: async (creds: FirebaseTokenLoginCreds): Promise<void> => {
    await FirebaseCallbacks.signInWithCustomToken(creds);
  },
  checkPassword: async (password: string): Promise<boolean> => {
    try {
      return await FirebaseCallbacks.checkPassword(password);
    } catch (error) {
      logger.error(error, `Error checking password.`);
      return false;
    }
  },
  resetPassword: async (email: string): Promise<void> => {
    await FirebaseCallbacks.resetPassword(email);
  },
  updateEmail: async (newEmail: string): Promise<void> => {
    await FirebaseCallbacks.updateEmail(newEmail);
  },
  updatePassword: async (newPassword: string): Promise<void> => {
    await FirebaseCallbacks.updatePassword(newPassword);
  },
  logout: async (): Promise<void> => {
    await FirebaseCallbacks.signOut();
  },
  emailAndPasswordSignUp: async (
    creds: FirebaseEmailCreds,
    account: AccountDetails,
    createAppAccount: AuthHandlerCreateAppAccountMutationFn
  ): Promise<SignUpResult> => {
    const { data, errors } = await createAppAccount({
      variables: {
        email: creds.email?.trim().toLowerCase(),
        first_name: account.firstName,
        last_name: account.lastName,
        password: creds.password,
        language: account.language,
        invitationToken: account.invitationToken,
      },
    });
    if (errors || !data) {
      throw new Error('Error creating account');
    }
    if (!data.firebaseSignUp.valid) {
      return {
        message: data.firebaseSignUp.message || '',
        result: false,
      };
    }
    await FirebaseCallbacks.signInWithEmailAndPassword(creds);
    return {
      result: true,
    };
  },
  customTokenSignUp: async (
    creds: FirebaseTokenRegisterCreds,
    requestFirebaseCustomAuth: AuthHandlerRequestFirebaseCustomAuthMutationFn
  ): Promise<SignUpResult> => {
    const { data } = await requestFirebaseCustomAuth({ variables: { ...creds } });
    return {
      result: data?.requestFirebaseCustomAuth.status === PseudoAuthStatusEnum.Ok,
      message: data?.requestFirebaseCustomAuth.message,
    };
  },
  appleSignUp: async (encodedIntent?: string): Promise<void> => {
    if (encodedIntent) {
      addEncodedIntentToURL(encodedIntent);
    }
    await FirebaseCallbacks.signInWithRedirect(ThirdPartyProviderId.APPLE);
  },
  googleSignUp: async (isUnityWebView: boolean, requestId?: string, encodedIntent?: string): Promise<void> => {
    if (isUnityWebView) {
      const result = await externalGoogleAuth(requestId, encodedIntent);
      if (!result?.token) {
        await FirebaseCallbacks.signOut();
        return;
      }
      if (result.encodedIntent) {
        addEncodedIntentToURL(result.encodedIntent);
      }
      await FirebaseCallbacks.signInWithCredential(result.token);
      return;
    }

    if (encodedIntent) {
      addEncodedIntentToURL(encodedIntent);
    }
    await FirebaseCallbacks.signInWithRedirect(ThirdPartyProviderId.GOOGLE);
  },
  finalizeThirdPartySignIn: async (userCredential: FirebaseUserCredential): Promise<void> => {
    // Used to handle Hasura part of third party sign in
    // ! This is also called from firebase.onAuthStateChanged, so no HOOKS here
    const isKaleidoAR =
      isMobile && (runtimeFlagUpdate.unityWebView || window.location.toString().includes('unityWebView'));
    const providerId = userCredential.credential?.providerId;
    const parsedUserInfo = parseUserInfoForThirdPartyLogin(userCredential, providerId);
    if (!parsedUserInfo) {
      throw new Error('Error! Missing user information from third party provider');
    }
    const { firstName, lastName, email, language, profilePicture } = parsedUserInfo;

    store.dispatch(persistentActions.SET_PROPERTY({ registerProfileUser: { firstName, lastName, email } }));

    if (profilePicture) {
      store.dispatch(artistProfileDetailsActions.SET_GOOGLE_PROFILE_PHOTO(profilePicture));
    }
    const result = await apolloClient.mutate({
      mutation: FINALIZE_THIRD_PARTY_SIGN_IN,
      variables: {
        providerId,
        firstName,
        lastName,
        email,
        preferredLanguage: language,
      },
    });
    if (result.errors || !result.data?.thirdPartySignIn.valid) {
      FirebaseCallbacks.signOut();
      throw new Error('Error finalizing third party sign in');
    }
    /**
     * This seems a bit overkill, but it's the only way to do this for (Google/Apple) signup on mobile app
     */

    if (isKaleidoAR) {
      const firebaseUser = firebase.auth().currentUser;
      if (firebaseUser) {
        await firebaseUser.reload();
        await firebaseUser.getIdTokenResult(true);
        FirebaseState.account = firebaseUser;
      }

      const { deviceId } = store.getState().permissionsState[PermissionTypes.Notifications];
      await apolloClient
        .mutate({
          mutation: ACTIVATE_FCM_TOKEN,
          variables: {
            platform: deviceId,
          },
        })
        .then((tokenActivationResult) => {
          if (!tokenActivationResult.data?.activateMyFCMToken) {
            logger.info(`No FCM token found for device id: ${deviceId}.`);
          }
        })
        .catch((error) => {
          logger.error(
            error,
            `Error activating FCM token on login, for profile ${FirebaseState.firebaseUserId}, device id: ${deviceId}.`
          );
        });
    }
  },
};

// This will be called whenever we want to update the auth state
// This should be the only place where we update the auth state, the rest of the app should only read from it
// Ideally we call this in two places, one in firebase and one in profile.
export const updateAuthState = (): void => {
  let status = AuthStatusEnum.LOGGED_OUT;
  const accountSlice = store.getState().account;
  const { profileProviderFinished, profiles } = accountSlice;

  if (FirebaseState.account && !profileProviderFinished) {
    status = AuthStatusEnum.AUTHENTICATED;
  }

  if (FirebaseState.account && profileProviderFinished) {
    if (profiles.length > 0) {
      status = AuthStatusEnum.LOGGED_IN;
    } else {
      status = AuthStatusEnum.REGISTERED;
    }
  }
  AuthState.status = status;
};

export default AuthState;
