import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface PersistentState {
  collectorInvitationToken?: string;
  showLocationDialog?: boolean;
  registerCountry?: string;
  registerProfileType?: string;
  registerProfileUser?: {
    firstName: string;
    lastName: string;
    email: string;
  };
}

const initialState: PersistentState = {};

const persistentSlice = createSlice({
  name: 'persistentSlice',
  initialState,
  reducers: {
    SET_PROPERTY: (state, action: PayloadAction<Partial<PersistentState>>) => ({
      ...state,
      ...action.payload,
    }),
  },
});

export const persistentActions = persistentSlice.actions;
export default persistentSlice;
