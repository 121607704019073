import { createSlice } from '@reduxjs/toolkit';
// @ts-expect-error
import { RecordState } from 'audio-react-recorder';
import useMediaStreamPermission, { MediaStreamErrors } from 'common/hooks/useMediaStreamPermission';
import _ from 'lodash';
import React, { Dispatch, Reducer, ReducerAction, useContext, useEffect, useReducer } from 'react';
import { useDispatch } from 'react-redux';
import { publishActions } from 'store/slices/publishSlice';

export enum RecordPluginState {
  START = RecordState.START,
  PAUSE = RecordState.PAUSE,
  STOP = RecordState.STOP,
  NONE = RecordState.NONE,
}

export type AudioRecorderContextState = {
  recordState: RecordPluginState;
  audio: string | null;
  audioTime: number;
  permissionsError: MediaStreamErrors | null;
  micPermission: 'UNKNOWN' | 'CHECKING' | 'CHECKED' | 'ALLOWED' | 'DENIED';
  widgetStatus: 'RECORDER' | 'PREVIEWER' | 'PLAYER';
};

const initialAudioRecorderContextState: AudioRecorderContextState = {
  recordState: RecordState.NONE,
  widgetStatus: 'RECORDER',
  audio: '',
  audioTime: 0,
  permissionsError: null,
  micPermission: 'UNKNOWN',
};

type AudioRecorderContextType = {
  dispatch: Dispatch<ReducerAction<any>>;
  state: AudioRecorderContextState;
  actions: typeof audioRecorderSlice.actions;
};

const defaultContext: AudioRecorderContextType = {
  dispatch: () => undefined,
  state: initialAudioRecorderContextState,
  actions: null as any, // todo : fix this EVIL !!
};

export const AudioRecorderContext = React.createContext<AudioRecorderContextType>(defaultContext);
export const useAudioRecorder = (): AudioRecorderContextType => useContext(AudioRecorderContext);

const audioRecorderSlice = createSlice({
  name: 'audioRecorderContext',
  initialState: initialAudioRecorderContextState,
  reducers: {
    deleteRecording: (state) => {
      state.audio = null;
      state.recordState = RecordPluginState.NONE;
      state.widgetStatus = 'RECORDER';
      state.audioTime = 0;
    },
    resetRecording: (state) => {
      state.audio = null;
      state.recordState = RecordPluginState.NONE;
      state.widgetStatus = 'RECORDER';
      state.audioTime = 0;
      state.micPermission = 'UNKNOWN';
    },
    startRecording: (state) => {
      if (state.micPermission === 'ALLOWED') {
        state.micPermission = 'CHECKED';
      } else {
        state.micPermission = 'CHECKING';
      }
    },
    _internalStartRecording: (state) => {
      state.recordState = RecordPluginState.START;
      state.micPermission = 'ALLOWED';
    },
    _internalFailedMic: (state) => {
      state.recordState = RecordPluginState.NONE;
      state.micPermission = 'DENIED';
    },
    _internalPermissionsError: (state, action) => {
      state.permissionsError = action.payload;
    },
    pauseRecording: (state) => {
      state.recordState = RecordPluginState.PAUSE;
    },
    stopRecording: (state) => {
      state.recordState = RecordPluginState.STOP;
    },
    previewRecording: (state, action) => {
      state.widgetStatus = 'PREVIEWER';
      state.audio = action.payload;
    },
    acceptRecording: (state, action) => {
      state.widgetStatus = 'PLAYER';
      if (action.payload) {
        action.payload(state.audio);
      }
    },
    setAudioTime: (state, action) => {
      state.audioTime = action.payload;
    },
    uploadRecording: (state, action) => {
      state.audio = URL.createObjectURL(action.payload);
      state.recordState = RecordPluginState.STOP;
      state.widgetStatus = 'PREVIEWER';
    },
    setDefaultAudio: (state, action) => {
      state.audio = action.payload;
      state.recordState = RecordPluginState.STOP;
      state.widgetStatus = 'PLAYER';
    },
  },
});

type AudioRecorderProviderProps = {
  initialAudio: string | null;
};
export const AudioRecorderProvider: React.FC<AudioRecorderProviderProps> = ({ children, initialAudio }) => {
  const { stream, checkPermission, error: permissionsError } = useMediaStreamPermission();

  const [{ audio, audioTime, micPermission, recordState, widgetStatus }, contextDispatch] = useReducer<
    Reducer<AudioRecorderContextState, any>
  >(audioRecorderSlice.reducer, initialAudioRecorderContextState);

  const globalDispatch = useDispatch();
  useEffect(() => {
    globalDispatch(publishActions.SET_RECORDING_STATE({ audioRecordingState: recordState }));
  }, [recordState]);

  useEffect(() => {
    if (micPermission !== 'CHECKING' && micPermission !== 'CHECKED') {
      return;
    }
    if (!stream) {
      checkPermission(
        () => contextDispatch(audioRecorderSlice.actions._internalStartRecording()),
        () => contextDispatch(audioRecorderSlice.actions._internalFailedMic())
      );
    } else {
      contextDispatch(audioRecorderSlice.actions._internalStartRecording());
    }
  }, [micPermission]);

  useEffect(() => {
    if (initialAudio) {
      contextDispatch(audioRecorderSlice.actions.setDefaultAudio(initialAudio));
    }
  }, [initialAudio]);

  useEffect(() => {
    if (permissionsError) {
      contextDispatch(audioRecorderSlice.actions._internalPermissionsError(permissionsError));
    }
  }, [permissionsError]);

  const AudioRecorderProviderValues: AudioRecorderContextType = {
    state: {
      audio,
      audioTime,
      recordState,
      widgetStatus,
      micPermission,
      permissionsError,
    },
    dispatch: contextDispatch,
    actions: audioRecorderSlice.actions,
  };

  return (
    <AudioRecorderContext.Provider value={AudioRecorderProviderValues}>
      {!!audioRecorderSlice.actions && children}
    </AudioRecorderContext.Provider>
  );
};
