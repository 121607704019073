import { TypeGuard } from 'common/utils/types';
import { SortOptionsEnum } from '../VirtualList.config';
import { defaultListState, getDefaultListState, ListState } from './listState';

export const filterHashResetMarker = 'resetMarker';
export const filtersMarker = 'filters';

export type ListingIdentifier<
  SortSubSet extends SortOptionsEnum,
  FiltersHash extends string = string
> = `${SortSubSet}_${FiltersHash}`;

export type ListReduxFragment<SortSubset extends SortOptionsEnum, DataType> = {
  currentSort: SortSubset;
  identifier: ListingIdentifier<SortSubset>;
  data: ListState<DataType>;
};
export const generateListReduxFragmentTypeGuard =
  <SortSubset extends SortOptionsEnum, DataType>(
    possibleValuesCheck: (castedValues: ListReduxFragment<SortSubset, DataType>) => boolean
  ): TypeGuard<ListReduxFragment<SortSubset, DataType>> =>
  (possibleListReduxFragment): possibleListReduxFragment is ListReduxFragment<SortSubset, DataType> => {
    if (!possibleListReduxFragment) {
      return false;
    }
    return possibleValuesCheck(possibleListReduxFragment as ListReduxFragment<SortSubset, DataType>);
  };
export const defaultListReduxFragment: ListReduxFragment<SortOptionsEnum, unknown> = {
  currentSort: SortOptionsEnum._EXHAUSTIVE_OPTION,
  identifier: `${SortOptionsEnum._EXHAUSTIVE_OPTION}_denada` as const,
  data: { ...defaultListState },
};

type DefaultProps<SortSubset extends SortOptionsEnum, DataType> = {
  currentSort: SortSubset;
  filtersHash: string;
  listedBatchSize: number;
  detailedBatchSize: number;
  backwardCursor: DataType;
  forwardCursor: DataType;
};

export const getDefaultListReduxFragment = <SortSubset extends SortOptionsEnum, DataType>({
  currentSort,
  filtersHash,
  backwardCursor,
  forwardCursor,
  listedBatchSize = 32,
  detailedBatchSize = 128,
}: DefaultProps<SortSubset, DataType>): ListReduxFragment<SortSubset, DataType> => ({
  ...defaultListReduxFragment,
  currentSort,
  identifier: `${currentSort}_${filtersHash}` as const,
  data: getDefaultListState<DataType>({ listedBatchSize, detailedBatchSize, backwardCursor, forwardCursor }),
});
