import { Scalars } from 'common/schema/commonSchemaRemoteTypes';
import { FollowersSort } from './filter.types';

export type FollowersFiltersHash = `${FollowersSort}_${string}`;

export const listingDirections = ['BACKWARD', 'FORWARD'] as const;
export type ListDirection = (typeof listingDirections)[0] | (typeof listingDirections)[1];

export const loadingIdentifier = 'LOADING' as const;
export type ListLoadingId = typeof loadingIdentifier;

export type ListLoaderStatus = 'COLD' | 'FINISHED' | 'IDLE' | `${ListLoadingId}_${ListDirection}`;

export type ListWindowLoaderStateType<ValuesType, StatusType> = {
  batchSize: number;
  values: Array<ValuesType>;
  status: StatusType;
  hasMore: [boolean, boolean]; // [backward, forward]
};

export type ListLoaderStateType<Typename extends FollowersSort, CursorType, IdType = Scalars['bigint']> = {
  __typename: Typename;
  listed: ListWindowLoaderStateType<CursorType, ListLoaderStatus>;
  detailed: ListWindowLoaderStateType<IdType, ListLoaderStatus>;
  cursor: [CursorType, CursorType]; // [backward, forward]
  detailedToListedStartOffset: number;
  lastScrollPosition: number;
};

export type FollowersListByDateCursor = {
  contextId: Scalars['bigint'];
  appearedAt: Scalars['date'];
};
export type FollowersListByDate = ListLoaderStateType<'byDate', FollowersListByDateCursor>;

export type ListActionArgs = [
  ListDirection,
  FollowersListByDate['__typename'],
  FollowersListByDate['listed']['values']
];

export type DetailActionArgs = [
  ListDirection,
  FollowersListByDate['__typename'],
  FollowersListByDate['detailed']['values']
];

export type FollowersListState = {
  identifier: FollowersFiltersHash;
  type: FollowersSort;
} & {
  type: 'byDate';
  data: FollowersListByDate;
};

export type FollowersListingCursor = FollowersListByDateCursor;

export const isFollowersListByDateCursor = (cursor: FollowersListingCursor): cursor is FollowersListByDateCursor =>
  (cursor as FollowersListByDateCursor)?.appearedAt !== undefined;

export const LOADING_STATES: ListLoaderStatus[] = ['LOADING_BACKWARD', 'LOADING_FORWARD'];
