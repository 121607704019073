import { makeStyles } from '@material-ui/core';

export const useAvailabilityDotStyles = makeStyles((theme) => ({
  container: {
    borderRadius: '50%',
    marginRight: theme.spacing(1.25),
    display: 'inline-block',
  },
  smallDot: {
    height: theme.spacing(1.5),
    width: theme.spacing(1.5),
    minHeight: theme.spacing(1.5),
    minWidth: theme.spacing(1.5),
  },
  mediumDot: {
    height: theme.spacing(1.75),
    width: theme.spacing(1.75),
    minHeight: theme.spacing(1.75),
    minWidth: theme.spacing(1.75),
  },
  largeDot: {
    height: theme.spacing(2.25),
    width: theme.spacing(2.25),
    minHeight: theme.spacing(2.25),
    minWidth: theme.spacing(2.25),
  },
}));
