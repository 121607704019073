import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createToggleArtistContextIdCaseReducer } from 'common/features/Filters/predefined/ArtistContextId/store/artistContextId.reducers';
import {
  createSelectDateCaseReducer,
  SelectDateAction,
} from 'common/features/Filters/predefined/Date/store/date.reducers';
import { createRefreshFilterCaseReducer } from 'common/features/Filters/predefined/Refresh/refresh.reducers';
import {
  createSelectArtworkIdsCaseReducer,
  SelectArtworkIdsAction,
} from 'common/features/Filters/predefined/Artworks/store/artworks.reducers';
import { filterInitialState, getDefaultFilters } from './filter.defaults';
import { HomeArtworksFiltersState } from './filter.types';

const sinceDateCaseReducer = createSelectDateCaseReducer<HomeArtworksFiltersState['since']>();
const untilDateCaseReducer = createSelectDateCaseReducer<HomeArtworksFiltersState['until']>();
const artworkIdsCaseReducer = createSelectArtworkIdsCaseReducer<HomeArtworksFiltersState['artworks']>();

export const homeArtworksFiltersSlice = createSlice({
  name: 'filter',
  initialState: filterInitialState,
  reducers: {
    RESET_HomeArtworks: getDefaultFilters,
    SORT_HomeArtworks: (state, action: PayloadAction<HomeArtworksFiltersState['sort']>) => ({
      ...state,
      sort: action.payload,
    }),
    REFRESH_HomeArtworks: createRefreshFilterCaseReducer<HomeArtworksFiltersState>(),
    ARTIST_CONTEXT_ID_HomeArtworks: createToggleArtistContextIdCaseReducer<HomeArtworksFiltersState>(),
    SELECT_SINCE_DATE_HomeArtworks: (state, action: SelectDateAction) => {
      const since = { ...state.since };
      sinceDateCaseReducer(since, action);
      return {
        ...state,
        since,
      };
    },
    SELECT_UNTIL_DATE_HomeArtworks: (state, action: SelectDateAction) => {
      const until = { ...state.until };
      untilDateCaseReducer(until, action);
      return {
        ...state,
        until,
      };
    },
    SELECT_SPECIFIC_HomeArtworks: (state, action: SelectArtworkIdsAction) => {
      const artworks = { ...state.artworks };
      artworkIdsCaseReducer(artworks, action);
      return {
        ...state,
        artworks,
      };
    },
  },
});
