import { combineReducers } from '@reduxjs/toolkit';
import { listArtShowArtworksSlice } from './listArtShowArtworks.slice';
import { filterArtShowArtworksSlice } from './filterArtShowArtworks.slice';
import { displayArtShowArtworksSlice } from './displayArtShowArtworks.slice';

export const actions = {
  ...listArtShowArtworksSlice.actions,
  ...filterArtShowArtworksSlice.actions,
  ...displayArtShowArtworksSlice.actions,
};

export const artShowArtworksSlice = {
  artShowArtworks: combineReducers({
    [listArtShowArtworksSlice.name]: listArtShowArtworksSlice.reducer,
    [filterArtShowArtworksSlice.name]: filterArtShowArtworksSlice.reducer,
    [displayArtShowArtworksSlice.name]: displayArtShowArtworksSlice.reducer,
  }),
};
