import safelyStringify from 'fast-safe-stringify';
import { pageHitIgnoredPaths } from './analyticsConstants';
import { AnalyticsBody } from './analyticsTypes';

export const shouldExcludeAnalytic = (data: AnalyticsBody): boolean => {
  let isExcluded = false;

  if (
    data.hitType === 'SUCCESS' &&
    data.eventCategory === 'PAGE' &&
    data.eventAction === 'LOCATION_URL' &&
    data.tags.url &&
    pageHitIgnoredPaths.includes(String(data.tags.url))
  ) {
    isExcluded = true;
  }

  return isExcluded;
};

// Serializes an object to a JSON string, handling circular references by discarding duplicate object values through a replacer function with a cache array.
export const safeStringify = (obj: unknown, indent = 0): string => {
  try {
    return safelyStringify(obj, undefined, indent, { depthLimit: 20, edgesLimit: 10000 });
  } catch (e) {
    return JSON.stringify({ error: e.message || 'unknown error' });
  }
};

export const isMobile = () => {
  const toMatch = [/Android/i, /webOS/i, /iPhone/i, /iPad/i, /iPod/i, /BlackBerry/i, /Windows Phone/i];

  return toMatch.some((toMatchItem) => navigator.userAgent.match(toMatchItem));
};
