import { Grid } from '@material-ui/core';
import React from 'react';
import { BasicImageType } from '../../Image/types';

export type ShowcaseReelImageLayoutProps = {
  children: React.ReactElement<BasicImageType>;
  displayBorder?: boolean;
};

export const ShowcaseReelImageLayout: React.FC<ShowcaseReelImageLayoutProps> = ({
  children,
  displayBorder = false,
}) => (
  <Grid
    container
    style={{
      padding: displayBorder ? 4 : 0,
      border: displayBorder ? '1px solid black' : 'none',
      borderRadius: 4,
      boxSizing: 'border-box',
    }}
  >
    {children}
  </Grid>
);
