import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Enum_Language_Preference_Enum, Enum_Pseudo_User_Action_Enum } from 'common/schema/commonSchemaRemoteTypes';
import { ArtShowcaseType } from 'stacks/ShowcaseNew/context/ShowcaseProvider/utils/types';

export enum UserTrapView {
  VALIDATE_EMAIL = 'VALIDATE_EMAIL',
  LOGIN = 'LOGIN',
  VERIFICATION = 'VERIFICATION',
  LOGGED_IN = 'LOGGED_IN',
  SIMPLE_CONTENT = 'SIMPLE_CONTENT',
  INQUIRE_EMAIL_SENT = 'INQUIRE_EMAIL_SENT',
  CONTACT_EMAIL_SENT = 'CONTACT_EMAIL_SENT',
}

export enum TimedUserTrapState {
  NOT_TRIGGERED = 'NOT_TRIGGERED',
  SHOULD_TRIGGER = 'SHOULD_TRIGGER',
  SHOULD_NOT_TRIGGER = 'SHOULD_NOT_TRIGGER',
  TRIGGERED = 'TRIGGERED',
}

export type UserTrapData = {
  artworkId?: number;
  artworkTitle?: string;
  thumbnailMetadataId?: number;
  price?: string;
  isPricePublic?: boolean;
  discipline?: string;
  year?: string;
  artistTitle?: string;
  size?: string;
  availability?: 'ARTWORK' | 'PRINTS' | 'ARTWORK_AND_PRINTS';
  profilePhoto?: string;
  location?: string;
  artistContextId?: number;
  artworkExploreImages?: string[];
} & Partial<Pick<ArtShowcaseType, 'availablePrint'>>;

export interface UserTrapState {
  open: boolean;
  timedUserTrapState: TimedUserTrapState;
  currentAction: Enum_Pseudo_User_Action_Enum | null;
  currentView: UserTrapView;
  email: string;
  firstName: string;
  preferredLanguage: Enum_Language_Preference_Enum | null;
  message: string;
  token: number;
  userTrapData: UserTrapData | null;
}

const initialState: UserTrapState = {
  open: false,
  timedUserTrapState: TimedUserTrapState.NOT_TRIGGERED,
  currentAction: null,
  currentView: UserTrapView.VALIDATE_EMAIL,
  email: '',
  firstName: '',
  preferredLanguage: null,
  message: '',
  token: 0,
  userTrapData: null,
};

const userTrapSlice = createSlice({
  name: 'userTrap',
  initialState,
  reducers: {
    RESET: (state) => {
      state.open = false;
      state.currentAction = null;
      state.currentView = UserTrapView.VALIDATE_EMAIL;
      state.email = '';
      state.firstName = '';
      state.token = 0;
      state.preferredLanguage = null;
      state.message = '';
    },
    OPEN: (state, action: PayloadAction<Enum_Pseudo_User_Action_Enum | null>) => {
      state.open = true;
      state.currentAction = action.payload;
    },
    CLOSE: (state) => {
      state.open = false;
    },
    SET_CURRENT_VIEW: (state, action: PayloadAction<UserTrapView>) => {
      state.currentView = action.payload;
    },
    SET_EMAIL: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    SET_CODE_VALIDATION: (state, action: PayloadAction<number>) => {
      state.token = action.payload;
    },
    SET_INQUIRE_SUCCESS: (
      state,
      action: PayloadAction<{
        currentView: UserTrapView;
        email: string;
        firstName: string;
        preferredLanguage: Enum_Language_Preference_Enum | null;
        token?: number;
      }>
    ) => {
      state.currentView = action.payload.currentView;
      state.email = action.payload.email;
      state.firstName = action.payload.firstName;
      state.preferredLanguage = action.payload.preferredLanguage;
      if (action.payload.token !== undefined) {
        state.token = action.payload.token;
      }
    },
    SET_ARTIST_CONTACTED_SUCCESS: (
      state,
      action: PayloadAction<{
        currentView: UserTrapView;
      }>
    ) => {
      state.currentView = action.payload.currentView;
    },
    SET_VERIFICATION_REDIRECT: (
      state,
      action: PayloadAction<{
        email: string;
        firstName: string;
        preferredLanguage: Enum_Language_Preference_Enum | null;
        message?: string;
      }>
    ) => {
      state.currentView = UserTrapView.VERIFICATION;
      state.email = action.payload.email;
      state.firstName = action.payload.firstName;
      state.preferredLanguage = action.payload.preferredLanguage;
      state.message = action.payload.message ?? '';
    },
    SET_LOGIN_REDIRECT: (
      state,
      action: PayloadAction<{
        email: string;
        firstName: string;
        preferredLanguage: Enum_Language_Preference_Enum | null;
      }>
    ) => {
      state.currentView = UserTrapView.LOGIN;
      state.email = action.payload.email;
      state.firstName = action.payload.firstName;
      state.preferredLanguage = action.payload.preferredLanguage;
    },
    SET_USER_TRAP_DATA: (
      state,
      action: PayloadAction<{
        data: UserTrapData;
      }>
    ) => {
      state.userTrapData = {
        ...state.userTrapData,
        ...action.payload.data,
      };
    },
    SET_TIMED_USER_TRAP_STATE: (state, action: PayloadAction<TimedUserTrapState>) => {
      state.timedUserTrapState = action.payload;
    },
  },
});

export const userTrapActions = userTrapSlice.actions;
export default userTrapSlice;
