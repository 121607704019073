import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createSelectCountryCaseReducer } from 'common/features/Filters/predefined/Country/store/country.reducers';
import { createToggleInterestCaseReducer } from 'common/features/Filters/predefined/Interests/store/interests.reducers';
import { filterInitialState, getDefaultFilters } from './filter.defaults';
import { FollowersFiltersState } from './filter.types';

export const followersFiltersSlice = createSlice({
  name: 'filter',
  initialState: filterInitialState,
  reducers: {
    RESET_Followers: getDefaultFilters,
    SORT_Followers: (state, action: PayloadAction<FollowersFiltersState['sort']>) => ({
      ...state,
      sort: action.payload,
    }),
    TOGGLE_INTEREST_Followers: createToggleInterestCaseReducer<FollowersFiltersState>(),
    CHANGE_COUNTRY_Followers: createSelectCountryCaseReducer<FollowersFiltersState>(),
  },
});
