import { useEffect, useState } from 'react';

export const useIsMyProfile = (contextId: number, artistContextId: number): boolean => {
  const [isMyProfile, setIsMyProfile] = useState(false);

  useEffect(() => {
    if (artistContextId === contextId) setIsMyProfile(true);
    else setIsMyProfile(false);
  }, [artistContextId]);

  return isMyProfile;
};
