import { GetProvenanceByArtworkIdQuery } from 'common/schema/commonSchemaRemoteOperationTypes';
import { DEFAULT_MEASURE_UNIT, orderedSizes } from 'common/utils/constants';
import { ArtworkMeasureUnit } from 'common/utils/types';
import { t } from 'i18next';
import { sortEditionTypes } from 'stacks/Dashboard/common/ArtworkPublish/components/PublishPrints/components/PrintsEdition/PrintsEditionTypes/hooks/useEditionTypes';
import { getCDNImageUrl } from 'utils/CDNImages';
import { compareByArrayOrder, getArtworkProfileTitle, getContextLocation, isSomeEnum } from 'utils/utilFunctions';
import {
  mapArtworkAITagsWithLanguage,
  sortAsTagsLibraryOrder,
} from 'stacks/Dashboard/common/ArtworkPublish/components/PublishArtwork/utils/publishArtworkUtils';
import { AIArtworkTagsUI } from 'common/components/TagsLibraryDialog/utils/types';
import { Enum_Language_Preference_Enum } from 'common/schema/commonSchemaRemoteTypes';
import { hasARExperience } from '../../ShowcaseProvider/utils/helpers';
import {
  doesArtworkHaveCollector,
  getArtworksWithinSeries,
  getBestArtPrize,
  getCurrentCollector,
  getPartnerGalleryInformation,
  getShowcaseSecondaryTags,
  mapEditionsData,
  mapProvenanceData,
} from './helpers';
import { defaultShowcaseDetails, ShowcaseDetailsQueryType, ShowcaseDetailsType } from './types';

export const showcaseDetailsMapper = (
  showcaseDetails?: ShowcaseDetailsQueryType,
  provenanceData?: GetProvenanceByArtworkIdQuery['getArtworkEventByArtworkId'],
  tagsLanguage: Enum_Language_Preference_Enum = Enum_Language_Preference_Enum.English,
  tagsLibrary?: AIArtworkTagsUI
): ShowcaseDetailsType => {
  if (!showcaseDetails) {
    return defaultShowcaseDetails;
  }
  const artworkDetails = showcaseDetails?.artwork_details;
  const artPrizes =
    showcaseDetails.art_prizes?.map((prize) => ({
      artPrizeTitle: prize.Art_prize_winner.Art_prize_show.title || '',
      prizeSlug: prize.Art_prize_winner.Art_prize_show.prize_show_slug || '',
      place: prize.Art_prize_winner.position_type,
    })) || [];

  const userSelectedAITags: string[] = [];
  const rawUserSelectedTags = showcaseDetails.Artwork_ai_tags?.[0]?.user_selected_image_tags;
  if (tagsLibrary && rawUserSelectedTags) {
    const sortedTagsWithLibray = sortAsTagsLibraryOrder(
      tagsLibrary,
      showcaseDetails.Artwork_ai_tags?.[0]?.user_selected_image_tags
    );

    const userLanguageSelectedTags = mapArtworkAITagsWithLanguage(sortedTagsWithLibray, tagsLanguage, true);
    const sortedByMostUsedTags = Object.values(userLanguageSelectedTags)
      .flatMap((item) => item.tagList.map((tag) => ({ id: tag.id, name: tag.name })))
      .sort((a, b) => a.id - b.id)
      .map((tag) => tag.name);

    userSelectedAITags.push(...sortedByMostUsedTags);
  }

  return {
    artworkTab: {
      detailsId: artworkDetails?.id || 0,
      artworkTitle: artworkDetails?.title ?? '',
      artworkStatement: showcaseDetails?.artwork_story?.statement ?? '',
      seriesName: showcaseDetails.artwork_to_collections?.[0]?.Collection?.name ?? '',
      seriesArtworks: getArtworksWithinSeries(
        showcaseDetails.artwork_to_collections?.[0]?.Collection?.artwork_to_collections
      ).filter((artwork) => artwork.id !== showcaseDetails?.id),
      details: {
        hasAR: hasARExperience(artworkDetails?.ENUM_artwork_discipline?.description, showcaseDetails?.vuforia_rating),
        vuforiaRating: showcaseDetails?.vuforia_rating ?? 0,
        vuforiaTargetId: showcaseDetails?.target_id ?? '',
        pendingVuforiaImagePath: showcaseDetails?.pending_vuforia_image_path ?? '',
        creationDate: {
          day: showcaseDetails.artwork_details?.creation_date_day ?? undefined,
          month: showcaseDetails.artwork_details?.creation_date_month ?? undefined,
          year: showcaseDetails.artwork_details?.creation_date_year ?? undefined,
        },
        dimensions: {
          width: artworkDetails?.width ? Math.round(artworkDetails?.width * 10) / 10 : 0,
          height: artworkDetails?.height ? Math.round(artworkDetails?.height * 10) / 10 : 0,
          depth: artworkDetails?.depth ? Math.round(artworkDetails?.depth * 10) / 10 : 0,
          measuringUnit: showcaseDetails.artwork_details?.measuring_unit ?? '',
        },
        discipline:
          t(
            `addArtworkStrings.artDetails.select.discipline.${showcaseDetails.artwork_details?.ENUM_artwork_discipline?.description}`
          ) ?? '',
        medium: showcaseDetails.artwork_details?.materials_and_medium ?? '',
        blockchain_enum: showcaseDetails.artwork_details?.ENUM_blockchain ?? undefined,
        blockchain: showcaseDetails.artwork_details?.blockchain ?? undefined,
        blockchain_other: showcaseDetails.artwork_details?.blockchain_other ?? '',
        nft_url: showcaseDetails.artwork_details?.nft_url ?? '',
        nft_copies: showcaseDetails.artwork_details?.nft_copies ?? undefined,
        art_type: showcaseDetails.artwork_details?.art_type?.toUpperCase() ?? '', // old art types - "Physical" instead of "PHYSICAL"
      },
      hasCollector: doesArtworkHaveCollector(showcaseDetails.transaction_histories),
      hasPartnerGallery: showcaseDetails.gallery_context_id !== null,
      seriesStatement: showcaseDetails.artwork_to_collections?.[0]?.Collection?.description ?? '',
      primaryTag: {
        name: showcaseDetails.primary_tag?.[0]?.tag?.name,
        description: showcaseDetails.primary_tag?.[0]?.tag?.description,
      },
      secondaryTags: getShowcaseSecondaryTags(showcaseDetails.secondary_tags),
      aiTags: userSelectedAITags,
      location: artworkDetails?.location,
      locationAddress: artworkDetails?.location_address || undefined,
      locationMapId: artworkDetails?.location_map_id || undefined,
      collector: getCurrentCollector(showcaseDetails.transaction_histories),
      gallery: getPartnerGalleryInformation(showcaseDetails.gallery_context),
    },
    artistTab: {
      artistStatement: showcaseDetails.artist_context?.profile?.statement ?? '',
      artistName: getArtworkProfileTitle(showcaseDetails),
      artistImage: getCDNImageUrl({
        target: 'Profile',
        purpose: 'Avatar',
        targetId: showcaseDetails.artist_context.profile?.id ?? 0,
        fileId: showcaseDetails.artist_context?.profile?.avatar ?? 0,
        meta: { size: 'large' },
      }),
      artistLocation: getContextLocation(showcaseDetails.artist_context as any),
    },
    editionsTab: mapEditionsData(showcaseDetails),
    provenanceTab: {
      provenanceData: mapProvenanceData(showcaseDetails.id, provenanceData),
      editionSizes: showcaseDetails.editions.reduce((sizes, edition) => {
        const editionSizes = sizes ?? [];
        edition.Edition_By_Sizes.slice()
          .sort((a, b) => compareByArrayOrder(orderedSizes)(a.print_size, b.print_size))
          .forEach((ebs) => {
            editionSizes.push({
              editionId: edition.id,
              size: ebs.print_size,
              width: ebs.width ?? 0,
              height: ebs.height ?? 0,
              measuringUnit: isSomeEnum(ArtworkMeasureUnit)(ebs.measuring_unit)
                ? ebs.measuring_unit
                : DEFAULT_MEASURE_UNIT,
              editionTypes: ebs.Edition_By_Types.slice()
                .sort((a, b) => sortEditionTypes(a.Edition_Type.value, b.Edition_Type.value))
                .map((ebt) => ({
                  id: ebt.id,
                  type: ebt.Edition_Type.value,
                  printsAmount: ebt.print_amount ?? 0,
                })),
              createdOn: {
                year: ebs.year ?? new Date().getFullYear(),
                month: ebs.month ?? undefined,
                day: ebs.day ?? undefined,
              },
            });
          });
        return editionSizes;
      }, [] as ShowcaseDetailsType['provenanceTab']['editionSizes']),
    },
    artPrizes,
    bestArtPrize: getBestArtPrize(artPrizes),
  };
};
