import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Enum_Pseudo_User_Action_Enum } from 'common/schema/commonSchemaRemoteTypes';

interface ActionPayload {
  artist?: {
    title?: string;
    photo?: string;
    location?: string;
    handle?: string;
    artworkExploreImages?: string[];
  };
  artwork?: {
    id?: number;
    title?: string;
    primaryImageId?: number;
    thumbnailId?: number;
    discipline?: string;
    isPricePublic?: boolean;
    width?: number;
    height?: number;
    measuringUnit?: string;
    price?: string;
    materials?: string;
  };
}

interface ActionData {
  action: Enum_Pseudo_User_Action_Enum;
  actionId: number;
}

export interface CompleteProfileState {
  actionData: ActionData | null;
  actionPayload: ActionPayload | null;
  redirectEnabled: boolean;
}

const initialState: CompleteProfileState = {
  actionData: null,
  actionPayload: null,
  redirectEnabled: true,
};

const completeProfileSlice = createSlice({
  name: 'completeProfile',
  initialState,
  reducers: {
    RESET: (state) => {
      state.actionData = null;
      state.actionPayload = null;
    },
    SET_ACTION_DATA: (state, action: PayloadAction<ActionData>) => {
      state.actionData = action.payload;
    },
    SET_ACTION_PAYLOAD: (state, action: PayloadAction<ActionPayload>) => {
      state.actionPayload = action.payload;
    },
    SET_REDIRECT_ENABLED: (state, action: PayloadAction<boolean>) => {
      state.redirectEnabled = action.payload;
    },
  },
});

export const completeProfileActions = completeProfileSlice.actions;
export default completeProfileSlice;
