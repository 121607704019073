const map: { urls: Record<string, boolean> } = { urls: {} };
const loadedImages = {
  wasLoaded: (key: string): boolean => map.urls[key],
  markLoaded: (key: string): void => {
    map.urls[key] = true;
  },
  clear: (key: string): void => {
    delete map.urls[key];
  },
  clearAll: (): void => {
    map.urls = {};
  },
};
export default loadedImages;
