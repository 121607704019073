import { useMemo } from 'react';
import useAppSelector from 'common/hooks/useAppSelector';
import { useSelectedProfile } from 'common/hooks/useSelectedProfile';
import FavoritesButton from 'common/features/FavoritesButton/FavoritesButton';
import { useTranslation } from 'react-i18next';
import { ArtCollectedCardProps } from '../ArtCollectedCard/ArtCollectedCard';
import useGetSwipeContainerProps from '../common/SwipeInfoContainer/hooks/useGetSwipeContainerProps';
import { unknownTargetLinkProps, useArtworkShowcaseTargetLink } from './common/cardTargetLinksPropsHooks';
import {
  unknownProfileImageSrc,
  getAspectArtworkImageSrc,
  getProfileAvatarSrc,
  getSquareArtworkImageSrc,
} from './common/imageGetters';
import { getKnownAspectRatio, squareAspectRatio } from './common/aspectRatio';
import getAvailabilityDot from './common/getAvailabilityDot';
import useGetArtworkDetails from './common/useGetArtworkDetails';

const artworkInfoCTAStyle: React.CSSProperties = {
  width: '37.5px',
  marginBottom: '4px',
  alignSelf: 'flex-end',
  paddingLeft: '1.5px',
  marginRight: '-1.5px',
};

const useArtCollectedCardProps = (
  artworkId: number,
  columnCount: number,
  galleryContextId: number,
  buyerContextId: number,
  index: number,
  width: number,
  aspectRatio?: number,
  isSquare?: boolean
): ArtCollectedCardProps => {
  const { t } = useTranslation();
  const hasOnlyOneColumn = columnCount === 1;
  const currentContextId = useSelectedProfile()?.contextId;
  const { artwork, artist } = useAppSelector((store) => store.entities.artworkCardsCache[artworkId]) ?? {};
  const gallery = useAppSelector((store) => store.entities.collectedArtworkChipInfoCache.galleries[galleryContextId]);
  const buyer = useAppSelector((store) => store.entities.collectedArtworkChipInfoCache.collectors[buyerContextId]);

  const artworkShowcaseLinkProps = useArtworkShowcaseTargetLink(
    artist?.handle,
    artwork?.slug,
    artworkId,
    artwork?.primaryMetadataId
  );

  // artwork info cta
  const isFavorite = useAppSelector(
    (store) =>
      !!store.favorites.favorites.find((v) => v.artworkId === artwork?.artworkId && v.contextId === currentContextId)
  );

  const swipeContainerProps = useGetSwipeContainerProps(index, artwork?.artworkId ?? 0);

  // has data
  const hasArtist = !!artist;
  const hasArtwork = !!artwork;
  const hasGallery = !!gallery;
  const hasBuyer = !!buyer;

  const artworkDetails = useGetArtworkDetails(artwork);

  return useMemo<ArtCollectedCardProps>(() => {
    const hasSmallCTA = width <= 600;
    const avatarSize = hasSmallCTA ? 40 : 48;
    const sellerAvatarSrc =
      getProfileAvatarSrc(hasOnlyOneColumn ? 'large' : 'medium', artist?.profileId, artist?.avatar) ??
      unknownProfileImageSrc;
    const galleryAvatarSrc =
      getProfileAvatarSrc(hasOnlyOneColumn ? 'large' : 'medium', gallery?.profileId, gallery?.avatar) ??
      unknownProfileImageSrc;
    const buyerAvatarSrc =
      getProfileAvatarSrc(hasOnlyOneColumn ? 'large' : 'medium', buyer?.profileId, buyer?.avatar) ??
      unknownProfileImageSrc;
    const infoCTA = !artwork?.artworkId ? undefined : (
      <FavoritesButton artworkId={artwork.artworkId} isFavorite={isFavorite} />
    );
    const availabilityDot = getAvailabilityDot(
      artwork?.lastTransactionId,
      artwork?.isAvailableSale,
      artwork?.editionIsAvailableSale,
      artwork?.availablePrintAmount
    );

    // artwork card image
    const shouldBeSquare = isSquare === undefined ? hasOnlyOneColumn : isSquare;
    const squareImageSrc = getSquareArtworkImageSrc(
      hasOnlyOneColumn ? 'large' : 'medium',
      artwork?.originalArtworkId,
      artwork?.primaryMetadataId
    );
    const aspectImageSrc = getAspectArtworkImageSrc(
      hasOnlyOneColumn ? 'large' : 'medium',
      artwork?.originalArtworkId,
      artwork?.primaryMetadataId
    );
    const finalAspectRatio = shouldBeSquare ? squareAspectRatio : getKnownAspectRatio(aspectRatio);
    const finalSrc = shouldBeSquare ? squareImageSrc : aspectImageSrc;

    return {
      cardTargetLinksProps: {
        top: artworkShowcaseLinkProps,
        bottom: unknownTargetLinkProps,
      },
      artCollectedChipProps: {
        seller: {
          title: artist?.title ?? '',
          avatar: sellerAvatarSrc,
        },
        gallery: hasGallery
          ? {
              title: gallery?.title ?? '',
              avatar: galleryAvatarSrc,
            }
          : undefined,
        buyer: {
          title: buyer?.title || '',
          avatar: buyerAvatarSrc,
        },
        avatarSize,
      },
      imageProps: {
        src: finalSrc,
        aspectRatio: finalAspectRatio,
      },
      artCollectedBadgeProps: {
        maxWidth: `${width}px`,
      },
      artworkId,
      artworkInfoProps: {
        title: artwork?.title ?? undefined,
        ctaStyle: artworkInfoCTAStyle,
        details: artworkDetails,
        prefix: {
          title: availabilityDot,
        },
        infoCTA,
      },
      isSquare: shouldBeSquare,
      swipeContainerProps: hasOnlyOneColumn ? swipeContainerProps : undefined,
      isSwipeable: hasOnlyOneColumn,
    };
    // controlled re-computation of the props
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    hasOnlyOneColumn,
    hasArtist,
    hasArtwork,
    hasGallery,
    hasBuyer,
    isFavorite,
    artworkShowcaseLinkProps,
    currentContextId,
    t,
    artworkDetails,
  ]);
};

export default useArtCollectedCardProps;
