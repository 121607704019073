import { useSelectedProfile } from 'common/hooks/useSelectedProfile';
import { useMemo } from 'react';
import { useRouteMatch } from 'react-router';
import { usePublishState } from 'store/slices/publishSlice';
import { ROUTES } from 'routes/_definition';
import { PublishTypeEnum } from '../utils/artworkPublishTypes';

export const SELECT_ARTIST_ROUTE = 'artist';
export const PUBLISH_TYPE_ROUTE = 'type';
export const EDITION_TYPE_ROUTE = 'type';
export const PUBLISH_ARTWORK_ROUTE = 'artwork';
export const MOBILE_PUBLISH_ARTWORK_ROUTE = ROUTES.MOBILE_ART_PUBLICATION;
export const PUBLISH_PRINTS_ROUTE = 'prints';
export const PUBLISH_CONGRATS_ROUTE = 'congrats';

const usePublishRoutes = () => {
  const profile = useSelectedProfile();
  const isGallery = profile?.type === 'GALLERY';
  const { path } = useRouteMatch();
  const { type } = usePublishState();

  const route = useMemo(() => {
    switch (type) {
      case PublishTypeEnum.UNIQUE_ARTWORK:
        return PUBLISH_ARTWORK_ROUTE;
      case PublishTypeEnum.PRINT_EDITION:
        return PUBLISH_PRINTS_ROUTE;
      default:
        return isGallery ? SELECT_ARTIST_ROUTE : PUBLISH_TYPE_ROUTE;
    }
  }, [isGallery, type]);

  const routes = useMemo(
    () => ({
      redirect: `${path}/${route}`,
      selectArtist: `${path}/${SELECT_ARTIST_ROUTE}`,
      selectType: `${path}/${PUBLISH_TYPE_ROUTE}`,
      publishArtwork: `${path}/${PUBLISH_ARTWORK_ROUTE}`,
      publishPrints: `${path}/${PUBLISH_PRINTS_ROUTE}`,
    }),
    [path, route]
  );

  return routes;
};

export default usePublishRoutes;
