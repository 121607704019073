import { CollectionsListType } from 'store/slices/collectionListModifiers';
import {
  AppliedListingModifier,
  COLLECTION_QUERY_CONDITION,
  FILTERS_DEFAULT_VALUE,
  isOptionType,
  ListingModifier,
  ListingModifierButtonsConfig,
  ListingModifierOption,
} from './types';

// can map to translation keys
export enum KnownOptions {
  CATEGORY_DISCIPLINES = 'interestStrings.titles.Discipline',
  CATEGORY_GENRES = 'interestStrings.titles.Genres',
  CATEGORY_IDENTITIES = 'filterStrings.collections.identities',
  CATEGORY_LOCATIONS = 'filterStrings.collections.locations',
  CATEGORY_MISCELLANEOUS = 'interestStrings.titles.Miscellaneous',
  CATEGORY_ALL_COLLECTIONS = 'filterStrings.collections.all',
}

type CategoriesVariant = CollectionsListType;
type CategoriesVariantOptions = {
  [key in CategoriesVariant]: ListingModifierOption[];
};

const variants: CategoriesVariantOptions = {
  COLLECTIONS: [
    {
      value: FILTERS_DEFAULT_VALUE,
      name: KnownOptions.CATEGORY_ALL_COLLECTIONS,
      key: 'COLLECTION_QUERY_CONDITION',
      payload: {},
    },
    {
      value: 1,
      name: KnownOptions.CATEGORY_DISCIPLINES,
      key: 'COLLECTION_QUERY_CONDITION',
      payload: {
        categories: {
          _cast: {
            String: {
              _ilike: '%disciplines%',
            },
          },
        },
      },
    },
    {
      value: 2,
      name: KnownOptions.CATEGORY_GENRES,
      key: 'COLLECTION_QUERY_CONDITION',
      payload: {
        categories: {
          _cast: {
            String: {
              _ilike: '%genres%',
            },
          },
        },
      },
    },
    {
      value: 3,
      name: KnownOptions.CATEGORY_IDENTITIES,
      key: 'COLLECTION_QUERY_CONDITION',
      payload: {
        categories: {
          _cast: {
            String: {
              _ilike: '%identities%',
            },
          },
        },
      },
    },
    {
      value: 4,
      name: KnownOptions.CATEGORY_LOCATIONS,
      key: 'COLLECTION_QUERY_CONDITION',
      // eslint-disable-next-line no-useless-escape
      payload: {
        categories: {
          _cast: {
            String: {
              _ilike: '%locations%',
            },
          },
        },
      },
    },
    {
      value: 5,
      name: KnownOptions.CATEGORY_MISCELLANEOUS,
      key: 'COLLECTION_QUERY_CONDITION',
      payload: {
        categories: {
          _cast: {
            String: {
              _ilike: '%miscellaneous%',
            },
          },
        },
      },
    },
  ],
};

export const getCategoriesFilter = (variant?: CategoriesVariant): ListingModifier => ({
  type: 'CATEGORIES',
  allOptions: [...(variant ? variants[variant] : [])],
});

export const categoriesButtonsDefaultConfig: ListingModifierButtonsConfig = {
  [KnownOptions.CATEGORY_ALL_COLLECTIONS]: {
    specialWidth: 'HALF',
  },
  [KnownOptions.CATEGORY_DISCIPLINES]: {
    specialWidth: 'HALF',
  },
  [KnownOptions.CATEGORY_GENRES]: {
    specialWidth: 'HALF',
  },
  [KnownOptions.CATEGORY_IDENTITIES]: {
    specialWidth: 'HALF',
  },
  [KnownOptions.CATEGORY_MISCELLANEOUS]: {
    specialWidth: 'HALF',
  },
  [KnownOptions.CATEGORY_LOCATIONS]: {
    specialWidth: 'HALF',
  },
};

export const categoriesButtonsListTypeConfig: {
  [key in CollectionsListType]: ListingModifierButtonsConfig;
} = {
  COLLECTIONS: { ...categoriesButtonsDefaultConfig },
};

export const getAppliedCategoriesFilter = (
  variant?: CategoriesVariant
): AppliedListingModifier<COLLECTION_QUERY_CONDITION> => ({
  ...getCategoriesFilter(variant),
  selectionType: 'SINGLE',
  selectedOptions: [FILTERS_DEFAULT_VALUE],
  flattenSelectedOptions: (options, selectedOptions) => {
    const flattenedOption = options.find((option) => selectedOptions.includes(option.value));
    if (!flattenedOption || !isOptionType<COLLECTION_QUERY_CONDITION>(flattenedOption, 'COLLECTION_QUERY_CONDITION')) {
      throw new Error('Wrong option type');
    }
    return flattenedOption;
  },
});
