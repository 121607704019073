import { ApolloClient, InMemoryCache } from '@apollo/client';
import { authLink, link, uploadLink } from '../config/apolloConfig';

export const uploadClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(uploadLink),
});

export const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  connectToDevTools: false,
  link,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  },
});
