import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { getDefaultInterestFilter } from './interests.defaults';
import { InterestsFilterState } from './interests.types';

type ToggleInterestAction = PayloadAction<{
  interestGroup: string;
  interestId: number;
}>;
export const createToggleInterestCaseReducer =
  <State extends InterestsFilterState>(): CaseReducer<State, ToggleInterestAction> =>
  (state, action) => {
    const { interestGroup, interestId } = action.payload ?? {};

    const prevGroup = state.interests[interestGroup];
    if (!prevGroup) {
      return;
    }

    // cannot actively turn "All" off when it is on
    if (interestId === 0 && prevGroup[0]) {
      return;
    }

    // can turn "All" on when it is off and turn everything else off
    if (interestId === 0) {
      state.interests[interestGroup] = getDefaultInterestFilter().interests[interestGroup];
      return;
    }

    const nextGroup = { ...prevGroup };
    // toggle interest
    nextGroup[interestId] = !nextGroup[interestId];

    // if no interests are on, set "All" on
    nextGroup[0] = !Object.values(nextGroup).includes(true);

    state.interests[interestGroup] = nextGroup;
  };
